import styles from './ArrowLink.module.scss';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../assets/media/icons/icon-arrow-down.svg';
import { History } from 'history';

interface IProps {
  children: JSX.Element | string;
  position: 'back' | 'forward';
  classNames?: string;
  to?: string;
  onClick?: any;
}

interface ILinkOrAProps {
  children: JSX.Element;
  history: History;
  to?: string;
}
function LinkOrA({ children, history, to }: ILinkOrAProps) {
  if (!to) {
    return (
      <button className='focus-ring btn--no-style' onClick={() => history.goBack()}>
        {children}
      </button>
    );
  }
  return (
    <Link className='focus-ring' to={to}>
      {children}
    </Link>
  );
}

function ArrowLink({ children, classNames, to, position, onClick }: IProps): JSX.Element {
  const history = useHistory();

  const body =
    position === 'back' ? (
      <>
        <ArrowDown className='icon' aria-hidden='true' focusable='false' role='img' />
        {children}
      </>
    ) : (
      <>
        {children}
        <ArrowDown className='icon' aria-hidden='true' focusable='false' role='img' />
      </>
    );

  return (
    <div onClick={onClick && onClick} className={`${styles['arrow-link']} ${classNames} ${styles[position]}`}>
      <LinkOrA history={history} to={to}>
        {body}
      </LinkOrA>
    </div>
  );
}

export default ArrowLink;
