import { ESTIMATED_DATE } from '../ProductConfig';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import GetEstimatedDatesRequestModel from 'stores/create-order/models/GetEstimatedDatesRequestModel';
import generateFieldState from './generateFieldState';

export default function getStoredFields({
  dispatch,
  product,
  productConfigs,
  step,
  setStep,
  originalProductConfigs,
  storedSubmittedLists,
  setMasterFieldObject,
  setSubmittedList,
  setFieldState,
  setErrorMessage,
}) {
  const storedMFO = productConfigs[product.replace(' ', '_')];
  const updatedFields = generateFieldState(storedMFO, step, setStep);

  // Set default date
  ESTIMATED_DATE.setProduct(product);
  CreateOrderStatelessCalls.getEstimatedShipDates(
    new GetEstimatedDatesRequestModel({
      product,
      configuration: [],
    })
  ).then((res) => {
    if (!res.data) {
      setErrorMessage(res);
    }
    ESTIMATED_DATE.defaultDate = res.data;
  });

  setMasterFieldObject(storedMFO);
  setSubmittedList(storedSubmittedLists[product.replace(' ', '_')]);
  setFieldState(updatedFields);
}
