import { ISelectOption } from 'components/Form/ISelectOption';
import StateNames, { ProvinceNames } from 'constants/StatesNamesArr';

/**
 * This function adds the property name with the passed in value to each state in the states array
 * Used to make the form usable on react-select fields.
 *
 * @param nameValue The value assigned to the name property
 * @returns array of state name objects with an added name field
 */
export function getStateNameOptions(nameValue): ISelectOption[] {
  return StateNames.map((item: ISelectOption) => {
    item.name = nameValue;
    return item;
  });
}

/**
 * This function adds the property name with the passed in value to each state in the states array
 * Used to make the form usable on react-select fields.
 *
 * @param nameValue The value assigned to the name property
 * @returns array of state name objects with an added name field
 */
export function getProvinceNameOptions(nameValue): ISelectOption[] {
  return ProvinceNames.map((item: ISelectOption) => {
    item.name = nameValue;
    return item;
  });
}
