import styles from './LinkListItem.module.scss';
import * as React from 'react';
import { useState } from 'react';
import { ReactComponent as LinkArrow } from '../../../../assets/media/icons/icon-link-arrow.svg';
import { ReactComponent as Pdf } from '../../../../assets/media/icons/icon-pdf.svg';
import { ReactComponent as Excel } from '../../../../assets/media/icons/icon-excel.svg';
import { ReactComponent as VideoPlay } from '../../../../assets/media/icons/icon-video-play.svg';
import { IPageResourceItem } from 'stores/pages/models/IPage';
import PagesStatelessCalls from 'stores/pages/PagesStatelessCalls';
import { ReactComponent as DownloadArrow } from 'assets/media/icons/icon-download.svg';
import LinkModal from '../LinkModal/LinkModal';

interface IProps {
  item: IPageResourceItem;
  documentClick: (r: string, i: string, v: string) => void;
  setIsLoading: (v: boolean) => void;
}

export const LinkListItem = ({ item, documentClick, setIsLoading }: IProps): JSX.Element => {
  const [modalState, setModalState] = useState(false);

  const onFileDownload = async (pageUrl, modalText) => {
    setIsLoading(true);
    if (modalText !== '') {
      setIsLoading(false);
      setModalState(true);
    } else {
      await PagesStatelessCalls.downloadFileForPage(pageUrl);
      setIsLoading(false);
    }
  };

  const handleOnFileClick = async (modalText) => {
    let url = '';
    if (modalText !== '') {
      setModalState(true);
    } else {
      if (item.resourceType === 'LINK') {
        url = item.linkUrl;
      } else if (item.resourceType === 'VIDEO') {
        url = item.videoId;
      } else if (item.resourceType === 'PDF' || item.resourceType === 'Excel') {
        url = item.fileUrl;
      }
      documentClick(item.resourceType, item.videoId, url);
    }
  };

  return (
    <li>
      {item.resourceType !== 'LINK' && item.resourceType !== 'VID' ? (
        <div
          onClick={item.isDownloadable ? () => onFileDownload(item.fileUrl, item.modalText) : () => handleOnFileClick(item.modalText)}
          className={`${styles['link-card']} focus-ring`}
        >
          <span className={styles['link-card__inner']}>
            <span className='text text--truncate'>{item.header}</span>
            {/* TODO: Impliment icons */}
            {item.resourceType === 'LINK' && <LinkArrow className='icon icon--link-arrow' aria-hidden='true' focusable='false' role='img' />}
            {(item.resourceType === 'VID' || item.resourceType === 'VIDEO') && (
              <VideoPlay className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />
            )}
            {item.resourceType === 'EXCEL' ? (
              <div>
                <Excel className='icon icon--link-excel' aria-hidden='true' focusable='false' role='img' />
                <DownloadArrow className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />
              </div>
            ) : null}
            {item.resourceType === 'PDF' ? (
              <div>
                <Pdf className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />
                {item.isDownloadable && <DownloadArrow className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />}
              </div>
            ) : null}
          </span>
        </div>
      ) : (
        <a href={item.linkUrl} target='_blank' rel='noopener noreferrer' className={`${styles['link-card']} focus-ring`}>
          <span className={styles['link-card__inner']}>
            <span className='text text--truncate'>{item.header}</span>
            {/* TODO: Impliment icons */}
            {item.resourceType === 'LINK' && <LinkArrow className='icon icon--link-arrow' aria-hidden='true' focusable='false' role='img' />}
            {item.resourceType === 'VID' && <VideoPlay className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />}
            {item.resourceType !== 'VID' && item.resourceType !== 'LINK' && item.resourceType !== 'EXCEL' ? (
              <Pdf className='icon icon--pdf' aria-hidden='true' focusable='false' role='img' />
            ) : null}
          </span>
        </a>
      )}
      {modalState ? <LinkModal modalText={item.modalText} documentClick={documentClick} setModalState={setModalState} item={item} /> : null}
    </li>
  );
};
export default LinkListItem;
