import { BaseModel } from 'sjs-base-model';
import ICreditSummary from './ICreditSummary';
/*
    // Returned Api Data Sample
    {
      "creditLimit": '$0.00',
      "balance": '$0.00',
      "availableCredit": '$0.00',
      "openCredit": '$0.00',
    }
 */
export default class CreditSummaryResponseModel extends BaseModel {
  public readonly data: ICreditSummary = {
    creditLimitValue: '$0.00',
    creditLimit: 0.0,
    currentBalanceValue: '$0.00',
    currentBalance: 0.0,
    availableCreditValue: '$0.00',
    availableCredit: 0.0,
    openCreditValue: '$0.00',
    openCredit: 0.0,
  };
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<CreditSummaryResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<CreditSummaryResponseModel>): void {
    super.update(data);
  }
}
