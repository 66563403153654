import './AccountsTable.scss';

import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/media/icons/icon-arrow-down.svg';
import AccountCard from '../AccountCard/AccountCard';
import IAccount from '../../../../stores/accounts/models/IAccount';
import Pagination from '../../../../components/Pagination/Pagination';
import IAccountsResponse from '../../../../stores/accounts/models/IAccountsResponse';
import { useTable, usePagination } from 'react-table';
import IDataTableUIState from '../../../../stores/dataTable/models/IDataTableUIState';
import SortDirectionEnum from '../../../../constants/SortDirectionEnum';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import AccountsColumnNamesEnum from 'constants/AccountsColumnNamesEnum';
import { selectRequesting } from 'selectors/requesting/RequestingSelector';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import AccountsAction from 'stores/accounts/AccountsAction';
import Error4xx from 'components/Error4xx/Error4xx';

interface IProps {
  accountData: IAccountsResponse;
  ui: IDataTableUIState;
  onSortClick: (sortCol: string) => void;
  onNewPageClick: (page: number) => void;
  onAccountClick: (e: React.SyntheticEvent, account: IAccount) => void;
  closeModal?: any;
}

export default function AccountsTable(props: IProps) {
  const [activeRow, setActiveRow] = useState(0);
  const { accountData, ui, onSortClick, onAccountClick, onNewPageClick } = props;
  const tableData = accountData.data;
  const controlledPageCount = accountData.totalPages;

  const columns = [
    {
      Header: 'Account Number',
      accessor: AccountsColumnNamesEnum.AccountNumber,
    },
    {
      Header: 'Account Name',
      accessor: AccountsColumnNamesEnum.AccountName,
    },
    {
      Header: 'Business Name',
      accessor: AccountsColumnNamesEnum.BusinessName,
    },
  ];

  const accountsList4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED]
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const onMouseEnter = (rowNum: number): void => {
    setActiveRow(rowNum);
  };

  const onMouseLeave = (): void => {
    setActiveRow(0);
  };

  const requestingAccounts = useSelector((state: IStore) => selectRequesting(state, ['AccountsAction.REQUEST_ACCOUNT_RESPONSE']));
  const iconDirection = ui.sortData.SortDirection === SortDirectionEnum.DESC ? 'icon--dir-0' : 'icon--dir-180';

  return (
    <div className={`acc-table-wrapper`}>
      <div className={'vr vr5'}></div>
      {requestingAccounts && <LoadingIndicator className={'accounts-loader'} isActive={true} inverted={true} />}
      <Error4xx response={accountsList4xxResponses['AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED']} textOnly>
        <table className='acc-table' {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`headerGroup-${i}`} className='acc-table__row acc-table__row--hd' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className='acc-table__row__cell' scope='col' key={column.id}>
                    <button
                      type='button'
                      className='acc-table__sort-btn focus-ring'
                      onClick={() => onSortClick(column.id)}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      {ui.sortData.Column === column.id ? (
                        <ArrowDown className={`icon icon--active ${iconDirection}`} aria-hidden='true' focusable='false' role='img' />
                      ) : (
                        <ArrowDown className={'icon'} aria-hidden='true' focusable='false' role='img' />
                      )}
                    </button>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <>
              {page.map((row, i) => {
                const rowNum = i + 1;
                const activeRowStyle = activeRow === rowNum ? 'is--active' : '';
                const account = row.original;
                prepareRow(row);
                return (
                  <tr
                    key={`row-${i}`}
                    role='link'
                    className={`acc-table__row acc-table__row--bd ${activeRowStyle}`}
                    {...row.getRowProps()}
                    onMouseEnter={() => onMouseEnter(rowNum)}
                    onMouseLeave={onMouseLeave}
                    onClick={(e) => onAccountClick(e, account)}
                  >
                    <AccountCard rowData={row.values} />
                  </tr>
                );
              })}
            </>
          </tbody>
          <tfoot>
            <tr>
              <th scope='row' colSpan={3}>
                <Pagination data={accountData} onNewPageClick={onNewPageClick} />
              </th>
            </tr>
          </tfoot>
        </table>
      </Error4xx>
    </div>
  );
}
