import * as React from 'react';

interface IProps {
  filter: string;
  onSearchChange: (event: any) => void;
}

export default function AccountsSearch(props: IProps): JSX.Element {
  const { filter, onSearchChange } = props;

  return (
    <div className='search search--magnifying-glass'>
      <label className='visually-hidden'>Search Account Number or Name</label>
      <input
        type='search'
        className='search__input focus-ring'
        name='accountsSearch'
        placeholder='Search Account Number or Name…'
        value={filter}
        onChange={(e: any): void => onSearchChange(e)}
      />
    </div>
  );
}
