/**
 * Sets state and returns submitted list for immidiate use
 *
 * @param submittedList list of previously submitted dependency calls
 * @param newDependency the newDependency to add to the list
 * @param setSubmittedList the state function to set the state
 */
export default function generateSubmittedList(submittedList: string[], newDependency: string, setSubmittedList: (item) => void) {
  setSubmittedList([...submittedList, newDependency]);

  return [...submittedList, newDependency];
}
