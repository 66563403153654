import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import IStore from '../models/IStore';
import UsersReducer from './users/UsersReducer';
import RequestingReducer from './requesting/RequestingReducer';
import ErrorReducer from './error/ErrorReducer';
import ToastsReducer from './toasts/ToastsReducer';
import AccountsReducer from './accounts/AccountsReducer';
import OrdersReducer from './orders/OrdersReducer';
import PaymentsReducer from './payments/PaymentsReducer';
import InvoicesReducer from './invoices/InvoicesReducer';
import CreditSummaryReducer from './credit-summary/CreditSummaryReducer';
import NavigationReducer from './navigation/NavigationReducer';
import PagesReducer from './pages/PagesReducer';
import SearchReducer from './search/SearchReducer';
import CreateOrderReducer from './create-order/CreateOrderReducer';
import FeatureFlagReducer from './feature-flag/FeatureFlagReducer';
import SerialNumberReducer from './serial-number/SerialNumberReducer';

export default (history: History): Reducer<IStore> => {
  const reducerMap: ReducersMapObject<IStore> = {
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    router: connectRouter(history) as any,
    users: new UsersReducer().reducer,
    accounts: new AccountsReducer().reducer,
    toasts: new ToastsReducer().reducer,
    orders: new OrdersReducer().reducer,
    invoices: new InvoicesReducer().reducer,
    payments: new PaymentsReducer().reducer,
    pages: new PagesReducer().reducer,
    creditSummary: new CreditSummaryReducer().reducer,
    navigation: new NavigationReducer().reducer,
    search: new SearchReducer().reducer,
    createOrder: new CreateOrderReducer().reducer,
    featureFlag: new FeatureFlagReducer().reducer,
    serialNumber: new SerialNumberReducer().reducer,
  };

  return combineReducers(reducerMap);
};
