import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import PaymentResponseModel from './models/PaymentsResponseModel';

export default class OrdersEffect {
  public static async requestPayment(
    accountNum: number,
    tranNumber: string,
    tranRef: string,
    tranDate: Date
  ): Promise<PaymentResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${
      (environment.api as any).transactions
    }/${accountNum}/payment?transactionNumber=${tranNumber}&transactionReferenceNumber=${tranRef}&transactionDate=${tranDate}`;
    // const endpoint: string = `${(environment.api as any).orders}/127/orderDetails?orderNumber=1776673`; //TODO: remove testing for merge
    // working order numbers 2156739 2122519 1776673
    return EffectUtility.getToModel<PaymentResponseModel>(PaymentResponseModel, endpoint);
  }
}
