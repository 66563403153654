import { BaseModel } from 'sjs-base-model';

export default class LinkUnlinkUserRequestModel extends BaseModel {
  userId: number;
  accountNumbers: number[];

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
