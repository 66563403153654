import { BaseModel } from 'sjs-base-model';
import IUser from './IUser';

/*
    // Returned Api Data Sample
    {
      data: [],
      message: null,
      status: 'Ok',
      statusCode: 200,
      source: 'UsersController',
      eventCode: 'GetUser',
    }
 */
export default class UserResponseModel extends BaseModel {
  public readonly data: IUser;
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;
  public readonly source: string = '';
  public readonly eventCode: string = '';

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<UserResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<UserResponseModel>): void {
    super.update(data);
  }
}
