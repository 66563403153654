/* eslint-disable react/display-name */
import detailStyle from '../../OrderDetailTable/OrderDetailTable.module.scss';
import environment from 'environment';
import style from '../OrderItems.module.scss';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import { usePopper } from 'react-popper';
import { ReactComponent as TrackingIcon } from 'assets/media/icons/icon-tracking.svg';
import { ReactComponent as DetailsIcon } from 'assets/media/icons/icon-details.svg';
import { ReactComponent as IconArrowDiag } from 'assets/media/icons/icon-arrow-diagonal.svg';
import { CarrierEnum } from 'constants/CarrierEnum';
import { IOrderTracking } from 'stores/orders/models/IOrder';
import HttpUtility from 'utilities/HttpUtility';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { DATE_HELPER } from 'index';

interface ITableStatus {
  readonly value: {
    readonly itemStatus: string;
    readonly itemStatusDate: string;
  };
}

interface ITableDescription {
  readonly value: {
    readonly itemDescription: string;
    readonly product: string;
    readonly tagName: string;
  };
}
export interface ITrackingInfo {
  trackingOpen: boolean;
  currentOpenItemNumber: number;
  trackingList: any;
}
interface ITableTracking {
  readonly value: {
    readonly pONumber: string; // Not available
    readonly reqDate: string; // Requested Date // Not available
    readonly terms: string;
    readonly trackingInfo: IOrderTracking;
    itemNumber: number;
    orderNumber: number;
    orderHeaderId: string | null;
  };
  readonly tracking: ITrackingInfo;
  readonly setTracking: any;
}

interface ITableInvoice {
  readonly value: string | number;
}

export function convertDate(date) {
  const initialDate = new Date(date);
  const convertedDate = initialDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return convertedDate;
}

export const getTrackingLink = (carrier, trackingNumber) => {
  const convertedCarrier = carrier?.toUpperCase().replaceAll(' ', '');
  const countryCode = environment.regionName === 'na' ? 'us' : environment.regionName;
  const getTrackingLink = {
    [CarrierEnum.FEDX]: () => (
      <a
        className={detailStyle['order-detail-table__tracking-link']}
        href={`https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}&cntry_code=${countryCode}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {trackingNumber}{' '}
        <span className={detailStyle['order-detail-table__tracking-icon']}>
          <IconArrowDiag />
        </span>
      </a>
    ),
    [CarrierEnum.FEDEX]: () => (
      <a
        className={detailStyle['order-detail-table__tracking-link']}
        href={`https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}&cntry_code=${countryCode}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {trackingNumber}{' '}
        <span className={detailStyle['order-detail-table__tracking-icon']}>
          <IconArrowDiag />
        </span>
      </a>
    ),
    [CarrierEnum.UPS]: () => (
      <a
        className={detailStyle['order-detail-table__tracking-link']}
        href={`https://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=${trackingNumber}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {trackingNumber}{' '}
        <span className={detailStyle['order-detail-table__tracking-icon']}>
          <IconArrowDiag />
        </span>
      </a>
    ),
    [CarrierEnum.USPS]: () => (
      <a
        className={detailStyle['order-detail-table__tracking-link']}
        href={`https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {trackingNumber}{' '}
        <span className={detailStyle['order-detail-table__tracking-icon']}>
          <IconArrowDiag />
        </span>
      </a>
    ),
  };

  return getTrackingLink[convertedCarrier]?.() ?? null;
};

export const Status = (props: ITableStatus) => {
  const {
    value: { itemStatus, itemStatusDate },
  } = props;
  const date = DATE_HELPER.getDisplayDate(itemStatusDate);

  return (
    <div>
      <div className={detailStyle['order-detail-table__status--orderstatus']} data-orderstatus={itemStatus}>
        {itemStatus}
      </div>
      <br />
      {date}
    </div>
  );
};

export const Description = (props: ITableDescription) => {
  const {
    value: { itemDescription, product, tagName },
  } = props;

  return (
    <div>
      <span className={detailStyle['order-detail-table__description-hd']}>{product}</span> <br />
      {itemDescription} <br />
      <span className={detailStyle['order-detail-table__bold']}>Tags: {tagName}</span>
    </div>
  );
};

export const Invoice = (props: ITableInvoice) => {
  const { value } = props;
  return (
    <div>
      {value <= 0 ? (
        <>
          <span>Not available.</span>
        </>
      ) : (
        <>
          <Link to={`${RouteEnum.Transactions}/${value}`}>{value}</Link>
        </>
      )}
    </div>
  );
};

export const Money = (props) => {
  const { value } = props;

  return <>{`${value}`}</>;
};

export function openModalDynamic(stateSetter, stateStatus, ref) {
  function close(stateSetter) {
    stateSetter(false);
  }

  // ... show the menu element ...
  const clickOff = (evt) => {
    if (stateStatus === false) {
      document.removeEventListener('click', clickOff);
      if (ref.current) {
        document.addEventListener('click', clickOff);
        if (ref.current && !ref.current.contains(evt.target)) {
          document.removeEventListener('click', clickOff);
          close(stateSetter);
        }
      }
    }
  };

  if (stateStatus === true) {
    stateSetter(false);
    document.removeEventListener('click', clickOff);
  } else {
    stateSetter(true);
    document.addEventListener('click', clickOff);
  }
}

const TrackingPopper = ({ children, buttonTitle, icon }) => {
  const [referenceElement, setReferenceElement] = React.useState<any>(null);
  const [popperElement, setPopperElement] = React.useState<any>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const yoRef = useRef<any>(null);
  const { styles, attributes } = usePopper<any>(referenceElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  return (
    <div>
      <button
        className={detailStyle['order-detail-table__button']}
        onClick={() => openModalDynamic(setOpen, isOpen, yoRef)}
        ref={setReferenceElement}
      >
        {icon === 'trackingIcon' ? <TrackingIcon /> : <DetailsIcon />}
      </button>
      {isOpen && (
        <div ref={yoRef}>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
            }}
            className='menu'
            {...attributes.popper}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export const Tracking = (props: ITableTracking) => {
  const { value, setTracking, tracking } = props;
  const [emptyTracking, setEmptyTracking] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const getTrackingForLine = async () => {
    const endpoint = `${(environment.api as any).orders}/${value.orderNumber}/item-tracking/${value.itemNumber}`;

    if (tracking.trackingList.find((item: any) => item[0].itemNumber === value.itemNumber)) {
      setTracking({
        ...tracking,
        activeHeaderId: value.orderHeaderId,
        trackingOpen: true,
        currentOpenItemNumber: value.itemNumber,
      });
    } else {
      setLoading(true);
      const response = await HttpUtility.get(endpoint);
      if (!response.data) {
        setEmptyTracking(true);
        return;
      }
      setLoading(false);
      if (response.data.data.length === 0) {
        setEmptyTracking(true);
      } else {
        setTracking({
          activeHeaderId: value.orderHeaderId,
          trackingOpen: true,
          trackingList: [...tracking.trackingList, response.data.data],
          currentOpenItemNumber: value.itemNumber,
        });
      }
    }
  };

  return (
    <div className={emptyTracking ? detailStyle['order-detail-table__tracking--empty'] : detailStyle['order-detail-table__tracking']}>
      {emptyTracking ? (
        <span>No tracking available</span>
      ) : (
        <>
          {loading ? (
            <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 0 }} />
          ) : (
            <button className={detailStyle['order-detail-table__button']} onClick={() => getTrackingForLine()}>
              <TrackingIcon />
            </button>
          )}
        </>
      )}
      <TrackingPopper buttonTitle='2' icon='DetailsIcon'>
        <div className={style['tracking-popper']}>
          <h5 className='hdg hdg--5'>PO Number</h5>
          <p className='vr1_5'>{value.pONumber}</p>
          <h5 className='hdg hdg--5'>Entered Date</h5>
          <p className='vr1_5'>{DATE_HELPER.getDisplayDate(value.reqDate)}</p>
        </div>
      </TrackingPopper>
    </div>
  );
};
