import { MAIN_CONFIG } from 'configurations/mainConfig';

export const getAddedValuesMasterFieldObject = (data) => {
  return data.map((item) => {
    if (item.attributeName === 'DEPARTMENT') {
      return {
        ...item,
        selectedValue: MAIN_CONFIG.CreateOrder.Department,
      };
    }

    return {
      ...item,
      selectedValue: null,
    };
  });
};
