import IUser from 'stores/users/models/IUser';
import { KenticoUser } from 'stores/users/models/IUserV3';

const getUserInitials = (user: IUser | KenticoUser) => {
  if (user) {
    if (user.firstName && user.lastName) {
      return user.firstName[0] + user.lastName[0];
    }
    if (user.fullName) {
      const names = user.fullName.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  }
  return '';
};
export default getUserInitials;
