import { MAIN_CONFIG } from 'configurations/mainConfig';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';

export default class CartStateHelpers {
  public static cancelOrder(dispatch, accountNumber, cartID, setLoading?) {
    CartStatelessCalls.deleteCart(cartID).then(() => {
      dispatch(CreateOrderAction.getCart(accountNumber)).then(() => {
        dispatch(
          CreateOrderAction.storeTabState({
            hasConfirm: false,
            hasItems: false,
            hasReview: false,
            hasShipping: false,
            tabIndex: 0,
            product: MAIN_CONFIG.CreateOrder.NoProduct,
            itemToEditId: null,
          })
        );
        if (setLoading) {
          setLoading(false);
        }
        // TODO: ask chris about toast notifications
        // dispatch(ToastsAction.add('Cart Cleared', ToastStatusEnum.Success));
      });
    });
  }
}
