import React, { useEffect, useState } from 'react';
import AccessControl from '../../components/AccessControl/AccessControl';
import UserRolesEnum from '../../constants/UserRolesEnum';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import RouteEnum from '../../constants/RouteEnum';
import styles2 from './PaymentDetailsPage.module.scss';
import PaymentDetailsHeader from './components/PaymentsDetailsHeader/PaymentDetailsHeader';
import PaymentDetailsTable from './components/PaymentsDetailsTable/PaymentsDetailsTable';
import IStore from '../../models/IStore';
import IAccount from 'stores/accounts/models/IAccount';
import { useParams } from 'react-router-dom';
import PaymentsAction from 'stores/payments/PaymentsAction';
import IPayment from 'stores/payments/models/IPayment';
import { oc } from 'ts-optchain.macro';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import { pageLoader } from 'components/PageLoader/PageLoader';

// Order number 2273145 has a payments page with multiple payments
// ref # 220874 also has more then one order 2277080

interface IParams {
  tranNumber: string;
  refNumber: string;
  tranDate: string;
}

function PaymentDetailsPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const payments: IPayment | undefined = useSelector((state: IStore) => oc(state).payments.paymentsResponse.data(undefined));
  const activeAccount: IAccount | null = useSelector((state: IStore) => state.accounts.activeAccount);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const { tranNumber, refNumber, tranDate } = useParams<IParams>();
  const payment4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [PaymentsAction.GET_PAYMENT_FINISHED]
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accountNumber) {
      setLoading(true);
      dispatch(PaymentsAction.requestPayment(accountNumber, tranNumber, refNumber, tranDate)).then(() => setLoading(false));
    }
    return function cleanUp() {
      dispatch(PaymentsAction.resetPayment(null));
    };
  }, [dispatch, accountNumber, tranNumber, refNumber, tranDate]);

  const transactionData = payments?.transactions.filter((item) => item.transactionType === 'PAY');

  if (loading) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewTransactions} noAccessRedirect={true}>
      <div className='wrapper'>
        <Error4xx response={payment4xxResponses['PaymentsAction.GET_PAYMENT_FINISHED']}>
          {accountNumber ? (
            <div>
              <div className='wrapper'>
                <ArrowLink position='back' classNames='vr1_5' to={RouteEnum.Transactions}>
                  Back to Invoice Summary
                </ArrowLink>
                <h1 className={'hdg hdg--1'}>Payment Details</h1>
                <div className={`${styles2['payment-container']}`}>
                  <PaymentDetailsHeader payments={payments} />
                </div>
              </div>
              <div>
                {transactionData?.map((tranData) => {
                  return <PaymentDetailsTable payments={tranData} key={tranData.accountNumber} />;
                })}
              </div>
            </div>
          ) : (
            <div className='wrapper'>
              <h1 className='hdg--1'>Please select an account</h1>
            </div>
          )}
        </Error4xx>
      </div>
    </AccessControl>
  );
}

export default PaymentDetailsPage;
