import React from 'react';
import styles from './Error5xx.module.scss';
import { ReactComponent as ErrorIcon } from 'assets/media/icons/icon-error.svg';
import { useParams } from 'react-router-dom';
import environment from 'environment';
import { PingCustomerServiceContactMessage } from 'components/Content/Contact/CustomerServiceContactMessage';

const Error5xx = () => {
  const { statusCode, userMessage, eventCode, previousLocation } = useParams<any>();
  const decodedPreviousLocation = decodeURIComponent(previousLocation);

  const handleReload = () => {
    (window as any).location.replace(`${environment.uri.baseUri as any}${decodedPreviousLocation}`);
  };

  const handleReloadToDashboard = () => {
    (window as any).location.replace(`${environment.uri.baseUri as any}`);
  };

  return (
    <div className='wrapper'>
      <h1 className='hdg hdg--1'>Oops, something went wrong.</h1>
      <div className={styles.error5}>
        <div className={styles.error5__grid}>
          <div className={styles.error5__icon}>
            <ErrorIcon className={styles['error-card__icon']} />
          </div>
          <div className={styles.error5__messageArea}>
            <h4 className='hdg--4'>
              An Error Occurred {statusCode}. {userMessage}
            </h4>
            <div className='vr3'></div>
            <p>
              <PingCustomerServiceContactMessage />. [Error code: {eventCode}]
            </p>
            <div className='vr2'></div>
            <div className={styles.error5__buttonContainer}>
              <button onClick={handleReload} className='btn btn__text-only'>
                Reload To Previous Page
              </button>
              <button onClick={handleReloadToDashboard} className='btn btn__text-only'>
                Reload To Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error5xx;
