import { MAIN_CONFIG } from 'configurations/mainConfig';
import environment from 'environment';
import HttpUtility from 'utilities/HttpUtility';
import HttpUtilityRequestBody from 'utilities/HttpUtilityRequestBody';
import GetPriceRequestModel from './models/GetPriceRequestModel';
import EffectUtility from 'utilities/EffectUtility';
import GetPriceResponseModel from './models/GetPriceResponseModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import GetEstimatedDatesRequestModel from './models/GetEstimatedDatesRequestModel';
import GetEstimatedDatesResponseModel from './models/GetEstimatedDatesResponseModel';
import GetClubsResponseModel from './models/GetClubsResponseModel';

// TODO: use the EffectUtility.
/**
 * These function will hit the api and return the response without saving it to the store
 */

interface IGetInitialFields {
  product: string;
  accountNumber: number | undefined;
}

interface IDeparseClubsRequest {
  accountNumber: any;
  product: string;
  clubs: string;
}

export interface IGetFieldsUpdate {
  product: string;
  dependentVariable: string;
  independentVariable1: string;
  independentVariable1Value: string;
  independentVariable2?: string;
  independentVariable2Value?: string;
  accountNumber: number | undefined;
  setIsLoading?: (v: boolean) => void;
}

export interface IGetClubs {
  accountNumber: number | undefined;
  cfgModel: string | undefined;
  indVarValue: string | undefined;
}

export default class CreateOrderStatelessCalls {
  // TODO: If needed to insert loading state otherwise delete
  private async getResponse(endpoint: string) {
    return HttpUtility.get(endpoint);
  }

  /**
   * TODO: No longer used now that BE is handling this
   */
  public static getProducts(accountNumber: number) {
    // const endpoint = `https://test.pinggolf.biz/api/v1/Products/127/GetActiveProducts?orgId=us`;
    const endpoint = `${(environment.api as any).product}/${accountNumber}/GetActiveProducts?orgId=${MAIN_CONFIG.CreateOrder.OrgID}`;

    HttpUtility.get(endpoint)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  public static getInitialFields({ product, accountNumber }: IGetInitialFields) {
    // http://api.pinggolf.local/api/v1/Products/127/GetProductAttributesByOrgId?orgId=us&product=IRONS
    const endpoint: string = encodeURI(
      `${(environment.api as any).product}/${accountNumber}?orgId=${MAIN_CONFIG.CreateOrder.OrgID}&product=${product}`
    );

    return HttpUtility.get(endpoint);
  }

  public static getFieldsUpdate({
    product,
    dependentVariable,
    independentVariable1,
    independentVariable1Value,
    independentVariable2,
    independentVariable2Value,
    accountNumber,
    setIsLoading,
  }: IGetFieldsUpdate) {
    const endpoint = encodeURI(
      `${(environment.api as any).product}/${accountNumber}/GetProductAttributeDependencies?orgId=${
        MAIN_CONFIG.CreateOrder.OrgID
      }&product=${product}&dependentVariable=${encodeURIComponent(
        dependentVariable
      )}&independentVariable1=${independentVariable1}&independentVariable1Value=${encodeURIComponent(independentVariable1Value)}${
        independentVariable2
          ? `&independentVariable2=${independentVariable2}&independentVariable2Value=${
              independentVariable2Value ? encodeURIComponent(independentVariable2Value) : independentVariable2Value
            }`
          : ''
      }`
    );
    if (setIsLoading) {
      setIsLoading(true);
    }
    // const endpoint = 'https://test.pinggolf.biz/api/v1/Product/127/get-product-attribute-dependencies?orgId=us&product=IRONS&dependentVariable=MODEL&independentVariable1=DEPARTMENT&independentVariable1Value=CUSTOMER%20SERVICE'
    // const endpoint = "https://test.pinggolf.biz/api/v1/Product/127/GetProductAttributeDependencies?orgId=us&product=IRONS&dependentVariable=MODEL&independentVariable1=DEPARTMENT&independentVariable1Value=CUSTOMER%20SERVICE"
    // https://test.pinggolf.biz/api/v1/Product/127/GetProductAttributeDependencies/orgId=us&product=IRONS&dependentVariable=CFGMODELORGID&independentVariable1=DEPARTMENT&independentVariable1Value=INTERNATIONAL

    return HttpUtility.get(endpoint);
  }

  public static async getClubs({ accountNumber, cfgModel, indVarValue }: IGetClubs) {
    // http://api.pinggolf.local/api/v1/Product/127/GetClubDefinitions?cfgModel=IRONS1998&indVarValue=3%2C5-9PWUWSWLW
    const endpoint: string = encodeURI(
      `${(environment.api as any).product}/${accountNumber}/GetClubDefinitions?cfgModel=${cfgModel}&indVarValue=${indVarValue}`
    );

    const res = await EffectUtility.getToModel(GetClubsResponseModel, endpoint);

    return res;
  }

  public static async getProductPrice(requestConfig: GetPriceRequestModel): Promise<GetPriceResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).product3}/price`;

    const res = await EffectUtility.postToModelBody(GetPriceResponseModel, endpoint, requestConfig, 'price');

    return res;
  }

  public static async getEstimatedShipDates(
    requestConfig: GetEstimatedDatesRequestModel
  ): Promise<GetEstimatedDatesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).product3}/shipDate`;

    const res = await EffectUtility.postToModelBody(GetEstimatedDatesResponseModel, endpoint, requestConfig);

    return res;
  }

  public static getTax(requestConfig, accountNumber: number) {
    const endpoint: string = `${(environment.api as any).taxes}/${accountNumber}`;

    return HttpUtilityRequestBody.post(endpoint, requestConfig);
  }

  public static createOrder(requestConfig) {
    const endpoint: string = `${(environment.api as any).orders}`;

    return HttpUtilityRequestBody.post(endpoint, requestConfig);
  }
}
