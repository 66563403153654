import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import CreditSummaryResponseModel from './models/CreditSummaryResponseModel';

export default class CreditSummaryEffect {
  public static async requestCreditSummary(accountNumber: number): Promise<CreditSummaryResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).accounts}/${accountNumber}/creditsummary`;

    return EffectUtility.getToModel<CreditSummaryResponseModel>(CreditSummaryResponseModel, endpoint);
  }
}
