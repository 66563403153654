import moment from 'moment';
import DateFormatsEnum from './DateFormatsEnum';

export default class DateHelper {
  public today = moment().local();
  public oneTwentyDays = moment()
    .subtract(120, 'day')
    .local();
  public todayToDate = moment()
    .local()
    .toDate();
  public oneTwentyDaysToDate = moment(this.oneTwentyDays.format(DateFormatsEnum.LocalMonthDayYear))
    .local()
    .toDate();
  public oneTwentyDaysMonthDayYear = moment(this.oneTwentyDays).format(DateFormatsEnum.MonthDayYear);
  public todayDateWithTime = moment(this.today).format(DateFormatsEnum.NewDate);
  public getDisplayDate(dateString) {
    return moment(dateString)
      .local()
      .format(DateFormatsEnum.WrittenDayMonthYear);
  }
}
