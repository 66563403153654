import IFields from '../components/Form/IFields';

const FittingLocationContactArr: IFields = [
  {
    title: 'Contact Information',
    name: 'name',
    type: 'input',
    inputType: 'text',
    label: 'Contact Name (Optional)',
    notRequired: true,
  },
  {
    name: 'phone',
    type: 'input',
    inputType: 'phone',
    label: 'Phone Number',
    notRequired: true,
  },
  {
    name: 'website',
    type: 'input',
    inputType: 'text',
    label: 'Website',
    notRequired: true,
  },
];

export default FittingLocationContactArr;
