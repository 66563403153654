import styles from './OrderDetail.module.scss';
import React from 'react';
import IOrder from '../../../../stores/orders/models/IOrder';
import Value from 'components/Value/Value';
import { Link } from 'react-router-dom';
import RouteEnum from '../../../../constants/RouteEnum';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import BillToShipTo from 'components/BillToShipTo/BillToShipTo';
interface IProps {
  order: IOrder;
  billToAddress?: any;
}

const CARD_LABELS = ['Order Number', 'Order Date', 'Purchaser', 'PO Number'];
const CARD_VALUE_PROPS = ['orderNumber', 'orderDate', 'purchaserName', 'orderPurchaseOrder'];

interface ISectionProps {
  label: string;
  valueProp: string;
}

function OrderDetailSection({ label, order, valueProp }: ISectionProps & IProps): JSX.Element {
  const orderObject = {
    orderNumber: order.orderNumber,
    orderDate: order.orderDate,
    purchaserName: order.purchaser,
    orderPurchaseOrder: order.purchaseOrderNumber,
  };
  let value = orderObject[valueProp];

  if (valueProp === 'invoiceNumber') {
    value = (
      <AccessControl allowedRole={UserRolesEnum.ViewTransactions} noAccessRender={() => orderObject[valueProp]}>
        <Link to={`${RouteEnum.Transactions}/${orderObject[valueProp]}`}>{orderObject[valueProp]}</Link>
      </AccessControl>
    );
  }

  return (
    <div className={`${styles.section} ${styles[`section--${valueProp}`]}`}>
      <div className={styles.section__label}>{label}</div>
      <Value className={styles.section__value} value={value} propertyName={valueProp} />
    </div>
  );
}

function OrderDetail({ order, billToAddress }: IProps): JSX.Element {
  return (
    <div className={styles['order-detail']}>
      <div className={styles['order-detail__hd']}>
        <h3 className='hdg--3'>Order Detail</h3>
      </div>

      <div className={styles['order-detail__hd']}>
        {billToAddress && <BillToShipTo shippingAddress={!order.dropShipInformation.address1 ? billToAddress : order.dropShipInformation} />}
      </div>

      <div className={styles['order-detail__bd']}>
        {CARD_LABELS.map((label, index) => {
          return <OrderDetailSection label={label} order={order} valueProp={CARD_VALUE_PROPS[index]} key={`order-detail-section-${index}`} />;
        })}
      </div>
    </div>
  );
}

export default OrderDetail;
