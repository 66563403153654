import { MAIN_CONFIG } from 'configurations/mainConfig';

export class DropShipStateModel {
  public name: string;
  public businessName: string;
  public attention: string | null;
  public address1: string;
  public address2: string | null;
  public city: string;
  public state: string;
  public zip: string;
  public country: string;

  /**
   * Used in form to reference property names
   */
  public static readonly PropertyNames = {
    NAME: 'name',
    BUSINESS: 'businessName',
    ATTENTION: 'attention',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
    COUNTRY: 'country',
  };

  constructor(data?: DropShipStateModel | any) {
    if (data) {
      this.name = data.name;
      this.businessName = data.businessName;
      this.attention = data.attention;
      this.address1 = data.address1;
      this.address2 = data.address2;
      this.city = data.city;
      this.state = data.state;
      this.zip = data.zip;
      this.country = MAIN_CONFIG.CreateOrder.OrgID;
    } else {
      this.name = '';
      this.businessName = '';
      this.attention = '';
      this.address1 = '';
      this.address2 = '';
      this.city = '';
      this.state = '';
      this.country = MAIN_CONFIG.CreateOrder.OrgID;
    }
  }
}
