import React, { useEffect, useState } from 'react';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import OrderForm from './components/orderForm/OrderForm';
import { useHistory } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import IAccount from 'stores/accounts/models/IAccount';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import AccountsAction from 'stores/accounts/AccountsAction';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import TabStateHelpers from './helpers/TabStateHelpers';
import SubmittedListForDependencies from './components/productConfig/helpers/SubmittedListForDependencies';
import { pageLoader } from 'components/PageLoader/PageLoader';

export const SUBMITTED_LIST_INSTANCE = new SubmittedListForDependencies();

const CreateOrderPage = () => {
  const history = useHistory();
  const originalHistory = useHistory();
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const [isOpen, setOpen] = useState<any>(false);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const addresses: IAccountAddresses | null = useSelector((state: IStore) => oc(state).accounts.addresses(null));

  useEffect(() => {
    setOpen(false);
    if (accountNumber) {
      dispatch(CreateOrderAction.getCart(accountNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accountNumber) {
      // redirectToAccounts();
    } else {
      dispatch(AccountsAction.requestAccountAddresses(accountNumber));
    }

    return function cleanup() {
      TabStateHelpers.resetTabState(dispatch);
    };
    // eslint-disable-next-line
  }, [accountNumber]); //@ts-ignore

  if (!activeAccount) {
    return pageLoader(activeAccount);
  }

  // let pathName = history.location.pathname;
  return (
    <AccessControl allowedRole={UserRolesEnum.ManageOrders} noAccessRedirect={false}>
      <div className='wrapper'>
        <ArrowLink position='back' classNames='vr1_5' to={RouteEnum.Orders}>
          Back to Orders
        </ArrowLink>
        <OrderForm
          originalHistory={originalHistory}
          accountNumber={accountNumber}
          addresses={addresses}
          history={history}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </div>
    </AccessControl>
  );
};

export default CreateOrderPage;
