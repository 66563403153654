import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import EffectUtility from 'utilities/EffectUtility';
import ContactRequestModel from './models/Contact/ContactRequestModel';
import ContactResponseModel from './models/Contact/ContactResponseModel';
import UpdateContactRequestModel from './models/Contact/UpdateContactRequestModel';

export default class LocationContactUtility {
  public static async requestContactByLocationId(locationId: string): Promise<ContactResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).contacts}/location/${locationId}`;
    return EffectUtility.getToModel<ContactResponseModel>(ContactResponseModel, endpoint);
  }
  public static async updateContactById(
    contactId: string,
    updateRequestBody: UpdateContactRequestModel
  ): Promise<ContactResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).contacts}/${contactId}`;
    return EffectUtility.putToModelBody<ContactResponseModel>(ContactResponseModel, endpoint, updateRequestBody);
  }

  public static async createContactByLocation(requestBody: ContactRequestModel): Promise<ContactResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).contacts}`;
    return EffectUtility.postToModelBody<ContactResponseModel>(ContactResponseModel, endpoint, requestBody);
  }
}
