import * as React from 'react';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';

interface IBillingAndShippingProps {
  addresses: IAccountAddresses | undefined;
}

function BillingAndShipping({ addresses }: IBillingAndShippingProps): JSX.Element | null {
  if (!addresses) {
    return null;
  }

  const shippingAddress = {
    name: addresses.attention,
    line1: addresses.address1,
    line2: addresses.address2,
    city: addresses.city,
    state: addresses.state,
    zip: addresses.zip,
  };

  const shippingAddressMarkup = (
    <p>
      <span>
        {shippingAddress.name}
        <br />
      </span>
      <span>
        {shippingAddress.line1}
        <br />
      </span>
      {shippingAddress.line2 ? (
        <span>
          {shippingAddress.line2}
          <br />
        </span>
      ) : null}
      <span>
        {shippingAddress.city} {shippingAddress.state}, {shippingAddress.zip}
      </span>
    </p>
  );

  const billingAddress = null;

  return (
    <div className='tiles__tile tiles__tile--has-card'>
      <h3 className='hdg hdg--3'>Billing &amp; Shipping</h3>
      <div className='tiles__tile__card'>
        <div className='vr vr1'></div>
        {billingAddress && (
          <>
            <div>
              <h4 className='hdg hdg--4'>Billing Address</h4>
              {billingAddress}
            </div>
          </>
        )}
        {billingAddress && shippingAddress && (
          <>
            <div className='vr vr3'></div>
            <hr className='hr' />
            <div className='vr vr3'></div>
          </>
        )}
        {shippingAddress && (
          <>
            <div>
              <h4 className='hdg hdg--4'>Shipping Address</h4>
              {shippingAddressMarkup}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default BillingAndShipping;
