import INavigationState from './models/INavigationState';
import IAction from '../../models/IAction';
import NavigationAction from './NavigationAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class NavigationReducer extends BaseReducer<INavigationState> {
  public readonly initialState: INavigationState = {
    currentAndPreviousPaths: {
      current: '/',
      previous: '',
    },
  };

  public [NavigationAction.UPDATE_CURRENT_AND_PREVIOUS_PATHS](state: INavigationState, action: IAction<string>): INavigationState {
    return {
      ...state,
      currentAndPreviousPaths: {
        current: action.payload as string,
        previous: state.currentAndPreviousPaths.current,
      },
    };
  }
}
