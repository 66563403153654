import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import React from 'react';
import { ReactComponent as ErrorIcon } from 'assets/media/icons/icon-error.svg';
import styles from './ErrorCard.module.scss';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import UserRolesEnum from 'constants/UserRolesEnum';
import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';

interface IProps {
  response: HttpErrorResponseModel;
  formPreview?: any;
  onLight?: boolean;
  customHeading?: string;
  customText?: string;
}

const ErrorCard = (props: IProps) => {
  const { response, formPreview, onLight, customHeading, customText } = props;
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  return (
    <div className={`${styles['error-card']} ${onLight && styles['error-card--on-light']} `}>
      <div className={styles['error-card__icon-container']}>
        <ErrorIcon className={styles['error-card__icon']} />
      </div>
      <div className={styles['error-card__message-container']}>
        <div className={styles['error-card__error-message']}>
          {currentUser?.data.memberships.find((item) => item.key !== UserRolesEnum.PingAdmin) && customHeading ? (
            <h4 className='hdg--4'>{customHeading}</h4>
          ) : (
            <h4 className='hdg--4'>
              An Error Occurred {response.statusCode}. {response.userMessage}
            </h4>
          )}
        </div>
        <div className={styles['error-card__secondary-message']}>
          {customText ? (
            <p>{customText}</p>
          ) : (
            <p>
              <PingCustomerServiceContact /> {`[Error Code: ${response.eventCode}]`}
            </p>
          )}
        </div>
        {currentUser?.data.memberships.find((item) => item.key === UserRolesEnum.PingAdmin) && (
          <>
            <div className='vr2'></div>
            <div>
              <h5 className='hdg--5'>Only Visible to Admins:</h5>
              <br />
              <p>
                {/* <span className='bold'> RequestUrl: If debugging products please include the below url:</span> <br /> {response.raw.config.url} */}
              </p>
              {formPreview && (
                <div className={styles['error-card__form-preview']}>
                  <div className='vr2'></div>
                  <div className='bold'> Form Preview: Please include when debugging products </div>
                  {formPreview.map((item) => {
                    return (
                      <div key={item.attributeName}>
                        {item.attributeName} : {item.selectedValue}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorCard;
