import React from 'react';
import styles from './SubNavMenu.module.scss';
import { NavLink } from 'react-router-dom';

const SubNavMenu = (subItems) => {
  return (
    <ul className={styles.dropdownContent}>
      {subItems.subItems.map((item, index) => {
        return (
          <>
            {(item.FEATUREFLAG || typeof item.FEATUREFLAG === 'undefined') && (
              <li className={styles.subNavMenu__Link} key={`${item.NAME}-${index}`}>
                <NavLink to={item.URL} activeClassName='active' className={`${styles.navMenu_Link}`}>
                  {item.NAME}
                </NavLink>
              </li>
            )}
          </>
        );
      })}
    </ul>
  );
};
export default SubNavMenu;
