import UserMembershipsEnum from './UserMembershipsEnum';
import UserMembershipsNamesEnum from './UserMembershipsNamesEnum';

/**
 * The values here need to be exact without  This is being done in the AccountsEffect before being sent.
 *
 * Please do not change the order as it is deciding the role that a user can create.
 * Users can create roles under them if they have the manage users permissiopns
 */

const UserRoleOptionsArr = [
  { value: UserMembershipsEnum.PingAdmin, label: UserMembershipsNamesEnum.PingAdmin, name: 'user_membership' },
  { value: UserMembershipsEnum.Sales, label: UserMembershipsNamesEnum.Sales, name: 'user_membership' },
  { value: UserMembershipsEnum.CorporateUser, label: UserMembershipsNamesEnum.CorporateUser, name: 'user_membership' },
  { value: UserMembershipsEnum.Distributor, label: UserMembershipsNamesEnum.Distributor, name: 'user_membership' },
  { value: UserMembershipsEnum.Account, label: UserMembershipsNamesEnum.Account, name: 'user_membership' },
  { value: UserMembershipsEnum.IntlProAccount, label: UserMembershipsNamesEnum.IntlProAccount, name: 'user_membership' },
  { value: UserMembershipsEnum.EmployeeBilling, label: UserMembershipsNamesEnum.EmployeeBilling, name: 'user_membership' },
  { value: UserMembershipsEnum.Employee, label: UserMembershipsNamesEnum.Employee, name: 'user_membership' },
  { value: UserMembershipsEnum.Fitter, label: UserMembershipsNamesEnum.Fitter, name: 'user_membership' },
];

export default UserRoleOptionsArr;
