import { LANG } from '../LangKeys';

export const en = {
  common: {
    cool: 'test',
  },
  dashBoard: {
    [LANG.goodMorning]: 'good morning',
    [LANG.goodAfternoon]: 'good afternoon',
    [LANG.goodEvening]: 'good evening',
  },
};
