import React, { useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserRolesEnum from 'constants/UserRolesEnum';
import AccessControl from 'components/AccessControl/AccessControl';
import styles from './LocationInfoPage.module.scss';
import LocationDetails from './components/LocationDetails/LocationDetails';
import CertificationTab from 'containers/LocationInfoPage/components/CertificationTab/CertificationTab';
import EnvironmentTab from './components/EnvironmentTab/EnvironmentTab';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import TechnologyTab from './components/TechnologyTab/TechnologyTab';
import { LocationTabStateConstants, LocationTabs } from 'utilities/Location/models/ILocationTabState';
const LocationInfoPage = () => {
  const { locationTabState, setLocationTabStateProperty } = useContext(LocationContext);

  const componentObj = {
    addressDetails: (a) => <LocationDetails {...a} />,
    certifications: (a) => <CertificationTab {...a} />,
    environment: (a) => <EnvironmentTab {...a} />,
    technology: (a) => <TechnologyTab {...a} />,
  };

  const getUIforSection = (type: string, propsObj: object): JSX.Element => {
    return componentObj[type]?.(propsObj) ?? <div>UI config error!{type}</div>;
  };

  const handleSetTabIndex = (index: number) => {
    if (setLocationTabStateProperty) {
      setLocationTabStateProperty(LocationTabStateConstants.tabIndex, index);
    }
  };

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewFittingResources} noAccessRedirect={true}>
      <Tabs className={styles.locationTab_header} selectedIndex={locationTabState.tabIndex} onSelect={(index) => handleSetTabIndex(index)}>
        <TabList className={styles.locationTab_tabList}>
          {LocationTabs.map((locationTab) => {
            return (
              <Tab key={`${locationTab.name} tab`} className={styles.locationTab_tab}>
                {locationTab.displayName}
              </Tab>
            );
          })}
        </TabList>
        {LocationTabs.map((locationTab) => {
          return (
            <TabPanel key={`${locationTab.name} tabPanel`} className={styles.locationTab_panel}>
              {getUIforSection(locationTab.name, { type: locationTab.name, statePropertyName: locationTab.statePropertyName })}
            </TabPanel>
          );
        })}
      </Tabs>
    </AccessControl>
  );
};

export default LocationInfoPage;
