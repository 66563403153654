import styles from './UsersList.module.scss';
import React from 'react';
import { useTable, usePagination } from 'react-table';
import UserCard from '../UserCard/UserCard';
import AccessControl from 'components/AccessControl/AccessControl';
import { NavLink } from 'react-router-dom';
import UserRolesEnum from 'constants/UserRolesEnum';
import RouteEnum from 'constants/RouteEnum';
import IUser from 'stores/users/models/IUser';
import Pagination from 'components/Pagination/Pagination';
import UserMembershipsEnum from 'constants/UserMembershipsEnum';
import UserMembershipsNamesEnum from 'constants/UserMembershipsNamesEnum';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import Error4xx from 'components/Error4xx/Error4xx';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import UsersAction, { IRolesFilterState } from 'stores/users/UsersAction';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { Dispatch } from 'redux';
import { IUserV3 } from 'stores/users/models/IUserV3';
interface IProps {
  users: IUserV3[] | null | any;
  currentUser: IUser;
  userMembership: UsersRequestModel['membership'];
  onNewPageClick: (page: number) => void;
  onRolesFilterChange: (userMembership: string) => void;
  fromAdmin?: boolean;
  isLoading: boolean;
  usersList4xxErrorResponses?: any;
  errorStoreToCheck: string;
}

const UsersList = ({
  users,
  currentUser,
  userMembership,
  onNewPageClick,
  onRolesFilterChange,
  fromAdmin,
  usersList4xxErrorResponses,
  errorStoreToCheck,
  isLoading,
}: IProps) => {
  const userList = users?.data?.map((i) => i.kenticoUser) || [];
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => state.users.rolesFilters);
  const userFilterType = fromAdmin ? 'adminPanelFilter' : 'manageUserFilter';
  const actualRolesFilter = rolesFilter ? rolesFilter[userFilterType] : '';
  const errorStore = errorStoreToCheck === 'manage' ? 'UsersAction.REQUEST_ACCOUNT_USERS_FINISHED' : 'UsersAction.REQUEST_USERS_FINISHED';
  const dispatch: Dispatch = useDispatch();
  const columns = [
    {
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Role',
      accessor: 'memberships.key',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const updateResults = (userMembership: string) => {
    const userFilterType = fromAdmin ? 'adminPanelFilter' : 'manageUserFilter';
    const userFilterObj: IRolesFilterState = {
      ...rolesFilter,
      [userFilterType]: userMembership,
    };

    dispatch(UsersAction.setUserRolesFilter(userFilterObj));
    onRolesFilterChange(userMembership);
  };

  const { getTableProps, headerGroups } = useTable(
    {
      columns,
      data: userList,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: 1,
    },
    usePagination
  );

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewUsers}>
      <section className=''>
        <div className='split split--centered'>
          <div>
            <h3 className='hdg hdg--3'>Users</h3>
          </div>
          <div>
            <div className={'split split--centered'}>
              <div>
                {/* TODO: Replace (i) icon when Sketch licensing fixed */}
                <AccessControl allowedRole={UserRolesEnum.ManageUsers}>
                  <NavLink to={RouteEnum.UserRolesOverlay} className={styles['user-roles-btn']}>
                    (i) Description of User Roles
                  </NavLink>
                </AccessControl>
              </div>
              <div>
                <select
                  className={`table__filter btn focus-ring ${styles['filter-btn']}`}
                  data-is-selected={Boolean(userMembership)}
                  onChange={(e) => updateResults(e.target.value)}
                  value={actualRolesFilter}
                >
                  <option value=''>All</option>
                  {currentUser.permissions.canViewMembership.map((membership, i) => {
                    const membershipKey = Object.keys(UserMembershipsEnum).filter((key) => UserMembershipsEnum[key] === membership)[0];
                    return (
                      <option key={membership + i} value={membership}>
                        {UserMembershipsNamesEnum[membershipKey]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
        ) : (
          <Error4xx response={usersList4xxErrorResponses[errorStore]} textOnly>
            <table className={styles['user-list-table']} {...getTableProps()}>
              <thead className={'visually-hidden'}>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    key={`headerGroup-${i}`}
                    className={`${styles['user-list-table__row']} ${styles['user-list-table__row--hd']}`}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th className={` ${styles['user-list-table__row__cell']} `} scope='col' key={column.id}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {userList.map((user, i) => {
                  return <UserCard key={i} rowData={user} currentUser={currentUser} fromAdmin={fromAdmin} />;
                })}
              </tbody>
            </table>
            {!(userList.length < 1) && <Pagination data={users} onNewPageClick={onNewPageClick} />}
          </Error4xx>
        )}
      </section>
    </AccessControl>
  );
};
export default UsersList;
