import styles from './Modal.module.scss';

import * as React from 'react';
import ReactDOM from 'react-dom';
import AccessibilityUtility from '../../utilities/AccessibilityUtility';
import classNames from 'classnames';

interface IProps {
  isFullScreen?: boolean;
  onClose?: () => void;
  onRight?: boolean;
  isSmaller?: boolean;
}
interface IState {
  unMounting: boolean;
}

class Modal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    isFullScreen: true,
    isSmaller: false,
  };

  public state: IState = {
    unMounting: false,
  };
  public container: HTMLDivElement;
  public el: HTMLDivElement;

  constructor(props) {
    super(props);
    this.container = document.createElement('div');
    this.container.setAttribute('role', 'dialog');
    this.container.setAttribute('aria-modal', 'true');
    if (props['aria-labelledby']) {
      this.container.setAttribute('aria-labelledby', props['aria-labelledby']);
    }
    this.container.id = 'modal-root';
  }

  componentDidMount() {
    document.body.appendChild(this.container);
    document.addEventListener('keyup', this.onKeyUp);
    AccessibilityUtility.freezeBodyScrolling();
    AccessibilityUtility.focusToElement(document.getElementById('closeBtn'));
  }

  componentWillUnmount() {
    document.body.removeChild(this.container);
    // document.body.classList.remove('modal-open');
    document.removeEventListener('keyup', this.onKeyUp);
    AccessibilityUtility.unFreezeBodyScrolling();
  }

  public onKeyUp = (event) => {
    AccessibilityUtility.actOnEscapePress(event, this.onCloseClick);
  };

  public close() {
    // 1. Update state to append exit animation class
    this.setState({
      unMounting: true,
    });

    // 2. Call handler
    if (this.props.onClose) {
      setTimeout(this.props.onClose, 150);
    }
  }

  public onCloseClick = () => {
    this.close();
  };

  public render(): JSX.Element {
    const { isFullScreen } = this.props;
    const { isSmaller } = this.props;
    const { unMounting } = this.state;

    const containerClass = classNames(styles.modalContainer, {
      [styles['modalContainer--smol']]: !isFullScreen,
      [styles['modalContainer--smol_smaller']]: isSmaller,
      [styles.unMounting]: unMounting,
    });

    return ReactDOM.createPortal(
      <div className={containerClass}>
        {this.props.children}
        {this.props.onClose && (
          <button
            aria-label='Close'
            id='closeBtn'
            onClick={this.onCloseClick}
            className={`${styles.modalContainerCloseBtn} ${this.props.onRight ? styles['modalContainerCloseBtn--on-right'] : ''}`}
          >
            &times;
          </button>
        )}
      </div>,
      this.container
    );
  }
}

export default Modal;
