export default class AccessibilityUtility {
  public static actOnEscapePress(event: KeyboardEvent, callback: () => void): void {
    if (event.keyCode === 27 || event.key === 'Escape') {
      callback();
    }
  }

  public static freezeBodyScrolling() {
    document.body.classList.add('modal-open');
  }

  public static unFreezeBodyScrolling() {
    document.body.classList.remove('modal-open');
  }

  public static focusToElement(el: HTMLElement | null) {
    if (el) {
      el.focus();
    }
  }
}
