import { BaseModel } from 'sjs-base-model';
import { Shipping } from './IUpdateCart';

export default class UpdateCartRequestModel extends BaseModel {
  AccountNumber: number;
  CartId: number;
  Shipping: Shipping;
  PoNumber: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
