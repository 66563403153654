import * as React from 'react';
import LinkListItem from '../LinkListItem/LinkListItem';

interface IProps {
  heading?: string;
  list: any;
  documentClick: (r: string, i: string, v: string) => void;
  setIsLoading: (v: boolean) => void;
}

export const LinkListCard = ({ heading, list, documentClick, setIsLoading }: IProps): JSX.Element => {
  return (
    <div className='card'>
      {heading && <h2 className='hdg hdg--3'>{heading}</h2>}
      <div className='columns columns--2'>
        <ul>
          {list.map((item, i) => (
            <LinkListItem setIsLoading={setIsLoading} documentClick={documentClick} item={item} key={i} />
          ))}
          {list.length === 0 && <p>{heading} contains no items.</p>}
        </ul>
      </div>
    </div>
  );
};
export default LinkListCard;
