import styles from './AccountsOverlay.module.scss';
import { checkRoles } from 'components/AccessControl/AccessControl';
import Title from 'components/Title/Title';
import { push } from 'connected-react-router';
import AccountsColumnNamesEnum from 'constants/AccountsColumnNamesEnum';
import RouteEnum from 'constants/RouteEnum';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import UserRolesEnum from 'constants/UserRolesEnum';
import AccountsSearch from 'containers/AccountsOverlay/components/AccountsSearch/AccountsSearch';
import AccountsTable from 'containers/AccountsOverlay/components/AccountsTable/AccountsTable';
import IStore from 'models/IStore';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import AccountsAction from 'stores/accounts/AccountsAction';
import IAccount from 'stores/accounts/models/IAccount';
import IAccountsResponse from 'stores/accounts/models/IAccountsResponse';
import InvoicesAction from 'stores/invoices/InvoicesAction';
import OrdersAction from 'stores/orders/OrdersAction';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { oc } from 'ts-optchain.macro';
import HttpUtility from 'utilities/HttpUtility';
import uuid from 'uuid';
import IDataTableUIState from 'stores/dataTable/models/IDataTableUIState';
import debounce from 'lodash.debounce';
import { isEqual } from 'lodash';
import { PREVIOUS_INVOICES_CONFIG } from 'containers/TransactionsPage/TransactionsPage';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { KenticoUser } from 'stores/users/models/IUserV3';
import UsersAction from 'stores/users/UsersAction';
interface IState {
  ui: IDataTableUIState;
}

interface IProps {
  userToLink?: KenticoUser;
  closeModal?: any;
  linkedAccounts?: IAccount[];
}

class AccountsPreviousState {
  public filter = '-1'; // state must start differnet the the default so it makes the initial call

  public setFilter(state) {
    this.filter = state;
  }
}

const PREVIOUS = new AccountsPreviousState();

const AccountsOverlay = ({ userToLink, closeModal, linkedAccounts }: IProps) => {
  const dispatch: Dispatch = useDispatch();
  const accountsResponse: IAccountsResponse = useSelector((state: IStore) => state.accounts.accountsResponse);
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const [state, setState] = useState<IState>({
    ui: {
      filter: '',
      sortData: {
        Column: AccountsColumnNamesEnum.AccountNumber,
        SortDirection: SortDirectionEnum.ASC,
      },
    },
  });
  const [isLoading] = useState<boolean>(false);

  const onNewPageClick = (page: number): void => {
    const { currentPageSize } = accountsResponse;
    const { filter, sortData } = state.ui;
    dispatch(AccountsAction.requestAccountResponse(currentPageSize, page, filter, sortData));
  };

  const callApi = (updatingSearch?: boolean, newState?: any): void => {
    const { currentPageSize, currentPage } = accountsResponse;
    const { filter, sortData } = state.ui;
    const filterForCall = newState?.ui.filter ?? filter;
    const sortForCall = newState?.ui.sortData ?? sortData;
    dispatch(AccountsAction.requestAccountResponse(currentPageSize, updatingSearch ? 1 : currentPage, filterForCall, sortForCall));
  };

  const onSortClick = (sortCol: string): void => {
    const isDesc = state.ui.sortData.SortDirection === SortDirectionEnum.DESC;
    const isSameCol = state.ui.sortData.Column === sortCol;
    let sortDir;
    if (isSameCol) {
      sortDir = isDesc ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
    } else {
      sortDir = SortDirectionEnum.DESC;
    }

    setState({
      ui: {
        filter: state.ui.filter,
        sortData: {
          Column: sortCol,
          SortDirection: sortDir,
        },
      },
    });
  };

  const updateSearchCall = (newState?: any) => {
    callApi(true, newState);
  };

  const updateSearch = useCallback(
    debounce((newState: any) => updateSearchCall(newState), 750),
    []
  );

  const onSearchChange = (event: any): void => {
    const newState = Object.assign({}, state);
    const filter = event.target.value;
    newState.ui.filter = filter;
    setState(newState);
  };

  useEffect(() => {
    if (!isEqual(state.ui.filter, PREVIOUS.filter)) {
      const tempStateObj = state;
      updateSearch(tempStateObj);
      PREVIOUS.setFilter(state.ui.filter);
    } else {
      callApi(false); // no new search term
    }
    // eslint-disable-next-line
  }, [dispatch, state]);

  const onAccountClick = (e: React.SyntheticEvent, account: IAccount) => {
    e.preventDefault();
    const userHasDashboard = currentUser
      ? checkRoles(
          currentUser.data.roles,
          UserRolesEnum.ViewDashboard,
          Boolean(currentUser?.data?.memberships?.find((dm) => dm?.key === UserRolesEnum.PingAdmin))
        )
      : false;
    const token = uuid();
    HttpUtility.cancelCalls(token);

    dispatch(AccountsAction.setActiveAccount(account));
    dispatch(InvoicesAction.resetTransactions(null));
    dispatch(OrdersAction.resetOrders(null));
    dispatch(UsersAction.requestAccountUsers(null));
    dispatch(CreateOrderAction.getCart(null));
    dispatch(OrdersAction.saveOrdersFilters(null));
    dispatch(AccountsAction.requestAccountAddresses(null));
    PREVIOUS_INVOICES_CONFIG.setPreviousInvoicesConfig(null);

    if (!userHasDashboard) {
      dispatch(
        push({
          pathname: RouteEnum.AccountProfile,
        })
      );
    } else {
      dispatch(
        push({
          pathname: RouteEnum.Dashboard,
        })
      );
    }
  };

  if (isLoading) {
    return (
      <LoadingIndicator className={'full-height'} customLoadingText={'Linking and unlinking User from Accounts'} isActive={true} inverted={true} />
    );
  }

  return (
    <div>
      <div className={`${styles['acc-overview']} ${userToLink && styles['acc-overview--split']}`}>
        <div>
          <h1 id='modalTitle'>
            <Title title={'Select Account'} />
          </h1>
        </div>
        <div>
          <AccountsSearch onSearchChange={onSearchChange} filter={state.ui.filter}></AccountsSearch>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <AccountsTable
          onAccountClick={onAccountClick}
          onSortClick={onSortClick}
          onNewPageClick={onNewPageClick}
          accountData={accountsResponse}
          ui={state.ui}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
};

export default AccountsOverlay;
