import { MAIN_CONFIG } from 'configurations/mainConfig';
import { fieldsNotToBeDisplayedList } from 'constants/fieldsNotToBeDisplayed';
import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';

export default function getCurrentStep(newMasterFieldObject: IDynamicFormAttribute[], setError) {
  const newStep = newMasterFieldObject.find(
    (item) => item.inputDisplayFlag === 'Y' && item.selectedValue === null && !fieldsNotToBeDisplayedList.includes(item.attributeName)
  )?.attributeName;

  if (newStep) {
    return newStep;
  }

  let lastSelectableField: string = '';
  for (let i = newMasterFieldObject.length - 1; i >= 0; i--) {
    if (
      newMasterFieldObject[i].inputDisplayFlag === 'Y' &&
      !MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed.includes(newMasterFieldObject[i].attributeName)
    ) {
      lastSelectableField = newMasterFieldObject[i].attributeName;
      break;
    }
  }

  if (lastSelectableField) {
    return lastSelectableField;
  }

  setError('Something went wrong');
  return null;
}
