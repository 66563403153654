import { BaseModel } from 'sjs-base-model';
import ILocations from './ILocations';

export default class LocationResponseModel extends BaseModel {
  public readonly data: ILocations;
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<LocationResponseModel>): void {
    super.update(data);
  }
}
