import { BaseModel } from 'sjs-base-model';
import IOrdersSummary from './IOrdersSummary';
/*
    // Returned Api Data Sample
    {
      "currentOrders": 0,
      "pendingOrders": 0,
      "openOrders": 0,
      "recentlyShipped": 0,
    }
 */
export default class OrdersSummaryResponseModel extends BaseModel {
  public data: IOrdersSummary;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<OrdersSummaryResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<OrdersSummaryResponseModel>): void {
    super.update(data);
  }
}
