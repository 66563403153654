/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import ToastStatusEnum from 'constants/ToastStatusEnum';
import INotificationType from 'models/INotificationType';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ToastsAction from 'stores/toasts/ToastsAction';
import ErrorCard from './components/ErrorCard/ErrorCard';
import { Dispatch } from 'redux';

interface IProps {
  children?: any;
  response: any;
  textOnly?: boolean;
  formPreview?: any;
  onLight?: boolean;
  overrideText?: {
    headingText?: string;
    text?: string;
  };
}

const Error4xx = (props: IProps) => {
  const { response, textOnly, formPreview, onLight, overrideText } = props;
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (response?.errorNotificationType === INotificationType.Toast) {
      dispatch(ToastsAction.add(response.userMessage, ToastStatusEnum.Error));
    }
    // eslint-disable-next-line
  }, [response?.errorNotificationType]);
  const location = useLocation();
  const pathname = location.pathname;
  const pathNameErrorMsgOjb = {
    'pages/orders': 'No Orders to display',
    '/transactions': 'No Transactions to display',
    '/manage-users': 'No Users to display',
    '/admin-panel': 'No Users to display',
    '/accounts': 'No Accounts to display',
  };

  if (response === null || response === undefined) {
    return <div>{props.children}</div>;
  }

  if (response.statusCode === 200) {
    return <div>{props.children}</div>;
  }

  if ((response.statusCode === 404 || response.statusCode === 401) && textOnly) {
    return pathNameErrorMsgOjb[pathname] ?? response.message;
  }

  if (overrideText) {
    const checkOverride = () => {
      if (!response.errorNotificationType || response.errorNotificationType === INotificationType.Card) {
        if (overrideText.headingText && !overrideText.text) {
          return 'headingOnly';
        }
        if (!overrideText.headingText && overrideText.text) {
          return 'textOnly';
        }
        return 'both';
      }
      return 'toast';
    };
    const override = checkOverride();

    const overrideRender = {
      headingOnly: () => <ErrorCard customHeading={overrideText.headingText} response={response} onLight={onLight} formPreview={formPreview} />,
      textOnly: () => <ErrorCard customText={overrideText.text} response={response} onLight={onLight} formPreview={formPreview} />,
      both: () => (
        <ErrorCard
          customHeading={overrideText.headingText}
          customText={overrideText.text}
          response={response}
          onLight={onLight}
          formPreview={formPreview}
        />
      ),
      toast: () => <></>,
    };

    return overrideRender[override]();
  }

  return <ErrorCard response={response} onLight={onLight} formPreview={formPreview} />;
};

export default Error4xx;
