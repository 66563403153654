import styles from './OrderDetailPage.module.scss';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderDetail from './components/OrderDetail/OrderDetail';
import OrderItems from './components/OrderItems/OrderItems';
import IStore from '../../models/IStore';
import { useDispatch, useSelector } from 'react-redux';
import OrdersAction from '../../stores/orders/OrdersAction';
import { Dispatch } from 'redux';
import IOrder from '../../stores/orders/models/IOrder';
import { oc } from 'ts-optchain.macro';
import RouteEnum from '../../constants/RouteEnum';
import RouteNameEnum from '../../constants/RouteNameEnum';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import IAccount from 'stores/accounts/models/IAccount';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import { pageLoader } from 'components/PageLoader/PageLoader';
import Value from 'components/Value/Value';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import AccountsAction from 'stores/accounts/AccountsAction';
import OrderTracking from './components/OrderTracking/OrderTracking';

function OrderDetailPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const order: IOrder[] | undefined = useSelector((state: IStore) => oc(state).orders.orderResponse.data(undefined));
  const activeAccount: IAccount | null = useSelector((state: IStore) => state.accounts.activeAccount);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const addresses: IAccountAddresses | null = useSelector((state: IStore) => oc(state).accounts.addresses(null));
  const { id } = useParams<any>();
  const orders4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [OrdersAction.REQUEST_ORDER_FINISHED]
  );
  const [tracking, setTracking] = useState({
    activeHeaderId: null,
    trackingOpen: false,
    trackingList: [],
    currentOpenItemNumber: 0,
  });

  const getOrderStatus = (): string => {
    const statuses: string[] = [];
    order?.[0].items.forEach((item) => {
      if (!statuses.includes(item.itemStatus)) {
        statuses.push(item.itemStatus);
      }
    });

    if (statuses.length > 1) {
      return 'Mixed';
    }

    if (statuses.length === 1) {
      return statuses[0];
    }

    return '';
  };

  const orderStatus = getOrderStatus();

  useEffect(() => {
    if (id) {
      dispatch(OrdersAction.requestOrder(id, accountNumber));
    }

    if (!addresses && accountNumber) {
      dispatch(AccountsAction.requestAccountAddresses(accountNumber));
    }

    return function cleanup() {
      dispatch(OrdersAction.resetOrder(null));
    };
  }, [dispatch, id, accountNumber, addresses]);

  const currentAndPreviousPaths = useSelector((state: IStore) => oc(state).navigation.currentAndPreviousPaths({ current: '', previous: '' }));
  const cameFromDashboard = currentAndPreviousPaths.previous === '/dashboard';
  const backLinkURL = cameFromDashboard ? RouteEnum.Dashboard : RouteEnum.Orders;
  const backLinkText = `Back to ${cameFromDashboard ? RouteNameEnum.Dashboard : RouteNameEnum.Orders}`;

  useEffect(() => {
    const sectionToFocus = document.getElementById(`section-${tracking.activeHeaderId}`);
    sectionToFocus?.focus();
    sectionToFocus?.scrollIntoView();
  }, [tracking]);

  if (!activeAccount) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewOrders} noAccessRedirect={true}>
      <>
        <div className={`wrapper ${styles['order-detail-page']}`}>
          <ArrowLink position='back' classNames='vr1_5' to={backLinkURL}>
            {backLinkText}
          </ArrowLink>
          <Error4xx response={orders4xxResponses['OrdersAction.REQUEST_ORDER_FINISHED']}>
            {order ? (
              <>
                {order.map((order: IOrder, index) => {
                  return (
                    <>
                      <div className={styles['order-detail-page__hd']}>
                        <h2 className='hdg--2 vr2_5'>
                          Order Number - {id} - {order.orderHeaderId}
                        </h2>
                        <Value value={orderStatus} propertyName='orderStatus' />
                      </div>
                      <OrderDetail order={order!} billToAddress={addresses} />
                      <div tabIndex={-1} id={`section-${order.orderHeaderId}`} />
                      {tracking.trackingOpen && order.orderHeaderId === tracking.activeHeaderId ? (
                        <OrderTracking tracking={tracking} index={index} setTracking={setTracking} />
                      ) : (
                        <OrderItems order={order!} tracking={tracking} setTracking={setTracking} />
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
            )}
          </Error4xx>
        </div>
      </>
    </AccessControl>
  );
}

export default OrderDetailPage;
