import environment from 'environment';
import OrdersResponseModel from './models/OrdersResponseModel';
import OrdersSummaryResponseModel from './models/OrdersSummaryResponseModel';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import OrderResponseModel from './models/OrderResponseModel';

export default class OrdersEffect {
  public static async requestOrder(orderId, accountNumber: number): Promise<OrderResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).orders3}/${orderId}`;
    return EffectUtility.getToModel<OrderResponseModel>(OrderResponseModel, endpoint);
  }

  public static async requestOrders(requestOrdersConfig, accountNumber: number): Promise<OrdersResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).orders}/${accountNumber}`;

    return EffectUtility.getToModel<OrdersResponseModel>(OrdersResponseModel, endpoint, requestOrdersConfig);
  }

  public static async requestOrdersSummary(accountNumber, startDate, endDate): Promise<OrdersSummaryResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).orders}/${accountNumber}/summary?startDate=${startDate}&endDate=${endDate}`;
    return EffectUtility.getToModel<OrdersSummaryResponseModel>(OrdersSummaryResponseModel, endpoint);
  }
}
