import { BaseModel } from 'sjs-base-model';

export default class GetEstimatedDatesResponseModel extends BaseModel {
  public data: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
