import IAction from 'models/IAction';
import BaseReducer from 'utilities/BaseReducer';
import ISerialNumberProductState from './models/ISerialNumberProductState';
import SerialNumberProductResponseModel from './models/SerialNumberProductResponseModel';
import SerialNumberAction from './SerialNumberAction';

export default class SerialNumberReducter extends BaseReducer<ISerialNumberProductState> {
  public readonly initialState: ISerialNumberProductState = {
    serialNumberResponse: null,
  };

  public [SerialNumberAction.REQUEST_SERIAL_NUMBER_FINISHED](
    state: ISerialNumberProductState,
    action: IAction<SerialNumberProductResponseModel>
  ): ISerialNumberProductState {
    return {
      ...state,
      serialNumberResponse: action.payload!,
    };
  }
  public [SerialNumberAction.REQUEST_SERIAL_NUMBER_RESET](state, action): any {
    return {
      ...state,
      serialNumberResponse: action.payload!,
    };
  }
}
