/* eslint-disable new-cap */
import React from 'react';
import { History, UnregisterCallback } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import 'what-input';
import IAction from '../models/IAction';
import RouteEnum from '../constants/RouteEnum';
import AccountHeader from '../components/AccountHeader/AccountHeader';
import Toasts from '../components/Toasts/Toasts';
import PrimaryNav from '../components/PrimaryNav/PrimaryNav';
import NavigationAction from '../stores/navigation/NavigationAction';
import AccountsOverlayPage from './AccountsOverlay/AccountsOverlayPage';
import { scrollToPageTop } from '../utilities/scroll';
import './App.scss';
import Footer from '../components/Footer/Footer';
import RenderIfAuthorized from '../components/RenderIfAuthorized/RenderIfAuthorized';
import IdleTimer from 'react-idle-timer';
import TimerModal from '../components/TimerModal/TimerModal';
import environment from 'environment';

import NotFoundPage from './NotFoundPage/NotFoundPage';
import SelectRegionPage from './SelectRegionPage/SelectRegionPage';
import LoginPage from './LoginPage/LoginPage';
import OrdersPage from './OrdersPage/OrdersPage';
import OrderDetailPage from './OrderDetailPage/OrderDetailPage';
import SearchResultsPage from './SearchResultsPage/SearchResultsPage';
import TransactionsPage from './TransactionsPage/TransactionsPage';
import InvoicePage from './InvoicePage/InvoicePage';
import MemoPage from './MemoPage/MemoPage';
import PaymentDetailsPage from './PaymentDetailsPage/PaymentDetailsPage';
import PricingPage from './PricingPage/PricingPage';
import FittingPage from './FittingPage/FittingPage';
import SerialLookupPage from './SerialLookupPage/SerialLookupPage';
import SalesSupportPage from './SalesSupportPage/SalesSupportPage';
import FrequentAskedQuestionPage from './FrequentAskedQuestionPage/FrequentAskedQuestionPage';
import AccountProfilePage from './AccountProfilePage/AccountProfilePage';
import ContactPage from './ContactPage/ContactPage';
import UserRolesOverlay from './UserRolesOverlay/UserRolesOverlay';
import classNames from 'classnames';
import CreateOrderPage from './CreateOrderPage/CreateOrderPage';
import EditUserPage from './EditUsersPage/EditUsersPage';
import CreateUsersPage from './CreateUsersPage/CreateUsersPage';
import LocationInfoPage from './LocationInfoPage/LocationInfoPage';
import ProtectedRoute from 'components/ProtectedRoute';
import ManageUsersPage from './ManageUsers/ManageUsersPage';
import AdminPanelPage from './AdminPanelPage/AdminPanelPage';
import { MSAL_AUTH } from 'index';
import Error5xx from './Error5xx/Error5xx';
import DashBoardPage from './DashboardPage/DashboardPage';
import MaintenancePage from './MaintenancePage/MaintenancePage';
import LocationPage from './LocationPage/LocationPage';
import SetupLocation from './LocationPage/components/SetupLocation/SetupLocation';
import FeatureFlagAction from 'stores/feature-flag/FeatureFlagAction';
import FoundLocation from './LocationPage/components/FoundLocation/FoundLocation';
import Rollbar from 'rollbar';
import Five9OnlineChat from 'components/Five9OnlineChat/Five9OnlineChat';
import { LocationProvider } from 'contexts/fitting-management-locations/LocationContext';
import TagNameLookupPage from './TagNameLookupPage/TagNameLookupPage';

interface IProps {
  readonly history: History;
  readonly dispatch: Dispatch<IAction<any>>;
  readonly featureFlag: any;
}
interface IState {
  containerStyles: string;
  timeoutAfterIdle: number;
  secondsLeft: number;
  showTimerModal: boolean;
  isTimedOut: boolean;
  rollbar: Rollbar;
}

class App extends React.Component<IProps> {
  public historyUnlisten: UnregisterCallback;
  public isAuthenticated = MSAL_AUTH.hasAccount();
  public isPreAuthPage = this.props.history.location.pathname === RouteEnum.SelectRegion;
  public idleTimer: IdleTimer | null = null;
  public timerInterval: any; // Timeout
  public state: IState = {
    containerStyles: 'App-mainContent',
    timeoutAfterIdle: environment.intervals.logout,
    secondsLeft: 59,
    showTimerModal: false,
    isTimedOut: false,
    rollbar: new Rollbar({
      accessToken: environment.rollbar.token,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: environment.name,
    }),
  };

  private _onIdle = (e) => {
    if (this.state.isTimedOut) {
      MSAL_AUTH.logout();
    } else {
      if (this.idleTimer) {
        this.idleTimer.pause();
      }
      this.setState({ showTimerModal: true });
      this._timerInterval();
    }
  };

  private _timerInterval() {
    const now = new Date().getTime();
    const futureTime = new Date(now + 60000).getTime(); // +1 minute

    this.timerInterval = setInterval(() => {
      const nowCountDown = new Date().getTime();
      const distance = futureTime - nowCountDown;
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({ secondsLeft: seconds });

      if (seconds <= 0) {
        this.resetAllTimers();
        this.setState({
          isTimedOut: true,
        });
      }
    }, 1000);
  }

  public onClickLogout = () => {
    MSAL_AUTH.logout();
  };

  public onClickContinue = () => {
    this.resetAllTimers();
    this.setState({
      showTimerModal: false,
      secondsLeft: 59,
    });
  };

  public resetAllTimers() {
    if (this.idleTimer) {
      this.idleTimer.reset();
    }
    clearInterval(this.timerInterval);
  }

  public async componentDidMount() {
    this.historyUnlisten = this.props.history.listen(this.tasksToDoOnRouteChange);
    this.props.dispatch(FeatureFlagAction.requestFeatureFlag(this.props.featureFlag));
  }

  public componentWillUnmount() {
    this.historyUnlisten();
  }

  public tasksToDoOnRouteChange = (location: History.LocationState) => {
    scrollToPageTop();
    this.props.dispatch(NavigationAction.updateCurrentAndPreviousPaths(location.pathname));
  };

  public render(): JSX.Element {
    const containerStyles = classNames({
      'app-main-content': true,
      'app-main-content--pre-auth': this.isPreAuthPage,
      'app-main-content--no-auth': !this.isAuthenticated,
    });

    if (this.state.isTimedOut) {
      MSAL_AUTH.logout();
    }

    return (
      <>
        <Helmet>
          <meta name='ui-sha' content={process.env.REACT_APP_GIT_SHA} />
          <meta name='ui-version' content={process.env.REACT_APP_GIT_TAG} />
        </Helmet>
        {this.props.featureFlag.customer_service_online_chat ? <Five9OnlineChat /> : ''}
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this._onIdle}
          timeout={this.state.timeoutAfterIdle}
        />
        <ConnectedRouter history={this.props.history}>
          <RenderIfAuthorized>{!this.isPreAuthPage && <PrimaryNav />}</RenderIfAuthorized>
          <div className={containerStyles}>
            {this.state.showTimerModal && (
              <>
                <Redirect to={RouteEnum.Orders} />
                <TimerModal onClickContinue={this.onClickContinue} onClickLogout={this.onClickLogout} secondsLeft={this.state.secondsLeft} />
              </>
            )}
            <RenderIfAuthorized>{!this.isPreAuthPage && <AccountHeader />}</RenderIfAuthorized>
            <main id='main-view' className='app-main-content__main-view'>
              <LocationProvider>
                <Switch>
                  <ProtectedRoute exact={true} path={RouteEnum.Landing} component={AccountsOverlayPage} />
                  <Route path={RouteEnum.Login} component={LoginPage} />
                  <ProtectedRoute path={RouteEnum.Accounts} component={AccountsOverlayPage} />
                  <ProtectedRoute exact={true} path={RouteEnum.Orders} component={OrdersPage} />
                  <ProtectedRoute exact={true} path={RouteEnum.OrderTagName} component={TagNameLookupPage} />
                  <ProtectedRoute path={RouteEnum.Order} component={OrderDetailPage} />
                  <ProtectedRoute path={RouteEnum.CreateOrders} component={CreateOrderPage} />
                  <ProtectedRoute path={RouteEnum.Dashboard} component={DashBoardPage} />
                  <ProtectedRoute path={RouteEnum.SearchResults} component={SearchResultsPage} />
                  <ProtectedRoute exact={true} path={RouteEnum.Transactions} component={TransactionsPage} />
                  <ProtectedRoute exact={true} path={RouteEnum.Invoice} component={InvoicePage} />
                  <ProtectedRoute exact={true} path={RouteEnum.Memo} component={MemoPage} />
                  <ProtectedRoute exact={true} path={RouteEnum.Payment} component={PaymentDetailsPage} />
                  <ProtectedRoute path={`${RouteEnum.Pricing}`} component={PricingPage} />
                  <ProtectedRoute path={RouteEnum.Fitting} component={FittingPage} />
                  <ProtectedRoute
                    exact={true}
                    path={RouteEnum.FittingLocationSetup}
                    component={SetupLocation}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute
                    exact={true}
                    path={RouteEnum.FittingLocationFound}
                    component={FoundLocation}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute
                    exact={true}
                    path={RouteEnum.FittingLocationSetup}
                    component={SetupLocation}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute
                    exact={true}
                    path={RouteEnum.FittingLocationFound}
                    component={FoundLocation}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute
                    exact={true}
                    path={RouteEnum.FittingLocation}
                    component={LocationPage}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute
                    path={RouteEnum.FittingLocationDetail}
                    component={LocationInfoPage}
                    featureName='fitting_management_location_enabled'
                  />
                  <ProtectedRoute path={RouteEnum.AccountProfile} component={AccountProfilePage} />
                  <ProtectedRoute path={RouteEnum.SerialLookup} component={SerialLookupPage} />
                  <ProtectedRoute path={RouteEnum.SalesSupport} component={SalesSupportPage} />
                  <ProtectedRoute path={RouteEnum.FrequentAskedQuestion} component={FrequentAskedQuestionPage} />
                  <ProtectedRoute path={RouteEnum.ManageUsers} component={ManageUsersPage} />
                  <ProtectedRoute path={RouteEnum.AdminPanel} component={AdminPanelPage} />
                  <Route path={RouteEnum.UserRolesOverlay} component={UserRolesOverlay} />
                  <ProtectedRoute path={RouteEnum.EditUserPage} component={EditUserPage} />
                  <ProtectedRoute path={RouteEnum.CreateUsersPage} component={CreateUsersPage} />
                  <ProtectedRoute path={RouteEnum.CreateUsersPageAdmin} component={CreateUsersPage} />
                  <Route path={RouteEnum.SelectRegion} component={SelectRegionPage} />
                  <Route path={RouteEnum.Contact} component={ContactPage} />
                  <Route path={RouteEnum.Error500} component={Error5xx} />
                  <Route path={RouteEnum.Maintenance} component={MaintenancePage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </LocationProvider>

              <Toasts />
            </main>
            <RenderIfAuthorized>
              <Footer isInverse={this.isPreAuthPage} />
            </RenderIfAuthorized>
          </div>
        </ConnectedRouter>
      </>
    );
  }
}
export { App as Unconnected };
export default App;
