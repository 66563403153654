import { BaseModel } from 'sjs-base-model';
import IAccountPrograms from './IAccountPrograms';

export default class AccountProgramsResponseModel extends BaseModel {
  public readonly data: IAccountPrograms[];
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<AccountProgramsResponseModel>): void {
    super.update(data);
  }
}
