import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import generateFieldState from './generateFieldState';

interface IGenerateClubsField {
  masterFieldObject: IDynamicFormAttribute[];
  clubs: any;
  setMasterFieldObject: (fields) => void;
  setFieldState: (fields) => void;
  step: string;
  setStep: (step: string) => void;
}

/**
 * generates the clubs fields values.
 * @param param0 object
 */
export default function generateClubsField({ masterFieldObject, clubs, setMasterFieldObject, setFieldState, step, setStep }: IGenerateClubsField) {
  const reformatedClubsFields = clubs.map((item, index) => {
    return {
      attributeName: 'CLUBS',
      attributeValue: item,
      attributeType: 'CLUB',
      attributeNumber: index,
    };
  });

  const masterFieldObjectWithClubs = masterFieldObject.map((item) => {
    if (item.attributeType === 'CLUB') {
      return {
        ...item,
        attributeValues: reformatedClubsFields,
      };
    }

    return item;
  });

  const fields = generateFieldState(masterFieldObjectWithClubs, step, setStep);

  setMasterFieldObject(masterFieldObjectWithClubs);
  setFieldState(fields);

  /**
   * For testing
   */
  return reformatedClubsFields;
}
