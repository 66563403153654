export function getSelectHelperMessageByFieldName(selectName) {
  switch (selectName) {
    case 'CUSTOM FITTING FLAG':
      return 'Is this custom fit?';
    default:
      return '';
  }
}
export function getSelectErrorMessageByFieldName(selectName) {
  switch (selectName) {
    case 'CLUBS':
      return 'Please select club(s) to continue.';
    default:
      return '';
  }
}
