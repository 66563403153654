import React from 'react';
import InvoicesRequestModel from '../../../../stores/invoices/models/InvoicesRequestModel';
import TransactionTypeEnum from '../../../../constants/TransactionTypeEnum';
import TransactionStatusEnum from '../../../../constants/TransactionStatusEnum';
import DateFilter from 'components/DateFilter/DateFilter';
import TransactionPastDueEnum from 'constants/TransactionPastDueEnum';
interface ITableFilterProps {
  endDate: InvoicesRequestModel['endDate'];
  startDate: InvoicesRequestModel['startDate'];
  transactionType: InvoicesRequestModel['transactionType'];
  transactionStatus: InvoicesRequestModel['transactionStatus'];
  pastDue: InvoicesRequestModel['pastDue'];
  updateResults: Function;
}

function TransactionHistoryFilters({ endDate, startDate, transactionType, transactionStatus, pastDue, updateResults }: ITableFilterProps) {
  return (
    <>
      <div className='table__header__filters'>
        <select
          className='table__filter btn focus-ring'
          data-is-selected={Boolean(transactionType)}
          onChange={(e: any): void => updateResults({ transactionType: e.target.value })}
          value={transactionType}
        >
          {/* TODO: find out why first option does not trigger select due to default */}
          <option style={{ display: 'none' }} value={TransactionTypeEnum.ALL}>
            All Types
          </option>
          <option value={TransactionTypeEnum.ALL}>All Types</option>
          <option value={TransactionTypeEnum.INVOICE}>Invoice</option>
          <option value={TransactionTypeEnum.DEDIT_MEMO}>Debit Memo</option>
          <option value={TransactionTypeEnum.CREDIT_MEMO}>Credit Memo</option>
          <option value={TransactionTypeEnum.ADJUSTMENT}>Adjustment</option>
          <option value={TransactionTypeEnum.PAYMENT}>Payment</option>
          <option value={TransactionTypeEnum.INTEREST}>Interest</option>
        </select>
        <select
          className='table__filter table__filter--wide btn focus-ring'
          data-is-selected={Boolean(transactionStatus && transactionStatus !== 'all')}
          onChange={(e: any): void => updateResults({ transactionStatus: e.target.value })}
          value={transactionStatus}
        >
          <option style={{ display: 'none' }} value={TransactionStatusEnum.ALL}>
            Any Status
          </option>
          <option value={TransactionStatusEnum.ALL}>Any Status</option>
          <option value={TransactionStatusEnum.OPEN}>Open</option>
          <option value={TransactionStatusEnum.PREPAID}>Prepaid</option>
          <option value={TransactionStatusEnum.POST_PENDING}>Post Pending</option>
          <option value={TransactionStatusEnum.CLOSED}>Closed</option>
        </select>
        <select
          className='table__filter table__filter--wide btn focus-ring'
          data-is-selected={Boolean(pastDue)}
          onChange={(e: any): void => updateResults({ pastDue: e.target.value })}
          value={pastDue}
        >
          <option value={TransactionPastDueEnum.DO_NOT_FILTER}>All Due</option>
          <option value={TransactionPastDueEnum.PAST_DUE}>Past Due</option>
          <option value={TransactionPastDueEnum.NOT_PAST_DUE}>Current</option>
        </select>
        <DateFilter endDate={endDate} updateDates={updateResults} startDate={startDate} />
      </div>
    </>
  );
}

export default TransactionHistoryFilters;
