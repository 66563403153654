import IOrder from './IOrder';
import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      "data": IOrder,
    }
 */
export default class OrderResponseModel extends BaseModel {
  public readonly data: IOrder[];
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data) {
    super();

    // Calls the update method on BaseModel
    this.update(data);
  }
}
