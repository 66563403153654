import { BaseModel } from 'sjs-base-model';
import ICertificationCategory from './ICertficationCategory';

export default class CertificationCategoriesResponseModel extends BaseModel {
  public readonly data: ICertificationCategory[];
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<CertificationCategoriesResponseModel>): void {
    super.update(data);
  }
}
