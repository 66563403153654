/* eslint-disable react/prop-types */
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import CartStateHelpers from 'containers/CreateOrderPage/helpers/CartStateHelpers';
import IStore from 'models/IStore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IAccount from 'stores/accounts/models/IAccount';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { CartItemDTO } from 'stores/create-order/models/IGetCart';
import { oc } from 'ts-optchain.macro';
import styles from '../../OrderReviewTable.module.scss';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
// import RouteEnum from 'constants/RouteEnum';
interface IValue {
  item: string;
  frontEndDescription: string;
  itemId: number;
  onHandleItemDelete: (itemId: string, qty: string) => void;
  qty: string;
  tagName: string;
}
interface IProps {
  value: IValue;
  setOpen?: (v: boolean) => void;
}

function OrderReviewDescription(props) {
  const dispatch: Dispatch = useDispatch();
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const cartItems: CartItemDTO[] | undefined = useSelector((state: IStore) => state.createOrder.cartState?.cartItems);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const cartID = useSelector((state: IStore) => state.createOrder.cartState?.cart.cartID);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setOpen,
    value: { item, frontEndDescription, itemId, tagName },
  }: IProps = props;

  const deleteItem = (itemId) => {
    setIsLoading(true);
    if (cartItems?.length === 1) {
      CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID, setIsLoading);
      if (setOpen) {
        setOpen(false);
      }
      return;
    }
    if (accountNumber) {
      CartStatelessCalls.deleteCartItem(itemId).then(() => {
        dispatch(CreateOrderAction.getCart(accountNumber)).then(() => {
          setIsLoading(false);
          // TODO: ask chris about these toasts
          // dispatch(ToastsAction.add('Cart Item Deleted', ToastStatusEnum.Success));
        });
      });
    }
  };
  return (
    <div className={styles['order-review__description']}>
      {isLoading && <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 15 }} />}
      <div className={`${isLoading && 'visually-hidden'}`}>
        <h5 className='hdg--5'>{item}</h5>
        <br />
        {frontEndDescription}
        <div className='vr2' />
        <span style={{ fontStyle: 'italic' }}>Tag: {tagName}</span>
        <div className='vr2' />
        <div className={styles['order-review__edit-container']}>
          <button
            onClick={() => {
              if (setOpen) {
                setOpen(false);
              }

              TabStateHelpers.editItem({ dispatch, tabState, itemId, product: item });
            }}
            className='btn btn--no-style'
            style={{ display: 'inline' }}
          >
            <span>Edit</span>
          </button>
          <span style={{ color: 'gray', margin: '0 10px' }}>|</span>
          <button onClick={() => deleteItem(itemId)} className='btn btn--no-style' style={{ display: 'inline' }}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderReviewDescription;
