import environment from 'environment';
import AccountsResponseModel from './models/AccountsResponseModel';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import AccountAddressesResponseModel from './models/AccountAddressesResponseModel';
import AccountNotificationsResponseModel from './models/AccountNotificationsResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import AccountAddressesRequestModel from './models/AccountAddressesRequestModel';
import AccountNotificationsRequestModel from './models/AccountNotificationsRequestModel';
import AccountInfoResponseModel from './models/AccountInfoResponseModel';

export default class AccountsEffect {
  public static async requestAccountResponse(pageSize, page, filter, sortData): Promise<AccountsResponseModel | HttpErrorResponseModel> {
    const endpoint: string = environment.api.accounts;

    return EffectUtility.getToModel<AccountsResponseModel>(AccountsResponseModel, endpoint, { pageSize, page, filter, sortData });
  }

  /** TODO: CURRENTLY UNUSED */
  public static async requestAccountInfo(accountNumber: number): Promise<AccountInfoResponseModel | HttpErrorResponseModel> {
    // https://test.api.na.pinggolf.biz/api/v1/accounts/36888
    const endpoint: string = `${environment.api.accounts}/${accountNumber}`;

    return EffectUtility.getToModel<AccountInfoResponseModel>(AccountInfoResponseModel, endpoint);
  }

  public static async requestAccountAddresses(accountNumber: number): Promise<AccountAddressesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${environment.api.accounts}/${accountNumber}/addresses`;
    const requestModel: AccountAddressesRequestModel = new AccountAddressesRequestModel({ accountNumber });

    return EffectUtility.getToModel<AccountAddressesResponseModel>(AccountAddressesResponseModel, endpoint, requestModel);
  }

  public static async requestAccountNotifications(accountNumber): Promise<AccountNotificationsResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${environment.api.accounts}/${accountNumber}/notifications`;
    const requestModel: AccountNotificationsRequestModel = new AccountNotificationsRequestModel({ accountNumber });

    return EffectUtility.getToModel<AccountNotificationsResponseModel>(AccountNotificationsResponseModel, endpoint, requestModel);
  }
}
