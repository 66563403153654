import { BaseModel } from 'sjs-base-model';

export default class GetCartRequestModel extends BaseModel {
  accountNumber: number;
  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
