import React, { useEffect } from 'react';
declare var Five9SocialWidget: any;

const Five9OnlineChat = () => {
  useEffect(() => {
    const config = {
      type: 'chat',
      rootUrl: 'https://app.five9.com/consoles/',
      tenant: 'PING',
      title: 'PING Biz Chat',
      profiles: ['Chat - PING Biz'],
      showProfiles: false,
      theme: 'default-theme.css',
      showEmailButton: true,
      useBusinessHours: true,
    };

    const onScriptLoad = () => {
      Five9SocialWidget.addWidget(config);
    };

    const script = document.createElement('script');
    script.src = 'https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js';
    script.async = true;
    script.onload = () => onScriptLoad();
    document.body.appendChild(script);
  }, []);

  return <div></div>;
};
export default Five9OnlineChat;
