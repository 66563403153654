import SearchBox from 'components/GlobalSearch/SearchBox';
import React, { useEffect, useState } from 'react';
import { ReactComponent as MagnifyingGlass } from '../../assets/media/icons/icon-magnifying-glass.svg';
import searchStyles from 'components/GlobalSearch/GlobalSearch.module.scss';
import styles from './TagLookup.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import SearchAction from 'stores/search/SearchAction';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import IStore from 'models/IStore';
import IOrderItemByTagName from 'stores/search/models/IOrderItemByTagName';
import TagNameSearchResponseModel from 'stores/search/models/TagNameSearchResponseModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import OrderItemTable from './OrderItemTable';
import Error4xx from 'components/Error4xx/Error4xx';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import DateFilter from 'components/DateFilter/DateFilter';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import moment from 'moment';
import ErrorAction from 'stores/error/ErrorAction';
import OrderTracking from 'containers/OrderDetailPage/components/OrderTracking/OrderTracking';
import ITracking from 'utilities/models/ITracking';
import useAccountCurrency from 'utilities/useAccountCurrency';

const TagNameLookupPage = () => {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const accountCurrency = useAccountCurrency();
  const tagResponse: TagNameSearchResponseModel | null = useSelector((state: IStore) => oc(state).search.orderItemsByTagName(null));
  var matchingOrderItems: IOrderItemByTagName[] | undefined = tagResponse?.data;
  var tagNameSearch4xxError: HttpErrorResponseModel | undefined = selectRawErrors(
    useSelector((state: IStore) => state),
    [SearchAction.REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL_FINISHED]
  )[SearchAction.REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL_FINISHED];
  const today = moment();
  const [tagSearch, setTagSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [statusFilter, setStatusFilter] = useState<string>();
  const [endDate, setEndDate] = useState<Date>(today.toDate());
  const [startDate, setStartDate] = useState<Date>(today.subtract(120, 'd').toDate());
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<HttpErrorResponseModel | null>();
  const [orderItems, setOrderItems] = useState<Array<IOrderItemByTagName> | null>();
  const [searchString, setSearchString] = useState<string>();
  const [firstSearchComplete, setFirstSearchComplete] = useState<boolean>(false);
  const [tracking, setTracking] = useState<ITracking>({
    activeHeaderId: null,
    trackingOpen: false,
    trackingList: [],
    currentOpenItemNumber: 0,
  });
  useEffect(() => {
    setError(tagNameSearch4xxError);
  }, [tagNameSearch4xxError]);
  useEffect(() => {
    setOrderItems(matchingOrderItems);
  }, [matchingOrderItems]);
  useEffect(() => {
    if (orderItems || error) {
      setLoading(false);
      setFirstSearchComplete(true);
    }
  }, [orderItems, error]);
  useEffect(
    () => () => {
      // On unmount, clear search results
      dispatch(SearchAction.requestSearchReset(null));
      dispatch(ErrorAction.removeByKey(SearchAction.REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL_FINISHED));
    },
    // eslint-disable-next-line
    []
  );
  const resetTracking = () => {
    setTracking({
      activeHeaderId: null,
      trackingOpen: false,
      trackingList: [],
      currentOpenItemNumber: 0,
    });
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const onTagLookup = () => {
    if (tagSearch && accountNumber) {
      setSearchString(tagSearch);
      //TODO: What if a tag name contains a comma?
      var splitTagSearch = tagSearch.split(',');
      splitTagSearch = splitTagSearch.filter((tag) => tag.trim().length > 0);
      dispatch(SearchAction.requestSearchByTagNameResponseModel(splitTagSearch, accountNumber, page, pageSize, statusFilter, startDate, endDate));
      setLoading(true);
      setError(null);
      setOrderItems(null);
      resetTracking();
    }
  };
  useEffect(() => {
    onTagLookup();
    // eslint-disable-next-line
  }, [page, statusFilter, startDate, endDate]);
  const updateDateFilter = (e: any) => {
    setStartDate(e.startDate);
    setEndDate(e.endDate);
  };

  return (
    <div className='wrapper'>
      <h1 className='hdg hdg--1'>Tag Name Lookup</h1>
      <div className='card'>
        <div className={`search ${searchStyles['global-search']}`}>
          <SearchBox
            value={tagSearch}
            onChange={(e) => setTagSearch(e.target.value)}
            onSubmit={onTagLookup}
            placeholder='Type Tag...'
            buttonIcon={<MagnifyingGlass aria-hidden='true' focusable='false' role='img' />}
            disableInputPadding
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onTagLookup();
              }
            }}
          />
        </div>
      </div>
      {loading ? (
        <LoadingIndicator isActive={true} inverted />
      ) : (
        <div>
          <div className={`${styles['flex-space-between']} ${styles['search-details-container']}`}>
            {firstSearchComplete && (
              <div>
                <h3 className='hdg hdg--3'>Orders Matching "{searchString}"</h3>
                {error && error.statusCode === 404 && <p className={styles['error-message']}>No results found.</p>}
                <p>Tag Lookup results are displayed by Order Date. To view Orders older than 120 days, set date range using Custom Calendar.</p>
              </div>
            )}
            {firstSearchComplete && (
              <div className='table__header__filters__end__top'>
                <DateFilter endDate={endDate} updateDates={updateDateFilter} startDate={startDate} noDateSelectedText={`All Order Dates`} />
                <select
                  className='table__filter btn focus-ring'
                  data-is-selected={Boolean(statusFilter)}
                  onChange={(e: any): void => setStatusFilter(e.target.value)}
                  value={statusFilter}
                >
                  <option value=''>Any Status</option>
                  <option value='PRODUCTION'>Production</option>
                  <option value='BOXED'>Boxed</option>
                  <option value='SHIPPED'>Shipped</option>
                  <option value='CANCELLED'>Cancelled</option>
                  <option value='ON ORDER'>On Order</option>
                </select>
              </div>
            )}
          </div>
          {!error && firstSearchComplete && !tracking?.trackingOpen ? (
            <OrderItemTable
              data={orderItems}
              tagResponse={tagResponse}
              onNewPageClick={onPageChange}
              tracking={tracking}
              setTracking={setTracking}
              currency={accountCurrency}
            />
          ) : (
            error && error.statusCode !== 404 && <Error4xx response={error} />
          )}
          {!error && tracking?.trackingOpen && (
            <OrderTracking
              tracking={tracking}
              index={0}
              setTracking={setTracking}
              exitMessage={'Back to lookup'}
              onExit={() => resetTracking()}
              hideOrderDetails
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TagNameLookupPage;
