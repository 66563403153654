import { BaseModel } from 'sjs-base-model';
import IPDF from './IPDF';

export default class PDFResponseModel extends BaseModel {
  public readonly data: IPDF;
  public userMessage: any = null;
  public status: string = '';
  public statusCode: number = 0;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
