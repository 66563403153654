import styles from '../InvoicePage.module.scss';
import React from 'react';
import IInvoice from 'stores/invoices/models/IInvoice';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  invoiceData: IInvoice[];
}

const InvoiceTotals = ({ invoiceData }: IProps) => {
  const { invoiceTotal, taxAmount, documentSubTotal, paymentReceived, balanceDue, billingAddress, shippingAndHandling, currency } = invoiceData[0];
  return (
    <div className={styles['invoice-grid-total']}>
      <div className={styles['invoice-grid__total__item']}>Subtotal</div>
      <div className={styles['invoice-grid__total__item']}>{formatCurrency(currency, documentSubTotal)}</div>
      {taxAmount > 0 && (
        <>
          <div className={`${styles['invoice-grid__total__item']} ${styles['invoice-grid__total__item--thin']}`}>{billingAddress.state}</div>
          <div className={`${styles['invoice-grid__total__item']} ${styles['invoice-grid__total__item--thin']}`}>
            {formatCurrency(currency, taxAmount)}
          </div>
        </>
      )}
      <div className={`${styles['invoice-grid__total__item']} ${styles['invoice-grid__total__item--thin']}`}>Shipping & Handling</div>
      <div className={`${styles['invoice-grid__total__item']} ${styles['invoice-grid__total__item--thin']}`}>
        {formatCurrency(currency, shippingAndHandling)}
      </div>
      <div className={styles['invoice-grid__total__item']}>Total</div>
      <div className={styles['invoice-grid__total__item']}>{formatCurrency(currency, invoiceTotal)}</div>
      <div className={styles['invoice-grid__total__item']}>Payment Recd</div>
      <div className={styles['invoice-grid__total__item']}>- {formatCurrency(currency, paymentReceived)}</div>
      <div className={styles['invoice-grid__total__item']}>Balance Due</div>
      <div className={styles['invoice-grid__total__item']}>{formatCurrency(currency, balanceDue)}</div>
    </div>
  );
};

export default InvoiceTotals;
