import UserUtility from 'utilities/UserUtility/UserUtility';
import CreateUserRequestModel from 'utilities/UserUtility/models/CreateUserRequestModel';
import UserPreferredCultureEnum from 'constants/UserPreferredCultureEnum';
import UsersAction from 'stores/users/UsersAction';
import CreateUserToAccountsRequestModel from 'utilities/UserUtility/models/CreateUserToAccountsRequestModel';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import AccountsColumnNamesEnum from 'constants/AccountsColumnNamesEnum';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import { handleValidation } from 'containers/EditUsersPage/EditUsersHelpers';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

interface IUserRole {
  value: string;
  label: string;
  name: string;
}

export const handleSubmit = ({
  e,
  editRef,
  fromAdmin,
  setCreateLoading,
  formState,
  accountNumber,
  setError,
  dispatch,
  currentAndPreviousPaths,
  history,
  rolesFilter,
  setFormErrors,
}) => {
  e.preventDefault();

  const errors = handleValidation(formState, setFormErrors);

  if (errors.hasError) {
    return;
  }

  if (editRef.current.checkValidity()) {
    if (fromAdmin) {
      setCreateLoading(true);
      UserUtility.createUser(
        new CreateUserRequestModel({
          firstName: formState.first_name,
          lastName: formState.last_name,
          email: formState.email,
          userMembership: formState.user_membership,
          preferredCultureCode: UserPreferredCultureEnum.USEnglish,
        })
      ).then((res) => {
        if (res instanceof HttpErrorResponseModel) {
          setError(res);
          setCreateLoading(false);
          return;
        } else {
          dispatch(UsersAction.requestUsers(new UsersRequestModel({ membership: rolesFilter ? rolesFilter.adminPanelFilter : '' }))).then(() => {
            dispatch(ToastsAction.add('Created User', ToastStatusEnum.Success));
            history.push(currentAndPreviousPaths.previous);
          });
        }
      });
    } else if (accountNumber) {
      setCreateLoading(true);
      UserUtility.createUserToAccounts(
        new CreateUserToAccountsRequestModel({
          firstName: formState.first_name,
          lastName: formState.last_name,
          email: formState.email,
          userMembership: formState.user_membership,
          preferredCultureCode: UserPreferredCultureEnum.USEnglish,
          accountNumbers: [accountNumber],
        })
      ).then((res) => {
        if (res instanceof HttpErrorResponseModel) {
          setError(res);
          setCreateLoading(false);
          return;
        } else {
          const requestConfig = new UsersRequestModel({
            accountNumber,
            membership: rolesFilter ? rolesFilter.manageUserFilter : '',
            page: 1,
            pageSize: 5,
            sortData: {
              Column: AccountsColumnNamesEnum.AccountNumber,
              SortDirection: SortDirectionEnum.ASC,
            },
          });
          dispatch(UsersAction.requestAccountUsers(requestConfig)).then(() => {
            dispatch(ToastsAction.add('Created User', ToastStatusEnum.Success));
            history.push(currentAndPreviousPaths.previous);
          });
        }
      });
    }
  }
};

// TODO: is this needed any longer?
export function getCurrentUserAssignableRoles(UserRolesOptionsArr: IUserRole[], currentUser) {
  const membership = currentUser?.data.memberships[0].key;
  const currentUserIndex = UserRolesOptionsArr.findIndex((role) => membership === role.value);

  return UserRolesOptionsArr.filter((item: IUserRole, index) => index >= currentUserIndex);
}
