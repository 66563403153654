import { MAIN_CONFIG } from 'configurations/mainConfig';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import ITabState from 'stores/create-order/models/ITabState';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../components/productConfig/ProductConfig';

interface IEditItem {
  dispatch: any;
  tabState: ITabState;
  itemId: number;
  product: string;
}

export default class TabStateHelpers {
  public static handleReview(dispatch, tabState) {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: 2 }));
  }

  public static handleShipping(dispatch, tabState) {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: 1 }));
  }

  public static goToInitial(dispatch, tabState): any {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, product: MAIN_CONFIG.CreateOrder.NoProduct, tabIndex: 0, itemToEditId: null }));
    // (window as any).location.replace(`${environment.uri.baseUri as any}/create-order`);
  }

  public static resetTabState(dispatch): any {
    dispatch(
      CreateOrderAction.storeTabState({
        hasConfirm: false,
        hasItems: false,
        hasReview: false,
        hasShipping: false,
        tabIndex: 0,
        product: MAIN_CONFIG.CreateOrder.NoProduct,
        itemToEditId: null,
      })
    );
  }

  public static editItem({ dispatch, tabState, itemId, product }: IEditItem) {
    PRODUCT_CONFIG_LOCAL_STORE.isEditing = true;

    dispatch(
      CreateOrderAction.storeTabState({
        ...tabState,
        product,
        tabIndex: 0,
        itemToEditId: itemId,
      })
    );
  }
}
