import UsersEffect from './UsersEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import UserResponseModel from './models/UserResponseModel';
import ActionUtility from 'utilities/ActionUtility';
import AccountsResponseModel from 'stores/accounts/models/AccountsResponseModel';
import UserResponseModelV3 from './models/UserResponseModelV3';
import UsersRequestModel from './models/UsersRequestModel';
import UsersResponseModel from './models/UsersResponseModel';

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | UserResponseModel
  | UserResponseModelV3
  | UsersResponseModel
  | AccountsResponseModel
  | UsersResponseModel
  | IRolesFilterState
  | null;

export interface IRolesFilterState {
  adminPanelFilter?: string;
  manageUserFilter?: string;
}

export default class UsersAction {
  public static readonly REQUEST_USER_INFO: string = 'UsersAction.REQUEST_USER_INFO';
  public static readonly REQUEST_USERS: string = 'UsersAction.REQUEST_USERS';
  public static readonly REQUEST_USERS_FINISHED: string = 'UsersAction.REQUEST_USERS_FINISHED';
  public static readonly REQUEST_USER_ACCOUNTS: string = 'UsersAction.REQUEST_USER_ACCOUNTS';
  public static readonly REQUEST_USER_ACCOUNTS_FINISHED: string = 'UsersAction.REQUEST_USER_ACCOUNTS_FINISHED';
  public static readonly REQUEST_USER_INFO_FINISHED: string = 'UsersAction.REQUEST_USER_INFO_FINISHED';
  public static readonly RESET_USER_ACCOUNTS: string = 'UsersAction.RESET_USER_ACCOUNTS';
  public static readonly REQUEST_SET_CURRENT_USER: string = 'UsersAction.REQUEST_SET_CURRENT_USER';
  public static readonly REQUEST_SET_CURRENT_USER_FINISHED: string = 'UsersAction.REQUEST_SET_CURRENT_USER_FINISHED';
  public static readonly STORE_USERROLE_FILTER: string = 'UsersAction.STORE_USERROLE_FILTER';
  public static readonly REQUEST_USER_BY_ID: string = 'UsersAction.REQUEST_USER_BY_ID';
  public static readonly REQUEST_USER_BY_ID_FINISHED: string = 'UsersAction.REQUEST_USER_BY_ID_FINISHED';
  public static readonly REQUEST_ACCOUNT_USERS: string = 'UsersAction.REQUEST_ACCOUNT_USERS';
  public static readonly REQUEST_ACCOUNT_USERS_FINISHED: string = 'UsersAction.REQUEST_ACCOUNT_USERS_FINISHED';

  public static requestUserInfo(): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: UsersAction.REQUEST_USER_INFO });

      const model: UserResponseModel | HttpErrorResponseModel = await UsersEffect.requestUserInfo();

      dispatch({
        type: UsersAction.REQUEST_USER_INFO_FINISHED,
        payload: model,
        error: model instanceof HttpErrorResponseModel,
      });
    };
  }

  public static requestUserById(userId: string | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      if (userId === null) {
        dispatch({ type: UsersAction.REQUEST_USER_BY_ID_FINISHED, payload: null });
      } else {
        await ActionUtility.createThunkEffect<UserResponseModelV3>(dispatch, UsersAction.REQUEST_USER_BY_ID, UsersEffect.requestUserById, userId);
      }
    };
  }

  public static requestUserAccounts(userEmail: string | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      if (userEmail === null) {
        dispatch({ type: UsersAction.REQUEST_USER_ACCOUNTS_FINISHED, payload: null });
      } else {
        await ActionUtility.createThunkEffect<AccountsResponseModel>(
          dispatch,
          UsersAction.REQUEST_USER_ACCOUNTS,
          UsersEffect.requestUserAccounts,
          userEmail
        );
      }
    };
  }

  public static requestAccountUsers(UsersRequestConfig: UsersRequestModel | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion | null>, getState: () => IStore) => {
      if (UsersRequestConfig === null) {
        dispatch({ type: UsersAction.REQUEST_ACCOUNT_USERS_FINISHED, payload: null });
      } else {
        dispatch({ type: UsersAction.REQUEST_ACCOUNT_USERS });

        const responseModel: UsersResponseModel | HttpErrorResponseModel = await UsersEffect.requestAccountUsers(UsersRequestConfig);

        dispatch({
          type: UsersAction.REQUEST_ACCOUNT_USERS_FINISHED,
          payload: responseModel,
          error: responseModel instanceof HttpErrorResponseModel,
        });
      }
    };
  }

  public static resetUserAccounts(): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: UsersAction.RESET_USER_ACCOUNTS });
    };
  }

  public static setCurrentUser(token: string): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<UserResponseModel>(dispatch, UsersAction.REQUEST_SET_CURRENT_USER, UsersEffect.setCurrentUser, token);
    };
  }

  public static requestUsers(usersRequestConfig: UsersRequestModel | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion | null>, getState: () => IStore) => {
      if (usersRequestConfig === null) {
        dispatch({ type: UsersAction.REQUEST_USERS_FINISHED, payload: null });
      } else {
        await ActionUtility.createThunkEffect<UsersResponseModel>(dispatch, UsersAction.REQUEST_USERS, UsersEffect.requestUsers, {
          ...usersRequestConfig,
        });
      }
    };
  }

  public static setUserRolesFilter(payload: IRolesFilterState): any {
    return async (dispatch: any, getState: () => IStore) => {
      dispatch({ type: UsersAction.STORE_USERROLE_FILTER, payload });
    };
  }
}
