import React from 'react';
import styles from '../../OrderItems/OrderItems.module.scss';
import { getTrackingLink } from '../../OrderItems/helpers/OrderItemsHelperComps';
import { IOrderTracking } from 'stores/orders/models/IOrder';
import { DATE_HELPER } from 'index';
interface IProps {
  item: IOrderTracking;
}

const TrackingShipping = ({ item }: IProps) => {
  return (
    <div className={styles['trackingColumn']}>
      <h5 className='hdg hdg--5'>Box Number</h5>
      <p className='vr1_5'>{item.boxId}</p>
      <h5 className='hdg hdg--5'>Ship Date</h5>
      <p className='vr1_5'>{DATE_HELPER.getDisplayDate(item.shipDate)}</p>
      <h5 className='hdg hdg--5'>Shipping Instructions</h5>
      <p className='vr1_5'>{item.carrierName}</p>
      <h5 className='hdg hdg--5'>Tracking Number</h5>
      <p className='vr1_5'>{getTrackingLink(item.carrierName, item.trackingNumber) ?? <span>NA</span>}</p>
    </div>
  );
};

export default TrackingShipping;
