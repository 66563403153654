import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AccessControl from 'components/AccessControl/AccessControl';
import RouteEnum from 'constants/RouteEnum';
import UserRolesEnum from 'constants/UserRolesEnum';
import { ReactComponent as Plus } from '../../assets/media/icons/icon-plus.svg';
import { ReactComponent as MagicWand } from '../../assets/media/icons/icon-magic-wand.svg';
import CreateFirstLocation from './components/CreateFirstLocation/CreateFirstLocation';
import IStore from 'models/IStore';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import LocationTable from './components/LocationTable/LocationTable';
import styles from './LocationPage.module.scss';
import CreateLocationRequestModel from 'utilities/Location/models/CreateLocationRequestModel';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationUtility from 'utilities/Location/LocationUtility';
import { pageLoader } from 'components/PageLoader/PageLoader';
import LocationStatusUtility from 'utilities/Location/LocationStatusUtility';
import { LocationInfoErrorWrapped } from 'containers/LocationInfoPage/components/LocationInfoError/LocationInfoError';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import LocationSetupUtility from 'utilities/Location/LocationSetupUtility';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
import LocationsResponseModel from 'utilities/Location/models/LocationsResponseModel';

const LocationPage = () => {
  const history = useHistory();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const {
    accountLocations,
    statusTable,
    resetAllLocationStateAndTabState,
    updateAllLocationStateAndTabState,
    setStatusState,
    updateAccountLocations,
  } = useContext(LocationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ILocationInfoError>(initialError);

  useEffect(() => {
    if (resetAllLocationStateAndTabState) {
      resetAllLocationStateAndTabState();
    }
    if (setStatusState) {
      if (statusTable[0].id === '') {
        LocationStatusUtility.initStatus().then((response) => {
          if (response !== null) {
            setStatusState(response);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (accountNumber) {
      LocationUtility.requestLocationByAccount(accountNumber).then((response: LocationsResponseModel) => {
        updateAccountLocations && updateAccountLocations(response.data);
        setIsLoading(false);
        if (response instanceof HttpErrorResponseModel) {
          setError({ isError: true, message: LocationErrorMessages.GET_LOCATION });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  const handleAddLocation = () => {
    setIsLoading(true);
    const createRequestBody = new CreateLocationRequestModel({
      accountNumber: activeAccount?.accountNumber,
      name: activeAccount?.businessName ? activeAccount?.businessName : activeAccount?.accountName,
    });
    LocationUtility.createLocation(createRequestBody).then((response) => {
      if (response && response.data) {
        const createdLocation = response.data;
        LocationSetupUtility.setupAccountPrograms(createdLocation.id, createdLocation.accountNumber);
        updateAllLocationStateAndTabState &&
          updateAllLocationStateAndTabState(
            {
              id: createdLocation.id,
              name: createdLocation.name,
              displayHours: createdLocation.displayHours,
              regionId: createdLocation.regionId,
              statusId: createdLocation.statusId,
              hasAddress: false,
              certificationsVerified: createdLocation.certificationsVerified,
              environmentsVerified: createdLocation.environmentsVerified,
              technologiesVerified: createdLocation.technologiesVerified,
              displayOnMap: createdLocation.displayOnMap,
            },
            {
              hasAddress: false,
              hasCertifications: createdLocation.certificationsVerified,
              hasEnvironment: createdLocation.environmentsVerified,
              hasTechnology: createdLocation.technologiesVerified,
              tabIndex: 0,
            }
          );
        history.push(`${RouteEnum.FittingLocation}/${createdLocation.id}`);
      }
      if (response instanceof HttpErrorResponseModel) {
        setError({ isError: true, message: LocationErrorMessages.CREATE_LOCATION });
      }
      setIsLoading(false);
    });
  };

  if (isLoading) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewFittingResources}>
      <>
        {error.isError && <LocationInfoErrorWrapped message={error.message} />}
        {accountNumber ? (
          <div>
            {accountLocations?.length === 0 ? (
              <CreateFirstLocation />
            ) : (
              <div className='wrapper'>
                {statusTable[0].id !== '' ? (
                  <>
                    <div className='split'>
                      <div className={styles.locationPageTitle}>
                        <h2 className='hdg--1'>Locations</h2>
                        <span className={styles.locationPageTitle_toast}>{accountLocations?.length}</span>
                      </div>
                      <div className='split'>
                        <Link to={`${RouteEnum.FittingLocationSetup}`} className='btn--link'>
                          <MagicWand aria-hidden='true' focusable='false' role='img' className={styles.iconLink} />
                          <span className='btn__text'>Use Location Setup Wizard</span>
                        </Link>
                        <button onClick={handleAddLocation} className='btn'>
                          <span className='btn__text'>Add New Locations</span>
                          <span className='btn__icon'>
                            <Plus aria-hidden='true' focusable='false' role='img' />
                          </span>
                        </button>
                      </div>
                    </div>
                    <LocationTable isLoading={isLoading} locations={accountLocations} locations4xxResponse={error} />
                  </>
                ) : (
                  <LocationInfoErrorWrapped
                    message={
                      'We are sorry, but it looks like an error occurred and trying to retrieve statuses for location. Please try again later.'
                    }
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div className='wrapper'>
            <h1 className='hdg--1'>Please select an account</h1>
          </div>
        )}
      </>
    </AccessControl>
  );
};

export default LocationPage;
