import React from 'react';
interface DynamicTextFieldProps {
  name: any;
  label: string;
  placeHolder: string;
  required: boolean;
  handleChange: any;
  inputType: string;
  calculatedRef: any;
  formState: any;
  minimumQuantity?: number | null;
}
interface DynamicTextFieldState {}
class DynamicTextField extends React.Component<DynamicTextFieldProps, DynamicTextFieldState> {
  state = { value: '' };

  render() {
    const { name, formState, required, label, inputType, calculatedRef, handleChange, minimumQuantity } = this.props;
    const handleFocus = () => {
      calculatedRef.current.focus();
    };
    return (
      <div onClick={handleFocus} className={'form__input-container form__input-container--full'}>
        <label className={'form__label'} htmlFor={name}>
          {label}
        </label>
        <input
          required={required}
          className='form__input'
          id={name}
          ref={calculatedRef}
          onChange={handleChange(name)}
          value={formState[name]}
          type={inputType}
          name={name}
          min={minimumQuantity || 1}
        />
      </div>
    );
  }
}

export default DynamicTextField;
