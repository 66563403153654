import React from 'react';
import styles from './ProductCard.module.scss';
import { IProduct } from 'constants/ProductsArr';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import IStore from 'models/IStore';

interface IProps {
  product: IProduct;
}

function ProductCard(props: IProps): JSX.Element {
  const { product } = props;
  const dispatch: Dispatch = useDispatch();
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  // const productNameChanged = getProductChangesNames(product, true);

  // https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
  // const ProductImage = productIcons[Case.title(product.endpointProduct).replace(/ /g, '')];
  const ProductImage = product.iconName;

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        dispatch(CreateOrderAction.storeTabState({ ...tabState, product: product.endpointProduct }));
      }}
    >
      <div className={styles.content}>
        <div className={styles.imageWrap}>
          {ProductImage ? <ProductImage role='img' alt={`${product.displayName} Icon`} className={styles.image} aria-hidden='true' /> : ''}
        </div>
        <div className={styles.nameWrap}>
          <p className={styles.name}>
            <span>{product.displayName}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default ProductCard;
