import React, { useContext, useEffect, useState } from 'react';
import LocationTabTemplate from '../LocationTabTemplate/LocationTabTemplate';
import LocationCertificationUtility from '../../../../utilities/Location/LocationCertificationUtility';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationInfoFooter from '../LocationInfoFooter/LocationInfoFooter';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationUtility from 'utilities/Location/LocationUtility';
import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
interface IProps {
  type: string;
  statePropertyName: string;
}

const CertificationTab = ({ type, statePropertyName }: IProps): JSX.Element => {
  const [certificationCategories, setCertificationCategories] = useState([]);
  const [locationCertifications, setLocationCertifications] = useState([]);
  const { locationState, locationTabState, updateAllLocationState } = useContext(LocationContext);
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const errorMessage: string = 'Error loading certification categories.';

  const updateCertificationVerification = (isVerified: boolean) => {
    // TODO: refactor this
    const updateRequest = new UpdateLocationRequestModel({ certificationsVerified: isVerified });
    LocationUtility.updateLocation(locationState.id, updateRequest).then((response) => {
      if (response && response.data) {
        const updatedLocation = response.data;
        updateAllLocationState &&
          updateAllLocationState({
            id: updatedLocation.id,
            name: updatedLocation.name,
            displayHours: updatedLocation.displayHours,
            regionId: updatedLocation.regionId,
            statusId: updatedLocation.statusId,
            hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
            certificationsVerified: updatedLocation.certificationsVerified,
            environmentsVerified: updatedLocation.environmentsVerified,
            technologiesVerified: updatedLocation.technologiesVerified,
            displayOnMap: updatedLocation.displayOnMap,
          });
      } else {
      }
    });
  };

  const addCertificationToLocation = (certificationId: string) => {
    // call the createlocationCertification to create a specific certification on a specific location
    LocationCertificationUtility.createLocationCertification(locationState.id, certificationId);
  };

  const deleteCertificationToLocation = (certificationId: string) => {
    LocationCertificationUtility.deleteLocationCertification(locationState.id, certificationId);
  };

  useEffect(() => {
    LocationCertificationUtility.getLocationCertifications(locationState.id)
      .then((data) => {
        setLocationCertifications(data.data);
      })
      .catch((error) => {
        setIsError({ isError: true, message: errorMessage });
      });
  }, [setCertificationCategories, locationState.id]);

  useEffect(() => {
    if (certificationCategories.length === 0 && !isError.isError) {
      LocationCertificationUtility.getAllCertificationCategories()
        .then((data) => {
          if (data.length === 0) {
            setIsError({ isError: true, message: errorMessage });
          } else {
            setCertificationCategories(data);
          }
        })
        .catch((error) => {
          setIsError({ isError: true, message: errorMessage });
        });
    }
  }, [certificationCategories, isError]);

  return (
    <>
      <LocationTabTemplate
        categoryList={certificationCategories}
        tabName={type}
        itemListName={'certifications'}
        statePropertyName={statePropertyName}
        locationSpecificItems={locationCertifications}
        updateVerification={updateCertificationVerification}
        addLocationSpecificItem={addCertificationToLocation}
        deleteLocationSpecificItem={deleteCertificationToLocation}
        isError={isError}
      />
      <LocationInfoFooter tabName={type} statePropertyName={statePropertyName} tabIndex={locationTabState.tabIndex} />
    </>
  );
};

export default CertificationTab;
