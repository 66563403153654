import React from 'react';
import IDynamicNonMasterFormFields, { IDynamicFormFields } from 'stores/create-order/models/IDynamicFormModels';
import styles from './DynamicFormPanelFields.module.scss';
import { DATE_HELPER } from 'index';

interface IProps {
  fields: IDynamicFormFields[];
  nonMasterFormFields: IDynamicNonMasterFormFields[];
  nonMasterFieldState: any;
  exceptions: string[];
}

const DynamicFormPanelFields = ({ fields, nonMasterFieldState, nonMasterFormFields, exceptions }: IProps) => {
  return (
    <>
      {fields.map((field, i) => {
        // TODO: calling out by name if this name is changed it will need to change as well
        if (field.name === 'QTY') {
          const quantity: IDynamicNonMasterFormFields | undefined = nonMasterFormFields.find((item) => item.name === 'QTY');

          return (
            <>
              <div key={field.name} className={styles['preview-panel-grid__left']}>
                {quantity?.name}
              </div>
              <div key={`${field.name}right`} className={styles['preview-panel-grid__right']}>
                {nonMasterFieldState.QTY}
              </div>
            </>
          );
        }

        if (exceptions.includes(field.name)) {
          return null;
        }

        /**
         * Default return
         */
        return (
          <>
            <div key={field.name} className={styles['preview-panel-grid__left']}>
              {field.name}
            </div>
            <div key={`${field.name}right`} className={styles['preview-panel-grid__right']}>
              {field.selectedValue}
            </div>
            {field.estimatedDate && (
              <>
                <div>Estimated Date</div> <div className={styles['preview-panel-grid__right']}>{DATE_HELPER.getDisplayDate(field.estimatedDate)}</div>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default DynamicFormPanelFields;
