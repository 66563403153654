import { MSAL_AUTH } from 'index';
import IStore from 'models/IStore';
import { useSelector } from 'react-redux';
import UserResponseModel from 'stores/users/models/UserResponseModel';

export default function RenderIfAuthorized(props): JSX.Element | null {
  const isAuthorized = MSAL_AUTH.hasAccount();
  const activeAccount: UserResponseModel | null = useSelector((state: IStore) => state.users.currentUser);
  let auth = false;
  if (activeAccount !== null && activeAccount.data.roles.length > 0) {
    auth = true;
  }

  return isAuthorized && auth ? props.children : null;
}
