import { BaseModel } from 'sjs-base-model';
import IAccountAddresses from './IAccountAddresses';

export default class AccountAddressesResponseModel extends BaseModel {
  public readonly data: IAccountAddresses;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<AccountAddressesResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<AccountAddressesResponseModel>): void {
    super.update(data);
  }
}
