import { BaseModel } from 'sjs-base-model';
import { Shipping } from './IUpdateCart';

export default class UpdateCartResponseModel extends BaseModel {
  CartID: number;
  UserID: string;
  AccountID: number;
  Shipping: Shipping;
  PoNumber: string;
  SubmittedAt: Date;
  CreatedAt: Date;
  CreatedBy: string;
  CartLastModifiedBy: string;
  CartGuid: string;
  CartLastModified: Date;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
