import { BaseModel } from 'sjs-base-model';
import { IAddToCartConfiguration } from './IAddToCart';

export default class AddToCartRequestModel extends BaseModel {
  accountNumber: number;
  product: string;
  poNumber: string;
  quantity: number;
  tagName: string;
  configuration?: IAddToCartConfiguration[];
  currency: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
