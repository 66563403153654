import HttpUtility from 'utilities/HttpUtility';
import environment from 'environment';

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createPDFUrl(arrayBuffer) {
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
}

export function createAndDownloadBlobFile(body, filename, extension = 'pdf', openInTab = false) {
  const fileName = `${filename}`;
  const blob = extension === 'pdf' ? new Blob([body], { type: 'application/pdf' }) : new Blob([body]);

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      if (openInTab) {
        // TODO: IFrame option
        // const win = window.open('', 'yoooooooo', 'height=300, width=300');
        // const iframe = document.createElement('iframe');
        // const title = document.createElement('title');
        // const fileUrl = URL.createObjectURL(blob);
        // title.appendChild(document.createTextNode('Nice title :)'));

        // iframe.src = fileUrl;
        // iframe.width = '100%';
        // iframe.height = '100%';
        // iframe.style.border = 'none';

        // win && win.document.head.appendChild(title);
        // win && win.document.body.appendChild(iframe);

        // TODO: New Tab Option
        const pdfWindow = window.open();
        pdfWindow && (pdfWindow.location.href = url);
      } else {
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

export function downloadPDF(invoiceNumber: string, accountNumber: number | undefined, loadingSetState?: (v: boolean) => void) {
  const endpoint = `${(environment.api as any).transactions}/${accountNumber}/GetInvoicePdf?invoices=${invoiceNumber}`;
  // const invoiceArray = [invoiceNumber];
  if (accountNumber) {
    const response = async () => {
      if (loadingSetState) {
        loadingSetState(true);
      }
      return HttpUtility.post(endpoint);
    };
    response()
      .then((res) => {
        const data: string[] = res.data.data;
        const byteArray = data;

        byteArray.forEach((item) => {
          const arrayBuffer = base64ToArrayBuffer(item);
          createAndDownloadBlobFile(arrayBuffer, invoiceNumber, 'pdf');
        });

        if (loadingSetState) {
          loadingSetState(false);
        }
      })
      .catch((err) => console.log(err));
  }
}

export function downloadPDFs(pdfList: string[], accountNumber: number, loadingSetState?: (v: boolean) => void) {
  const endpoint: string = `${(environment.api as any).transactions}/${accountNumber}/GetInvoicePdf?invoices=${pdfList.join('&invoices=')}`;

  if (accountNumber) {
    const response = async () => {
      if (loadingSetState) {
        loadingSetState(true);
      }
      return HttpUtility.post(endpoint);
    };
    response()
      .then((res) => {
        const data: string[] = res.data.data;
        const byteArray = data;

        byteArray.forEach((item, index) => {
          const arrayBuffer = base64ToArrayBuffer(item);
          createAndDownloadBlobFile(arrayBuffer, pdfList[index], 'pdf');
        });
        if (loadingSetState) {
          loadingSetState(false);
        }
      })
      .catch((err) => console.log(err));
  }
}
