import React, { useState, FocusEvent, useRef, useEffect } from 'react';
import { ReactComponent as PlusIcon } from '../../../../../../assets/media/icons/icon-plus-alt.svg';
import { ReactComponent as NegativeIcon } from '../../../../../../assets/media/icons/icon-negative.svg';
import styles from '../../OrderReviewTable.module.scss';
import { CartItemDTO } from 'stores/create-order/models/IGetCart';
import IStore from 'models/IStore';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import AddToCartRequestModel from 'stores/create-order/models/AddToCartRequestModel';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { debounce } from 'lodash';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import DynamicFieldEnum from 'constants/DynamicFieldEnum';

interface IProps {
  value: {
    qty: number;
    itemId: number;
  };
}

function OrderReviewQuantity(props: IProps) {
  const {
    value: { qty, itemId },
  } = props;
  const dispatch: Dispatch = useDispatch();
  const cartItems: CartItemDTO[] | undefined = useSelector((state: IStore) => state.createOrder.cartState?.cartItems);
  const currentItem: CartItemDTO | undefined = cartItems?.find((item) => itemId === item.cartItemID);
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const inputRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uiQuantity, setUiQuantity] = useState({
    quantity: currentItem?.quantity.toString(),
    isChangingQty: false,
  });

  useEffect(() => {
    setUiQuantity({ ...uiQuantity, quantity: currentItem?.quantity.toString() });
    // eslint-disable-next-line
  }, [currentItem?.quantity]);

  const handleQtyDecrease = (): void => {
    setIsLoading(true);
    if (currentItem && accountNumber) {
      const request: AddToCartRequestModel = new AddToCartRequestModel({
        accountNumber,
        poNumber: '',
        product: currentItem.type,
        tagName: currentItem.tagName,
        quantity: currentItem.quantity - 1,
        currency: currentItem.currency,
      });
      CartStatelessCalls.updateCartItem({ cartItemId: itemId, cartItemRequest: request }).then(() => {
        dispatch(CreateOrderAction.getCart(accountNumber)).then(() => setIsLoading(false));
      });
    }
  };

  const handleQtyIncrease = (): void => {
    setIsLoading(true);
    if (currentItem && accountNumber) {
      const request: AddToCartRequestModel = new AddToCartRequestModel({
        accountNumber,
        poNumber: '',
        product: currentItem.type,
        tagName: currentItem.tagName,
        quantity: currentItem.quantity + 1,
        currency: currentItem.currency,
      });
      CartStatelessCalls.updateCartItem({ cartItemId: itemId, cartItemRequest: request }).then(() => {
        dispatch(CreateOrderAction.getCart(accountNumber)).then(() => setIsLoading(false));
      });
    }
  };

  const changeQuantity = (quantity: string): void => {
    if (currentItem && accountNumber && quantity !== currentItem.quantity.toString() && Number(quantity) > 0) {
      setIsLoading(true);
      setUiQuantity({ ...uiQuantity, isChangingQty: false });
      const request: AddToCartRequestModel = new AddToCartRequestModel({
        accountNumber,
        poNumber: '',
        product: currentItem.type,
        tagName: currentItem.tagName,
        quantity: Number(quantity),
        currency: currentItem.currency,
      });
      CartStatelessCalls.updateCartItem({ cartItemId: itemId, cartItemRequest: request }).then(() => {
        dispatch(CreateOrderAction.getCart(accountNumber)).then(() => setIsLoading(false));
      });
    } else {
      setUiQuantity({ quantity: currentItem?.quantity.toString(), isChangingQty: false });
    }
  };

  const debounceDecrease = debounce(handleQtyDecrease, 250);
  const debounceIncrease = debounce(handleQtyIncrease, 250);

  return (
    <div className={styles['order-review__qty']}>
      {isLoading && <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 15 }} />}
      <div className={`${isLoading && 'visually-hidden'}`}>
        <button
          disabled={qty <= 1}
          className={`${styles['order-review__pmbtn']} ${qty <= 1 && styles['order-review__pmbtn--disabled']}`}
          onClick={debounceDecrease}
        >
          <NegativeIcon height='7px' width='7px' aria-hidden='true' focusable='false' role='img' />
        </button>

        <input
          className={`${!uiQuantity.isChangingQty && 'visually-hidden'} ${styles['order-review__qty-input--isChanging']}`}
          name='quantity'
          ref={inputRef}
          type={DynamicFieldEnum.Number}
          onChange={(e) => setUiQuantity({ ...uiQuantity, quantity: e.target.value })}
          onBlur={(e: FocusEvent<HTMLInputElement>): void => changeQuantity(e.target.value)}
          value={uiQuantity.quantity}
          min='1'
        />

        <button
          className={`${uiQuantity.isChangingQty && 'visually-hidden'} ${styles['order-review__qty-input']}`}
          onClick={(e) => {
            e.preventDefault();
            setUiQuantity({ ...uiQuantity, isChangingQty: true });
            inputRef.current.focus();
          }}
        >
          {uiQuantity.quantity}
        </button>

        <button className={styles['order-review__pmbtn']} onClick={debounceIncrease}>
          <PlusIcon height='7px' width='7px' aria-hidden='true' focusable='false' role='img' />
        </button>
      </div>
    </div>
  );
}

export default OrderReviewQuantity;
