import React from 'react';
import styles from './GlobalSearch.module.scss';

interface ISearchBoxProps {
  placeholder: string;
  maxLength?: number;
  value: string | undefined;
  onChange: (e: any) => void;
  onSubmit: (e: any) => void;
  buttonIcon?: JSX.Element;
  disableInputPadding?: boolean;
  classNames?: string | undefined;
  onKeyDown?: (e: any) => void;
  disabled?: boolean;
}
const SearchBox = (props: ISearchBoxProps) => {
  const { placeholder, maxLength, value, onChange, onSubmit, buttonIcon, disableInputPadding, onKeyDown, disabled } = props;
  return (
    <>
      <input
        className={`search__input ${!disableInputPadding && styles['global-search__input']} focus-ring`}
        name='searchQuery'
        placeholder={placeholder}
        type='search'
        required
        maxLength={maxLength}
        value={value}
        onChange={(e) => onChange && onChange(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      />
      <button className={`btn focus-ring search__button ${disabled && 'btn--disabled'}`} onClick={onSubmit} disabled={disabled}>
        <div className={'search__button__content'}>{buttonIcon ? buttonIcon : 'Search'}</div>
      </button>
    </>
  );
};

export default SearchBox;
