import { BaseModel } from 'sjs-base-model';
import { IDynamicFormAttribute } from './IDynamicFormModels';

export default class GetPriceRequestModel extends BaseModel {
  accountNumber: number = 0;
  product: string = '';
  configuration: IDynamicFormAttribute[];

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
