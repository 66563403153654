import environment, { Environment } from './base';

// const baseRoute = 'https://na.pinggolfbiz.com';
// api.test.na.pingolfbiz.com
// const baseUri = 'https://stpinggolfbiztest.z5.web.core.windows.net';
// const baseApi = 'https://test.pinggolf.biz';

const baseUri = 'https://na.pinggolf.biz';
const baseApi = 'https://api.na.pinggolf.biz';
const baseFittingApi = 'https://locations.fittingmanagement.pingtechnology.digital';
const env = environment(baseUri, baseApi, baseFittingApi);
const tenantName = 'pingbizna';
const signInUserFlow = 'b2c_1_signin_v2';

const productionEnv: Environment = {
  ...env,
  // override anything that gets added from base.
  msalConfig: {
    auth: {
      clientId: '99d11e4c-67dc-4050-b3e0-67e4a2f444a4',
      redirectUri: baseUri,
      postLogoutRedirectUri: baseUri,
      authority: `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInUserFlow}`,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
    },
    cacheOptions: {
      cacheLocation: 'sessionStorage',
    },
  },
  msalLoginRequest: {
    scopes: ['openid', `https://${tenantName}.onmicrosoft.com/api/user_impersonation`],
  },
  isKentico: false,
  isDevelopment: false,
  isProduction: true,
  isTesting: false,
  isStage: false,
  gtm: 'GTM-WFKJNP3',
  googleMapsAPI: 'AIzaSyCOf-U6R9NHFtbXijXMxRx4nE2Twhojigk',
  geoCodeAPI: 'AIzaSyCWZAuslggj-lK9C73XCo1NM-a-69gYXgU',
  name: 'prod-na',
};

export default productionEnv;
