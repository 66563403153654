import './AccountHeader.scss';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../models/IStore';
import IAccount from '../../stores/accounts/models/IAccount';
import RouteEnum from '../../constants/RouteEnum';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowsSwitch } from '../../assets/media/icons/icon-arrows-switch.svg';
import { ReactComponent as ArrowDiagonal } from '../../assets/media/icons/icon-arrow-diagonal.svg';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
// TODO: This will be implemented in Phase 2
import { ReactComponent as Plus } from '../../assets/media/icons/icon-plus.svg';
import { oc } from 'ts-optchain.macro';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import { Dispatch } from 'redux';
import ITabState from 'stores/create-order/models/ITabState';
import FeatureFlagComponent from 'components/FeatureFlag/FeatureFlag';

const NULL_ACCOUNT_COPY = 'Select An Account';

function AccountHeader() {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const location = useLocation();
  const tabState: ITabState = useSelector((state: IStore) => state.createOrder.tabState);
  const isCreateOrderPage: boolean = location.pathname === RouteEnum.CreateOrders;

  const handleClick = (e: React.MouseEvent) => {
    if (isCreateOrderPage || !tabState) {
      e.preventDefault();
      return;
    }

    if (tabState.hasConfirm) {
      TabStateHelpers.resetTabState(dispatch);
      return;
    }

    TabStateHelpers.goToInitial(dispatch, tabState);
    // dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: 0, product: MAIN_CONFIG.CreateOrder.NoProduct }));
  };

  function checkToShowAccountName() {
    if (!activeAccount?.accountName) {
      return false;
    }

    if (location.pathname === RouteEnum.AdminPanel) {
      return false;
    }

    if (location.state?.superUser === true) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className='account-header'>
        <div className='account-header__col'>
          <NavLink to={RouteEnum.Accounts} className='btn'>
            <ArrowsSwitch aria-hidden='true' focusable='false' role='img' />
          </NavLink>
          <span>{checkToShowAccountName() ? activeAccount?.accountName : NULL_ACCOUNT_COPY}</span>
        </div>
        <div className='account-header__col'>
          {/* TODO: This will be implemented in Phase 2 */}
          <AccessControl allowedRole={UserRolesEnum.ManageOrders}>
            <NavLink to={RouteEnum.CreateOrders} onClick={(e) => handleClick(e)} className={isCreateOrderPage ? 'btn--disabled' : 'btn'}>
              <span className='btn__text'>Create Order</span>
              <span className={isCreateOrderPage ? 'btn__icon--disabled' : 'btn__icon'}>
                <Plus aria-hidden='true' focusable='false' role='img' />
              </span>
            </NavLink>
          </AccessControl>
          <FeatureFlagComponent name={'payment_integration'}>
            <AccessControl allowedRole={UserRolesEnum.ViewMakePayments}>
              <a href='https://www.pinggolf.biz/CSPNAWEBPAY' className='btn' target='_blank' rel='noopener noreferrer'>
                <span className='btn__text'>Make Payment</span>
                <span className='btn__icon'>
                  <ArrowDiagonal aria-hidden='true' focusable='false' role='img' />
                </span>
              </a>
            </AccessControl>
          </FeatureFlagComponent>
        </div>
      </div>
    </>
  );
}

export { AccountHeader as Unconnected };
export default AccountHeader;
