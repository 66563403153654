import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import LocationInfoError from 'containers/LocationInfoPage/components/LocationInfoError/LocationInfoError';
import ILocationInfoError from 'containers/LocationInfoPage/models/ILocationError';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import React, { useContext, useEffect, useState } from 'react';
import LocationSection from '../LocationSection/LocationSection';
import _ from 'lodash';
import IStatus from 'utilities/Location/models/IStatus';
import ILocations from 'utilities/Location/models/ILocations';

interface IProps {
  locations: ILocations[] | undefined;
  locations4xxResponse: ILocationInfoError;
  isLoading: boolean;
}

interface IStatusTableRow {
  name: string;
  description: string;
  locations: ILocations[];
}

type IStatusLocations = {
  [key: string]: IStatusTableRow;
};

const initStatusTableObjects: IStatusLocations = {
  active: {
    locations: [],
    description: '',
    name: '',
  },
  inactive: {
    locations: [],
    description: '',
    name: '',
  },
};

const LocationTable = ({ locations, locations4xxResponse, isLoading }: IProps): JSX.Element => {
  const { statusTable } = useContext(LocationContext);
  const [statusTableObjects, setStatusTableObjects] = useState<IStatusLocations>(initStatusTableObjects);

  useEffect(() => {
    sortStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const sortStatusList = () => {
    let sortedLocations: { [key: string]: ILocations[] } = {};
    locations?.forEach((location) => {
      if (sortedLocations[location.statusId]) {
        sortedLocations[location.statusId].push(location);
      } else {
        sortedLocations[location.statusId] = [location];
      }
    });
    mergeStatusLocations(sortedLocations);
  };

  const mergeStatusLocations = (sortedLocations: { [key: string]: ILocations[] }) => {
    var statusLocations = _.cloneDeep(initStatusTableObjects) as IStatusLocations;
    statusTable.forEach((status: IStatus) => {
      if (sortedLocations[status.id]) {
        if (status.name === 'active') {
          const updatedActiveStatusLocations = statusLocations['active'];
          updatedActiveStatusLocations.name = status.name;
          updatedActiveStatusLocations.description = status.description;
          updatedActiveStatusLocations.locations.push(...sortedLocations[status.id]);
          statusLocations['active'] = updatedActiveStatusLocations;
        }
        if (status.name === 'inactive' || status.name === 'draft') {
          const updatedInactiveStatusLocations = statusLocations['inactive'];
          if (status.name === 'inactive') {
            updatedInactiveStatusLocations.name = status.name;
            updatedInactiveStatusLocations.description = status.description;
            statusLocations['inactive'] = updatedInactiveStatusLocations;
          }
          updatedInactiveStatusLocations.locations.push(...sortedLocations[status.id]);
        }
      }
    });
    setStatusTableObjects(statusLocations);
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
      ) : (
        <>
          {locations4xxResponse.isError ? (
            <LocationInfoError message={locations4xxResponse.message} />
          ) : (
            <>
              {Object.keys(statusTableObjects).length > 0 &&
                Object.keys(statusTableObjects).map((statusName) => {
                  return (
                    <LocationSection
                      name={statusTableObjects[statusName].name}
                      description={statusTableObjects[statusName].description}
                      locations={statusTableObjects[statusName].locations}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LocationTable;
