import PaginatedResponseModel from '../../pagination/IPaginatedResponseModel';
import IInvoices from './IInvoices';

/*
    // Returned Api Data Sample
    {
      "data": IInvoice[],
      ...PaginatedResponseModel
    }
 */
export default class InvoicesResponseModel extends PaginatedResponseModel<IInvoices> {}
