import React, { useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import AccessibilityUtility from 'utilities/AccessibilityUtility';
import { NavLink } from 'react-router-dom';
import styles from '../OrderFooter/OrderFooter.module.scss';

interface IProps {
  handleCartClear: any;
  setModalState: any;
  to: any;
}

function CancelOrderModal({ handleCartClear, setModalState, to }: IProps): JSX.Element {
  useEffect(() => {
    setTimeout(() => {
      AccessibilityUtility.focusToElement(document.getElementById('cart-cancel-btn'));
    }, 0);
  }, []);

  return (
    <Modal onClose={() => setModalState(false)} isFullScreen={false} aria-labelledby='modalTitle' onRight>
      <div className={styles['order-footer__modal']}>
        <h1 className='hdg hdg--3' id='modalTitle'>
          Are you sure you want to cancel?
        </h1>
        <p>Canceling an order cannot be undone</p>

        <div className='vr vr10' />

        <div className='split'>
          <div className={styles['order-footer__cancel-container']}>
            <NavLink to={to} id='cart-cancel-btn' className={styles['df__cancel-btn']} onClick={() => handleCartClear()}>
              Cancel Order
            </NavLink>
          </div>
          <div>
            <button className='btn btn__text-only' onClick={() => setModalState(false)}>
              Return to order
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CancelOrderModal;
