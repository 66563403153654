import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IStore from 'models/IStore';
import RouteEnum from '../../../../constants/RouteEnum';
import UserRolesEnum from 'constants/UserRolesEnum';
import IFeatureFlag from 'stores/feature-flag/models/IFeatureFlag';
import styles from './PrimaryNavMenu.module.scss';
import PrimaryNavMenuItem from '../PrimaryNavMenuItem/PrimaryNavMenuItem';
import SubNavMenu from '../SubNavMenu/SubNavMenu';
import { ReactComponent as OrdersIcon } from '../../../../assets/media/icons/icon-orders.svg';
import { ReactComponent as DashboardIcon } from '../../../../assets/media/icons/icon-dashboard.svg';
import { ReactComponent as SupportIcon } from '../../../../assets/media/icons/icon-support.svg';
import { ReactComponent as PricingIcon } from '../../../../assets/media/icons/icon-pricing.svg';
import { ReactComponent as FittingIcon } from '../../../../assets/media/icons/icon-fitting.svg';
import { ReactComponent as ManageUsersIcon } from '../../../../assets/media/icons/icon-manage-users.svg';
import { ReactComponent as FrequentAskedQuestionIcon } from '../../../../assets/media/icons/icon-frequent-asked-questions.svg';
import { ReactComponent as TransactionsIcon } from '../../../../assets/media/icons/icon-invoices.svg';
import { ReactComponent as SerialLookupIcon } from '../../../../assets/media/icons/icon-serial-lookup.svg';
import PrimarySubNavMenuLink from '../PrimarySubNavMenuLink/PrimarySubNavMenuLink';

const PrimaryNavMenu = () => {
  const [navOpen, setOpenNav] = useState({});
  const feature: IFeatureFlag | null = useSelector((state: IStore) => state.featureFlag.featureFlagItems);
  const history = useHistory();

  const toggleMenu = (name: string) => {
    setOpenNav((prevState) => {
      return {
        ...prevState,
        [name]: !prevState[name],
      };
    });
  };

  const checkFeatureStatus = (name: string): boolean => {
    if (feature?.hasOwnProperty(name)) {
      return feature[name];
    }
    return false;
  };

  const PRIMARY_NAVIGATION_ITEMS = [
    {
      ICON: DashboardIcon,
      NAME: 'Dashboard',
      URL: RouteEnum.Dashboard,
      ACCESS: UserRolesEnum.ViewDashboard,
    },
    {
      ICON: OrdersIcon,
      NAME: 'Orders',
      URL: RouteEnum.Orders,
      ACCESS: UserRolesEnum.ViewOrders,
      SUBITEMS: [
        {
          NAME: 'View Orders',
          URL: `${RouteEnum.Orders}`,
        },
        {
          NAME: 'Tag Name Lookup',
          URL: `${RouteEnum.OrderTagName}`,
          FEATUREFLAG: checkFeatureStatus('tag_search'),
        },
      ],
    },
    {
      ICON: TransactionsIcon,
      NAME: 'Transactions',
      URL: RouteEnum.Transactions,
      ACCESS: UserRolesEnum.ViewTransactions,
    },
    {
      ICON: PricingIcon,
      NAME: 'Pricing',
      URL: `${RouteEnum.Pricing}`,
      ACCESS: UserRolesEnum.ViewPricingResources,
    },
    {
      ICON: FittingIcon,
      NAME: 'Fitting',
      ACCESS: UserRolesEnum.ViewFittingResources,
      URL: `${RouteEnum.Fitting}`,
      SUBITEMS: [
        {
          NAME: 'Locations',
          URL: `${RouteEnum.FittingLocation}`,
          FEATUREFLAG: checkFeatureStatus('fitting_management_location_enabled'),
        },
        {
          NAME: 'Events',
          URL: `${RouteEnum.FittingEvents}`,
          FEATUREFLAG: checkFeatureStatus('fitting_management_events_enabled'),
        },
        {
          NAME: 'Tools',
          URL: `${RouteEnum.Fitting}`,
        },
      ],
    },
    {
      ICON: SerialLookupIcon,
      NAME: 'Serial Lookup',
      URL: `${RouteEnum.SerialLookup}`,
      ACCESS: UserRolesEnum.ViewOrders,
      FEATUREFLAG: checkFeatureStatus('serial_lookup_enabled'),
    },
    {
      ICON: SupportIcon,
      NAME: 'Sales Support',
      URL: `${RouteEnum.SalesSupport}`,
      ACCESS: UserRolesEnum.ViewSalesSupportResources,
    },
    {
      ICON: FrequentAskedQuestionIcon,
      NAME: 'FAQ',
      URL: `${RouteEnum.FrequentAskedQuestion}`,
      ACCESS: UserRolesEnum.ViewFittingResources,
    },
    {
      ICON: ManageUsersIcon,
      NAME: 'Manage Users',
      URL: `${RouteEnum.ManageUsers}`,
      ACCESS: UserRolesEnum.ViewUsers,
    },
  ];

  return (
    <nav aria-label='Main' className={styles['primaryNav-Menu']}>
      {PRIMARY_NAVIGATION_ITEMS.map((NavItem, index) => {
        return NavItem.SUBITEMS && NavItem.SUBITEMS.length > 0 ? (
          <PrimarySubNavMenuLink
            key={index}
            path={history.location.pathname}
            activeState={navOpen[NavItem.NAME]}
            onLinkClick={() => toggleMenu(NavItem.NAME)}
            IconToggle={navOpen[NavItem.NAME]}
            Icon={NavItem.ICON}
            name={NavItem.NAME}
            access={NavItem.ACCESS}
            toggle={true}
          >
            {navOpen[NavItem.NAME] && <SubNavMenu subItems={NavItem.SUBITEMS} key={`${NavItem.NAME}-${index}`} />}
          </PrimarySubNavMenuLink>
        ) : (
          (NavItem.FEATUREFLAG || typeof NavItem.FEATUREFLAG === 'undefined') && <PrimaryNavMenuItem navItem={NavItem} key={NavItem.NAME} />
        );
      })}
    </nav>
  );
};

export default PrimaryNavMenu;
