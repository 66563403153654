/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styles from '../OrderFooter.module.scss';
import CancelOrderModal from '../../CancelOrderModal/CancelOrderModal';
import { ReactComponent as Plus } from 'assets/media/icons/icon-plus.svg';

interface IProps {
  to?: any;
  toNextStep: string;
  navlinkText?: string;
  buttonText?: string;
  navLinkOnClick?: any;
  buttonOnClick?: any;
  selectItemsStep?: boolean;
  disabledButton?: string;
  noTopMargin?: boolean;
  addMoreItems?: {
    handleAddMoreItems: () => void;
  };
}

interface IDisabledButtonProps {
  disabledButton?: string;
  buttonText?: string;
  buttonOnClick?: any;
}

const OrderFooterDisabledButton = ({ disabledButton, buttonText, buttonOnClick }: IDisabledButtonProps) => {
  return (
    <>
      {disabledButton ? (
        disabledButton === 'true' ? (
          <span className='btn btn__text-only btn--disabled'>{buttonText}</span>
        ) : (
          <div onClick={buttonOnClick}>
            <span className='btn btn__text-only'>{buttonText}</span>
          </div>
        )
      ) : (
        <div onClick={buttonOnClick}>
          <span className='btn btn__text-only'>{buttonText}</span>
        </div>
      )}
    </>
  );
};

const OrderFooterMainContainer = ({
  to,
  toNextStep,
  navlinkText,
  buttonText,
  navLinkOnClick,
  buttonOnClick,
  selectItemsStep,
  disabledButton,
  noTopMargin,
  addMoreItems,
}: IProps) => {
  const [modalState, setModalState] = useState(false);

  return (
    <>
      <hr className={`${noTopMargin ? styles['df__divider--no-top-margin'] : styles['df__divider']}`} />
      <div className={`split ${styles['df__footer-container']}`}>
        {selectItemsStep ? (
          <div onClick={navLinkOnClick} className={styles['df__cancel-btn']}>
            <span>{navlinkText}</span>
          </div>
        ) : (
          <span onClick={() => setModalState(true)} className={styles['df__cancel-btn']}>
            <span>{navlinkText}</span>
          </span>
        )}

        {modalState && !selectItemsStep ? <CancelOrderModal to={to} handleCartClear={navLinkOnClick} setModalState={setModalState} /> : null}

        {addMoreItems ? (
          <div style={{ display: 'inline-flex' }}>
            <div onClick={addMoreItems.handleAddMoreItems} className='btn' style={{ marginRight: 10 }}>
              <span className='btn__text'>Add More Items</span>
              <span className='btn__icon'>
                <Plus aria-hidden='true' focusable='false' role='img' />
              </span>
            </div>

            {buttonOnClick && <OrderFooterDisabledButton buttonOnClick={buttonOnClick} buttonText={buttonText} disabledButton={disabledButton} />}
          </div>
        ) : (
          <>{buttonOnClick && <OrderFooterDisabledButton buttonOnClick={buttonOnClick} buttonText={buttonText} disabledButton={disabledButton} />}</>
        )}
      </div>
    </>
  );
};

export default OrderFooterMainContainer;
