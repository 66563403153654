import environment, { Environment } from './base';

const baseUri = 'https://stage.eu.pinggolf.biz';
const baseApi = 'https://stage.api.eu.pinggolf.biz';
const baseFittingApi = 'https://stage.locations.fittingmanagement.pingtechnology.digital';
const env = environment(baseUri, baseApi, baseFittingApi);
const tenantName = 'pingbizna';
const signInUserFlow = 'b2c_1_signin_v2';

const stageEUEnv: Environment = {
  ...env,
  // override anything that gets added from base.
  msalConfig: {
    auth: {
      clientId: '99d11e4c-67dc-4050-b3e0-67e4a2f444a4',
      redirectUri: baseUri,
      postLogoutRedirectUri: baseUri,
      authority: `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInUserFlow}`,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
    },
    cacheOptions: {
      cacheLocation: 'sessionStorage',
    },
  },
  msalLoginRequest: {
    scopes: ['openid', `https://${tenantName}.onmicrosoft.com/api/user_impersonation`],
  },
  isKentico: false,
  isDevelopment: false,
  isProduction: false,
  isTesting: false,
  isStage: true,
  name: 'stage-eu',
  regionName: 'eu',
  defaultCurrency: 'GBP',
};

export default stageEUEnv;
