import { BaseModel } from 'sjs-base-model';

export default class DeleteLocationResponseModel extends BaseModel {
  public readonly data: [];
  public readonly statusCode: number;
  public readonly statusMessage: string;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<DeleteLocationResponseModel>): void {
    super.update(data);
  }
}
