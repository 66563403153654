import { NavLink, NavLinkProps } from 'react-router-dom';
import React from 'react';
import styles from './PrimaryNavMenuLink.module.scss';

interface IProps extends NavLinkProps {
  children?: JSX.Element | boolean;
  Icon?: Function;
  name?: string;
  to: string;
  onLinkClick?: () => void;
}
// eslint-disable-next-line valid-jsdoc
/**
 * If specifying `name` and `Icon`, returns a standard layout with Icon on left and text on right,
 * collapsing to just Icon when menu is collapsed.
 * Alternatively, you can pass in a custom display element as `children`.
 */
const PrimaryNavMenuLink = ({ children, Icon, name, to, onLinkClick }: IProps) => {
  return (
    <NavLink to={to} onClick={onLinkClick} activeClassName='active' className={`${styles.primaryNav_Menu_Link} focus-ring`}>
      {Icon && <Icon aria-hidden={true} focusable={false} role='img' />}
      {name && <span className={styles.primaryNav_Menu_Link_Text}>{name}</span>}
      {children}
    </NavLink>
  );
};

export default PrimaryNavMenuLink;
