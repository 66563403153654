import environment from 'environment';
import EffectUtility from '../EffectUtility';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import CreateLocationRequestModel from 'utilities/Location/models/CreateLocationRequestModel';
import UpdateLocationRequestModel from './models/Address/UpdateLocationRequestModel';
import DeleteLocationResponseModel from './DeleteLocationResponseModel';
import LocationsResponseModel from './models/LocationsResponseModel';
import LocationResponseModel from './models/LocationResponseModel';

export default class LocationUtility {
  public static baseLocationUri: string = `${(environment.api as any).location}`;

  /**
   * This method retrieves all locations by account number
   * @param accountNumber Requires an account number to get a list of locations by account
   * @returns
   */
  public static async requestLocationByAccount(accountNumber: number): Promise<LocationsResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).location}/account/${accountNumber}`;
    return EffectUtility.getToModel<LocationsResponseModel>(LocationsResponseModel, endpoint);
  }

  /**
   * This method creates a new location object
   * @param requestBody Requires name, environment and account Number
   */
  public static async createLocation(requestBody: CreateLocationRequestModel): Promise<LocationResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${LocationUtility.baseLocationUri}`;
    const response = await EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, requestBody);
    return response;
  }

  /**
   * This method retrieves a location object based on passing a locationId
   * @param locationId Requires locationId to retrieve location object
   */
  public static async getLocationByLocationId(locationId: string) {
    const endpoint: string = `${LocationUtility.baseLocationUri}/${locationId}`;
    const response = await EffectUtility.getToModel<LocationResponseModel>(LocationResponseModel, endpoint);
    return response;
  }

  /**
   * This method updates a location object based on passing a locationId and request body
   * @param locationId Requires locationId
   * @param requestBody Requires request body so update knows which property to update
   */
  public static async updateLocation(locationId: string, requestBody: UpdateLocationRequestModel) {
    const endpoint: string = `${LocationUtility.baseLocationUri}/${locationId}`;
    const response = await EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, requestBody);
    return response;
  }

  /**
   * This method deletes a location object based on passing a locationId - does a soft delete in the database
   * @param locationId Requires locationId
   */
  public static async deleteLocationById(locationId: string | undefined): Promise<DeleteLocationResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).location}/${locationId}`;
    return EffectUtility.deleteToModel<DeleteLocationResponseModel>(DeleteLocationResponseModel, endpoint);
  }
}
