/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseUri: string, baseApi: string, baseFittingApi: string) {
  const tenantName = 'pinggolfusb2ctest';
  const signInUserFlow = 'b2c_1_signin_v2';

  return {
    uri: {
      baseUri,
    },
    route: {
      baseRoute: '',
    },
    api: {
      users: `${baseApi}/api/v2/users`,
      users3: `${baseApi}/api/v3/users`,
      usersAccounts: `${baseApi}/api/v2/users/user-accounts`,
      login: `${baseApi}/api/v2/users/login`,
      accounts: `${baseApi}/api/v2/accounts`,
      orders: `${baseApi}/api/v2/orders`,
      orders3: `${baseApi}/api/v3/orders`,
      taxes: `${baseApi}/api/v2/taxes`,
      orderSummary: `${baseApi}/api/v2/account/order/summary`,
      transactions: `${baseApi}/api/v2/transactions`,
      transactions3: `${baseApi}/api/v3/transactions`,
      serialLookup: `${baseApi}/api/v1/seriallookup`,
      pages: `${baseApi}/api/v2/pages`,
      search: `${baseApi}/api/v1/Search`,
      product: `${baseApi}/api/v2/product`,
      product3: `${baseApi}/api/v3/product`,
      productAltEndpoint: `${baseApi}/product-configuration/product/v2/api/product`,
      cart: `${baseApi}/api/v2/cart`,
      cartItem: `${baseApi}/api/v2/cart-item`,
      cartItem3: `${baseApi}/api/v3/cart-item`,
      serial: `${baseApi}/api/v1/serial`,
      shipping: `${baseApi}/api/v2/shipping`,
      system1: `${baseApi}/api/v1/system`,
      system: `${baseApi}/api/v2/system`,
      system3: `${baseApi}/api/v3/system`,
      location: `${baseFittingApi}/api/v1/locations`,
      certificationCategories: `${baseFittingApi}/api/v1/certification-categories/certifications`,
      updateCertificationCategory: `${baseFittingApi}/api/v1/certification-categories/`,
      environmentCategories: `${baseFittingApi}/api/v1/environment-categories/environments`,
      updateEnvironmentCategory: `${baseFittingApi}/api/v1/environment-categories/`,
      technologyCategories: `${baseFittingApi}/api/v1/technology-categories/technologies`,
      updateTechnologyCategory: `${baseFittingApi}/api/v1/technology-categories/`,
      address: `${baseFittingApi}/api/v1/address`,
      contacts: `${baseFittingApi}/api/v1/contacts`,
      hours: `${baseFittingApi}/api/v1/hours`,
      statuses: `${baseFittingApi}/api/v1/statuses`,
    },
    msalConfig: {
      auth: {
        clientId: '5f62e013-c549-47fa-b0d1-89209c214db1',
        redirectUri: baseUri,
        postLogoutRedirectUri: baseUri,
        authority: `https://${tenantName}.b2clogin.com/tfp/pinggolfusb2ctest.onmicrosoft.com/${signInUserFlow}`,
        knownAuthorities: [`${tenantName}.b2clogin.com`],
      },
      cacheOptions: {
        cacheLocation: 'sessionStorage',
      },
    },
    msalLoginRequest: {
      scopes: [`https://${tenantName}.onmicrosoft.com/api/user_impersonation`],
    },
    intervals: {
      logout: 1000 * 60 * 15, // 15 minutes
    },
    isKentico: false,
    isDevelopment: false,
    isProduction: true,
    isTesting: false,
    isStage: false,
    regionName: 'na',
    gtm: 'GTM-M6DW3DS',
    name: 'local',
    rollbar: {
      token: '0b7fce6f4cb4453fbd7b7d297592cd2b',
    },
    googleMapsAPI: 'AIzaSyAzRrkHg6A-HR4D7C9FSb3ZK_ETpkUMdGk',
    geoCodeAPI: 'AIzaSyBxSC3vUESs55A0t_zvExJgHjJszWqWpDE',
    defaultCurrency: 'USD',
    customerServiceFallbackUrl: 'https://ping.com/customer-service',
  };
}

export type Environment = ReturnType<typeof baseEnv>;
