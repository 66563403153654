import React, { useState } from 'react';
import classNames from 'classnames';
import './PrimaryNav.scss';
import PrimaryNavFooter from './components/PrimaryNavFooter/PrimaryNavFooter';
import PrimaryNavMenu from './components/PrimaryNavMenu/PrimaryNavMenu';
import PrimaryNavHeader from './components/PrimaryNavHeader/PrimaryNavHeader';
import OrdersRequestModel from 'stores/orders/models/OrdersRequestModel';
import OrdersAction from 'stores/orders/OrdersAction';
import InvoicesRequestModel from 'stores/invoices/models/InvoicesRequestModel';
import InvoicesAction from 'stores/invoices/InvoicesAction';
import IAccount from 'stores/accounts/models/IAccount';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import { Dispatch } from 'redux';
import useInterval from 'utilities/useInterval';
import { DATE_HELPER } from 'index';
import SortDirectionEnum from 'constants/SortDirectionEnum';
function getPrimaryNavClasses(isExpanded): string {
  return classNames({
    primaryNav: true,
    'is-expanded': isExpanded,
    'is-collapsed': !isExpanded,
  });
}

export default function PrimaryNav(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const orderFilters: OrdersRequestModel | null = useSelector((state: IStore) => state.orders.ordersFilters);
  const transactionsFilters: InvoicesRequestModel | null = useSelector((state: IStore) => state.invoices.transactionHistoryFilters);
  const invoicesFilters: InvoicesRequestModel | null = useSelector((state: IStore) => state.invoices.invoicesFilters);
  const accountNumber: number | undefined = activeAccount?.accountNumber;

  const primaryNavOrdersRefresh = (startDay, today, accountNumber: number) => {
    const ordersRequestConfig = new OrdersRequestModel({
      pageSize: 10,
      startDate: startDay,
      endDate: today,
    });

    dispatch(OrdersAction.requestOrdersSummary(accountNumber, DATE_HELPER.oneTwentyDaysMonthDayYear, DATE_HELPER.todayDateWithTime));
    // Refresh orders on the dashboard
    dispatch(
      OrdersAction.requestOrders(
        new OrdersRequestModel({
          pageSize: 10,
          page: 1,
          startDate: startDay,
          endDate: today,
        }),
        accountNumber,
        true
      )
    );

    dispatch(OrdersAction.requestOrders(orderFilters ? orderFilters : ordersRequestConfig, accountNumber, false));
  };

  const primaryNavInvoicesRefresh = (startDay, today, accountNumber: number) => {
    const transactionHistoryRequestConfig = new InvoicesRequestModel({
      pageSize: 10,
      page: 1,
      startDate: startDay,
      endDate: today,
    });
    const invoicesRequestConfig = new InvoicesRequestModel({
      pageSize: 12,
      transactionType: 'INV',
      sortData: {
        Column: 'TransactionDueDate',
        SortDirection: SortDirectionEnum.DESC,
      },
      startDate: startDay,
      endDate: today,
    });

    // create specific invoice request for the dashboard
    var dashboardInvoiceRequest = new InvoicesRequestModel({
      pageSize: 4,
      transactionType: 'INV',
      sortData: {
        Column: 'TransactionDueDate',
        SortDirection: SortDirectionEnum.DESC,
      },
      startDate: startDay,
      endDate: today,
    });

    dispatch(
      InvoicesAction.requestTransactionHistory(transactionsFilters ? transactionsFilters : transactionHistoryRequestConfig, accountNumber, false)
    );
    dispatch(InvoicesAction.requestInvoices(invoicesFilters ? invoicesFilters : invoicesRequestConfig, accountNumber, false));

    // Request invoices for the dashboard
    dispatch(InvoicesAction.requestInvoices(dashboardInvoiceRequest, accountNumber, true));
  };

  useInterval(() => {
    const today = DATE_HELPER.todayToDate;
    const startDay = DATE_HELPER.oneTwentyDaysToDate;
    if (accountNumber) {
      primaryNavOrdersRefresh(startDay, today, accountNumber);
      primaryNavInvoicesRefresh(startDay, today, accountNumber);
    }
  }, 300000); // 5 minutes = 300000

  return (
    <div className={getPrimaryNavClasses(isExpanded)}>
      <PrimaryNavHeader isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <PrimaryNavMenu />
      <PrimaryNavFooter />
    </div>
  );
}
