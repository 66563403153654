import AccessControl from 'components/AccessControl/AccessControl';
import React from 'react';
import PrimaryNavMenuLink from '../PrimaryNavMenuLink/PrimaryNavMenuLink';

interface PrimaryNavMenuItemProps {
  navItem: any;
}

export default function PrimaryNavMenuItem(props: PrimaryNavMenuItemProps): JSX.Element {
  const { navItem } = props;
  return (
    <AccessControl allowedRole={navItem.ACCESS}>
      <PrimaryNavMenuLink Icon={navItem.ICON} name={navItem.NAME} to={navItem.URL} />
    </AccessControl>
  );
}
