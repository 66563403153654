import styles from './UserRolesOverlay.module.scss';
import * as React from 'react';
import { ReduxProps } from '../../models/ReduxProps';
import Modal from 'components/Modal/Modal';
import Title from 'components/Title/Title';
import UserMembershipsNamesEnum from '../../constants/UserMembershipsNamesEnum';

interface IProps {}
interface IRouteParams {}

class UserRolesOverlay extends React.Component<IProps & ReduxProps<any, IRouteParams>> {
  // eslint-disable-next-line
  constructor(props: any) {
    super(props);
  }

  // TODO: Where will this data come from?
  public rolesDescriptions = [
    {
      name: UserMembershipsNamesEnum.Sales,
      description:
        'A Sales User has the ability to setup users, can view anything related to orders, billing and accounting but restricted on making account payments.',
    },
    {
      name: UserMembershipsNamesEnum.CorporateUser,
      description:
        'A Corporate User has the ability to setup users within their organization, can view and take action on anything related to orders, billing and accounting.',
    },
    {
      name: UserMembershipsNamesEnum.Distributor,
      description:
        'A Distributor has the ability to setup users within their organization, can view and take action on anything related to orders, billing and accounting as well as view international repair instructions.',
    },
    {
      name: UserMembershipsNamesEnum.Account,
      description:
        'An Account has the ability to setup users within their organization, can view and take action on anything related to orders, billing and accounting.',
    },
    {
      name: UserMembershipsNamesEnum.IntlProAccount,
      description:
        'An International Pro Account has the ability to setup users within their organization, can view and take action on anything related to orders, billing and accounting, and can access the fitting section.',
    },
    {
      name: UserMembershipsNamesEnum.EmployeeBilling,
      description:
        'An Employee (Billing) can view and take action on anything related to orders, billing and accounting, as well as have access to the fitting section.',
    },
    {
      name: UserMembershipsNamesEnum.Employee,
      description: 'An Employee has a limited view into order details (no pricing) and the fitting section.',
    },
    {
      name: UserMembershipsNamesEnum.Fitter,
      description: 'A Fitter has access to the fitting section only.',
    },
  ];

  onCloseClick = () => {
    this.props.history.goBack();
  };

  public render(): JSX.Element {
    return (
      <Modal onClose={this.onCloseClick} aria-labelledby='modalTitle'>
        <h1 id='modalTitle'>
          <Title title={'User Roles'} />
        </h1>
        <ul className={styles['overlay-list']}>
          {this.rolesDescriptions.map((role, i) => {
            return (
              <li key={i}>
                <h2 className={'hdg hdg--4'}>{role.name}</h2>
                <div>{role.description}</div>
              </li>
            );
          })}
        </ul>
      </Modal>
    );
  }
}
export default UserRolesOverlay;
