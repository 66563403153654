import styles from './InvoiceCards.module.scss';

import React, { useState } from 'react';
import InvoiceCard from '../InvoiceCard/InvoiceCard';
import Pagination from '../../../../components/Pagination/Pagination';
import IInvoices from '../../../../stores/invoices/models/IInvoices';
import InvoicesResponseModel from 'stores/invoices/models/InvoicesResponseModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Error4xx from 'components/Error4xx/Error4xx';

interface IProps {
  invoicesResponse: InvoicesResponseModel | null;
  onNewPageClick: (page: number) => void;
  onTabClick: (tab: string) => void;
  loading: any;
  invoices4xxResponses: any;
}

interface IState {
  filter: string;
}

const InvoiceCards = ({ invoicesResponse, onNewPageClick, onTabClick, loading, invoices4xxResponses }: IProps): JSX.Element | null => {
  const initialState: IState = { filter: 'all' };
  const [filter, setFilter] = useState(initialState.filter);

  const invoicesData: IInvoices[] | undefined = invoicesResponse?.data;

  // const pastDueCount = invoicesData.reduce((acc, inv) => {
  //   const newAcc = inv.pastDue === true ? acc + 1 : acc;
  //   return newAcc;
  // }, 0);

  // const currentCount = invoicesData.reduce((acc, inv) => {
  //   const newAcc = inv.pastDue === false ? acc + 1 : acc;
  //   return newAcc;
  // }, 0);

  const setInvoiceFilter = (filter) => {
    setFilter(filter);
    onTabClick(filter);
  };

  return (
    <div>
      <ul className={styles['status-filters']}>
        <li className={filter === 'all' ? styles.active : ''} onClick={() => setInvoiceFilter('all')}>
          <button className='focus-ring'>Open</button>
        </li>
        <li className={filter === 'past due' ? styles.active : ''} onClick={() => setInvoiceFilter('past due')}>
          {/* <button className='focus-ring'>Past Due ({pastDueCount})</button> */}
          <button className='focus-ring'>Past Due</button>
        </li>
      </ul>
      <div className='vr1_5' />
      {loading ? (
        <>
          <LoadingIndicator isActive={true} inverted={true} />
        </>
      ) : (
        <Error4xx response={invoices4xxResponses['InvoicesAction.REQUEST_INVOICES_FINISHED']} textOnly>
          {invoicesResponse && invoicesData && (
            <>
              <div className='invoice-cards'>
                {filter === 'all' &&
                  invoicesData.map((invoice, i) => {
                    const key = `${invoice.referenceNumber}-${i}`;
                    return <InvoiceCard key={key} invoice={invoice} />;
                  })}
                {filter === 'past due' &&
                  invoicesData
                    .filter((inv) => inv.pastDue === true)
                    .map((invoice, i) => {
                      const key = `${invoice.referenceNumber}-${i}`;
                      return <InvoiceCard key={key} invoice={invoice} />;
                    })}
              </div>
              <Pagination data={invoicesResponse} hidePageDisplay={true} onNewPageClick={onNewPageClick} />{' '}
            </>
          )}
        </Error4xx>
      )}
    </div>
  );
};

export default InvoiceCards;
