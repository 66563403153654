import React, { useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import Title from 'components/Title/Title';
interface IProps {
  onCancel: Function;
  onConfirm: Function;
}

const AdminUserConfirmModal = (props: IProps) => {
  const { onCancel, onConfirm } = props;

  useEffect(() => {
    if (onConfirm) {
      const enterHandler = (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          onConfirm();
        }
      };

      document.addEventListener('keydown', enterHandler);

      return () => {
        document.removeEventListener('keydown', enterHandler);
      };
    }
  }, [onConfirm]);

  return (
    <>
      <Modal onClose={() => onCancel()} isFullScreen={false}>
        <Title title='Ping Admin Confirmation' />
        <p>
          Please enter to confirm, that the user being granted the Ping Admin role is a PING employee with a ping email address. Otherwise press
          Cancel.
        </p>
        <div className='split'>
          <button className='btn btn--inverted btn__text-only' onClick={() => onCancel()}>
            Cancel
          </button>
          <button className='btn btn__text-only' onClick={() => onConfirm()}>
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AdminUserConfirmModal;
