import React from 'react';
import styles from '../../../../components/Error4xx/components/ErrorCard/ErrorCard.module.scss';
import { ReactComponent as ErrorIcon } from '../../../../assets/media/icons/icon-error.svg';
import { PingCustomerServiceContactMessage } from 'components/Content/Contact/CustomerServiceContactMessage';

interface IProps {
  message: string;
}
const LocationInfoError = ({ message }: IProps) => {
  return (
    <div className={`${styles['error-card']}`}>
      <div className={styles['error-card__icon-container']}>
        <ErrorIcon className={styles['error-card__icon']} />
      </div>
      <div className={styles['error-card__message-container']}>
        <div className={styles['error-card__error-message']}></div>
        <div className={styles['error-card__secondary-message']}>
          <h4 className={`hdg--4`}>An Error Occurred:</h4>
          <div className={styles.error_buttonContainer}>
            {message && `${message}`}
            <button className={`btn btn--loading btn__text-only ${styles.error_btn}`} onClick={(e) => window.document.location.reload()}>
              Refresh Page
            </button>
          </div>
          <p className={`${styles.error_text}`}>
            <PingCustomerServiceContactMessage />
          </p>
        </div>
      </div>
    </div>
  );
};
export const LocationInfoErrorWrapped = ({ message }) => {
  return (
    <div className='wrapper'>
      <LocationInfoError message={message} />
    </div>
  );
};
export default LocationInfoError;
