import { BaseModel } from 'sjs-base-model';
import IOrderItemByTagName from './IOrderItemByTagName';

/*
    // Returned Api Data Sample
    {
      "data": IOrder[],
      ...PaginatedResponseModel
    }
 */
export default class TagNameSearchResponseModel extends BaseModel {
  data: IOrderItemByTagName[];
  pageSize: number = 10;
  currentPage: number = 1;
  pageSkip: number;
  totalCount: number;
  totalPages: number;
  userMessage: string;
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
