import { SUBMITTED_LIST_INSTANCE } from 'containers/CreateOrderPage/CreateOrderPage';
import _ from 'lodash';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { IDynamicFormDependencyUpdateFields, IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import generateFieldState from './generateFieldState';
import { generateFormWithNewSelectionLoop, setMasterFieldObjectValue, getUpdatedMasterFieldObject } from './ProductConfigHelper';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../ProductConfig';

// TODO: check if we need willAttributeBeSet helper if not delete
/**
 * Updates Fields sending dependency requests for any field with its dependencies fully filled out.
 * It also checks if values of fields are at 1 and sets the selected value to that.
 * if it sets the selected value it will rerun the dependecy call on those values.
 * It will also run the dependency call a final time to populate any values that still need to be set.
 *
 * @param Object Request object of IDynamicFormDependencyUpdateFields
 * @type IDynamicFormDependencyUpdateFields
 */
export default async function requestFieldUpdates({
  product,
  masterFieldObject,
  setMasterFieldObject,
  fieldState,
  setFieldState,
  accountNumber,
  selectedValue,
  setSubmittedList,
  submittedList,
  finalCall,
  step,
  setStep,
  setIsLoading,
  setErrorMessage,
  originalMasterFieldObject,
  saveProductConfig,
  setPriceTotal,
  dispatch,
}: IDynamicFormDependencyUpdateFields) {
  let masterFieldObjectSet = masterFieldObject;
  setIsLoading(true);

  // Set to false at begining of loop this will decide if we need to make another set of Dependency calls.
  PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall = false;
  /**
   * Check if user is setting an already set value
   */
  const setFieldObject = masterFieldObject.find((item) => selectedValue.Name === item.attributeName);
  if (setFieldObject?.selectedValue !== selectedValue.Value && setFieldObject?.selectedValue !== null && setFieldObject?.attributeName !== 'CLUBS') {
    const originalClone = _.clone(originalMasterFieldObject);
    const withUserSelected = originalClone.map((attribute, index) => {
      if (masterFieldObject[index].userSelected) {
        return {
          ...attribute,
          userSelected: true,
        };
      }

      return attribute;
    });
    /**
     * At this point we can branch to a new loop that will deal with if a user is reselecting a value.
     * Meaning we need to figure out if the selectedValues are still legit and regenerate all field options.
     */
    generateFormWithNewSelectionLoop({
      product,
      masterFieldObject,
      setMasterFieldObject,
      fieldState,
      setFieldState,
      accountNumber,
      selectedValue,
      setSubmittedList,
      finalCall,
      step,
      setStep,
      setIsLoading,
      setErrorMessage,
      savedSubmittedList: [],
      nullList: [],
      setPriceTotal,
      originalMasterFieldObject: withUserSelected,
      firstLoop: true,
    });

    return;
  }

  /**
   * This will set the selectedValue that the user passed in when they started this function.
   */
  masterFieldObjectSet = setMasterFieldObjectValue({ masterFieldObject, selectedValue });

  /**
   * Makes all the possible dependncy calls and sets a value
   * if either the response only has 1 value
   * or if the response has a default value.
   *
   * If a value gets set then it will mark shouldMakeDependencyCall as true.
   * this will trigger another loop since its possible that further calls can be made.
   */
  getUpdatedMasterFieldObject({
    masterFieldObjectSet,
    product,
    accountNumber,
    setIsLoading,
    submittedList,
    setSubmittedList,
    setErrorMessage,
  })
    .then(async (updatedMasterFieldObject: any) => {
      const newMasterFieldObject: IDynamicFormAttribute[] = updatedMasterFieldObject;

      /**
       * Set State
       *
       * TODO: if && finalCall added this will work correctly for the calls but will not get the clubs field
       * Needs logic to check and call the clubs field if it hits it
       */
      console.log({ PRODUCT_CONFIG_LOCAL_STORE });

      if (PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall) {
        const requestConfig: IDynamicFormDependencyUpdateFields = {
          product,
          masterFieldObject: newMasterFieldObject,
          setMasterFieldObject,
          fieldState,
          setFieldState,
          accountNumber,
          selectedValue,
          setSubmittedList,
          setPriceTotal,
          submittedList: SUBMITTED_LIST_INSTANCE.submittedList,
          step,
          setStep,
          setIsLoading,
          setErrorMessage,
          originalMasterFieldObject,
          saveProductConfig: saveProductConfig ? true : false,
          dispatch: dispatch ? dispatch : null,
        };
        setIsLoading(true);
        requestFieldUpdates(requestConfig);
      } else if (accountNumber) {
        // Sets Values in ESTIMATED_DATE needed for generateFieldState below
        const updatedFields = generateFieldState(newMasterFieldObject, step, setStep);

        setMasterFieldObject(newMasterFieldObject);
        setFieldState(updatedFields);
        setIsLoading(false);

        /**
         * If this is the initial call that is only setting the department
         * Store the relevent data so that we can recall this products initial state
         * and not make future calls for this product. Removed on refresh.
         */
        if (saveProductConfig) {
          dispatch(CreateOrderAction.storeProductConfig(SUBMITTED_LIST_INSTANCE.submittedList, product, false, true));
          dispatch(CreateOrderAction.storeProductConfig(newMasterFieldObject, product));
        }
        SUBMITTED_LIST_INSTANCE.setSubmittedList([]);
      }
    })
    .catch(() => {
      setIsLoading(false);
      // setErrorMessage(err);
      // console.log(err);
    });
}
