import { IDynamicFormAttributeDependecyResponse, IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../ProductConfig';

export default function getSelectedValue(item: IDynamicFormAttribute, res: IDynamicFormAttributeDependecyResponse[]) {
  const defaultValue: IDynamicFormAttributeDependecyResponse | undefined = res.find(
    (item: IDynamicFormAttributeDependecyResponse) => item.defaultFlag === 'Y'
  );

  if (item.selectedValue !== null && item.userSelected) {
    return item.selectedValue;
  }

  if (res.length === 1) {
    PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall = true;
    return res[0].dependentVariableValue;
  }

  if (item.attributeValues.length === 1) {
    PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall = true;
    return item.attributeValues[0].attributeValue;
  }

  if (defaultValue) {
    PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall = true;
    return defaultValue.dependentVariableValue;
  }

  return null;
}
