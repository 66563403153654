import styles from './Value.module.scss';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';

interface IProps {
  className?: string;
  propertyName?: string;
  textTruncate?: boolean;
  value: string | string[] | undefined;
}

// eslint-disable-next-line valid-jsdoc
/**
 * This component automates putting HTML data attributes to enable styling for specific values,
 * and enables display value styles to be easily reused across the application.
 */
function Value({ className, value, propertyName, textTruncate }: IProps): JSX.Element {
  const normalizedPropName = propertyName?.toLowerCase();
  const textTreatment = textTruncate === false ? 'text--break' : 'text--truncate';
  const valueClasses = classNames(styles[`${className}`], styles.value, styles[`value--${normalizedPropName}`], textTreatment);

  // data prop is used to style specific value types.
  const dataProp = {
    [`data-${normalizedPropName}`]: value && Array.isArray(value) && value.length > 1 && propertyName === 'orderstatus' ? 'MIXED' : value,
  };

  /**
   * TODO: Refactor value to not have any specific logic for hasCases.
   */
  let displayValue = value;
  if (propertyName === 'orderDate') {
    displayValue = !Array.isArray(value) ? moment(value).format(DateFormatsEnum.WrittenDayMonthYear) : value;
  }

  if (propertyName === 'orderstatus') {
    displayValue = value && Array.isArray(value) && value.length > 1 ? 'MIXED' : value;
  }

  if (propertyName === 'purchaseOrders') {
    displayValue = Array.isArray(value) ? value.join() : value;
  }

  return (
    <span className={valueClasses} {...dataProp}>
      {displayValue}
    </span>
  );
}

export default Value;
