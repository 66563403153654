import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './react-tabs.scss';
import styles from './OrderForm.module.scss';
import ProductCards from '../productCards/ProductCards';
import ProductConfig from '../productConfig/ProductConfig';
import OrderShipping from '../orderShipping/OrderShipping';
import OrderReview from '../orderReview/OrderReview';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import RouteEnum from 'constants/RouteEnum';
import OrderCart from '../orderCart/OrderCart';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { Dispatch } from 'redux';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import { IGetCartResponse } from 'stores/create-order/models/IGetCart';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import OrderConfirmation from '../orderConfirmation/OrderConfirmation';
import useAccountCurrency from 'utilities/useAccountCurrency';

interface IProps {
  accountNumber: number | undefined;
  addresses: IAccountAddresses | null;
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  history: any;
  originalHistory: any;
}

function OrderForm({ accountNumber, addresses, originalHistory, history, isOpen, setOpen }: IProps): JSX.Element {
  // const [currentProduct, setCurrentProduct] = useState('product-cards');
  const dispatch: Dispatch = useDispatch();
  const getCart4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [CreateOrderAction.REQUEST_GET_CART_FINISHED]
  );
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const cartState: IGetCartResponse | null = useSelector((state: IStore) => state.createOrder.cartState);
  const cartItems = useSelector((state: IStore) => state.createOrder.cartState?.cartItems);
  const fallbackCurrency = useAccountCurrency();
  if (!cartItems) {
    return (
      <Error4xx
        // eslint-disable-next-line quotes
        overrideText={{ headingText: "We're sorry but it looks like an error occurred and we cannot complete your order at this time. " }}
        response={getCart4xxResponses['CreateOrderAction.REQUEST_GET_CART_FINISHED']}
      />
    );
  }

  const isLoading = addresses && cartState ? false : true;
  // if not onHandleShipping(false)Account redirect to Account List
  // else Pass account down
  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  const pathName = history.location.pathname;
  return (
    <>
      <div className='split'>
        <h1 className='hdg hdg--1'>Create Order</h1>
        {pathName !== `${RouteEnum.CreateOrders}/order-confirm` && (
          <>
            <OrderCart isOpen={isOpen} setOpen={setOpen} cartState={cartState} accountCurrency={fallbackCurrency} />
          </>
        )}
      </div>
      <Tabs
        selectedIndex={tabState.tabIndex}
        onSelect={(index) =>
          dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: index, itemToEditId: null, product: MAIN_CONFIG.CreateOrder.NoProduct }))
        }
      >
        <TabList>
          <Tab disabled={!tabState.hasConfirm ? false : true}>
            {!tabState.hasConfirm ? (
              <div>
                <span className={styles['tab-order']}>1</span> Select Items
              </div>
            ) : (
              <>
                <span className={`${styles['tab-order']} ${styles.disabled}`}>1</span> Select Items
              </>
            )}
          </Tab>
          <Tab disabled={tabState.hasItems && !tabState.hasConfirm ? false : true}>
            {tabState.hasItems && !tabState.hasConfirm ? (
              <div>
                <>
                  <span className={styles['tab-order']}>2</span> Shipping
                </>
              </div>
            ) : (
              <>
                <span className={`${styles['tab-order']} ${styles.disabled}`}>2</span> Shipping
              </>
            )}
          </Tab>
          <Tab disabled={tabState.hasItems && tabState.hasShipping && !tabState.hasConfirm ? false : true}>
            {tabState.hasItems && tabState.hasShipping && !tabState.hasConfirm ? (
              <div>
                <>
                  <span className={styles['tab-order']}>3</span> Review
                </>
              </div>
            ) : (
              <>
                <span className={`${styles['tab-order']} ${styles.disabled}`}>3</span>Review
              </>
            )}
          </Tab>

          <Tab disabled={tabState.hasItems && tabState.hasShipping && tabState.hasReview ? false : true}>
            {tabState.hasItems && tabState.hasShipping && tabState.hasReview ? (
              <div>
                <>
                  <span className={styles['tab-order']}>4</span> Order Confirmation
                </>
              </div>
            ) : (
              <>
                <span className={`${styles['tab-order']} ${styles.disabled}`}>4</span> Order Confirmation
              </>
            )}
          </Tab>
        </TabList>
        <TabPanel>
          {tabState.product !== MAIN_CONFIG.CreateOrder.NoProduct ? (
            <ProductConfig accountNumber={accountNumber} originalHistory={originalHistory} />
          ) : (
            <ProductCards orders={cartItems} tabState={tabState} cartState={cartState} />
          )}
        </TabPanel>
        <TabPanel>
          <OrderShipping addresses={addresses} />
        </TabPanel>
        <TabPanel>
          <OrderReview accountNumber={accountNumber} orders={cartItems} />
        </TabPanel>
        <TabPanel>
          <OrderConfirmation accountNumber={accountNumber} />
        </TabPanel>
      </Tabs>
    </>
  );
}

export default OrderForm;
