import React from 'react';
import { ReactComponent as GooglePin } from '../../../assets/media/icons/icon-Google-Pin.svg';
interface IProps {
  lat: number;
  lng: number;
}
// we must pass lat and lng to make typescript happy
const LocationMarker = ({ lat, lng }: IProps) => {
  return (
    <>
      <GooglePin aria-hidden='true' focusable='false' role='img' />
    </>
  );
};

export default LocationMarker;
