import IPayment from './IPayment';
import { BaseModel } from 'sjs-base-model';

export default class PaymentsResponseModel extends BaseModel {
  public readonly data: IPayment;
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data) {
    super();

    // Calls the update method on BaseModel
    this.update(data);
  }
}
