import React from 'react';
import styles from '../../OrderItems/OrderItems.module.scss';
import IOrder, { IOrderTracking, Item } from 'stores/orders/models/IOrder';
import TrackingItemDescription from './TrackingItemDescription';
import TrackingShipping from './TrackingShipping';
import TrackingShipTo from './TrackingShipTo';

interface IProps {
  item: IOrderTracking;
  currentOrderItem: Item | undefined;
  order: IOrder | undefined;
  hideOrderDetails?: boolean;
}

const TrackingItem = ({ item, currentOrderItem, order, hideOrderDetails }: IProps) => {
  return (
    <div className={styles.trackingBg}>
      <div className={styles.trackingCard}>
        <div className={styles.trackingContainer}>
          <TrackingShipping item={item} />
          <TrackingShipTo order={order} item={item} />
        </div>
        {!hideOrderDetails && <TrackingItemDescription currentOrderItem={currentOrderItem} />}
      </div>
    </div>
  );
};

export default TrackingItem;
