import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import PagesResponseModel from './models/PagesResponseModel';
import PagesEffect from './PagesEffect';

type ActionUnion = void | HttpErrorResponseModel | PagesResponseModel;

export default class PagesAction {
  public static readonly REQUEST_PAGE: string = 'PagesAction.REQUEST_PAGE';
  public static readonly REQUEST_PAGE_FINISHED: string = 'PagesAction.REQUEST_PAGE_FINISHED';

  public static requestPage(pageName: string): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<PagesResponseModel>(dispatch, PagesAction.REQUEST_PAGE, PagesEffect.requestPage, pageName);
    };
  }
}
