// eslint-disable-next-line valid-jsdoc

import DynamicFieldEnum from 'constants/DynamicFieldEnum';
import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import getNextStep from './getNextStep';
import { ESTIMATED_DATE } from '../ProductConfig';
import { MAIN_CONFIG } from 'configurations/mainConfig';

/**
 * generates the neccessary values for fields from a masterFieldObject
 *
 * @param masterFieldObject a masterFieldObject to generate fields from
 * @type IDynamicFormAttribute[]
 */
export default function generateFieldState(masterFieldObject: IDynamicFormAttribute[] | any, step, setStep) {
  // get Display fields
  const masterFieldObjectDisplayFields = masterFieldObject.filter((item) => item.inputDisplayFlag === 'Y');
  let newStepConfig: any = {
    newStep: step,
    newStepIndex: masterFieldObjectDisplayFields.findIndex((item) => item.attributeName === step),
  };

  const currentField: IDynamicFormAttribute | undefined = masterFieldObjectDisplayFields.find((state) => state.attributeName === step);
  const currentIndex = masterFieldObjectDisplayFields.findIndex((state) => state.attributeName === step);

  function getFieldType(field: IDynamicFormAttribute) {
    if (field.attributeType === 'CLUB') {
      return DynamicFieldEnum.Club;
    }

    return DynamicFieldEnum.Select;
  }

  /**
   * Find out what step the user is on if the value is
   * selected
   * for some reason cough clubs the values length is 0
   * or if we are ignoring the field
   */
  if (
    currentField?.selectedValue ||
    currentField?.selectedValue === '' ||
    currentField?.attributeValues.length === 0 ||
    (currentField && MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed.includes(currentField?.attributeName))
  ) {
    newStepConfig = getNextStep(masterFieldObjectDisplayFields, currentIndex, newStepConfig, setStep);
  }

  const fieldObject = masterFieldObjectDisplayFields.map((item: IDynamicFormAttribute, index) => {
    if (item.inputDisplayFlag === 'Y') {
      const estimatedDate = ESTIMATED_DATE?.classEstimatedDates?.[item.attributeName] ? ESTIMATED_DATE.classEstimatedDates[item.attributeName] : '';

      return {
        name: item.attributeName,
        label: item.attributeName,
        values: item.attributeValues.map((item) => item.attributeValue),
        placeHolder: item.attributeName,
        required: item.inputRequiredFlag === 'Y' ? true : false,
        inputType: getFieldType(item),
        disabled: item.selectedValue ? true : false,
        selectedValue: item.selectedValue && item.selectedValue,
        visible: index <= newStepConfig.newStepIndex,
        estimatedDate,
        minimumQuantity: item.minimumQuantity,
      };
    }

    return null;
  });

  return fieldObject;
}
