import IStore from 'models/IStore';
import { useSelector } from 'react-redux';
import IFeatureFlag from 'stores/feature-flag/models/IFeatureFlag';

/**
 * Currently not being used, but we can use it for future feature flag component base
 * @param name Represents the featureFlag name
 * @param children If children exits on the DOM, it will render the child component
 * @returns
 */
const FeatureFlagComponent = ({ name, children }) => {
  const features: IFeatureFlag | null = useSelector((state: IStore) => state.featureFlag.featureFlagItems);
  const feature = features?.hasOwnProperty(name) ? features[name] : false;
  // if kentico checkbox is checked then true
  if (feature) {
    return children;
  }
  // else kentico checkbox is uncheck meaning false
  return null;
};

export default FeatureFlagComponent;
