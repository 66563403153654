import { BaseModel } from 'sjs-base-model';
import { IAddToCartResponse } from './IAddToCart';

export default class AddToCartResponseModel extends BaseModel {
  public readonly data: IAddToCartResponse;
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
