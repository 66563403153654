import styles from './LoadingIndicator.module.scss';

import React from 'react';
import classNames from 'classnames';
import { CSSProperties } from 'react';

export interface SpinnerCustomCSS extends CSSProperties {
  '--colorVar': string;
  '--hoverColor': string;
}
interface IProps {
  readonly isActive?: boolean;
  readonly className?: string;
  readonly inverted?: boolean;
  readonly noLoadingText?: boolean;
  readonly lineLoader?: boolean;
  readonly smallWrapper?: boolean;
  readonly customLoadingText?: string;
  readonly sizeableSpinner?: {
    height: number;
    width: number;
    marginLeft: number;
  };
  readonly sizeableSpinnerColor?: {
    normal: string;
    hover?: string;
  };
}
interface IState {}

export default class LoadingIndicator extends React.PureComponent<IProps, IState> {
  public static defaultProps: IProps = {
    isActive: false,
    // eslint-disable-next-line no-undefined
    className: undefined,
    inverted: false,
    noLoadingText: false,
  };

  public render(): JSX.Element {
    const { isActive, className, inverted, noLoadingText } = this.props;
    const wrapperCss: string = classNames(className, {
      [styles.wrapper]: isActive,
    });
    const containerCss: string = classNames(styles['loader-container'], {
      [styles.inverted]: inverted,
    });

    const spinnerColorVar = {
      // hovercolor on the spinner is not fully implemented
      // '--hoverColor': this.props.sizeableSpinnerColor?.hover ?? '#0371b5',
      '--colorVar': this.props.sizeableSpinnerColor?.normal ?? '#0371b5',
    } as SpinnerCustomCSS;
    if (this.props.sizeableSpinner) {
      return (
        <>
          {/* <div
            className={styles['sk-circle-fade']}
            style={{ width: `${this.props.sizeableSpinner.width}px`, height: `${this.props.sizeableSpinner.width}px` }}
          >
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
            <div className={styles['sk-circle-fade-dot']}></div>
          </div> */}

          {/* <div
            className={styles['sk-pulse']}
            style={{ width: `${this.props.sizeableSpinner.width}px`, height: `${this.props.sizeableSpinner.width}px` }}
          /> */}

          <div
            className={styles['sk-chase']}
            style={{
              width: `${this.props.sizeableSpinner.width}px`,
              height: `${this.props.sizeableSpinner.width}px`,
              marginLeft: `${this.props.sizeableSpinner.marginLeft}px`,
            }}
          >
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
            <div className={styles['sk-chase-dot']} style={spinnerColorVar}></div>
          </div>
        </>
      );
    }

    return (
      <div className={`${this.props.smallWrapper ? styles['small-wrapper'] : wrapperCss}`}>
        {isActive && (
          <div className={containerCss}>
            <div className={`${this.props.lineLoader ? styles.spinner : styles['lds-roller']}`}>
              {this.props.lineLoader ? (
                <>
                  <div className={styles.bounce1}></div>
                  <div className={styles.bounce2}></div>
                  <div className={styles.bounce3}></div>
                </>
              ) : (
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            </div>
            {this.props.customLoadingText ? (
              <p className={styles['loader-container__message']}>{this.props.customLoadingText}</p>
            ) : noLoadingText ? null : (
              <p className={styles['loader-container__message']}>Loading</p>
            )}
          </div>
        )}
      </div>
    );
  }
}
