import Case from 'case';
import { IFormErrors } from '../IFormErrors';

export default function getRequiredStrings(formState: any, requiredStrings: string[], formErrors: IFormErrors, address1Switch?: boolean) {
  requiredStrings.forEach((item) => {
    if (!formState[item]) {
      formErrors.errors.push({
        errorFieldName: item,
        errorMessage: address1Switch
          ? `${item === 'address1' ? 'Street Address' : Case.capital(item)} is required`
          : `${Case.capital(item)} is required`,
      });
      formErrors.hasError = true;
    }
  });
}
