import styles from './Footer.module.scss';
import React from 'react';
import classNames from 'classnames';

interface IProps {
  isInverse: boolean;
}
interface IState {
  year: string;
}
export default class Footer extends React.PureComponent<IProps, IState> {
  public static defaultProps: IProps = {
    isInverse: false,
  };

  public componentDidMount() {
    this.setCurrentYear();
  }

  public setCurrentYear() {
    const today = new Date();
    return today.getFullYear();
  }

  render() {
    const { isInverse } = this.props;
    const footerListStyles = classNames({
      [styles.footer__list]: true,
      [styles['footer__list--inverse']]: isInverse,
    });

    return (
      <footer className={styles.footer}>
        <div>
          <div className={styles.timeZoneNote}>All dates / times are displayed in Mountain Standard Time (MST)</div>
          <div>
            <ul className={footerListStyles}>
              <li>
                <span>&copy;{new Date().getFullYear()} PING. All Rights Reserved.</span>
              </li>
              <li>
                <a href='https://academy.ping.com/' target='_blank' rel='noopener noreferrer' className='focus-ring'>
                  PING Academy
                </a>
              </li>
              <li>
                <a href='https://webfit.ping.com/' target='_blank' rel='noopener noreferrer' className='focus-ring'>
                  PING WebFit
                </a>
              </li>
              <li>
                {/* TODO: Link to future internal app feature */}
                <a href='https://www.pinggolf.biz/CSPNA/Accounting/CertificationView.aspx' className='focus-ring'>
                  State Tax Form &amp; Information
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
