import React, { useContext, useEffect, useState } from 'react';
import LocationTabTemplate from '../LocationTabTemplate/LocationTabTemplate';
import LocationEnvironmentUtility from '../../../../utilities/Location/LocationEnvironmentUtility';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationInfoFooter from '../LocationInfoFooter/LocationInfoFooter';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationUtility from 'utilities/Location/LocationUtility';
import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

interface IProps {
  type: string;
  statePropertyName: string;
}

const EnvironmentTab = ({ type, statePropertyName }: IProps): JSX.Element => {
  const [environmentCategories, setEnvironmentCategories] = useState([]);
  const [locationEnvironments, setLocationEnvironments] = useState([]);
  const { locationState, locationTabState, updateAllLocationState } = useContext(LocationContext);
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const errorMessage: string = 'Error loading environment categories.';
  const updateEnvironmentVerfication = (isVerified: boolean) => {
    // TODO: refactor this
    const updateRequest = new UpdateLocationRequestModel({ environmentsVerified: isVerified });
    LocationUtility.updateLocation(locationState.id, updateRequest).then((response) => {
      if (response && response.data) {
        const updatedLocation = response.data;
        updateAllLocationState &&
          updateAllLocationState({
            id: updatedLocation.id,
            name: updatedLocation.name,
            displayHours: updatedLocation.displayHours,
            regionId: updatedLocation.regionId,
            statusId: updatedLocation.statusId,
            hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
            certificationsVerified: updatedLocation.certificationsVerified,
            environmentsVerified: updatedLocation.environmentsVerified,
            technologiesVerified: updatedLocation.technologiesVerified,
            displayOnMap: updatedLocation.displayOnMap,
          });
      }
      if (response instanceof HttpErrorResponseModel) {
        setIsError({ isError: true, message: LocationErrorMessages.UPDATE_LOCATION });
      }
    });
  };

  const addEnvironmentToLocation = (environmentId: string) => {
    // call the createlocationCertification to create a specific certification on a specific location
    LocationEnvironmentUtility.createLocationEnvironment(locationState.id, environmentId);
  };

  const deleteEnvironmentToLocation = (environmentId: string) => {
    LocationEnvironmentUtility.deleteLocationEnvironment(locationState.id, environmentId);
  };

  useEffect(() => {
    LocationEnvironmentUtility.getLocationEnvironments(locationState.id)
      .then((data) => {
        setLocationEnvironments(data.data);
      })
      .catch((error) => {
        setIsError({ isError: true, message: errorMessage });
      });
  }, [setLocationEnvironments, locationState.id]);

  useEffect(() => {
    if (environmentCategories.length === 0 && !isError.isError) {
      LocationEnvironmentUtility.getAllCertificationCategories()
        .then((data) => {
          if (data.lenght === 0) {
            setIsError({ isError: true, message: errorMessage });
          } else {
            setEnvironmentCategories(data);
          }
        })
        .catch((error) => {
          setIsError({ isError: true, message: errorMessage });
        });
    }
  }, [environmentCategories, setEnvironmentCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LocationTabTemplate
        categoryList={environmentCategories}
        tabName={type}
        itemListName={'environments'}
        statePropertyName={statePropertyName}
        updateVerification={updateEnvironmentVerfication}
        locationSpecificItems={locationEnvironments}
        addLocationSpecificItem={addEnvironmentToLocation}
        deleteLocationSpecificItem={deleteEnvironmentToLocation}
        isError={isError}
      />
      <LocationInfoFooter tabName={type} statePropertyName={statePropertyName} tabIndex={locationTabState.tabIndex} />
    </>
  );
};

export default EnvironmentTab;
