import React from 'react';
import Modal from 'components/Modal/Modal';
import PagesStatelessCalls from 'stores/pages/PagesStatelessCalls';
import styles from './LinkModal.module.scss';
import parse from 'html-react-parser';
import { IPageResourceItem } from 'stores/pages/models/IPage';

interface IProps {
  modalText: string;
  setModalState: any;
  item: IPageResourceItem;
  documentClick: (r: string, i: string, v: string) => void;
}

const LinkModal = ({ modalText, setModalState, item, documentClick }: IProps): JSX.Element => {
  const onDownload = async () => {
    if (item.isDownloadable) {
      await PagesStatelessCalls.downloadFileForPage(item.fileUrl);
      setModalState(false);
    } else if (item.modalText !== '' && item.resourceType === 'PDF') {
      documentClick(item.resourceType, item.videoId, item.fileUrl);
      setModalState(false);
    }
  };
  const message = parse(modalText);

  return (
    <Modal onClose={() => setModalState(false)} isFullScreen={false} aria-labelledby='LinkModal'>
      <h1 className='hdg hdg--3'>Important Notice</h1>
      <p className={styles.message}>{message}</p>
      <div className='split'>
        <button className='btn btn--inverted btn__text-only' onClick={() => setModalState(false)}>
          Cancel
        </button>
        <button className='btn btn__text-only' onClick={onDownload}>
          {item.isDownloadable ? 'Download' : 'Continue'}
        </button>
      </div>
    </Modal>
  );
};

export default LinkModal;
