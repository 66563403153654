import React, { useRef, createRef } from 'react';
import IFields from './IFields';
import Select from 'react-select';
import SelectStyles from './selectStyles';
import InputMask from 'react-input-mask';

interface Props {
  fields: IFields;
  handleSubmit?: Function;
  handleFieldChange: Function;
  handleSelectFieldChange?: Function;
  formState: any;
  handleThankYou?: boolean;
  thankYouMessage?: string;
  submitButtonText?: string;
  formRef: any;
  formId: string;
  additionalStyle?: string;
  checkValid?: boolean;
}

function Form({
  additionalStyle,
  fields,
  handleFieldChange,
  handleSelectFieldChange,
  formState,
  handleThankYou,
  thankYouMessage,
  formId,
  checkValid,
  formRef,
}: Props) {
  // const dispatch: Dispatch = useDispatch();
  //TODO Figure out how to update ref based on when fields object gets updated
  // Currently not being inplaced with Fitting Location Form
  const inputRef = useRef<any>(fields.map(() => createRef()));
  const zipCode = (newState, userInput) => {
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-') {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  return (
    <form className={`form ${additionalStyle}`} ref={formRef} id={formId}>
      {handleThankYou ? (
        'thanks'
      ) : (
        <div className={`form__select-wrapper`}>
          {fields.map((field, index) => {
            let calculatedRef = inputRef.current;
            if (inputRef) {
              calculatedRef = inputRef.current[index];
            }
            const fieldLabel = field.options?.find((option) => option.value === formState[field.name])?.label;
            const handleFocus = () => {
              // Prevent crashing on fields using inputMask
              if (!calculatedRef?.current?.props) {
                calculatedRef?.current?.focus();
              }
            };
            return (
              <div key={index} className='vr1_5'>
                {field.title && <h4 className='hdg hdg--4 form_title'>{field.title}</h4>}
                {field.type === 'select' ? (
                  <div className={'form__select-container'}>
                    <label className={'form__select-label'} htmlFor={field.name}>
                      {field.label} {!field.notRequired && <span className='error-color'>*</span>}
                    </label>
                    <Select
                      styles={SelectStyles}
                      defaultValue={field.defaultSelectValue}
                      id={field.name}
                      value={{
                        key: formState[field.name],
                        value: formState[field.name],
                        /**
                         * Double ternary used to allow default selected value to be used if there is no fieldLabel found.
                         * if there is no defaultSelectedValue passed in on the field it will default to the fields value
                         */
                        // eslint-disable-next-line no-nested-ternary
                        label: fieldLabel
                          ? fieldLabel
                          : field.defaultSelectValue && field.defaultSelectValue.label
                          ? field.defaultSelectValue.label
                          : formState[field.name],
                      }}
                      required={!field.notRequired}
                      options={field.options}
                      onChange={handleSelectFieldChange}
                    />
                  </div>
                ) : (
                  <div onClick={handleFocus} className={'form__input-container'}>
                    <label className='form__label' id={field.name} htmlFor={field.name}>
                      {field.label} {!field.notRequired && <span className='error-color'>*</span>}
                    </label>
                    {field.mask ? (
                      <InputMask
                        mask={field.mask}
                        required={!field.notRequired}
                        className='form__input'
                        id={field.name}
                        ref={calculatedRef}
                        onChange={handleFieldChange(field.name)}
                        value={formState[field.name]}
                        type={field.inputType}
                        name={field.name}
                        autoComplete={field.auto ? field.auto : ''}
                        pattern={field.pattern && field.pattern}
                        maskChar={null}
                        beforeMaskedValueChange={zipCode}
                      />
                    ) : (
                      <input
                        required={!field.notRequired}
                        className={'form__input'}
                        id={field.name}
                        ref={calculatedRef}
                        checked={formState[field.name]}
                        onChange={handleFieldChange(field.name)}
                        value={formState[field.name]}
                        type={field.inputType}
                        name={field.name}
                        autoComplete={field.auto ? field.auto : ''}
                        pattern={field.pattern && field.pattern}
                      />
                    )}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {checkValid && calculatedRef.current ? (
                      calculatedRef.current.validity.valid ? null : (
                        <div className='form__error'>This Field Is required</div>
                      )
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </form>
  );
}

export default Form;
