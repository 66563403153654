import React, { useState } from 'react';
import styles from '../OrderFooter.module.scss';
import CancelOrderModal from '../../CancelOrderModal/CancelOrderModal';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../../productConfig/ProductConfig';

interface IProps {
  to?: any;
  toNextStep: string;
  navlinkText?: string;
  buttonText?: string;
  navLinkOnClick?: any;
  buttonOnClick?: any;
  selectItemsStep?: boolean;
  disabledButton?: boolean;
  noTopMargin?: boolean;
  handleResetForm?: (e: any) => void;
}

const OrderFooterDynamicForm = ({
  to,
  toNextStep,
  navlinkText,
  buttonText,
  navLinkOnClick,
  buttonOnClick,
  selectItemsStep,
  disabledButton,
  noTopMargin,
  handleResetForm,
}: IProps) => {
  const [modalState, setModalState] = useState(false);

  return (
    <>
      <hr className={`${noTopMargin ? styles['df__divider--no-top-margin'] : styles['df__divider']}`} />
      <div className={`split ${styles['df__footer-container']}`}>
        {PRODUCT_CONFIG_LOCAL_STORE.isEditing ? null : (
          <button className={styles['df__reset-btn']} onClick={handleResetForm}>
            Reset Form
          </button>
        )}
        <div className={`${PRODUCT_CONFIG_LOCAL_STORE.isEditing && styles.df__secondSplit} split`}>
          {selectItemsStep ? (
            <div onClick={navLinkOnClick} className={styles['df__cancel-btn-dynamic']}>
              <span>{navlinkText}</span>
            </div>
          ) : (
            <span onClick={() => setModalState(true)} className={styles['df__cancel-btn-dynamic']}>
              <span>{navlinkText}</span>
            </span>
          )}
          {modalState && !selectItemsStep ? <CancelOrderModal to={to} handleCartClear={navLinkOnClick} setModalState={setModalState} /> : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {disabledButton ? (
            <span className='btn btn__text-only btn--disabled'>{buttonText}</span>
          ) : (
            <div onClick={buttonOnClick}>
              <span className='btn btn__text-only'>{buttonText}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderFooterDynamicForm;
