import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styles from './LocationDetails.module.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import LocationHours from './components/LocationHours';
import LocationContact from './components/LocationContact';
import LocationName from './components/LocationName';
import LocationDisplayHour from './components/LocationDisplayHour';
import { debounce } from 'lodash';
import LocationAddress from './components/LocationAddress';
import LocationUtility from 'utilities/Location/LocationUtility';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationInfoStatus from '../LocationInfoStatus/LocationInfoStatus';
import LocationInfoFooter from '../LocationInfoFooter/LocationInfoFooter';
import LocationTitle from '../LocationTitle/LocationTitle';
import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
import LocationInfoError from '../LocationInfoError/LocationInfoError';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { LocationTabs } from 'utilities/Location/models/ILocationTabState';
import LocationDisplayOnMap from './components/LocationDisplayOnMap';

interface IProps {
  type: string;
  statePropertyName: string;
}
const LocationDetails = ({ type, statePropertyName }: IProps) => {
  const editRef = useRef<any>(null);
  const { locationId } = useParams<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    name: '',
    displayHours: false,
  });
  const { locationState, locationTabState, updateAllLocationStateAndTabState } = useContext(LocationContext);
  const [error, setError] = useState<ILocationInfoError>(initialError);
  const setFormStateCallBack = useCallback(() => {
    setFormState((prevState) => {
      return {
        ...prevState,
        name: locationState.name,
        displayHours: locationState.displayHours,
      };
    });
    setIsLoading(false);
  }, [locationState.name, locationState.displayHours]);
  useEffect(() => {
    if (locationState.id !== null && locationState.id !== '') {
      setFormStateCallBack();
    } else {
      if (updateAllLocationStateAndTabState) {
        LocationUtility.getLocationByLocationId(locationId).then((response) => {
          if (response && response.data) {
            const selectedLocation = response.data;
            updateAllLocationStateAndTabState(
              {
                id: selectedLocation.id,
                name: selectedLocation.name,
                displayHours: selectedLocation.displayHours,
                regionId: selectedLocation.regionId,
                statusId: selectedLocation.statusId,
                hasAddress: true, // if we need to keep this get call and it needs to work we might need to modify endpoint to return a hasAddress property
                certificationsVerified: selectedLocation.certificationsVerified,
                environmentsVerified: selectedLocation.environmentsVerified,
                technologiesVerified: selectedLocation.technologiesVerified,
                displayOnMap: selectedLocation.displayOnMap,
              },
              {
                hasAddress: true, // if we need to keep this get call and it needs to work we might need to modify endpoint to return a hasAddress property
                hasCertifications: selectedLocation.certificationsVerified,
                hasEnvironment: selectedLocation.environmentsVerified,
                hasTechnology: selectedLocation.technologiesVerified,
                tabIndex: 0,
              }
            );
          } else {
            setError({ isError: true, message: LocationErrorMessages.GET_LOCATION });
          }
        });
      }
    }
  }, [locationState.id, locationId, setFormStateCallBack, updateAllLocationStateAndTabState]);

  const update = (updateRequest) => {
    if (updateRequest !== null) {
      LocationUtility.updateLocation(locationState.id, updateRequest).then((response) => {
        if (response && response.data) {
          const updatedLocation = response.data;
          updateAllLocationStateAndTabState &&
            updateAllLocationStateAndTabState(
              {
                id: updatedLocation.id,
                name: updatedLocation.name,
                displayHours: updatedLocation.displayHours,
                regionId: updatedLocation.regionId,
                statusId: updatedLocation.statusId,
                hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
                certificationsVerified: updatedLocation.certificationsVerified,
                environmentsVerified: updatedLocation.environmentsVerified,
                technologiesVerified: updatedLocation.technologiesVerified,
                displayOnMap: updatedLocation.displayOnMap,
              },
              {
                hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
                hasCertifications: updatedLocation.certificationsVerified,
                hasEnvironment: updatedLocation.environmentsVerified,
                hasTechnology: updatedLocation.technologiesVerified,
                tabIndex: 0,
              }
            );
        }
        if (response instanceof HttpErrorResponseModel) {
          setError({ isError: true, message: LocationErrorMessages.UPDATE_LOCATION });
        }
      });
    }
  };
  const debounceUpdate = useCallback(
    debounce((newState: any) => update(newState), 750),
    []
  );

  const handleFieldChange = (input) => (e) => {
    const updateRequest = new UpdateLocationRequestModel({});
    if (input === 'displayHours') {
      setFormState((prevState) => {
        return {
          ...prevState,
          [input]: e.target.checked,
        };
      });
      updateRequest.displayHours = e.target.checked;
      // call update
      update(updateRequest);
    } else if (input === 'name') {
      setFormState((prevState) => {
        return {
          ...prevState,
          [input]: e.target.value,
        };
      });
      updateRequest.name = e.target.value;
      // call debounce update
      debounceUpdate(updateRequest);
    }
    setSaved(true);
  };
  const handleDisplayOnMapChange = (displayOnMap) => {
    const updateRequest = new UpdateLocationRequestModel({});
    setFormState((prevState) => {
      return {
        ...prevState,
        displayOnMap: displayOnMap,
      };
    });
    updateRequest.displayOnMap = displayOnMap;
    update(updateRequest);
    setSaved(true);
  };

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} customLoadingText={'Loading Fitting Location...'} isActive={true} inverted={true} />;
  }
  return (
    <>
      {error.isError ? (
        <LocationInfoError message={error.message} />
      ) : (
        <>
          <div className='split'>
            <LocationTitle saved={saved} setSaved={setSaved} title={LocationTabs[locationTabState.tabIndex].displayName} />
            <LocationInfoStatus />
          </div>
          <div className={styles.fittingFormContainer}>
            <div>
              <LocationName formState={formState} handleFieldChange={handleFieldChange} formRef={editRef} />
              <LocationAddress formRef={editRef} name={formState.name} setSaved={setSaved} />
            </div>
            <div>
              <LocationDisplayOnMap displayOnMap={locationState?.displayOnMap} handleChange={handleDisplayOnMapChange} />
              <LocationContact formRef={editRef} setSaved={setSaved} />
              <LocationDisplayHour formState={formState} formRef={editRef} handleFieldChange={handleFieldChange} />
              {formState.displayHours && <LocationHours formRef={editRef} setSaved={setSaved} />}
            </div>
          </div>
          <LocationInfoFooter tabName={type} statePropertyName={statePropertyName} tabIndex={locationTabState.tabIndex} />
        </>
      )}
    </>
  );
};

export default LocationDetails;
