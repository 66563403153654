import PaginatedRequestModel from 'stores/pagination/IPaginatedRequestModel';

export default class UpdateLocationRequestModel extends PaginatedRequestModel {
  name: string;
  displayHours: boolean;
  displayOnMap: boolean;
  certificationsVerified: boolean;
  environmentsVerified: boolean;
  technologiesVerified: boolean;
  statusId: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
