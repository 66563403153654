import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs } from 'react-pdf';
import LinkListCard from '../../components/LinkList/components/LinkListCard/LinkListCard';
import AccessControl from 'components/AccessControl/AccessControl';
import IStore from '../../models/IStore';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IAccount from 'stores/accounts/models/IAccount';
import PagesAction from 'stores/pages/PagesAction';
import PagesResponseModel from 'stores/pages/models/PagesResponseModel';
import { oc } from 'ts-optchain.macro';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import PagesStatelessCalls from 'stores/pages/PagesStatelessCalls';
import styles from './Pages.module.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface IProps {
  filter?: string[];
  title: string;
  allowedRole: any;
  pageEndpoint: string;
}

function Pages({ filter, title, allowedRole, pageEndpoint }: IProps): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => state.accounts.activeAccount);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const pagesResponse: PagesResponseModel | null = useSelector((state: IStore) => oc(state).pages.pagesResponse(null));
  const pages = pagesResponse?.data;
  const pages4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [PagesAction.REQUEST_PAGE_FINISHED]
  );
  const [pdf, setPdf] = useState('');
  const [video, setVideo] = useState(false);
  const [videoId, setVideoId] = useState<string>('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (pageNumber !== numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const goToPrevPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onClose = () => {
    setPdf('');
    setVideo(false);
  };

  const handleDocumentClick = async (resourceType, vimeoId, pdfUrl) => {
    setIsLoading(true);
    if (resourceType === 'VIDEO') {
      setVideo(true);
      setVideoId(vimeoId);
    } else {
      const pdf = await PagesStatelessCalls.getPDF(pdfUrl);
      setPdf(pdf);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (pageEndpoint) {
      dispatch(PagesAction.requestPage(pageEndpoint));
    }
  }, [dispatch, pageEndpoint, accountNumber]);

  const data = useMemo(() => {
    if (filter) {
      const temp = pages?.content.filter((item) => filter.includes(item.header));

      // Remove duplicates based on header
      const data = temp?.filter((item, index, self) => index === self.findIndex((i) => i.header === item.header));

      return data;
    }

    return pages?.content;
  }, [pages, filter]);

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  return (
    <AccessControl allowedRole={allowedRole} noAccessRedirect={true}>
      <div className='wrapper'>
        {pdf ? (
          <div>
            <nav className={styles.PDFNavContainer}>
              <button onClick={onClose} className={styles.PDFNavContainer__CloseBtn}>
                &times;
              </button>
              <button className={`btn btn__text-only btn--inverted ${styles.PDFNav__btn}`} onClick={goToPrevPage}>
                Prev Page
              </button>
              <button className={`btn btn__text-only btn--inverted ${styles.PDFNav__btn}`} onClick={goToNextPage}>
                Next Page
              </button>
            </nav>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} onContextMenu={(e) => e.preventDefault()}>
              <Page pageNumber={pageNumber} width={1200} />
            </Document>
          </div>
        ) : video ? (
          <div>
            <nav className={styles.videoNavContainer}>
              <button onClick={onClose} className={styles.PDFNavContainer__CloseBtn}>
                &times;
              </button>
            </nav>
            <ReactPlayer url={`https://vimeo.com/${videoId}`} controls={true} width={'1200px'} height={'906px'} />
          </div>
        ) : (
          <Error4xx response={pages4xxResponses['PagesAction.REQUEST_PAGE_FINISHED']}>
            {
              <>
                <h1 className='hdg hdg--1'>{title}</h1>
                <div className='vr4' />
                {data?.map((item, index) => (
                  <div key={index} className='tiles'>
                    <div className='tiles__tile'>
                      <LinkListCard setIsLoading={setIsLoading} documentClick={handleDocumentClick} heading={item.header} list={item.resourceItems} />
                    </div>
                  </div>
                ))}
              </>
            }
          </Error4xx>
        )}
      </div>
    </AccessControl>
  );
}
export default Pages;
