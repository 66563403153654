import { BaseModel } from 'sjs-base-model';
import IStatus from './IStatus';

export default class StatusResponseModel extends BaseModel {
  public readonly data: IStatus[];
  public readonly statusMessage: string = '';
  public readonly statusCode: number = 0;

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
