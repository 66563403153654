// import styles from './Title.module.scss';

import * as React from 'react';

interface IProps {
  readonly title: string;
}
interface IState {}

export default class Title extends React.Component<IProps, IState> {
  // public static defaultProps: Partial<IProps> = {};

  // public state: IState = {};

  public render(): JSX.Element {
    return <div className='hdg hdg--2'>{this.props.title}</div>;
  }
}
