import { generateInitialFields } from './ProductConfigHelper';
import { ESTIMATED_DATE } from '../ProductConfig';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import { IGenerateFieldsConfig } from 'stores/create-order/models/IDynamicFormModels';

export default function productConfigResetForm({
  e,
  accountNumber,
  product,
  setFieldState,
  setMasterFieldObject,
  setStep,
  setIsLoading,
  submittedList,
  setSubmittedList,
  setNotValidProduct,
  setErrorMessage,
  setPriceTotal,
  setNonMasterFieldFormState,
  dispatch,
}) {
  e.preventDefault();
  setMasterFieldObject([]);
  setStep(MAIN_CONFIG.CreateOrder.StartingStep);
  setFieldState(null);
  setSubmittedList([]);
  setErrorMessage('');
  setNonMasterFieldFormState({
    QTY: 1,
    TAGNAME: '',
  });

  ESTIMATED_DATE.resetProperties();

  const requestConfig: IGenerateFieldsConfig = {
    accountNumber,
    product,
    setFieldState,
    setMasterFieldObject,
    step: MAIN_CONFIG.CreateOrder.StartingStep,
    setStep,
    setIsLoading,
    submittedList,
    setSubmittedList,
    setNotValidProduct,
    setErrorMessage,
    setPriceTotal,
    dispatch,
  };

  generateInitialFields(requestConfig);
}
