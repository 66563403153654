import { IAddToCartConfiguration } from 'stores/create-order/models/IAddToCart';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import AddToCartRequestModel from 'stores/create-order/models/AddToCartRequestModel';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../ProductConfig';

export default function productConfigFormSubmit({
  event,
  accountNumber,
  masterFieldObject,
  setErrorMessage,
  dispatch,
  setIsLoading,
  product,
  tabState,
  nonMasterFieldFormState,
  currency,
}) {
  event.preventDefault();
  if (accountNumber) {
    const config: IAddToCartConfiguration[] = masterFieldObject.map(({ attributeValues, ...keep }) => keep);
    const displayConfig = config.filter((item) => item.inputDisplayFlag === 'Y');
    const clubs = config.find((item) => item.attributeName === 'CLUBS');

    // Make Sure all fields are filled
    let error;
    const selectableFieldIsEmpty = {
      isEmpty: false,
      field: '',
    };

    /**
     * Tries to find a field with the selected value of null since '' is technically a legit value although now we would get 'None' instead
     * But we always know null means a user has not selected it so we use that explicitly
     * It also ignores fields that are denoted in the MAIN_CONFIG as non supported fields
     */
    displayConfig.find((item) => {
      if (item.selectedValue === null && !MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed.includes(item.attributeName)) {
        selectableFieldIsEmpty.isEmpty = true;
        selectableFieldIsEmpty.field = item.attributeName;
        return item;
      }

      return null;
    });

    if (selectableFieldIsEmpty.isEmpty) {
      error = `Please Select A ${selectableFieldIsEmpty.field}`;
    }

    if (clubs && (!clubs.selectedValue || clubs?.selectedValue.length === 0)) {
      error = 'Please Select A Club';
    }

    if (error) {
      setErrorMessage(error);
      return;
    }

    // Make Add Request
    const requestConfig = new AddToCartRequestModel({
      accountNumber,
      poNumber: '',
      product,
      quantity: nonMasterFieldFormState.QTY,
      tagName: nonMasterFieldFormState.TAGNAME,
      configuration: config,
      currency,
    });

    /**
     * If we are editing we will make a put request
     * If we are submiting we wull make a post
     */
    setIsLoading(true);
    setErrorMessage();
    if (PRODUCT_CONFIG_LOCAL_STORE.isEditing) {
      CartStatelessCalls.updateCartItem({ cartItemId: tabState.itemToEditId, cartItemRequest: requestConfig }).then((res) => {
        if (!res.data) {
          setIsLoading(false);
          // In this case the res will be an instanceof HttpErrorResponseModel
          // and can be passed to the 4xx component that setErrorMessage feeds into
          setErrorMessage(res);
        } else {
          dispatch(CreateOrderAction.getCart(accountNumber)).then(() => {
            setIsLoading(false);
            dispatch(
              CreateOrderAction.storeTabState({
                ...tabState,
                product: MAIN_CONFIG.CreateOrder.NoProduct,
                tabIndex: 0,
                hasItems: true,
                itemToEditId: null,
              })
            );
          });
        }
      });
    } else {
      CartStatelessCalls.addToCart(requestConfig)
        .then((res) => {
          console.log(res);
          if (!res.data) {
            setIsLoading(false);
            setErrorMessage(res);
          } else {
            dispatch(CreateOrderAction.getCart(accountNumber)).then(() => {
              setIsLoading(false);
              dispatch(
                CreateOrderAction.storeTabState({
                  ...tabState,
                  product: MAIN_CONFIG.CreateOrder.NoProduct,
                  tabIndex: 0,
                  hasItems: true,
                  itemToEditId: null,
                })
              );
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }
}
