import IPagesState from './models/IPagesState';
import PagesAction from './PagesAction';
import PagesResponseModel from './models/PagesResponseModel';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class PagesReducer extends BaseReducer<IPagesState> {
  public readonly initialState: IPagesState = {
    pagesResponse: null,
  };

  public [PagesAction.REQUEST_PAGE_FINISHED](state: IPagesState, action: IAction<PagesResponseModel>): IPagesState {
    return {
      ...state,
      pagesResponse: action.payload!,
    };
  }
}
