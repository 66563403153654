import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import OrderFooter from '../OrderFooter/OrderFooter';
import styles from './OrderConfirmation.module.scss';
interface IProps {
  accountNumber: number | undefined;
}
const OrderConfirmation = ({ accountNumber }: IProps) => {
  const pathname = useLocation().pathname;
  const dispatch: Dispatch = useDispatch();
  const orderNumber = pathname.substring(pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    if (accountNumber) {
      dispatch(CreateOrderAction.getCart(accountNumber));
    }

    return function cleanup() {
      TabStateHelpers.resetTabState(dispatch);
    };
  }, [accountNumber, dispatch]);

  return (
    <>
      <div className={styles['order-confirmation__container']}>
        <h1 className={`${styles['order-confirmation__confirmation-heading']} hdg--3`}>Order Number</h1>
        <h2 className={`${styles['order-confirmation__number']} hdg--3`}> {orderNumber} </h2>
      </div>
      <div className={styles['order-confirmation__blue-box']}>
        <h1 className='hdg hdg--3'>Thank You for Your Order</h1>
        <h2 className=''>
          Your order is currently being processed <br />
        </h2>
      </div>
      <div className={styles['order-confirmation__footer-wrapper']}>
        <OrderFooter stepName='orderConfirmation' onHandleBackToOrders={() => TabStateHelpers.resetTabState(dispatch)} />
      </div>
    </>
  );
};

export default OrderConfirmation;
