import React, { LegacyRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as Info } from '../../../../assets/media/icons/icon-information.svg';

import styles from './InfoIconWithTooltip.module.scss';

interface IProps {
  message?: string;
  alignment?: string;
  iconColor?: string;
}

const InfoIconWithTooltip = ({ message = '', alignment = 'right', iconColor = '' }: IProps): JSX.Element => {
  const [arrowRef, setArrowRef] = useState(null);
  const [icon, setIcon] = useState(null);
  const [popper, setPopper] = useState(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(icon as any, popper as any, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowRef } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  return (
    <div className={styles.iconWrapper}>
      <div className={`${styles.info} ${styles[alignment]}`} ref={setIcon as LegacyRef<HTMLDivElement> | undefined}>
        <Info className={styles[iconColor]} />
      </div>
      {message && (
        <div
          className={styles.tooltip}
          ref={setPopper as LegacyRef<HTMLDivElement> | undefined}
          style={popperStyles.popper}
          {...popperAttributes.popper}
        >
          {message}
          <div ref={setArrowRef as LegacyRef<HTMLDivElement> | undefined} style={popperStyles.arrow} className={styles.arrow}></div>
        </div>
      )}
    </div>
  );
};

export default InfoIconWithTooltip;
