import Toggle from 'components/Toggle/Toggle';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import LocationStatusUtility from 'utilities/Location/LocationStatusUtility';
import styles from './LocationInfoStatus.module.scss';
import LocationUtility from 'utilities/Location/LocationUtility';
import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import ToastsAction from 'stores/toasts/ToastsAction';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
import InfoIconWithTooltip from 'containers/LocationPage/components/InfoIconWithTooltip/InfoIconWithTooltip';

const LocationInfoStatus = () => {
  const dispatch: Dispatch = useDispatch();
  const [statusNameState, setStatusNameState] = useState<string>('');
  const { locationState, statusTable, updateAllLocationState } = useContext(LocationContext);
  const [isToggledOn, setIsToggledOn] = useState<boolean>(false);
  const updateStatusName = useCallback(
    async (statusId: string): Promise<void> => {
      const statusName = await LocationStatusUtility.getStatusName(statusId, statusTable);
      const initialToggle = statusName === 'active' ? true : false;
      setStatusNameState(statusName);
      setIsToggledOn(initialToggle);
    },
    [statusTable]
  );

  const statusMessage = 'Set status to Active to display this location on PING Fitter Maps.';

  useEffect(() => {
    if (locationState.statusId) {
      updateStatusName(locationState.statusId);
    }
  }, [locationState.statusId, updateStatusName]);

  const handleToggle = (name: string) => (e) => {
    const statusName = isToggledOn ? 'inactive' : 'active';
    LocationStatusUtility.getStatusId(statusTable, statusName).then((res) => {
      const updateRequest = new UpdateLocationRequestModel({
        statusId: res,
      });
      LocationUtility.updateLocation(locationState.id, updateRequest).then((response) => {
        if (response && response.data) {
          if (name === 'active') {
            setIsToggledOn(false);
          } else if (name === 'inactive') {
            setIsToggledOn(true);
          }
          const updatedLocation = response.data;
          updateAllLocationState &&
            updateAllLocationState({
              id: updatedLocation.id,
              name: updatedLocation.name,
              displayHours: updatedLocation.displayHours,
              regionId: updatedLocation.regionId,
              statusId: updatedLocation.statusId,
              hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
              certificationsVerified: updatedLocation.certificationsVerified,
              environmentsVerified: updatedLocation.environmentsVerified,
              technologiesVerified: updatedLocation.technologiesVerified,
              displayOnMap: updatedLocation.displayOnMap,
            });
        }
        if (response instanceof HttpErrorResponseModel) {
          dispatch(ToastsAction.add(LocationErrorMessages.UPDATE_LOCATION, ToastStatusEnum.Error));
        }
      });
    });
  };
  return (
    <div className={styles.statusContainer}>
      {statusNameState && (
        <>
          <InfoIconWithTooltip message={statusMessage} alignment='left' iconColor='primary' />
          <p className={styles.statusTitle}>Status:</p>
          {statusNameState === 'draft' ? (
            <p className={styles.statusName}>Inactive</p>
          ) : (
            <p className={styles.statusName}>{statusNameState.charAt(0).toUpperCase() + statusNameState.slice(1)}</p>
          )}
          <Toggle handleToggle={handleToggle} statusName={statusNameState} isOn={isToggledOn} locationId={locationState.id} />
        </>
      )}
    </div>
  );
};

export default LocationInfoStatus;
