import environment from 'environment';
import EffectUtility from 'utilities/EffectUtility';
import AccountProgramsResponseModel from './models/AccountProgramsResponseModel';

export default class AccountUtility {
  public static baseUri: string = `${(environment.api as any).accounts}`;

  /**
   * this returns a list of Account Programs
   * @param accountNumber accountNumber
   */
  public static async getAccountPrograms(accountNumber: string) {
    const endpoint: string = `${AccountUtility.baseUri}/${accountNumber}/programs`;
    const response = await EffectUtility.getToModel<AccountProgramsResponseModel>(AccountProgramsResponseModel, endpoint);
    return response;
  }
}
