/**
 * Finds the next step in the form proccess
 * @param filteredMasterFieldObject Object
 * @param currentIndex number
 * @param newStepConfig Object
 * @param setStep function
 */
export default function getNextStep(filteredMasterFieldObject, currentIndex, newStepConfig, setStep) {
  for (let i = currentIndex; i < filteredMasterFieldObject.length; i++) {
    if (
      filteredMasterFieldObject[i]?.selectedValue ||
      filteredMasterFieldObject[i]?.selectedValue === '' ||
      (filteredMasterFieldObject[i]?.attributeValues.length === 0 && filteredMasterFieldObject[i]?.attributeName !== 'CLUBS') ||
      filteredMasterFieldObject[i]?.attributeName === 'GRAPHICS'
      //  && filteredMasterFieldObject[i].attributeName !== 'CUSTOM FITTING FLAG'
    ) {
      if (
        i === filteredMasterFieldObject.length - 1 &&
        (filteredMasterFieldObject[i].selectedValue === '' || filteredMasterFieldObject[i].selectedValue)
      ) {
        setStep(filteredMasterFieldObject[i].attributeName);
        return {
          newStep: filteredMasterFieldObject[i].attributeName,
          newStepIndex: i,
        };
      }
    } else {
      setStep(filteredMasterFieldObject[i].attributeName);
      return {
        newStep: filteredMasterFieldObject[i].attributeName,
        newStepIndex: i,
      };
    }
  }
}
