import InvoicesResponseModel from './models/InvoicesResponseModel';
import InvoiceResponseModel from './models/InvoiceResponseModel';
import InvoicesEffect from './InvoicesEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import InvoicesRequestModel from './models/InvoicesRequestModel';
import InvoiceRequestModel from './models/InvoiceRequestModel';
import MatchTransactionsResponseModel from './models/MatchTransactionsResponseModel';
import PDFDownloadResponseModel from './models/PDFDownloadResponseModel';

type ActionUnion =
  | void
  | HttpErrorResponseModel
  | InvoicesResponseModel
  | InvoiceResponseModel
  | MatchTransactionsResponseModel
  | PDFDownloadResponseModel
  | InvoicesRequestModel
  | null;

export default class InvoicesAction {
  public static readonly REQUEST_INVOICES: string = 'InvoicesAction.REQUEST_INVOICES';
  public static readonly REQUEST_INVOICES_DASHBOARD: string = 'InvoicesAction.REQUEST_INVOICES_DASHBOARD';
  public static readonly REQUEST_INVOICES_DASHBOARD_FINISHED: string = 'InvoicesAction.REQUEST_INVOICES_DASHBOARD_FINISHED';
  public static readonly REQUEST_INVOICES_FINISHED: string = 'InvoicesAction.REQUEST_INVOICES_FINISHED';
  public static readonly REQUEST_INVOICES_FOR_INVOICE: string = 'InvoicesAction.REQUEST_INVOICES_FOR_INVOICE';
  public static readonly REQUEST_INVOICES_FOR_INVOICE_FINISHED: string = 'InvoicesAction.REQUEST_INVOICES_FOR_INVOICE_FINISHED';
  public static readonly RESET_INVOICES_FOR_INVOICE: string = 'InvoicesAction.RESET_INVOICES_FOR_INVOICE';
  public static readonly REQUEST_INVOICE: string = 'InvoicesAction.REQUEST_INVOICE';
  public static readonly REQUEST_INVOICE_FINISHED: string = 'InvoicesAction.REQUEST_INVOICE_FINISHED';
  public static readonly REQUEST_TRANSACTIONS: string = 'InvoicesAction.REQUEST_TRANSACTIONS';
  public static readonly REQUEST_INVOICE_TRANSACTIONS: string = 'InvoicesAction.REQUEST_INVOICE_TRANSACTIONS';
  public static readonly REQUEST_INVOICE_TRANSACTIONS_FINISHED: string = 'InvoicesAction.REQUEST_INVOICE_TRANSACTIONS_FINISHED';
  public static readonly REQUEST_TRANSACTIONS_FINISHED: string = 'InvoicesAction.REQUEST_TRANSACTIONS_FINISHED';
  public static readonly REQUEST_CREDIT_SUMMARY: string = 'InvoicesAction.REQUEST_CREDIT_SUMMARY';
  public static readonly REQUEST_CREDIT_SUMMARY_FINISHED: string = 'InvoicesAction.REQUEST_CREDIT_SUMMARY_FINISHED';
  public static readonly REQUEST_INVOICE_RESET: string = 'InvoicesAction.REQUEST_INVOICE_RESET';
  public static readonly REQUEST_MEMO_RESET: string = 'InvoicesAction.REQUEST_MEMO_RESET';
  public static readonly REQUEST_INVOICE_MEMO: string = 'InvoicesAction.REQUEST_INVOICE_MEMO';
  public static readonly REQUEST_INVOICE_MEMO_FINISHED: string = 'InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED';
  public static readonly REQUEST_INVOICE_PDF_CHECKED: string = 'InvoicesAction.REQUEST_INVOICE_PDF_CHECKED';
  public static readonly REQUEST_INVOICE_PDF_ALL_CHECKED: string = 'InvoicesAction.REQUEST_INVOICE_PDF_ALL_CHECKED';
  public static readonly REQUEST_MATCH_TRANSACTION: string = 'InvoicesAction.REQUEST_MATCH_TRANSACTION';
  public static readonly REQUEST_MATCH_TRANSACTION_FINISHED: string = 'InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED';
  public static readonly DOWNLOAD_PDFS: string = 'InvoicesAction.DOWNLOAD_PDFS';
  public static readonly DOWNLOAD_PDFS_FINISHED: string = 'InvoicesAction.DOWNLOAD_PDFS_FINISHED';
  public static readonly RESET_TRANSACTIONS: string = 'InvoicesAction.RESET_TRANSACTIONS';
  public static readonly RESET_TRANSACTION_HISTORY: string = 'InvoicesAction.RESET_TRANSACTION_HISTORY';
  public static readonly RESET_INVOICES: string = 'InvoicesAction.RESET_INVOICES';
  public static readonly SAVE_FILTERS: string = 'InvoicesAction.SAVE_FILTERS';
  public static readonly SAVE_INVOICES_FILTERS: string = 'InvoicesAction.SAVE_INVOICES_FILTERS';

  public static requestInvoices(invoicesRequestConfig: InvoicesRequestModel, accountNumber: number, dashboard?, setLoading?): any {
    if (setLoading) {
      setLoading(true);
    }
    if (dashboard) {
      return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
        await ActionUtility.createThunkEffect<InvoicesResponseModel>(
          dispatch,
          InvoicesAction.REQUEST_INVOICES_DASHBOARD,
          InvoicesEffect.requestInvoices,
          {
            ...invoicesRequestConfig,
          },
          accountNumber
        );
        if (setLoading) {
          setLoading(false);
        }
      };
    }

    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.SAVE_INVOICES_FILTERS, payload: invoicesRequestConfig });
      if (setLoading) {
        setLoading(true);
      }

      await ActionUtility.createThunkEffect<InvoicesResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_INVOICES,
        InvoicesEffect.requestInvoices,
        {
          ...invoicesRequestConfig,
        },
        accountNumber
      );
      if (setLoading) {
        setLoading(false);
      }
    };
  }

  public static requestTransactionHistory(transactionHistoryRequestConfig: InvoicesRequestModel, accountNumber: number, setLoading?): any {
    if (setLoading) {
      setLoading(true);
    }

    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.SAVE_FILTERS, payload: transactionHistoryRequestConfig });
      await ActionUtility.createThunkEffect<InvoicesResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_TRANSACTIONS,
        InvoicesEffect.requestInvoices,

        {
          ...transactionHistoryRequestConfig,
        },
        accountNumber
      );

      if (setLoading) {
        setLoading(false);
      }
    };
  }

  public static requestMatchTransactions(accountNum: number, tranNumber: number, tranType: string, tranDate: string): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<MatchTransactionsResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_MATCH_TRANSACTION,
        InvoicesEffect.requestMatchTransactions,
        accountNum,
        tranNumber,
        tranType,
        tranDate
      );
    };
  }

  // TODO: deprecate in favor of match
  public static requestInvoiceTransactionHistory(
    transactionHistoryRequestConfig: Partial<InvoicesRequestModel>,
    accountNumber: number,
    invoiceNumber: number
  ): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<InvoicesResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_INVOICE_TRANSACTIONS,
        InvoicesEffect.requestInvoiceTransactionHistory,
        {
          ...transactionHistoryRequestConfig,
        },
        accountNumber,
        invoiceNumber
      );
    };
  }

  public static requestInvoice(invoiceNumber: number): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<InvoiceResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_INVOICE,
        InvoicesEffect.requestInvoice,
        invoiceNumber
      );
    };
  }

  public static resetInvoice(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.REQUEST_INVOICE_RESET, payload });
    };
  }

  /**
   * TODO: after IS finishes individual invoice and memo api this is uneeded.
   * @param payload
   */
  public static resetInvoicesForInvoice(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.RESET_INVOICES_FOR_INVOICE, payload });
    };
  }

  public static resetMemo(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.REQUEST_MEMO_RESET, payload });
    };
  }

  public static resetInvoices(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.RESET_INVOICES, payload });
    };
  }

  public static resetTransactionHistory(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.RESET_TRANSACTION_HISTORY, payload });
    };
  }

  public static resetTransactions(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.RESET_TRANSACTIONS, payload });
    };
  }

  /**
   * Makes and stores a transactions request of a single transaction
   *
   * TODO: unneeded once IS finishes the new invoice and memo api
   * @param invoicesRequestConfig should be only setting referenceId
   * @param accountNumber account number
   */
  public static requestInvoicesInfoForInvoice(invoicesRequestConfig: InvoicesRequestModel, accountNumber: number, setIsLoading?): any {
    if (setIsLoading) {
      setIsLoading(true);
    }
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<InvoicesResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_INVOICES_FOR_INVOICE,
        InvoicesEffect.requestInvoices,
        {
          ...invoicesRequestConfig,
        },
        accountNumber
      ).then((response) => {
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (response instanceof HttpErrorResponseModel) {
          dispatch({ type: InvoicesAction.REQUEST_INVOICES_FOR_INVOICE_FINISHED, payload: null });
        }
      });
    };
  }

  public static requestInvoiceMemo(
    invoicesRequestConfig: Partial<InvoiceRequestModel>,
    accountMemoNumber: number,
    memoNumber: number,
    memoType: string
  ): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<InvoicesResponseModel>(
        dispatch,
        InvoicesAction.REQUEST_INVOICE_MEMO,
        InvoicesEffect.requestInvoiceMemo,
        {
          ...invoicesRequestConfig,
        },
        accountMemoNumber,
        memoNumber,
        memoType
      );
    };
  }

  public static downloadPDFs(pdfList: string[], accountNumber: number): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<PDFDownloadResponseModel>(
        dispatch,
        InvoicesAction.DOWNLOAD_PDFS,
        InvoicesEffect.downloadPDFs,
        pdfList,
        accountNumber
      );
    };
  }

  public static invoiceCheckbox(payload, individual?): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.REQUEST_INVOICE_PDF_CHECKED, payload, individual });
    };
  }

  public static invoiceAllCheckbox(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: InvoicesAction.REQUEST_INVOICE_PDF_ALL_CHECKED, payload });
    };
  }
}
