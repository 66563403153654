import { BaseModel } from 'sjs-base-model';
import ISortData from 'stores/dataTable/models/ISortData';

/*
    // Returned Api Data Sample
    {
      currentPage: 1,
      currentPageSize: 10,
      data: [],
      message: '',
      status: '',
      statusCode: 200,
      totalCount: 0,
      totalPages: 1,
    }
 */
export default class PaginatedRequestModel extends BaseModel {
  pageSize: number = 10;
  page: number = 1;
  sortData: ISortData;
  filter: string; // *

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<PaginatedRequestModel> = {}) {
    super();

    this.update(data);
  }

  public update(data: Partial<PaginatedRequestModel>): void {
    super.update(data);
  }
}
