import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { oc } from 'ts-optchain.macro';
import IStore from 'models/IStore';
import IAccount from '../../stores/accounts/models/IAccount';
import IUser from 'stores/users/models/IUser';
import UsersAction, { IRolesFilterState } from 'stores/users/UsersAction';
import AccessControl from 'components/AccessControl/AccessControl';
import RouteEnum from 'constants/RouteEnum';
import UserRolesEnum from 'constants/UserRolesEnum';
import { ReactComponent as Plus } from '../../assets/media/icons/icon-plus.svg';
import { Link } from 'react-router-dom';
import UsersList from '../AccountProfilePage/components/UsersList/UsersList';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import UsersResponseModel from 'stores/users/models/UsersResponseModel';
import UserSearch from './components/UserSearch';

const AdminPanelPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: Dispatch = useDispatch();
  const currentUser: IUser | undefined = useSelector((state: IStore) => oc(state).users.currentUser.data(undefined));
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const usersList: UsersResponseModel | null = useSelector((state: IStore) => oc(state).users.users(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => state.users.rolesFilters);
  const selectedUserRoleFilter = rolesFilter?.adminPanelFilter ? rolesFilter.adminPanelFilter : '';

  const [usersRequestConfig, setUsersRequestConfig] = useState(
    new UsersRequestModel({
      membership: selectedUserRoleFilter,
      page: 1,
    })
  );

  const adminPanelUsersList4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [UsersAction.REQUEST_USERS_FINISHED]
  );

  // Wait for all the API calls to finish gathering data before rendering the page
  // No need to show the loader unless the user has selected an account

  const onNewPageClick = (page: number) => {
    const requestConfig = new UsersRequestModel({
      ...usersRequestConfig,
      page,
    });

    setUsersRequestConfig(requestConfig);

    setIsLoading(true);
    dispatch(UsersAction.requestUsers(requestConfig)).then(() => {
      setIsLoading(false);
    });
  };

  const handleSearch = (e, input) => {
    e.preventDefault();
    const requestConfig = new UsersRequestModel({
      ...usersRequestConfig,
      filter: input,
    });

    setUsersRequestConfig(requestConfig);

    setIsLoading(true);
    dispatch(UsersAction.requestUsers(requestConfig)).then(() => {
      setIsLoading(false);
    });
  };

  const onRolesFilterChange = (membership: string) => {
    setUsersRequestConfig(
      new UsersRequestModel({
        ...usersRequestConfig,
        membership,
        page: 1,
      })
    );

    // Needed because set is not fast enough to set before dispatch goes off.
    const requestConfig = new UsersRequestModel({
      ...usersRequestConfig,
      membership,
    });

    setIsLoading(true);
    dispatch(UsersAction.requestUsers(requestConfig)).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!usersList) {
      setIsLoading(true);
      dispatch(UsersAction.requestUsers(usersRequestConfig)).then(() => {
        setIsLoading(false);
      });
    }

    if (usersList) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, dispatch, usersList]);

  return (
    <div className='wrapper'>
      <div className='split'>
        <h1 className='hdg hdg--1'>Admin Panel</h1>
        <div>
          <AccessControl allowedRole={UserRolesEnum.ManageUsers}>
            <Link to={`${RouteEnum.CreateUsersPageAdmin}`} className='btn'>
              <span className='btn__text'>Add New User</span>
              <span className='btn__icon'>
                <Plus aria-hidden='true' focusable='false' role='img' />
              </span>
            </Link>
          </AccessControl>
        </div>
      </div>
      <div className='vr2' />
      <UserSearch handleSubmit={handleSearch} />
      <div className='vr2' />
      {currentUser && (
        <>
          <UsersList
            isLoading={isLoading}
            users={usersList}
            currentUser={currentUser}
            userMembership={usersRequestConfig.membership}
            onRolesFilterChange={onRolesFilterChange}
            onNewPageClick={onNewPageClick}
            fromAdmin={true}
            errorStoreToCheck={'admin'}
            usersList4xxErrorResponses={adminPanelUsersList4xxResponses}
          />
        </>
      )}
    </div>
  );
};
export default AdminPanelPage;
