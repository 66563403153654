import styles from '../InvoicePage/InvoicePage.module.scss';

import React, { useEffect, useState } from 'react';

import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import InvoicesAction from '../../stores/invoices/InvoicesAction';
import InvoiceRequestModel from '../../stores/invoices/models/InvoiceRequestModel';
import InvoiceResponseModel from '../../stores/invoices/models/InvoiceResponseModel';
import InvoiceMemoResponseModel from '../../stores/invoices/models/InvoiceMemoResponseModel';
import IStore from '../../models/IStore';
import { oc } from 'ts-optchain.macro';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import moment from 'moment';
import RouteEnum from 'constants/RouteEnum';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import { ReactComponent as DownloadArrow } from '../../assets/media/icons/icon-download.svg';
import IAccount from '../../stores/accounts/models/IAccount';
import MatchedTransactions from '../InvoicePage/components/matchedTransactions';
import MemoTotals from '../InvoicePage/components/memoTotals';
import MemoProductList from '../InvoicePage/components/memoProductList';
import OrdersAction from '../../stores/orders/OrdersAction';
import IMatchTransaction from 'stores/invoices/models/IMatchTransaction';
import CrediDebitHeader from '../InvoicePage/components/creditDebitHeader';
import { downloadPDF } from '../InvoicePage/helpers/pdfDownloadHelperFunctions';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import InvoicesRequestModel from 'stores/invoices/models/InvoicesRequestModel';
import InvoicesResponseModel from 'stores/invoices/models/InvoicesResponseModel';
import Value from 'components/Value/Value';
import { pageLoader } from 'components/PageLoader/PageLoader';

function MemoPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const invoice4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [
      InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED,
      InvoicesAction.REQUEST_INVOICE_FINISHED,
      OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED,
      InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED,
    ]
  );
  const { id } = useParams<any>();
  const invoiceNumber = id;
  const { memoId } = useParams<any>();
  const memoNumber = memoId;
  const { memoType } = useParams<any>();
  const { accountMemoNumber } = useParams<any>();
  const pdfChecklist = useSelector((state: IStore) => state.invoices.pdfChecklistResponse);
  const [pdfLoading, setPdfLoading] = useState(false);

  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const invoicesResponseForInvoice: InvoicesResponseModel | null = useSelector((state: IStore) =>
    oc(state).invoices.invoicesResponseForInvoice(null)
  );
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const invoiceResponse: InvoiceResponseModel | null = useSelector((state: IStore) => oc(state).invoices.invoiceResponse(null));

  const invoiceMemoResponse: InvoiceMemoResponseModel | null = useSelector((state: IStore) => oc(state).invoices.invoiceMemoResponse(null));

  const matched: IMatchTransaction[] | undefined = useSelector((state: IStore) => oc(state).invoices.matchTransactionsResponse.data(undefined));

  // This is the tran type being passed into the memo request
  const [invoiceRequestConfig] = useState(
    new InvoiceRequestModel({
      transactionType: 'INV',
    })
  );

  /**
   * TODO: Add refrenceId to invoices call
   * make an invoices call in use effects using referenceId
   * grab transactionDueDate and pastDue
   * Display on tab
   */

  // This requests the invoice memo
  useEffect(() => {
    if (accountMemoNumber && accountNumber) {
      dispatch(InvoicesAction.requestInvoiceMemo(invoiceRequestConfig, accountMemoNumber, memoNumber, memoType));
      const transactionsRequestModel = new InvoicesRequestModel({
        referenceId: memoNumber,
      });
      dispatch(InvoicesAction.requestInvoicesInfoForInvoice(transactionsRequestModel, accountNumber));
    }

    return function cleanup() {
      dispatch(InvoicesAction.resetMemo(null));
    };
  }, [dispatch, accountMemoNumber, invoiceRequestConfig, memoNumber, memoType, accountNumber]);

  useEffect(() => {
    if (accountNumber && invoiceMemoResponse) {
      const tranData = invoiceMemoResponse?.data[0].creditDebitHeader.addressList;
      dispatch(
        InvoicesAction.requestMatchTransactions(
          accountNumber,
          tranData[0].transNumber,
          tranData[0].transType,
          moment(tranData[0].transDate).format(DateFormatsEnum.HyphensForCalls)
        )
      );
    }
  }, [dispatch, accountNumber, invoiceResponse, invoiceMemoResponse]);

  const memoData = invoiceMemoResponse?.data.map((item) => {
    return {
      headerList: item.creditDebitHeader.headerList[0],
      addressList: item.creditDebitHeader.addressList[0],
      itemList: item.creditDebitDetails,
      creditDebitTotals: item.creditDebitTotals[0],
    };
  });

  function addPdfToList(invoiceNumber) {
    dispatch(InvoicesAction.invoiceCheckbox(invoiceNumber));
  }

  const formattedDueDate = invoicesResponseForInvoice?.data[0].transactionDueDate
    ? moment(invoicesResponseForInvoice?.data[0].transactionDueDate).format(DateFormatsEnum.WrittenDayMonthYear)
    : undefined;
  const invoiceStatus: string | undefined = invoicesResponseForInvoice?.data[0].transactionStatus;
  const pastDue = invoicesResponseForInvoice?.data[0].pastDue;

  if (!activeAccount) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewTransactions} noAccessRedirect={true}>
      <div>
        <div className='wrapper'>
          <div>
            <ArrowLink position='back' classNames='vr1_5' to={RouteEnum.Transactions}>
              Back to Invoice Summary
            </ArrowLink>
          </div>
          <div className='split'>
            <h1 className={`hdg hdg--1 ${styles.invoiceHeader}`}>
              {memoType === 'CM' ? 'Credit' : 'Debit'} Memo - {memoNumber}
              {invoiceStatus ? (
                <>
                  <Value value={invoiceStatus} propertyName='transactionStatus' />
                  {pastDue && <Value value={'PAST DUE'} propertyName='transactionStatus' />}
                </>
              ) : (
                <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 15 }} />
              )}
            </h1>
            <div className={styles.invoiceHeader__right}>
              {pdfLoading ? (
                <div className={'btn--loading'}>
                  <LoadingIndicator isActive={true} inverted={true} noLoadingText={true} />
                </div>
              ) : (
                <button onClick={() => downloadPDF(memoNumber, accountNumber, setPdfLoading)} className={`btn btn__icon ${styles.btn__download}`}>
                  <span className={styles.btn__download__label}>Download Invoice {pdfLoading && 'loading'}</span>
                  <DownloadArrow width='16' height='26' aria-hidden='true' focusable='false' role='img' />
                </button>
              )}
              <button
                type='button'
                className={`btn btn__text-only ${pdfChecklist.includes(memoNumber) && 'btn--delete-user'}`}
                onClick={() => addPdfToList(memoNumber)}
              >
                {pdfChecklist.includes(memoNumber) ? 'Remove From PDF download list' : 'Add to PDF download list'}
              </button>
            </div>
          </div>
          <div className={styles.invoiceGrid}>
            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED']}>
              {memoData ? (
                <CrediDebitHeader
                  invoiceData={memoData}
                  invoiceNumber={invoiceNumber}
                  invoiceStatus={pastDue ? 'PAST DUE' : invoiceStatus}
                  formattedDueDate={formattedDueDate}
                  memoNumber={memoNumber}
                  memoType={memoType}
                />
              ) : (
                <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
              )}
            </Error4xx>

            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED']}>
              {memoData && <MemoProductList memoData={memoData} />}
            </Error4xx>

            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED']}>
              {memoData && <MemoTotals memoData={memoData} />}
            </Error4xx>
          </div>
        </div>

        {invoice4xxResponses['InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED'] && <h3 className='hdg hdg--3'>Matched Transactions</h3>}
        <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED']}>
          <MatchedTransactions transactions={matched} accountNumber={accountNumber} invoiceNumber={invoiceNumber} />
        </Error4xx>
      </div>
    </AccessControl>
  );
}
export default MemoPage;
