import React from 'react';
import Footer from 'components/Footer/Footer';
import styles from './MaintenancePage.module.scss';
import logo from 'assets/media/images/header_logo.svg';
import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';

const MaintenancePage = () => {
  return (
    <div className={styles.maintenance__wrapper}>
      <img src={logo} alt='' style={{ height: '44px', width: '250px' }} className={styles['primaryNavHeader-logo_big']} />
      <div className={styles.box__bd}>
        <h1 className={styles.header}>This site is currently undergoing maintenance.</h1>
        <p className={styles.body}>
          We’re sorry about any inconvenience this may cause. We hope to have the site back up and running momentarily.
          <br /> <br />
          For any urgent matters please contact PING Customer Service at{' '}
          <strong>
            <PingCustomerServiceContact />
          </strong>
          .
        </p>
      </div>
      <div>
        <Footer isInverse={false} />
      </div>
    </div>
  );
};

export default MaintenancePage;
