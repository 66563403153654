import styles from '../InvoicePage.module.scss';
import { Link } from 'react-router-dom';
import RouteEnum from '../../../constants/RouteEnum';
import React from 'react';
import { ReactComponent as PingLogo } from '../../../assets/media/icons/PING-logo.svg';
import moment from 'moment';
import DateFormatsEnum from '../../../constants/DateFormatsEnum';
import { PingAddress } from './PingAddress';

const CrediDebitHeader = ({ invoiceData, invoiceNumber, invoiceStatus, formattedDueDate, memoNumber, memoType }) => {
  const invoiceDataPulled = invoiceData[0];
  const formattedOrderDate = moment(invoiceDataPulled.headerList.orderDate).format(DateFormatsEnum.WrittenDayMonthYear);
  const formattedTransactionDate = moment(invoiceDataPulled.addressList.transDate).format(DateFormatsEnum.WrittenDayMonthYear);
  return (
    <div>
      <div className={styles['invoice-grid']}>
        <div className={`${styles['invoice-grid__list__item']} ${styles.grow3}`}>
          <div className={styles.logo}>
            <span className={styles.logo__label}>PING</span>
            <PingLogo aria-hidden='true' focusable='false' role='img' />
          </div>
          <PingAddress />
        </div>
        <div className={styles['invoice-grid__list__item']}>
          <div className={styles['invoice-details']}>
            <h3 className='hdg hdg--3 hdg--miniPadding'>
              {`${memoType === 'CM' ? 'Credit' : 'Debit'}`} Memo - {memoNumber}
            </h3>
          </div>

          <div className={styles.wrapper}>
            <div className={styles.statusBar} data-transactionstatus={invoiceStatus}></div>
            <div className={styles.content}>
              <p className={styles.status} data-transactionstatus={invoiceStatus}>
                {invoiceData && invoiceData[0].addressList.transType === 'DM ' ? 'Net Due Date' : 'Transaction Date'}
              </p>
              <p className={styles.balance}>
                {invoiceData && invoiceData[0].addressList.transType === 'DM ' ? formattedDueDate : formattedTransactionDate}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* TODO: getting multiple addresses objects from api need to see if we can normalize this */}
      {/* TODO: Change Memo's below to use BillToShipTo Component instead of bellow */}
      <div className={styles['invoice-grid']}>
        <div className={`${styles['invoice-grid__list__item']} ${styles.grow2}`}>
          <h1 className='hdg hdg--3 hdg--miniPadding'>Bill To:</h1>
          <p>{invoiceDataPulled.addressList.billToBusiness}</p>
          <p>{invoiceDataPulled.addressList.billToName}</p>
          <p>{invoiceDataPulled.addressList.billToAddress1}</p>
          <p>
            {invoiceDataPulled.addressList.billToCity}, {invoiceDataPulled.addressList.billToState} {invoiceDataPulled.addressList.billToZip}
          </p>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.grow2}`}>
          <h1 className='hdg hdg--3 hdg--miniPadding'>Ship To:</h1>
          <p>{invoiceDataPulled.addressList.shipToName}</p>
          <p>{invoiceDataPulled.addressList.shipToBusiness}</p>
          <p>{invoiceDataPulled.addressList.shipToAddress1}</p>
          <p>&nbsp;</p>
          <div>
            {invoiceDataPulled.addressList.shipToCity}, {invoiceDataPulled.addressList.shipToState} {invoiceDataPulled.addressList.shipToZip}
          </div>
          <div>{invoiceDataPulled.addressList.shipToCountry}</div>
        </div>
      </div>

      <dl className={styles['invoice-grid']}>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Customer Number</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.acctNumber ? invoiceDataPulled.headerList.acctNumber : '--'}
          </dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>P.O. Number</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.pONumber ? invoiceDataPulled.headerList.pONumber : '--'}
          </dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Refer to</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.orderNumber ? (
              <Link className='focus-ring' to={`${RouteEnum.Orders}/${invoiceDataPulled.headerList.orderNumber}`}>
                {invoiceDataPulled.headerList.orderNumber}
              </Link>
            ) : (
              '--'
            )}
          </dd>
        </div>
        {invoiceData && invoiceData[0].addressList.transType === 'DM ' && (
          <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
            <dt className={styles['invoice-grid__list__item__label']}>Transaction Date</dt>
            <dd className={styles['invoice-grid__list__item__value']}>{formattedOrderDate}</dd>
          </div>
        )}

        <div className={`${styles['invoice-grid__list__item']} ${styles.card} ${styles.grow2}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Reason</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.reasonDescription ? invoiceDataPulled.headerList.reasonDescription : '--'}
          </dd>
        </div>

        <div className={`${styles['invoice-grid__list__item']} ${styles.card} ${styles.grow2}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Terms</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.termDescription ? invoiceDataPulled.headerList.termDescription : '--'}
          </dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>SLS</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {invoiceDataPulled.headerList.salesRepNumber ? invoiceDataPulled.headerList.salesRepNumber : '0'}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default CrediDebitHeader;
