import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './LocationCard.module.scss';
import LocationCardSection from '../LocationCardSection/LocationCardSection';
import ILocations from 'utilities/Location/models/ILocations';
import { useHistory } from 'react-router';
import RouteEnum from 'constants/RouteEnum';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationStatusUtility from 'utilities/Location/LocationStatusUtility';
import { ReactComponent as Trashcan } from '../../../../assets/media/icons/icon-trashcan.svg';
import { ReactComponent as Pencil } from '../../../../assets/media/icons/icon-pencil.svg';
import LocationCardModal from '../LocationCardModal/LocationCardModal';
import { ReactComponent as ErrorIcon } from '../../../../assets/media/icons/icon-error.svg';

interface IProps {
  location: ILocations;
}
const LocationCard = ({ location }: IProps): JSX.Element => {
  const COLUMN_LABELS = ['Location Name', 'Address', 'City', 'State', 'Status', 'Visibility', ''];
  const VALUE_PROPS = ['name', 'street', 'city', 'state', 'status', 'visibility', ''];
  const history = useHistory();
  const [editLocation, setEditLocation] = useState<boolean>(false);
  const [statusName, setStatusName] = useState<string>('');
  const locationContext = useContext(LocationContext);
  const { statusTable, updateAllLocationStateAndTabState } = locationContext;
  const [modalState, setModalState] = useState(false);

  const isEditing = () => {
    setEditLocation(!editLocation);
  };

  const handleEditLocation = (locationId: string) => {
    // Set Location Context
    if (updateAllLocationStateAndTabState) {
      updateAllLocationStateAndTabState(
        {
          id: location.id,
          name: location.name,
          displayHours: location.displayHours,
          regionId: location.regionId,
          statusId: location.statusId,
          hasAddress: Boolean(location.address.latitude !== 0 && location.address.longitude !== 0),
          certificationsVerified: location.certificationsVerified,
          environmentsVerified: location.environmentsVerified,
          technologiesVerified: location.technologiesVerified,
          displayOnMap: location.displayOnMap,
        },
        {
          hasAddress: Boolean(location.address.latitude !== 0 && location.address.longitude !== 0),
          hasCertifications: location.certificationsVerified,
          hasEnvironment: location.environmentsVerified,
          hasTechnology: location.technologiesVerified,
          tabIndex: 0,
        }
      );
    }
    history.push(`${RouteEnum.FittingLocation}/${locationId}`);
  };

  const handleDeleteLocation = () => {
    setModalState(true);
  };

  const getStatusName = useCallback(async (): Promise<void> => {
    const name = await LocationStatusUtility.getStatusName(location.statusId, statusTable);
    setStatusName(name);
  }, [statusTable, location.statusId]);

  useEffect(() => {
    if (location.statusId) {
      getStatusName();
    }
  }, [location.statusId, getStatusName]);

  return (
    <div key={location.id}>
      <div className={styles['location-card']}>
        {COLUMN_LABELS.map((label, index) => {
          return (
            <LocationCardSection
              label={label}
              location={location}
              valueProp={VALUE_PROPS[index]}
              key={index}
              isEditing={isEditing}
              statusName={statusName}
            />
          );
        })}
        {statusName === 'suspended' && (
          <div className={styles.suspendedBox}>
            <ErrorIcon className={`btn_icon ${styles.error_icon}`} aria-hidden='true' focusable='false' role='img' />
            <p className={styles.suspendedBox_text}>This listing has been flagged. Please contact your administrator to resolve this item.</p>
          </div>
        )}
      </div>
      {editLocation && (
        <div className={styles.editContainer}>
          <div className={styles.editContainer_link}>
            <Pencil className='btn_icon icon--dir-90' aria-hidden='true' focusable='false' role='img' />
            <p className={styles.editContainer_text} onClick={() => handleEditLocation(location.id)}>
              Edit Location
            </p>
          </div>
          <div className={styles.editContainer_link}>
            <Trashcan className='btn_icon' aria-hidden='true' focusable='false' role='img' />
            <p className={styles.editContainer_textDelete} onClick={handleDeleteLocation}>
              Remove Location
            </p>
          </div>
        </div>
      )}
      {modalState && <LocationCardModal setModalState={setModalState} location={location} />}
    </div>
  );
};

export default LocationCard;
