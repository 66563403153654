import Pagination from 'components/Pagination/Pagination';
import React, { useMemo } from 'react';
import ISerialNumberProduct, { IProductAtrribute } from 'stores/serial-number/models/ISerialNumberProduct';
import SerialNumberProductResponse from 'stores/serial-number/models/SerialNumberProductResponseModel';
import styles from './SerialLookupTable.module.scss';
import SerialLookupTable from './SerialLookupTable';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
export interface ISerialLookupFilters {
  model: string;
  club: string;
  product: string;
}
interface IProps {
  data?: ISerialNumberProduct[] | null;
  onNewPageClick: (page: number) => void;
  serialNumberResponse?: SerialNumberProductResponse | null;
  selectedRowId?: string;
  updateSelectedRow: (row: any) => void;
  onSort: (column: any) => void;
  sortConfig: any;
  error?: HttpErrorResponseModel | undefined | null;
}
const SerialLookupItems = (props: IProps) => {
  const { data, serialNumberResponse, selectedRowId, updateSelectedRow, onSort, sortConfig, error } = props;
  const columns = useMemo(
    () => [
      {
        Header: <div className={`${styles['left-align']}`}>Serial Number</div>,
        sortable: false,
        accessor: 'serialNumber',
        Cell: ({ cell: { value } }) => <div className={styles['left-align']}>{value}</div>,
      },
      {
        Header: () => <div className={`${styles['left-align']}`}>Model</div>,
        sortable: true,
        accessor: 'attributes',
        id: 'model',
        Cell: ({ cell: { value } }) => {
          const model: IProductAtrribute = value.find((attribute) => attribute?.name?.toLowerCase() === 'model');
          return <div className={styles['left-align']}>{model?.value}</div>;
        },
      },
      {
        Header: () => <div className={`${styles['left-align']}`}>Club</div>,
        sortable: true,
        accessor: 'attributes',
        id: 'club',
        Cell: ({ cell: { value } }) => {
          const club: IProductAtrribute = value.find((attribute) => attribute?.name?.toLowerCase() === 'club');
          return <div className={styles['left-align']}>{club?.value}</div>;
        },
      },
      {
        Header: () => <div className={`${styles['left-align']}`}>Product</div>,
        sortable: true,
        accessor: 'product',
        Cell: ({ cell: { value } }) => <div className={styles['left-align']}>{value}</div>,
      },
    ],
    //eslint-disable-next-line
    [sortConfig]
  );
  return (
    <>
      <div className={styles['serial-table-card']}>
        <SerialLookupTable
          columns={columns}
          data={data}
          selectedRowId={selectedRowId}
          updatedSelectedRow={updateSelectedRow}
          onSortColumnClick={onSort}
          sortCol={sortConfig?.column}
          sortDir={sortConfig?.sortDirection}
        />
      </div>
      <Pagination data={!error ? serialNumberResponse : { currentPage: 1, totalPages: 1 }} onNewPageClick={props.onNewPageClick} />
    </>
  );
};
export default SerialLookupItems;
