import React, { createRef, useRef, useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import IDynamicNonMasterFormFields, {
  IDynamicFormAttribute,
  IDynamicFormDependencyUpdateFields,
  IDynamicFormFields,
  IPriceTotalState,
  IFinalEstimatedDateState,
} from 'stores/create-order/models/IDynamicFormModels';
import DynamicFieldEnum from 'constants/DynamicFieldEnum';
import DynamicTextField from '../dynamicInputs/DynamicTextField';
import DynamicSelectField from '../dynamicInputs/DynamicSelectField';
import IDynamicFormValues from 'stores/create-order/IDynamicFormValues';
import OrderFooter from '../OrderFooter/OrderFooter';
import { requestFieldUpdates } from '../productConfig/helpers/ProductConfigHelper';
import styles from './DynamicForm.module.scss';
import DynamicTextFieldSemantic from '../dynamicInputs/DynamicTextSemantic';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ProductsArr, { IProduct } from 'constants/ProductsArr';
import Error4xx from 'components/Error4xx/Error4xx';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import IStore from 'models/IStore';
import { useSelector } from 'react-redux';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { oc } from 'ts-optchain.macro';
import checkDuplicates from '../productConfig/helpers/checkDuplicates';
import DynamicFormPanel from './components/DynamicFormPanel';
import { INonMasterFormFieldState } from '../productConfig/ProductConfig';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import GetEstimatedDatesRequestModel from 'stores/create-order/models/GetEstimatedDatesRequestModel';
import GetPriceRequestModel from 'stores/create-order/models/GetPriceRequestModel';
import GetPriceResponseModel from 'stores/create-order/models/GetPriceResponseModel';
import GetEstimatedDatesResponseModel from 'stores/create-order/models/GetEstimatedDatesResponseModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import useAccountCurrency from 'utilities/useAccountCurrency';
import UserRolesEnum from 'constants/UserRolesEnum';
import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';
import { PingCustomerServiceContactMessage } from 'components/Content/Contact/CustomerServiceContactMessage';

export interface DynamicFormProps {
  // fields: Array<IDynamicFormFields>;
  fields: IDynamicFormFields[];
  product: any;
  setFieldState: (fields) => void;
  fieldState: any;
  setMasterFieldObject: (fields) => void;
  masterFieldObject: IDynamicFormAttribute[];
  originalMasterFieldObject: IDynamicFormAttribute[];
  handleFormSubmit: (e: any) => void;
  accountNumber: number | undefined;
  step: any;
  setStep: (step: string) => void;
  resetForm: (e: any) => void;
  nonMasterFieldFormState: INonMasterFormFieldState;
  setNonMasterFieldFormState: (v: any) => void;
  submittedList: any;
  setSubmittedList: any;
  errorMessage: any;
  setPriceTotal: any;
  priceTotal: IPriceTotalState;
  setErrorMessage: (v: any) => void;
}

// TODO: May not be needed depending on how error handling and fixes are implimented in erp
const generateErrorMessage = (errorMessage, product?) => {
  switch (true) {
    case Array.isArray(errorMessage):
      return (
        <div style={{ textAlign: 'left' }}>
          <div className='vr2'>There were duplicate values found in the following fields for the current cfgmodel</div>
          <div className='vr2'>Product: {product}</div>
          {errorMessage.map((values, index) => {
            return (
              <div key={index} className='vr2'>
                <h4>{values.attributeName}:</h4>

                <p>{values.duplicates.join(', ')}</p>
                <br />
              </div>
            );
          })}
        </div>
      );
    default:
      return errorMessage.toString();
  }
};

const canAddToOrder = (fieldState, step, isLoading) => {
  if (!fieldState) {
    return false;
  }

  if (isLoading) {
    return false;
  }

  const fieldsNotSelected = fieldState.filter((item) => {
    if (MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed.includes(item.name)) {
      return false;
    }
    if (Array.isArray(item?.selectedValue) && item?.selectedValue?.length > 0) {
      return false;
    }
    if (!Array.isArray(item?.selectedValue) && item?.selectedValue !== null) {
      return false;
    }

    return true;
  });

  if (fieldsNotSelected.length === 0) {
    return true;
  }
  return false;
};

const DynamicForm = ({
  fields,
  product,
  handleFormSubmit,
  setFieldState,
  setMasterFieldObject,
  fieldState,
  masterFieldObject,
  accountNumber,
  step,
  setStep,
  resetForm,
  nonMasterFieldFormState,
  setNonMasterFieldFormState,
  setSubmittedList,
  submittedList,
  errorMessage,
  setErrorMessage,
  priceTotal,
  setPriceTotal,
  originalMasterFieldObject,
}: DynamicFormProps) => {
  const productObjectDefault = {
    displayName: '',
    endpointProduct: '',
    iconName: '',
    featureFlag: '',
    isClub: false,
  };

  const fallbackCurrency: string = useAccountCurrency();
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const productObject: IProduct = ProductsArr.find((item) => item.endpointProduct === product) || productObjectDefault;
  // eslint-disable-next-line
  const [formValues, setFormValues] = useState({ Product: productObject.endpointProduct, ProductConfig: [{ Name: '', Value: '' }] });
  const [isLoading, setIsLoading] = useState(false);
  const [finalEstimatedDate, setFinalEstimatedDate] = useState<IFinalEstimatedDateState>({
    estimatedDate: '',
    isLoading: false,
  });
  const [minQuantity, setMinQuantity] = useState<number | null>();
  const [qtyError, setQtyError] = useState<string | null>();
  const Product = ProductsArr.find((item) => product === item.endpointProduct);
  const ProductImage = Product?.iconName;
  const IsProductClub = Product?.isClub;
  const getPriceAndEstimatedDate = ({ fieldState, step, setPriceTotal, priceTotal, masterFieldObject, product, accountNumber }) => {
    if (canAddToOrder(fieldState, step, isLoading)) {
      setPriceTotal({ ...priceTotal, currency: '', isLoading: true });
      CreateOrderStatelessCalls.getProductPrice(
        new GetPriceRequestModel({
          accountNumber,
          product,
          configuration: masterFieldObject,
        })
      ).then((res: GetPriceResponseModel | HttpErrorResponseModel) => {
        if (res instanceof HttpErrorResponseModel) {
          setErrorMessage(res);
          return;
        }
        setPriceTotal({ priceTotal: res.data.priceTotal, currency: res.data.currency, isLoading: false });
      });

      setFinalEstimatedDate({ ...finalEstimatedDate, isLoading: true });
      CreateOrderStatelessCalls.getEstimatedShipDates(
        new GetEstimatedDatesRequestModel({
          product,
          configuration: masterFieldObject,
        })
      ).then((res: GetEstimatedDatesResponseModel | HttpErrorResponseModel) => {
        setFinalEstimatedDate({ estimatedDate: res.data, isLoading: false });
      });
    } else {
      setPriceTotal({ priceTotal: 0, currency: '', isLoading: false });
      setFinalEstimatedDate({ estimatedDate: '', isLoading: false });
    }
  };
  const debounceGetPriceAndEstimatedDate = useCallback(
    debounce((config: any) => getPriceAndEstimatedDate(config), 500),
    []
  );

  useEffect(() => {
    const config = {
      fieldState,
      step,
      setPriceTotal,
      priceTotal,
      masterFieldObject,
      accountNumber,
      product,
    };
    debounceGetPriceAndEstimatedDate(config);
    // eslint-disable-next-line
  }, [fieldState]);

  const nonMasterFieldFormFields: IDynamicNonMasterFormFields[] = [
    {
      name: 'TAGNAME',
      label: 'TAG NAME',
      values: [],
      placeHolder: 'TAG NAME',
      required: false,
      inputType: DynamicFieldEnum.Text,
    },
    {
      name: 'QTY',
      label: 'QTY',
      values: [],
      placeHolder: 'QTY',
      required: true,
      inputType: DynamicFieldEnum.Number,
      minimumQuantity: fields?.find((field) => field?.name === 'QTY')?.minimumQuantity || 1,
    },
  ];
  const inputRef = useRef<any>(nonMasterFieldFormFields.map(() => createRef()));

  const nonMasterFieldHandleChange = (input) => (e) => {
    let value = e.target.value;

    if (e.target.type === 'number') {
      setQtyError(null);
      value = parseInt(e.target.value, 10);
      if (minQuantity && value < minQuantity) {
        setQtyError('The minimum quantity for this product is ' + minQuantity);
        return;
      }
    }
    setNonMasterFieldFormState({ ...nonMasterFieldFormState, [input]: value });
  };
  // this is where field is changed
  const handleChange = (selectedValue: IDynamicFormValues) => {
    const productToCall = productObject.endpointProduct;

    // Mark selected field as user selected,
    const newMasterFieldObject = masterFieldObject.map((item) => {
      if (item.attributeName === selectedValue.Name) {
        return {
          ...item,
          userSelected: true,
        };
      }
      return item;
    });

    const requestFieldConfig: IDynamicFormDependencyUpdateFields = {
      product: productToCall,
      masterFieldObject: newMasterFieldObject,
      setMasterFieldObject,
      fieldState,
      setFieldState,
      accountNumber,
      selectedValue,
      submittedList,
      setSubmittedList,
      step,
      setStep,
      setIsLoading,
      setErrorMessage,
      setPriceTotal,
      originalMasterFieldObject,
    };

    requestFieldUpdates(requestFieldConfig);
  };

  const submitForm = (event) => {
    setFormValues({
      Product: '',
      ProductConfig: [
        {
          Name: '',
          Value: '',
        },
      ],
    });
    handleFormSubmit(event);
  };
  useEffect(() => {
    if (fields) {
      var min = fields?.find((field) => field?.name === 'QTY')?.minimumQuantity;
      if (min && nonMasterFieldFormState['QTY'] < min) {
        setMinQuantity(min);
        setNonMasterFieldFormState((prevState) => {
          return {
            ...prevState,
            QTY: min,
          };
        });
      }
    }
    //eslint-disable-next-line
  }, [fields, setNonMasterFieldFormState]);

  return (
    <>
      <hr className={styles['dynamic-form__hr-top']} />
      {/* Start */}
      <div className={styles['dynamic-form__container']}>
        <div className={styles['dynamic-form__left']}>
          <div className={'awrapper'}>
            <div className='vr2' />
            <h3 className='hdg hdg--3'>{productObject.displayName}</h3>
            <div className='vr2' />
          </div>
          <form className={styles['dynamic-form']} onSubmit={submitForm}>
            <div className={styles['dynamic-form__non-master-fields']}>
              {nonMasterFieldFormFields &&
                nonMasterFieldFormFields.map((field, i) => {
                  const calculatedRef = inputRef.current[i];
                  if (field.name === 'QTY') {
                    return (
                      <div key={field.name}>
                        <h4 className='hdg--4'>Quantity</h4>
                        <div className='vr2' />
                        <DynamicTextField
                          key={i}
                          name={field.name}
                          label={field.label}
                          calculatedRef={calculatedRef}
                          required={field.required}
                          formState={nonMasterFieldFormState}
                          placeHolder={field.placeHolder}
                          handleChange={nonMasterFieldHandleChange}
                          inputType={field.inputType}
                          minimumQuantity={field.minimumQuantity}
                        />
                        {qtyError && (
                          <>
                            <div className='vr2' />
                            <p className='form__error-highlight'>{qtyError}</p>
                          </>
                        )}
                      </div>
                    );
                  }
                  return (
                    <div key={field.name}>
                      <DynamicTextField
                        key={i}
                        name={field.name}
                        label={field.label}
                        calculatedRef={calculatedRef}
                        required={field.required}
                        formState={nonMasterFieldFormState}
                        placeHolder={field.placeHolder}
                        handleChange={nonMasterFieldHandleChange}
                        inputType={field.inputType}
                      />
                      <div className='vr5' />
                    </div>
                  );
                })}
            </div>
            <div className={styles['title_withLoading']}>
              <h4 className='hdg--4'>Product Specifications</h4>
              {isLoading && <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 20 }}></LoadingIndicator>}
            </div>
            <div className='vr2' />
            <div className={styles['dynamic-form__fields-container']}>
              {fields &&
                fields.map((field, i) => {
                  if (field.inputType === DynamicFieldEnum.Text) {
                    return (
                      <DynamicTextFieldSemantic
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        required={field.required}
                        placeHolder={field.placeHolder}
                        handleChange={handleChange}
                      />
                    );
                  }
                  if (field.inputType === DynamicFieldEnum.Select) {
                    return (
                      <DynamicSelectField
                        key={field.name}
                        name={field.name}
                        required={field.required}
                        placeHolder={field.placeHolder}
                        label={field.label}
                        values={field.values}
                        handleChange={handleChange}
                        disabled={field.disabled}
                        defaultValue={field.selectedValue ? field.selectedValue : ''}
                        visible={field.visible}
                        fieldsNotToBeDisplayed={MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed}
                        isLoading={isLoading}
                      />
                    );
                  }
                  if (field.inputType === DynamicFieldEnum.Club) {
                    return (
                      <DynamicSelectField
                        key={field.name}
                        name={field.name}
                        required={field.required}
                        placeHolder={field.placeHolder}
                        label={field.label}
                        values={field.values}
                        handleChange={handleChange}
                        disabled={field.disabled}
                        visible={field.visible}
                        clubsValue={field.selectedValue}
                        fieldsNotToBeDisplayed={MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed}
                        isMulti
                        isLoading={isLoading}
                      />
                    );
                  }
                  // eslint-disable-next-line react/jsx-key
                  return <span>Error:</span>;
                })}
            </div>
            {!errorMessage && <div className='vr10' />}
          </form>
          {IsProductClub && (
            <div className='helper-message'>
              <p className={styles['inline']}>
                If your desired club specifications are not available to select, please call PING customer service at: <PingCustomerServiceContact />
              </p>
            </div>
          )}
          {currentUser?.data.memberships.find((item) => item.key === UserRolesEnum.PingAdmin) && (
            <button
              style={{ marginBottom: '10px' }}
              className='btn btn__text-only'
              onClick={() => checkDuplicates(masterFieldObject, setErrorMessage)}
            >
              Debug
            </button>
          )}
          <div className={styles['dynamic-form__controls-container']}>
            {errorMessage && (
              <>
                {typeof errorMessage === 'string' ? (
                  <div className={styles['dynamic-form__error']}>
                    <div className='vr2'>
                      <PingCustomerServiceContactMessage />
                    </div>
                    <br />
                    <div>{generateErrorMessage(errorMessage, product)}</div>
                    {/* <Error4xx /> */}
                  </div>
                ) : (
                  <Error4xx
                    // eslint-disable-next-line quotes
                    overrideText={{
                      headingText:
                        errorMessage?.userMessage ||
                        "We're sorry but it looks like an error occurred and we cannot complete your order at this time.",
                    }}
                    onLight
                    response={errorMessage}
                    formPreview={masterFieldObject}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles['dynamic-form__right']}>
          <DynamicFormPanel
            product={product}
            priceTotal={priceTotal}
            ProductImage={ProductImage}
            fields={fields}
            finalEstimatedDate={finalEstimatedDate}
            nonMasterFieldFormFields={nonMasterFieldFormFields}
            nonMasterFieldFormState={nonMasterFieldFormState}
            fallbackCurrency={fallbackCurrency}
          />
        </div>
      </div>
      <OrderFooter
        stepName='dynamicForm'
        noTopMargin
        handleResetForm={resetForm}
        submitForm={submitForm}
        disabledButton={!canAddToOrder(fieldState, step, isLoading)}
      />
      {/* <OrderFooter stepName='dynamicForm' submitForm={submitForm} /> */}
    </>
  );
};

export default DynamicForm;
