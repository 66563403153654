import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import CreateUserResponseModel from './models/CreateUserResponseModel';
import CreateUserRequestModel from './models/CreateUserRequestModel';
import LinkUnlinkUserRequestModel from './models/LinkUnlinkUserRequestModel';
import LinkUnlinkUserResponseModel from './models/LinkUnlinkUserResponseModel';
import CreateUserToAccountsRequestModel from './models/CreateUserToAccountsRequestModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import HttpUtility from 'utilities/HttpUtility';
import UpdateUserRequestModel from './models/UpdateUserRequestModel';
import UpdateUserResponseModel from './models/UpdateUserResponseModel';

/**
 * Handles CRUD operations for users including linking and unlinking from accounts.
 *
 * Does not get a user or lists of users
 * Does not store to redux
 * For those functions please see the UsersAction.ts or AccountsAction.ts
 */
export default class UserUtility {
  /**
   * Creates a unlinked user.
   *
   * @param {CreateUserRequestModel} requestConfig - a create user response model.
   * @returns {Promise<CreateUserRequestModel | HttpErrorResponseModel>}
   */
  public static async createUser(requestConfig: CreateUserRequestModel): Promise<CreateUserResponseModel | HttpErrorResponseModel> {
    const endpoint = `${(environment.api as any).users3}`;

    const res = await EffectUtility.postToModelBody(CreateUserResponseModel, endpoint, requestConfig);

    return res;
  }

  /**
   * Updates a user to the fields passed in.
   *
   * @param {UpdateUserResponseModel} requestConfig - fields to set the user to
   * @returns {Promise<UpdateUserResponseModel | HttpErrorResponseModel>}
   */
  public static async updateUser(requestConfig: UpdateUserRequestModel): Promise<UpdateUserResponseModel | HttpErrorResponseModel> {
    const endpoint = `${(environment.api as any).users3}/${requestConfig.userId}`;

    const res = await EffectUtility.putToModelBody(UpdateUserResponseModel, endpoint, requestConfig);

    return res;
  }

  /**
   * Deletes a user from all accounts.
   *
   * @param {number} userId
   * @returns
   */
  public static async deleteUser(userId) {
    const endpoint = `${(environment.api as any).users3}/${userId}`;

    const res = await HttpUtility.delete(endpoint);

    return res;
  }

  /**
   * Link a user to a list of accounts by userId.
   *
   * @param {LinkUnlinkUserRequestModel} requestConfig
   * @returns {Promise<LinkUnlinkUserResponseModel | HttpErrorResponseModel>}
   */
  public static async linkUser(requestConfig: LinkUnlinkUserRequestModel): Promise<LinkUnlinkUserResponseModel | HttpErrorResponseModel> {
    const endpoint = `${(environment.api as any).users3}/${requestConfig.userId}/account`;

    const res = await EffectUtility.postToModelBody(LinkUnlinkUserResponseModel, endpoint, requestConfig);

    return res;
  }

  /**
   * Unlinks a user from a list of accounts by userId.
   *
   * @param {LinkUnlinkUserRequestModel} requestConfig
   * @returns {Promise<LinkUnlinkUserResponseModel | HttpErrorResponseModel>}
   */
  public static async unlinkUser(requestConfig: LinkUnlinkUserRequestModel): Promise<LinkUnlinkUserResponseModel | HttpErrorResponseModel> {
    const endpoint = `${(environment.api as any).users3}/${requestConfig.userId}/account`;

    const res = await EffectUtility.deleteToModel(LinkUnlinkUserResponseModel, endpoint, requestConfig);

    return res;
  }

  /**
   * An aggregate helper function that will first create a user given the CreateUser form fields.
   * Then link the user to the list of accounts supplied.
   *
   * @param {CreateUserToAccountsRequestModel} requestConfig
   * @returns {LinkUnlinkUserResponseModel | HttpErrorResponseModel}
   */
  public static async createUserToAccounts(
    requestConfig: CreateUserToAccountsRequestModel
  ): Promise<LinkUnlinkUserResponseModel | HttpErrorResponseModel> {
    const createConfig = new CreateUserRequestModel({
      firstName: requestConfig.firstName,
      lastName: requestConfig.lastName,
      email: requestConfig.email,
      userMembership: requestConfig.userMembership,
      preferredCultureCode: requestConfig.preferredCultureCode,
    });

    const createResponse: CreateUserResponseModel | HttpErrorResponseModel = await this.createUser(createConfig);

    if (createResponse instanceof HttpErrorResponseModel) {
      return createResponse;
    }

    const linkConfig = new LinkUnlinkUserRequestModel({
      userId: createResponse.data.kenticoUser.userId,
      accountNumbers: requestConfig.accountNumbers,
    });

    const linkResponse: LinkUnlinkUserResponseModel | HttpErrorResponseModel = await this.linkUser(linkConfig);

    return linkResponse;
  }
}
