import React, { useEffect } from 'react';
import ProductCard from '../productCard/ProductCard';
import OrderFooter from '../OrderFooter/OrderFooter';
import styles from '../productCard/ProductCard.module.scss';
import { ReactComponent as RoundCheckbox } from '../../../../assets/media/icons/icon-checkbox-round.svg';
import ProductsArr, { IProduct } from 'constants/ProductsArr';
import { CartItemDTO, IGetCartResponse } from 'stores/create-order/models/IGetCart';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import ITabState from 'stores/create-order/models/ITabState';
import IFeatureFlag from 'stores/feature-flag/models/IFeatureFlag';
import IStore from 'models/IStore';

interface IProps {
  orders: CartItemDTO[];
  cartState: IGetCartResponse | null;
  tabState: any;
}

function ProductCards({ orders, cartState, tabState }: IProps): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const clubs: IProduct[] = [];
  const gear: IProduct[] = [];
  const products = ProductsArr;
  const features: IFeatureFlag | null = useSelector((state: IStore) => state.featureFlag.featureFlagItems);

  products.forEach((product: IProduct, index) => {
    // check the feature flag
    if (features && features[product.featureFlag]) {
      if (!MAIN_CONFIG.CreateOrder.ListOfNonClubProducts.includes(product.displayName)) {
        clubs.push(products[index]);
      } else {
        gear.push(products[index]);
      }
    }
  });

  console.groupCollapsed('%c State for%c Cart', 'color: MediumSlateBlue');
  console.log(orders);
  console.groupEnd();

  const getLastItem = () => {
    const result = orders[orders.length - 1];
    return ` Qty ${result.quantity}  -  ${result.description}`;
  };

  const hasCartItem = orders && orders.length > 0 ? true : false;

  useEffect(() => {
    if (cartState?.cartItems && cartState?.cartItems.length > 0 && tabState.hasItems === false) {
      const request: ITabState = {
        ...tabState,
        hasItems: true,
        hasShipping: cartState?.cart.shipping.accountNumber !== 0,
      };
      dispatch(CreateOrderAction.storeTabState(request));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartState?.cartItems]);

  return (
    <div className='awrapper'>
      {hasCartItem && (
        <div>
          <div className={styles.itemAddedWrapper}>
            <div className={styles.itemAddedIcon}>
              <RoundCheckbox aria-hidden='true' focusable='false' role='img' />
            </div>
            <div>
              <div className='hdg hdg--4'>Item Added</div>
              <div>{getLastItem()} </div>
            </div>
          </div>
          <div className='vr3' />
          <hr className='hr' />
          <div className='vr3' />
        </div>
      )}
      {clubs && clubs.length > 0 && (
        <>
          <h3 className='hdg hdg--3'>Clubs</h3>
          <div className={`vr2 ${styles.productsWrapper}`}>
            {clubs.map((club, i) => {
              return <ProductCard key={club.endpointProduct} product={club} />;
            })}
          </div>
          <div style={{ clear: 'both' }}></div>
          <div className='vr2' />
        </>
      )}
      {gear && gear.length > 0 && (
        <>
          <h3 className='hdg hdg--3'>Gear</h3>
          <div className={`vr2 ${styles.productsWrapper}`}>
            {gear.map((gear, i) => {
              return <ProductCard key={gear.endpointProduct} product={gear} />;
            })}
          </div>
        </>
      )}
      <div style={{ clear: 'both' }}></div>
      {hasCartItem && <OrderFooter stepName='productCards' />}
    </div>
  );
}

export default ProductCards;
