import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import UserResponseModel from './models/UserResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import AccountsResponseModel from 'stores/accounts/models/AccountsResponseModel';
import UserResponseModelV3 from './models/UserResponseModelV3';
import UsersRequestModel from './models/UsersRequestModel';
import UsersResponseModel from './models/UsersResponseModel';

export default class UsersEffect {
  public static async requestUserInfo(): Promise<UserResponseModel | HttpErrorResponseModel> {
    const endpoint: string = (environment.api as any).userInfo;

    return EffectUtility.getToModel<UserResponseModel>(UserResponseModel, endpoint);
  }

  public static async setCurrentUser(token: string): Promise<UserResponseModel | HttpErrorResponseModel> {
    const endpoint: string = (environment.api as any).login;
    const response = await EffectUtility.postToModel<UserResponseModel>(UserResponseModel, endpoint, { token });
    if (response.statusCode === 400) {
      console.warn('User not found. This is likely due to an Azure and Kentico user mismatch.');
      alert('ERROR: Your PING Biz user account could not be found. Please contact your IT department to resolve this issue.');
    }
    return response;
  }

  public static async requestUserById(userId: number): Promise<UserResponseModelV3 | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).users3}/${userId}`;

    return EffectUtility.getToModel<UserResponseModelV3>(UserResponseModelV3, endpoint);
  }

  public static async requestUsers(usersRequestConfig: UsersRequestModel): Promise<UsersResponseModel | HttpErrorResponseModel> {
    const endpoint: string = (environment.api as any).users3;

    return EffectUtility.getToModel<UsersResponseModel>(UsersResponseModel, endpoint, usersRequestConfig);
  }

  public static async requestAccountUsers(UsersRequestConfig: UsersRequestModel): Promise<UsersResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).users3}/account/${UsersRequestConfig.accountNumber}`;
    return EffectUtility.getToModel<UsersResponseModel>(UsersResponseModel, endpoint, UsersRequestConfig);
  }

  public static async requestUserAccounts(userEmail: string): Promise<AccountsResponseModel | HttpErrorResponseModel> {
    const endpoint: string = (environment.api as any).usersAccounts;
    const requestConfig = {
      email: userEmail,
    };
    return EffectUtility.getToModel<AccountsResponseModel>(AccountsResponseModel, endpoint, requestConfig);
  }
}
