export enum CarrierEnum {
  AF = 'A/F',
  CONS = 'CONS',
  CUSTOMER = 'CUSTOMER',
  FEDX = 'FEDX',
  FEDEX = 'FEDEX',
  KCC = 'KCC',
  KMC = 'KMC',
  PURO = 'PURO',
  TRUCK = 'TRUCK',
  UPS = 'UPS',
  USPS = 'USPS',
  VESSEL = 'VESSEL',
}
