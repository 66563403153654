import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import ErrorAction from 'stores/error/ErrorAction';
import EffectUtility from 'utilities/EffectUtility';
import IStatus from './models/IStatus';
import StatusResponseModel from './models/StatusResponseModel';

export default class LocationStatusUtility {
  /**
   * This method calls the status endpoint to get back the status
   * @param setStatus
   * @param dispatch
   */
  public static async getStatus(setStatus: ((status: IStatus[]) => void) | undefined, dispatch: Function) {
    const endpoint: string = `${(environment.api as any).statuses}`;
    const response = await EffectUtility.getToModel<StatusResponseModel>(StatusResponseModel, endpoint);
    if (response.data && setStatus) {
      const tempStatusResponse: IStatus[] = response.data.map((res: IStatus) => {
        return {
          id: res.id,
          name: res.name,
          description: res.description,
          active: res.active,
        };
      });
      setStatus(tempStatusResponse);
    }
    // If response comes back with an error, this will dispatch to the
    if (response instanceof HttpErrorResponseModel) {
      dispatch(ErrorAction.add(response, 'ErrorGettingStatuses'));
    }
  }

  /**
   * This method initializes the status call to retrieve the status api response
   * @param dispatch
   */
  public static async initStatus() {
    let initialStatus: IStatus[] | null = null;
    const endpoint: string = `${(environment.api as any).statuses}`;
    const response = await EffectUtility.getToModel<StatusResponseModel>(StatusResponseModel, endpoint);
    if (response.data) {
      // add loops here
      initialStatus = this.sortStatus(response.data);
    }
    if (response instanceof HttpErrorResponseModel) {
      console.log('Error making init call for status:', response);
    }
    return initialStatus;
  }

  /**
   * This will sort the status table in order
   */
  public static sortStatus(data) {
    const sortOrder = ['active', 'inactive', 'draft', 'suspended'];
    let sortedStatuses: IStatus[] = [];
    // loop throught the order
    for (let index = 0; index < sortOrder.length; index++) {
      // get the status
      const order = sortOrder[index];
      data.forEach((status) => {
        // Check for the status name that equals the same string name within sortOrder array
        if (status.name === order) {
          //push to array
          sortedStatuses.push(status);
        }
        return;
      });
    }
    return sortedStatuses;
    // return test;
  }
  /**
   * This is a custom hook that returns back the statusName after passing in the location status ID
   * @param id Requires the statusId from location object
   * @returns the IStatus object
   */
  public static async getStatusName(statusId: string, statusTable: IStatus[]): Promise<string> {
    if (statusTable.length > 0) {
      const status = statusTable.find((item: IStatus) => item.id === statusId);
      if (status) {
        return status.name;
      }
    }
    return '';
  }

  /**
   * This returns just the status guid from altering the toggle swicth
   * @param statusTable
   * @param statusName
   * @returns
   */
  public static async getStatusId(statusTable: IStatus[], statusName: string): Promise<string | undefined> {
    const statusId = statusTable.find((item: IStatus) => item.name === statusName.toLowerCase());
    return statusId?.id;
  }
}
