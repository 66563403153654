import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import reduxFreeze from 'redux-freeze';
import environment from 'environment';
import rootReducer from './rootReducer';
import IStore from '../models/IStore';
import errorToastMiddleware from '../middlewares/errorToastMiddleware';

export default (initialState: Partial<IStore>, history: History): Store<IStore> => {
  const middleware: Middleware[] = [environment.isDevelopment ? reduxFreeze : null!, thunk, routerMiddleware(history), errorToastMiddleware()].filter(
    Boolean
  );

  // To enable trace hits performance
  // const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const composeEnhancers = composeWithDevTools({});

  const store: Store<IStore> = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middleware)));

  // Leave enabled when mergeing to develop for QA
  if (environment.isProduction === false) {
    store.subscribe(() => {
      console.groupCollapsed('Main State Storage');
      console.log(store.getState());
      console.groupEnd();
    });
  }

  return store;
};
