import React, { useEffect, useRef, useState } from 'react';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import OrderFooter from '../OrderFooter/OrderFooter';
import styles from './OrderShipping.module.scss';
import stylesFromFooter from '../OrderFooter/OrderFooter.module.scss';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import UpdateCartRequestModel from 'stores/create-order/models/UpdateCartRequestModel';
import IAccount from 'stores/accounts/models/IAccount';
import { IGetCartResponse } from 'stores/create-order/models/IGetCart';
import { oc } from 'ts-optchain.macro';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import AccountShippingAddress from './components/AccountShippingAddress';
import DropShippingForm from './components/DropShippingForm';
import ShipTypeSelector from './components/ShipTypeSelector';
import { DropShipStateModel } from './models/DropShipStateModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import _ from 'lodash';
import DropShipAddressSelection from './components/DropShipAddressSelection';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import Error4xx from 'components/Error4xx/Error4xx';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { IFormErrors } from 'components/Form/IFormErrors';
import getRequiredStrings from 'components/Form/FormHelpers/getRequiredStrings';
import highlightFields from 'components/Form/FormHelpers/highlightFields';
import FeatureFlagComponent from 'components/FeatureFlag/FeatureFlag';

interface IProps {
  addresses: IAccountAddresses | null;
}

interface ITMEData {
  alternateAddresses: [];
  isResidential: boolean;
  isValid: boolean;
  status: string;
}

export interface IAlternateAddresses {
  isDropShipAddressSelection: boolean;
  alternateAddresses: IAlternateAddress[];
}

export interface IAlternateAddress {
  companyName: string;
  street: string;
  locale: null | string;
  other: null | string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  residential: boolean;
}

function OrderShipping({ addresses }: IProps): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const cartState: IGetCartResponse | null = useSelector((state: IStore) => state.createOrder.cartState);
  const cart = cartState?.cart;
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const [shippingType, setShippingType] = useState<string>(cart?.shipping.dropShipAddress === null ? 'account' : 'drop');
  const [isLoading, setIsLoading] = useState(false);
  const [TMEError, setTMEError] = useState('');
  const [error4xx, setError4xx] = useState<HttpErrorResponseModel | null | any>(null);
  const [alternateAddresses, setAlternateAddresses] = useState<any>({
    isDropShipAddressSelection: false,
    alternateAddresses: [],
  });
  const [selectedAddress, setSelectedAddress] = useState<number>(-1);
  // Passed to DropShip form used in validation of address
  const [formState, setFormState] = useState(new DropShipStateModel());
  const [checkValid, setCheckValid] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    hasError: false,
    errors: [],
  });
  const dropShipFormRef = useRef<any>(null);
  const [dropShipOptionSelected, setDropShipOptionSelected] = useState<number | undefined>();
  useEffect(() => {
    if (accountNumber) {
      /**
       * Handle if drop ship is in cart bring it into formState
       */
      if (cart?.shipping.dropShipAddress !== null) {
        setFormState(new DropShipStateModel(cart?.shipping.dropShipAddress));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: see if we need this.
  const handleSelectChange = (event) => {
    setSelectedAddress(event.value);
  };

  const handleDropShip = async () => {
    setIsLoading(true);

    try {
      const TMEResponse = await CartStatelessCalls.validateDropShip(formState);
      if (!TMEResponse.data) {
        setError4xx(TMEResponse);
      }
      const TMEData: ITMEData = TMEResponse.data.data;
      setIsLoading(false);

      /**
       * No Addresses found kick back
       */
      if (TMEData.isValid === false) {
        setAlternateAddresses({
          isDropShipAddressSelection: true,
          alternateAddresses: null,
        });
        setCheckValid(false);
      }

      /**
       * Recommended Address found
       */
      if (TMEData.alternateAddresses.length > 0) {
        setAlternateAddresses({
          isDropShipAddressSelection: true,
          alternateAddresses: TMEData.alternateAddresses,
        });
      }

      if (TMEData.isValid === true && TMEData.alternateAddresses.length === 0) {
        // Send to next page
        TabStateHelpers.handleReview(dispatch, tabState);
      }
    } catch (e) {
      setIsLoading(false);
    }

    // set loading
    // if it goes well lockin address and continue
  };

  const saveAddressToCart = async (address, residentialFlag: boolean, selectedRadio: number) => {
    if (!addresses || !accountNumber) {
      return;
    }
    setIsLoading(true);

    const newDropShipAddress = _.cloneDeep(addresses);
    newDropShipAddress.dropShipAddress = address;
    newDropShipAddress.residentialFlag = residentialFlag;

    const cartUpdateRequest: UpdateCartRequestModel = new UpdateCartRequestModel({
      AccountNumber: accountNumber,
      CartId: cart?.cartID,
      PoNumber: cart?.poNumber,
      Shipping: newDropShipAddress,
    });

    const res = await CartStatelessCalls.updateCart(cartUpdateRequest);

    if (res instanceof HttpErrorResponseModel) {
      setError4xx(res);
      setIsLoading(false);
      return;
    }
    setDropShipOptionSelected(selectedRadio);

    dispatch(CreateOrderAction.getCart(accountNumber));

    setIsLoading(false);
  };

  useEffect(() => {
    if (checkValid && dropShipFormRef.current && dropShipFormRef.current.checkValidity() && !TMEError) {
      // If all checks pass start apiCalls
      handleDropShip();
    }
    // eslint-disable-next-line
  }, [checkValid]);

  /**
   * Stores Form errors to state
   * @returns boolean false if errors are present true if not
   */
  const getFormErrors = (formState): IFormErrors => {
    const requiredStrings = ['name', 'address1', 'city'];
    const formErrors: IFormErrors = {
      hasError: false,
      errors: [],
    };

    getRequiredStrings(formState, requiredStrings, formErrors, true);

    if (!formState.state) {
      formErrors.errors.push({ errorFieldName: 'state', errorMessage: 'Please select a State / Province' });
      formErrors.hasError = true;
    }

    if (!/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/.test(formState.zip)) {
      formErrors.errors.push({
        errorFieldName: 'zip',
        errorMessage: 'Please enter a valid post code matching the pattern 00000, 00000-0000, or A1B2C3',
      });
      formErrors.hasError = true;
    }

    if (formErrors.hasError) {
      setFormErrors(formErrors);
      return formErrors;
    }

    return formErrors;
  };

  /**
   * Checks form state on drop ship and account dropdown
   */
  const handleReview = async () => {
    if (shippingType === 'drop') {
      setTMEError('');

      const errors = getFormErrors(formState);
      if (errors.errors.length > 0) {
        return;
      }

      if (dropShipOptionSelected !== undefined) {
        dispatch(CreateOrderAction.storeTabState({ ...tabState, hasShipping: true, tabIndex: 2 }));
        return;
      } else {
        // We should add an error message here to the user to remind him to select an option to continue to the next tab
        if (alternateAddresses.isDropShipAddressSelection) {
          return;
        }
      }
      await handleDropShip();
    } else {
      if (addresses && accountNumber) {
        // Needs to clear drop from cart and update to the address on the account
        const newDropShipAddress = _.cloneDeep(addresses);
        if (selectedAddress === -1) {
          newDropShipAddress.dropShipAddress = null;

          const cartUpdateRequest: UpdateCartRequestModel = new UpdateCartRequestModel({
            AccountNumber: accountNumber,
            CartId: cart?.cartID,
            PoNumber: cart?.poNumber,
            Shipping: newDropShipAddress,
          });

          setIsLoading(true);
          await CartStatelessCalls.updateCart(cartUpdateRequest).then(() => dispatch(CreateOrderAction.getCart(accountNumber)));
          setIsLoading(false);
        } else {
          newDropShipAddress.dropShipAddress = addresses?.dropShipAddresses?.[selectedAddress];
          if (newDropShipAddress.dropShipAddress && !newDropShipAddress.dropShipAddress.name && addresses.businessName) {
            // If the address has no name, use the business name
            newDropShipAddress.dropShipAddress = { ...newDropShipAddress.dropShipAddress, name: addresses.businessName };
          }
          const cartUpdateRequest: UpdateCartRequestModel = new UpdateCartRequestModel({
            AccountNumber: accountNumber,
            CartId: cart?.cartID,
            PoNumber: cart?.poNumber,
            Shipping: newDropShipAddress,
          });

          setIsLoading(true);
          await CartStatelessCalls.updateCart(cartUpdateRequest).then(() => dispatch(CreateOrderAction.getCart(accountNumber)));
          setIsLoading(false);
        }
        dispatch(CreateOrderAction.storeTabState({ ...tabState, hasShipping: true, tabIndex: 2 }));
      }
    }
  };

  /**
   * Changes radio menu
   * @param type address type currently 'account' of 'drop'
   */
  const handleShippingTypeChange = (type) => {
    if (type === 'account') {
      if (cart?.shipping.accountNumber !== 0) {
        dispatch(CreateOrderAction.storeTabState({ ...tabState, hasShipping: true }));
      }
      setFormErrors({
        errors: [],
        hasError: false,
      });
      setShippingType('account');
    }
    if (type === 'drop') {
      dispatch(CreateOrderAction.storeTabState({ ...tabState, hasShipping: false }));
      setShippingType('drop');
    }
  };

  /**
   * Highlights and unhighlights form errors.
   */
  useEffect(() => {
    const fieldNames = [
      DropShipStateModel.PropertyNames.ADDRESS1,
      DropShipStateModel.PropertyNames.NAME,
      DropShipStateModel.PropertyNames.ZIP,
      DropShipStateModel.PropertyNames.CITY,
      DropShipStateModel.PropertyNames.STATE,
    ];

    highlightFields(fieldNames, formErrors);
  }, [formErrors]);

  return (
    <div className={styles.shipping}>
      {isLoading && (
        <div className={styles.shipping__loadingContainer}>
          <LoadingIndicator
            customLoadingText={alternateAddresses.isDropShipAddressSelection ? 'Saving selected option...' : 'Validating Address...'}
            isActive={true}
            inverted={true}
          />
        </div>
      )}
      <div className='awrapper'>
        <div className='split'>
          <h2 className='hdg hdg--3'>Shipping Address</h2>
          <p style={{ color: 'red', fontSize: '.75rem' }}>* Denotes Required Fields</p>
        </div>
        <div>
          <FeatureFlagComponent name={'drop_ship_address'}>
            <ShipTypeSelector handleSelect={handleShippingTypeChange} selectState={shippingType} />
          </FeatureFlagComponent>
          <div className='vr4' />

          <hr className={`${stylesFromFooter['df__divider--no-top-margin']}`} />
          <div className='vr4' />
          {shippingType === 'account' ? (
            <div className={styles['order-shipping__options-container']}>
              <AccountShippingAddress addresses={addresses} handleSelectChange={handleSelectChange} />
            </div>
          ) : (
            <>
              {alternateAddresses.isDropShipAddressSelection ? (
                <DropShipAddressSelection
                  alternateAddresses={alternateAddresses}
                  formState={formState}
                  setAlternateAddresses={setAlternateAddresses}
                  saveAddressToCart={saveAddressToCart}
                  dropShipOptionSelected={dropShipOptionSelected}
                  setDropShipOptionSelected={setDropShipOptionSelected}
                />
              ) : (
                <div className={styles['order-shipping__options-container']}>
                  <FeatureFlagComponent name={'drop_ship_address'}>
                    <DropShippingForm
                      handleReview={handleReview}
                      formState={formState}
                      setFormState={setFormState}
                      formRef={dropShipFormRef}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      getFormErrors={getFormErrors}
                    />
                  </FeatureFlagComponent>
                </div>
              )}
            </>
          )}
        </div>
        {TMEError && <div className='form__error'> {TMEError} </div>}
        {formErrors.errors.length > 0 && (
          <div className={styles.errors}>
            {formErrors.errors.map((e, i) => (
              <div className={styles.errors__line} key={i}>
                {e.errorMessage}
              </div>
            ))}
          </div>
        )}
        <Error4xx response={error4xx} onLight />

        <OrderFooter handleReview={handleReview} stepName='orderShipping' />
      </div>
    </div>
  );
}

export default OrderShipping;
