import { BaseModel } from 'sjs-base-model';

export default class SubmitOrderRequestModel extends BaseModel {
  cartId: number;
  expectedPrice: number;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
