import PaginatedRequestModel from 'stores/pagination/IPaginatedRequestModel';

export default class AddressRequestModel extends PaginatedRequestModel {
  street: string;
  compliment: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  locationId: string;
  latitude: number;
  longitude: number;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
