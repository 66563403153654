import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import HttpErrorResponseModel from '../models/HttpErrorResponseModel';
import { oc } from 'ts-optchain.macro';
import { MSAL_AUTH } from 'index';
import environment from 'environment';

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Options = 'OPTIONS',
  Head = 'HEAD',
  Patch = 'PATCH',
}

export default class HttpUtilityRequestBody {
  public static CancelToken: any = axios.CancelToken;
  /**
   * TODO: If initial calls are to a broken accounts endpoint cancel token seems to be always pending and
   * will crash the app when the user tries to select an account.
   */
  public static GlobalCancelToken: any = HttpUtilityRequestBody.CancelToken.source();

  public static cancelCalls(newToken: string) {
    /**
     * Check if token is extensible.
     *
     * If the first call made fails token will not be extensible.
     * TODO: Code what should be done when token is not extensible.
     */
    if (Object.isExtensible(this.GlobalCancelToken.token)) {
      this.GlobalCancelToken.cancel('User Canceled Calls');
      this.GlobalCancelToken = this.CancelToken.source(newToken);
    }
  }

  public static customCancelTokens = {};

  /**
   * Will replace the golbal token.
   */
  public static setCustomCancelToken(token, keyName) {
    this.customCancelTokens[keyName] = this.CancelToken.source(token);
  }

  public static cancelCustomCancelToken(keyName, newToken) {
    if (Object.isExtensible(this.customCancelTokens[keyName])) {
      const token = this.customCancelTokens[keyName];

      token.cancel('customCancel');
      this.customCancelTokens[keyName] = this.CancelToken.source(newToken);
    }
  }

  public static async get(
    endpoint: string,
    params?: any,
    requestConfig?: AxiosRequestConfig,
    cancelTokenKey?
  ): Promise<AxiosResponse | HttpErrorResponseModel> {
    const paramsConfig: AxiosRequestConfig | undefined = params ? { params } : undefined;

    return HttpUtilityRequestBody._request(
      {
        url: endpoint,
        method: RequestMethod.Get,
      },
      {
        ...paramsConfig,
        ...requestConfig,
      },
      cancelTokenKey
    );
  }

  public static async post(endpoint: string, data?: any, cancelTokenKey?: any): Promise<AxiosResponse | HttpErrorResponseModel> {
    const config: AxiosRequestConfig | undefined = data ? data : undefined;
    return HttpUtilityRequestBody._request(
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      config,
      cancelTokenKey
    );
  }

  public static async put(endpoint: string, data?: any, cancelTokenKey?): Promise<AxiosResponse | HttpErrorResponseModel> {
    const config: AxiosRequestConfig | undefined = data ? data : undefined;

    return HttpUtilityRequestBody._request(
      {
        url: endpoint,
        method: RequestMethod.Put,
      },
      config,
      cancelTokenKey
    );
  }

  public static async delete(endpoint: string, cancelTokenKey?): Promise<AxiosResponse | HttpErrorResponseModel> {
    return HttpUtilityRequestBody._request(
      {
        url: endpoint,
        method: RequestMethod.Delete,
      },
      undefined,
      cancelTokenKey
    );
  }

  private static async _request(
    restRequest: Partial<Request>,
    config?: AxiosRequestConfig,
    cancelTokenKey?
  ): Promise<AxiosResponse | HttpErrorResponseModel> {
    if (!Boolean(restRequest.url)) {
      console.error(`Received ${restRequest.url} which is invalid for a endpoint url`);
    }

    try {
      // TODO: make sure that token doesnt expire causing problems.
      const token = MSAL_AUTH.token;

      const axiosRequestConfig: AxiosRequestConfig = {
        cancelToken: this.customCancelTokens[cancelTokenKey] ? this.customCancelTokens[cancelTokenKey].token : this.GlobalCancelToken.token,
        method: restRequest.method as Method,
        url: restRequest.url,
        headers: {
          'Content-Type': 'application/json',
          'Access-Token': token,
          'Access-Control-Allow-Origin': environment.uri.baseUri,
          'X-Region': environment.regionName,
          Authorization: `Bearer ${token}`,
          ...oc(config).headers(undefined),
        },
        data: {
          ...config,
        },
      };
      const [axiosResponse] = await Promise.all([axios(axiosRequestConfig)]);
      const { status, data, request } = axiosResponse;
      const { eventCode, userMessage, errorNotificationType } = data;
      const previousLocation = encodeURIComponent((window as any).location.pathname);

      if (status > 499 && status < 600) {
        (window as any).location.replace(`${environment.uri.baseUri as any}/error-500/${status}/${userMessage}/${eventCode}/${previousLocation}`);
      }

      if (data.success === false) {
        return HttpUtilityRequestBody._fillInErrorWithDefaults(
          {
            statusCode: status,
            message: data.message,
            errors: data.errors,
            eventCode,
            userMessage,
            url: request ? request.responseURL : restRequest.url,
            raw: axiosResponse,
            errorNotificationType: errorNotificationType,
          },
          restRequest
        );
      }

      return {
        ...axiosResponse,
      };
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const { status, statusText, data } = error.response;
        const errors: string[] = data.hasOwnProperty('userMessage') ? [data.userMessage] : [statusText];
        const { eventCode, userMessage, errorNotificationType } = data;
        const previousLocation = encodeURIComponent((window as any).location.pathname);

        if (status > 499 && status < 600) {
          (window as any).location.replace(`${environment.uri.baseUri as any}/error-500/${status}/${userMessage}/${eventCode}/${previousLocation}`);
        }

        return HttpUtilityRequestBody._fillInErrorWithDefaults(
          {
            statusCode: status,
            // message: errors.filter(Boolean).join(' - '),
            message: data.message,
            errors,
            eventCode,
            userMessage,
            url: error.request.responseURL,
            raw: error.response,
            errorNotificationType: errorNotificationType,
          },
          restRequest
        );
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        const { status, statusText, responseURL } = error.request;

        return HttpUtilityRequestBody._fillInErrorWithDefaults(
          {
            statusCode: status,
            message: statusText,
            errors: [statusText],
            url: responseURL,
            raw: error.request,
          },
          restRequest
        );
      }

      // Something happened in setting up the request that triggered an Error
      return HttpUtilityRequestBody._fillInErrorWithDefaults(
        {
          statusCode: 0,
          message: error.message,
          errors: [error.message],
          url: restRequest.url!,
          raw: error,
          errorNotificationType: error?.response?.data?.errorNotificationType,
        },
        restRequest
      );
    }
  }

  private static _fillInErrorWithDefaults(error: Partial<HttpErrorResponseModel>, request: Partial<Request>): HttpErrorResponseModel {
    const model = new HttpErrorResponseModel();

    model.statusCode = error.statusCode || 0;
    model.message = error.message || 'Error requesting data';
    model.errors = error.errors!.length ? error.errors! : ['Error requesting data'];
    model.eventCode = error.eventCode || '';
    model.userMessage = error.userMessage || '';
    model.url = error.url || request.url!;
    model.raw = error.raw;
    model.errorNotificationType = error.errorNotificationType || null;

    // Remove anything with undefined or empty strings.
    model.errors = model.errors.filter(Boolean);

    return model;
  }

  /**
   * We want to show the loading indicator to the user but sometimes the api
   * request finished too quickly. This makes sure there the loading indicator is
   * visual for at least a given time.
   *
   * @param duration
   * @returns {Promise<unknown>}
   * @private
   */
  private static _delay(duration: number = 250): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }
}
