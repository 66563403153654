import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../models/IStore';
import Summary from './components/OrdersSummary/OrdersSummary';
import OrdersTable from './components/OrdersTable/OrdersTable';
import OrdersAction from '../../stores/orders/OrdersAction';
import OrdersResponseModel from '../../stores/orders/models/OrdersResponseModel';
import OrdersSummaryResponseModel from '../../stores/orders/models/OrdersSummaryResponseModel';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import RouteEnum from '../../constants/RouteEnum';
import OrdersRequestModel from '../../stores/orders/models/OrdersRequestModel';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import IAccount from 'stores/accounts/models/IAccount';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import styles from './OrdersPage.module.scss';
import { ReactComponent as Refresh } from '../../assets/media/icons/icon-restart.svg';
import DayMessagesEnum from 'constants/DayMessagesEnum';
import React, { useEffect, useState } from 'react';
import { oc } from 'ts-optchain.macro';
import { Dispatch } from 'redux';
import { isEqual } from 'lodash';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import { DATE_HELPER } from 'index';
import { pageLoader } from 'components/PageLoader/PageLoader';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import SearchEnum from 'constants/SearchEnum';

interface IProps {}
interface IState {
  ordersRequestConfig: OrdersRequestModel;
  ordersLoading: boolean;
}
interface IRouteParams {}

const OrdersPage = () => {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const ordersResponse: OrdersResponseModel | null = useSelector((state: IStore) => oc(state).orders.ordersResponse(null));
  const ordersSummary: OrdersSummaryResponseModel | null = useSelector((state: IStore) => oc(state).orders.ordersSummary(null));
  const ordersFilters: OrdersRequestModel | null = useSelector((state: IStore) => oc(state).orders.ordersFilters(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const orders4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [OrdersAction.REQUEST_ORDERS_FINISHED, OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED]
  );

  const [state, setState] = useState({
    ordersRequestConfig: ordersFilters
      ? new OrdersRequestModel({
          ...ordersFilters,
          sortData: {
            Column: 'orderNumber',
            SortDirection: SortDirectionEnum.DESC,
          },
        })
      : new OrdersRequestModel({
          pageSize: 10,
          startDate: DATE_HELPER.oneTwentyDaysToDate,
          endDate: DATE_HELPER.todayToDate,
          sortData: {
            Column: 'orderNumber',
            SortDirection: SortDirectionEnum.DESC,
          },
        }),
    ordersLoading: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setLoading = (bool) => {
    setState({ ...state, ordersLoading: bool });
  };

  useEffect(() => {
    const accountNumber: number | undefined = activeAccount?.accountNumber;

    if (accountNumber && !ordersSummary) {
      dispatch(OrdersAction.requestOrdersSummary(accountNumber, DATE_HELPER.oneTwentyDaysMonthDayYear, DATE_HELPER.todayDateWithTime));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This prevents making an inital call when the filter is the same.
    const ordersRequestConfigCompare = {
      ...state.ordersRequestConfig,
      sjsId: 0,
      sjsOptions: 0,
      startDate: moment(state.ordersRequestConfig.startDate).format(DateFormatsEnum.MonthDayYear),
      endDate: moment(state.ordersRequestConfig.endDate).format(DateFormatsEnum.MonthDayYear),
    };

    const ordersFiltersCompare = {
      ...ordersFilters,
      sjsId: 0,
      sjsOptions: 0,
      startDate: moment(ordersFilters?.startDate).format(DateFormatsEnum.MonthDayYear),
      endDate: moment(ordersFilters?.endDate).format(DateFormatsEnum.MonthDayYear),
    };

    if (accountNumber && !isEqual(ordersRequestConfigCompare, ordersFiltersCompare)) {
      // This works in tandem with adding to the actions.
      var request = state.ordersRequestConfig;
      if (request.endDate) {
        request.endDate = moment(state.ordersRequestConfig.endDate).format(DateFormatsEnum.MonthDayYear);
      }
      if (request.startDate) {
        request.startDate = moment(state.ordersRequestConfig.startDate).format(DateFormatsEnum.MonthDayYear);
      }
      dispatch(OrdersAction.requestOrders(request, accountNumber, false, setLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ordersRequestConfig]);

  const updateResults = (updatesToRequestOrdersConfig) => {
    setState({
      ...state,
      ordersRequestConfig: new OrdersRequestModel({
        ...state.ordersRequestConfig,
        ...updatesToRequestOrdersConfig,
        page: updatesToRequestOrdersConfig.page ? updatesToRequestOrdersConfig.page : 1,
      }),
    });
  };

  const refreshResults = () => {
    if (accountNumber) {
      dispatch(OrdersAction.requestOrders(state.ordersRequestConfig, accountNumber, false, setLoading)).catch((err) => console.log('dat err', err));
    }
  };

  const onNewPageClick = (page: number): void => {
    updateResults({ page });
  };

  let summaryData;
  let openOrdersCount = 0;
  let recentlyShippedCount = 0;

  ordersSummary?.data.summary.forEach((item) => {
    if (item.status === 'SHIPPED') {
      recentlyShippedCount = item.count;
    } else {
      if (item.status !== 'CANCELLED') {
        openOrdersCount = openOrdersCount + item.count;
      }
    }
  });
  if (ordersSummary?.data) {
    summaryData = [
      { label: 'Open Orders', value: openOrdersCount },
      { label: 'Recently Shipped', value: recentlyShippedCount, tooltipText: 'Orders shipped within the last 120 days' },
    ];
  }

  const { endDate, startDate, status } = state.ordersRequestConfig;

  if (!activeAccount) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl
      allowedRole={UserRolesEnum.ViewOrders}
      errorResponse={orders4xxResponses[OrdersAction.REQUEST_ORDERS_FINISHED]}
      defaultErrorResponse={true}
    >
      <div className='wrapper'>
        <div className='split'>
          <div className={'refresh-title__container'}>
            <h1 className='hdg hdg--1'>Orders</h1>

            <div className={'refresh-title__container-buttonPadding'}>
              {!state.ordersLoading && (
                <button
                  className={'icon--refresh '} // ${transactionHistoryLoading && 'btn--disabled'}
                  onClick={refreshResults}
                  disabled={state.ordersLoading}
                >
                  <Refresh />
                  <span>Refresh Orders</span>
                </button>
              )}
              {state.ordersLoading && <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 0 }} />}
            </div>
          </div>
          <GlobalSearch classNames='search--is-narrow' initialTarget={SearchEnum.Orders} referringPage={RouteEnum.Orders} />
        </div>
        <div className='vr4' />
        <h3 className='hdg hdg--3'>
          Orders Summary <span className={styles.lastXDays}>{`(${DayMessagesEnum.last120DaysLong})`}</span>
        </h3>
        <div className='vr2_5' />
        {summaryData && <Summary entries={summaryData} orders4xxResponses={orders4xxResponses} />}
        <div className='vr5' />
        <>
          <OrdersTable
            refreshResults={refreshResults}
            ordersLoading={state.ordersLoading}
            endDate={endDate}
            onNewPageClick={onNewPageClick}
            ordersResponse={ordersResponse}
            startDate={startDate}
            statusFilter={status}
            updateResults={updateResults}
            orders4xxResponses={orders4xxResponses}
          />
        </>
      </div>
    </AccessControl>
  );
};

export default OrdersPage;
