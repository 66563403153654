/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styles from './SearchResultsPage.module.scss';
import useQuery from '../../utilities/useQuery';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import SearchResultsList from './components/SearchResultsList/SearchResultsList';
import RouteEnum from '../../constants/RouteEnum';
import SearchRequestModel from 'stores/search/models/SearchRequestModel';
import IAccount from 'stores/accounts/models/IAccount';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import { Dispatch } from 'redux';
import SearchAction from 'stores/search/SearchAction';
import InvoicesRequestModel from 'stores/invoices/models/InvoicesRequestModel';
import OrdersRequestModel from 'stores/orders/models/OrdersRequestModel';
import SearchEnum from 'constants/SearchEnum';
import Title from 'components/Title/Title';
import Error4xx from 'components/Error4xx/Error4xx';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const MAP_REFERRING_PAGE_TO_BACK_LINK_TEXT = {
  [RouteEnum.Orders]: 'Orders',
  [RouteEnum.Dashboard]: 'Dashboard',
  [RouteEnum.Transactions]: 'Transactions',
};

const getBackToLinkText = (referringPage) => {
  if (!referringPage || !MAP_REFERRING_PAGE_TO_BACK_LINK_TEXT[referringPage]) {
    return 'Back';
  }
  return `Back to ${MAP_REFERRING_PAGE_TO_BACK_LINK_TEXT[referringPage]}`;
};

const getBackToLink = (referringPage) => {
  if (!referringPage || !MAP_REFERRING_PAGE_TO_BACK_LINK_TEXT[referringPage]) {
    return null;
  }
  return referringPage;
};

const whichSearchModel = (searchTarget: string | undefined, searchQuery: string | undefined): InvoicesRequestModel | OrdersRequestModel | null => {
  if (!searchTarget || !searchQuery) {
    return null;
  }

  const whichModel = {
    transactions: new InvoicesRequestModel({ page: 1, pageSize: 10, referenceId: searchQuery }),
    orders: new OrdersRequestModel({ page: 1, pageSize: 10, orderNumber: searchQuery }),
    poNumber: {
      transactions: new InvoicesRequestModel({ page: 1, pageSize: 10, purchaseOrderNumber: searchQuery }),
      orders: new OrdersRequestModel({ page: 1, pageSize: 10, poNumber: searchQuery }),
    },
  };

  return whichModel[searchTarget];
};

const displayWhichResults = ({ searchResults, searchTarget, onNewPageClick, poOnNewPageClick, possibleErrors }) => {
  if (searchResults && searchTarget && searchTarget === SearchEnum.PoNumber) {
    return (
      <>
        <Error4xx response={searchResults.orders} textOnly>
          <Title title='Orders' />
          <SearchResultsList
            po
            orders
            searchResults={searchResults.orders}
            criteria={SearchEnum.Orders}
            onNewPageClick={onNewPageClick}
            poOnNewPageClick={poOnNewPageClick}
          />{' '}
        </Error4xx>
        <div className='vr2' />
        <Error4xx response={searchResults.transactions} textOnly>
          <Title title='Transactions' />
          <SearchResultsList
            po
            transactions
            searchResults={searchResults.transactions}
            criteria={SearchEnum.Transactions}
            onNewPageClick={onNewPageClick}
            poOnNewPageClick={poOnNewPageClick}
          />
        </Error4xx>
      </>
    );
  }

  if (searchResults || (possibleErrors && searchTarget)) {
    return (
      <Error4xx response={possibleErrors} textOnly>
        <SearchResultsList
          poOnNewPageClick={poOnNewPageClick}
          searchResults={searchResults}
          criteria={searchTarget}
          onNewPageClick={onNewPageClick}
        />
      </Error4xx>
    );
  }
};

function SearchResultsPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const searchResults: any | null = useSelector((state: IStore) => oc(state).search.searchResponseModel(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const [isLoading, setIsLoading] = useState(false);
  const searchPossible4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [SearchAction.REQUEST_SEARCH_RESPONSE_MODEL_FINISHED]
  );
  const { referringPage, searchQuery, searchTarget }: { referringPage?: string; searchQuery?: string; searchTarget?: string } = useQuery();
  const [searchRequestConfig, setSearchRequestConfig] = useState<any>(null);

  // Set new search arguments
  useEffect(() => {
    setSearchRequestConfig(
      new SearchRequestModel({
        accountNumber,
        criteria: searchTarget,
        searchValue: searchQuery,
        searchModel: whichSearchModel(searchTarget, searchQuery),
      })
    );
  }, [accountNumber, searchTarget, searchQuery, dispatch]);

  // Dispatch search based on arguments
  useEffect(() => {
    if (
      accountNumber &&
      accountNumber !== 0 &&
      accountNumber !== undefined &&
      searchTarget &&
      searchQuery &&
      searchRequestConfig &&
      searchRequestConfig.accountNumber
    ) {
      dispatch(SearchAction.requestSearchReset(null));
      setIsLoading(true);
      dispatch(SearchAction.requestSearchResponseModel(searchRequestConfig)).then(() => {
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [dispatch, searchRequestConfig]);

  const poOnNewPageClick = (pageNumber: number, currentCase: string) => {
    if (currentCase === SearchEnum.Orders) {
      setSearchRequestConfig(
        new SearchRequestModel({
          ...searchRequestConfig,
          searchModel: { ...searchRequestConfig.searchModel, orders: { ...searchRequestConfig.searchModel.orders, page: pageNumber } },
        })
      );
    } else {
      setSearchRequestConfig(
        new SearchRequestModel({
          ...searchRequestConfig,
          searchModel: { ...searchRequestConfig.searchModel, transactions: { ...searchRequestConfig.searchModel.transactions, page: pageNumber } },
        })
      );
    }
  };

  const onNewPageClick = (pageNumber: number) => {
    setSearchRequestConfig(new SearchRequestModel({ ...searchRequestConfig, searchModel: { ...searchRequestConfig.searchModel, page: pageNumber } }));
  };

  return (
    <div className={styles['search-results']}>
      <div className={styles['search-results__hd']}></div>
      <div className={styles['search-results__bd']}>
        <ArrowLink position='back' classNames='vr2_5 arrow-link--inverse' to={getBackToLink(referringPage)}>
          {getBackToLinkText(referringPage)}
        </ArrowLink>
        <GlobalSearch
          classNames={styles['search-results__search']}
          initialTarget={searchTarget}
          initialValue={searchQuery}
          referringPage={referringPage}
        />
        <div className='vr5'></div>
        {isLoading ? (
          <>
            <LoadingIndicator isActive={true} inverted={true} className='full-height' />
          </>
        ) : (
          <>
            {displayWhichResults({
              searchResults,
              searchTarget,
              onNewPageClick,
              poOnNewPageClick,
              possibleErrors: searchPossible4xxResponses['SearchAction.REQUEST_SEARCH_RESPONSE_MODEL_FINISHED'],
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchResultsPage;
