import styles from './DashboardPage.module.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../models/IStore';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import RouteEnum from '../../constants/RouteEnum';
import OrdersResponseModel from '../../stores/orders/models/OrdersResponseModel';
import OrdersAction from '../../stores/orders/OrdersAction';
import CreditSummaryAction from '../../stores/credit-summary/CreditSummaryAction';
import CreditSummary from '../TransactionsPage/components/CreditSummary/CreditSummary';
import InvoicesAction from '../../stores/invoices/InvoicesAction';
import InvoicesResponseModel from '../../stores/invoices/models/InvoicesResponseModel';
import InvoiceCardsPreview from '../TransactionsPage/components/InvoiceCardsPreview/InvoiceCardsPreview';
import OrdersPreview from '../OrdersPage/components/OrdersPreview/OrdersPreview';
import InvoicesRequestModel from '../../stores/invoices/models/InvoicesRequestModel';
import UserResponseModel from '../../stores/users/models/UserResponseModel';
import { oc } from 'ts-optchain.macro';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import IAccount from 'stores/accounts/models/IAccount';
import OrdersRequestModel from 'stores/orders/models/OrdersRequestModel';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import { DATE_HELPER } from 'index';
import ICreditSummary from 'stores/credit-summary/models/ICreditSummary';
import { Dispatch } from 'redux';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import { pageLoader } from 'components/PageLoader/PageLoader';
import { LANG } from 'translations/LangKeys';

interface IState {
  timeOfDay: string;
  isLoading: boolean;
}

const DashBoardPage = () => {
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  // const accountNumber: number | undefined = activeAccount?.accountNumber;
  const creditSummary: ICreditSummary | any | null = useSelector((state: IStore) => oc(state).creditSummary.creditSummaryResponse(null));
  const orders: OrdersResponseModel | null = useSelector((state: IStore) => oc(state).orders.ordersDashboardResponse(null));
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const invoices: InvoicesResponseModel | null = useSelector((state: IStore) => oc(state).invoices.invoicesDashboardResponse(null));
  const [state, setState] = useState<IState>({ timeOfDay: 'morning', isLoading: true });

  const dashboard4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [
      InvoicesAction.REQUEST_INVOICES_DASHBOARD_FINISHED,
      CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED,
      OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED,
      OrdersAction.REQUEST_ORDERS_DASHBOARD_FINISHED,
    ]
  );

  function setTimeOfDay(): string {
    const hour = new Date().getHours();
    const timeOfDay =
      // eslint-disable-next-line no-nested-ternary
      hour < 12
        ? LANG._t(LANG.nameSpaces.dashBoard, LANG.goodMorning)
        : hour < 18
        ? LANG._t(LANG.nameSpaces.dashBoard, LANG.goodAfternoon)
        : LANG._t(LANG.nameSpaces.dashBoard, LANG.goodEvening);

    return timeOfDay;
  }

  useEffect(() => {
    const accountNumber: number | undefined = activeAccount?.accountNumber;
    if (accountNumber) {
      Promise.all([
        dispatch(OrdersAction.requestOrdersSummary(accountNumber, DATE_HELPER.oneTwentyDaysMonthDayYear, DATE_HELPER.todayDateWithTime)),
        dispatch(CreditSummaryAction.requestCreditSummary(accountNumber)),
        dispatch(
          OrdersAction.requestOrders(
            new OrdersRequestModel({
              pageSize: 10,
              page: 1,
              startDate: DATE_HELPER.oneTwentyDaysToDate,
              endDate: DATE_HELPER.todayToDate,
              sortData: {
                Column: 'orderNumber',
                SortDirection: SortDirectionEnum.DESC,
              },
            }),
            accountNumber,
            true
          )
        ),
        dispatch(
          InvoicesAction.requestInvoices(
            new InvoicesRequestModel({
              pageSize: 4,
              transactionType: 'INV',
              transactionStatus: 'OPEN',
              sortData: {
                Column: 'TransactionDueDate',
                SortDirection: SortDirectionEnum.ASC,
              },
            }),
            accountNumber,
            true
          )
        ),
      ]).then(() => setState({ timeOfDay: setTimeOfDay(), isLoading: false }));
    }

    // eslint-disable-next-line
  }, []);

  function getSplitHeader(name: string, linkText: string, url: string): JSX.Element {
    return (
      <div className='split split--centered'>
        <h3 className='hdg hdg--3'>{name}</h3>
        <ArrowLink position='forward' classNames='vr1_5' to={url}>
          {linkText}
        </ArrowLink>
      </div>
    );
  }

  const { timeOfDay } = state;

  if (state.isLoading) {
    return pageLoader(activeAccount);
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ViewDashboard} noAccessRedirect={true}>
      <>
        <div className={styles.dashboard__hero}>
          <div className='wrapper'>
            <h1 className='hdg hdg--1'>
              {timeOfDay}, {currentUser && currentUser.data.firstName}
            </h1>
            <div className='vr2' />
            <GlobalSearch classNames={styles.dashboard__search} referringPage={RouteEnum.Dashboard} />
          </div>
        </div>
        <div className='wrapper'>
          <div className='vr4' />
          <h3 className='hdg hdg--3'>Credit Summary</h3>
          <Error4xx response={dashboard4xxResponses['CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED']}>
            <CreditSummary creditSummary={oc(creditSummary).data(undefined)} />
          </Error4xx>
          <div className='vr4' />
          {getSplitHeader('Upcoming Invoices', 'View All Invoices', RouteEnum.Transactions)}
          <div className='vr3' />
          <Error4xx response={dashboard4xxResponses['InvoicesAction.REQUEST_INVOICES_DASHBOARD_FINISHED']} textOnly>
            <InvoiceCardsPreview invoicesResponse={invoices} />
          </Error4xx>
          <div className='vr4' />
          {getSplitHeader('All Orders', 'View All Orders', 'pages/orders')}
          <div className='vr3' />
          <Error4xx response={dashboard4xxResponses['OrdersAction.REQUEST_ORDERS_DASHBOARD_FINISHED']} textOnly>
            <OrdersPreview ordersResponse={orders} />
          </Error4xx>
        </div>
      </>
    </AccessControl>
  );
};

export { DashBoardPage as Unconnected };
export default DashBoardPage;
