import { IDynamicFormAttribute, IDynamicFormAttributeDependecyResponse } from 'stores/create-order/models/IDynamicFormModels';
import CreateOrderStatelessCalls, { IGetFieldsUpdate } from 'stores/create-order/CreateOrderStatelessCalls';
import generateIndVariableValueCheck from './generateIndVariableValueCheck';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

/**
 * Makes all dependency calls as long as field has a independentVariable1 and is not the CLUBS FIELD
 * Assumes entire form has selected values.
 *
 * @param masterFieldObject
 * @param product
 * @param accountNumber
 * @returns
 */
export default async function makeAllDependencyCalls(masterFieldObject: IDynamicFormAttribute[], product, accountNumber) {
  const fieldsForDependencyCalls = masterFieldObject.filter((item) => item.independentVariable1 && item.attributeName !== 'CLUBS');

  const res = await Promise.all(
    fieldsForDependencyCalls.map(async (item: IDynamicFormAttribute) => {
      const variableValues = generateIndVariableValueCheck(item, masterFieldObject);
      const requestConfig: IGetFieldsUpdate = {
        product,
        dependentVariable: item.attributeName,
        independentVariable1: variableValues.independentVariable1,
        independentVariable1Value: variableValues.independentVariableValue1,
        independentVariable2: variableValues.independentVariable2,
        independentVariable2Value: variableValues.independentVariableValue2,
        accountNumber,
      };

      const fieldResponse = await CreateOrderStatelessCalls.getFieldsUpdate(requestConfig);
      if (fieldResponse instanceof HttpErrorResponseModel) {
        return fieldResponse;
      }
      const fieldData: IDynamicFormAttributeDependecyResponse[] = fieldResponse.data.data;
      const fieldDataValues = fieldData.map((item) => item.dependentVariableValue);
      const listOfNewValues = item.attributeValues.filter((value) => fieldDataValues.includes(value.attributeValue));

      return {
        attributeName: item.attributeName,
        values: listOfNewValues,
      };
    })
  );

  const hasError = res.find((item) => item instanceof HttpErrorResponseModel);

  if (hasError) {
    return hasError;
  }

  return masterFieldObject.map((item) => {
    const values = res.find((dep) => !(dep instanceof HttpErrorResponseModel) && item.attributeName === dep.attributeName);
    if (values) {
      return {
        ...item,
        attributeValues: !(values instanceof HttpErrorResponseModel) && values.values,
        userSelected: item.inputDisplayFlag === 'Y',
      };
    }

    return {
      ...item,
      attributeValues: item.attributeValues ? item.attributeValues : [],
      userSelected: item.inputDisplayFlag === 'Y',
    };
  });
}
