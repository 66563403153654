export function shouldMakeDependencyCall(variableValues, SUBMITTED_LIST_INSTANCE, item) {
  if (
    (variableValues.needsIndependentVariable1 === true &&
      variableValues.independentVariableValue1 !== '' &&
      variableValues.needsIndependentVariable2 === false &&
      !SUBMITTED_LIST_INSTANCE.submittedList.includes(item.attributeName)) ||
    (variableValues.needsIndependentVariable1 === true &&
      variableValues.needsIndependentVariable2 === true &&
      variableValues.independentVariableValue1 !== '' &&
      variableValues.independentVariableValue2 !== '' &&
      !SUBMITTED_LIST_INSTANCE.submittedList.includes(item.attributeName))
  ) {
    return true;
  }

  return false;
}
