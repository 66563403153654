import React from 'react';
import InvoiceCard from '../InvoiceCard/InvoiceCard';
import InvoicesResponseModel from '../../../../stores/invoices/models/InvoicesResponseModel';
import IInvoices from '../../../../stores/invoices/models/IInvoices';

interface IProps {
  invoicesResponse: InvoicesResponseModel | null;
}

const InvoiceCardsPreview = ({ invoicesResponse }: IProps): JSX.Element | null => {
  if (!invoicesResponse) {
    return null;
  }

  const invoicesData: IInvoices[] = invoicesResponse.data;

  return (
    <div className='invoice-cards'>
      {invoicesData.map((invoice, i) => (
        <InvoiceCard key={`${invoice.referenceNumber}-${i}`} invoice={invoice} />
      ))}
    </div>
  );
};

export default InvoiceCardsPreview;
