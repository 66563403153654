import { ReduxDispatch } from '../../models/ReduxProps';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import PaymentResponseModel from './models/PaymentsResponseModel';
import PaymentsEffect from './PaymentsEffect';

type ActionUnion = void | HttpErrorResponseModel | PaymentResponseModel;

export default class PaymentsAction {
  public static readonly GET_PAYMENT: string = 'PaymentsAction.GET_PAYMENT';
  public static readonly GET_PAYMENT_FINISHED: string = 'PaymentsAction.GET_PAYMENT_FINISHED';
  public static readonly RESET_PAYMENT: string = 'PaymentsAction.RESET_PAYMENT';

  public static requestPayment(accountNum: number, tranNumber: string, tranRef: string, tranDate: string): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<PaymentResponseModel>(
        dispatch,
        PaymentsAction.GET_PAYMENT,
        PaymentsEffect.requestPayment,
        accountNum,
        tranNumber,
        tranRef,
        tranDate
      );
    };
  }

  public static resetPayment(payload: any): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({
        type: PaymentsAction.RESET_PAYMENT,
        payload,
      });
    };
  }
}
