import environment, { Environment } from './base';

const baseUri = 'https://eu.pinggolf.biz';
const baseApi = 'https://api.eu.pinggolf.biz';
const baseFittingApi = 'https://locations.fittingmanagement.pingtechnology.digital';
const env = environment(baseUri, baseApi, baseFittingApi);
const tenantName = 'pingbizna';
const signInUserFlow = 'b2c_1_signin_v2';

const productionEUEnv: Environment = {
  ...env,
  // override anything that gets added from base.
  msalConfig: {
    auth: {
      clientId: '99d11e4c-67dc-4050-b3e0-67e4a2f444a4',
      redirectUri: baseUri,
      postLogoutRedirectUri: baseUri,
      authority: `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInUserFlow}`,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
    },
    cacheOptions: {
      cacheLocation: 'sessionStorage',
    },
  },
  msalLoginRequest: {
    scopes: ['openid', `https://${tenantName}.onmicrosoft.com/api/user_impersonation`],
  },
  isKentico: false,
  isDevelopment: false,
  isProduction: true,
  isTesting: false,
  isStage: false,
  name: 'prod-eu',
  regionName: 'eu',
};

export default productionEUEnv;
