// Need to be removed for BI-1939
/* eslint-disable */
import Value from 'components/Value/Value';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ILocations from 'utilities/Location/models/ILocations';
import styles from './LocationCardSection.module.scss';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import LocationStatusUtility from 'utilities/Location/LocationStatusUtility';
// import LocationUtility from 'utilities/Location/LocationUtility';
// import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
// import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
// import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
// import ToastsAction from 'stores/toasts/ToastsAction';
// import ToastStatusEnum from 'constants/ToastStatusEnum';
// import LocationsResponseModel from 'utilities/Location/models/LocationsResponseModel';
import { ReactComponent as Kabob } from '../../../../assets/media/icons/icon-kebob-menu.svg';
import InfoIconWithTooltip from '../InfoIconWithTooltip/InfoIconWithTooltip';

interface ISectionProps {
  label: string;
  valueProp: string;
  location: ILocations;
  isEditing: () => void | undefined;
  statusName: string;
}

const LocationCardSection = ({ label, location, valueProp, isEditing, statusName }: ISectionProps) => {
  const dispatch: Dispatch = useDispatch();
  const [isToggledOn, setIsToggledOn] = useState<boolean>(false);
  const { statusTable, updateAllLocationState, updateAccountLocations } = useContext(LocationContext);

  const statusMessage = 'Set whether Location is currently Active or Inactive internally within Biz.';
  const visibilityMessage = 'Display or Hide Location from Fitter Maps';

  // const handleToggle = (name: string) => (e) => {
  //   const statusName = isToggledOn ? 'inactive' : 'active';

  //   LocationStatusUtility.getStatusId(statusTable, statusName).then((res) => {
  //     const updateRequest = new UpdateLocationRequestModel({
  //       statusId: res,
  //     });
  //     LocationUtility.updateLocation(location.id, updateRequest).then((response) => {
  //       LocationUtility.requestLocationByAccount(location.accountNumber).then((response: LocationsResponseModel) => {
  //         updateAccountLocations && updateAccountLocations(response.data);
  //       });
  //       if (response && response.data) {
  //         if (name === 'active') {
  //           setIsToggledOn(false);
  //         } else if (name === 'inactive') {
  //           setIsToggledOn(true);
  //         }
  //         const updatedLocation = response.data;
  //         updateAllLocationState &&
  //           updateAllLocationState({
  //             id: updatedLocation.id,
  //             name: updatedLocation.name,
  //             displayHours: updatedLocation.displayHours,
  //             regionId: updatedLocation.regionId,
  //             statusId: updatedLocation.statusId,
  //             hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
  //             certificationsVerified: updatedLocation.certificationsVerified,
  //             environmentsVerified: updatedLocation.environmentsVerified,
  //             technologiesVerified: updatedLocation.technologiesVerified,
  //             displayOnMap: updatedLocation.displayOnMap,
  //           });
  //       }
  //       if (response instanceof HttpErrorResponseModel) {
  //         dispatch(ToastsAction.add(LocationErrorMessages.UPDATE_LOCATION, ToastStatusEnum.Error));
  //       }
  //     });
  //   });
  // };

  const updateStatusName = useCallback(
    async (statusId: string): Promise<void> => {
      const statusName = await LocationStatusUtility.getStatusName(statusId, statusTable);
      const initialToggle = statusName === 'active' ? true : false;
      setIsToggledOn(initialToggle);
    },
    [statusTable]
  );

  useEffect(() => {
    if (location.statusId) {
      updateStatusName(location.statusId);
    }
  }, [location.statusId, updateStatusName]);

  return (
    <div
      className={`${styles.section} ${styles[`section--${valueProp}`]} ${
        valueProp === 'name' || valueProp === 'street' || valueProp === 'city' ? styles.column_hidden : null
      }`}
    >
      <div className={styles.section__label}>
        {label}
        {valueProp === 'status' || valueProp === 'visibility' ? (
          <InfoIconWithTooltip message={valueProp === 'status' ? statusMessage : visibilityMessage} alignment='right' />
        ) : null}
      </div>
      {valueProp === 'name' && <Value value={location?.[valueProp]} />}
      {valueProp !== 'name' && <Value value={location?.address?.[valueProp]} />}
      {valueProp === 'visibility' && statusName === 'inactive' && <Value value={'--'} />}
      {valueProp === 'visibility' && statusName !== 'inactive' && <Value value={location.displayOnMap ? 'Displays on map' : 'Hidden from map'} />}
      {valueProp === '' && statusName !== 'suspended' && <Kabob className={styles.edit} onClick={isEditing} />}
      {valueProp === 'status' && (
        // <Toggle handleToggle={handleToggle} statusName={statusName} isOn={isToggledOn} locationId={location.id} />
        <p className={styles[statusName]}>{!statusName ? 'suspended' : statusName.charAt(0).toUpperCase() + statusName.slice(1)}</p>
      )}
    </div>
  );
};

export default LocationCardSection;
