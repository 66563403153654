import React from 'react';
import styles from './PrimarySubNavMenuLink.module.scss';
import { ReactComponent as CaretDown } from '../../../../assets/media/icons/icon-caret-down.svg';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';

interface IProps {
  children?: JSX.Element | boolean;
  Icon?: Function;
  name: string;
  toggle?: boolean;
  IconToggle?: boolean;
  activeState?: boolean;
  onLinkClick?: () => void;
  path: string;
  access: UserRolesEnum;
}

const PrimarySubNavMenuLink = ({ children, Icon, name, onLinkClick, toggle, IconToggle, activeState, path, access }: IProps) => {
  const iconClassName = IconToggle ? 'icon--dir-0' : 'icon--dir-270';
  return (
    <AccessControl allowedRole={access}>
      <ul className={`${styles.primaryNav_Menu_Link} focus-ring ${activeState && path.includes(name?.toLowerCase()) && 'active'}`}>
        <div className={`${styles.primaryNav_Link} ${activeState && 'active'}`} onClick={onLinkClick}>
          {Icon && <Icon aria-hidden={true} focusable={false} role='img' className={styles.primaryNav_Link_Icon} />}
          {name && <span className={styles.primaryNav_Menu_Link_Text}>{name}</span>}
          {toggle && <CaretDown className={`icon ${styles.iconStyle} ${iconClassName}`} aria-hidden='true' focusable='false' role='img' />}
        </div>
        {children}
      </ul>
    </AccessControl>
  );
};

export default PrimarySubNavMenuLink;
