import React from 'react';
import styles from '../OrderItems/OrderItems.module.scss';
import { useSelector } from 'react-redux';
import IOrder, { Item } from 'stores/orders/models/IOrder';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import TrackingItem from './components/TrackingItem';
import { ReactComponent as ArrowDown } from 'assets/media/icons/icon-arrow-down.svg';

interface IProps {
  tracking: any;
  setTracking: any;
  index: number;
  onExit?: () => void;
  exitMessage?: string;
  hideOrderDetails?: boolean;
}

const OrderTracking = ({ tracking, setTracking, index, onExit, exitMessage, hideOrderDetails }: IProps) => {
  const { trackingList, currentOpenItemNumber } = tracking;
  const currentTrackingList = trackingList.find((item) => item[0].itemNumber === currentOpenItemNumber);
  const order: IOrder[] | undefined = useSelector((state: IStore) => oc(state).orders.orderResponse.data(undefined));
  const currentOrder: IOrder | undefined = order?.[index];
  const currentOrderItem: Item | undefined = currentOrder?.items.find((item) => item.itemNumber === currentOpenItemNumber);

  return (
    <div className={styles['order-items']}>
      <div className={styles['order-items__hd']}>
        <h3 className='hdg--3'>Tracking</h3>

        <button
          className={styles.trackingArrow}
          onClick={() => (onExit ? onExit() : setTracking({ ...tracking, trackingOpen: false, activeHeaderId: null }))}
        >
          <span className={styles.trackingArrowIcon}>
            <ArrowDown />
          </span>
          {exitMessage ? exitMessage : 'Back to Order'}
        </button>
      </div>
      {currentTrackingList.map((item, i) => {
        return <TrackingItem key={i} item={item} currentOrderItem={currentOrderItem} order={currentOrder} hideOrderDetails={hideOrderDetails} />;
      })}
      <div className='vr vr4'></div>
    </div>
  );
};

export default OrderTracking;
