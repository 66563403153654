import React, { useState } from 'react';
import ILocationInfoError from 'containers/LocationInfoPage/models/ILocationError';
import ICertification from '../../../../utilities/Location/models/ICertification';
import IEnvironment from '../../../../utilities/Location/models/IEnvironment';
import ITechnology from '../TechnologyTab/models/ITechnology';
import Categories from './components/CategoryComponents/Categories/Categories';
import FittingTerminology from './components/FittingTerminology/FittingTerminology';
import styles from './LocationTabTemplate.module.scss';
import LocationInfoError from '../LocationInfoError/LocationInfoError';
import LocationInfoStatus from '../LocationInfoStatus/LocationInfoStatus';
import LocationTitle from '../LocationTitle/LocationTitle';

type CategoryUnion = ICertification | IEnvironment | ITechnology;

interface IProps {
  categoryList: CategoryUnion[];
  tabName: string;
  itemListName: string;
  statePropertyName: string;
  locationSpecificItems: CategoryUnion[];
  updateVerification: Function;
  addLocationSpecificItem: Function;
  deleteLocationSpecificItem: Function;
  isError: ILocationInfoError;
}

const LocationTabTemplate = ({
  categoryList,
  tabName,
  itemListName,
  statePropertyName,
  locationSpecificItems,
  updateVerification,
  addLocationSpecificItem,
  deleteLocationSpecificItem,
  isError,
}: IProps): JSX.Element => {
  const protectedCategoryList = categoryList.filter((cat) => cat.protected);
  const unprotectedCategoryList = categoryList.filter((cat) => !cat.protected);
  const itemListTitle = itemListName[0].toUpperCase() + itemListName.slice(1);
  const [saved, setSaved] = useState<boolean>(false);

  const handleAdd = (id: string) => {
    setSaved(true);
    addLocationSpecificItem(id);
  };
  const handleDelete = (id: string) => {
    setSaved(true);
    deleteLocationSpecificItem(id);
  };
  return (
    <div>
      <div className='split'>
        <LocationTitle saved={saved} setSaved={setSaved} title={`${itemListTitle === 'Certifications' ? 'Programs' : itemListTitle}`} />
        <LocationInfoStatus />
      </div>
      {isError.isError ? (
        <LocationInfoError message={isError.message} />
      ) : (
        <>
          <div className={styles.templateContainer}>
            <div className={'leftSide'}>
              {protectedCategoryList && protectedCategoryList.length !== 0 && (
                <Categories
                  listOfCategories={protectedCategoryList}
                  itemListName={itemListName}
                  locationSpecificItems={locationSpecificItems}
                  addLocationSpecificItem={handleAdd}
                  deleteLocationSpecificItem={handleDelete}
                  isProtected={true}
                />
              )}
              {itemListTitle === 'Certifications' && (
                <div className={styles.templateSubTitle}>
                  By checking the boxes below, you're indicating that you have and <u>actively</u> use the following PING fitting tools:
                </div>
              )}
              {categoryList.length !== 0 && (
                <Categories
                  listOfCategories={unprotectedCategoryList}
                  itemListName={itemListName}
                  locationSpecificItems={locationSpecificItems}
                  addLocationSpecificItem={handleAdd}
                  deleteLocationSpecificItem={handleDelete}
                  isProtected={false}
                />
              )}
            </div>
            <div className='rightSide'>
              <FittingTerminology itemListName={itemListName} listOfCategories={categoryList} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LocationTabTemplate;
