import React from 'react';
import { useTable } from 'react-table';
import styles from '../../containers/InvoicePage/InvoicePage.module.scss';

interface IProps {
  columns: any;
  data: any;
}

const BasicTable = ({ columns, data }: IProps) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()} className={styles['basic-table']}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} className={styles['basic-table__row--hd']} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th key={i} className={`${styles['basic-table__row__cell']} ${styles['basic-table__row__cell__label']}`} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} className={styles['basic-table__row--bd']} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td key={i} className={styles['basic-table__row__cell']} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default BasicTable;
