import Form from 'components/Form/Form';
import React from 'react';
import { DropShipStateModel } from '../models/DropShipStateModel';
import styles from '../OrderShipping.module.scss';
import { IFormErrors } from 'components/Form/IFormErrors';
import { useRegionalDropShipFields } from 'utilities/useRegionalDropShipFields';

interface IProps {
  formState: DropShipStateModel;
  setFormState: (v: DropShipStateModel) => void;
  handleReview: any;
  formRef: any;
  setFormErrors: any;
  formErrors: IFormErrors;
  getFormErrors: any;
}

const DropShippingForm = ({ formRef, handleReview, formState, setFormState, getFormErrors, formErrors, setFormErrors }: IProps) => {
  const dropShipFields = useRegionalDropShipFields();

  const handleFieldChange = (input) => (e) => {
    setFormState({ ...formState, [input]: e.target.value });

    // Remove errors when user types.
    if (formErrors.errors.length > 0) {
      const newFormState = { ...formState, [input]: e.target.value };
      const errors = getFormErrors(newFormState);
      setFormErrors(errors);
    }
  };

  const handleSelectFieldChange = (newValue: any) => {
    setFormState({ ...formState, [newValue.name]: newValue.value });

    // Remove form errors
    if (formErrors.errors.length > 0) {
      const newFormState = { ...formState, [newValue.name]: newValue.value };
      const errors = getFormErrors(newFormState);
      setFormErrors(errors);
    }
  };

  return (
    <>
      <h3 className='hdg hdg--4 vr2'>Drop Ship Address</h3>
      {dropShipFields && (
        <Form
          additionalStyle={styles['order-shipping__form']}
          fields={dropShipFields}
          formId={'drop-ship-form'}
          formRef={formRef}
          formState={formState}
          handleFieldChange={handleFieldChange}
          handleSelectFieldChange={handleSelectFieldChange}
          handleSubmit={handleReview}
        />
      )}
      <div className='vr20' />

      {/* <div>
        <button type='submit' form='drop-ship-form' className='btn btn--form-button' onClick={handleSubmit}>
          Validate
        </button>
      </div> */}
    </>
  );
};

export default DropShippingForm;
