/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import styles from '../InvoicePage.module.scss';
import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import OrdersAction from '../../../stores/orders/OrdersAction';
import InvoiceResponseModel from 'stores/invoices/models/InvoiceResponseModel';
import IInvoice, { Item } from 'stores/invoices/models/IInvoice';
import { DATE_HELPER } from 'index';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  invoiceData: IInvoice[];
  accountNumber: number | undefined;
  invoiceResponse: InvoiceResponseModel | null;
}

const ProductList = ({ invoiceData, accountNumber, invoiceResponse }: IProps) => {
  const dispatch: Dispatch = useDispatch();

  // TODO: should we be showing order number at top
  const orderNumber = invoiceData[0].items[0]?.orderNumber;
  const invoiceItems: Item[] = invoiceData[0].items;
  const currency = invoiceData[0].currency;

  useEffect(() => {
    if (orderNumber) {
      dispatch(OrdersAction.requestOrder(orderNumber.toString(), accountNumber));
    }
  }, [dispatch, accountNumber, orderNumber]);

  const columns = [
    {
      Header: 'Item',
      accessor: 'itemNumber',
    },
    {
      Header: 'Description',
      accessor: (invoiceItem: Item) => {
        return (
          <div>
            <div className={styles['product-list-table__row__cell__category']}>{invoiceItem.product}</div>
            {invoiceItem.attributes[0].itemDescription ? invoiceItem.attributes[0].itemDescription : 'n/a'}
          </div>
        );
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    /**
     * TODO: Missing shipped
     **/
    // {
    //   Header: 'Ship',
    //   accessor: 'itemShip',
    // },
    {
      Header: 'Item Total',
      accessor: (item: Item) => {
        return <div>{formatCurrency(currency, item.priceTotal)}</div>;
      },
    },
    {
      Header: 'Est. Date',
      accessor: (item: Item) => {
        return <div>{DATE_HELPER.getDisplayDate(item.quoteDate)}</div>;
      },
    },
    {
      Header: 'Status Date',
      accessor: (item: Item) => DATE_HELPER.getDisplayDate(item.itemStatusDate),
    },
  ];

  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: invoiceItems,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: 1,
    },
    usePagination
  );

  return (
    <div>
      <table className={styles['product-list-table']}>
        <tbody>
          {headerGroups.map((headerGroup, i) => (
            <tr className={styles['product-list-table__row--hd']} key={`headerGroup-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${styles['product-list-table__row__cell']} ${styles['product-list-table__row__cell__label']}`}
                  scope='col'
                  key={column.id}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
          {rows.map((row, index) => {
            prepareRow(row);

            return (
              <tr key={index} className={styles['product-list-table__row--bd']} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} className={styles['product-list-table__row__cell']}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;
