import { BaseModel } from 'sjs-base-model';

export default class HourUpdateRequestModel extends BaseModel {
  timeZone: null | string;
  mondayStart: null | string;
  mondayEnd: null | string;
  tuesdayStart: null | string;
  tuesdayEnd: null | string;
  wednesdayStart: null | string;
  wednesdayEnd: null | string;
  thursdayStart: null | string;
  thursdayEnd: null | string;
  fridayStart: null | string;
  fridayEnd: null | string;
  saturdayStart: null | string;
  saturdayEnd: null | string | null;
  sundayStart: null | string;
  sundayEnd: null | string;

  constructor(data: {}) {
    super();
    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
