import PaginatedRequestModel from '../../pagination/IPaginatedRequestModel';

/*
    // Returned Api Data Sample
    {
      "data": IOrder[],
      ...PaginatedResponseModel
    }
 */
export default class OrdersRequestModel extends PaginatedRequestModel {
  orderNumber: number;
  poNumber: string;
  startDate: Date | undefined | string = undefined; // *
  endDate: Date | undefined | string = undefined; // *
  status: string = '';

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
