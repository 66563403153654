import React from 'react';
import styles from './Toggle.module.scss';

interface IToggleProp {
  isOn: boolean;
  handleToggle: Function;
  statusName: string | undefined;
  locationId?: string;
}
const Toggle = ({ handleToggle, statusName, isOn, locationId }: IToggleProp) => {
  return (
    <>
      <div className={styles.switchContainer}>
        <input checked={isOn} onChange={handleToggle(statusName)} className={styles.switchCheckbox} id={locationId} type='checkbox' />
        <label className={styles.switchLabel} htmlFor={locationId}>
          <span className={styles.switchButton} />
        </label>
      </div>
    </>
  );
};

export default Toggle;
