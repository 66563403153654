import React from 'react';
import OrdersResponseModel from '../../../../stores/orders/models/OrdersResponseModel';
import OrdersCard from '../OrdersCard/OrdersCard';
import Pagination from '../../../../components/Pagination/Pagination';
import DateFilter from 'components/DateFilter/DateFilter';
import OrdersRequestModel from '../../../../stores/orders/models/OrdersRequestModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Error4xx from 'components/Error4xx/Error4xx';
import { ReactComponent as ArrowDown } from 'assets/media/icons/icon-arrow-down.svg';

interface IProps {
  endDate: OrdersRequestModel['endDate'];
  onNewPageClick: (page: number) => void;
  ordersResponse: OrdersResponseModel | null;
  startDate: OrdersRequestModel['startDate'];
  statusFilter: OrdersRequestModel['status'];
  updateResults: Function;
  ordersLoading: boolean;
  refreshResults: () => void;
  orders4xxResponses: any;
}

function OrdersTable({
  endDate,
  onNewPageClick,
  ordersResponse,
  startDate,
  statusFilter,
  updateResults,
  ordersLoading,
  refreshResults,
  orders4xxResponses,
}: IProps): JSX.Element | null {
  return (
    <>
      <div className='table__header'>
        <div className='table__header__with-message'>
          <h3 className='hdg hdg--3'>All Orders</h3>
          <span>
            {' '}
            To view Orders older than 120 days, set date range using Custom Calendar{' '}
            <ArrowDown style={{ color: 'black' }} className='icon icon--dir-90' aria-hidden='true' focusable='false' role='img' />
          </span>
        </div>

        <div className='table__header__filters'>
          <DateFilter endDate={endDate} updateDates={updateResults} startDate={startDate} />
          <select
            className='table__filter btn focus-ring'
            data-is-selected={Boolean(statusFilter)}
            onChange={(e: any): void => updateResults({ status: e.target.value })}
            value={statusFilter}
          >
            <option value=''>Any Status</option>
            <option value='PRODUCTION'>Production</option>
            <option value='BOXED'>Boxed</option>
            <option value='SHIPPED'>Shipped</option>
            <option value='CANCELLED'>Cancelled</option>
            <option value='ON ORDER'>On Order</option>
          </select>
        </div>
      </div>

      {ordersLoading ? (
        <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
      ) : (
        <Error4xx response={orders4xxResponses['OrdersAction.REQUEST_ORDERS_FINISHED']} textOnly>
          {ordersResponse && (
            <>
              {ordersResponse?.data.map((order, i) => {
                return <OrdersCard key={order.orderNumber + i} order={order} />;
              })}
              <Pagination data={ordersResponse} onNewPageClick={onNewPageClick} />
            </>
          )}
        </Error4xx>
      )}
    </>
  );
}

export default OrdersTable;
