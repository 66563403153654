import styles from './EditAccountsTable.module.scss';
import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/media/icons/icon-arrow-down.svg';
import IAccount from '../../../../stores/accounts/models/IAccount';
import Pagination from '../../../../components/Pagination/Pagination';
import IAccountsResponse from '../../../../stores/accounts/models/IAccountsResponse';
import { useTable, usePagination } from 'react-table';
import IDataTableUIState from '../../../../stores/dataTable/models/IDataTableUIState';
import SortDirectionEnum from '../../../../constants/SortDirectionEnum';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import AccountsColumnNamesEnum from 'constants/AccountsColumnNamesEnum';
import { selectRequesting } from 'selectors/requesting/RequestingSelector';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import AccountsAction from 'stores/accounts/AccountsAction';
import Error4xx from 'components/Error4xx/Error4xx';
import { KenticoUser } from 'stores/users/models/IUserV3';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import EditAccountCard from '../AccountCard/EditAccountCard';

interface IProps {
  accountData: IAccountsResponse;
  ui: IDataTableUIState;
  onSortClick: (sortCol: string) => void;
  onNewPageClick: (page: number) => void;
  userToLink?: KenticoUser;
  closeModal?: any;
  onAddAccount?: (v: IAccount) => void;
  onRemoveAccount?: (v: IAccount) => void;
  selectedAccounts?: IAccount[];
}

export default function EditAccountsTable(props: IProps) {
  const [activeRow, setActiveRow] = useState(0);
  const { accountData, ui, onSortClick, onNewPageClick, userToLink, onAddAccount, onRemoveAccount, selectedAccounts } = props;
  const tableData = accountData.data.map((item) => {
    return {
      [AccountsColumnNamesEnum.AccountNumber]: item.accountNumber,
      [AccountsColumnNamesEnum.AccountName]: {
        accountName: item.accountName,
        businessName: item.businessName,
      },
    };
  });
  const controlledPageCount = accountData.totalPages;
  let allAccountsLinked = false;
  const onLinkorUnlink = (accountClicked: IAccount) => {
    const isAccountSelected = selectedAccounts?.find((item) => item.accountNumber === accountClicked.accountNumber) ? true : false;
    if (!onRemoveAccount || !onAddAccount) {
      console.warn('You are missing either onRemoveAccount or onAddAccount');
      return;
    }

    if (isAccountSelected) {
      onRemoveAccount(accountClicked);
    } else {
      onAddAccount(accountClicked);
    }
  };

  const columns = [
    {
      Header: 'Account Number',
      accessor: AccountsColumnNamesEnum.AccountNumber,
    },
    {
      Header: 'Account Name',
      accessor: AccountsColumnNamesEnum.AccountName,
    },
  ];

  const accountsList4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED]
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const onMouseEnter = (rowNum: number): void => {
    setActiveRow(rowNum);
  };

  const onMouseLeave = (): void => {
    setActiveRow(0);
  };

  const requestingAccounts = useSelector((state: IStore) => selectRequesting(state, ['AccountsAction.REQUEST_ACCOUNT_RESPONSE']));
  const iconDirection = ui.sortData.SortDirection === SortDirectionEnum.DESC ? 'icon--dir-0' : 'icon--dir-180';

  if (userToLink && selectedAccounts) {
    const found: any = [];
    selectedAccounts.forEach((account) => {
      const matchingAccount = page.find((row) => row.values.accountNumber === account.accountNumber);
      if (matchingAccount) {
        found.push(matchingAccount);
      }
    });

    if (found.length === MAIN_CONFIG.BasePaginatedModel.pageSize) {
      allAccountsLinked = true;
    } else {
      allAccountsLinked = false;
    }
  }

  return (
    <div className={`${styles['acc-table-wrapper']} ${userToLink ? styles['acc-split'] : ''}`}>
      {requestingAccounts && <LoadingIndicator className='accounts-loader' isActive={true} inverted={true} />}
      <Error4xx response={accountsList4xxResponses['AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED']} textOnly>
        <table className={`${styles['acc-table']}`} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`headerGroup-${i}`} className={`${styles['acc-table__row acc-table__row--hd']}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className={`${styles['acc-table__row__cell']}`} scope='col' key={column.id}>
                    <button
                      type='button'
                      className={`${styles['acc-table__sort-btn']} focus-ring`}
                      onClick={() => onSortClick(column.id)}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      {ui.sortData.Column === column.id ? (
                        <ArrowDown className={`icon icon--active ${iconDirection}`} aria-hidden='true' focusable='false' role='img' />
                      ) : (
                        <ArrowDown className={'icon'} aria-hidden='true' focusable='false' role='img' />
                      )}
                    </button>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {allAccountsLinked ? (
              <tr>
                <td align='center' colSpan={10}>
                  <span>All Accounts on this page have been linked to this User</span>
                </td>
              </tr>
            ) : (
              <>
                {page.map((row, i) => {
                  const rowNum = i + 1;
                  // const activeRowStyle = activeRow === rowNum ? 'is--active' : '';
                  const activeRowStyle = activeRow === rowNum;
                  // const isAccountSeleceted = selectedAccounts?.find((item) => item.accountNumber === row.values.accountNumber) ? 'is--hidden' : '';
                  const isAccountSelected = selectedAccounts?.find((item) => item.accountNumber === row.values.accountNumber);
                  prepareRow(row);
                  return (
                    <tr
                      key={`row-${i}`}
                      role='link'
                      className={`${styles['acc-table__row']} ${styles['acc-table__row--bd']} ${activeRowStyle &&
                        styles['is--active']} ${isAccountSelected && styles['is--hidden']}`}
                      {...row.getRowProps()}
                      onMouseEnter={() => onMouseEnter(rowNum)}
                      onMouseLeave={onMouseLeave}
                      onClick={() =>
                        onLinkorUnlink({
                          accountNumber: row.values.accountNumber,
                          accountName: row.values.accountName.accountName,
                          businessName: row.values.accountName.businessName,
                        })
                      }
                    >
                      <EditAccountCard rowData={row.values} userToLink={userToLink} />
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th scope='row' colSpan={3}>
                <Pagination data={accountData} onNewPageClick={onNewPageClick} />
              </th>
            </tr>
          </tfoot>
        </table>
      </Error4xx>
    </div>
  );
}
