import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';

export default function checkDuplicates(masterFieldObject: IDynamicFormAttribute[], setErrorMessage) {
  const attributesWithDuplicates: any = [];

  masterFieldObject.forEach((item) => {
    const values: any = [];
    item.attributeValues.forEach((val) => {
      values.push(val.attributeValue);
    });

    const duplicates = values.filter((item, index) => values.indexOf(item) !== index);

    if (duplicates.length > 0) {
      attributesWithDuplicates.push(`${item.attributeName} -> ${duplicates}`);
    }
  });

  if (attributesWithDuplicates.length > 0) {
    setErrorMessage(attributesWithDuplicates.join(',  '));
  }
}
