import React, { useState } from 'react';
import UsersAccountCard from './UsersAccountCard';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import styles from './UsersAccountsList.module.scss';
import Error4xx from 'components/Error4xx/Error4xx';
import IStore from 'models/IStore';
import { useSelector } from 'react-redux';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import UsersAction from 'stores/users/UsersAction';
import IAccount from 'stores/accounts/models/IAccount';
import { KenticoUser } from 'stores/users/models/IUserV3';
interface IProps {
  linkedAccounts: IAccount[] | undefined;
  selectedUser: KenticoUser;
  fromAdmin: boolean;
}
const UsersAccountsList = ({ fromAdmin, linkedAccounts, selectedUser }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userAccountsList4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [UsersAction.REQUEST_USER_ACCOUNTS_FINISHED]
  );

  return (
    <div className={styles['usersAccountsList__container']}>
      <Error4xx response={userAccountsList4xxResponses['UsersAction.REQUEST_USER_ACCOUNTS_FINISHED']} textOnly>
        {!isLoading && linkedAccounts ? (
          linkedAccounts.map((accnt, i) => {
            return (
              <UsersAccountCard key={i} index={i} setIsLoading={setIsLoading} fromAdmin={fromAdmin} accountInfo={accnt} selectedUser={selectedUser} />
            );
          })
        ) : (
          <LoadingIndicator isActive={true} inverted={true} />
        )}
      </Error4xx>
    </div>
  );
};

export default UsersAccountsList;
