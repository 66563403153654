import styles from './SelectRegionPage.module.scss';
import React from 'react';
import { useCookies } from 'react-cookie';
import { REGION_COOKIE_NAME, REGIONS } from '../../constants/RegionsEnum';
import Footer from 'components/Footer/Footer';
import logo from 'assets/media/images/header_logo.svg';

interface IProps {
  message: string;
}
interface IState {}

function SelectRegionPage({ message }: IProps) {
  const [cookies, setCookie] = useCookies(['name']);

  if (cookies.Ping_Region_URL) {
    return (window.location.href = cookies.Ping_Region_URL);
  }

  const onClickSetRegion = (region) => {
    setCookie(REGION_COOKIE_NAME, region.url, { path: '/', sameSite: 'strict' });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.box__hd}>
          <img src={logo} alt='' style={{ height: '44px', width: '250px' }} className={styles['primaryNavHeader-logo_big']} />
        </div>
        <div className={styles.box__bd}>
          <h1 className={styles.heading}>Select your Region</h1>
          <ul className={styles['link-list']}>
            {REGIONS.map((region, index) => (
              <li key={index}>
                <a href={region.url} className='focus-ring' onClick={() => onClickSetRegion(region)}>
                  {region.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer isInverse={true} />
      </div>
    </div>
  );
}

export default SelectRegionPage;
