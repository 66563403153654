import React, { useState } from 'react';
import { ReactComponent as MagnifyingGlass } from 'assets/media/icons/icon-magnifying-glass.svg';
import styles from './UsersSearch.module.scss';

interface IProps {
  handleSubmit: any;
}

export default function UserSearch({ handleSubmit }: IProps) {
  const [input, setInput] = useState('');
  return (
    <form className={`${styles.search}`}>
      <input
        className={`${styles.search__input} focus-ring`}
        name='searchQuery'
        placeholder='Search...'
        type='search'
        required
        value={input}
        onChange={(e: any): void => setInput(e.target.value)}
      />
      <button className={`btn focus-ring ${styles.search__button}`} onClick={(e) => handleSubmit(e, input)} type='submit'>
        <MagnifyingGlass aria-hidden='true' focusable='false' role='img' />
      </button>
    </form>
  );
}
