import InvoicesRequestModel from 'stores/invoices/models/InvoicesRequestModel';
import OrdersRequestModel from 'stores/orders/models/OrdersRequestModel';
import PaginatedRequestModel from '../../pagination/IPaginatedRequestModel';
import { CombinedRequest } from './ISearchRequestConfig';

/*
    // Returned Api Data Sample
    {
      "data": IInvoice[],
      ...PaginatedResponseModel
    }
 */
export default class SearchRequestModel extends PaginatedRequestModel {
  accountNumber: number | undefined;
  criteria: string | undefined;
  searchValue: string | undefined;
  searchModel: InvoicesRequestModel | OrdersRequestModel | CombinedRequest | any;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: Partial<PaginatedRequestModel>): void {
    super.update(data);
  }
}
