import styles from './NotificationCard.module.scss';
import * as React from 'react';
import INotificationPreference from '../../../../stores/accounts/models/INotificationPreference';
import Value from '../../../../components/Value/Value';
import FeatureFlagComponent from 'components/FeatureFlag/FeatureFlag';
interface IProps {
  notificationPreference: INotificationPreference;
}

function NotificationsCard(props: IProps): JSX.Element {
  const { notificationPreference } = props;

  return (
    <div className={styles['notification-card']}>
      <div className={styles.section}>
        <div className={styles.section__label}>Send to</div>
        <Value textTruncate={false} value={notificationPreference.recipient} propertyName='recipient' />
      </div>
      <div className={styles.section}>
        <div className={styles.section__label}>Send Invoice Reminders</div>
        <Value value={notificationPreference.sendInvoiceReminders} propertyName='sendInvoiceReminders' />
      </div>
      <div className={styles.section}>
        <div className={styles.section__label}>Send Statement Reminders</div>
        <Value value={notificationPreference.sendStatementReminders} propertyName='sendStatementReminders' />
      </div>
      <FeatureFlagComponent name={'credit_debit_notifications'}>
        <div className={styles.section}>
          <div className={styles.section__label}>Send Credit Reminders</div>
          <Value value={notificationPreference.sendCreditReminders} propertyName='sendCreditReminders' />
        </div>
        <div className={styles.section}>
          <div className={styles.section__label}>Send Debit Reminders</div>
          <Value value={notificationPreference.sendDebitReminders} propertyName='sendDebitReminders' />
        </div>
      </FeatureFlagComponent>
      {/* <div className={styles.section}>
        <a href='/'>Edit</a>
      </div> */}
    </div>
  );
}

export default NotificationsCard;
