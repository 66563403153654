import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import GetCartRequestModel from './models/GetCartRequestModel';
import GetCartResponseModel from './models/GetCartResponseModel';
import HttpUtility from 'utilities/HttpUtility';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';

export default class CreateOrderEffect {
  public static async getCart(request: GetCartRequestModel): Promise<GetCartResponseModel | HttpErrorResponseModel> {
    // TODO: change to correct endpoint once api is built out
    const { accountNumber } = request;
    const endpoint: string = `${(environment.api as any).cart}/accountNumber/${accountNumber}`;

    return EffectUtility.getToModel<GetCartResponseModel>(GetCartResponseModel, endpoint);
  }

  public static async getEstimatedDateByItemId(itemId: number): Promise<any | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).cartItem3}/${itemId}/ship-date?wantDate=${moment().format(DateFormatsEnum.HyphensForCalls)}`;

    return HttpUtility.get(endpoint);
  }
}
