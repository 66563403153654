import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import React from 'react';

/**
 * Takes the active account and returns a message or a loading indicator.
 *
 * Common Uses:
 * Should be returned in an if that decideds when you want the loading indicator to appear.
 * Or returned in a account check if.
 * @param account the active account
 * @returns
 */
export const pageLoader = (account) => {
  if (!account) {
    return (
      <div className='wrapper'>
        <h1 className='hdg hdg--1'>Please Select an Account</h1>
      </div>
    );
  }

  return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
};
