import CreditSummaryResponseModel from './models/CreditSummaryResponseModel';
import CreditSummaryEffect from './CreditSummaryEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';

type ActionUnion = void | HttpErrorResponseModel | CreditSummaryResponseModel;

export default class CreditSummaryAction {
  public static readonly REQUEST_CREDIT_SUMMARY: string = 'CreditSummaryAction.REQUEST_CREDIT_SUMMARY';
  public static readonly REQUEST_CREDIT_SUMMARY_FINISHED: string = 'CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED';

  public static requestCreditSummary(accountNumber: number): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: CreditSummaryAction.REQUEST_CREDIT_SUMMARY });

      const model: CreditSummaryResponseModel | HttpErrorResponseModel = await CreditSummaryEffect.requestCreditSummary(accountNumber);

      dispatch({
        type: CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED,
        payload: model,
        error: model instanceof HttpErrorResponseModel,
      });
    };
  }
}
