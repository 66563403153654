import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';
import React from 'react';
import PagesStatelessCalls from 'stores/pages/PagesStatelessCalls';

function ContactPage(): JSX.Element {
  const onDownloadRepair = () => {
    const pageUrl = '/mediastorage/pingbiz-na/media/sales/ping_club_repair_form.pdf';
    PagesStatelessCalls.downloadFileForPage(pageUrl);
  };
  return (
    <div className='wrapper'>
      <h1 className='hdg hdg--1'>Customer Support</h1>
      <div className='tiles tiles--split tiles--vr2'>
        <div className='tiles__tile tiles__tile--has-card'>
          <div className='tiles__tile__card'>
            <h2 className='hdg hdg--3'>PING United States</h2>
            <div className='user-content'>
              <p>
                <strong>Ping Main Headquarters</strong>
                <br />
                P.O. BOX 82000
                <br />
                Phoenix, Arizona 85071-2000
              </p>
              <PingCustomerServiceContact />
              <p>Monday-Friday 7:00am – 4:00pm MST</p>
            </div>
          </div>
        </div>
        <div className='tiles__tile tiles__tile--has-card'>
          <div className='tiles__tile__card'>
            <h2 className='hdg hdg--3'>PING Repair Department</h2>
            <div className='user-content'>
              <p>
                <strong>Ping Repairs</strong>
                <br />
                2201 W. Desert Cove, Suite 100
                <br />
                Phoenix, Arizona 85029
              </p>
              <p>1-800-474-6434</p>
              <p>Monday-Friday 7:00am – 3:30pm MST</p>
              <p>
                <button className='btn--link' onClick={onDownloadRepair}>
                  Ping Repair Form
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactPage;
