import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import CreateOrderStatelessCalls, { IGetFieldsUpdate } from 'stores/create-order/CreateOrderStatelessCalls';
import { IDynamicFormAttribute, IDynamicFormAttributeDependecyResponse } from 'stores/create-order/models/IDynamicFormModels';
import generateSubmittedList from './generateSubmittedList';
import generateIndVariableValueCheck from './generateIndVariableValueCheck';
import { SUBMITTED_LIST_INSTANCE } from 'containers/CreateOrderPage/CreateOrderPage';
import { shouldMakeDependencyCall } from './shouldMakeDependencyCall';
import getSelectedValue from './getSelectedValue';

/**
 * Updates the masterField object based on dependency calls.
 *
 * Route 1: user has not changed a value.
 *
 * @param param0 Object
 */
export default async function getUpdatedMasterFieldObject({
  masterFieldObjectSet,
  product,
  accountNumber,
  setIsLoading,
  submittedList,
  setSubmittedList,
  setErrorMessage,
}) {
  let newSubmittedList = submittedList;
  SUBMITTED_LIST_INSTANCE.setSubmittedList(submittedList);

  /**
   * This will return the response for all possible dependency fields in this batch
   */
  return Promise.all(
    masterFieldObjectSet.map((item: IDynamicFormAttribute) => {
      /**
       * Looks at indVar1 and 2 and grabs the values for the fields. Then does a few checks to see if inVar1 and 2 are needed
       * or if only inVar1 is needed.
       */
      const attributeIndVariableValues = generateIndVariableValueCheck(item, masterFieldObjectSet);

      /**
       * Needs to have all required independentVariables
       * (some require 1, some require 2 only call when all required are set).
       *
       * Needs their values.
       *
       * Makes call with all values needed.
       *
       * Cannot have already been submitted for dependencies
       * should also be checked before trying this function.
       */
      if (shouldMakeDependencyCall(attributeIndVariableValues, SUBMITTED_LIST_INSTANCE, item)) {
        /**
         * Request config based on the above logic
         * TODO: if kentico dirties the data back from non then we can take out that logic
         */
        const requestConfig: IGetFieldsUpdate = {
          product,
          dependentVariable: item.attributeName,
          independentVariable1: attributeIndVariableValues.independentVariable1,
          independentVariable1Value: attributeIndVariableValues.independentVariableValue1,
          independentVariable2: attributeIndVariableValues.independentVariable2,
          independentVariable2Value: attributeIndVariableValues.independentVariableValue2,
          accountNumber,
          setIsLoading,
        };

        /**
         * Adds item that is getting dependency call to the functions local list and to state.
         * And sets loval list to global class to be used on future loops.
         * This list is cleared when we unmount the component.
         */
        newSubmittedList = generateSubmittedList(newSubmittedList, item.attributeName, setSubmittedList);
        SUBMITTED_LIST_INSTANCE.setSubmittedList(newSubmittedList);

        if (item.attributeName === 'CLUBS') {
          return item;
        }

        /**
         * Generates the new master field object
         */
        return CreateOrderStatelessCalls.getFieldsUpdate(requestConfig)
          .then((res) => {
            try {
              const dependentVariable = item.attributeName;
              const data: IDynamicFormAttributeDependecyResponse[] = res.data.data;
              const attributeValuesFilter = data.map((item) => item.dependentVariableValue);

              if (item.attributeName === dependentVariable) {
                const newAttributeValues = item.attributeValues.filter((value) => {
                  return attributeValuesFilter.includes(value.attributeValue);
                });

                return {
                  ...item,
                  attributeValues: newAttributeValues,
                  selectedValue: getSelectedValue(item, data),
                };
              }

              return item;
            } catch {
              /**
               * Set error message to user message when we can
               */
              const response: HttpErrorResponseModel | any = res;
              setErrorMessage(response);
            }
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      } else {
        return item;
      }
    })
  );
}
