import getRequiredStrings from 'components/Form/FormHelpers/getRequiredStrings';
import { IFormErrors } from 'components/Form/IFormErrors';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import UsersAction from 'stores/users/UsersAction';
import LinkUnlinkUserRequestModel from 'utilities/UserUtility/models/LinkUnlinkUserRequestModel';
import UpdateUserRequestModel from 'utilities/UserUtility/models/UpdateUserRequestModel';
import UserUtility from 'utilities/UserUtility/UserUtility';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { MSAL_AUTH } from 'index';

export const handleValidation = (formState: any, setFormErrors: any): IFormErrors => {
  const requiredStrings = ['first_name', 'last_name'];
  const formErrors: IFormErrors = {
    hasError: false,
    errors: [],
  };
  getRequiredStrings(formState, requiredStrings, formErrors);

  if (!formState.user_membership) {
    formErrors.errors.push({ errorFieldName: 'user_membership', errorMessage: 'Please select a User Role' });
    formErrors.hasError = true;
  }

  if (!/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(formState.email)) {
    formErrors.errors.push({ errorFieldName: 'email', errorMessage: 'Please enter a valid Email' });
    formErrors.hasError = true;
  }

  if (formErrors.hasError) {
    setFormErrors(formErrors);
    return formErrors;
  }
  return formErrors;
};

export const handleSubmit = async ({
  e,
  formState,
  setFormErrors,
  userIdInt,
  setUpdateLoading,
  rolesFilter,
  history,
  currentAndPreviousPaths,
  fromAdmin,
  accountNumber,
  dispatch,
  setCallError,
  isCurrentUser,
}) => {
  e.preventDefault();
  const errors = handleValidation(formState, setFormErrors);
  if (errors.hasError) {
    return;
  }

  const requestConfig = new UpdateUserRequestModel({
    firstName: formState.first_name,
    lastName: formState.last_name,
    email: formState.email,
    userId: userIdInt,
    userMembership: formState.user_membership,
  });

  setUpdateLoading(true);
  await UserUtility.updateUser(requestConfig).then((res) => {
    if (res instanceof HttpErrorResponseModel) {
      setCallError(res);
      setUpdateLoading(false);
      return;
    }
    if (isCurrentUser) {
      if (MSAL_AUTH.token) {
        const config = new UsersRequestModel({
          accountNumber,
          membership: rolesFilter ? rolesFilter.manageUserFilter : '',
        });
        dispatch(UsersAction.requestAccountUsers(config)).then(() => {
          MSAL_AUTH.token &&
            dispatch(UsersAction.setCurrentUser(MSAL_AUTH.token)).then(() => {
              dispatch(ToastsAction.add('Updated Self', ToastStatusEnum.Success));
              history.push('/account-profile');
            });
        });
      }
    } else if (fromAdmin) {
      const config = new UsersRequestModel({ membership: rolesFilter ? rolesFilter.adminPanelFilter : '' });
      dispatch(UsersAction.requestUsers(config)).then(() => {
        dispatch(ToastsAction.add('Updated User', ToastStatusEnum.Success));
        history.push(currentAndPreviousPaths.previous);
      });
    } else {
      const config = new UsersRequestModel({
        accountNumber,
        membership: rolesFilter ? rolesFilter.manageUserFilter : '',
      });
      dispatch(UsersAction.requestAccountUsers(config)).then(() => {
        dispatch(ToastsAction.add('Updated User', ToastStatusEnum.Success));
        history.push(currentAndPreviousPaths.previous);
      });
    }
  });
};

export const handleDeleteUser = ({
  editRef,
  linkedAccounts,
  setDeleteLoading,
  dispatch,
  userId,
  userIdInt,
  fromAdmin,
  rolesFilter,
  history,
  currentAndPreviousPaths,
  accountNumber,
  setCallError,
}) => {
  if (editRef.current.checkValidity()) {
    if (userId) {
      if (userId && userIdInt && fromAdmin) {
        setDeleteLoading(true);
        UserUtility.deleteUser(userIdInt).then((res) => {
          if (res instanceof HttpErrorResponseModel) {
            setCallError(res);
            return;
          }
          if (res.data) {
            const config = new UsersRequestModel({ membership: rolesFilter ? rolesFilter.adminPanelFilter : '' });
            dispatch(UsersAction.requestUsers(config)).then(() => {
              dispatch(ToastsAction.add('Deleted User', ToastStatusEnum.Success));
              history.push(currentAndPreviousPaths.previous);
            });
          }
        });
      } else if (userIdInt) {
        setDeleteLoading(true);
        const config = new LinkUnlinkUserRequestModel({ userId: userIdInt, accountNumbers: [accountNumber] });
        UserUtility.unlinkUser(config).then((res) => {
          if (res instanceof HttpErrorResponseModel) {
            setCallError(res);
            return;
          }
          if (res.data) {
            const config = new UsersRequestModel({
              accountNumber,
              membership: rolesFilter ? rolesFilter.manageUserFilter : '',
            });
            dispatch(UsersAction.requestAccountUsers(config)).then(() => {
              dispatch(ToastsAction.add('Deleted User', ToastStatusEnum.Success));
              history.push(currentAndPreviousPaths.previous);
            });
          }
        });
      }
    }
  }
};
