import React, { useEffect, useState, ChangeEvent } from 'react';
import IStore from '../../../../models/IStore';
import RouteEnum from 'constants/RouteEnum';
import RouteNameEnum from 'constants/RouteNameEnum';
import { oc } from 'ts-optchain.macro';
import UserRolesEnum from 'constants/UserRolesEnum';
import AccessControl from 'components/AccessControl/AccessControl';
import ArrowLink from 'components/ArrowLink/ArrowLink';
import { useDispatch, useSelector } from 'react-redux';
import DynamicForm from '../dynamicForm/DynamicForm';
import uuid from 'uuid/v4';
import { generateClubsField, generateFieldState, getClubs } from './helpers/ProductConfigHelper';
import { IDynamicFormAttribute, IPriceTotalState } from 'stores/create-order/models/IDynamicFormModels';
import { IGetClubs } from 'stores/create-order/CreateOrderStatelessCalls';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import HttpUtility from 'utilities/HttpUtility';
// import AccountInfoResponseModel from 'stores/accounts/models/AccountInfoResponseModel';
import { Dispatch } from 'redux';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import { MAIN_CONFIG } from 'configurations/mainConfig';
import EstimatedDateController from './helpers/EstimatedDateController';
import { Link } from 'react-router-dom';
import initialLoopEffect from './helpers/initialLoopEffect';
import productConfigFormSubmit from './helpers/productConfigFormSubmit';
import productConfigResetForm from './helpers/productConfigResetForm';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

interface IProps {
  accountNumber: number | undefined;
  originalHistory: any;
}

export interface INonMasterFormFieldState {
  QTY: number;
  TAGNAME: string;
}

class ProductConfigLocalStore {
  public shouldClearClubs: boolean = false;
  public shouldMakeDepCall: boolean = false;
  public isEditing: boolean = false;
}

/**
 * Export instances of classes
 */
export const PRODUCT_CONFIG_LOCAL_STORE = new ProductConfigLocalStore();
export const ESTIMATED_DATE = new EstimatedDateController();

const ProductConfig = ({ accountNumber }: IProps) => {
  const dispatch: Dispatch = useDispatch();
  const currentAndPreviousPaths = useSelector((state: IStore) => oc(state).navigation.currentAndPreviousPaths({ current: '', previous: '' }));
  // eslint-disable-next-line new-cap
  const cameFromReview = currentAndPreviousPaths.previous === `${RouteEnum.CreateOrder}/review`;
  const backLinkURL = cameFromReview ? `${RouteEnum.CreateOrder}/review` : RouteEnum.CreateOrders;
  const backLinkText = `Back to ${cameFromReview ? RouteNameEnum.CreateOrderReview : RouteNameEnum.CreateOrderCategories}`;
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const productConfigs = useSelector((state: IStore) => state.createOrder.productConfigs);
  const storedSubmittedLists = useSelector((state: IStore) => state.createOrder.submittedLists);
  const originalProductConfigs = useSelector((state: IStore) => state.createOrder.originalProductConfigs);

  // The stored master field object this gets set at the end of a loop.
  const [masterFieldObject, setMasterFieldObject] = useState<IDynamicFormAttribute[]>([]);

  /**
   * The stored field state. The visible ui containing only visible fields with attribues used only to display the form on the FE
   * This is generated at the end of a loop from the MasterFieldObject
   **/
  const [fieldState, setFieldState] = useState<any>(null);

  // Step that the user is currently on. This is used in deciding which field should be visible.
  const [step, setStep] = useState<string>(MAIN_CONFIG.CreateOrder.StartingStep);

  // Loading triggered when a loop starts
  const [isLoading, setIsLoading] = useState(false);

  // The list of fields that have been submitted for dependency calls, to avoid making duplicate calls.
  const [submittedList, setSubmittedList] = useState<Array<string>>([]);

  // A check for if the product is valid. If it is invalid the user will be sent back to product select
  const [notValidProduct, setNotValidProduct] = useState(false);

  // Price total in the dynamicFormPanel
  const [priceTotal, setPriceTotal] = useState<IPriceTotalState>({
    priceTotal: 0,
    isLoading: false,
    currency: '',
  });
  const currency = priceTotal.currency;
  // Error message can be a string or a HttpErrorResponseModel
  const [errorMessage, setErrorMessage] = useState<string | object>('');

  // Fields that are independent of the MasterFieldObject and that are not part of the dynamic proccess
  const [nonMasterFieldFormState, setNonMasterFieldFormState] = useState<INonMasterFormFieldState>({
    QTY: 1,
    TAGNAME: '',
  });

  // Retrievs the product from tabState
  const product = tabState.product;

  // If invalid then cancel calls
  if (notValidProduct) {
    setNotValidProduct(false);
    const token = uuid();
    HttpUtility.cancelCalls(token);
  }

  /**
   * Resets the form clearing all classes and state
   *
   * @param e event
   * @returns null
   */
  const resetForm = (e: ChangeEvent<HTMLInputElement>) =>
    productConfigResetForm({
      e,
      accountNumber,
      product,
      setFieldState,
      setMasterFieldObject,
      setStep,
      setIsLoading,
      submittedList,
      setSubmittedList,
      setNotValidProduct,
      setErrorMessage,
      setPriceTotal,
      setNonMasterFieldFormState,
      dispatch,
    });

  const handleFormSubmit = (event: any) =>
    productConfigFormSubmit({
      event,
      accountNumber,
      masterFieldObject,
      setErrorMessage,
      dispatch,
      setIsLoading,
      product,
      tabState,
      nonMasterFieldFormState,
      currency,
    });

  console.groupCollapsed('%c State for%c masterFieldObject and fieldState', 'color: teal');
  console.log('MFO', masterFieldObject);
  console.log('fieldState', fieldState);
  console.log('originalMFO', originalProductConfigs[product.replace(' ', '_')]);
  console.log('product', product);
  console.log('outside step', step);
  console.log('submittedList', submittedList);
  console.groupEnd();

  useEffect(() => {
    // If clubs has no values and we can make a clubs call do so. triggerd on fieldState change
    const clubsField = masterFieldObject.find((item) => item.attributeType === 'CLUB');
    const modelGroupB = masterFieldObject.find((item) => item.attributeName === 'MODELGROUPB');
    const clubsHasNoValues = clubsField?.attributeValues.length === 0;

    if (clubsHasNoValues && modelGroupB?.selectedValue) {
      const requestConfig: IGetClubs = {
        accountNumber,
        cfgModel: modelGroupB?.configurationModelName,
        indVarValue: modelGroupB?.selectedValue,
      };

      /**
       * Makes call to get possible club options using the MODELGROUPB Field
       * And then assigns those options to the clubs field
       */
      getClubs(requestConfig).then((clubOptions) => {
        if (clubOptions instanceof HttpErrorResponseModel) {
          setErrorMessage(clubOptions);
          return;
        }

        generateClubsField({
          masterFieldObject,
          clubs: clubOptions,
          setMasterFieldObject,
          setFieldState,
          step,
          setStep,
        });
      });
    }

    // eslint-disable-next-line
  }, [fieldState]);

  useEffect(() => {
    /**
     * Logic for loading initial fields
     * Depending on if we are editing retrieving from store or loading for the first time
     */
    initialLoopEffect({
      tabState,
      accountNumber,
      product,
      setFieldState,
      setMasterFieldObject,
      step: MAIN_CONFIG.CreateOrder.StartingStep,
      setStep,
      setIsLoading,
      submittedList,
      setSubmittedList,
      setNotValidProduct,
      setNonMasterFieldFormState,
      setErrorMessage,
      setPriceTotal,
      dispatch,
      productConfigs,
      generateFieldState,
      originalProductConfigs,
      storedSubmittedLists,
    });

    /**
     * Cleans up global classes on component unmount;
     */
    return () => {
      ESTIMATED_DATE.resetProperties();
      PRODUCT_CONFIG_LOCAL_STORE.shouldMakeDepCall = false;
      PRODUCT_CONFIG_LOCAL_STORE.isEditing = false;
    };
    // eslint-disable-next-line
  }, [product]);

  /*
  When Editing a Item from Review we pass the Product and the ItemId
  we need to use the itemId to load the Dynamic Form values and not use the Api to fetch the Values.
  */

  // TODO: Phase2 allow edit with frontEndItemID
  // if (productId) {
  //   //pass config data to dynamic form
  // }
  // TODO: enable before launch
  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ManageOrders} noAccessRedirect={true}>
      <>
        <ArrowLink position='back' classNames='vr'>
          <Link to={backLinkURL} onClick={() => TabStateHelpers.goToInitial(dispatch, tabState)}>
            {backLinkText}
          </Link>
        </ArrowLink>
        {originalProductConfigs[product.replace(' ', '_')] && (
          <DynamicForm
            product={product}
            setFieldState={setFieldState}
            fieldState={fieldState}
            setMasterFieldObject={setMasterFieldObject}
            masterFieldObject={masterFieldObject}
            fields={fieldState}
            priceTotal={priceTotal}
            setPriceTotal={setPriceTotal}
            handleFormSubmit={handleFormSubmit}
            accountNumber={accountNumber}
            step={step}
            nonMasterFieldFormState={nonMasterFieldFormState}
            setNonMasterFieldFormState={setNonMasterFieldFormState}
            setStep={setStep}
            resetForm={resetForm}
            setSubmittedList={setSubmittedList}
            submittedList={submittedList}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            originalMasterFieldObject={originalProductConfigs[product.replace(' ', '_')]}
          />
        )}
      </>
    </AccessControl>
  );
};

export default ProductConfig;
