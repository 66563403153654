import PaginatedRequestModel from '../../pagination/IPaginatedRequestModel';

/*
    // Returned Api Data Sample
    {
      "data": IInvoice[],
      ...PaginatedResponseModel
    }
 */
export default class InvoiceRequestModel extends PaginatedRequestModel {
  transactionType: string = '';

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: Partial<PaginatedRequestModel>): void {
    super.update(data);
  }
}
