import React from 'react';
import environment from 'environment';
import Autocomplete from 'react-google-autocomplete';
import { IAddressState } from 'utilities/Location/models/ILocationAddress';
import styles from './GoogleMapsAutocomplete.module.scss';

interface IProps {
  setGoogleMapAddress: Function;
  addressState: IAddressState;
  handleFieldChange: Function;
}

const GoogleMapsAutocomplete = ({ setGoogleMapAddress, addressState, handleFieldChange }: IProps) => {
  // Sort out the floating dropdown of the autocomplete
  const dropdown: HTMLElement | null = document.querySelector('.pac-container');
  const autocompleteContainer: HTMLElement | null = document.getElementById('autocompleteContainer');

  if (dropdown && autocompleteContainer) {
    dropdown.classList.add(styles.autocompleteDropdown);
    autocompleteContainer.appendChild(dropdown);
  }

  return (
    <>
      <div className='vr1_5'>
        <h4 className='hdg hdg--4 form_title'>Location Address</h4>
        <div id='autocompleteContainer' className={'form__input-container'}>
          <label className='form__label' id='labelStreet'>
            Address
          </label>
          <Autocomplete
            id='street'
            defaultValue={addressState['street']}
            placeholder={'Enter address'}
            className={'form-input'}
            apiKey={environment.googleMapsAPI}
            options={{ types: ['address'] }}
            onChange={handleFieldChange('street')}
            onPlaceSelected={(place, inputRef) => setGoogleMapAddress(place, inputRef)}
          />
        </div>
      </div>
    </>
  );
};

export default GoogleMapsAutocomplete;
