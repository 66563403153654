import { BaseModel } from 'sjs-base-model';
import IAddress from 'utilities/Location/models/Address/IAddress';

export default class AddressResponseModel extends BaseModel {
  public readonly data: IAddress;
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<AddressResponseModel>): void {
    super.update(data);
  }
}
