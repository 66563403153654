import { BaseModel } from 'sjs-base-model';
import { MAIN_CONFIG } from 'configurations/mainConfig';

/*
    // Returned Api Data Sample
    {
      currentPage: 1,
      currentPageSize: 10,
      data: [],
      message: '',
      status: '',
      statusCode: 200,
      totalCount: 0,
      totalPages: 1,
    }
 */
export default class PaginatedResponseModel<T> extends BaseModel {
  public readonly currentPage: number = 1;
  public readonly currentPageSize: number = MAIN_CONFIG.BasePaginatedModel.pageSize;
  public readonly data: T[] = [];
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;
  public readonly totalCount: number = 0;
  public readonly totalPages: number = 1;
  public readonly userMessage: string = '';
  public readonly eventCode: string = '';

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<PaginatedResponseModel<T>>) {
    super();

    this.update(data);
  }

  public update(data: Partial<PaginatedResponseModel<T>>): void {
    super.update(data);
  }
}
