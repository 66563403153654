import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';

/**
 * Checks if given item can make a dependency call
 *
 * @param item Object
 * @param masterFieldObjectSet Array
 * @returns Object
 */
export default function generateIndVariableValueCheck(item, masterFieldObjectSet) {
  /**
   * checks and generates variables needed to check if dependency call should be made
   */
  const varibleValues = {
    dependentVariable: '',
    needsIndependentVariable1: false,
    independentVariable1: '',
    independentVariableValue1: '',
    independentVariable2: '',
    needsIndependentVariable2: false,
    independentVariableValue2: '',
  };

  /**
   * Logic to see if Item needs 1 independent variable or 2 and if they are set
   */
  if (item.independentVariable1 !== '') {
    varibleValues.dependentVariable = item.attributeName;
    varibleValues.needsIndependentVariable1 = true;
    // find if that variable has been set
    const inVar1Obj: IDynamicFormAttribute | undefined = masterFieldObjectSet.find((attr: IDynamicFormAttribute) => {
      return attr.attributeName === item.independentVariable1;
    });

    if (inVar1Obj?.selectedValue) {
      varibleValues.independentVariable1 = item.independentVariable1;
      varibleValues.independentVariableValue1 = inVar1Obj.selectedValue;
    }
  }

  if (item.independentVariable2 !== '') {
    varibleValues.dependentVariable = item.attributeName;
    varibleValues.needsIndependentVariable2 = true;
    // find if that variable has been set
    const inVar2Obj: IDynamicFormAttribute | undefined = masterFieldObjectSet.find((attr: IDynamicFormAttribute) => {
      return attr.attributeName === item.independentVariable2;
    });

    if (inVar2Obj?.selectedValue) {
      varibleValues.independentVariable2 = item.independentVariable2;
      varibleValues.independentVariableValue2 = inVar2Obj.selectedValue;
    }
  }

  return varibleValues;
}
