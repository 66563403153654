import React from 'react';
import styles from '../../OrderItems/OrderItems.module.scss';
import { Item } from 'stores/orders/models/IOrder';
import detailStyle from '../../OrderDetailTable/OrderDetailTable.module.scss';

interface IProps {
  currentOrderItem: Item | undefined;
}

const TrackingItemDescription = ({ currentOrderItem }: IProps) => {
  return (
    <div className={styles.trackingItemInfo}>
      <span className={detailStyle['order-detail-table__description-hd']}>{currentOrderItem?.product}</span> <br />
      {currentOrderItem?.itemDescription} <br />
      <span className={detailStyle['order-detail-table__bold']}>Tags: {currentOrderItem?.tagName}</span>
    </div>
  );
};

export default TrackingItemDescription;
