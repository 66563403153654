import uuid from 'uuid/v4';
import IError from './IError';
import INotificationType from './INotificationType';

export default class HttpErrorResponseModel implements IError {
  public readonly id: string = uuid();
  public data: any;
  public statusCode: number = 0;
  public message: string = '';
  public errors: string[] = [];
  public eventCode?: string;
  public userMessage?: string;
  public url: string = '';
  public raw: any = null;
  public errorNotificationType: INotificationType | null = null;
}
