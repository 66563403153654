import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { oc } from 'ts-optchain.macro';
import CreditSummary from './components/CreditSummary/CreditSummary';
import IStore from '../../models/IStore';
import ICreditSummary from '../../stores/credit-summary/models/ICreditSummary';
import CreditSummaryAction from '../../stores/credit-summary/CreditSummaryAction';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import RouteEnum from '../../constants/RouteEnum';
import InvoicesResponseModel from '../../stores/invoices/models/InvoicesResponseModel';
import InvoiceCards from './components/InvoiceCards/InvoiceCards';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import InvoicesRequestModel from '../../stores/invoices/models/InvoicesRequestModel';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import IAccount from 'stores/accounts/models/IAccount';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import InvoicesAction from '../../stores/invoices/InvoicesAction';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import { isEqual } from 'lodash';
import { ReactComponent as Refresh } from '../../assets/media/icons/icon-restart.svg';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import { DATE_HELPER } from 'index';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import { pageLoader } from 'components/PageLoader/PageLoader';
import SearchEnum from 'constants/SearchEnum';

class PreviousInvoicesConfig {
  public previousConfig;

  public setPreviousInvoicesConfig(config) {
    this.previousConfig = config;
  }
}

export const PREVIOUS_INVOICES_CONFIG = new PreviousInvoicesConfig();

function TransactionsPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const store = useSelector((state: IStore) => state);
  const invoices4xxResponses = selectRawErrors(store, [
    InvoicesAction.REQUEST_INVOICES_FINISHED,
    InvoicesAction.REQUEST_TRANSACTIONS_FINISHED,
    CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED,
  ]);

  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const creditSummary: ICreditSummary | undefined = useSelector((state: IStore) => oc(state).creditSummary.creditSummaryResponse.data(undefined));
  const invoicesResponse: InvoicesResponseModel | null = useSelector((state: IStore) => oc(state).invoices.invoicesResponse(null));
  const transactionsResponse: InvoicesResponseModel | null = useSelector((state: IStore) => oc(state).invoices.transactionHistoryResponse(null));
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const transactionHistoryFilters: InvoicesRequestModel | null = useSelector((state: IStore) => oc(state).invoices.transactionHistoryFilters(null));
  // may need a separate invoices filter for the top section to possibly filter as past due/current. currently those filters don't even work
  const [transactionHistoryLoading, setTransactionHistoryLoading] = useState(false);

  const resetTransactionHistory = () => dispatch(InvoicesAction.resetTransactionHistory(null));
  const resetInvoices = () => dispatch(InvoicesAction.resetInvoices(null));

  const [invoicesRequestConfig, setInvoicesRequestConfig] = useState(
    new InvoicesRequestModel({
      pageSize: 12,
      transactionType: 'INV',
      startDate: DATE_HELPER.oneTwentyDaysToDate,
      endDate: DATE_HELPER.todayToDate,
      transactionStatus: 'OPEN',
      sortData: {
        Column: 'TransactionDueDate',
        SortDirection: SortDirectionEnum.ASC,
      },
      pastDue: '',
    })
  );

  const [transactionHistoryConfig, setTransactionHistoryConfig] = useState(
    transactionHistoryFilters
      ? transactionHistoryFilters
      : new InvoicesRequestModel({
          pageSize: 15,
          startDate: DATE_HELPER.oneTwentyDaysToDate,
          endDate: DATE_HELPER.todayToDate,
        })
  );

  useEffect(() => {
    const newInvoicesRequestConfig = {
      ...invoicesRequestConfig,
      sjsId: 0,
      sjsOptions: 0,
      startDate: moment(invoicesRequestConfig?.startDate).format(DateFormatsEnum.MonthDayYear),
      endDate: moment(invoicesRequestConfig?.endDate).format(DateFormatsEnum.MonthDayYear),
    };

    if (accountNumber && !isEqual(PREVIOUS_INVOICES_CONFIG.previousConfig, newInvoicesRequestConfig)) {
      // if (accountNumber) {
      dispatch(InvoicesAction.requestInvoices(invoicesRequestConfig, accountNumber, false, setInvoicesLoading));
      PREVIOUS_INVOICES_CONFIG.setPreviousInvoicesConfig(newInvoicesRequestConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesRequestConfig]);

  // Transaction History
  useEffect(() => {
    // This prevents making an inital call when the filter is the same.
    const transactionHistoryRequestConfigCompareObj = {
      ...transactionHistoryConfig,
      sjsId: 0,
      sjsOptions: 0,
      startDate: moment(transactionHistoryConfig.startDate).format(DateFormatsEnum.MonthDayYear),
      endDate: moment(transactionHistoryConfig.endDate).format(DateFormatsEnum.MonthDayYear),
    };

    const transactionHistoryFiltersCompareObj = {
      ...transactionHistoryFilters,
      sjsId: 0,
      sjsOptions: 0,
      startDate: moment(transactionHistoryFilters?.startDate).format(DateFormatsEnum.MonthDayYear),
      endDate: moment(transactionHistoryFilters?.endDate).format(DateFormatsEnum.MonthDayYear),
    };

    if (accountNumber && !isEqual(transactionHistoryRequestConfigCompareObj, transactionHistoryFiltersCompareObj)) {
      setTransactionHistoryLoading(true);
      dispatch(InvoicesAction.requestTransactionHistory(transactionHistoryConfig, accountNumber, setTransactionHistoryLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, transactionHistoryConfig, transactionHistoryLoading, invoicesLoading]);

  // Invoices

  // credit summary
  useEffect(() => {
    if (accountNumber && !creditSummary) {
      dispatch(CreditSummaryAction.requestCreditSummary(accountNumber));
    }
  }, [dispatch, accountNumber, creditSummary]);

  const onInvoicesTabClick = (tab) => {
    const tabSwitch = {
      'past due': () => true,
      all: () => '',
      current: () => false,
    };
    setInvoicesRequestConfig(new InvoicesRequestModel({ ...invoicesRequestConfig, ...{ pastDue: tabSwitch[tab]() } }));
  };

  const onInvoicesPaginationClick = (pageNumber: number) => {
    resetInvoices();
    setInvoicesRequestConfig(new InvoicesRequestModel({ ...invoicesRequestConfig, ...{ page: pageNumber } }));
  };

  const onTransactionsPaginationClick = (pageNumber: number) => {
    resetTransactionHistory();
    setTransactionHistoryConfig(new InvoicesRequestModel({ ...transactionHistoryConfig, ...{ page: pageNumber } }));
  };

  const refreshResults = () => {
    setTransactionHistoryLoading(true);
    if (accountNumber) {
      dispatch(InvoicesAction.requestTransactionHistory(transactionHistoryConfig, accountNumber, setTransactionHistoryLoading));
      dispatch(InvoicesAction.requestInvoices(invoicesRequestConfig, accountNumber, false, setInvoicesLoading));
    }
  };

  if (accountNumber) {
    return (
      <AccessControl
        allowedRole={UserRolesEnum.ViewTransactions}
        errorResponse={invoices4xxResponses[InvoicesAction.REQUEST_TRANSACTIONS_FINISHED]}
        defaultErrorResponse={true}
      >
        <div className='wrapper'>
          <div className='split'>
            <div className={'refresh-title__container'}>
              <h1 className='hdg hdg--1'>Transactions</h1>
              <div className={'refresh-title__container-buttonPadding'}>
                {!transactionHistoryLoading && (
                  <button
                    className={'icon--refresh '} // ${transactionHistoryLoading && 'btn--disabled'}
                    onClick={refreshResults}
                    disabled={transactionHistoryLoading}
                  >
                    <Refresh />
                    <span>Refresh Transactions</span>
                  </button>
                )}
                {transactionHistoryLoading && <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 0 }} />}
              </div>
            </div>

            <GlobalSearch classNames='search--is-narrow' initialTarget={SearchEnum.Transactions} referringPage={RouteEnum.Transactions} />
          </div>
          <div className='vr4' />
          <h3 className='hdg hdg--3'>Credit Summary</h3>
          <div className='vr2_5' />
          <Error4xx response={invoices4xxResponses[CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED]}>
            {creditSummary ? <CreditSummary creditSummary={creditSummary} /> : <LoadingIndicator isActive={true} inverted={true} />}
          </Error4xx>

          <div className='vr5' />
          <div className='vr2_5' />
          <InvoiceCards
            loading={invoicesLoading}
            invoicesResponse={invoicesResponse}
            onNewPageClick={onInvoicesPaginationClick}
            onTabClick={onInvoicesTabClick}
            invoices4xxResponses={invoices4xxResponses}
          />
          <div className='vr5' />
          <TransactionHistory
            invoices4xxResponses={invoices4xxResponses}
            refreshResults={refreshResults}
            transactionHistoryLoading={transactionHistoryLoading}
            resetTransactionHistory={resetTransactionHistory}
            accountNumber={accountNumber}
            transactionResponse={transactionsResponse}
            transactionHistoryConfig={transactionHistoryConfig}
            setTransactionHistoryConfig={setTransactionHistoryConfig}
            onNewPageClick={onTransactionsPaginationClick}
          />
        </div>
      </AccessControl>
    );
  } else {
    return pageLoader(activeAccount);
  }
}
export default TransactionsPage;
