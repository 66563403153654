import styles from './EditAccountsOverlay.module.scss';
import Title from 'components/Title/Title';
import AccountsColumnNamesEnum from 'constants/AccountsColumnNamesEnum';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import AccountsSearch from 'containers/AccountsOverlay/components/AccountsSearch/AccountsSearch';
import IStore from 'models/IStore';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import AccountsAction from 'stores/accounts/AccountsAction';
import IAccount from 'stores/accounts/models/IAccount';
import IAccountsResponse from 'stores/accounts/models/IAccountsResponse';
import { oc } from 'ts-optchain.macro';
import IDataTableUIState from 'stores/dataTable/models/IDataTableUIState';
import debounce from 'lodash.debounce';
import { isEqual } from 'lodash';
import AccountsSelected from 'containers/AccountsOverlay/components/AccountsSelected/AccountsSelected';
import UserUtility from 'utilities/UserUtility/UserUtility';
import LinkUnlinkUserRequestModel from 'utilities/UserUtility/models/LinkUnlinkUserRequestModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { KenticoUser } from 'stores/users/models/IUserV3';
import UsersAction, { IRolesFilterState } from 'stores/users/UsersAction';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import { useParams } from 'react-router-dom';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import EditAccountsTable from 'containers/AccountsOverlay/components/AccountsTable/EditAccountsTable';
import { ReactComponent as ArrowsSwitch } from '../../assets/media/icons/icon-arrows-switch.svg';
import ErrorAction from 'stores/error/ErrorAction';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
interface IState {
  ui: IDataTableUIState;
}

interface IProps {
  userToLink?: KenticoUser;
  closeModal?: any;
  linkedAccounts?: IAccount[];
}

class AccountsPreviousState {
  public filter = '-1'; // state must start differnet the the default so it makes the initial call

  public setFilter(state) {
    this.filter = state;
  }
}

const PREVIOUS = new AccountsPreviousState();

const EditAccountsOverlay = ({ userToLink, closeModal, linkedAccounts }: IProps) => {
  const dispatch: Dispatch = useDispatch();
  const { accountNumber } = useParams();
  const accountsResponse: IAccountsResponse = useSelector((state: IStore) => state.accounts.accountsResponse);
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => oc(state).users.rolesFilters(null));
  const [state, setState] = useState<IState>({
    ui: {
      filter: '',
      sortData: {
        Column: AccountsColumnNamesEnum.AccountNumber,
        SortDirection: SortDirectionEnum.ASC,
      },
    },
  });
  const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onAddAccount = (account: IAccount) => {
    setSelectedAccounts([account, ...selectedAccounts]);
  };

  const onRemoveAccount = (account: IAccount) => {
    const newSelectedAccounts = selectedAccounts.filter((item) => account.accountNumber !== item.accountNumber);
    setSelectedAccounts(newSelectedAccounts);
  };

  const onSubmitAccounts = async () => {
    const newSelectedAccountNumbers = selectedAccounts.map((item) => item.accountNumber);
    // See if user aleady belonged to accounts
    function refreshAccountUsers() {
      const accountUsersRequestConfig = new UsersRequestModel({
        accountNumber,
        membership: rolesFilter ? rolesFilter.manageUserFilter : '',
      });
      dispatch(UsersAction.requestAccountUsers(accountUsersRequestConfig));
    }

    if (userToLink) {
      const origLinkedAccountNumbers = linkedAccounts?.map((item) => item.accountNumber);
      const linkList = newSelectedAccountNumbers.filter((item) => !origLinkedAccountNumbers?.includes(item)) || [];
      const unlinkList = origLinkedAccountNumbers?.filter((item) => !newSelectedAccountNumbers.includes(item)) || [];
      const linkRequest: LinkUnlinkUserRequestModel = new LinkUnlinkUserRequestModel({
        userId: userToLink?.userId,
        accountNumbers: linkList,
      });

      const unlinkRequest: LinkUnlinkUserRequestModel = new LinkUnlinkUserRequestModel({
        userId: userToLink?.userId,
        accountNumbers: unlinkList,
      });

      if (unlinkList.length === 0 && linkList.length === 0) {
        closeModal();
        return;
      }

      if (linkList.length > 0 && unlinkList.length === 0) {
        setIsLoading(true);
        await UserUtility.linkUser(linkRequest).then((response) => {
          if (response instanceof HttpErrorResponseModel) {
            dispatch(ErrorAction.add(response, 'linkUserId'));
          } else {
            dispatch(ErrorAction.clearAll());
            dispatch(UsersAction.requestUserAccounts(userToLink.email)).then(() => {
              refreshAccountUsers();
              dispatch(ToastsAction.add(`Linked user to Account(s): ${linkList.join(', ')}`, ToastStatusEnum.Success));
            });
          }
        });
        setIsLoading(false);
        closeModal();
        return;
      }

      if (unlinkList.length > 0 && linkList.length === 0) {
        setIsLoading(true);
        await UserUtility.unlinkUser(unlinkRequest).then((response) => {
          if (response instanceof HttpErrorResponseModel) {
            dispatch(ErrorAction.add(response, 'unlinkUserId'));
          } else {
            dispatch(ErrorAction.clearAll());
            dispatch(UsersAction.requestUserAccounts(userToLink.email)).then(() => {
              refreshAccountUsers();
              dispatch(ToastsAction.add(`Unlinked user from Account(s): ${unlinkList.join(', ')}`, ToastStatusEnum.Success));
            });
          }
        });
        setIsLoading(false);
        setIsLoading(false);
        closeModal();
        return;
      }

      // If both are not equal to zero.
      setIsLoading(true);
      await Promise.all([UserUtility.linkUser(linkRequest), UserUtility.unlinkUser(unlinkRequest)]).then((response) => {
        const responseError = response.find((res) => res instanceof HttpErrorResponseModel);

        if (responseError instanceof HttpErrorResponseModel) {
          dispatch(ErrorAction.add(responseError, 'linkAndUnlinkMultipleUsers'));
        } else {
          dispatch(ErrorAction.clearAll());
          dispatch(UsersAction.requestUserAccounts(userToLink.email)).then(() => {
            refreshAccountUsers();
            dispatch(ToastsAction.add(`Unlinked user from Account(s): ${unlinkList.join(', ')}`, ToastStatusEnum.Success));
            dispatch(ToastsAction.add(`Linked user to Account(s): ${linkList.join(', ')}`, ToastStatusEnum.Success));
          });
        }
      });
      setIsLoading(false);
      closeModal();
      return;
    }
  };

  const onNewPageClick = (page: number): void => {
    const { currentPageSize } = accountsResponse;
    const { filter, sortData } = state.ui;
    dispatch(AccountsAction.requestAccountResponse(currentPageSize, page, filter, sortData));
  };

  const callApi = (updatingSearch?: boolean, newState?: any): void => {
    const { currentPageSize, currentPage } = accountsResponse;
    const { filter, sortData } = state.ui;
    const filterForCall = newState?.ui.filter ?? filter;
    const sortForCall = newState?.ui.sortData ?? sortData;
    dispatch(AccountsAction.requestAccountResponse(currentPageSize, updatingSearch ? 1 : currentPage, filterForCall, sortForCall));
  };

  const onSortClick = (sortCol: string): void => {
    const isDesc = state.ui.sortData.SortDirection === SortDirectionEnum.DESC;
    const isSameCol = state.ui.sortData.Column === sortCol;
    let sortDir;
    if (isSameCol) {
      sortDir = isDesc ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
    } else {
      sortDir = SortDirectionEnum.DESC;
    }

    setState({
      ui: {
        filter: state.ui.filter,
        sortData: {
          Column: sortCol,
          SortDirection: sortDir,
        },
      },
    });
  };

  const updateSearchCall = (newState?: any) => {
    callApi(true, newState);
  };

  const updateSearch = useCallback(
    debounce((newState: any) => updateSearchCall(newState), 750),
    []
  );

  const onSearchChange = (event: any): void => {
    const newState = Object.assign({}, state);
    const filter = event.target.value;
    newState.ui.filter = filter;
    setState(newState);
  };

  useEffect(() => {
    if (!isEqual(state.ui.filter, PREVIOUS.filter)) {
      const tempStateObj = state;
      updateSearch(tempStateObj);
      PREVIOUS.setFilter(state.ui.filter);
    } else {
      callApi(false); // no new search term
    }
    // eslint-disable-next-line
  }, [dispatch, state]);

  useEffect(() => {
    if (linkedAccounts) {
      setSelectedAccounts(linkedAccounts);
    }
  }, [linkedAccounts]);

  if (isLoading) {
    return (
      <LoadingIndicator className={'full-height'} customLoadingText={'Linking and unlinking User from Accounts'} isActive={true} inverted={true} />
    );
  }

  return (
    <div>
      <div className={`${styles['acc-overview']} ${userToLink && styles['acc-overview--split']}`}>
        <div>
          <h1 id='modalTitle'>
            <Title title={'Select Account'} />
          </h1>
        </div>
        <div>
          <AccountsSearch onSearchChange={onSearchChange} filter={state.ui.filter}></AccountsSearch>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <EditAccountsTable
          onSortClick={onSortClick}
          onNewPageClick={onNewPageClick}
          accountData={accountsResponse}
          ui={state.ui}
          userToLink={userToLink}
          onAddAccount={onAddAccount}
          onRemoveAccount={onRemoveAccount}
          selectedAccounts={selectedAccounts}
          closeModal={closeModal}
        />
        <div className={styles.verticalDivider}>
          <div className={styles.verticalLine} />
          <span className={styles.switch_icon}>
            <ArrowsSwitch aria-hidden='true' focusable='false' role='img' />
          </span>
        </div>
        {userToLink && (
          <AccountsSelected
            user={userToLink}
            closeModal={closeModal}
            onSubmitAccounts={onSubmitAccounts}
            selectedAccounts={selectedAccounts}
            onRemoveAccount={onRemoveAccount}
          />
        )}
      </div>
    </div>
  );
};

export default EditAccountsOverlay;
