import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import GetEstimatedDatesRequestModel from 'stores/create-order/models/GetEstimatedDatesRequestModel';
import { ESTIMATED_DATE } from '../ProductConfig';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import makeAllDependencyCalls from './makeAllDependencyCalls';
import generateFieldState from './generateFieldState';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

export default async function getEditFields({
  dispatch,
  product,
  accountNumber,
  setIsLoading,
  tabState,
  setPriceTotal,
  step,
  setStep,
  setFieldState,
  setMasterFieldObject,
  setNonMasterFieldFormState,
  setErrorMessage,
  setSubmittedList,
}) {
  setIsLoading(true);
  // Get the initial fields over again to get the initial list of field values/options
  const initialFieldsRes = await CreateOrderStatelessCalls.getInitialFields({ product, accountNumber });
  const initialFieldsMFO: IDynamicFormAttribute[] = initialFieldsRes.data.data;

  // Store initial fields and config it is the same thing except the product config store is used to cache products
  // used in cache and when editing
  dispatch(CreateOrderAction.storeProductConfig(initialFieldsMFO, product, true));

  // Set product default estimated date
  ESTIMATED_DATE.setProduct(product);
  CreateOrderStatelessCalls.getEstimatedShipDates(
    new GetEstimatedDatesRequestModel({
      product,
      configuration: [],
    })
  ).then((res) => {
    if (!res.data) {
      setErrorMessage(res);
    }
    ESTIMATED_DATE.defaultDate = res.data;
  });

  // Retrieve the cart item to edit
  const cartItemRes = await CartStatelessCalls.getCartItem(tabState.itemToEditId);

  if (!cartItemRes.data) {
    setErrorMessage(cartItemRes);
    setIsLoading(false);
    return;
  }

  // Grab the MFO/Configuration from the res
  const cartItemConfigutration: IDynamicFormAttribute[] = cartItemRes.data.configuration.configuration;

  // Add values from the initialFieldsMFO into the retrieved cart item
  const masterFieldObject: IDynamicFormAttribute[] = cartItemConfigutration.map((item: IDynamicFormAttribute) => {
    return {
      ...item,
      attributeValues: initialFieldsMFO.find((initial: IDynamicFormAttribute) => initial.attributeName === item.attributeName)?.attributeValues || [],
    };
  });

  // Make all dependency calls. Returns a MFO with new value lists from the dependency calls
  const newMasterFieldObject: any = await makeAllDependencyCalls(masterFieldObject, product, accountNumber);
  if (newMasterFieldObject instanceof HttpErrorResponseModel) {
    setErrorMessage(newMasterFieldObject);
    setIsLoading(false);
    return;
  }

  // Update convert the new MFO (newCartItemToEdit) into state used for visible fields.
  const updatedFields = generateFieldState(newMasterFieldObject, step, setStep);

  // Set the submitted list to everything that was called. and also add CLUBS
  setSubmittedList(
    newMasterFieldObject.filter((item: IDynamicFormAttribute) => item.independentVariable1).map((item: IDynamicFormAttribute) => item.attributeName)
  );

  // set not master form state from the cartItemResponse
  setNonMasterFieldFormState({ QTY: cartItemRes.data.quantity, TAGNAME: cartItemRes.data.tagName });

  // Set MFO to the newCartItemToEdit
  setMasterFieldObject(newMasterFieldObject);

  // Set FieldState to fieldState
  setFieldState(updatedFields);
  setIsLoading(false);
}
