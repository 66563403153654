import React from 'react';
import IAccount from 'stores/accounts/models/IAccount';
import UsersAccountCardMenu from './UserAccountCardMenu';
import styles from './UsersAccountCard.module.scss';
import { KenticoUser } from 'stores/users/models/IUserV3';

interface IProps {
  index: number;
  accountInfo: IAccount;
  selectedUser: KenticoUser;
  fromAdmin: boolean;
  setIsLoading: any;
}

const UsersAccountCard = ({ setIsLoading, fromAdmin, selectedUser, index, accountInfo }: IProps) => {
  return (
    <div className={`vr1_5 ${styles['row']} ${index === 0 ? styles['first'] : ''}`}>
      <div className={`split ${styles.splitContainer}`}>
        <div>
          <div className={styles['cell']}>{accountInfo.accountNumber}</div>
          <div className={styles['cell']}>{accountInfo.accountName}</div>
        </div>
        <UsersAccountCardMenu
          setIsLoading={setIsLoading}
          selectedUser={selectedUser}
          fromAdmin={fromAdmin}
          accountNumberToDetach={accountInfo.accountNumber}
        />
      </div>
    </div>
  );
};

export default UsersAccountCard;
