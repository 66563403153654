import PaginatedRequestModel from 'stores/pagination/IPaginatedRequestModel';

export default class CreateLocationRequestModel extends PaginatedRequestModel {
  accountNumber: number;
  name: string;
  regionName: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
