import * as React from 'react';
import OrdersResponseModel from '../../../../stores/orders/models/OrdersResponseModel';
import OrdersCard from '../OrdersCard/OrdersCard';

interface IProps {
  ordersResponse: OrdersResponseModel | null;
}

function OrdersPreview({ ordersResponse }: IProps) {
  if (!ordersResponse) {
    return null;
  }

  return (
    <ul>
      {ordersResponse.data.slice(0, 5).map((order, i) => {
        return (
          <li key={order.orderNumber + i}>
            <OrdersCard order={order} />
          </li>
        );
      })}
    </ul>
  );
}

export default OrdersPreview;
