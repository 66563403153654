import React from 'react';
import ILocations from 'utilities/Location/models/ILocations';
import LocationCard from '../LocationCard/LocationCard';
import styles from './LocationSection.module.scss';

interface IProps {
  locations: ILocations[] | undefined;
  name: string;
  description: string;
}

const LocationSection = ({ name, description, locations }: IProps) => {
  return (
    <>
      {locations && (
        <div>
          <div className={styles.locationSection}>
            <h2 className={styles.locationSection_title}>{name.charAt(0).toUpperCase() + name.slice(1)}</h2>
            <p className={styles.locationSection_desc}>{description}</p>
          </div>
          {locations?.map((locationItem) => {
            return <LocationCard key={locationItem.id} location={locationItem} />;
          })}
        </div>
      )}
    </>
  );
};

export default LocationSection;
