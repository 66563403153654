/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory, History } from 'history';
import IStore from './models/IStore';
import rootStore from './stores/rootStore';
import App from './containers/App';
import environment from 'environment';
import { CookiesProvider } from 'react-cookie';
import MSALAuth from 'utilities/MSALAuth';
import { REGION_COOKIE_NAME } from 'constants/RegionsEnum';
import RouteEnum from 'constants/RouteEnum';
import DateHelper from 'constants/Dates';
import MaintenancePage from 'containers/MaintenancePage/MaintenancePage';
import LoadingWithLogout from 'components/LoadingWithLogout/LoadingWithLogout';
import './i18n.ts';
import i18n from './i18n';
import TagManager from 'react-gtm-module';
import FeatureFlagUtility from 'utilities/FeatureFlagUtility';
import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';

const tagManagerArgs = {
  gtmId: environment.gtm,
};

TagManager.initialize(tagManagerArgs);

/**
 * The Global MSAL Auth instance that should be used.
 */
export const MSAL_AUTH = new MSALAuth();
/**
 * The Global Date Helper that contains commonly used date functions.
 */
export const DATE_HELPER = new DateHelper();
/**
 * The Global alias for the translation tool;
 * Makes importing easier
 * i18n.t('key') becomes tr.t('key')
 */
export const TR = i18n;

class skipStore {
  public skipStore = false;
  public hashStore = false;
  public setSkipStore(shouldSkip) {
    this.skipStore = shouldSkip;
  }
  public setHashStore(shouldHash) {
    this.hashStore = shouldHash;
  }
}

export const SKIP_STORE = new skipStore();

function noConsole() {}

if (environment.isProduction === true) {
  console.log = noConsole;
  console.warn = noConsole;
  console.error = noConsole;
  console.group = noConsole;
  console.groupEnd = noConsole;
}

if (!String.prototype.repeat) {
  String.prototype.repeat = function(count) {
    'use strict';
    if (this == null) {
      throw new TypeError("can't convert " + this + ' to object');
    }

    let str = String(this);
    // To convert string to integer.
    count = Number(count);
    // Check NaN
    if (count != count) {
      count = 0;
    }

    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }

    if (count == Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }

    count = Math.floor(count);
    if (str.length == 0 || count == 0) {
      return '';
    }

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }

    const maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}

const checkRegion = () => {
  // Check to see if the Ping_Region_URL cookie is set.
  const regionCookieName = REGION_COOKIE_NAME;
  const regionCookieRawValue = `; ${document.cookie}`;
  const regionCookieParts = regionCookieRawValue.split(`; ${regionCookieName}=`);

  if (regionCookieParts.length === 2) {
    const regionCookieValue = decodeURIComponent(
      // @ts-ignore
      regionCookieParts
        .pop()
        .split(';')
        .shift()
    );

    // Check to see if the Ping_Region_URL cookie is set to US, if not redirect to the cookie value.
    if (regionCookieValue !== environment.uri.baseUri) {
      (window as any).location = regionCookieValue;

      return regionCookieValue;
    }

    return regionCookieValue;
  } else {
    // If not set, redirect to select-region page if we're not on the region page.

    const selectRegionPageURL = `${environment.route.baseRoute}${RouteEnum.SelectRegion}`;
    if ((window as any).location.pathname !== selectRegionPageURL) {
      (window as any).location = selectRegionPageURL;

      return null;
    }

    return null;
  }
};

(async (window: Window): Promise<void> => {
  const initialState: Partial<IStore> = {};
  const checkFeatureFlag = await FeatureFlagUtility.getAllFeatureFlag();
  const history: History = createBrowserHistory({ basename: environment.route.baseRoute });

  const store: Store<IStore> = rootStore(initialState, history);
  const rootEl: HTMLElement | null = document.getElementById('root');
  const isRegionSet = checkRegion();
  let renderCase;

  const errorHash = window.location.hash.includes('error=');
  const cancelPass = (window as any).location.hash.includes('error_description=AADB2C90091');
  if (cancelPass) {
    renderCase = 'cancelPass';
  } else if (errorHash) {
    renderCase = 'errorHash';
  } else {
    renderCase = 'default';
  }

  /**
   * Decides what page or action to initially execute
   */
  const renderSwitch = {
    /**
     * When user cancels pass
     */
    cancelPass: async () => {
      await MSAL_AUTH.initialize();
      MSAL_AUTH.logout();
    },
    /**
     * When a error is passed from login
     */
    errorHash: async () => {
      await MSAL_AUTH.initialize();

      const render = (Component: typeof App, el: HTMLElement | null): void => {
        // This will grab the error description for debugging. The messages are to long to show the user
        // const hashParams = new URLSearchParams(window.location.hash);
        // const errorDesc = hashParams.get('error_description')
        ReactDOM.render(
          <LoadingWithLogout
            customErrorText={
              <>
                <PingCustomerServiceContact />
              </>
            }
            className={'full-page'}
            isActive={true}
            inverted={true}
            displayError={true}
          />,
          el
        );
      };

      render(App, rootEl);
    },
    /**
     * Check if maintenance from Azure Feature Flag is enabled
     * If enabled, it will render the Maintenance Page
     * If not enabled, it will render the Application
     */
    default: async () => {
      if (window.location.hash.includes('state')) {
        await MSAL_AUTH.initialize();
      }
      if (checkFeatureFlag.maintenance) {
        const render = (Component: typeof App, el: HTMLElement | null): void => {
          ReactDOM.render(<MaintenancePage />, el);
        };
        render(App, rootEl);
      } else {
        const render = (Component: typeof App, el: HTMLElement | null): void => {
          ReactDOM.render(
            <Provider store={store}>
              <CookiesProvider>
                <Component history={history} dispatch={store.dispatch} featureFlag={checkFeatureFlag} />
              </CookiesProvider>
            </Provider>,
            el
          );
        };
        render(App, rootEl);
      }
    },
  };

  /**
   * Start App with switch
   */
  renderSwitch[renderCase]();
})(window);
