import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import EffectUtility from 'utilities/EffectUtility';
import HourCreateRequestModel from './models/HourCreateRequestModel';
import HourResponseModel from './models/HourResponseModel';
import HourUpdateRequestModel from './models/HourUpdateRequestModel';

export default class HoursEffect {
  public static async getHoursByLocationId(locationId: string): Promise<HttpErrorResponseModel | HourResponseModel> {
    const endpoint: string = `${(environment.api as any).hours}/location/${locationId}`;
    return await EffectUtility.getToModel<HourResponseModel>(HourResponseModel, endpoint);
  }
  public static async updateHours(hourId: string, request: HourUpdateRequestModel): Promise<HourResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).hours}/${hourId}`;
    return await EffectUtility.putToModel<HourResponseModel>(HourResponseModel, endpoint, request);
  }
  public static async createHours(request: HourCreateRequestModel): Promise<HourResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).hours}`;
    return await EffectUtility.postToModel<HourResponseModel>(HourResponseModel, endpoint, request);
  }
};
