import './Pagination.scss';
import React from 'react';
import { ReactComponent as CaretDown } from '../../assets/media/icons/icon-caret-down.svg';

interface IProps {
  data: any;
  onNewPageClick: (page: number, hasCases?: string) => void;
  hidePageDisplay?: boolean;
  hasCases?: boolean;
  currentCase?: string;
}

function Pagination(props: IProps) {
  const { data, hidePageDisplay, onNewPageClick, hasCases, currentCase } = props;
  const paginationItems: JSX.Element[] = [];

  let minPage = 1;
  let maxPage = data.totalPages;
  let showEndEllipses = false;
  let showBeginEllipses = false;
  const useAlternateDisplayStrategy = data.totalPages > 10;

  if (useAlternateDisplayStrategy) {
    const offset = 2;
    const center = data.currentPage;
    showEndEllipses = center + offset < data.totalPages;
    minPage = Math.max(1, center - offset);
    maxPage = Math.min(center + offset, data.totalPages);
    showBeginEllipses = minPage > offset;
  }

  for (let i = minPage; i <= maxPage; i++) {
    const activePage = data.currentPage === i;
    paginationItems.push(
      <li key={i}>
        <button
          type='button'
          aria-current={activePage ? 'page' : undefined}
          className='pagination__nav__btn focus-ring'
          onClick={hasCases && currentCase ? () => onNewPageClick(i, currentCase) : () => onNewPageClick(i)}
        >
          <span className='visually-hidden'>page </span>
          {i}
        </button>
      </li>
    );
  }

  return (
    <div className='pagination'>
      <p className='pagination__count'>
        <span className={Boolean(hidePageDisplay) ? 'pagination__count__hidden' : ''}>
          Page {data.currentPage} of {data.totalPages}
        </span>
      </p>
      <nav aria-label='Pagination'>
        <ul className='pagination__nav'>
          {data.currentPage > 1 && (
            <li key='prev'>
              <button type='button' className='pagination__nav__btn focus-ring' onClick={() => onNewPageClick(data.currentPage - 1)}>
                <span className='visually-hidden'>Previous page</span>
                <CaretDown className='icon icon--dir-270' aria-hidden='true' focusable='false' role='img' />
              </button>
            </li>
          )}
          {showBeginEllipses && (
            <>
              <li>
                <button type='button' className='pagination__nav__btn focus-ring' onClick={() => onNewPageClick(1)}>
                  <span className='visually-hidden'>page </span>
                  {1}
                </button>
              </li>
              <li className='pagination__nav__btn focus-ring'>
                <button type='button' disabled style={{ cursor: 'default' }} className='pagination__nav__btn focus-ring'>
                  <span>...</span>
                </button>
              </li>
            </>
          )}
          {paginationItems}
          {showEndEllipses && (
            <>
              <li className='pagination__nav__btn focus-ring'>
                <button type='button' disabled style={{ cursor: 'default' }} className='pagination__nav__btn focus-ring'>
                  <span>...</span>
                </button>
              </li>
              <li>
                <button type='button' className='pagination__nav__btn focus-ring' onClick={() => onNewPageClick(data.totalPages)}>
                  <span className='visually-hidden'>page </span>
                  {data.totalPages}
                </button>
              </li>
            </>
          )}
          {data.currentPage <= data.totalPages - 1 && (
            <li key='next'>
              <button type='button' className='pagination__nav__btn focus-ring' onClick={() => onNewPageClick(data.currentPage + 1)}>
                <span className='visually-hidden'>Next page</span>
                <CaretDown className='icon icon--dir-90' aria-hidden='true' focusable='false' role='img' />
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
export default Pagination;
