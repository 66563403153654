import React from 'react';
import styles from './TextWithSubtext.module.scss';
interface IProps {
  text: any;
  pastDue: string;
  subtext: any;
}

export default function TextWithSubtext(props: IProps) {
  const { text, pastDue, subtext } = props;

  return (
    <>
      <h3 className={pastDue ? styles.pastDue : ''}>{text}</h3>
      <p className={styles.subtext}>{subtext}</p>
    </>
  );
}
