import React, { useEffect, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import PingPhoneUtility from 'utilities/PingPhoneUtility';
import { PingCustomerServiceContact } from './PingCustomerServiceContact';
import environment from 'environment';

export const PingCustomerServiceContactMessage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  useEffect(() => {
    PingPhoneUtility.getPingCustomerServicePhone().then((data) => {
      setError(!data);
      setPhoneNumber(data);
    });
  }, []);
  return phoneNumber ? (
    <p>
      If this problem persists please contact Customer Service at <PingCustomerServiceContact />.
    </p>
  ) : error ? (
    <>
      <p>
        If this problem persists please contact Customer Service at{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          aria-label='link to PING customer service information'
          href={environment.customerServiceFallbackUrl}
        >
          Customer Service
        </a>
        .
      </p>
    </>
  ) : (
    <LoadingIndicator isActive={true} />
  );
};
