import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import styles from './FoundLocation.module.scss';
import Select from 'react-select';
import { ReactComponent as GrayMagicWand } from '../../../../assets/media/icons/icon-magic-wand--gray-chateau.svg';
import RouteEnum from 'constants/RouteEnum';
import selectStyles from 'components/Form/selectStyles';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import IAccount from 'stores/accounts/models/IAccount';
import AccountsAction from 'stores/accounts/AccountsAction';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import AddressRequestModel from 'utilities/Location/models/Address/AddressRequestModel';
import CreateLocationRequestModel from 'utilities/Location/models/CreateLocationRequestModel';
import LocationUtility from 'utilities/Location/LocationUtility';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationsAddressUtility from 'utilities/Location/LocationAddressUtility';
import LocationSetupUtility from 'utilities/Location/LocationSetupUtility';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import LocationInfoError from 'containers/LocationInfoPage/components/LocationInfoError/LocationInfoError';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
import ILocationSelectedState from 'utilities/Location/models/ILocationSelectedState';

const selectStylesModified = {
  ...selectStyles,
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    border: '2px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
  }),
};

const FoundLocation = () => {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const addresses: IAccountAddresses | null = useSelector((state: IStore) => oc(state).accounts.addresses(null));
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;

  const { locationState, updateAllLocationStateAndTabState } = useContext(LocationContext);
  const [addressState, setAddressState] = useState<ILocationSelectedState>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [addressList, setAddressList] = useState<ILocationSelectedState[]>([]);
  const [error, setError] = useState<ILocationInfoError>(initialError);

  // this gets the account address
  useEffect(() => {
    setIsLoading(true);
    if (accountNumber) {
      Promise.all([dispatch(AccountsAction.requestAccountAddresses(accountNumber))]);
    }
  }, [accountNumber, dispatch]);

  // this creates the location address and redirect to the details page
  const createLocationAddress = useCallback(
    (locationId: string) => {
      LocationsAddressUtility.createAddressLocation(
        new AddressRequestModel({
          street: addressState?.addressRequestConfig.street,
          compliment: addressState?.addressRequestConfig.compliment,
          city: addressState?.addressRequestConfig.city,
          state: addressState?.addressRequestConfig.state,
          postalCode: addressState?.addressRequestConfig.postalCode,
          country: addressState?.addressRequestConfig.country,
          locationId,
          latitude: addressState?.addressRequestConfig.latitude,
          longitude: addressState?.addressRequestConfig.longitude,
        })
      ).then((res) => {
        // setIsLoading(false);
        history.push(`${RouteEnum.FittingLocation}/${locationState.id}`);
      });
    },
    [locationState.id, addressState, history]
  );

  useEffect(() => {
    if (locationState.id !== '') {
      // Location id is not empty, it should generate a locationAddress array (addressConfig before)
      createLocationAddress(locationState.id);
    }
  }, [locationState.id, createLocationAddress]);

  // this sets the locationAddress List select
  const setLocationAddressList = (addresses: IAccountAddresses) => {
    const locationAddressLabel = `${addresses?.address1} ${addresses?.address2} ${addresses?.city} ${addresses?.state} ${addresses?.zip} ${addresses?.country}`;
    const request = new AddressRequestModel({
      street: `${addresses?.address1}`.trim(),
      compliment: `${addresses?.address2}`.trim(),
      city: ` ${addresses?.city}`.trim(),
      state: `${addresses?.state}`.trim(),
      postalCode: `${addresses?.zip}`.trim(),
      country: `${addresses?.country}`.trim(),
      latitude: 0.0,
      longitude: 0.0,
    });

    const addressArray: ILocationSelectedState[] = [];
    addressArray.push({ value: 0, label: locationAddressLabel, addressRequestConfig: LocationsAddressUtility.validate(request) });
    if (addresses && addresses.dropShipAddresses && addresses?.dropShipAddresses?.length !== 0) {
      for (let i = 0; i < addresses?.dropShipAddresses.length; i++) {
        const dropshipAddress = addresses?.dropShipAddresses[i];
        const dropShipAddressLabel = `${dropshipAddress.address1} ${dropshipAddress.address2} ${dropshipAddress.state} ${dropshipAddress.zip} ${dropshipAddress.country}`;
        const request = new AddressRequestModel({
          street: `${dropshipAddress?.address1}`.trim(),
          compliment: `${dropshipAddress?.address2}`.trim(),
          city: ` ${dropshipAddress?.city}`.trim(),
          state: `${dropshipAddress?.state}`.trim(),
          postalCode: `${dropshipAddress?.zip}`.trim(),
          country: `${dropshipAddress?.country}`.trim(),
          latitude: 0.0,
          longitude: 0.0,
        });
        addressArray.push({ value: i + 1, label: dropShipAddressLabel, addressRequestConfig: LocationsAddressUtility.validate(request) });
      }
    }
    if (addressArray.length > 0) {
      setAddressList(addressArray);
      setAddressState(addressArray[0]);
      setIsLoading(false);
    }
  };

  // this sets the location address select when the addresses list comes back
  useEffect(() => {
    if (addresses) {
      // eslint-disable-next-line no-use-before-define
      setLocationAddressList(addresses);
    }
  }, [addresses]);

  // this handles the select change
  const handleSelectChange = (event: ILocationSelectedState) => {
    setAddressState(event);
  };

  // this submits and enters the method when a user clicks on continue
  const handleSelectedLocationAddress = () => {
    const createRequestBody = new CreateLocationRequestModel({
      accountNumber: activeAccount?.accountNumber,
      name: activeAccount?.businessName ? activeAccount?.businessName : activeAccount?.accountName,
    });

    LocationUtility.createLocation(createRequestBody).then((response) => {
      if (response && response.data) {
        const createdLocation = response.data;
        LocationSetupUtility.setupAccountPrograms(createdLocation.id, createdLocation.accountNumber);
        updateAllLocationStateAndTabState &&
          updateAllLocationStateAndTabState(
            {
              id: createdLocation.id,
              name: createdLocation.name,
              displayHours: createdLocation.displayHours,
              regionId: createdLocation.regionId,
              statusId: createdLocation.statusId,
              hasAddress: false,
              certificationsVerified: createdLocation.certificationsVerified,
              environmentsVerified: createdLocation.environmentsVerified,
              technologiesVerified: createdLocation.technologiesVerified,
              displayOnMap: createdLocation.displayOnMap,
            },
            {
              hasAddress: false,
              hasCertifications: createdLocation.certificationsVerified,
              hasEnvironment: createdLocation.environmentsVerified,
              hasTechnology: createdLocation.technologiesVerified,
              tabIndex: 0,
            }
          );
      }
      if (response instanceof HttpErrorResponseModel) {
        setError({ isError: true, message: LocationErrorMessages.CREATE_LOCATION });
      }
    });
  };

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }
  return (
    <div className={styles.foundLocationContainer}>
      {error.isError ? (
        <LocationInfoError message={error.message} />
      ) : (
        <>
          <GrayMagicWand aria-hidden='true' focusable='false' role='img' />
          <h1 className={styles.foundLocationContainer_header}>The Following Addresses were Found:</h1>
          <p className={styles.foundLocationContainer_text}>Select the address you would like to set up as your Fitting Location.</p>

          <div className={`form__select-container ${styles.foundLocationContainer_form}`}>
            <label className={'form__select-label'} htmlFor='select-location-address'>
              {'Account Addresses'}
            </label>
            <Select
              styles={selectStylesModified}
              defaultValue={addressList[0]}
              required
              id='select-location-address'
              options={addressList}
              onChange={handleSelectChange}
            />
          </div>
          <div className='split'>
            <Link to={`${RouteEnum.FittingLocationSetup}`} className='btn btn--inverted'>
              <span className={styles.foundLocationContainer_btnText}>Cancel</span>
            </Link>
            <button onClick={handleSelectedLocationAddress} className='btn'>
              <span className={styles.foundLocationContainer_btnText}>Continue</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FoundLocation;
