import IFields from 'components/Form/IFields';
import { caDropShipFields } from 'containers/CreateOrderPage/components/orderShipping/constants/caDropShipFields';
import { naDropShipFields } from 'containers/CreateOrderPage/components/orderShipping/constants/naDropShipFields';
import { useEffect, useState } from 'react';
import PingAddressUtitlity from './AddressUtility';
const useRegionalDropShipFields = (): IFields | undefined => {
  const [fields, setFields] = useState<IFields | undefined>();
  const [addressType, setAddressType] = useState<string | undefined>();
  useEffect(() => {
    PingAddressUtitlity.getAddressType().then((at) => {
      setAddressType(at);
    });
  }, []);
  useEffect(() => {
    switch (addressType) {
      case 'NA':
        setFields(naDropShipFields);
        break;
      case 'CA':
        setFields(caDropShipFields);
        break;
    }
  }, [addressType]);
  return fields;
};
export { useRegionalDropShipFields };
