import { convertDate } from './OrderItemsHelperComps';

export function getItemData(order) {
  return order.items.map((item, index) => {
    const { itemStatus, itemStatusDate, tagName, itemDescription, product } = item;
    const estimatedDate = convertDate(item.quoteDate);
    const pONumber = order.purchaseOrderNumber;
    const reqDate = convertDate(order.wantDate);

    return {
      itemNumber: item.itemNumber,
      description: {
        product,
        itemDescription,
        tagName,
      },
      tagName: item.tagName,
      quantity: item.itemQuantity,
      unitPrice: item.unitPrice,
      itemTotal: item.totalItemPrice,
      status: {
        itemStatus,
        itemStatusDate,
      },
      estimatedDate,
      invoice: item.invoiceNumber,
      tracking: {
        orderHeaderId: order.orderHeaderId,
        itemNumber: item.itemNumber,
        orderNumber: order.orderNumber,
        pONumber,
        reqDate,
        terms: item.termId,
      },
    };
  });
}
