/* eslint-disable react/display-name */
import React from 'react';
import SearchResultItem from '../SearchResultItem/SearchResultItem';
import { getLink } from 'containers/TransactionsPage/components/TransactionHistoryItem/TransactionHistoryItemHelpers';
import { Link } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import Value from 'components/Value/Value';
import SearchResponseModelResponseModel from 'stores/search/models/search-response-model/SearchResponseModelResponseModel';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import SearchEnum from 'constants/SearchEnum';

function handleColumns(criteria) {
  switch (criteria) {
    case SearchEnum.Orders:
      return [
        {
          Header: 'Order No.',
          accessor: (itemData) => {
            return (
              <>
                <Link to={`${RouteEnum.Orders}/${itemData.orderNumber}`}>{itemData.orderNumber}</Link>
              </>
            );
          },
        },
        {
          Header: 'Order Date',
          accessor: (itemData) => {
            return moment(itemData.orderDate).format(DateFormatsEnum.WrittenDayMonthYear);
          },
        },
        {
          Header: 'P.O.',
          accessor: 'purchaseOrders',
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
        },
        {
          Header: 'Status',
          accessor: (itemData) => {
            return <Value value={itemData.itemStatuses} propertyName='orderstatus' />;
          },
        },
      ];

    case SearchEnum.Transactions:
      return [
        {
          Header: 'Reference #',
          accessor: (itemData) => {
            const { transactionType, referenceNumber, transactionNumber, transactionDate, accountNumber } = itemData;
            return <>{getLink(transactionType, referenceNumber, transactionNumber, transactionDate, accountNumber)}</>;
          },
        },
        {
          Header: 'Transaction Date',
          // accessor: 'transactionDate',
          accessor: (itemData) => {
            return moment(itemData.transactionDate).format(DateFormatsEnum.WrittenDayMonthYear);
          },
        },
        {
          Header: 'Type',
          accessor: 'transactionType',
        },
        {
          Header: 'P.O.',
          accessor: 'purchaseOrderNumber',
        },
        {
          Header: 'Amount',
          accessor: (itemData) => `$${itemData.amount.toFixed(2)}`,
        },

        {
          Header: 'Balance',
          accessor: (itemData) => {
            return `$${itemData.openBalance.toFixed(2)}`;
          },
          // accessor: 'openBalance',
        },
        {
          Header: 'Status',
          accessor: (itemData) => {
            return <Value value={itemData.transactionStatus} propertyName='transactionstatus' />;
          },
        },
      ];

    default:
      return [];
  }
}

interface IProps {
  searchResults: SearchResponseModelResponseModel;
  criteria: string;
  po?: boolean;
  orders?: boolean;
  transactions?: boolean;
  onNewPageClick: (page: number) => void;
  poOnNewPageClick: (pageNumber: number, type: string) => void;
}

function SearchResultsList({ searchResults, criteria, onNewPageClick, poOnNewPageClick, transactions, orders, po }: IProps): JSX.Element {
  const columns = handleColumns(criteria);

  return (
    <div>
      {searchResults?.data && (
        <h2 className='hdg hdg--3'>
          Showing {searchResults.data?.length} result{searchResults?.totalCount > 1 && 's'} of {searchResults?.totalCount}
        </h2>
      )}
      <SearchResultItem
        po={po ? true : false}
        orders={orders ? true : false}
        transactions={transactions ? true : false}
        poOnNewPageClick={poOnNewPageClick}
        searchResults={searchResults}
        columns={columns}
        onNewPageClick={onNewPageClick}
      />
    </div>
  );
}
export default SearchResultsList;
