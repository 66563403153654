import React, { useRef, createRef, useState } from 'react';
import IFields from './IFields';
import Select from 'react-select';
import SelectStyles from './selectStyles';
import InputMask from 'react-input-mask';
import ILocationInfoError from 'containers/LocationInfoPage/models/ILocationError';
import LocationInfoError from 'containers/LocationInfoPage/components/LocationInfoError/LocationInfoError';
import 'intl-tel-input/build/css/intlTelInput.css';
import { PhoneInput } from './PhoneInput';
interface IProps {
  fields: IFields;
  handleSubmit?: Function;
  handleFieldChange: Function;
  handleCountryCodeUpdates?: Function | undefined;
  handleSelectFieldChange?: Function;
  formState: any;
  handleThankYou?: boolean;
  submitButtonText?: string;
  formRef: any;
  formId: string;
  additionalStyle?: string;
  checkValid?: boolean;
  isError: ILocationInfoError;
}

function LocationForm({
  additionalStyle,
  fields,
  handleFieldChange,
  handleCountryCodeUpdates,
  handleSelectFieldChange,
  formState,
  handleThankYou,
  formId,
  checkValid,
  formRef,
  isError,
}: IProps) {
  //TODO Figure out how to update ref based on when fields object gets updated
  // Currently not being inplaced with Fitting Location Form
  const [phoneError, setPhoneError] = useState<string | null>();
  const inputRef = useRef<any>(fields.map(() => createRef()));
  const zipCode = (newState, userInput) => {
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-') {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };
  return (
    <>
      {isError.isError ? (
        <LocationInfoError message={isError.message} />
      ) : (
        <form className={`form ${additionalStyle}`} ref={formRef} id={formId}>
          {handleThankYou ? (
            'thanks'
          ) : (
            <div className={`form__select-wrapper`}>
              {fields.map((field, index) => {
                let calculatedRef = inputRef.current;
                if (inputRef) {
                  calculatedRef = inputRef.current[index];
                }
                const handleFocus = () => {
                  // Prevent crashing on fields using inputMask
                  if (!calculatedRef?.current?.props) {
                    calculatedRef?.current?.focus();
                  }
                };
                return (
                  <div key={index} className='vr1_5'>
                    {field.title && <h4 className='hdg hdg--4 form_title'>{field.title}</h4>}
                    {field.type === 'select' ? (
                      <div className={'form__select-container'}>
                        <label className={'form__select-label'} htmlFor={field.name}>
                          {field.label} {!field.notRequired && <span className='error-color'>*</span>}
                        </label>
                        <Select
                          styles={SelectStyles}
                          id={field.name}
                          value={{
                            key: formState[field.name],
                            value: formState[field.name].value,
                            label: formState[field.name].label,
                          }}
                          required={!field.notRequired}
                          options={field.options}
                          onChange={handleSelectFieldChange}
                        />
                      </div>
                    ) : field.inputType === 'phone' ? (
                      <>
                        <div className={'form__input-container'} onClick={handleFocus}>
                          <PhoneInput
                            onCountryCodeChange={handleCountryCodeUpdates}
                            onNumberChange={handleFieldChange(field.name)}
                            phoneNumber={formState[field.name]}
                            countryCode={{ countryCodeName: formState.countryCodeName, countryCodeNumber: formState.countryCodeNumber }}
                            setErrorMessage={setPhoneError}
                          />
                        </div>
                        {phoneError && <p className='form__error-highlight'>{phoneError}</p>}
                      </>
                    ) : (
                      <div onClick={handleFocus} className={field.type === 'checkbox' ? 'form__checkbox-container' : 'form__input-container'}>
                        <label className={field.type === 'checkbox' ? 'form__checkbox-label' : 'form__label'} id={field.name} htmlFor={field.name}>
                          {field.label} {!field.notRequired && <span className='error-color'>*</span>}
                        </label>
                        {field.mask ? (
                          <InputMask
                            mask={field.mask}
                            required={!field.notRequired}
                            id={field.name}
                            ref={calculatedRef}
                            onChange={handleFieldChange(field.name)}
                            value={formState[field.name]}
                            type={field.inputType}
                            name={field.name}
                            autoComplete={field.auto ? field.auto : ''}
                            pattern={field.pattern && field.pattern}
                            maskChar={null}
                            beforeMaskedValueChange={zipCode}
                          >
                            <input className={'form__input'} />
                          </InputMask>
                        ) : (
                          <input
                            required={!field.notRequired}
                            className={field.type === 'checkbox' ? 'form__checkbox' : 'form__input'}
                            id={field.name}
                            ref={calculatedRef}
                            checked={formState[field.name]}
                            onChange={handleFieldChange(field.name)}
                            value={formState[field.name] ? formState[field.name] : ''}
                            type={field.inputType}
                            name={field.name}
                            autoComplete={field.auto ? field.auto : ''}
                            pattern={field.pattern && field.pattern}
                          />
                        )}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {checkValid && calculatedRef.current ? (
                          calculatedRef.current.validity.valid ? null : (
                            <div className='form__error'>This Field Is required</div>
                          )
                        ) : null}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </form>
      )}
    </>
  );
}

export default LocationForm;
