import { ReactComponent as IconAccessories } from 'assets/media/icons/icon-Accessories.svg';
import { ReactComponent as IconGolfBags } from 'assets/media/icons/icon-Bags2.svg';
import { ReactComponent as IconHybrids } from 'assets/media/icons/icon-Hybrids.svg';
import { ReactComponent as IconIrons } from 'assets/media/icons/icon-Irons.svg';
import { ReactComponent as IconPutters } from 'assets/media/icons/icon-Putters.svg';
import { ReactComponent as IconMetalWoods } from 'assets/media/icons/icon-MetalWoods.svg';
import { ReactComponent as IconHeadwear } from 'assets/media/icons/icon-Headwear.svg';
import { ReactComponent as IconGloves } from 'assets/media/icons/icon-Gloves.svg';
import { ReactComponent as IconApparel } from 'assets/media/icons/icon-Apparel.svg';

export interface IProduct {
  displayName: string;
  endpointProduct: string;
  iconName: any;
  featureFlag: string;
  isClub: boolean;
}

/*
   * Additional unused Product Icons
  Crossovers: IconCrossovers,
  Fairways: IconFairways,
  Sets: IconSets,
  Wedges: IconWedges,
  */

const ProductsArr: IProduct[] = [
  {
    displayName: 'Accessories',
    endpointProduct: 'ACCESSORIES',
    iconName: IconAccessories,
    featureFlag: 'product_category_accessories',
    isClub: false,
  },
  {
    displayName: 'Golf Bags',
    endpointProduct: 'GOLF BAGS',
    iconName: IconGolfBags,
    featureFlag: 'product_category_golf_bags',
    isClub: false,
  },
  {
    displayName: 'Drivers / Fairways',
    endpointProduct: 'METAL WOODS',
    iconName: IconMetalWoods,
    featureFlag: 'product_category_drivers/fairways',
    isClub: true,
  },
  {
    displayName: 'Hybrids / Crossovers',
    endpointProduct: 'HYBRIDS',
    iconName: IconHybrids,
    featureFlag: 'product_category_hybrids/crossovers',
    isClub: true,
  },
  {
    displayName: 'Irons / Wedges',
    endpointProduct: 'IRONS',
    iconName: IconIrons,
    featureFlag: 'product_category_irons/wedges',
    isClub: true,
  },
  {
    displayName: 'Putters',
    endpointProduct: 'PUTTERS',
    iconName: IconPutters,
    featureFlag: 'product_category_putters',
    isClub: true,
  },
  {
    displayName: 'Headwear',
    endpointProduct: 'HEADWEAR',
    iconName: IconHeadwear,
    featureFlag: 'product_category_headwear',
    isClub: false,
  },
  {
    displayName: 'Gloves',
    endpointProduct: 'GLOVES',
    iconName: IconGloves,
    featureFlag: 'product_category_gloves',
    isClub: false,
  },
  {
    displayName: 'Apparel',
    endpointProduct: 'APPAREL',
    iconName: IconApparel,
    featureFlag: 'product_category_apparel',
    isClub: false,
  },
];

export default ProductsArr;
