import styles from '../SearchResultsList/SearchResultsList.module.scss';
import * as React from 'react';
import Pagination from 'components/Pagination/Pagination';
import { useTable, usePagination } from 'react-table';
import SearchEnum from 'constants/SearchEnum';

interface IProps {
  columns: any;
  searchResults: any;
  onNewPageClick: (page: number) => void;
  poOnNewPageClick: (pageNumber: number, type: string) => void;
  po: boolean;
  orders: boolean;
  transactions: boolean;
}

export default function SearchResultItem(props: IProps) {
  const { columns, searchResults, onNewPageClick, poOnNewPageClick, po, orders, transactions } = props;
  const controlledPageCount = searchResults.totalPages;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: searchResults.data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className={styles['search-table']}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className={`${styles['search-table__row']} ${styles['search-table__row--hd']}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={styles['search-table__row__cell']} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr className={`${styles['search-table__row']} ${styles['search-table__row--bd']}`} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={styles['search-table__row__cell']} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>

        {/* <tfoot>
        <tr className={styles['search-table__row'] + ' ' + styles['search-table__row--ft']}>
          <th scope='row' colSpan={7}>
            <Pagination data={searchResults} onNewPageClick={onNewPageClick} />
          </th>
        </tr>
      </tfoot> */}
      </table>
      {po ? (
        <>
          {transactions && <Pagination hasCases currentCase={SearchEnum.Transactions} data={searchResults} onNewPageClick={poOnNewPageClick} />}
          {orders && <Pagination hasCases currentCase={SearchEnum.Orders} data={searchResults} onNewPageClick={poOnNewPageClick} />}
        </>
      ) : (
        <Pagination data={searchResults} onNewPageClick={onNewPageClick} />
      )}
    </>
  );
}
