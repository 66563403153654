import { BaseModel } from 'sjs-base-model';

export default class UpdateUserRequestModel extends BaseModel {
  userId: number = 0;
  firstName: string;
  lastName: string;
  email: string;
  userMembership: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
