import IFittinglocationProgramMapping from 'utilities/Location/models/IFittinglocationProgramMapping';

const FittingLocationProgramMapping: IFittinglocationProgramMapping = {
  4: 'Metal Wood Fitting Tools',

  // pcsName: 'METAL WOOD FITTING PROGRAM',
  15: 'Metal Wood Fitting Tools',

  // pcsName: 'ADAPTIVE FITTING PROGRAM',
  39038: 'Adaptive Fitting Bag',

  // pcsName: 'FITTING PROGRAM TIER 1',
  86601: 'FT1',

  // pcsName: 'FITTING PROGRAM TIER 2',
  86602: 'FT2',

  // pcsName: 'FITTING PROGRAM TIER 3',
  86603: 'FT3',

  // pcsName: 'FITTING PROGRAM TIER 4',
  86604: 'FT4',

  // pcsName: 'FITTING PROGRAM TIER 1 CA',
  86605: 'FT1',

  // pcsName: 'FITTING PROGRAM TIER 2 CA',
  86606: 'FT2',

  // pcsName: 'FITTER OF THE YEAR',
  194128: 'Fitter of the Year 2018',

  // pcsName: 'FITTER OF THE YEAR',
  214141: 'Fitter of the Year 2019',

  // pcsName: 'Fitter of the Year',
  228931: 'Fitter of the Year 2020',

  // pcsName: 'FITTER OF THE YEAR',
  253630: 'Fitter of the Year 2021',

  // pcsName: 'AFS WEDGE FITTING SYSTEM',
  229155: 'Interchangeable Advanced Wedge Fitting System (AFS)',

  // pcsName: 'IPING 2.0 CERTIFIED',
  229230: 'Utilizes iPING 2.0',

  // pcsName: 'Ballnamic',
  242486: 'Ballnamic',

  //pcsName: 'Green Grass Brand Account',
  35950: 'Brand Account',

  //pcsName: 'Store Brand Account',
  35951: 'Brand Account',
};

export default FittingLocationProgramMapping;
