import * as React from 'react';
// import { NavLink } from 'react-router-dom';
import UserRolesEnum from 'constants/UserRolesEnum';
import IUser from 'stores/users/models/IUser';
import AccessControl from 'components/AccessControl/AccessControl';
import NotificationCard from '../NotificationCard/NotificationCard';
// import { ReactComponent as Plus } from '../../../../assets/media/icons/icon-plus.svg';
import INotificationPreference from 'stores/accounts/models/INotificationPreference';

interface IProps {
  notifications: INotificationPreference[] | undefined;
  currentUser: IUser;
}
interface IState {}

export default class NotificationPreferences extends React.Component<IProps, IState> {
  public render(): JSX.Element {
    const { notifications, currentUser } = this.props;
    const currentEmail = currentUser.email[0];
    const currentUserNotification = notifications?.filter((noti) => {
      return noti.recipient === currentEmail;
    })[0];

    const otherNotifications = notifications?.filter((noti) => {
      return noti.recipient !== currentEmail;
    });

    return (
      <AccessControl allowedRole={UserRolesEnum.ViewNotifications}>
        <>
          <div className='split split--centered'>
            <h3 className='hdg hdg--3'>Notification Preferences</h3>
            {/* <AccessControl allowedRole={UserRolesEnum.ManageSelfNotifications}>
              <NavLink to={'/'} className='btn'>
                <span className='btn__text'>Add New Recipient</span>
                <span className='btn__icon'>
                  <Plus aria-hidden='true' focusable='false' role='img' />
                </span>
              </NavLink>
            </AccessControl> */}
          </div>
          <div className='vr vr1' />
          <p>Select delivery option for each account user.</p>
          {currentUserNotification && (
            <>
              <div className='vr vr1' />
              <NotificationCard notificationPreference={currentUserNotification} />
            </>
          )}
          <AccessControl allowedRole={UserRolesEnum.ManageAllNotifications}>
            {otherNotifications ? (
              <>
                <div className='vr vr1' />
                {otherNotifications.map((notification) => {
                  return <NotificationCard notificationPreference={notification} key={notification.recipient} />;
                })}
              </>
            ) : (
              <></>
            )}
          </AccessControl>
        </>
      </AccessControl>
    );
  }
}
