import PaginatedRequestModel from 'stores/pagination/IPaginatedRequestModel';

export default class UpdateContactRequestModel extends PaginatedRequestModel {
  name: string;
  phone: string;
  website: string;
  countryCodeName: string;
  countryCodeNumber: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
