// http://api.pinggolf.local/api/v1/Products/127/GetProductAttributesByOrgId?orgId=us&product=IRONS

import { MAIN_CONFIG } from 'configurations/mainConfig';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import { IGenerateFieldsConfig, IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';
import generateFieldState from './generateFieldState';
import { getAddedValuesMasterFieldObject } from './getAddedValuesMasterFieldObject';
import requestFieldUpdates from './requestFieldUpdates';
import { ESTIMATED_DATE } from '../ProductConfig';
import GetEstimatedDatesRequestModel from 'stores/create-order/models/GetEstimatedDatesRequestModel';

/**
 * Makes call for the initial masterFieldObject and generates its fields based on product passed in
 *
 * @param requestObject
 * @type IGenerateFieldsConfig
 */
export default async function generateInitialFields({
  product,
  accountNumber,
  setFieldState,
  setMasterFieldObject,
  step,
  setStep,
  setIsLoading,
  setSubmittedList,
  submittedList,
  setPriceTotal,
  setNotValidProduct,
  setErrorMessage,
  dispatch,
}: IGenerateFieldsConfig) {
  setIsLoading(true);
  const requestConfig = {
    product,
    accountNumber,
  };

  // Set default date
  ESTIMATED_DATE.setProduct(product);
  const defaultDate = await CreateOrderStatelessCalls.getEstimatedShipDates(
    new GetEstimatedDatesRequestModel({
      product,
      configuration: [],
    })
  );

  ESTIMATED_DATE.defaultDate = defaultDate.data;

  /**
   * Make call to get the initial field response and make the initial request for dependncy calls after setting
   * Department
   */
  if (accountNumber) {
    CreateOrderStatelessCalls.getInitialFields(requestConfig)
      .then(async (res) => {
        const resData: IDynamicFormAttribute[] = res.data.data;
        const masterFieldObjectAddedValues = getAddedValuesMasterFieldObject(resData);

        // Keep a copy of the original master field object.
        dispatch(CreateOrderAction.storeProductConfig(resData, product, true));

        // Set Department, Make dependency call
        const fields = generateFieldState(masterFieldObjectAddedValues, step, setStep);

        requestFieldUpdates({
          product,
          masterFieldObject: masterFieldObjectAddedValues,
          setMasterFieldObject,
          fieldState: fields,
          setFieldState,
          accountNumber,
          selectedValue: { Name: 'DEPARTMENT', Value: MAIN_CONFIG.CreateOrder.Department },
          setSubmittedList,
          submittedList: [],
          step,
          setStep,
          setIsLoading,
          setErrorMessage,
          setPriceTotal,
          originalMasterFieldObject: resData,
          saveProductConfig: true,
          dispatch,
        });
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err);
        setNotValidProduct(true);
      });
  } else {
    setIsLoading(false);
  }
}
