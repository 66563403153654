/**
 * Helper to add event listener when an overlay is open which will close it if mouse clicks outside of it.
 * You may also pass an optional outside node which will also be ignored, since that may already have a toggle function attached.
 *
 * @param {React.MouseEvent} event mouse event
 * @param {React.RefObject} node overlay node to close
 * @param {function} setIsOpen callback to close node with false param
 * @param {React.RefObject} [optionalTriggerNode] optional trigger outside of node which will also be ignored, such as toggle button that opened node
 */
export function closeOnClickOutside(event, node, setIsOpen, optionalTriggerNode): void {
  const isClickOutsideNode = !node.current.contains(event.target);

  // Optionally also prevent double-execution if clicking on a trigger outside of the main node.
  let isOutsideOptionalTriggerNode = true;

  if (optionalTriggerNode && optionalTriggerNode.current) {
    isOutsideOptionalTriggerNode = !optionalTriggerNode.current.contains(event.target);
  }

  if (isClickOutsideNode && isOutsideOptionalTriggerNode) {
    return setIsOpen(false);
  }
}
