import ICertification from 'utilities/Location/models/ICertification';
import IEnvironment from 'utilities/Location/models/IEnvironment';
import ITechnology from 'containers/LocationInfoPage/components/TechnologyTab/models/ITechnology';
import React, { useEffect, useState } from 'react';
import FittingTerm from './components/FittingTerm';
import { ReactComponent as Dictionary } from '../../../../../../assets/media/icons/icon-dictionary.svg';
import styles from './FittingTerminology.module.scss';

type CategoryUnion = ICertification | IEnvironment | ITechnology;

interface ITerm {
  termName: string;
  termDescription: string;
}
interface IProps {
  listOfCategories: CategoryUnion[];
  itemListName: string;
}

const FittingTerminology = ({ listOfCategories, itemListName }: IProps): JSX.Element => {
  const [listOfTerms, setListOfTerms] = useState<ITerm[]>([]);

  useEffect(() => {
    const tempTerms: ITerm[] = [];
    for (let i = 0; i < listOfCategories.length; i += 1) {
      if (listOfCategories[i].description) {
        tempTerms.push({
          termName: listOfCategories[i].name,
          termDescription: listOfCategories[i].description,
        });
      }
      let tempInnerTerms = listOfCategories[i][itemListName]
        .filter((item) => {
          return item.description;
        })
        .map((item) => {
          return {
            termName: item.name,
            termDescription: item.description,
          };
        });
      tempTerms.push(...tempInnerTerms);
    }
    setListOfTerms(tempTerms);
  }, [listOfCategories, itemListName]);

  return (
    <div>
      <h2 className={styles.terminologyTitle}>
        <Dictionary aria-hidden='true' focusable='false' role='img' /> PING Fitting Terminology
      </h2>
      {listOfTerms.length !== 0 &&
        listOfTerms.sort((a, b) => (a.termName > b.termName ? 1 : -1)) &&
        listOfTerms.map((term, i) => {
          return <FittingTerm key={term.termName + i} term={term} />;
        })}
    </div>
  );
};

export default FittingTerminology;
