import { IDynamicFormSetMasterFieldObjectValue, IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';

/**
 * Sets the value of an attribute int the master field object
 * Returns and sets it in state
 *
 * @param requestObject the request object
 * @type IDynamicFormSetMasterFieldObjectValue
 */
export default function setMasterFieldObjectValue({ masterFieldObject, selectedValue }: IDynamicFormSetMasterFieldObjectValue) {
  const masterFieldObjectSet: IDynamicFormAttribute[] = masterFieldObject.map((item: IDynamicFormAttribute) => {
    if (item.attributeName === selectedValue.Name && item.attributeName === 'CLUBS') {
      return {
        ...item,
        selectedValue: Array.isArray(selectedValue.Value) ? selectedValue.Value : [selectedValue.Value],
      };
    }

    if (item.attributeName === selectedValue.Name) {
      return {
        ...item,
        selectedValue: selectedValue.Value,
      };
    }

    return item;
  });

  return masterFieldObjectSet;
}
