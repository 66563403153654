import IAccountsState from './models/IAccountsState';
import AccountsAction from './AccountsAction';
import AccountsResponseModel from './models/AccountsResponseModel';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';
import SortDirectionEnum from '../../constants/SortDirectionEnum';
import IDataTableUIState from '../dataTable/models/IDataTableUIState';
import IAccountAddresses from './models/IAccountAddresses';
import INotificationPreference from './models/INotificationPreference';
import AccountInfoResponseModel from './models/AccountInfoResponseModel';

export default class AccountsReducer extends BaseReducer<IAccountsState> {
  public readonly initialState: IAccountsState = {
    accountsResponse: {
      totalCount: 0,
      totalPages: 0,
      currentPage: 1,
      currentPageSize: 10,
      data: [],
      statusCode: 0,
      status: '',
      message: null,
    },
    ui: {
      filter: '',
      sortData: {
        Column: '',
        SortDirection: SortDirectionEnum.NONE,
      },
    },
    activeAccount: null,
    accountsInfoResponse: null,
    notificationPreferences: null,
    addresses: null,
  };

  public [AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED](state: IAccountsState, action: IAction<AccountsResponseModel>): IAccountsState {
    return {
      ...state,
      accountsResponse: action.payload!,
    };
  }

  /** TODO: CURRENTLY UNUSED */
  public [AccountsAction.REQUEST_ACCOUNT_INFO_FINISHED](state: IAccountsState, action: IAction<AccountInfoResponseModel>): IAccountsState {
    return {
      ...state,
      accountsInfoResponse: action.payload!,
    };
  }

  public [AccountsAction.UPDATE_ACCOUNTS_UI](state: any, action: any): IDataTableUIState {
    return {
      ...state,
      ui: {
        ...state.ui,
        ...action.payload,
      },
    };
  }

  public [AccountsAction.SET_ACTIVE_ACCOUNT](state: any, action: any): IDataTableUIState {
    return {
      ...state,
      activeAccount: {
        ...action.payload,
      },
    };
  }

  public [AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED](state: any, action: any): IAccountAddresses {
    if (action.payload === null) {
      return {
        ...state,
        addresses: action.payload,
      };
    }
    return {
      ...state,
      ...action.payload.data,
    };
  }

  public [AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED](state: any, action: any): INotificationPreference[] {
    return {
      ...state,
      notificationPreferences: action.payload.data,
    };
  }
}
