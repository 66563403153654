import HourType from 'constants/HourEnum';
import moment from 'moment';
import momentTz from 'moment-timezone';

export interface ITimeOption {
  label: string;
  value: null | string;
}

export default class HoursUtility {
  /**
   * 12 hour format hh:mm A
   */
  private static twelveHourFormat: string = 'hh:mm A';
  /**
   * 24 hour format H:MM
   */
  private static twentyHourFormat: string = 'H:mm';
  /**
   * This function using moment to populate and grab all the available times in 30 minute increments
   *
   * @returns array of available times a user can select
   */
  public static getTimeZoneOptionsObjArr(options, nameValue) {
    return options.map((option) => {
      const label = option.replace(/_/g, ' ');
      return {
        label,
        value: option,
        name: nameValue,
      };
    });
  }
  public static getTimeOptionsObjArr(options: ITimeOption[], nameValue) {
    return options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        name: nameValue,
      };
    });
  }
  /**
   * This function using moment to populate and grab all the available times in 30 minute increments
   *
   * @returns array of available times a user can select
   */
  public static getFittingHoursOptions(nameValue) {
    const results: ITimeOption[] = [];
    let startTime = moment().startOf('day');
    const endTime = moment().endOf('day');

    while (startTime <= endTime) {
      if (results.length > 0) {
        startTime = startTime.add(30, 'minute');
        results.push({ label: startTime.format(HourType.twelveHour), value: startTime.format(HourType.twentyFourHour) });
      } else {
        results.push({ label: startTime.format(HourType.twelveHour), value: startTime.format(HourType.twentyFourHour) });
      }
    }

    const tempArray = results.map((item: ITimeOption) => {
      return item;
    });

    return this.getTimeOptionsObjArr(tempArray, nameValue);
  }

  /**
   * This function using moment retrieves and get all timezone available
   * @returns an array of available time zones a user can select from
   */
  public static getFittingTimeZone(nameValue: string) {
    return this.getTimeZoneOptionsObjArr(momentTz.tz.names(), nameValue);
  }
  /**
   * this converts a string to a time option
   * @param mondayStart: string
   * @param format : string
   * @returns ITimeOption
   */
  public static convertStringToTimeOption(timeString: string | null, hourType: HourType): ITimeOption {
    // check if string can be converted to time
    if (timeString !== null) {
      let timeFormatted: string;
      if (hourType === HourType.twentyFourHour) {
        timeFormatted = moment(timeString, HourType.twentyFourHour).format(HourType.twentyFourHour);
      } else {
        timeFormatted = moment(timeString, HourType.twelveHour).format(HourType.twelveHour);
      }
      const hourOption: ITimeOption = {
        value: timeString,
        label: timeFormatted,
      };
      return hourOption;
    }
    return { value: null, label: '-' };
  }
  /**
   * this returns a timezone option
   * @param timeZone string
   * @param nameValue string
   * @returns ITimeOption
   */
  public static convertStringToTimeZone(timeZone: string): ITimeOption {
    if (timeZone !== null && timeZone !== undefined) {
      return {
        value: timeZone,
        label: timeZone.replace(/_/g, ' '),
      };
    }
    return { value: null, label: '-' };
  }

  /**
   * this returns if time range is valid
   * @param startValue string
   * @param endValue string
   * @returns boolean
   */
  public static isValidTimeRange(startValue: null | string, endValue: null | string): boolean {
    const format = 'hh:mm:ss';
    const beforeTime = moment(startValue, format);
    const afterTime = moment(endValue, format);
    if (afterTime.isBefore(beforeTime)) {
      return false;
    }
    return true;
  }
}
