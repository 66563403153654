import React from 'react';
import { DropShipStateModel } from '../models/DropShipStateModel';
import { IAlternateAddress, IAlternateAddresses } from '../OrderShipping';
import styles from '../OrderShipping.module.scss';
import { ReactComponent as ArrowDown } from 'assets/media/icons/icon-arrow-down.svg';

interface IProps {
  alternateAddresses: IAlternateAddresses;
  formState: DropShipStateModel;
  setAlternateAddresses: any;
  saveAddressToCart: any;
  dropShipOptionSelected: number | undefined;
  setDropShipOptionSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const DropShipAddressSelection = ({
  alternateAddresses,
  formState,
  saveAddressToCart,
  setAlternateAddresses,
  dropShipOptionSelected,
  setDropShipOptionSelected,
}: IProps) => {
  const convertAndSaveAddress = (TMEAddress: IAlternateAddress, residentialFlag: boolean, selectedRadio: number) => {
    const newAddress: DropShipStateModel = {
      address1: TMEAddress.street,
      address2: TMEAddress.locale,
      attention: formState.attention,
      businessName: formState.businessName,
      city: TMEAddress.city,
      country: TMEAddress.country,
      name: formState.name,
      state: TMEAddress.region,
      zip: TMEAddress.postalCode,
    };

    saveAddressToCart(newAddress, residentialFlag, selectedRadio);
  };

  return (
    <>
      <button
        className={styles.dropShipAddressSelection__back}
        onClick={() => {
          setAlternateAddresses({ ...alternateAddresses, isDropShipAddressSelection: false });
          setDropShipOptionSelected(undefined);
        }}
      >
        <ArrowDown className='icon' aria-hidden='true' focusable='false' role='img' /> Back to form
      </button>
      <div className='vr2' />
      <h2 className='hdg--3'> Which address would you like to use?</h2>
      <div className='vr1_5' />
      <div className={styles.dropShipAddressSelection}>
        <div className={styles.dropShipAddressSelection__currentAddress}>
          <p className='bold'> Use Current Address</p>
          <div className='vr1_5' />
          <div
            onClick={() =>
              saveAddressToCart(
                formState,
                alternateAddresses.alternateAddresses?.[0] ? alternateAddresses.alternateAddresses[0].residential : true,
                0
              )
            }
            className={dropShipOptionSelected === 0 ? styles.dropShipAddressSelection__cardSelected : styles.dropShipAddressSelection__card}
          >
            <div className={styles.suggestedAddress__radioSymbolContainer}>
              <div className={styles.suggestedAddress__radioSymbol} />
            </div>

            <div className={styles.suggestedAddress__content}>
              {`${formState.address1 !== null ? formState.address1.trim() : ''}, ${formState.address2 !== null ? formState.address2.trim() : ''}`}{' '}
              <br /> {`${formState.city !== null ? formState.city : ''}, ${formState.state !== null ? formState.state : ''}`} <br />
              {`${formState.zip !== null ? formState.zip : ''} ${formState.country !== null ? formState.country : ''}`}
            </div>
          </div>
        </div>
        <div className={`${styles.dropShipAddressSelection__suggestedAddresses}`}>
          <p className='bold'>Use Suggested Addresses</p>
          <div className='vr1_5' />
          <div className={styles.dropShipAddressSelection__suggestedAddressesContainer}>
            {alternateAddresses.alternateAddresses === null ? (
              <> No Suggested Addresses Found </>
            ) : (
              <>
                {alternateAddresses.alternateAddresses.map((address, i) => {
                  return (
                    <div
                      onClick={() =>
                        convertAndSaveAddress(alternateAddresses.alternateAddresses[i], alternateAddresses.alternateAddresses[i].residential, i + 1)
                      }
                      className={
                        dropShipOptionSelected === i + 1 ? styles.dropShipAddressSelection__cardSelected : styles.dropShipAddressSelection__card
                      }
                      key={i}
                    >
                      <div className={styles.suggestedAddress__radioSymbolContainer}>
                        <div className={styles.suggestedAddress__radioSymbol} />
                      </div>
                      <div className={styles.suggestedAddress__content}>
                        {`${address.street !== null ? address.street : ''}, ${address.locale !== null ? address.locale : ''}`} <br />{' '}
                        {`${address.city !== null ? address.city : ''}, ${address.region !== null ? address.region : ''}`} <br />{' '}
                        {`${address.postalCode !== null ? address.postalCode : ''} ${address.country !== null ? address.country : ''}`}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropShipAddressSelection;
