import styles from './OrdersCard.module.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';
import Value from '../../../../components/Value/Value';
import IOrders from 'stores/orders/models/IOrders';

interface IProps {
  order: IOrders;
}

const COLUMN_LABELS = ['Order Number', 'Status', 'Quantity', 'Purchase Order', 'Order Date'];
const VALUE_PROPS = ['orderNumber', 'itemStatuses', 'quantity', 'purchaseOrders', 'orderDate'];

interface ISectionProps {
  label: string;
  valueProp: string;
}

function OrderCardSection({ label, order, valueProp }: ISectionProps & IProps): JSX.Element {
  return (
    <div className={`${styles.section} ${styles[`section--${valueProp}`]}`}>
      <div className={styles.section__label}>{label}</div>
      <Value value={order[valueProp]} propertyName={valueProp === 'itemStatuses' ? 'orderstatus' : valueProp} />
    </div>
  );
}

function OrdersCard(props: IProps): JSX.Element {
  const { order } = props;
  return (
    <Link to={`/pages/orders/${order.orderNumber}`}>
      <div className={styles['orders-card']}>
        {COLUMN_LABELS.map((label, index) => {
          return <OrderCardSection label={label} order={order} valueProp={VALUE_PROPS[index]} key={`${order.orderNumber}-${index}`} />;
        })}
      </div>
    </Link>
  );
}

export default OrdersCard;
