import { useEffect } from 'react';
import { closeOnClickOutside } from './mouse';

/**
 * Hook to add event listener when an overlay is open which will close it if mouse clicks outside of it.
 * You may also pass an optional outside node which will also be ignored, since that may already have a toggle function attached.
 *
 * @param {Boolean} isOpen True is node is currently open
 * @param {React.RefObject} node The node to close (typically a tooltip, popover, modal, etc.)
 * @param {function} setIsOpen callback to close node with false param
 * @param {React.RefObject} [optionalTriggerNode] optional trigger outside of node which will also be ignored, such as toggle button that opened node
 */
export function useCloseOnClickOutside(isOpen, node, setIsOpen, optionalTriggerNode): void {
  useEffect(() => {
    const boundCloseOnClickOutside = (event) => closeOnClickOutside(event, node, setIsOpen, optionalTriggerNode);

    if (isOpen) {
      window.addEventListener('mousedown', boundCloseOnClickOutside);
      return () => {
        window.removeEventListener('mousedown', boundCloseOnClickOutside);
      };
    }
  }, [isOpen, node, optionalTriggerNode, setIsOpen]);
}
