import BaseReducer from '../../utilities/BaseReducer';
import IUsersState from './models/IUsersState';
import UsersAction from './UsersAction';
import IAction from '../../models/IAction';
import UserResponseModel from './models/UserResponseModel';
import AccountsResponseModel from 'stores/accounts/models/AccountsResponseModel';
import UserResponseModelV3 from './models/UserResponseModelV3';
import UsersResponseModel from './models/UsersResponseModel';

export default class UsersReducer extends BaseReducer<IUsersState> {
  public readonly initialState: IUsersState = {
    currentUser: null,
    users: null,
    linkedAccounts: null,
    rolesFilters: null,
    userById: null,
    accountUsers: null,
  };

  public [UsersAction.REQUEST_USER_INFO_FINISHED](state: IUsersState, action: IAction<UserResponseModel>): IUsersState {
    return {
      ...state,
      currentUser: action.payload!,
    };
  }

  public [UsersAction.REQUEST_SET_CURRENT_USER_FINISHED](state: IUsersState, action: IAction<UserResponseModel>): IUsersState {
    return {
      ...state,
      currentUser: action.payload!,
    };
  }

  public [UsersAction.REQUEST_USERS_FINISHED](state: IUsersState, action: IAction<UsersResponseModel>): IUsersState {
    return {
      ...state,
      users: action.payload!,
    };
  }

  public [UsersAction.REQUEST_ACCOUNT_USERS_FINISHED](state: IUsersState, action: IAction<UsersResponseModel>): IUsersState {
    return {
      ...state,
      accountUsers: action.payload!,
    };
  }

  public [UsersAction.REQUEST_USER_BY_ID_FINISHED](state: IUsersState, action: IAction<UserResponseModelV3>): IUsersState {
    return {
      ...state,
      userById: action.payload!,
    };
  }

  public [UsersAction.REQUEST_USER_ACCOUNTS_FINISHED](state: IUsersState, action: IAction<AccountsResponseModel>): IUsersState {
    return {
      ...state,
      linkedAccounts: action.payload!,
    };
  }

  public [UsersAction.STORE_USERROLE_FILTER](state: IUsersState, action: any): IUsersState {
    return {
      ...state,
      rolesFilters: action.payload!,
    };
  }
}
