import React from 'react';
import styles from '../OrderShipping.module.scss';
import selectStyles from 'components/Form/selectStyles';
import Select from 'react-select';

interface IAddressList {
  value: number;
  label: string;
}

const selectStylesModified = {
  ...selectStyles,
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    border: '2px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
  }),
};

const AccountShippingAddress = ({ addresses, handleSelectChange }) => {
  let address;
  let addressList: IAddressList[] = [];
  address = `${addresses?.address1} ${addresses?.address2} ${addresses?.city} ${addresses?.state} ${addresses?.zip}`;
  addressList.push({ value: -1, label: address });

  if (addresses?.dropShipAddresses?.length !== 0) {
    address = addresses?.dropShipAddresses?.map((item, index) => {
      const dropShipAddressList = `${item.address1} ${item.address2} ${item.state} ${item.zip}`;
      addressList.push({ value: index, label: dropShipAddressList });
    });
  }
  return (
    <div className={styles['order-shipping__address-container']}>
      <h3 className='hdg hdg--4 vr2'>Address</h3>
      {/* <h4 className='hdg hdg--4'>{addresses?.businessName}</h4> */}
      <div className={'form__select-container'}>
        <label className={'form__select-label'} htmlFor='select-address'>
          {'Select an Address'}
        </label>
        <Select
          styles={selectStylesModified}
          defaultValue={addressList[0]}
          required
          id='select-address'
          options={addressList}
          onChange={handleSelectChange}
        />
      </div>
    </div>
  );
};

export default AccountShippingAddress;
