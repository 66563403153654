import * as React from 'react';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import styles from '../TransactionHistoryTable/TransactionHistoryTable.module.scss';
import { Link } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import { TransactionTypeDescriptionEnum } from 'constants/TransactionTypeEnum';

export function getLink(transactionTypeDescription, transactionReferenceNumber, transactionNumber, transactionDate, accountNumber) {
  switch (transactionTypeDescription) {
    case TransactionTypeDescriptionEnum.ADJUSTMENT:
      return <div className={styles['transaction-history-table__ref-link']}>{transactionReferenceNumber}</div>;
    case TransactionTypeDescriptionEnum.DEDIT_MEMO:
      return (
        <Link
          className={styles['transaction-history-table__ref-link']}
          to={`${RouteEnum.Transactions}/${accountNumber}/memo/${transactionReferenceNumber}/DM`}
        >
          {transactionReferenceNumber}
        </Link>
      );
    case TransactionTypeDescriptionEnum.CREDIT_MEMO:
      return (
        <Link
          className={styles['transaction-history-table__ref-link']}
          to={`${RouteEnum.Transactions}/${accountNumber}/memo/${transactionReferenceNumber}/CM`}
        >
          {transactionReferenceNumber}
        </Link>
      );
    case TransactionTypeDescriptionEnum.INTEREST:
      return <div className={styles['transaction-history-table__ref-link']}>{transactionReferenceNumber}</div>;
    case TransactionTypeDescriptionEnum.DISCOUNT:
      return <div className={styles['transaction-history-table__ref-link']}>{transactionReferenceNumber}</div>;
    case TransactionTypeDescriptionEnum.INVOICE:
      return (
        <Link className={styles['transaction-history-table__ref-link']} to={`${RouteEnum.Transactions}/${transactionReferenceNumber}`}>
          {transactionReferenceNumber}
        </Link>
      );
    case TransactionTypeDescriptionEnum.PAYMENT:
      return (
        <Link
          className={styles['transaction-history-table__ref-link']}
          to={`${RouteEnum.Payments}/${transactionNumber}/${transactionReferenceNumber}/${moment(transactionDate).format(
            DateFormatsEnum.HyphensForCalls
          )}`}
        >
          {transactionReferenceNumber}
        </Link>
      );
    default:
  }
}
