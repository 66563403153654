enum RouteEnum {
  Landing = '/',
  SelectRegion = '/select-region',
  Login = '/login',
  Logout = '/logout',
  Dashboard = '/dashboard',
  Transactions = '/transactions',
  Invoice = '/transactions/:id',
  Memo = '/transactions/:accountMemoNumber/memo/:memoId/:memoType',
  Payments = '/payment',
  Payment = '/payment/:tranNumber/:refNumber/:tranDate',
  Orders = '/pages/orders',
  Order = '/pages/orders/:id',
  OrderTagName = '/orders/tag-name',
  ManageUsers = '/manage-users',
  AdminPanel = '/admin-panel',
  SalesSupport = '/pages/sales-support',
  FrequentAskedQuestion = '/pages/frequent-asked-question',
  Accounts = '/accounts',
  Error500 = '/error-500/:statusCode/:userMessage/:eventCode/:previousLocation',
  Pricing = '/pages/pricing',
  Fitting = '/pages/fitting',
  FittingLocation = '/fitting/locations',
  FittingLocationDetail = '/fitting/locations/:locationId',
  FittingLocationSetup = '/fitting/locations/setup',
  FittingLocationFound = '/fitting/locations/addresses',
  Certification = '/fitting/certifications',
  FittingEvents = '/fitting/events',
  SerialLookup = '/serial-lookup',
  AccountProfile = '/account-profile',
  SearchResults = '/search',
  Contact = '/contact',
  UserRolesOverlay = '/user-roles',
  EditUserPage = '/users/:accountNumber/:userId/:fromAdmin?',
  EditUserPageBase = '/users',
  CreateUsersPageAdmin = '/users/create-user-sa',
  CreateUsersPage = '/users/create-user',
  CreateOrders = '/create-order',
  CreateOrder = '/create-order/:product',
  CreateOrderConfirm = '/create-order/order-confirm',
  CreateOrderConfirmFull = '/create-order/order-confirm/:orderNumber',
  CreateOrderShipping = '/create-order/shipping/',
  Maintenance = '/maintenance',
}

export default RouteEnum;
