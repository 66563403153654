import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './LocationInfoFooter.module.scss';
import RouteEnum from 'constants/RouteEnum';
import { LocationTabStateConstants } from 'utilities/Location/models/ILocationTabState';

interface IProps {
  tabName: string;
  tabIndex: number;
  statePropertyName: string;
}
const LocationInfoFooter = ({ tabName, tabIndex, statePropertyName }: IProps) => {
  const history = useHistory();
  const { locationTabState, setLocationTabStateProperty } = useContext(LocationContext);
  const handleTabChange = (tabIndex: number) => {
    if (setLocationTabStateProperty) {
      setLocationTabStateProperty(LocationTabStateConstants.tabIndex, tabIndex);
      if (tabName === 'technology' && tabIndex === 4) {
        history.push(`${RouteEnum.FittingLocation}`);
      } else if (tabName === 'addressDetails' && tabIndex === -1) {
        history.push(`${RouteEnum.FittingLocation}`);
      }
    }
  };

  const getBackButton = (type: string) => {
    const tabType = {
      addressDetails: 'Locations',
      certifications: 'Details',
      environment: 'Programs',
      technology: 'Environment',
      default: '',
    };
    return tabType[type] || tabType.default;
  };

  const getNextButton = (type: string) => {
    const tabType = {
      addressDetails: 'Next: Programs',
      certifications: 'Next: Environment',
      environment: 'Next: Technology',
      technology: 'Done',
      default: '',
    };
    return tabType[type] || tabType.default;
  };

  return (
    <div className={styles.buttonContainer}>
      <button
        className={`btn btn--inverted  btn__text-only btn--bold ${styles.buttonContainer_cancel}`}
        onClick={(e) => handleTabChange(tabIndex - 1)}
      >
        Back: {getBackButton(tabName)}
      </button>
      <button
        className={`btn btn__text-only btn--bold ${styles.buttonContainer_next} ${locationTabState[statePropertyName]} `}
        onClick={(e) => handleTabChange(tabIndex + 1)}
      >
        {getNextButton(tabName)}
      </button>
    </div>
  );
};

export default LocationInfoFooter;
