import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import IStore from '../models/IStore';
import IAction from '../models/IAction';
import ToastStatusEnum from '../constants/ToastStatusEnum';
import ToastsAction from '../stores/toasts/ToastsAction';

const errorToastMiddleware = (): Middleware => (store: MiddlewareAPI<Dispatch, IStore>) => (next: Dispatch) => (action: IAction<any>): void => {
  if (action.payload?.data === 'Success') {
    if (action.type === 'AccountsAction.REQUEST_UPDATE_ACCOUNT_USER_FINISHED') {
      next(ToastsAction.add('Updated user', ToastStatusEnum.Success));
    }
  }

  if (action.error === false) {
    if (action.type === 'AccountsAction.REQUEST_CREATE_ACCOUNT_USER_FINISHED') {
      next(ToastsAction.add('Created user', ToastStatusEnum.Success));
    }
  }

  if (action.payload?.data === true) {
    if (action.type === 'AccountsAction.REQUEST_DELETE_ACCOUNT_USER_FINISHED') {
      next(ToastsAction.add('Deleted user', ToastStatusEnum.Success));
    }
  }

  next(action);
};

export default errorToastMiddleware;
