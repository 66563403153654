import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import IAction from '../../models/IAction';
import ActionUtility from '../../utilities/ActionUtility';

export default class ErrorAction {
  public static readonly CLEAR_ALL: string = 'ErrorAction.CLEAR_ALL';
  public static readonly REMOVE: string = 'ErrorAction.REMOVE';
  public static readonly REMOVE_THIS: string = 'ErrorAction.REMOVE_THIS';
  public static readonly ADD: string = 'ErrorAction.ADD';

  public static removeById(id: string): IAction<string> {
    return ActionUtility.createAction(ErrorAction.REMOVE, id);
  }
  public static removeByKey(key: string): IAction<string> {
    return ActionUtility.createAction(ErrorAction.REMOVE_THIS, key);
  }
  public static clearAll(): IAction<undefined> {
    return ActionUtility.createAction(ErrorAction.CLEAR_ALL);
  }
  public static add(errorResponse: HttpErrorResponseModel, errorKey: string): any {
    const payload = {
      errorResponse,
      errorKey,
    };
    return {
      type: ErrorAction.ADD,
      payload,
    };
  }
}
