import React from 'react';
import { useTable } from 'react-table';
import styles from './OrderDetailTable.module.scss';

const OrderDetailTable = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  // Render the UI for your table
  return (
    <table {...getTableProps()} className={styles['order-detail-table']}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className={styles['order-detail-table__row--hd']} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th
                  className={`${styles['order-detail-table__row__cell']} ${styles['order-detail-table__row__cell__label']} ${styles[column.id]}`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className={styles['order-detail-table__row--bd']} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className={styles['order-detail-table__row__cell']} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrderDetailTable;
