export default interface ILocationTabState {
  hasAddress: boolean;
  hasCertifications: boolean;
  hasEnvironment: boolean;
  hasTechnology: boolean;
  tabIndex: number;
}

export interface ILocationTab {
  name: string;
  displayName: string;
  statePropertyName: string;
  tabIndex: number;
}
export const LocationTabStateConstants = {
  hasAddress: 'hasAddress',
  hasCertifications: 'hasCertifications',
  hasEnvironment: 'hasEnvironment',
  hasTechnology: 'hasTechnology',
  tabIndex: 'tabIndex',
};

export const LocationTabs: ILocationTab[] = [
  {
    name: 'addressDetails',
    displayName: 'Details',
    statePropertyName: LocationTabStateConstants.hasAddress,
    tabIndex: 0,
  },
  {
    name: 'certifications',
    displayName: 'Programs',
    statePropertyName: LocationTabStateConstants.hasCertifications,
    tabIndex: 1,
  },
  {
    name: 'environment',
    displayName: 'Environment',
    statePropertyName: LocationTabStateConstants.hasEnvironment,
    tabIndex: 2,
  },
  {
    name: 'technology',
    displayName: 'Technology',
    statePropertyName: LocationTabStateConstants.hasTechnology,
    tabIndex: 3,
  },
];
