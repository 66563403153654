/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import disableFormFields from 'components/Form/FormHelpers/disableFormFields';
import IHour from 'stores/hours/models/IHour';
import locationDaysUtility from 'utilities/LocationDaysUtility';
import IFields from 'components/Form/IFields';
import LocationForm from 'components/Form/LocationForm';
import HourUpdateRequestModel from 'stores/hours/models/HourUpdateRequestModel';
import HoursUtility from 'utilities/HoursUtility';
import HourType from 'constants/HourEnum';
import highlightFields from 'components/Form/FormHelpers/highlightFields';
import { IFormErrors } from 'components/Form/IFormErrors';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import HoursEffect from 'stores/hours/HoursEffect';
import HourCreateRequestModel from 'stores/hours/models/HourCreateRequestModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import HourResponseModel from 'stores/hours/models/HourResponseModel';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';

interface IProps {
  formRef: any;
  setSaved: (isSaved: boolean) => void;
}
interface ISelectOption {
  name: string;
  label: string;
  value: string;
}
interface ITimeOption {
  label: string;
  value: null | string;
}
interface IState {
  id: string;
  timeZone: ITimeOption;
  mondayLabel: boolean;
  mondayStart: ITimeOption;
  mondayEnd: ITimeOption;
  tuesdayLabel: boolean;
  tuesdayStart: ITimeOption;
  tuesdayEnd: ITimeOption;
  wednesdayLabel: boolean;
  wednesdayStart: ITimeOption;
  wednesdayEnd: ITimeOption;
  thursdayLabel: boolean;
  thursdayStart: ITimeOption;
  thursdayEnd: ITimeOption;
  fridayLabel: boolean;
  fridayStart: ITimeOption;
  fridayEnd: ITimeOption;
  saturdayLabel: boolean;
  saturdayStart: ITimeOption;
  saturdayEnd: ITimeOption;
  sundayLabel: boolean;
  sundayStart: ITimeOption;
  sundayEnd: ITimeOption;
}
const initialState = {
  id: '',
  timeZone: { value: null, label: '-' },
  mondayLabel: false,
  mondayStart: { value: null, label: '-' },
  mondayEnd: { value: null, label: '-' },
  tuesdayLabel: false,
  tuesdayStart: { value: null, label: '-' },
  tuesdayEnd: { value: null, label: '-' },
  wednesdayLabel: false,
  wednesdayStart: { value: null, label: '-' },
  wednesdayEnd: { value: null, label: '-' },
  thursdayLabel: false,
  thursdayStart: { value: null, label: '-' },
  thursdayEnd: { value: null, label: '-' },
  fridayLabel: false,
  fridayStart: { value: null, label: '-' },
  fridayEnd: { value: null, label: '-' },
  saturdayLabel: false,
  saturdayStart: { value: null, label: '-' },
  saturdayEnd: { value: null, label: '-' },
  sundayLabel: false,
  sundayStart: { value: null, label: '-' },
  sundayEnd: { value: null, label: '-' },
};

const InitialErrorState = {
  hasError: false,
  errors: [],
};

const LocationHours = ({ formRef, setSaved }: IProps) => {
  const [disableField, setDisableField] = useState<any>([]);
  const [state, setState] = useState<IState>(initialState);
  const [formErrors, setFormErrors] = useState<IFormErrors>(InitialErrorState);
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const days: IFields = locationDaysUtility.populateLocationDays(state);
  const labelFormat: HourType = HourType.twelveHour;
  const { locationState } = useContext(LocationContext);

  useEffect(() => {
    HoursEffect.getHoursByLocationId(locationState.id).then((res) => {
      setIsLoading(false);
      // error check
      if (res instanceof HttpErrorResponseModel) {
        if (res.statusCode === 404) {
          const request: HourCreateRequestModel = new HourCreateRequestModel({
            locationId: locationState.id,
          });
          HoursEffect.createHours(request).then((result: HourResponseModel) => {
            if (result instanceof HttpErrorResponseModel) {
              console.error('create error', result);
              setIsError({ isError: true, message: 'Error creating hours.' });
            }
            if (result.data) {
              const locationHours: IHour = result.data;
              setHours(locationHours);
            }
          });
        } else {
          console.error(res.data);
          setIsError({ isError: true, message: 'Error creating hours.' });
        }
      }
      // check if we have data
      if (res.data) {
        const locationHours: IHour = res.data;
        setHours(locationHours);
      } else {
        console.error(res.data);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * enables and disables form fields
   */
  useEffect(() => {
    disableFormFields(disableField);
  }, [disableField]);

  /**
   * Highlights and unhighlights form errors.
   */
  useEffect(() => {
    const fieldNames = [
      'sundayStart',
      'sundayStart',
      'sundayEnd',
      'mondayStart',
      'mondayEnd',
      'tuesdayStart',
      'tuesdayEnd',
      'wednesdayStart',
      'wednesdayEnd',
      'thursdayStart',
      'thursdayEnd',
      'fridayStart',
      'fridayEnd',
      'saturdayStart',
      'saturdayEnd',
    ];

    highlightFields(fieldNames, formErrors);
  }, [formErrors]);
  /**
   * this sets hours state
   * @param locationHours IHour
   */
  const setHours = (locationHours: IHour) => {
    if (locationHours) {
      setState({
        ...state,
        id: locationHours?.id,
        timeZone: HoursUtility.convertStringToTimeZone(locationHours.timeZone),
        mondayLabel: locationHours.mondayStart !== null,
        mondayStart: HoursUtility.convertStringToTimeOption(locationHours.mondayStart, labelFormat),
        mondayEnd: HoursUtility.convertStringToTimeOption(locationHours.mondayEnd, labelFormat),
        tuesdayLabel: locationHours.tuesdayStart !== null,
        tuesdayStart: HoursUtility.convertStringToTimeOption(locationHours.tuesdayStart, labelFormat),
        tuesdayEnd: HoursUtility.convertStringToTimeOption(locationHours.tuesdayEnd, labelFormat),
        wednesdayLabel: locationHours.wednesdayStart !== null,
        wednesdayStart: HoursUtility.convertStringToTimeOption(locationHours.wednesdayStart, labelFormat),
        wednesdayEnd: HoursUtility.convertStringToTimeOption(locationHours.wednesdayEnd, labelFormat),
        thursdayLabel: locationHours.thursdayStart !== null,
        thursdayStart: HoursUtility.convertStringToTimeOption(locationHours.thursdayStart, labelFormat),
        thursdayEnd: HoursUtility.convertStringToTimeOption(locationHours.thursdayEnd, labelFormat),
        fridayLabel: locationHours.fridayStart !== null,
        fridayStart: HoursUtility.convertStringToTimeOption(locationHours.fridayStart, labelFormat),
        fridayEnd: HoursUtility.convertStringToTimeOption(locationHours.fridayEnd, labelFormat),
        saturdayLabel: locationHours.saturdayStart !== null,
        saturdayStart: HoursUtility.convertStringToTimeOption(locationHours.saturdayStart, labelFormat),
        saturdayEnd: HoursUtility.convertStringToTimeOption(locationHours.saturdayEnd, labelFormat),
        sundayLabel: locationHours.sundayStart !== null,
        sundayStart: HoursUtility.convertStringToTimeOption(locationHours.sundayStart, labelFormat),
        sundayEnd: HoursUtility.convertStringToTimeOption(locationHours.sundayEnd, labelFormat),
      });
      setDisableField((prevDisableField) => [
        ...prevDisableField,
        locationHours.mondayStart !== null ? '' : 'mondayLabel',
        locationHours.tuesdayStart !== null ? '' : 'tuesdayLabel',
        locationHours.wednesdayStart !== null ? '' : 'wednesdayLabel',
        locationHours.thursdayStart !== null ? '' : 'thursdayLabel',
        locationHours.fridayStart !== null ? '' : 'fridayLabel',
        locationHours.saturdayStart !== null ? '' : 'saturdayLabel',
        locationHours.sundayStart !== null ? '' : 'sundayLabel',
      ]);
    }
  };
  /**
   * this handles hour change
   * @param time ISelectOption
   */
  const handleHoursChange = (time: ISelectOption) => {
    // this is the Name of the element
    const name = time.name;
    const value = time.value;
    let day: string;
    const end: ITimeOption = { label: '', value: null };
    const start: ITimeOption = { label: '', value: null };
    // get Day Start and End
    if (name.includes('Start')) {
      // we have startTime
      // set start day and value
      start.label = name;
      start.value = value;
      // get day
      day = name.substring(0, name.indexOf('Start'));
      // set end day and value
      end.label = `${day}End`;
      end.value = state[end.label].value;
    } else {
      // we have end
      end.label = name;
      end.value = value;
      day = name.substring(0, name.indexOf('End'));
      // set start day and value
      start.label = `${day}Start`;
      start.value = state[start.label].value;
    }
    // Validation
    const errors = handleValidation(start, end, setFormErrors);
    setFormErrors(errors);
    if (!errors.hasError) {
      // create request
      const updateRequest: HourUpdateRequestModel = new HourUpdateRequestModel({
        ...getStateValue(),
        [start.label]: start.value,
        [end.label]: end.value,
      });
      updateHours(updateRequest);
    }
  };
  /**
   * this handleValidation
   * @param start ITimeOption
   * @param end ITimeOption
   * @returns IFormErrors
   */
  const handleValidation = (start: ITimeOption, end: ITimeOption, setFormErrors: any): IFormErrors => {
    const formErrors: IFormErrors = {
      hasError: false,
      errors: [],
    };

    if (start.value === null) {
      formErrors.errors.push({ errorFieldName: start.label, errorMessage: `${start.label.replace('Start', ' from')} time is not set` });
    }
    // we have a start
    if (end.value === null) {
      formErrors.errors.push({ errorFieldName: end.label, errorMessage: `${end.label.replace('End', ' to')} time is not set` });
    }
    // we have an end
    if (!HoursUtility.isValidTimeRange(start.value, end.value)) {
      // focus start and alert start is after the end time
      formErrors.errors.push({
        errorFieldName: end.label,
        errorMessage: `${end.label.replace('End', ' to')} time is before ${start.label.replace('Start', ' from')}`,
      });
      // or end is before start
    }
    if (formErrors.errors.length > 0) {
      formErrors.hasError = true;
    }
    return formErrors;
  };
  /**
   * This handles timezone change
   * @param time ISelectOption
   */
  const handleTimeZoneChange = (time: ISelectOption) => {
    const timeZone = time.name;
    const timeZoneValue = time.value;

    let updateRequest: HourUpdateRequestModel;
    if (timeZoneValue !== '') {
      updateRequest = new HourUpdateRequestModel({ ...getStateValue(), [timeZone]: timeZoneValue });
      updateHours(updateRequest);
    }
  };
  /**
   * this makes the upate hours api call.
   * @param updateRequest HourUpdateRequestModel
   */
  const updateHours = (updateRequest: HourUpdateRequestModel) => {
    HoursEffect.updateHours(state.id, updateRequest).then((res) => {
      if (res instanceof HttpErrorResponseModel) {
        setIsError({ isError: true, message: 'Error updating location hours.' });
      }
      if (res.data) {
        setHours(res.data);
        setSaved(true);
      }
    });
  };
  const nullHours = (input: string) => {
    // get start and end from label
    const day = input.substring(0, input.indexOf('Label'));
    const start = `${day}Start`;
    const end = `${day}End`;
    const request = new HourUpdateRequestModel({ ...getStateValue(), [start]: null, [end]: null });
    updateHours(request);
  };
  /**
   * this handles input field changes
   * @param input Input field
   */
  const handleChange = (input) => (e): void => {
    if (input.includes('Label')) {
      setState({ ...state, [input]: e.target.checked });
      if (!e.target.checked) {
        // if not checked we update with null times
        nullHours(input);
      }
      if (disableField.includes(input)) {
        setDisableField(disableField.filter((item) => item !== input));
      } else {
        setDisableField([...disableField, input]);
      }
    } else {
      // handle not labels
      setState({ ...state, [input]: e.target.value });
    }
  };
  /**
   * this handles the select changes
   * @param newValue ISelectOption
   */
  const handleSelectChange = (newValue: ISelectOption) => {
    if (newValue.name.includes('Start') || newValue.name.includes('End')) {
      setState({ ...state, [newValue.name]: HoursUtility.convertStringToTimeOption(newValue.value, labelFormat) });
      handleHoursChange(newValue);
    } else {
      setState({ ...state, [newValue.name]: HoursUtility.convertStringToTimeZone(newValue.value) });
      handleTimeZoneChange(newValue);
    }
  };

  const getStateValue = () => {
    const stateValues: {} = {};
    Object.entries(state).map((key) => {
      if (typeof key[1] === 'object') {
        const name = key[0];
        const value = key[1].value;
        Object.assign(stateValues, { [name]: value });
        return { [name]: value };
      } else {
        const name = key[0];
        const value = key[1];
        Object.assign(stateValues, { [name]: value });
        return { [name]: value };
      }
    });
    return stateValues;
  };
  if (isLoading) {
    return <LoadingIndicator className={'full-height'} customLoadingText={'loading...'} isActive={true} inverted={true} />;
  }
  return (
    <>
      <LocationForm
        additionalStyle={'locationHours'}
        fields={days}
        handleFieldChange={handleChange}
        handleSelectFieldChange={handleSelectChange}
        formState={state}
        formRef={formRef}
        formId={'create-typical-hours'}
        isError={isError}
      />
      {formErrors.errors.length > 0 && (
        <div className='form__error'>
          {formErrors.errors.map((e, i) => (
            <div className='errors__line' key={i}>
              {e.errorMessage}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default LocationHours;
