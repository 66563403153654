import { BaseModel } from 'sjs-base-model';
import { IGetCartResponse } from './IGetCart';

export default class GetCartResponseModel extends BaseModel {
  public data: IGetCartResponse;
  public message: any = null;
  public status: string = '';
  public statusCode: number = 0;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
