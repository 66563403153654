/* eslint-disable quotes */
import IFields from 'components/Form/IFields';
import { getProvinceNameOptions } from 'utilities/getStateNameOptions';
import { DropShipStateModel } from '../models/DropShipStateModel';

export const caDropShipFields: IFields = [
  {
    name: DropShipStateModel.PropertyNames.NAME,
    type: 'input',
    label: 'Name',
    inputType: 'text',
    auto: 'name',
  },
  {
    name: DropShipStateModel.PropertyNames.BUSINESS,
    type: 'input',
    label: 'Business',
    inputType: 'text',
    notRequired: true,
    auto: 'organization',
  },
  {
    name: DropShipStateModel.PropertyNames.ATTENTION,
    type: 'input',
    label: 'Care Of / Attention:',
    inputType: 'text',
    notRequired: true,
  },
  {
    name: DropShipStateModel.PropertyNames.ADDRESS1,
    type: 'input',
    label: 'Street Address',
    inputType: 'text',
    auto: 'address-line1',
  },
  {
    name: DropShipStateModel.PropertyNames.ADDRESS2,
    type: 'input',
    label: 'Unit / Suite / Building',
    inputType: 'text',
    notRequired: true,
    auto: 'address-line2',
  },
  {
    name: DropShipStateModel.PropertyNames.CITY,
    type: 'input',
    label: 'City',
    inputType: 'text',
    auto: 'address-level2',
  },
  {
    name: DropShipStateModel.PropertyNames.STATE,
    type: 'select',
    inputType: 'select',
    label: 'State / Province',
    options: getProvinceNameOptions(DropShipStateModel.PropertyNames.STATE),
    auto: 'address-level1',
  },
  {
    name: DropShipStateModel.PropertyNames.ZIP,
    type: 'input',
    label: 'Postal Code',
    inputType: 'text',
    auto: 'postal-code',
    // eslint-disable-next-line
    pattern: '^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$',
  },
];
