import { BaseModel } from 'sjs-base-model';

export interface IGetClubs {
  cfgModel: string;
  club: string;
  clubOrder: string;
  swingWeightAdj: string;
  clubType: string;
}

export default class GetCartResponseModel extends BaseModel {
  public data: IGetClubs[];
  public message: any = null;
  public status: string = '';
  public statusCode: number = 0;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
