import environment from 'environment';
import EffectUtility from '../EffectUtility';
import TechnologyCategoriesResponseModel from '../../containers/LocationInfoPage/components/TechnologyTab/models/TechnologyCategoriesResonseModel';
import LocationResponseModel from './models/LocationResponseModel';

export default class LocationTechnologyUtility {
  public static baseTechCatUri: string = `${(environment.api as any).technologyCategories}`;
  public static baseLocationsUri: string = `${(environment.api as any).location}`;

  /**
   * This method retrieves a list of TechnologyCategories in the form of a TechnologyCategoriesResponse object
   * @param dispatch callback to dispatch error action
   */
  public static async getAllCertificationCategories() {
    const endpoint: string = `${LocationTechnologyUtility.baseTechCatUri}`;
    const response = await EffectUtility.getToModel<TechnologyCategoriesResponseModel>(TechnologyCategoriesResponseModel, endpoint);
    // TODO: Handle Error
    return response.data;
  }

  /**
   * This method gets the initial Location Technology data
   * @param locationId Requires a locationId so we know which specific location we are updating
   * @returns a TechnologyCategoriesResponseModel
   */
  public static async getLocationTechnologies(locationId: string) {
    const endpoint: string = `${LocationTechnologyUtility.baseLocationsUri}/${locationId}/technologies`;
    const response = await EffectUtility.getToModel<TechnologyCategoriesResponseModel>(TechnologyCategoriesResponseModel, endpoint);
    return response;
  }

  public static async updateLocationTechnologiesVerfication(locationId: string, propertyToUpdate: object) {
    const endpoint: string = `${LocationTechnologyUtility.baseLocationsUri}/${locationId}`;

    const response = await EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate).catch(() => {
      EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate);
    });
    return response;
  }

  public static async createLocationTechnology(locationId: string, technologyId: string) {
    const endpoint: string = `${LocationTechnologyUtility.baseLocationsUri}/${locationId}/technology/${technologyId}`;
    const response = await EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint).catch(() => {
      EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint);
    });
    return response;
  }

  /**
   * This method deletes the location environment
   * @param locationId Requires a location ID so that we know which location we are changing in the database
   * @param technologyId Requires a technologyId to know which specific certification is being changed
   * @returns
   */
  public static async deleteLocationTechnology(locationId: string, technologyId: string) {
    const endpoint: string = `${LocationTechnologyUtility.baseLocationsUri}/${locationId}/technology/${technologyId}`;

    const response = await EffectUtility.deleteToModel<LocationResponseModel>(LocationResponseModel, endpoint);
    return response;
  }
}
