import DepartmentEnum from 'constants/DepartmentEnum';

export const MAIN_CONFIG = {
  CreateOrder: {
    /**
     * Department that we get attributes against
     */
    Department: DepartmentEnum.DirectBusiness,
    /**
     * The first step at which the stepper will begin calculating
     * Currently skips QTY
     */
    StartingStep: 'DEPARTMENT',
    /**
     * Currency code being used
     * Used for both ISOCurrency and currency
     */
    IsoCurrency: 'USD',
    /**
     * The Organization ID
     */
    OrgID: 'US',
    /**
     * The flag passed to the tabHelper when we want the first tab to display product cards
     * If not set to this it will display the dynamic form.
     */
    NoProduct: 'no-product',
    /**
     * These are the current fields hidden from the user during the form process
     */
    FieldsNotToBeDisplayed: ['QTY', 'SWGT', 'GRAPHICS', 'RECIPE ID', 'SERIAL #', 'SERIAL', 'DEPARTMENT'],
    /**
     * The list of non club products.
     * Currently these will be placed in the Gear section of the productCards Page
     */
    ListOfNonClubProducts: ['Accessories', 'Apparel', 'Golf Bags', 'Headwear', 'Gloves', 'Apparel'],
  },
  BasePaginatedModel: {
    pageSize: 10,
  },
  Transactions: {
    Invoice: {
      TransactionType: 'INV',
    },
  },
};
