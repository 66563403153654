import React, { useEffect, useState } from 'react';
import GoogleMap from 'google-map-react';
import environment from 'environment';
import LocationMarker from '../LocationMarker/LocationMarker';
import Styles from './Map.module.scss';

interface IProps {
  latitude: number;
  longitude: number;
  name: string;
}
interface IState {
  latitude: number;
  longitude: number;
  name: string;
}
const initialState = {
  latitude: 33.58582073366945,
  longitude: -112.10478817327474,
  name: 'PING',
};
const Map = ({ latitude, longitude, name }: IProps) => {
  const [mapState, setMapState] = useState<IState>(initialState);
  const options = { key: environment.googleMapsAPI, libraries: ['places'] };
  const defaultZoom = 15;

  useEffect(() => {
    if (latitude !== 0 && longitude !== 0) {
      setMapState({ ...mapState, latitude, longitude, name });
    }
  }, [latitude, longitude, name]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleApiLoaded = () => {
    if (latitude !== 0) {
      setMapState({ ...mapState, latitude, longitude, name });
    }
  };
  return (
    <div className={Styles['map']}>
      <GoogleMap
        bootstrapURLKeys={options}
        center={[mapState.latitude, mapState.longitude]}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={() => handleApiLoaded()}
      >
        <LocationMarker lat={mapState.latitude} lng={mapState.longitude} />
      </GoogleMap>
    </div>
  );
};

export default Map;
