import { BaseModel } from 'sjs-base-model';
import moment from 'moment';
import { IDynamicFormAttribute } from './IDynamicFormModels';

export default class GetEstimatedDatesRequestModel extends BaseModel {
  wantDate: any = moment().toDate();
  product: string;
  clubs: string[] = [];
  configuration: IDynamicFormAttribute[];

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
