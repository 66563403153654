import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import { en } from 'translations/en-US';
// import { fr } from 'translations/fr';
import { en } from 'translations/en/en';
import { fr } from 'translations/fr/fr';

const resources = {
  en,
  fr,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    resources,
    detection: {
      // navigator first to get the browsers language if we need more advanced imp like allowing the user to change lang
      // then this will need to change.
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
