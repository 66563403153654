import React from 'react';
import { ITerm } from '../models/ITerm';
import styles from './FittingTerm.module.scss';
import parse from 'html-react-parser';

interface IProps {
  term: ITerm;
}

const FittingTerm = ({ term }: IProps): JSX.Element => {
  return (
    <div className={styles.termContainer}>
      <h2 className={styles.termName}>{term.termName}</h2>
      <p className={styles.termDescription} >{parse(term.termDescription)}</p>
    </div>
  );
};

export default FittingTerm;
