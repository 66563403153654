import React from 'react';
import IAccount from 'stores/accounts/models/IAccount';
import styles from './AccountsSelected.module.scss';
import AccountLabelCard, { ILabelCardColumn, ILabelCardButton } from 'components/LabelCard/AccountLabelCard';
import { ReactComponent as NegativeIcon } from 'assets/media/icons/icon-negative.svg';
import { KenticoUser } from 'stores/users/models/IUserV3';
import { ReactComponent as Restart } from '../../../../assets/media/icons/icon-restart.svg';

interface IProps {
  selectedAccounts: IAccount[];
  onRemoveAccount: (v: IAccount) => void;
  onSubmitAccounts: any;
  closeModal: () => void;
  user: KenticoUser;
}

const AccountsSelected = ({ user, selectedAccounts, onRemoveAccount, onSubmitAccounts, closeModal }: IProps) => {
  return (
    <div className={styles.container}>
      <h5 className='hdg--5'>Manage Account Access for:</h5>
      <h3 className={`${styles.userName} hdg--3`}>{`${user.firstName} ${user.lastName}`}</h3>
      <div className={styles.accountsContainer}>
        {selectedAccounts.map((account) => {
          const columns: ILabelCardColumn[] = [
            {
              label: account.accountNumber.toString(),
              data: '',
            },
            {
              label: account.accountName,
              data: account.businessName,
            },
          ];
          const button: ILabelCardButton = {
            text: <NegativeIcon height='20px' width='20px' aria-hidden='true' focusable='false' role='img' />,
            action: () => onRemoveAccount(account),
          };

          return <AccountLabelCard key={account.accountNumber} labelCardColumns={columns} labelCardButton={button} icon />;
        })}
      </div>
      <div className={styles.buttonContainer}>
        <button className={`${styles.cancelButton} btn--no-style`} onClick={() => closeModal()}>
          Cancel
        </button>
        <button className={`btn btn__text-only btn--bold ${styles.button}`} onClick={() => onSubmitAccounts()}>
          <span>Update Linked Accounts</span>
          <Restart className='btn__svg-icon' aria-hidden='true' focusable='false' role='img' />
        </button>
      </div>
    </div>
  );
};

export default AccountsSelected;
