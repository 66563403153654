import { base64ToArrayBuffer, createAndDownloadBlobFile, createPDFUrl } from 'containers/InvoicePage/helpers/pdfDownloadHelperFunctions';
import environment from 'environment';
import EffectUtility from 'utilities/EffectUtility';
import IPDF from './models/IPDF';
import PDFResponseModel from './models/PDFResponseModel';

export default class PagesStatelessCalls {
  public static async getPDF(filePath: string) {
    const endpoint = `${(environment.api as any).pages}?filePath=${filePath}`;
    const res = await EffectUtility.getToModel(PDFResponseModel, endpoint);
    const pdfResponse: IPDF = res.data;
    const arrayBuffer = base64ToArrayBuffer(pdfResponse.byteArray);
    const pdfUrl = createPDFUrl(arrayBuffer);

    return pdfUrl;
  }

  public static async downloadFileForPage(filePath: string) {
    const endpoint = `${(environment.api as any).pages}?filePath=${filePath}`;
    const res = await EffectUtility.getToModel(PDFResponseModel, endpoint);

    const arrayBuffer = base64ToArrayBuffer(res.data.byteArray);
    const fileName = res.data.fileName;
    createAndDownloadBlobFile(arrayBuffer, fileName, 'pdf', false);
  }
}
