import IFields from 'components/Form/IFields';
import HoursUtility from './HoursUtility';


export default class LocationDaysUtility {
  /**
   * This function is to create and populate location days from Monday through Sunday with an input type of checkbox and select option
   *
   * @returns array of available times a user can select
   */
  public static capitalize(word: string):string{
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  public static populateLocationDays(state:{}): IFields {
    const fittingLocationHourArr: IFields = [];
    const tempArray = [...fittingLocationHourArr];
    const dayFieldNames: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // create timezone select
    tempArray.push({
      name: 'timeZone',
      type: 'select',
      inputType: 'select',
      label: 'Time Zone',
      notRequired: false,
      options: HoursUtility.getFittingTimeZone('timeZone'),
      defaultSelectValue: { label: '—', value: '' },
    });
    // create day field check boxes
    dayFieldNames.forEach((option, index) => {
      const selects = ['Start', 'End'];
      tempArray.push({
        name: `${option}Label`,
        type: 'checkbox',
        inputType: 'checkbox',
        label: LocationDaysUtility.capitalize(option),
        notRequired: true,
      });
      // create Hour selects
      selects.forEach((select) => {
        tempArray.push({
          name: `${option}${select}`,
          type: 'select',
          inputType: 'select',
          label: `${LocationDaysUtility.capitalize(select)=== "Start"? "From" : "To"}:`,
          notRequired: true,
          options: HoursUtility.getFittingHoursOptions(`${option}${select}`),
          defaultSelectValue: { label: '—', value: '' },
        });
      });
    });
    return tempArray;
  }

  
}
