import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { oc } from 'ts-optchain.macro';
import styles from './PrimaryNavFooter.module.scss';
import menuLinkStyles from '../PrimaryNavMenuLink/PrimaryNavMenuLink.module.scss';
import PrimaryNavMenuLink from '../PrimaryNavMenuLink/PrimaryNavMenuLink';
import { ReactComponent as LogoutIcon } from '../../../../assets/media/icons/icon-logout.svg';
import PrimaryNavMenuItem from '../PrimaryNavMenuItem/PrimaryNavMenuItem';
import RouteEnum from '../../../../constants/RouteEnum';
import IStore from 'models/IStore';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { ReactComponent as ManageUsersIcon } from 'assets/media/icons/icon-manage-users.svg';
import UserRolesEnum from 'constants/UserRolesEnum';
import { MSAL_AUTH } from 'index';
import getUserInitials from 'containers/AccountProfilePage/helpers/UserHelper';
import IUser from 'stores/users/models/IUser';

//TODO: need to remove this const and refactor where used below
const MOCK_DATA = {
  firstName: '',
  lastName: '',
};

const adminPanelMenuItem = {
  ICON: ManageUsersIcon,
  NAME: 'Admin Panel',
  URL: `${RouteEnum.AdminPanel}`,
  ACCESS: UserRolesEnum.PingAdmin,
};

interface UserProfileLinkProps {
  currentUser: IUser | null;
}

function UserProfileLink(props: UserProfileLinkProps): JSX.Element {
  const { currentUser } = props;
  const [firstName, setFirstName] = useState<string | null>();
  const [lastName, setlastName] = useState<string | null>();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser?.firstName || currentUser?.fullName?.split(' ')[0]);
      if (!currentUser.lastName) {
        var ln = currentUser?.fullName?.split(' ');
        if (ln?.length > 0) {
          setlastName(ln[ln.length - 1]);
        }
      } else {
        setlastName(currentUser.lastName);
      }
    }
  }, [currentUser]);

  return (
    <PrimaryNavMenuLink to={RouteEnum.AccountProfile}>
      <>
        <div className={styles['primaryNavFooter-userInitials']}>{currentUser !== null && getUserInitials(currentUser)}</div>
        <div className={styles['primaryNavFooter-userName']}>
          {firstName || MOCK_DATA.firstName}
          <br />
          {lastName || MOCK_DATA.lastName}
        </div>
      </>
    </PrimaryNavMenuLink>
  );
}

function LogOutButton(): JSX.Element {
  return (
    <div onClick={() => MSAL_AUTH.logout()} className={`${menuLinkStyles.primaryNav_Menu_Link} focus-ring`}>
      <LogoutIcon aria-hidden={true} focusable={false} role='img' />
      <span className={menuLinkStyles.primaryNav_Menu_Link_Text}>Log Out</span>
    </div>
  );
}

function PrimaryNavFooter(): JSX.Element {
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  return (
    <div className={styles.primaryNavFooter}>
      <UserProfileLink currentUser={currentUser && currentUser.data} />
      <PrimaryNavMenuItem navItem={adminPanelMenuItem} />
      <LogOutButton />
    </div>
  );
}

export default PrimaryNavFooter;
