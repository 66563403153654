import ICreditSummaryState from './models/ICreditSummaryState';
import CreditSummaryAction from './CreditSummaryAction';
import CreditSummaryResponseModel from './models/CreditSummaryResponseModel';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';

export default class CreditSummaryReducer extends BaseReducer<ICreditSummaryState> {
  public readonly initialState: ICreditSummaryState = {
    creditSummaryResponse: null,
  };

  public [CreditSummaryAction.REQUEST_CREDIT_SUMMARY_FINISHED](
    state: ICreditSummaryState,
    action: IAction<CreditSummaryResponseModel>
  ): ICreditSummaryState {
    return {
      ...state,
      creditSummaryResponse: action.payload!,
    };
  }
}
