import { BaseModel } from 'sjs-base-model';
import INotificationPreference from './INotificationPreference';

export default class AccountNotificationsResponseModel extends BaseModel {
  public readonly data: INotificationPreference[];

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<AccountNotificationsResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<AccountNotificationsResponseModel>): void {
    super.update(data);
  }
}
