import React, { useEffect, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { ISelectedCountryCode } from 'containers/LocationInfoPage/components/LocationDetails/components/LocationContact';
interface IPhoneProps {
  phoneNumber: string | undefined;
  countryCode: ISelectedCountryCode | undefined;
  onNumberChange: (value: any) => void;
  onCountryCodeChange?: Function | undefined;
  setErrorMessage?: Function | undefined;
}
// here, the index maps to the error code returned from getValidationError - see readme
var errorMap = ['Invalid number', 'Invalid country code', 'Too short', 'Too long', 'Invalid number'];
export const PhoneInput = (props: IPhoneProps) => {
  const { phoneNumber, countryCode, onNumberChange, onCountryCodeChange, setErrorMessage } = props;
  const [itiTelInput, setItiTelInput] = useState<any>();
  const [input, setInput] = useState<any>();
  const [options] = useState({
    allowDropdown: true,
    autoHideDialCode: false,
    nationalMode: false,
    separateDialCode: true,
    initialCountry: '',
    countryCode: '',
    formatOnDisplay: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.js',
  });
  useEffect(() => {
    const phoneInput = document.querySelector('#phone');
    if (!phoneInput) return;
    setItiTelInput(
      intlTelInput(phoneInput, {
        ...options,
      })
    );
    setInput(phoneInput);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (input) {
      input?.addEventListener('countrychange', function() {
        var selectedCountryObject = (window as any).intlTelInputGlobals.getInstance(input).selectedCountryData;
        var selectedCountry: ISelectedCountryCode = {
          countryCodeName: selectedCountryObject.iso2,
          countryCodeNumber: '+' + selectedCountryObject.dialCode,
        };
        onCountryCodeChange && onCountryCodeChange(selectedCountry);
      });
      input?.addEventListener('blur', function() {
        var valid = itiTelInput.isValidNumber();
        if (!valid && setErrorMessage) {
          var errorIndex = itiTelInput.getValidationError();
          var errorMsg = errorMap[errorIndex];
          setErrorMessage(errorMsg);
          return;
        }
        setErrorMessage && setErrorMessage();
      });
    }
    //eslint-disable-next-line
  }, [input]);
  useEffect(() => {
    if (itiTelInput && countryCode && countryCode.countryCodeName) {
      itiTelInput.setCountry(countryCode.countryCodeName);
    }
  }, [countryCode, itiTelInput]);

  return (
    <>
      <input value={phoneNumber} type='tel' id='phone' onChange={onNumberChange} className='form__input' />
    </>
  );
};
