import AccountsResponseModel from './models/AccountsResponseModel';
import AccountsEffect from './AccountsEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import ISortData from '../dataTable/models/ISortData';
import AccountAddressesResponseModel from './models/AccountAddressesResponseModel';
import AccountNotificationsResponseModel from './models/AccountNotificationsResponseModel';
import AccountUpdateUserResponseModel from './models/AccountUpdateUserResponseModel';
import IAccount from './models/IAccount';
import AccountInfoResponseModel from './models/AccountInfoResponseModel';

type ActionUnion =
  | void
  | HttpErrorResponseModel
  | AccountsResponseModel
  | AccountAddressesResponseModel
  | AccountNotificationsResponseModel
  | AccountUpdateUserResponseModel
  | AccountInfoResponseModel
  | IAccount;

export default class AccountsAction {
  public static readonly REQUEST_ACCOUNT_RESPONSE: string = 'AccountsAction.REQUEST_ACCOUNT_RESPONSE';
  public static readonly REQUEST_ACCOUNT_INFO: string = 'AccountsAction.REQUEST_ACCOUNT_INFO';
  public static readonly REQUEST_ACCOUNT_INFO_FINISHED: string = 'AccountsAction.REQUEST_ACCOUNT_INFO_FINISHED';
  public static readonly REQUEST_ACCOUNT_RESPONSE_FINISHED: string = 'AccountsAction.REQUEST_ACCOUNT_RESPONSE_FINISHED';
  public static readonly UPDATE_ACCOUNTS_UI: string = 'AccountsAction.UPDATE_ACCOUNTS_UI';
  public static readonly SET_ACTIVE_ACCOUNT: string = 'AccountsAction.SET_ACTIVE_ACCOUNT';
  public static readonly GET_ACTIVE_ACCOUNT: string = 'AccountsAction.GET_ACTIVE_ACCOUNT';
  public static readonly REQUEST_ACCOUNT_ADDRESSES: string = 'AccountsAction.REQUEST_ACCOUNT_ADDRESSES';
  public static readonly REQUEST_ACCOUNT_ADDRESSES_FINISHED: string = 'AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED';
  public static readonly REQUEST_ACCOUNT_NOTIFICATIONS: string = 'AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS';
  public static readonly REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED: string = 'AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED';
  public static readonly REQUEST_ACCOUNT_USERS: string = 'AccountsAction.REQUEST_ACCOUNT_USERS';
  public static readonly REQUEST_ACCOUNT_USERS_FINISHED: string = 'AccountsAction.REQUEST_ACCOUNT_USERS_FINISHED';

  public static requestAccountResponse(pageSize: number, page: number, filter: string, sortData: ISortData): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<AccountsResponseModel>(
        dispatch,
        AccountsAction.REQUEST_ACCOUNT_RESPONSE,
        AccountsEffect.requestAccountResponse,
        pageSize,
        page,
        filter,
        sortData
      );

      dispatch(
        AccountsAction.updateAccountsUI({
          filter,
          sortData,
        })
      );
    };
  }

  public static requestAccountInfo(accountNum: number): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<AccountInfoResponseModel>(
        dispatch,
        AccountsAction.REQUEST_ACCOUNT_INFO,
        AccountsEffect.requestAccountInfo,
        accountNum
      );
    };
  }

  public static requestAccountAddresses(accountNumber: number | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion | null>, getState: () => IStore) => {
      if (accountNumber === null) {
        dispatch({ type: AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED, payload: null });
      } else {
        dispatch({ type: AccountsAction.REQUEST_ACCOUNT_ADDRESSES });

        const responseModel: AccountAddressesResponseModel | HttpErrorResponseModel = await AccountsEffect.requestAccountAddresses(accountNumber);

        dispatch({
          type: AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED,
          payload: responseModel,
          error: responseModel instanceof HttpErrorResponseModel,
        });
      }
    };
  }

  public static requestAccountNotifications(accountNumber: number): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS });

      const responseModel: AccountNotificationsResponseModel | HttpErrorResponseModel = await AccountsEffect.requestAccountNotifications(
        accountNumber
      );

      dispatch({
        type: AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED,
        payload: responseModel,
        error: responseModel instanceof HttpErrorResponseModel,
      });
    };
  }

  public static updateAccountsUI(payload: any): any {
    return {
      type: AccountsAction.UPDATE_ACCOUNTS_UI,
      payload,
    };
  }

  public static setActiveAccount(payload: IAccount): any {
    if (payload.accountName) {
      const localObject = {
        accountNumber: Number(payload.accountNumber),
        accountName: payload.accountName.toString(),
        businessName: payload.businessName.toString(),
      };
      localStorage.setItem('accountDetail', JSON.stringify(localObject));
    }

    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch(AccountsAction.requestAccountInfo(payload.accountNumber));
      dispatch({
        type: AccountsAction.SET_ACTIVE_ACCOUNT,
        payload,
      });
    };

    // return {
    //   type: AccountsAction.SET_ACTIVE_ACCOUNT,
    //   payload,
    // };
  }

  public static getActiveAccountLocal(): any {
    const localAccountDetails = localStorage.getItem('accountDetail');
    let localAccount: IAccount | null = null;
    if (localAccountDetails != null) {
      localAccount = JSON.parse(localAccountDetails);
    }

    console.log({ localAccount });

    return {
      localAccount,
    };
  }
}
