import React from 'react';
import { useEffect } from 'react';
//TODO: Create reusable radio button component
import styles from '../LocationDetails.module.scss';
export default function LocationDisplayOnMap({ displayOnMap, handleChange }) {
  const [isDisplayOnMap, setIsDisplayOnMap] = React.useState(displayOnMap);
  useEffect(() => {
    setIsDisplayOnMap(displayOnMap);
  }, [displayOnMap]);
  const handleDisplayOnMapChange = (value) => {
    if (value !== isDisplayOnMap) {
      handleChange(value);
      setIsDisplayOnMap(value);
    }
  };
  return (
    <div>
      <div className='hdg hdg--4 form_title'>Location Visability</div>
      <button onClick={() => handleDisplayOnMapChange(true)} className={` radio-btn-container`}>
        <div>
          <div className={`radio-btn ${isDisplayOnMap && 'radio-btn--selected'}`} />
        </div>
        <p className={`${styles['radio-button-label']} ${styles['inline']}`}>
          <p className={`bold ${styles['inline']}`}>Display on Map</p>: Location will be displayed publicly on all PING Fitter Maps.
        </p>
      </button>
      <button onClick={() => handleDisplayOnMapChange(false)} className={`radio-btn-container ${styles['radio-padding']}`}>
        <div>
          <div className={`radio-btn ${!isDisplayOnMap && 'radio-btn--selected'}`} />
        </div>
        <p className={`${styles['radio-button-label']} ${styles['inline']}`}>
          <p className={`bold ${styles['inline']}`}>Hide from Map</p>: Location will not be displayed on PING Fitter Maps.
        </p>
      </button>
    </div>
  );
}
