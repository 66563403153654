import PaginatedRequestModel from "stores/pagination/IPaginatedRequestModel";

export default class HourCreateRequestModel extends PaginatedRequestModel{
    locationId : string;
    timeZone?: string;
    mondayStart?:string;
    mondayEnd?:string;
    tuesdayStart?:string;
    tuesdayEnd?:string;
    wednesdayStart?:string;
    wednesdayEnd?:string;
    thursdayStart?:string;
    thursdayEnd?:string;
    fridayStart?:string;
    fridayEnd?:string;
    saturdayStart?:string;
    saturdayEnd?:string;
    sundayStart?:string;
    sundayEnd?:string;

constructor(data:{}){
    super();
    this.update(data);
}

public update(data: any): void{
    super.update(data);
}
}