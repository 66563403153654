import AccountsOverlay from 'components/AccountsOverlay/AccountsOverlay';
import Modal from 'components/Modal/Modal';
import { push } from 'connected-react-router';
import RouteEnum from 'constants/RouteEnum';
import IStore from 'models/IStore';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

const AccountsOverlayPage = () => {
  const dispatch: Dispatch = useDispatch();
  const previousPath: string = useSelector((state: IStore) => state.navigation.currentAndPreviousPaths.previous);

  const onCloseClick = () => {
    if (previousPath && previousPath !== RouteEnum.Landing) {
      return dispatch(
        push({
          pathname: previousPath,
        })
      );
    }
    dispatch(
      push({
        pathname: RouteEnum.AccountProfile,
      })
    );
  };
  return (
    <Modal onClose={onCloseClick} aria-labelledby='modalTitle'>
      <AccountsOverlay />
    </Modal>
  );
};

export default AccountsOverlayPage;
