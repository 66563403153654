import CreateOrderAction from './CreateOrderAction';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';
import ICreateOrderState from './models/ICreateOrderState';
import GetCartResponseModel from './models/GetCartResponseModel';
import { MAIN_CONFIG } from 'configurations/mainConfig';

export default class CreateOrderReducer extends BaseReducer<ICreateOrderState> {
  public readonly initialState: ICreateOrderState = {
    cartState: null,
    tabState: {
      hasItems: false,
      hasReview: false,
      hasShipping: false,
      hasConfirm: false,
      tabIndex: 0,
      product: MAIN_CONFIG.CreateOrder.NoProduct,
      itemToEditId: null,
    },
    productConfigs: {
      METAL_WOODS: null,
      HYBRIDS: null,
      IRONS: null,
      APPAREL: null,
      ACCESSORIES: null,
      HEADWEAR: null,
    },
    originalProductConfigs: {
      METAL_WOODS: null,
      HYBRIDS: null,
      IRONS: null,
      APPAREL: null,
      ACCESSORIES: null,
      HEADWEAR: null,
    },
    submittedLists: {
      METAL_WOODS: null,
      HYBRIDS: null,
      IRONS: null,
      APPAREL: null,
      ACCESSORIES: null,
      HEADWEAR: null,
    },
  };

  public [CreateOrderAction.REQUEST_GET_CART_FINISHED](state: ICreateOrderState, action: IAction<GetCartResponseModel>): ICreateOrderState {
    console.log(action.payload);

    if (action.payload === null) {
      return {
        ...state,
        cartState: null,
      };
    }

    const cartState = action.payload?.data || null;
    const setIdDuplicates: string[] = [];
    const newCartItems = cartState?.cartItems
      .filter((item, index) => {
        if (!setIdDuplicates.includes(item.setID) || item.setID === '00000000-0000-0000-0000-000000000000') {
          setIdDuplicates.push(item.setID);
          return true;
        }

        return false;
      })
      .map((item) => {
        const validateJson = () => {
          try {
            const valid = JSON.parse(item.description);
            return valid;
          } catch {
            return null;
          }
        };
        const descriptionObject = validateJson();
        const newDescription = descriptionObject
          ? Object.values(descriptionObject)
              .filter((i) => i !== 'None' || null)
              .join(' ')
          : '';
        return {
          ...item,
          description: newDescription,
          itemSetCount: descriptionObject && 'CLUBS' in descriptionObject ? descriptionObject['CLUBS'].length : 1,
        };
      });
    if (cartState && newCartItems) {
      cartState.cartItems = newCartItems;
    }

    return {
      ...state,
      cartState,
    };
  }

  public [CreateOrderAction.REQUEST_GET_ESTIMATED_DATE_FINISHED](state: ICreateOrderState, action: any) {
    const newState = state.cartState?.cartItems.map((item) => {
      const foundItem = action.payload.find((actionItem) => {
        return actionItem.cartItemId === item.cartItemID;
      });

      if (foundItem) {
        return {
          ...item,
          estimatedDate: foundItem.date ? foundItem.date : 'error',
        };
      }

      return {
        ...item,
      };
    });

    return {
      ...state,
      cartState: {
        ...state.cartState,
        cartItems: newState,
      },
    };
  }

  public [CreateOrderAction.STORE_PRODUCT_CONFIG](state: ICreateOrderState, action: any): ICreateOrderState {
    const formattedProduct = action.product.replace(' ', '_');
    if (action.storingOriginal) {
      return {
        ...state,
        originalProductConfigs: { ...state.originalProductConfigs, [formattedProduct]: action.productConfig },
      };
    }

    if (action.storingSubmitted) {
      return {
        ...state,
        submittedLists: { ...state.submittedLists, [formattedProduct]: action.productConfig },
      };
    }

    return {
      ...state,
      productConfigs: { ...state.productConfigs, [formattedProduct]: action.productConfig },
    };
  }

  public [CreateOrderAction.STORE_TAB_STATE](state: ICreateOrderState, action: any): ICreateOrderState {
    return {
      ...state,
      tabState: action.payload,
    };
  }
}
