import { openModalDynamic } from 'containers/OrderDetailPage/components/OrderItems/helpers/OrderItemsHelperComps';
import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import style from './UserAccountCardMenu.module.scss';
import { ReactComponent as KebobMenu } from 'assets/media/icons/icon-kebob-menu.svg';
import IStore from 'models/IStore';
import UsersAction, { IRolesFilterState } from 'stores/users/UsersAction';
import { useParams } from 'react-router-dom';
import UserUtility from 'utilities/UserUtility/UserUtility';
import LinkUnlinkUserRequestModel from 'utilities/UserUtility/models/LinkUnlinkUserRequestModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { KenticoUser } from 'stores/users/models/IUserV3';
import UsersRequestModel from 'stores/users/models/UsersRequestModel';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';

interface IProps {
  accountNumberToDetach: number;
  selectedUser: KenticoUser;
  fromAdmin: boolean;
  setIsLoading: any;
}

const UsersAccountCardMenu = ({ setIsLoading, selectedUser, accountNumberToDetach, fromAdmin }: IProps) => {
  const dispatch: Dispatch = useDispatch();
  const { accountNumber } = useParams();
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => state.users.rolesFilters);

  const handleDetach = () => {
    setIsLoading(true);
    const requestConfig = new LinkUnlinkUserRequestModel({
      userId: selectedUser.userId,
      accountNumbers: [accountNumberToDetach],
    });
    UserUtility.unlinkUser(requestConfig).then((res) => {
      if (res instanceof HttpErrorResponseModel) {
        return;
      }
      dispatch(UsersAction.requestUserAccounts(selectedUser.email)).then(() => {
        const config = new UsersRequestModel({
          accountNumber,
          membership: rolesFilter ? rolesFilter.manageUserFilter : '',
        });
        dispatch(UsersAction.requestAccountUsers(config)).then(() => {
          dispatch(ToastsAction.add(`Unlinked user from Account: ${accountNumberToDetach}`, ToastStatusEnum.Success));
          setIsLoading(false);
        });
      });
    });
  };
  return (
    <UserAccountsMenuPopper>
      <div className={style.userAccountMenuContentWrapper}>
        <button onClick={() => handleDetach()} className={style.userAccountMenu__button}>
          Unlink from User
        </button>
      </div>
    </UserAccountsMenuPopper>
  );
};

interface IPopper {
  children: any;
}
function UserAccountsMenuPopper({ children }: IPopper) {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const yoRef = useRef<any>(null);
  const { styles, attributes } = usePopper<any>(referenceElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  return (
    <div>
      <button className={style.userAccountMenu__icon} onClick={() => openModalDynamic(setOpen, isOpen, yoRef)} ref={setReferenceElement}>
        <KebobMenu />
      </button>
      {isOpen && (
        <div ref={yoRef}>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
            }}
            className='menu'
            {...attributes.popper}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersAccountCardMenu;
