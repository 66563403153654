import { MAIN_CONFIG } from 'configurations/mainConfig';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import { IDynamicFormTaxRequest } from 'stores/create-order/models/IDynamicFormModels';
import { IGetCartResponse } from 'stores/create-order/models/IGetCart';

interface IProps {
  cartState: IGetCartResponse;
  accountNumber: number;
  setTax: any;
  shipping: any;
}

/**
 * Makes a call to the tax api to get a tax
 *
 * @param cart
 * @param accountNumber
 * @param setTax
 */
export default function getTax({ cartState, accountNumber, setTax, shipping }: IProps) {
  // const shipping = cartState.cart.ShippingAddress;
  // TODO: comeback and fix setcount logic once we have description
  const cartItems = cartState.cartItems.map((item, index) => {
    return {
      extendedPrice: Number(item.price) * item.quantity, // change the 1
      isShipping: false,
      lineItemId: index + 1,
      product: item.type,
    };
  });

  const requestConfig: IDynamicFormTaxRequest = {
    isoCurrencyCode: MAIN_CONFIG.CreateOrder.IsoCurrency,
    orderNumber: 0,
    customer: {
      streetAddress: shipping.address1,
      city: shipping.city,
      postalCode: shipping.zip,
      state: shipping.state,
      country: shipping.country,
    },
    lineItems: cartItems,
  };

  if (requestConfig.lineItems.length > 0) {
    CreateOrderStatelessCalls.getTax(requestConfig, accountNumber)
      .then((res) => {
        const taxResponse = res.data.data;

        setTax(taxResponse.totalTax);
      })
      .catch((err) => console.log(err));
  }
}
