import { LANG } from '../LangKeys';

export const fr = {
  common: {
    cool: 'test',
  },
  dashBoard: {
    [LANG.goodMorning]: 'bonjour',
    [LANG.goodAfternoon]: 'bonjour',
    [LANG.goodEvening]: 'bonsoir',
  },
};
