import * as React from 'react';
import Value from '../../../../components/Value/Value';
import IInvoices from '../../../../stores/invoices/models/IInvoices';
import moment from 'moment';
import { Dispatch } from 'redux';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import styles from '../TransactionHistoryTable/TransactionHistoryTable.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceAction from 'stores/invoices/InvoicesAction';
import ITransactionReferenceStructured from 'stores/invoices/models/ITransactionReferenceStructured';
import AcceptedCheckboxTransactionTypes from 'constants/AcceptedCheckboxTransactionTypesArr';
import IStore from 'models/IStore';
import { getLink } from './TransactionHistoryItemHelpers';
import TextWithSubtext from 'components/TextWithSubtext/TextWithSubtext';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  columns: any;
  rowData: IInvoices;
  currency: string;
}

export default function TransactionHistoryItem(props: IProps) {
  const dispatch: Dispatch = useDispatch();

  const pdfChecklist = useSelector((state: IStore) => state.invoices.pdfChecklistResponse);

  const { rowData, currency } = props;
  const {
    referenceNumber,
    transactionDate,
    transactionType,
    purchaseOrderNumber,
    amount,
    openBalance,
    transactionStatus,
    transactionDueDate: { pastDue, transactionDueDate, pastDueCategory },
  } = rowData;

  // const acceptedCheckboxTransactionTypes = ['Invoice', 'Credit Memo', 'Debit Memo'];

  const handleCheckboxClick = (data: ITransactionReferenceStructured | any) => {
    if (data.transactionReferenceNumber) {
      dispatch(InvoiceAction.invoiceCheckbox(data.transactionReferenceNumber, true));
    }
  };

  return (
    <>
      <td className={styles['transaction-history-table__row__cell']}>
        <div className={styles['transaction-history-table__row__cell--center-vert']}>
          {AcceptedCheckboxTransactionTypes.includes(referenceNumber.transType) ? (
            <input
              className={styles['transaction-history-table__checkbox']}
              onChange={() => handleCheckboxClick(referenceNumber)}
              type='checkbox'
              checked={pdfChecklist.includes(referenceNumber.transactionReferenceNumber)}
              title='Check this checkbox to add this transaction to the download list.'
              id={referenceNumber.transactionReferenceNumber}
              name={referenceNumber.transactionReferenceNumber}
              value={referenceNumber.transactionReferenceNumber}
            />
          ) : (
            <div className={styles['transaction-history-table__no-checkbox-spacer']} />
          )}

          {referenceNumber.transactionReferenceNumber &&
            getLink(
              transactionType,
              referenceNumber.transactionReferenceNumber,
              referenceNumber.transNo,
              transactionDate,
              referenceNumber.accountNumber
            )}
        </div>
      </td>
      <td className={styles['transaction-history-table__row__cell']}>
        {transactionDate && moment(transactionDate).format(DateFormatsEnum.WrittenDayMonthYear)}
      </td>
      <td className={styles['transaction-history-table__row__cell']}>{transactionType}</td>
      <td className={styles['transaction-history-table__row__cell']}>{purchaseOrderNumber && <>{purchaseOrderNumber}</>}</td>
      <td className={styles['transaction-history-table__row__cell']}>{formatCurrency(currency, amount)}</td>
      <td className={styles['transaction-history-table__row__cell']}>{formatCurrency(currency, openBalance)}</td>
      <td className={styles['transaction-history-table__row__cell']}>
        {transactionStatus && <Value value={transactionStatus} propertyName='transactionStatus'></Value>}
      </td>
      <td className={styles['transaction-history-table__row__cell']}>
        {transactionDueDate && (
          <TextWithSubtext
            pastDue={pastDue}
            text={moment(transactionDueDate).format(DateFormatsEnum.WrittenDayMonthYear)}
            subtext={pastDueCategory ? pastDueCategory : ''}
          />
        )}
      </td>
    </>
  );
}
