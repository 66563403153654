import IStore from 'models/IStore';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AccountInfoResponseModel from 'stores/accounts/models/AccountInfoResponseModel';
import IAccountInfo from 'stores/accounts/models/IAccountInfo';
import { oc } from 'ts-optchain.macro';
import environment from 'environment';
const useAccountCurrency = () => {
  const [accountCurrency, setAccountCurrency] = useState<string>(environment.defaultCurrency);
  const accountInfoResponse: AccountInfoResponseModel | null | undefined = useSelector((state: IStore) =>
    oc(state).accounts.accountsInfoResponse(null)
  );
  useEffect(() => {
    if (accountInfoResponse) {
      const accountInfo: IAccountInfo | undefined = accountInfoResponse?.data;
      if (accountInfo) {
        setAccountCurrency(accountInfo.currency);
      }
    }
  }, [accountInfoResponse]);
  return accountCurrency;
};
export default useAccountCurrency;
