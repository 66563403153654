import React from 'react';
import { Input } from 'semantic-ui-react';
import IDynamicFormValues from 'stores/create-order/IDynamicFormValues';
interface DynamicTextFieldProps {
  name: string;
  label: string;
  placeHolder: string;
  required: boolean;
  handleChange: (values: IDynamicFormValues) => void;
}
interface DynamicTextFieldState {}
class DynamicTextFieldSemantic extends React.Component<DynamicTextFieldProps, DynamicTextFieldState> {
  state = { value: '' };
  handleTextChange = (e: any, { value }) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { placeHolder, name, required } = this.props;
    return (
      <div className='form__text-container'>
        <Input
          type='text'
          value={value}
          name={name}
          required={required}
          autoComplete='off'
          placeholder={placeHolder}
          onChange={this.handleTextChange}
          onBlur={() => {
            this.props.handleChange({ Name: this.props.name, Value: this.state.value });
          }}
        />
      </div>
    );
  }
}

export default DynamicTextFieldSemantic;
