export default class NavigationAction {
  public static readonly UPDATE_CURRENT_AND_PREVIOUS_PATHS: string = 'NavigationAction.UPDATE_CURRENT_AND_PREVIOUS_PATHS';

  public static updateCurrentAndPreviousPaths(pathName: string) {
    return {
      type: NavigationAction.UPDATE_CURRENT_AND_PREVIOUS_PATHS,
      payload: pathName,
    };
  }
}
