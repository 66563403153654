import React from 'react';
import styles from 'containers/InvoicePage/InvoicePage.module.scss';

interface IProps {
  billingAddress?: any;
  shippingAddress: any;
}

const BillToShipTo = ({ billingAddress, shippingAddress }: IProps) => {
  const findBusinessName = () => {
    if (shippingAddress.business) {
      return shippingAddress.business;
    }

    if (shippingAddress.lastName) {
      return shippingAddress.lastName;
    }

    if (shippingAddress.businessName) {
      return shippingAddress.businessName;
    }

    return '';
  };

  return (
    <div className={`${styles['invoice-grid']} ${!billingAddress && styles['invoice-grid--no-margin']}`}>
      {billingAddress && (
        <div className={`${styles['invoice-grid__list__item']} ${styles.grow2}`}>
          <h1 className='hdg hdg--3 hdg--miniPadding'>Bill To:</h1>
          <p>{findBusinessName()}</p>
          <p>{billingAddress?.name}</p>
          <p>{billingAddress?.address1}</p>
          <p>
            {billingAddress?.city}, {billingAddress?.state} {billingAddress?.zip}
          </p>
        </div>
      )}
      <div className={`${styles['invoice-grid__list__item']} ${styles.grow2}`}>
        <h1 className='hdg hdg--3 hdg--miniPadding'>Ship To:</h1>
        {/* TODO: we are not getting consistent addressses and should be. */}
        <div>
          <p>{shippingAddress?.name}</p>
          <p>{findBusinessName()}</p>
          <p>
            {shippingAddress?.address1} {shippingAddress?.address2}
          </p>
          <p>
            {shippingAddress?.city}, {shippingAddress?.state} {shippingAddress?.zip}
          </p>
          <p>{shippingAddress?.country}</p>
        </div>
      </div>
    </div>
  );
};

export default BillToShipTo;
