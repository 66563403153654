enum DateFormatsEnum {
  LocalMonthDayYear = 'L',
  MonthDay = 'M/D',
  WrittenDayMonthYear = 'D MMM YYYY',
  MonthDayYear = 'MM/DD/YYYY',
  HyphensForCalls = 'M-D-YYYY',
  NewDate = 'YYYY-MM-DDTHH:mm:ss.sssZ',
}

export default DateFormatsEnum;
