import React, { useCallback, useEffect, useState } from 'react';
import styles from './LocationTitle.module.scss';
import moment from 'moment';

interface IProps {
  title: string;
  saved: boolean;
  setSaved: (isSaved: boolean) => void;
}

const LocationTitle = ({ saved, setSaved, title }: IProps) => {
  const [autoSaved, setAutoSaved] = useState('');
  const resetSaved = useCallback(() => {
    setSaved(false);
  }, [setSaved]);

  useEffect(() => {
    if (saved) {
      const timeStamp = moment().format('h:mm:ss a MM/DD/YYYY');
      setAutoSaved(timeStamp);
      resetSaved();
    }
  }, [saved, resetSaved]);

  return (
    <div className='hdg hdg--2'>
      {`Fitting Location: ${title}`}
      <div className={styles.autosaved}>{autoSaved && `Autosaved @ ${autoSaved}`}</div>
    </div>
  );
};

export default LocationTitle;
