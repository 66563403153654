import FittingLocationProgramMapping from 'constants/FittingLocationProgramMapping';
import ITechnology from 'containers/LocationInfoPage/components/TechnologyTab/models/ITechnology';
import ITechnologyCategory from 'containers/LocationInfoPage/components/TechnologyTab/models/ITechnologyCategory';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import AccountUtility from 'utilities/Account/AccountUtility';
import AccountProgramsResponseModel from 'utilities/Account/models/AccountProgramsResponseModel';
import IAccountPrograms from 'utilities/Account/models/IAccountPrograms';
import LocationCertificationUtility from './LocationCertificationUtility';
import LocationEnvironmentUtility from './LocationEnvironmentUtility';
import LocationTechnologyUtility from './LocationTechnologyUtility';
import ICertificationCategory from './models/ICertficationCategory';
import ICertification from './models/ICertification';
import IEnvironment from './models/IEnvironment';
import IEnvironmentCategory from './models/IEnvironmentCategory';

export interface ICreate {
  locationId: string;
  classificationArray: any[];
  foundMappings: string[];
  callBack: (locationid: string, classificationId: string) => void;
}
export default class LocationSetupUtility {
  /**
   * this will automatically create Certifications,Environments, and Technologies
   * for a location based on the account.
   * @param locationId locationId
   * @param accountNumber accountNumber
   */
  public static async setupAccountPrograms(locationId: string, accountNumber: string) {
    // get all account programs from the biz api
    const accountPrograms = await this.getProgramMappings(accountNumber);
    // create matching programs names arrary
    let matchingProgramNames: string[] = [];
    if (accountPrograms && accountPrograms?.length > 0) {
      // get matching program names
      matchingProgramNames = await this.getMatchingProgramNames(accountPrograms);
      // then get all classifications (certs, env, tech)
      /**
       *@todo TODO: look at refactoring this and makinging it async as well for perforamance
       * */
      if (matchingProgramNames && matchingProgramNames?.length > 0) {
        Promise.all([
          this.setCertifications(matchingProgramNames, locationId),
          this.setEnvironments(matchingProgramNames, locationId),
          this.setTechnologies(matchingProgramNames, locationId),
        ]).catch((error) => {
          console.log(error);
        });
      }
    }
  }

  /**
   * this gets the program mappings
   * @param accountNumber account Number
   * @returns string []
   */
  private static async getProgramMappings(accountNumber: string) {
    // get account Programs
    return await AccountUtility.getAccountPrograms(accountNumber).then((response: AccountProgramsResponseModel) => {
      // if we have data return the data
      if (response.data) {
        return response.data;
      }
      // if we have an error call rollbar
      if (response instanceof HttpErrorResponseModel) {
        // TODO: make rollbar event call here
      }
    });
    // if (accountPrograms) {
    //   return accountPrograms;
    // }
  }
  /**
   * this find the matchs from the account progams and the mapping file.
   * and returns the found matching fitting location classification name.
   * @param accountPrograms account Programs array
   * @returns matching programs string []
   */
  private static async getMatchingProgramNames(accountPrograms: IAccountPrograms[]) {
    // store matching Programs Array
    const matchingProgramNames: string[] = [];

    if (accountPrograms && accountPrograms?.length > 0) {
      // get data from programs response

      /**
       * loop through accountPrograms and find mappings the match in both account Programs (data just for the account)
       *  and what is in the mappings file
       */
      for (let i = 0; i < accountPrograms.length; i++) {
        const program = accountPrograms[i];
        // check if program is in mapping array.
        // find by Fitting location Classification name to be used to find the guid for the same classification by name.
        const flmClassificationName = FittingLocationProgramMapping[program.programId];
        if (flmClassificationName) {
          matchingProgramNames.push(flmClassificationName);
        }
      }
    }
    return matchingProgramNames;
  }
  /**
   * this creates an classifications
   * @param createObject ICreate object
   */
  private static create(createObject: ICreate) {
    if (createObject.classificationArray.length > 0) {
      // loop through accountPrograms to get certifications
      for (let i = 0; i < createObject.foundMappings.length; i++) {
        const foundMapping = createObject.foundMappings[i];
        const foundClassification = createObject.classificationArray.find((cls) => cls.name === foundMapping);
        if (foundClassification) {
          // make call to location api to attach certification
          createObject.callBack(createObject.locationId, foundClassification.id);
        }
      }
    }
  }
  /**
   * this sets the Certifcations and returns the remaining program mappings
   * @param matchProgramNames program mapping array
   * @param locationId location id string
   * @returns program mappings array
   */
  private static async setCertifications(matchProgramNames: string[], locationId: string) {
     const certifications: ICertification[] = [];
    // valid we have everything we need
    if (matchProgramNames.length > 0 && locationId) {
      /**
       * this gets all the categories with the certifications within the category.
       * we only need the certifications.
       */
      const certificationCategories: ICertificationCategory[] = await LocationCertificationUtility.getAllCertificationCategories();
      /**
       * @todo TODO: refactor this if at all possible.
       * this is to get the certifications out of the categories
       * loop through category to get certs get certifications array
       */
      // const certifications = certificationCategories.filter((x) => x.certifications);
      for (let i = 0; i < certificationCategories.length; i++) {
        const certificationsArray: ICertification[] = certificationCategories[i].certifications;
        if (certificationsArray.length > 0) {
          for (let j = 0; j < certificationsArray.length; j++) {
            const certification = certificationsArray[j];
            certifications.push(certification);
          }
        }
      }
      const createObject: ICreate = {
        locationId,
        classificationArray: certifications,
        foundMappings: matchProgramNames,
        callBack: LocationCertificationUtility.createLocationCertification,
      };
      this.create(createObject);
    }
  }
  /**
   * this sets the Environment and retuns the remaining program mappings
   * @param matchingProgramNames program mapping array
   * @param locationId location id string
   * @returns program mappings array
   */
  private static async setEnvironments(matchingProgramNames: string[], locationId: string) {
     const environments: IEnvironment[] = [];
    if (matchingProgramNames.length > 0) {
      const environmentCategories: IEnvironmentCategory[] = await LocationEnvironmentUtility.getAllCertificationCategories();

      // loop through category to get env
      // get environment array
      // const environments = environmentCategories.filter((x) => x.environments);
      for (let i = 0; i < environmentCategories.length; i++) {
        const environmentsArray: IEnvironment[] = environmentCategories[i].environments;
        if (environmentsArray.length > 0) {
          for (let j = 0; j < environmentsArray.length; j++) {
            const enviroment = environmentsArray[j];
            environments.push(enviroment);
          }
        }
      }
      const createObject: ICreate = {
        locationId,
        classificationArray: environments,
        foundMappings: matchingProgramNames,
        callBack: LocationEnvironmentUtility.createLocationEnvironment,
      };
      this.create(createObject);
    }
  }

  /**
   * this sets the Technonlogies and retuns the remaining program mappings
   * @param matchingProgramNames program mapping array
   * @param locationId location id string
   * @returns program mappings array
   */
  private static async setTechnologies(matchingProgramNames: string[], locationId: string) {
    const technologies: ITechnology[] = [];
    if (matchingProgramNames.length > 0) {
      const technologiesCategories: ITechnologyCategory[] = await LocationTechnologyUtility.getAllCertificationCategories();

      // loop through category to get technology
      // get technology array
      // const technologies = technologiesCategories.filter((x) => x.technologies);
      for (let i = 0; i < technologiesCategories.length; i++) {
        const technologiesArray: ITechnology[] = technologiesCategories[i].technologies;
        if (technologiesArray.length > 0) {
          for (let j = 0; j < technologiesArray.length; j++) {
            const technology = technologiesArray[j];
            technologies.push(technology);
          }
        }
      }
      const createObject: ICreate = {
        locationId,
        classificationArray: technologies,
        foundMappings: matchingProgramNames,
        callBack: LocationTechnologyUtility.createLocationTechnology,
      };
      this.create(createObject);
    }
  }
}
