import styles from './TimerModal.module.scss';

import React from 'react';
import Title from '../../components/Title/Title';
import Modal from 'components/Modal/Modal';
import AccessibilityUtility from 'utilities/AccessibilityUtility';

interface IProps {
  secondsLeft: number;
  onClickContinue: () => void;
  onClickLogout: () => void;
}
interface IState {
  unMounting: boolean;
}

export default class TimerModal extends React.PureComponent<IProps, IState> {
  public state: IState = {
    unMounting: false,
  };

  componentDidMount() {
    setTimeout(() => {
      AccessibilityUtility.focusToElement(document.getElementById('continueBtn'));
    }, 0);
  }

  public render(): JSX.Element {
    const { onClickContinue, onClickLogout } = this.props;

    if (this.props.secondsLeft === 0) {
      onClickLogout();
    }

    return (
      <Modal isFullScreen={false} aria-labelledby='modalTitle'>
        <h1 id='modalTitle'>
          <Title title={'Session Timeout'} />
        </h1>
        <p>
          Your session is about to expire. You will be logged out in <span className={styles.seconds}>{this.props.secondsLeft}</span> seconds.
        </p>
        <div className='vr vr5' />
        <div className='split split--end'>
          <div>
            <button id='continueBtn' className='btn focus-ring' type='button' onClick={onClickContinue}>
              <span className='btn__text-only'>Keep me logged in</span>
            </button>
          </div>
          <div>
            <button className='btn focus-ring' type='button' onClick={onClickLogout}>
              <span className='btn__text-only'>Log Out</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
