import styles from '../InvoicePage.module.scss';
import { Link } from 'react-router-dom';
import RouteEnum from '../../../constants/RouteEnum';
import React from 'react';
import { ReactComponent as PingLogo } from '../../../assets/media/icons/PING-logo.svg';
import moment from 'moment';
import DateFormatsEnum from '../../../constants/DateFormatsEnum';
import IInvoice from 'stores/invoices/models/IInvoice';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { DATE_HELPER } from 'index';
import BillToShipTo from 'components/BillToShipTo/BillToShipTo';
import { PingAddress } from './PingAddress';

interface IProps {
  invoiceData: IInvoice[];
  invoiceNumber: number;
  invoiceStatus: string | undefined;
}

const InvoiceHeader = ({ invoiceData, invoiceNumber, invoiceStatus }: IProps) => {
  const formattedOrderDate = moment(invoiceData[0].orderDate).format(DateFormatsEnum.WrittenDayMonthYear);
  const formattedTransactionDate = moment(invoiceData[0].transactionDate).format(DateFormatsEnum.WrittenDayMonthYear);
  const orderNumber = invoiceData[0].items[0]?.orderNumber;
  const {
    billingAddress,
    shippingAddress,
    accountNumber,
    purchaseOrderNumber,
    termsDescription,
    salesRepresentativeNumber,
    carrier,
    invoiceDueDate,
  } = invoiceData[0];
  return (
    <div>
      <div className={styles['invoice-grid']}>
        <div className={`${styles['invoice-grid__list__item']} ${styles.grow3}`}>
          <div className={styles.logo}>
            <span className={styles.logo__label}>PING</span>
            <PingLogo aria-hidden='true' focusable='false' role='img' />
          </div>
          <h1 className='hdg hdg--3 hdg--miniPadding'>A Karsten MFG. Corp. Subsidiary</h1>
          <PingAddress />
        </div>
        <div className={styles['invoice-grid__list__item']}>
          <div className={styles['invoice-details']}>
            <h3 className='hdg hdg--3 hdg--miniPadding'>Invoice - {invoiceNumber}</h3>
            <p className={styles['invoice-details__date']}>Invoice Date - {formattedTransactionDate}</p>
          </div>

          <div className={styles.wrapper}>
            <div className={styles.statusBar} data-transactionstatus={invoiceStatus}></div>
            <div className={styles.content}>
              <p className={styles.status} data-transactionstatus={invoiceStatus}>
                Due Date
              </p>
              {invoiceDueDate ? (
                <p className={styles.balance}>{DATE_HELPER.getDisplayDate(invoiceDueDate)}</p>
              ) : (
                <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 15 }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <BillToShipTo billingAddress={billingAddress} shippingAddress={shippingAddress} />
      <dl className={styles['invoice-grid']}>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Customer Number</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{accountNumber ? accountNumber : ''}</dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>P.O. Number</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{purchaseOrderNumber ? purchaseOrderNumber : ''}</dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Order Number</dt>
          <dd className={styles['invoice-grid__list__item__value']}>
            {/* Need to look at this */}
            {orderNumber ? (
              <Link className='focus-ring' to={`${RouteEnum.Orders}/${orderNumber}`}>
                {orderNumber}
              </Link>
            ) : (
              ''
            )}
          </dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Order Date</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{formattedOrderDate}</dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card} ${styles.grow2}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Shipping</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{carrier ? `${carrier.name} ${carrier.priority}` : ''}</dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card} ${styles.grow2}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Terms</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{termsDescription ? termsDescription : ''}</dd>
        </div>
        <div className={`${styles['invoice-grid__list__item']} ${styles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>SLS</dt>
          <dd className={styles['invoice-grid__list__item__value']}>{salesRepresentativeNumber ? salesRepresentativeNumber : '0'}</dd>
        </div>
      </dl>
    </div>
  );
};

export default InvoiceHeader;
