import environment from 'environment';
import EffectUtility from '../EffectUtility';
import StateNames from 'constants/StatesNamesArr';
import { ISelectOption } from 'components/Form/ISelectOption';
import AddressRequestModel from './models/Address/AddressRequestModel';
import AddressResponseModel from './models/Address/AddressResponseModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import Geocode from 'react-geocode';
import UpdateAddressRequestModel from './models/Address/UpdateAddressRequestModel';

export default class LocationAddressUtility {
  private static baseAddressUri: string = `${(environment.api as any).address}`;
  public static getStateLabel(state: string): string {
    // get the state label by the state abbrevation
    const label: string | undefined = StateNames.find((item: ISelectOption) => item.value === state)?.label;
    return label === undefined ? '' : label;
  }
  /**
   * this return state Options
   * @param state string
   * @returns ISelectOption[]
   */
  public static getStateOptions(state: string): ISelectOption[] {
    const options: ISelectOption[] = [];
    return options;
  }
  public static async createAddressLocation(requestAddressBody: AddressRequestModel): Promise<AddressResponseModel | HttpErrorResponseModel> {
    const endpoint: string = LocationAddressUtility.baseAddressUri;
    return EffectUtility.postToModelBody<AddressResponseModel>(AddressResponseModel, endpoint, requestAddressBody);
  }
  public static async requestAddressByLocationId(locationId: string): Promise<AddressResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${LocationAddressUtility.baseAddressUri}/location/${locationId}`;
    return EffectUtility.getToModel<AddressResponseModel>(AddressResponseModel, endpoint);
  }
  public static async updateAddressByAddressId(
    addressId: string,
    updateRequestBody: UpdateAddressRequestModel
  ): Promise<AddressResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).address}/${addressId}`;
    return EffectUtility.putToModelBody<AddressResponseModel>(AddressResponseModel, endpoint, updateRequestBody);
  }
  public static validate(addressRequest: AddressRequestModel): AddressRequestModel {
    const result = addressRequest;
    const locationTypesArr = ['ROOFTOP', 'RANGE_INTERPOLATED', 'GEOMETRIC_CENTER'];
    const addressInput: string = `${addressRequest.street} ${addressRequest.city} ${addressRequest.state} ${addressRequest.postalCode} ${addressRequest.country}`;
    // Google Geo Api Key setup
    Geocode.setApiKey(`${environment.geoCodeAPI}`);
    // Call to get Geo Code from Address
    Geocode.fromAddress(addressInput)
      .then((response) => {
        response.results.forEach((place) => {
          const locationType: string = place.geometry.location_type;
          if (locationTypesArr.indexOf(locationType) > -1) {
            result.latitude = place.geometry.location.lat;
            result.longitude = place.geometry.location.lng;
          }
        });
      })
      .catch((error) => {
        console.log('error:', error);
      });
    return result;
  }
}
