import React from 'react';
import ArrowLink from '../../../components/ArrowLink/ArrowLink';
import { useSelector } from 'react-redux';
import IStore from '../../../models/IStore';
import { oc } from 'ts-optchain.macro';
import Case from 'case';

function UserRoleDescEditPages() {
  const currentAndPreviousPaths = useSelector((state: IStore) => oc(state).navigation.currentAndPreviousPaths({ current: '', previous: '' }));
  const backLinkUrl = currentAndPreviousPaths.previous;
  return (
    <div className='split split--centered vr1_5'>
      <ArrowLink position='back' classNames='vr1_5' to={backLinkUrl}>
        {`Back to ${Case.capital(currentAndPreviousPaths.previous.replace('/', '').replace('-', ' '))}`}
      </ArrowLink>
    </div>
  );
}

export default UserRoleDescEditPages;
