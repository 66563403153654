import sanitizeHtml from 'sanitize-html';
interface ISanitzeOptions {
  allowedTags?: string[];
  allowedAttributes?: string[];
}
export default function sanatizeHtmlString(htmlString: string, allowedTags: string[] = [], allowedAttributes: string[] = []): string {
  var sanitizeOptions: ISanitzeOptions = {};
  if (allowedTags.length > 0) {
    sanitizeOptions.allowedTags = allowedTags;
  }
  if (allowedAttributes.length > 0) {
    sanitizeOptions.allowedAttributes = allowedAttributes;
  }
  return sanitizeHtml(htmlString, sanitizeOptions);
}
