import { BaseModel } from 'sjs-base-model';
import IAccountInfo from './IAccountInfo';

export default class AccountInfoResponseModel extends BaseModel {
  public readonly data: IAccountInfo;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<AccountInfoResponseModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<AccountInfoResponseModel>): void {
    super.update(data);
  }
}
