import PaginatedResponseModel from '../../pagination/IPaginatedResponseModel';
import IInvoice from './IInvoice';

/*
    // Returned Api Data Sample
    {
      "data": IInvoice[],
      ...PaginatedResponseModel
    }
 */
export default class InvoiceResponseModel extends PaginatedResponseModel<IInvoice> {}
