import styles from './InvoicePage.module.scss';

import React, { useEffect, useState } from 'react';

import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import InvoicesAction from '../../stores/invoices/InvoicesAction';
import InvoiceResponseModel from '../../stores/invoices/models/InvoiceResponseModel';
import IStore from '../../models/IStore';
import { oc } from 'ts-optchain.macro';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import moment from 'moment';
import RouteEnum from 'constants/RouteEnum';
import ArrowLink from '../../components/ArrowLink/ArrowLink';
import { ReactComponent as DownloadArrow } from '../../assets/media/icons/icon-download.svg';
import IAccount from '../../stores/accounts/models/IAccount';
import ProductList from './components/productList';
import InvoiceHeader from './components/invoiceHeader';
import InvoiceTotals from './components/invoiceTotals';
import OrdersAction from '../../stores/orders/OrdersAction';
import IMatchTransaction from 'stores/invoices/models/IMatchTransaction';
import { downloadPDF } from './helpers/pdfDownloadHelperFunctions';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import InvoicesRequestModel from 'stores/invoices/models/InvoicesRequestModel';
import InvoicesResponseModel from 'stores/invoices/models/InvoicesResponseModel';
import Value from 'components/Value/Value';
import { pageLoader } from 'components/PageLoader/PageLoader';
import MatchedTransactions from './components/matchedTransactions';
import { MAIN_CONFIG } from 'configurations/mainConfig';

const InvoicePage = () => {
  const dispatch: Dispatch = useDispatch();
  const invoice4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [
      InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED,
      InvoicesAction.REQUEST_INVOICE_FINISHED,
      OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED,
      InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED,
    ]
  );
  const { id } = useParams<any>();
  const invoiceNumber = id;
  const pdfChecklist = useSelector((state: IStore) => state.invoices.pdfChecklistResponse);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const invoicesResponseForInvoice: InvoicesResponseModel | null = useSelector((state: IStore) => state.invoices.invoicesResponseForInvoice);
  const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const invoiceResponse: InvoiceResponseModel | null = useSelector((state: IStore) => oc(state).invoices.invoiceResponse(null));
  const transactionData = invoiceResponse?.data;
  const matched: IMatchTransaction[] | undefined = useSelector((state: IStore) => oc(state).invoices.matchTransactionsResponse.data(undefined));

  useEffect(() => {
    if (accountNumber && invoicesResponseForInvoice?.data[0]) {
      /**
       * TODO: getting transactions from invoicesResponseForInvoice should we be doing this?
       * In order to not we will need
       * transactionDate
       * transactionNumber
       * transactionType
       * transactionStatus
       * pastDue
       **/
      const { transactionNumber, transactionDate } = invoicesResponseForInvoice?.data[0];
      // dispatch match
      dispatch(
        InvoicesAction.requestMatchTransactions(
          accountNumber,
          transactionNumber,
          MAIN_CONFIG.Transactions.Invoice.TransactionType,
          moment(transactionDate).format(DateFormatsEnum.HyphensForCalls)
        )
      );
    }
  }, [dispatch, accountNumber, invoicesResponseForInvoice]);

  useEffect(() => {
    if (accountNumber) {
      dispatch(InvoicesAction.requestInvoice(invoiceNumber));
      const transactionsRequestModel = new InvoicesRequestModel({
        referenceId: invoiceNumber,
      });
      dispatch(InvoicesAction.requestInvoicesInfoForInvoice(transactionsRequestModel, accountNumber, setInvoicesLoading));
    }

    return function cleanup() {
      dispatch(InvoicesAction.resetInvoicesForInvoice(null));
      dispatch(InvoicesAction.resetInvoice(null));
      dispatch(OrdersAction.resetOrder(null));
      dispatch(InvoicesAction.resetMemo(null));
    };
  }, [dispatch, accountNumber, invoiceNumber]);

  const addPdfToList = (invoiceNumber) => {
    dispatch(InvoicesAction.invoiceCheckbox(invoiceNumber));
  };

  const invoiceStatus: string | undefined = invoicesResponseForInvoice?.data[0].transactionStatus;
  const pastDue = invoicesResponseForInvoice?.data[0].pastDue;

  if (!activeAccount) {
    return pageLoader(activeAccount);
  }
  return (
    <AccessControl allowedRole={UserRolesEnum.ViewTransactions} noAccessRedirect={true}>
      <div>
        <div className='wrapper'>
          <div>
            <ArrowLink position='back' classNames='vr1_5' to={RouteEnum.Transactions}>
              Back to Invoice Summary
            </ArrowLink>
          </div>
          <div className='split'>
            <h1 className={`hdg hdg--1 ${styles.invoiceHeader}`}>
              Invoice - {invoiceNumber}
              {invoicesLoading ? (
                <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 15 }} />
              ) : (
                <>
                  <Value value={invoiceStatus} propertyName='transactionStatus' />
                  {pastDue && <Value value={'PAST DUE'} propertyName='transactionStatus' />}
                </>
              )}
            </h1>
            <div className={styles.invoiceHeader__right}>
              {pdfLoading ? (
                <div className={'btn--loading'}>
                  <LoadingIndicator isActive={true} inverted={true} noLoadingText={true} />
                </div>
              ) : (
                <button onClick={() => downloadPDF(invoiceNumber, accountNumber, setPdfLoading)} className={`btn btn__icon ${styles.btn__download}`}>
                  <span className={styles.btn__download__label}>Download Invoice {pdfLoading && 'loading'}</span>
                  <DownloadArrow width='16' height='26' aria-hidden='true' focusable='false' role='img' />
                </button>
              )}
              <button
                type='button'
                className={`btn btn__text-only ${pdfChecklist.includes(invoiceNumber) && 'btn--delete-user'}`}
                onClick={() => addPdfToList(invoiceNumber)}
              >
                {pdfChecklist.includes(invoiceNumber) ? 'Remove From PDF download list' : 'Add to PDF download list'}
              </button>
            </div>
          </div>
          <div className={styles.invoiceGrid}>
            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_FINISHED']}>
              {transactionData ? (
                <InvoiceHeader invoiceData={transactionData} invoiceNumber={invoiceNumber} invoiceStatus={pastDue ? 'PAST DUE' : invoiceStatus} />
              ) : (
                <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />
              )}
            </Error4xx>

            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_FINISHED']}>
              {transactionData && <ProductList invoiceData={transactionData} accountNumber={accountNumber} invoiceResponse={invoiceResponse} />}
            </Error4xx>

            <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_INVOICE_FINISHED']}>
              {transactionData && <InvoiceTotals invoiceData={transactionData} />}
            </Error4xx>
          </div>
        </div>

        {invoice4xxResponses['InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED'] && <h3 className='hdg hdg--3'>Matched Transactions</h3>}
        <Error4xx response={invoice4xxResponses['InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED']}>
          <MatchedTransactions transactions={matched} accountNumber={accountNumber} invoiceNumber={invoiceNumber} />
        </Error4xx>
      </div>
    </AccessControl>
  );
};
export default InvoicePage;
