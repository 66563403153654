const labelFontSize = '20px';

const SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
    width: '100%',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '150px',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '2px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
  }),
  singleValue: (provided) => ({
    ...provided,
    poition: 'absolute',
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
  }),
  input: (provided) => ({
    ...provided,
    poition: 'absolute',
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
    marginTop: '20px',
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '66px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginBottom: '0',
    marginTop: '0',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 24px',
    color: '#0082d3',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    width: '69px',
  }),
};

export default SelectStyles;
