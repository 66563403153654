import ISearchState from './models/ISearchState';
import SearchAction from './SearchAction';
import BaseReducer from 'utilities/BaseReducer';
import IAction from 'models/IAction';
import InvoiceResponseModel from 'stores/invoices/models/InvoiceResponseModel';
import OrdersResponseModel from 'stores/orders/models/OrdersResponseModel';
import CombinedResponse from './models/ICombinedResponse';
import TagNameSearchResponseModel from './models/TagNameSearchResponseModel';

export default class SearchReducer extends BaseReducer<ISearchState> {
  public readonly initialState: ISearchState = {
    searchResponseModel: null,
    orderItemsByTagName: null,
  };
  public [SearchAction.REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL_FINISHED](
    state: ISearchState,
    action: IAction<TagNameSearchResponseModel>
  ): ISearchState {
    return {
      ...state,
      orderItemsByTagName: action.payload!,
    };
  }
  public [SearchAction.REQUEST_SEARCH_RESPONSE_MODEL_FINISHED](
    state: ISearchState,
    action: IAction<CombinedResponse> | IAction<OrdersResponseModel> | IAction<InvoiceResponseModel>
  ): ISearchState {
    return {
      ...state,
      searchResponseModel: action.payload!,
    };
  }

  public [SearchAction.REQUEST_SEARCH_RESET](state, action): any {
    return {
      ...state,
      searchResponseModel: action.payload!,
      orderItemsByTagName: action.payload!,
    };
  }
}
