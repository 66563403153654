import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import InvoicesResponseModel from 'stores/invoices/models/InvoicesResponseModel';
import OrdersResponseModel from 'stores/orders/models/OrdersResponseModel';
import EffectUtility from 'utilities/EffectUtility';
import CombinedResponse from './models/ICombinedResponse';
import ISearchRequestConfig from './models/ISearchRequestConfig';
import TagNameSearchResponseModel from './models/TagNameSearchResponseModel';
export default class SearchEffect {
  public static async requestSearchResponseModel(
    searchRequestConfig: ISearchRequestConfig
  ): Promise<InvoicesResponseModel | OrdersResponseModel | HttpErrorResponseModel | CombinedResponse> {
    const { accountNumber, criteria } = searchRequestConfig;

    const whichRequest = {
      poNumber: async () => {
        const transactionsEndpoint: string = `${(environment.api as any).transactions}/${accountNumber}`;
        const endpointOrders: string = `${(environment.api as any).orders}/${accountNumber}`;
        const combined: CombinedResponse = {
          orders: new OrdersResponseModel({}),
          transactions: new InvoicesResponseModel({}),
        };

        const res = await Promise.all([
          EffectUtility.getToModel<OrdersResponseModel>(OrdersResponseModel, endpointOrders, searchRequestConfig.searchModel.orders),
          EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, transactionsEndpoint, searchRequestConfig.searchModel.transactions),
        ]);

        combined.orders = res[0];
        combined.transactions = res[1];

        return combined;
      },

      transactions: () => {
        const endpoint: string = `${(environment.api as any).transactions}/${accountNumber}`;
        return EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, endpoint, searchRequestConfig.searchModel);
      },

      orders: () => {
        const endpoint: string = `${(environment.api as any).orders}/${accountNumber}`;
        return EffectUtility.getToModel<OrdersResponseModel>(OrdersResponseModel, endpoint, searchRequestConfig.searchModel);
      },
    };

    if (criteria) {
      return whichRequest[criteria]();
    }

    // Default should never be reached.
    const endpoint: string = `${(environment.api as any).orders}/${accountNumber}`;
    return EffectUtility.getToModel<OrdersResponseModel>(OrdersResponseModel, endpoint, searchRequestConfig.searchModel);
  }

  public static async requestSearchByTagName(
    tagNames: string[],
    accountNumber: number,
    pageNumber: number,
    pageSize: number,
    status: string,
    startDate: Date,
    endDate: Date
  ): Promise<TagNameSearchResponseModel | HttpErrorResponseModel> {
    var tagNameUri = tagNames.map((tn) => `tagNames=${tn.trim()}`).join('&');
    const endpoint: string = `${
      (environment.api as any).search
    }/${accountNumber}/Orders/Items/TagName?${tagNameUri}&pageSize=${pageSize}&page=${pageNumber}&status=${
      status ? status : ''
    }&startDate=${startDate && startDate.toDateString()}&endDate=${endDate && endDate.toDateString()}`;
    return EffectUtility.getToModel<TagNameSearchResponseModel>(TagNameSearchResponseModel, endpoint);
  }
}
