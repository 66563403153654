import React from 'react';
import styles from './AccountLabelCard.module.scss';

export interface ILabelCardColumn {
  label: string;
  data: any;
}

export interface ILabelCardButton {
  text: any;
  action: any;
}

interface ILabelCard {
  labelCardColumns: ILabelCardColumn[];
  labelCardButton?: ILabelCardButton;
  icon: boolean;
}

const LabelCard = ({ labelCardColumns, labelCardButton, icon }: ILabelCard) => {
  const click = () => {
    if (labelCardButton) {
      labelCardButton.action();
    }
  };
  return (
    <div className={`${styles.cardContainer} ${labelCardButton && styles['cardContainer--buttonPresent']}`} onClick={() => click()}>
      {labelCardColumns.map((item) => {
        return (
          <div key={item.label} className={styles.column}>
            <div className='hdg--4'>{item.label}</div>
            <div className={styles.data}>{item.data}</div>
          </div>
        );
      })}
      {labelCardButton && (
        <div className={`${styles.buttonColumn} ${icon && styles.iconButtonColumn}`}>
          <span className={`${icon ? styles.iconButton : styles.buttonText}`}>{labelCardButton.text}</span>
        </div>
      )}
    </div>
  );
};

export default LabelCard;
