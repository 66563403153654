import IInvoicesState from './models/IInvoicesState';
import InvoicesAction from './InvoicesAction';
import InvoicesResponseModel from './models/InvoicesResponseModel';
import InvoicesRequestModel from './models/InvoicesRequestModel';
import MatchTransactionsResponseModel from './models/MatchTransactionsResponseModel';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';
import InvoiceResponseModel from './models/InvoiceResponseModel';

export default class InvoicesReducer extends BaseReducer<IInvoicesState> {
  public readonly initialState: IInvoicesState = {
    invoicesResponse: null,
    invoicesRequest: null,
    invoicesDashboardResponse: null,
    invoiceResponse: null,
    transactionHistoryResponse: null,
    invoiceTransactionsHistoryResponse: null,
    invoiceMemoResponse: null,
    matchTransactionsResponse: null,
    pdfChecklistResponse: [],
    pdfChecklistAllState: false,
    invoicePDFDownloadResponse: null,
    transactionHistoryFilters: null,
    invoicesResponseForInvoice: null,
    invoicesFilters: null,
  };

  public [InvoicesAction.SAVE_FILTERS](state: IInvoicesState, action: IAction<InvoicesRequestModel>): IInvoicesState {
    return {
      ...state,
      transactionHistoryFilters: action.payload!,
    };
  }

  public [InvoicesAction.SAVE_INVOICES_FILTERS](state: IInvoicesState, action: IAction<InvoicesRequestModel>): IInvoicesState {
    return {
      ...state,
      invoicesFilters: action.payload!,
    };
  }

  /**
   * Uneeded once IS finishes new invoice and memo endpoint
   * @param state
   * @param action
   */
  public [InvoicesAction.REQUEST_INVOICES_FOR_INVOICE_FINISHED](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      invoicesResponseForInvoice: action.payload!,
    };
  }

  /**
   * Uneeded once IS finishes new invoice and memo endpoint
   * @param state
   * @param action
   */
  public [InvoicesAction.RESET_INVOICES_FOR_INVOICE](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      invoicesResponseForInvoice: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_INVOICES_FINISHED](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      invoicesResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_INVOICES_DASHBOARD_FINISHED](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      invoicesDashboardResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_TRANSACTIONS_FINISHED](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      transactionHistoryResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_MATCH_TRANSACTION_FINISHED](state: IInvoicesState, action: IAction<MatchTransactionsResponseModel>): IInvoicesState {
    return {
      ...state,
      matchTransactionsResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_FINISHED](state: IInvoicesState, action: IAction<InvoiceResponseModel>): IInvoicesState {
    return {
      ...state,
      invoiceResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_TRANSACTIONS_FINISHED](state: IInvoicesState, action: IAction<InvoicesResponseModel>): IInvoicesState {
    return {
      ...state,
      invoiceTransactionsHistoryResponse: action.payload!,
    };
  }

  public [InvoicesAction.DOWNLOAD_PDFS_FINISHED](state, action): IInvoicesState {
    return {
      ...state,
      invoicePDFDownloadResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_RESET](state, action): any {
    return {
      ...state,
      invoiceResponse: action.payload!,
    };
  }

  public [InvoicesAction.REQUEST_MEMO_RESET](state, action): any {
    return {
      ...state,
      invoiceMemoResponse: action.payload!,
    };
  }

  public [InvoicesAction.RESET_INVOICES](state, action): any {
    return {
      ...state,
      invoicesResponse: action.payload!,
    };
  }

  public [InvoicesAction.RESET_TRANSACTION_HISTORY](state, action): any {
    return {
      ...state,
      transactionHistoryResponse: action.payload!,
    };
  }

  public [InvoicesAction.RESET_TRANSACTIONS](state, action): any {
    return {
      invoicesResponse: null,
      invoicesDashboardResponse: null,
      invoiceResponse: null,
      transactionHistoryResponse: null,
      invoiceTransactionsHistoryResponse: null,
      invoiceMemoResponse: null,
      matchTransactionsResponse: null,
      pdfChecklistResponse: [],
      pdfChecklistAllState: false,
      invoicePDFDownloadResponse: null,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_PDF_ALL_CHECKED](state, action): any {
    if (action.payload === false) {
      return {
        ...state,
        pdfChecklistAllState: false,
      };
    }

    return {
      ...state,
      pdfChecklistAllState: !state.pdfChecklistAllState,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_PDF_CHECKED](state: IInvoicesState, action): any {
    const prevState = state.pdfChecklistResponse;
    const refBeingChecked = action.payload;

    const index = prevState.findIndex((x) => x === refBeingChecked);
    if (state.pdfChecklistAllState) {
      // not in list add it
      if (index === -1) {
        return {
          ...state,
          pdfChecklistResponse: [...prevState, refBeingChecked],
        };
      }

      if (action.individual) {
        const filterdList = prevState.filter((transaction) => transaction !== action.payload);
        return {
          ...state,
          pdfChecklistResponse: filterdList,
        };
      }

      // in list do nothing toggle allState
      return {
        ...state,
      };
    }

    // Not in list add it
    if (index === -1) {
      return {
        ...state,
        pdfChecklistResponse: [...prevState, refBeingChecked],
      };
    }

    // In list take it out
    const filterdList = prevState.filter((transaction) => transaction !== action.payload);
    return {
      ...state,
      pdfChecklistResponse: filterdList,
    };
  }

  public [InvoicesAction.REQUEST_INVOICE_MEMO_FINISHED](state, action): any {
    return {
      ...state,
      invoiceMemoResponse: action.payload!,
    };
  }
}
