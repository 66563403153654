import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import LocationForm from 'components/Form/LocationForm';
import FittingLocationAddressArr from 'constants/FittingLocationAddressArr';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import IAddress from 'utilities/Location/models/Address/IAddress';
import GoogleMapsAutocomplete from 'components/GoogleMap/GoogleMapsAutocomplete/GoogleMapsAutocomplete';
import AddressRequestModel from 'utilities/Location/models/Address/AddressRequestModel';
import Map from 'components/GoogleMap/Map/Map';
import { debounce } from 'lodash';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import { IAddressState } from 'utilities/Location/models/ILocationAddress';
import { ISelectOption } from 'components/Form/ISelectOption';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import AddressResponseModel from 'utilities/Location/models/Address/AddressResponseModel';
import LocationAddressUtility from 'utilities/Location/LocationAddressUtility';
import { LocationTabStateConstants } from 'utilities/Location/models/ILocationTabState';

interface IProps {
  formRef: any;
  name: string;
  setSaved: (isSaved: boolean) => void;
}
const initialState = {
  id: '',
  street: '',
  compliment: '',
  city: '',
  state: { label: '', value: '', name: 'state' },
  country: '',
  postalCode: '',
  latitude: 0,
  longitude: 0,
};

const LocationAddress = ({ formRef, name, setSaved }: IProps) => {
  const [addressState, setAddressState] = useState<IAddressState>(initialState);
  const addressId = useRef<string>('');
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const { locationState, setLocationTabStateProperty } = useContext(LocationContext);
  const errorMessage: string = 'Error creating a new address.';

  /**
   * this sets the Address State
   * @param data IAddress
   */
  const setAddressStateResponse = useCallback((data: IAddress): void => {
    addressId.current = data.id;
    setAddressState((prevState) => {
      return {
        ...prevState,
        id: data.id,
        street: data.street,
        compliment: data.compliment,
        city: data.city,
        state: { label: LocationAddressUtility.getStateLabel(data.state), value: data.state, name: 'state' },
        postalCode: data.postalCode,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
      };
    });
  }, []);

  useEffect(() => {
    if (locationState.id !== '') {
      LocationAddressUtility.requestAddressByLocationId(locationState.id).then((res: AddressResponseModel) => {
        if (res instanceof HttpErrorResponseModel) {
          // If status code returns a 404, then address does not exist for this locationId
          if (res.statusCode === 404) {
            LocationAddressUtility.createAddressLocation(
              new AddressRequestModel({
                street: '',
                compliment: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                locationId: locationState.id,
                latitude: 0,
                longitude: 0,
              })
            ).then((response) => {
              if (response instanceof HttpErrorResponseModel) {
                setIsError({ isError: true, message: errorMessage });
              }
              if (response.data) {
                setAddressStateResponse(response.data);
              }
            });
          }
        }
        if (res.data) {
          setAddressStateResponse(res.data);
        }
      });
    }
  }, [locationState, setAddressStateResponse]);

  const hasLatandLong = useCallback((lat: number, long: number): void => {
    if (lat !== 0 && long !== 0) {
      if (setLocationTabStateProperty) {
        setLocationTabStateProperty(LocationTabStateConstants.hasAddress, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addressState !== null) {
      hasLatandLong(addressState.longitude, addressState.latitude);
    }
  }, [addressState, hasLatandLong]);

  const setGoogleMapAddress = (place, inputRef) => {
    const tempAddressState: IAddressState = { ...addressState };
    tempAddressState.id = addressId.current;
    tempAddressState.latitude = place.geometry.location.lat();
    tempAddressState.longitude = place.geometry.location.lng();

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          tempAddressState.street = component.long_name;
          break;
        }
        case 'route': {
          tempAddressState.street += ` ${component.long_name}`;
          break;
        }
        case 'postal_code': {
          tempAddressState.postalCode = `${component.long_name}`;
          break;
        }
        case 'postal_code_suffix': {
          tempAddressState.postalCode += `-${component.long_name}`;
          break;
        }
        case 'locality':
          tempAddressState.city = `${component.long_name}`;
          break;
        case 'administrative_area_level_1': {
          tempAddressState.state = {
            label: LocationAddressUtility.getStateLabel(`${component.short_name}`),
            value: `${component.short_name}`,
            name: 'state',
          };
          break;
        }
        case 'country':
          tempAddressState.country = `${component.long_name}`;
          break;
      }
    }
    // This sets the value for autocomplete street value
    if (inputRef) {
      inputRef.value = tempAddressState.street;
    }
    setSaved(true);
    setAddressState(tempAddressState);
    const updateRequest = new AddressRequestModel({
      street: tempAddressState.street,
      city: tempAddressState.city,
      state: tempAddressState.state.value,
      postalCode: tempAddressState.postalCode,
      country: tempAddressState.country,
      latitude: tempAddressState.latitude,
      longitude: tempAddressState.longitude,
    });
    LocationAddressUtility.updateAddressByAddressId(addressId.current, updateRequest);
  };

  const updateAddressLocationApi = (newAddressState: IAddressState) => {
    if (newAddressState !== null && setLocationTabStateProperty) {
      const request = new AddressRequestModel({
        street: newAddressState.street,
        compliment: newAddressState.compliment,
        city: newAddressState.city,
        state: newAddressState.state.value,
        postalCode: newAddressState.postalCode,
        country: newAddressState.country,
        latitude: newAddressState.latitude,
        longitude: newAddressState.longitude,
      });
      const validatedRequest = LocationAddressUtility.validate(request);
      LocationAddressUtility.updateAddressByAddressId(newAddressState.id, validatedRequest);
    }
  };

  const updateAddressLocation = useCallback(
    debounce((newState: any) => updateAddressLocationApi(newState), 850),
    []
  );

  const handleFieldChange = (input) => (e): void => {
    setAddressState((prevState) => {
      return {
        ...prevState,
        [input]: e.target.value,
      };
    });
    const tempAddressFieldState: IAddressState = { ...addressState, [input]: e.target.value };
    updateAddressLocation(tempAddressFieldState);
    setSaved(true);
  };

  const handleSelectFieldChange = (select: ISelectOption) => {
    setAddressState({ ...addressState, [select.name]: { label: select.label, value: select.value } });
    // we need to validate address and update the address object
    LocationAddressUtility.updateAddressByAddressId(addressState.id, new AddressRequestModel({ state: select.value }));
  };
  return (
    <>
      <GoogleMapsAutocomplete handleFieldChange={handleFieldChange} addressState={addressState} setGoogleMapAddress={setGoogleMapAddress} />
      <LocationForm
        fields={FittingLocationAddressArr}
        handleFieldChange={handleFieldChange}
        handleSelectFieldChange={handleSelectFieldChange}
        formState={addressState}
        formRef={formRef}
        formId={'location-address'}
        isError={isError}
      />
      <Map latitude={addressState.latitude} longitude={addressState.longitude} name={name} />
    </>
  );
};

export default LocationAddress;
