import React, { useEffect, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import PingPhoneUtility from 'utilities/PingPhoneUtility';
import styles from '../Content.module.scss';
import environment from 'environment';
export const PingCustomerServiceContact = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  useEffect(() => {
    PingPhoneUtility.getPingCustomerServicePhone().then((data) => {
      setError(!data);
      setPhoneNumber(data);
    });
  }, []);
  return phoneNumber ? (
    <p className={styles['phone-number']}>{phoneNumber}</p>
  ) : error ? (
    <>
      <a
        target='_blank'
        rel='noopener noreferrer'
        aria-label='link to PING customer service information'
        href={environment.customerServiceFallbackUrl}
      >
        Customer Service
      </a>
    </>
  ) : (
    <LoadingIndicator isActive={true} />
  );
};
