import { IFormErrors } from '../IFormErrors';

// TODO: to be used in a use effect
export default function highlightFields(fieldNames: string[], formErrors: IFormErrors) {
  fieldNames.forEach((fieldName) => {
    const matchingField = formErrors.errors.find((e) => e.errorFieldName === fieldName);
    if (matchingField) {
      const field = document.getElementById(fieldName);
      field?.parentElement?.classList.add('form__error-highlight');
    } else {
      const field = document.getElementById(fieldName);
      field?.parentElement?.classList.remove('form__error-highlight');
    }
  });
}
