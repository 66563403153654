import styles from './OrdersSummary.module.scss';

import * as React from 'react';
import DayMessagesEnum from 'constants/DayMessagesEnum';
import Error4xx from 'components/Error4xx/Error4xx';
import i18n from 'i18n';

interface IOrdersSummaryEntry {
  label: string;
  value: number;
  tooltipText?: string;
}

interface IProps {
  viewUnboxed?: boolean;
  entries: IOrdersSummaryEntry[];
  orders4xxResponses: any;
}

export default class OrdersSummary extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    entries: [],
  };

  public render(): JSX.Element {
    const { viewUnboxed, entries } = this.props;
    const language = i18n;

    const wrapperClass = `
      ${styles.wrapper}
      ${viewUnboxed ? styles.unboxed : ''}
    `;

    return (
      <Error4xx response={this.props.orders4xxResponses ? this.props.orders4xxResponses['OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED'] : undefined}>
        <div className={wrapperClass}>
          {entries.map((entry) => {
            return (
              <div key={entry.label} className={styles.entry}>
                <p className={styles.label}>
                  <span>{entry.label}</span>
                  {/* {entry.tooltipText && (
                  <span className={styles.tooltip}>
                    <span>&nbsp;&#9432;</span>
                    <span className={styles.tooltipBody}>
                      <span>{entry.tooltipText}</span>
                    </span>
                  </span>
                )} */}
                </p>
                {viewUnboxed ? <p className={styles.last}>{DayMessagesEnum.last120Days}</p> : null}
                <p className={styles.value}>{new Intl.NumberFormat(language.language).format(entry.value)}</p>
              </div>
            );
          })}
        </div>
      </Error4xx>
    );
  }
}
