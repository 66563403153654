import React from 'react';
import { Link } from 'react-router-dom';
import RouteEnum from '../../../constants/RouteEnum';
import { TransactionTypeDescriptionEnum } from '../../../constants/TransactionTypeEnum';
import DateFormatsEnum from '../../../constants/DateFormatsEnum';
import moment from 'moment';

export function InvoiceLink(props) {
  const { value } = props;
  return (
    <div>
      <Link to={`${RouteEnum.Transactions}/${value}`}>{value}</Link>
    </div>
  );
}

interface IPaymentLink {
  value: {
    tranType: string;
    tranNum: any;
    tranRef: any;
    tranDate: any;
    invoiceNumber: number;
    accountNumber: number;
  };
}

export function PaymentLink(props: IPaymentLink) {
  const { value } = props;

  // eslint-disable-next-line max-params
  function getLink(transactionTypeDescription, transactionReferenceNumber, transactionNumber, transactionDate, accountNumber, invoiceNumber) {
    switch (transactionTypeDescription) {
      case TransactionTypeDescriptionEnum.ADJUSTMENT:
        return transactionReferenceNumber;
      case TransactionTypeDescriptionEnum.DEDIT_MEMO:
        return <Link to={`${RouteEnum.Transactions}/${accountNumber}/memo/${transactionReferenceNumber}/DM`}>{transactionReferenceNumber}</Link>;
      case TransactionTypeDescriptionEnum.CREDIT_MEMO:
        return <Link to={`${RouteEnum.Transactions}/${accountNumber}/memo/${transactionReferenceNumber}/CM`}>{transactionReferenceNumber}</Link>;
      case TransactionTypeDescriptionEnum.INTEREST:
        return transactionReferenceNumber;
      case TransactionTypeDescriptionEnum.DISCOUNT:
        return transactionReferenceNumber;
      case TransactionTypeDescriptionEnum.INVOICE:
        return <Link to={`${RouteEnum.Transactions}/${transactionReferenceNumber}`}>{transactionReferenceNumber}</Link>;
      case TransactionTypeDescriptionEnum.PAYMENT:
        // eslint-disable-next-line no-case-declarations
        const date =
          moment(transactionDate).format(DateFormatsEnum.HyphensForCalls) !== 'Invalid Date'
            ? moment(transactionDate).format(DateFormatsEnum.HyphensForCalls)
            : transactionDate;

        return <Link to={`${RouteEnum.Payments}/${transactionNumber}/${transactionReferenceNumber}/${date}`}>{transactionReferenceNumber}</Link>;
      default:
    }
  }

  return <div>{getLink(value.tranType, value.tranRef, value.tranNum, value.tranDate, value.accountNumber, value.invoiceNumber)}</div>;
}
