import OrdersResponseModel from './models/OrdersResponseModel';
import OrdersSummaryResponseModel from './models/OrdersSummaryResponseModel';
import OrdersEffect from './OrdersEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import OrderResponseModel from './models/OrderResponseModel';
import OrdersRequestModel from './models/OrdersRequestModel';

type ActionUnion = void | HttpErrorResponseModel | OrderResponseModel | OrdersResponseModel | OrdersSummaryResponseModel | OrdersRequestModel;

export default class OrdersAction {
  public static readonly REQUEST_ORDER: string = 'OrdersAction.REQUEST_ORDER';
  public static readonly REQUEST_ORDER_FINISHED: string = 'OrdersAction.REQUEST_ORDER_FINISHED';
  public static readonly RESET_ORDER_FINISHED: string = 'OrdersAction.RESET_ORDER_FINISHED';
  public static readonly REQUEST_ORDERS: string = 'OrdersAction.REQUEST_ORDERS';
  public static readonly REQUEST_ORDERS_FINISHED: string = 'OrdersAction.REQUEST_ORDERS_FINISHED';
  public static readonly REQUEST_ORDERS_DASHBOARD: string = 'OrdersAction.REQUEST_ORDERS_DASHBOARD';
  public static readonly REQUEST_ORDERS_DASHBOARD_FINISHED: string = 'OrdersAction.REQUEST_ORDERS_DASHBOARD_FINISHED';
  public static readonly REQUEST_ORDERS_SUMMARY: string = 'OrdersAction.REQUEST_ORDERS_SUMMARY';
  public static readonly REQUEST_ORDERS_SUMMARY_FINISHED: string = 'OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED';
  public static readonly RESET_ORDERS: string = 'OrdersAction.RESET_ORDERS';
  public static readonly SAVE_FILTERS: string = 'OrdersAction.SAVE_FILTERS';

  public static requestOrder(orderId: string, accountNumber: number | undefined): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<OrderResponseModel>(
        dispatch,
        OrdersAction.REQUEST_ORDER,
        OrdersEffect.requestOrder,
        orderId,
        accountNumber
      );
    };
  }

  public static resetOrder(payload: any): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({
        type: OrdersAction.RESET_ORDER_FINISHED,
        payload,
      });
    };
  }

  public static resetOrders(payload: any): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({
        type: OrdersAction.RESET_ORDERS,
        payload,
      });
    };
  }

  public static saveOrdersFilters(payload: OrdersRequestModel | null): any {
    return async (dispatch: ReduxDispatch<ActionUnion | null>, getState: () => IStore) => {
      if (payload === null) {
        dispatch({ type: OrdersAction.SAVE_FILTERS, payload: null });
      } else {
        dispatch({
          type: OrdersAction.SAVE_FILTERS,
          payload,
        });
      }
    };
  }

  public static requestOrders(ordersRequestConfig: OrdersRequestModel, accountNumber: number, dashboard?: boolean, setLoading?): any {
    if (setLoading) {
      setLoading(true);
    }

    const ordersRequestConfigPrepare: object = {
      ...ordersRequestConfig,
      status: ordersRequestConfig.status,
    };

    // TODO: break out into its own method.
    if (dashboard) {
      return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
        await ActionUtility.createThunkEffect<OrdersResponseModel>(
          dispatch,
          OrdersAction.REQUEST_ORDERS_DASHBOARD,
          OrdersEffect.requestOrders,
          {
            ...ordersRequestConfigPrepare,
          },
          accountNumber
        );

        if (setLoading) {
          setLoading(false);
        }
      };
    }

    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: OrdersAction.SAVE_FILTERS, payload: ordersRequestConfig });

      await ActionUtility.createThunkEffect<OrdersResponseModel>(
        dispatch,
        OrdersAction.REQUEST_ORDERS,
        OrdersEffect.requestOrders,
        {
          ...ordersRequestConfigPrepare,
        },
        accountNumber
      );

      if (setLoading) {
        setLoading(false);
      }
    };
  }

  public static requestOrdersSummary(accountNumber: number, startDate, endDate): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({ type: OrdersAction.REQUEST_ORDERS_SUMMARY });

      const model: OrdersSummaryResponseModel | HttpErrorResponseModel = await OrdersEffect.requestOrdersSummary(accountNumber, startDate, endDate);

      dispatch({
        type: OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED,
        payload: model,
        error: model instanceof HttpErrorResponseModel,
      });
    };
  }
}
