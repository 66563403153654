import * as React from 'react';
import styles from '../AccountsTable/EditAccountsTable.module.scss';
import { KenticoUser } from 'stores/users/models/IUserV3';
import { ReactComponent as PlusIcon } from 'assets/media/icons/icon-plus-alt.svg';

interface IProps {
  rowData: any;
  userToLink?: KenticoUser;
}

export default function EditAccountCard(props: IProps) {
  const { rowData, userToLink } = props;
  const { accountNumber, accountName } = rowData;
  return (
    <>
      <td className={`${styles['acc-table__row__cell']} hdg--4`}>{accountNumber}</td>
      <td className={styles['acc-table__row__cell']}>
        <div className={`${styles['acc-table__row__cell__split']}`}>
          <div>
            <div className='hdg--4'>{accountName.accountName}</div>
            <div>{accountName.businessName}</div>
          </div>

          <div>
            {userToLink ? (
              <div className={styles['acc-table__hover-icon-container']}>
                <PlusIcon
                  className={styles['acc-table__hover-icon-label']}
                  aria-hidden='true'
                  height='20px'
                  width='20px'
                  focusable='false'
                  role='img'
                />
              </div>
            ) : (
              <span className={styles['acc-table__hover-label']}>{userToLink ? 'Add' : 'View'}</span>
            )}
          </div>
        </div>
      </td>
    </>
  );
}
