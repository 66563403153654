import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import HttpUtility from './HttpUtility';

export default class FeatureFlagUtility {
  /**
   * Function makes an api call to Azure Feature Flag App Configs to get the feature flags status
   * @returns all feature flag status that needs to be displayed or hidden
   */
  public static async getAllFeatureFlag() {
    const endpoint = `${(environment.api as any).system}/features`;
    const responseStatus = await HttpUtility.get(endpoint);
    if (responseStatus instanceof HttpErrorResponseModel) {
      return false;
    }
    const status = responseStatus.data.data;
    return status;
  }
}
