import * as React from 'react';
import IAccount from '../../../../stores/accounts/models/IAccount';
import { KenticoUser } from 'stores/users/models/IUserV3';

interface IProps {
  rowData: IAccount;
  userToLink?: KenticoUser;
}

export default function AccountCard(props: IProps) {
  const { rowData, userToLink } = props;
  const { accountNumber, accountName, businessName } = rowData;
  return (
    <>
      <td className='acc-table__row__cell'>{accountNumber}</td>
      <td className='acc-table__row__cell'>{accountName}</td>
      <td className='acc-table__row__cell'>
        <div className='acc-table__row__cell__split'>
          <div>{businessName}</div>
          <div>
            <span className='acc-table__hover-label'>{userToLink ? 'Add' : 'View'}</span>
          </div>
        </div>
      </td>
    </>
  );
}
