import IStore from 'models/IStore';
import { ReduxDispatch } from 'models/ReduxProps';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import SerialNumberProductResponseModel from './models/SerialNumberProductResponseModel';
import ActionUtility from '../../utilities/ActionUtility';
import SerialNumberEffect from './SerialNumberEffect';
type ActionUnion = void | HttpErrorResponseModel | SerialNumberProductResponseModel;

export default class SerialNumberAction {
  public static readonly REQUEST_SERIAL_NUMBER: string = 'SerialNumberAction.REQUEST_SERIAL_NUMBER';
  public static readonly REQUEST_SERIAL_NUMBER_FINISHED: string = 'SerialNumberAction.REQUEST_SERIAL_NUMBER_FINISHED';
  public static readonly REQUEST_SERIAL_NUMBER_RESET: string = 'SerialNumberAction.REQUEST_SERIAL_NUMBER_RESET';
  public static requestSerialNumber(
    serialNumber: string,
    sortKey: string,
    sortDirection: string,
    pageSize: number | undefined,
    pageNumber: number | undefined
  ): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<SerialNumberProductResponseModel>(
        dispatch,
        SerialNumberAction.REQUEST_SERIAL_NUMBER,
        SerialNumberEffect.requestItemsBySerialNumber,
        serialNumber,
        sortKey,
        sortDirection,
        pageSize,
        pageNumber
      );
    };
  }
  public static requestSerialReset(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({
        type: SerialNumberAction.REQUEST_SERIAL_NUMBER_RESET,
        payload,
      });
    };
  }
}
