import Pagination from 'components/Pagination/Pagination';
import Value from 'components/Value/Value';
import OrderDetailTable from 'containers/OrderDetailPage/components/OrderDetailTable/OrderDetailTable';
import { Invoice, ITrackingInfo, Tracking } from 'containers/OrderDetailPage/components/OrderItems/helpers/OrderItemsHelperComps';
import { DATE_HELPER } from 'index';
import React, { useMemo, useState, useEffect } from 'react';
import IOrderItemByTagName from 'stores/search/models/IOrderItemByTagName';
import TagNameSearchResponseModel from 'stores/search/models/TagNameSearchResponseModel';
import { formatCurrency } from 'utilities/FormatCurrency';
import ITracking from 'utilities/models/ITracking';
import styles from './TagLookup.module.scss';
interface IOrderItemByTagNameTable {
  descriptionWithTagName: Element;
  orderNumber: number;
  itemNumber: number;
  description: string;
  quantity: number;
  unitPrice: number;
  itemTotal: number;
  status: string;
  tagName: string;
  estimatedDate: Date;
  tracking: ITracking;
}
interface IProps {
  data?: IOrderItemByTagName[] | null;
  onNewPageClick: (page: number) => void;
  tagResponse?: TagNameSearchResponseModel | null;
  tracking: ITrackingInfo;
  setTracking?: (tracking: ITrackingInfo) => void;
  currency: string;
}
const OrderItemTable = (props: IProps) => {
  const { data, tagResponse, tracking, setTracking, currency } = props;
  const [organizedData, setOrganizedData] = useState<Array<IOrderItemByTagNameTable | IOrderItemByTagName>>([]);

  useEffect(() => {
    if (data) {
      setOrganizedData(
        data.map((d) => ({
          ...d,
          descriptionWithTagName: d.description ? (
            <div>
              <p>{d.description}</p>
              <p className={styles['inline-bold']}>Tags:&nbsp;</p>
              <p className={styles['inline']}>{d.tagName}</p>
            </div>
          ) : (
            <p>Data Unavailable</p>
          ),
          tracking: {
            orderNumber: d.orderNumber,
            itemNumber: d.itemNumber,
            pONumber: d.purchaseOrderNumber,
            reqDate: d.orderDate,
          },
        }))
      );
    }
  }, [data]);
  const columns = useMemo(
    () =>
      currency && [
        {
          Header: <div className={`${styles['left-align']} ${styles['grey']} ${styles['left-padding']}`}>Order Number</div>,
          accessor: 'orderNumber',
          Cell: ({ cell: { value } }) => (
            <div className={`${styles['left-align']} ${styles['left-padding']}`}>
              <a className='focus-ring' href={`/pages/orders/${value}`}>
                {value}
              </a>
            </div>
          ),
        },
        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Order Date</div>,
          accessor: 'orderDate',
          Cell: ({ cell: { value } }) => <div className={styles['left-align']}>{value ? DATE_HELPER.getDisplayDate(value) : 'N/A'}</div>,
        },
        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Description</div>,
          accessor: 'descriptionWithTagName',
          Cell: ({ cell: { value } }) => <div className={styles['left-align']}>{value}</div>,
        },
        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Item</div>,
          accessor: 'itemNumber',
          Cell: ({ cell: { value } }) => <div className={styles['left-align']}>{value}</div>,
        },

        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Item Total</div>,
          accessor: 'itemTotal',
          Cell: ({ cell: { value } }) => <div className={styles['center-align']}>{formatCurrency(currency, value)}</div>,
        },
        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Status</div>,
          accessor: 'status',
          Cell: ({ cell: { value } }) => (
            <div className={styles['center-align']}>
              <Value value={value} propertyName={'orderstatus'} />
            </div>
          ),
        },
        {
          Header: () => <div className={`${styles['left-align']} ${styles['grey']}`}>Est. Date</div>,
          accessor: 'estimatedDate',
          Cell: ({ cell: { value } }) => <div className={styles['center-align']}>{value ? DATE_HELPER.getDisplayDate(value) : 'N/A'}</div>,
        },
        {
          Header: <div className={`${styles['grey']}`}>Invoice</div>,
          accessor: 'invoice',
          Cell: ({ cell: { value } }) => <Invoice value={value} />,
        },
        {
          Header: <div className={`${styles['grey']} ${styles['right-padding']}`}>Tracking</div>,
          accessor: 'tracking',
          Cell: ({ cell: { value } }) => (
            <div className={styles['right-padding']}>
              <Tracking value={value} setTracking={setTracking} tracking={tracking} />
            </div>
          ),
        },
      ],
    [tracking, setTracking, currency]
  );
  return (
    <>
      <div className={styles['tag-lookup-table__card']}>
        <OrderDetailTable columns={columns} data={organizedData} />
      </div>
      {tagResponse && <Pagination data={tagResponse} onNewPageClick={props.onNewPageClick} />}
    </>
  );
};

export default OrderItemTable;
