import { TR } from 'index';

export const LANG = {
  nameSpaces: {
    common: 'common',
    dashBoard: 'dashBoard',
  },
  goodMorning: 'goodMorning',
  goodAfternoon: 'goodAfternoon',
  goodEvening: 'goodEvening',
  _t: (nameSpace, key) => {
    return TR.t(`${nameSpace}:${key}`);
  },
};
