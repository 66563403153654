import React from 'react';
import LocationForm from 'components/Form/LocationForm';
import FittingLocationNameArr from 'constants/FittingLocationNameArr';
import { initialError } from 'containers/LocationInfoPage/models/ILocationError';

interface ICustomNameProps {
  formState: any;
  formRef: any;
  handleFieldChange: Function;
}

const LocationName = ({ formState, formRef, handleFieldChange }: ICustomNameProps) => {
  return (
    <>
      <LocationForm
        fields={FittingLocationNameArr}
        handleFieldChange={handleFieldChange}
        formState={formState}
        formRef={formRef}
        formId={'location-custom-name'}
        isError={initialError}
      />
    </>
  );
};

export default LocationName;
