import ICertification from 'utilities/Location/models/ICertification';
import IEnvironment from 'utilities/Location/models/IEnvironment';
import ITechnology from 'containers/LocationInfoPage/components/TechnologyTab/models/ITechnology';
import IStore from 'models/IStore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { oc } from 'ts-optchain';
import styles from './CategoryCard.module.scss';
import { ReactComponent as Lock } from '../../../../../../../assets/media/icons/icon-lock.svg';
import UserRolesEnum from 'constants/UserRolesEnum';

type CategoryUnion = ICertification | IEnvironment | ITechnology;
interface IProps {
  itemList: CategoryUnion[];
  locationSpecificItems: any[];
  categoryName: string;
  isProtected: boolean;
  addLocationSpecificItem: Function;
  deleteLocationSpecificItem: Function;
}

const CategoryCard = ({
  itemList,
  locationSpecificItems,
  categoryName,
  isProtected,
  addLocationSpecificItem,
  deleteLocationSpecificItem,
}: IProps): JSX.Element => {
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const [isCheckedList, setIsCheckedList] = useState<string[]>([]);

  const handleChange = (item: CategoryUnion) => (e) => {
    if (e.target.checked) {
      addLocationSpecificItem(item.id);
      setIsCheckedList((prevState) => {
        return [...prevState, item.id];
      });
    } else {
      setIsCheckedList((prevState) => {
        return prevState.filter((items) => items !== item.id);
      });
      deleteLocationSpecificItem(item.id);
    }
  };

  useEffect(() => {
    if (itemList && itemList.length > 0 && locationSpecificItems && locationSpecificItems.length > 0) {
      itemList.forEach((item) => {
        let matched = locationSpecificItems.some((specItem) => {
          return specItem.id === item.id;
        });
        if (matched) {
          setIsCheckedList((prevState) => {
            return [...prevState, item.id];
          });
        }
      });
    }
  }, [setIsCheckedList, itemList, locationSpecificItems]);
  const isDisabled = isProtected && !currentUser?.data?.memberships?.find((dm) => dm.key === UserRolesEnum.PingAdmin);
  itemList.sort((a, b) => (a.name.toLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
  return (
    <div
      className={
        !isProtected && !currentUser?.data?.memberships?.find((dm) => dm.key === UserRolesEnum.PingAdmin)
          ? `${styles.cardContainer} ${styles.protected}`
          : `${styles.cardContainer} `
      }
    >
      {isDisabled && (
        <span className={styles.protectedCategory}>
          <Lock className={styles.icon} aria-hidden='true' focusable='false' role='img' title='Locked section managed by PING' />
        </span>
      )}
      <h2 className={styles.cardTitle}>{categoryName}</h2>
      {itemList?.map((item: CategoryUnion, index) => {
        return (
          <div className={styles.cardCheckbox} key={index}>
            <label
              className={isDisabled ? `form__checkbox-container ${styles.disabled}` : `form__checkbox-container ${styles.enabled}`}
              id={`${item.name}-label`}
            >
              <span className={isCheckedList.includes(item.id) ? `${styles.cardLabel}` : ''}>{`${item.name}${
                !item.isActiveOnFilter ? ' (Hidden from Fitting Map)' : ''
              }`}</span>
              <input
                className={isDisabled ? 'form__checkbox' : `form__checkbox ${styles.enabled}`}
                type='checkbox'
                onChange={handleChange(item)}
                checked={isCheckedList.includes(item.id)}
                disabled={isDisabled}
                name={item.name}
              />
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryCard;
