enum RouteNameEnum {
  Landing = 'Home',
  Login = 'Login',
  Logout = 'Logout',
  Dashboard = 'Dashboard',
  Transactions = 'Transactions',
  Orders = 'Orders',
  Order = 'Order Detail',
  SalesSupport = 'Sales Support',
  FrequentAskedQuestion = 'Frequent Asked Question',
  Accounts = 'Accounts',
  Pricing = 'Pricing',
  Fitting = 'Fitting',
  ManageUsers = 'Manage Users',
  SerialLookup = 'Serial Lookup',
  AccountProfile = 'Account Profile',
  SearchResults = 'Search results',
  Contact = 'Contact',
  CreateOrderCategories = 'Categories',
  CreateOrderReview = 'Order Review',
}

export default RouteNameEnum;
