import styles from '../InvoicePage.module.scss';
import React from 'react';
import { useTable, usePagination } from 'react-table';

const MemoProductList = ({ memoData }) => {
  interface IMemoData {
    addressList: {};
    itemList: [];
  }
  const memoDataPulled: IMemoData = memoData[0];

  const columns = [
    {
      Header: 'Item',
      accessor: 'itemNumber',
    },
    {
      Header: 'Description',
      accessor: (itemData) => {
        return (
          <div>
            <div className={styles['product-list-table__row__cell__category']}>{itemData.productType}</div>
            {itemData.description ? itemData.description : 'n/a'}
          </div>
        );
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Unit',
      accessor: 'unitPrice',
    },
    {
      Header: 'Extended',
      accessor: 'extendedPrice',
    },
  ];

  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoDataPulled.itemList,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: 1,
    },
    usePagination
  );

  return (
    <div>
      <table className={styles['product-list-table']}>
        <tbody>
          {headerGroups.map((headerGroup, i) => (
            <tr className={styles['product-list-table__row--hd']} key={`headerGroup-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${styles['product-list-table__row__cell']} ${styles['product-list-table__row__cell__label']}`}
                  scope='col'
                  key={column.id}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr className={styles['product-list-table__row--bd']} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} className={styles['product-list-table__row__cell']}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MemoProductList;
