import IFields from '../components/Form/IFields';

const FittingLocationNameArr: IFields = [
  {
    title: 'Location Name',
    name: 'name',
    type: 'input',
    inputType: 'text',
    label: 'Custom Name for Search Results Page (Optional)',
    notRequired: true,
  },
];

export default FittingLocationNameArr;
