import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import RouteEnum from '../../../../constants/RouteEnum';
import OrderFooterMainContainer from './components/OrderFooterMainContainer';
import OrderFooterDynamicForm from './components/OrderFooterDynamicForm';
import CartStateHelpers from 'containers/CreateOrderPage/helpers/CartStateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { Dispatch } from 'redux';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../productConfig/ProductConfig';

interface IProps {
  stepName: string;
  onHandleCartClear?: () => void;
  handleShipping?: any;
  handleReview?: any;
  handleCancel?: () => void;
  handleOrder?: () => void;
  onHandleBackToOrders?: () => void;
  handleResetForm?: (e: any) => void;
  onHandleShippingForReset?: (v: boolean) => void;
  onClearCartAndShipping?: () => void;
  orders?: any;
  submitForm?: any;
  to?: any;
  disabledButton?: boolean;
  noTopMargin?: boolean;
  fromModal?: boolean;
}

const OrderFooter = (props: IProps) => {
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const cartID = useSelector((state: IStore) => state.createOrder.cartState?.cart.cartID);
  const disableShippingButton = !tabState.hasShipping;

  const handleAddMoreItems = () => {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: 0 }));
  };

  function renderFooter(props: IProps, history, dispatch, accountNumber) {
    const {
      stepName,
      handleOrder,
      onHandleBackToOrders,
      handleResetForm,
      handleReview,
      submitForm,
      orders,
      disabledButton,
      noTopMargin,
      fromModal,
    }: IProps = props;
    const disablePlaceOrderButton = () => {
      if (orders && orders.ItemCount <= 0) {
        return true;
      }
      if (disabledButton === true) {
        return true;
      }
      return false;
    };
    switch (stepName) {
      case 'productCards':
        return (
          <OrderFooterMainContainer
            to={RouteEnum.CreateOrders}
            toNextStep={`${RouteEnum.CreateOrders}/shipping`}
            navLinkOnClick={() => CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID)}
            navlinkText='Cancel Order'
            buttonOnClick={() => TabStateHelpers.handleShipping(dispatch, tabState)}
            buttonText='Continue To Shipping'
          />
        );
      case 'orderShipping':
        return (
          <>
            {fromModal ? (
              <OrderFooterMainContainer
                to={RouteEnum.CreateOrders}
                toNextStep={`${RouteEnum.CreateOrders}/review`}
                navLinkOnClick={() => CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID)}
                navlinkText='Cancel Order'
                // buttonOnClick={handleReview}
                // buttonText='Continue To Review'
                disabledButton={disableShippingButton.toString()}
                addMoreItems={{ handleAddMoreItems }}
              />
            ) : (
              <OrderFooterMainContainer
                to={RouteEnum.CreateOrders}
                toNextStep={`${RouteEnum.CreateOrders}/review`}
                navLinkOnClick={() => CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID)}
                navlinkText='Cancel Order'
                buttonOnClick={handleReview}
                buttonText='Continue To Review'
                addMoreItems={{ handleAddMoreItems }}
              />
            )}
          </>
        );

      case 'orderReview':
        return (
          <OrderFooterMainContainer
            to={RouteEnum.CreateOrders}
            toNextStep={`${RouteEnum.CreateOrders}/review`}
            navLinkOnClick={() => CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID)}
            navlinkText='Cancel Order'
            buttonOnClick={handleOrder}
            buttonText='Place Order'
            disabledButton={disablePlaceOrderButton().toString()}
            addMoreItems={{ handleAddMoreItems }}
          />
        );

      case 'orderConfirmation':
        return (
          <div className='split'>
            <NavLink onClick={onHandleBackToOrders} className='a' to={RouteEnum.Orders}>
              Back to Orders
            </NavLink>
          </div>
        );

      case 'dynamicForm':
        return (
          <OrderFooterDynamicForm
            to={RouteEnum.CreateOrders}
            toNextStep={`${RouteEnum.CreateOrders}/shipping`}
            navlinkText='Cancel'
            navLinkOnClick={() => TabStateHelpers.goToInitial(dispatch, tabState)}
            buttonOnClick={submitForm}
            buttonText={PRODUCT_CONFIG_LOCAL_STORE.isEditing ? 'Apply Changes' : 'Add To Order'}
            selectItemsStep
            disabledButton={disabledButton}
            noTopMargin={noTopMargin}
            handleResetForm={handleResetForm}
          />
        );
      default:
        return null;
    }
  }
  return <div>{renderFooter(props, history, dispatch, accountNumber)}</div>;
};

export default OrderFooter;
