import React from 'react';
import styles from '../OrderShipping.module.scss';

interface IProps {
  selectState: string;
  handleSelect: (v: string) => void;
}

const ShipTypeSelector = ({ selectState, handleSelect }: IProps) => {
  return (
    <div className={styles.shippingSelector}>
      <button onClick={() => handleSelect('account')} className={styles.shippingSelector__btn}>
        <div className={`${styles.shippingSelector__radio} ${selectState === 'account' && styles['shippingSelector__radio--selected']}`} /> Account
      </button>
      <button onClick={() => handleSelect('drop')} className={styles.shippingSelector__btn}>
        <div className={`${styles.shippingSelector__radio} ${selectState === 'drop' && styles['shippingSelector__radio--selected']}`} />
        Drop Ship
      </button>
    </div>
  );
};

export default ShipTypeSelector;
