/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState } from 'react';
import styles from '../InvoicePage.module.scss';
import { ReactComponent as PlusIcon } from '../../../assets/media/icons/icon-plus-alt.svg';
import { ReactComponent as NegativeIcon } from '../../../assets/media/icons/icon-negative.svg';
import BasicTable from '../../../components/BasicTable/BasicTable';
import IMatchTransaction from '../../../stores/invoices/models/IMatchTransaction';
import { PaymentLink } from './matchedTransactionsHelper';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';

interface IProps {
  transactions: IMatchTransaction[] | undefined;
  noWrapper?: boolean;
  accountNumber: number | undefined;
  invoiceNumber?: number | undefined;
  isFromPayment?: boolean;
}

const MatchedTransactions = ({ transactions, noWrapper, accountNumber, invoiceNumber, isFromPayment }: IProps) => {
  const [isTransactionsExpanded, toggleTransactions] = useState(false);
  const data = transactions?.map((item) => {
    const {
      dateAppliedAsDate,
      transactionFromTypeDescription,
      transactionFromReason,
      transactionFromNumber,
      transactionFromReferenceNumber,
      transactionFromAmount,
      transactionToTypeDescription,
      transactionToNumber,
      transactionToReason,
      transactionToReferenceNumber,
      TransactionToDateAsDate,
    } = item;

    return {
      fromDate: moment(dateAppliedAsDate).format(DateFormatsEnum.WrittenDayMonthYear),
      fromNumber: transactionFromNumber,
      fromType: transactionFromTypeDescription,
      fromReason: transactionFromReason,
      fromRef: transactionFromReferenceNumber,
      fromAmount: transactionFromAmount,
      toType: transactionToTypeDescription,
      toReason: transactionToReason,
      toRef: {
        tranType: transactionToTypeDescription,
        tranNum: transactionToNumber,
        tranRef: transactionToReferenceNumber,
        tranDate: moment(dateAppliedAsDate).format(DateFormatsEnum.WrittenDayMonthYear),
        accountNumber,
        invoiceNumber,
      },
      toDate: moment(TransactionToDateAsDate).format(DateFormatsEnum.WrittenDayMonthYear),
      fromLink: {
        tranType: transactionFromTypeDescription,
        tranNum: transactionFromNumber,
        tranRef: transactionFromReferenceNumber,
        tranDate: moment(dateAppliedAsDate).format(DateFormatsEnum.WrittenDayMonthYear),
        accountNumber,
        invoiceNumber,
      },
    };
  });
  const fromColumns = [
    {
      Header: 'Date Applied',
      accessor: 'fromDate',
    },
    {
      Header: 'Type',
      accessor: 'fromType',
    },
    {
      Header: 'Reason',
      accessor: 'fromReason',
    },
    {
      Header: 'Reference No.',
      accessor: 'fromLink',
      Cell: ({ cell: { value } }) => (!isFromPayment ? <PaymentLink value={value} /> : <span>{value.tranRef}</span>),
    },
    {
      Header: 'Amount',
      accessor: 'fromAmount',
    },
  ];

  const toColumns = [
    {
      Header: 'Type',
      accessor: 'toType',
    },
    {
      Header: 'Reason',
      accessor: 'toReason',
    },
    {
      Header: 'Reference No.',
      accessor: 'toRef',
      Cell: ({ cell: { value } }) => (isFromPayment ? <PaymentLink value={value} /> : <span>{value.tranRef}</span>),
    },
    {
      Header: 'Date',
      accessor: 'toDate',
    },
  ];

  return (
    <div className={`${noWrapper ? '' : 'wrapper'}`}>
      {transactions?.length === 0 ? (
        <>
          <h1 className='hdg hdg--3'>Matched Transactions</h1>
          <span>No Matched Transactions to display</span>
        </>
      ) : (
        <>
          <div className='split'>
            <div>
              <h1 className='hdg hdg--3'>Matched Transactions</h1>
            </div>

            <div>
              <button onClick={(): void => toggleTransactions(!isTransactionsExpanded)} className={`btn ${styles.btn__expand} `}>
                <span className={styles.btn__expand__label}>Expand/Contract</span>
                {!isTransactionsExpanded ? (
                  <span className={styles.btn__expand__toggle}>
                    <PlusIcon height='20px' width='20px' aria-hidden='true' focusable='false' role='img' />
                  </span>
                ) : (
                  <span className={styles.btn__expand__toggle}>
                    <NegativeIcon height='20px' width='20px' aria-hidden='true' focusable='false' role='img' />
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className={`${styles.transactions_wrapper} ${isTransactionsExpanded ? styles.open : styles.closed}`}>
            <div className={styles['matched-grid']}>
              <div className={styles['matched-grid__item']}>
                <h2 className={`${styles['matched-grid_toFrom']} hdg hdg--4 hdg--miniPadding`}>From</h2>
              </div>
              <div className={styles['matched-grid__item']}>
                <h2 className={`${styles['matched-grid_toFrom']} hdg hdg--4 hdg--miniPadding`}>To</h2>
              </div>
            </div>
            {transactions ? (
              <div className={`${noWrapper ? '' : styles.invoiceGrid}`}>
                <div className={styles['matched-grid']}>
                  <div className={styles['matched-grid__item']}>
                    <BasicTable columns={fromColumns} data={data} />
                  </div>
                  <div className={styles['matched-grid__item']}>
                    <BasicTable columns={toColumns} data={data} />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MatchedTransactions;
