import { getStateNameOptions } from 'utilities/getStateNameOptions';
import IFields from '../components/Form/IFields';

const FittingLocationAddressArr: IFields = [
  {
    name: 'compliment',
    type: 'input',
    inputType: 'text',
    label: 'Unit/Suite/Building',
    notRequired: true,
  },
  {
    name: 'city',
    type: 'input',
    inputType: 'text',
    label: 'City',
    notRequired: false,
    auto: 'true',
  },
  {
    name: 'state',
    type: 'select',
    inputType: 'select',
    label: 'State',
    options: getStateNameOptions('state'),
    notRequired: false,
  },
  {
    name: 'country',
    type: 'input',
    inputType: 'text',
    label: 'Country',
    notRequired: false,
    auto: 'true',
  },
  {
    name: 'postalCode',
    type: 'input',
    inputType: 'text',
    label: 'Zip Code/Postal Code',
    // mask: '99999-9999',
    // pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
    notRequired: false,
    auto: 'true',
  },
];

export default FittingLocationAddressArr;
