import { BaseModel } from 'sjs-base-model';

export default class UsersRequestModel extends BaseModel {
  accountNumber: string;
  membership: string = '';
  filter: string = '';
  page: number = 1;
  pageSize: number = 5;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
