import { BaseModel } from 'sjs-base-model';
import { ICreateUserResponse } from './ICreateUserResponse';

export default class CreateUserResponseModel extends BaseModel {
  data: ICreateUserResponse;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
