import { MAIN_CONFIG } from 'configurations/mainConfig';
import React from 'react';
import IDynamicNonMasterFormFields, {
  IDynamicFormFields,
  IPriceTotalState,
  IFinalEstimatedDateState,
} from 'stores/create-order/models/IDynamicFormModels';
import { INonMasterFormFieldState } from '../../productConfig/ProductConfig';
import styles from '../DynamicForm.module.scss';
import DynamicFormPanelFields from './DynamicFormPanelFields';
import { DATE_HELPER } from 'index';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  fields: IDynamicFormFields[];
  product: any;
  nonMasterFieldFormState: INonMasterFormFieldState;
  priceTotal: IPriceTotalState;
  nonMasterFieldFormFields: IDynamicNonMasterFormFields[];
  ProductImage: any;
  finalEstimatedDate: IFinalEstimatedDateState;
  fallbackCurrency: string;
}

const DynamicFormPanel = ({
  finalEstimatedDate,
  priceTotal,
  ProductImage,
  product,
  fields,
  nonMasterFieldFormFields,
  nonMasterFieldFormState,
  fallbackCurrency,
}: IProps) => {
  return (
    <div className={styles.dynamicFormPanel}>
      <div className={styles['preview-panel__image-container']}>
        {ProductImage ? <ProductImage role='img' alt={`${product} Icon`} className={styles.image} aria-hidden='true' /> : ''}
      </div>
      <div className={styles['preview-panel__summary-container']}>
        <div className='hdg--4'>Current Item Summary</div>
        <div className='vr2' />
        <div className={styles['preview-panel__grid-container']}>
          {fields && (
            <DynamicFormPanelFields
              fields={fields}
              nonMasterFieldState={nonMasterFieldFormState}
              nonMasterFormFields={nonMasterFieldFormFields}
              exceptions={MAIN_CONFIG.CreateOrder.FieldsNotToBeDisplayed}
            />
          )}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <div className={styles.priceInnerContainer}>
          <div className={styles.priceLabel}>Total</div>
          <span className={'hdg hdg--3 hdg--no-margin-bottom'}>
            {priceTotal.isLoading ? (
              <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 20 }} />
            ) : (
              <>
                {(priceTotal.currency || fallbackCurrency) &&
                  formatCurrency(priceTotal.currency || fallbackCurrency, priceTotal.priceTotal * nonMasterFieldFormState.QTY)}
              </>
            )}
          </span>
          <div className='vr2' />
          <div className={styles.priceLabel}>Estimated Date</div>
          <span className={'hdg hdg--3 hdg--no-margin-bottom'}>
            {finalEstimatedDate.isLoading ? (
              <LoadingIndicator sizeableSpinner={{ height: 25, width: 25, marginLeft: 20 }} />
            ) : (
              <>{finalEstimatedDate.estimatedDate ? DATE_HELPER.getDisplayDate(finalEstimatedDate.estimatedDate) : ''}</>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DynamicFormPanel;
