enum UserMembershipsNamesEnum {
  Sales = 'Sales',
  CorporateUser = 'Corporate User',
  Distributor = 'Distributor',
  Account = 'Account',
  IntlProAccount = 'International Pro Account',
  EmployeeBilling = 'Employee Billing',
  Employee = 'Employee',
  Fitter = 'Fitter',
  PingAdmin = 'Ping Admin',
}
export default UserMembershipsNamesEnum;
