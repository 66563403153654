export default function disableFormFields(disableField: []) {
  disableField.filter((field) =>field);
  const fieldNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const cleanedFields = disableField.filter((item)=>item);
  fieldNames.forEach((name) => {
      const matchingField =  cleanedFields.find((item)=> item === `${name}Label`);
      const label = document.getElementById(`${name}Label`);
      const fieldSelectEnd = document.getElementById(`${name}End`);
      const fieldSelectStart = document.getElementById(`${name}Start`);
      if (matchingField) {
        label?.parentElement?.classList.add('form__disable-label');
        fieldSelectEnd?.parentElement?.classList.add('form__disable-fields');
        fieldSelectStart?.parentElement?.classList.add('form__disable-fields');
      } else {
        label?.parentElement?.classList.remove('form__disable-label');
        fieldSelectEnd?.parentElement?.classList.remove('form__disable-fields');
        fieldSelectStart?.parentElement?.classList.remove('form__disable-fields');
      }
  });
}