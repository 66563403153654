import NotFoundPage from 'containers/NotFoundPage/NotFoundPage';
import IStore from 'models/IStore';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import IFeatureFlag from 'stores/feature-flag/models/IFeatureFlag';
import UserResponseModel from 'stores/users/models/UserResponseModel';

function ProtectedRoute(props) {
  // eslint-disable-next-line react/prop-types
  const { component: Component, ...rest } = props;
  const activeAccount: UserResponseModel | null = useSelector((state: IStore) => state.users.currentUser);
  const features: IFeatureFlag | null = useSelector((state: IStore) => state.featureFlag.featureFlagItems);
  let featureFlag: boolean = false;
  featureFlag = props?.featureName === undefined || (features?.hasOwnProperty(props?.featureName) && features[props?.featureName]) ? true : false;

  let auth = false;

  if (activeAccount !== null && activeAccount.data.roles.length > 0) {
    auth = true;
  }
  return (
    <>
      {featureFlag ? (
        <Route
          {...rest}
          render={(props) =>
            auth ? (
              <Component {...props} />
            ) : (
              /**
               * Skip store is if the user clicks continue on the maintenance page that shows up when Azure services are degraded
               * Hash store is true if the user comes back from the login redirect.loc
               */
              <Redirect to={{ pathname: '/login', state: { path: { ...rest } } }} />
            )
          }
        />
      ) : (
        <NotFoundPage message={'Access is denied. You may not have the appropriate permissions to access this page.'} />
      )}
    </>
  );
}
export default ProtectedRoute;
