import IFields from '../components/Form/IFields';

const FittingLocationHourArr: IFields = [
  {
    title: 'Typical Fitting Hours',
    name: 'displayHours',
    type: 'checkbox',
    inputType: 'checkbox',
    label: 'Display Fitter Availability (Optional)',
    notRequired: true,
  },
];

export default FittingLocationHourArr;
