import { BaseModel } from 'sjs-base-model';
import { ILinkUserResponse } from './ILinkUserResponse';

export default class LinkUnlinkUserResponseModel extends BaseModel {
  data: ILinkUserResponse;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
