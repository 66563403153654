import { IDynamicFormAttribute } from 'stores/create-order/models/IDynamicFormModels';

export function getDependencyFieldsToCall(masterFieldObject: IDynamicFormAttribute[], submittedList: string[]): IDynamicFormAttribute[] {
  return masterFieldObject.filter((item) => {
    let is1Set = false;
    let is2Set = false;
    let areBothNeeded = false;

    // Check if we need both
    if (item.independentVariable1 !== '' && item.independentVariable2 !== '') {
      areBothNeeded = true;
    }

    // Check if invar1 is set
    if (item.independentVariable1 !== '') {
      const inVarObj = masterFieldObject.find((field) => field.attributeName === item.independentVariable1);

      if (inVarObj?.selectedValue !== null) {
        is1Set = true;
      }
    }

    // Check if invar2 is set
    if (item.independentVariable2 !== '' && areBothNeeded) {
      const inVarObj = masterFieldObject.find((field) => field.attributeName === item.independentVariable2);

      if (inVarObj?.selectedValue !== null) {
        is2Set = true;
      }
    }

    return (!areBothNeeded && is1Set && !submittedList.includes(item.attributeName)) ||
      (areBothNeeded && is1Set && is2Set && !submittedList.includes(item.attributeName))
      ? true
      : false;
  });
}
