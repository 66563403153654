// import * as msal from '@azure/msal-browser';
import {
  PublicClientApplication,
  SilentRequest,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  EndSessionRequest,
} from '@azure/msal-browser';
import environment from 'environment';

class MSALAuth {
  private MSALObj: PublicClientApplication;
  private account: AccountInfo;
  public token: string | undefined;
  private silentRequest: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html

  constructor() {
    /**
     * Initialize MSAL object
     */
    this.MSALObj = new PublicClientApplication(environment.msalConfig);
  }

  /**
   * Initialize
   *
   * Sets token off redirect. If redirect token from login page is not set
   * It will attempt to get the token silently. If it cant it will redirect the user
   * to the login page.
   */
  public async initialize() {
    // Get token and set it from redirect promise. if a redirect did not occur token will not be set.
    let error = false;
    await this.MSALObj.handleRedirectPromise()
      .then((res) => (this.token = res?.accessToken))
      .catch((err) => {
        console.log(err);
        error = true;
      });

    if (error) {
      return;
    }

    try {
      // Set account
      this.account = this.getAccount();

      /**
       * If we already have a token from the redirect promise short circut.
       * We do this here because we need to set account.
       **/
      if (this.token) {
        return;
      }

      // If the token has not been set from the redirect get it silently or redirect to login
      if (this.account) {
        /**
         * This function will attempt to get the token silently if it cannot it.
         * will redirect to login to get the token from the redirect callback
         **/
        await this.getTokenRedirect(environment.msalLoginRequest)
          .then((res) => (this.token = res))
          .catch((err) => console.log(err));
      } else {
        // If there is no account redirect to login. This happens if a user is not logged in.
        this.MSALObj.loginRedirect(environment.msalLoginRequest);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Checks if user has account.
   *
   * @returns Boolean
   */
  public hasAccount() {
    return Boolean(this.account);
  }

  /**
   * Redirects user to login page.
   */
  public login() {
    if (!this.MSALObj) {
      return;
    }

    if (this.hasAccount()) {
      return;
    }

    this.MSALObj.loginRedirect(environment.msalLoginRequest);
  }

  /**
   * Logs out of current account.
   */
  public logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.account,
    };

    this.MSALObj.logout(logOutRequest);
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   * TODO: Add account chooser code
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo {
    // need to call getAccount here?
    const currentAccounts = this.MSALObj.getAllAccounts();
    if (currentAccounts === null) {
      return currentAccounts;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return currentAccounts[0];
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(interactiveRequest: RedirectRequest) {
    // TODO: Promise<string>
    try {
      // Getting a token silently requires an account
      const silentRequest: SilentRequest = {
        ...interactiveRequest,
        account: this.account,
      };

      // Attempt to get token silently
      const response = await this.MSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      // If we could not get token silently acquire token via redirect.
      if (e instanceof InteractionRequiredAuthError) {
        this.MSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error);
      } else {
        console.error(e);
      }
    }
  }
}

export default MSALAuth;
