import React, { useEffect, useRef, useState } from 'react';
import OrderFooter from '../OrderFooter/OrderFooter';
import orderStyles from '../OrderFooter/OrderFooter.module.scss';
import styles from './OrderReview.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import Error4xx from 'components/Error4xx/Error4xx';
import { CartItemDTO, IGetCartResponse } from 'stores/create-order/models/IGetCart';
import OrderReviewTable from '../orderReviewTable/OrderReviewTable';
import { getTax } from '../productConfig/helpers/ProductConfigHelper';
import { Dispatch } from 'redux';
import CreateOrderAction from 'stores/create-order/CreateOrderAction';
import CartStatelessCalls from 'stores/create-order/CartStatelessCalls';
import { useHistory } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import UpdateCartRequestModel from 'stores/create-order/models/UpdateCartRequestModel';
import { formatCurrency } from 'utilities/FormatCurrency';

// TODO: need more interfaces on files contained in components
interface IProps {
  orders: CartItemDTO[];
  accountNumber: number | undefined;
}

const OrderReview = ({ orders, accountNumber }: IProps) => {
  const dispatch: Dispatch = useDispatch();
  const poErrorRef: any = useRef(null);
  const tabState = useSelector((state: IStore) => state.createOrder.tabState);
  const poNumber = useSelector((state: IStore) => state.createOrder.cartState?.cart.poNumber);
  const isPORequired: boolean | undefined = useSelector((state: IStore) => state.accounts.accountsInfoResponse?.data.purchaseOrderRequired);
  const [tax, setTax] = useState(0);
  const [error, setError] = useState<any>({
    hasError: false,
    errorMessage: null,
    poRequiredError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [poSaving, setPoSaving] = useState(false);
  const [reSubmitAfterSaving, setReSubmitAfterSaving] = useState(false);
  const history = useHistory();

  const cart: IGetCartResponse | null = useSelector((state: IStore) => state.createOrder.cartState);
  let cartTotal = 0;
  let currency: string = '';
  cart?.cartItems.forEach((item) => {
    const itemPrice = item.price * item.quantity * (item.itemSetCount ? item.itemSetCount : 1);
    cartTotal = cartTotal + itemPrice;
    currency = item.currency;
  });
  const handleOrder = (orderNumber) => {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, hasConfirm: true, tabIndex: 3, hasReview: true }));
    history.push(`${RouteEnum.CreateOrderConfirm}/${orderNumber}`);
  };

  const addresses: IAccountAddresses | null = useSelector((state: IStore) => oc(state).accounts.addresses(null));

  const handleShipping = () => {
    dispatch(CreateOrderAction.storeTabState({ ...tabState, tabIndex: 1 }));
  };

  useEffect(() => {
    if (accountNumber && cart) {
      getTax({ cartState: cart, accountNumber, setTax, shipping: addresses });
    }
  }, [accountNumber, addresses, cart]);
  useEffect(() => {
    if (!poSaving && reSubmitAfterSaving) {
      // If resubmit after saving is triggered and poSaving is complete retry to submit order
      submitOrder();
    }
    // eslint-disable-next-line
  }, [reSubmitAfterSaving, poSaving]);

  const submitOrder = () => {
    if (!poSaving) {
      if (isInvalidPoNumber(poNumber)) {
        if (poErrorRef && poErrorRef.current) {
          poErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      CartStatelessCalls.submitOrder(cart?.cart.cartID, cartTotal).then((res) => {
        if (!res.data) {
          setError({
            ...error,
            hasError: true,
            errorMessage: res,
          });
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        handleOrder(res.data);
      });
    } else {
      // Trigger retry if po is saving
      setIsLoading(true);
      setReSubmitAfterSaving(true);
    }
  };

  const handleUpdatePONumber = (_poNumber: string) => {
    if (accountNumber) {
      const updatereqConfig = new UpdateCartRequestModel({
        AccountNumber: accountNumber,
        CartId: cart?.cart.cartID,
        Shipping: cart?.cart.shipping,
        PoNumber: _poNumber,
      });
      setPoSaving(true);
      CartStatelessCalls.updateCart(updatereqConfig).then(() => {
        dispatch(CreateOrderAction.getCart(accountNumber, true)).then(() => {
          setPoSaving(false);
        });
      });
    }
  };

  const isInvalidPoNumber = (_poNumber: string | undefined) => {
    const poError = isPORequired && !Boolean(_poNumber);
    setError({
      ...error,
      poRequiredError: poError,
    });
    return poError;
  };

  // TODO: Display Address, Order Items, Order Cost
  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  const getAddress = () => {
    const shipping = cart?.cart.shipping;
    if (!shipping) {
      return <h2 className='hdg hdg--3 hdg--miniPadding'> No Address Present </h2>;
    }

    if (shipping.dropShipAddress) {
      return (
        <>
          <h2 className='hdg hdg--3 hdg--miniPadding'>{`${shipping.dropShipAddress.name}${
            shipping.dropShipAddress.businessName ? `, ${shipping.dropShipAddress.businessName}` : ''
          }`}</h2>

          <h3 className='hdg hdg--4'>
            {`
              ${shipping.dropShipAddress.address1} 
              ${shipping.dropShipAddress.address2 ? shipping.dropShipAddress.address2 : ''} 
              ${shipping.dropShipAddress.city} 
              ${shipping.dropShipAddress.state} 
              ${shipping.dropShipAddress.zip} 
            `}
          </h3>
          {shipping.dropShipAddress.attention ? <p>Care Of / Attention: {shipping.dropShipAddress.attention}</p> : ''}
        </>
      );
    }

    return (
      <>
        <h2 className='hdg hdg--3 hdg--miniPadding'>{shipping.businessName ? shipping.attention : `${shipping.firstName} ${shipping.lastName}`}</h2>
        <h3 className='hdg hdg--4'>{`${addresses?.address1} ${addresses?.address2} ${addresses?.city} ${addresses?.state} ${addresses?.zip} `}</h3>
      </>
    );
  };

  return (
    <div className='awrapper'>
      <div>
        <h1 className='hdg hdg--3'>
          Review Order <small style={{ fontWeight: 300 }}> {orders.length} Items</small>
        </h1>
      </div>

      <div className={styles.poInputGroup}>
        <h4 className='hdg hdg--5'>Add PO Number</h4>

        <div className={`${styles.form__inputContainer} ${error.poRequiredError && 'form__error-highlight'}`}>
          <label className={styles.input__label} htmlFor='poInput'>
            PO Number {isPORequired ? '' : '(Optional)'}
          </label>
          <input
            className={styles.input}
            id='poInput'
            onChange={(e: any) => isInvalidPoNumber(e.target.value)}
            onBlur={(e: any): void => {
              // if user goes directly from po input to save button, we will retry to submit after the po number is updated
              isInvalidPoNumber(e.target.value);
              handleUpdatePONumber(e.target.value);
            }}
            defaultValue={poNumber}
            type='text'
            name='PO'
            maxLength={21} // PONumber has max length of 21 on the ERP side
          />
        </div>
        <div ref={poErrorRef}>{error.poRequiredError && <div className={'form__error'}>PO Number is required</div>}</div>
      </div>
      <h4 className={`${styles.shipToLabel} hdg hdg--5`}>Ship To:</h4>
      <div className={'split'}>
        <div>{getAddress()}</div>
        <div onClick={handleShipping} className='btn btn__text-only btn--large'>
          Edit Shipment
        </div>
      </div>

      <hr className={orderStyles['df__divider--small-margin']} />
      {orders.length > 0 && <OrderReviewTable orders={cart} />}

      <hr className='hr' />
      <div className={styles['order-review__total-container']}>
        <div className={styles['order-review__total-item-container']}>
          <h3 className='hdg hdg--4'>Sub Total</h3> <div>{formatCurrency(currency, cartTotal)}</div>
        </div>

        <div className={styles['order-review__total-item-container']}>
          <h4 className='hdg hdg--4 hdg--thin'>Taxes</h4> <div>{formatCurrency(currency, tax)}</div>
        </div>

        <div className={styles['order-review__total-item-container']}>
          <h3 className='hdg hdg--4'>Total</h3>{' '}
          <div>{tax !== 0 ? formatCurrency(currency, cartTotal + tax) : formatCurrency(currency, cartTotal)}</div>
        </div>
      </div>
      {error && (
        <Error4xx
          onLight
          // eslint-disable-next-line quotes
          overrideText={{ headingText: "We're sorry but it looks like an error occurred and we cannot complete your order at this time." }}
          response={error.errorMessage ? error.errorMessage : null}
        />
      )}
      <OrderFooter stepName='orderReview' handleOrder={submitOrder} orders={orders} />
    </div>
  );
};

export default OrderReview;
