import { BaseModel } from "sjs-base-model";
import IHour from './IHour';

export default class HourResponseModel extends BaseModel {
    public readonly data: IHour;
    public readonly success: boolean = true;
    public readonly errors: string[] = [];


    constructor(data: any) {
        super();

        this.update(data);
    }

    public update(data: Partial<HourResponseModel>): void {
        super.update(data);
    }
}