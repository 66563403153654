import React, { useState, createContext, FC } from 'react';
import { initialLocation } from 'utilities/Location/models/InitialLocation';
import ILocationState from 'utilities/Location/models/ILocationState';
import IStatus from 'utilities/Location/models/IStatus';
import ILocations from 'utilities/Location/models/ILocations';
import ILocationTabState from 'utilities/Location/models/ILocationTabState';
import { useSessionStorage } from 'middlewares/hooks/useSessionStorage';

interface ILocationContext {
  locationState: ILocationState;
  accountLocations?: ILocations[];
  locationTabState: ILocationTabState;
  statusTable: IStatus[];
  updateLocationProperty?: (propName: string, propValue: any) => void;
  updateAllLocationState?: (location: ILocationState) => void;
  setLocationTabStateProperty?: (propName: string, propValue: any) => void;
  updateAllLocationStateAndTabState?: (location: ILocationState, tabState: ILocationTabState) => void;
  resetAllLocationStateAndTabState?: () => void;
  setStatusState?: (status: IStatus[]) => void;
  updateAccountLocations?: (location: ILocations[]) => void;
}

const initialTabState: ILocationTabState = {
  hasAddress: false,
  hasCertifications: false,
  hasEnvironment: false,
  hasTechnology: false,
  tabIndex: 0,
};

const initialStatusTable: IStatus[] = [
  {
    id: '',
    name: '',
    active: false,
    description: '',
  },
];

const initialState = {
  locationState: { ...initialLocation },
  accountLocations: undefined,
  locationTabState: { ...initialTabState },
  statusTable: { ...initialStatusTable },
};

const LocationContext = createContext<ILocationContext>({ ...initialState });

const { Provider } = LocationContext;

const LocationProvider: FC = ({ children }) => {
  const [accountLocations, setAccountLocations] = useState<ILocations[] | undefined>(undefined);
  const [locationState, setLocationState] = useState<ILocationState>(initialLocation);
  const [locationTabState, setLocationTabState] = useSessionStorage('locationTabState', initialTabState);
  const [statusTable, setStatusTable] = useState<IStatus[]>(initialStatusTable);

  const setStatusState = (status: IStatus[]) => {
    setStatusTable(status);
  };

  const updateLocationProperty = (propName: string, propValue: any) => {
    setLocationState((prevState) => {
      return {
        ...prevState,
        [propName]: propValue,
      };
    });
  };

  const updateAllLocationState = (location: ILocationState) => {
    setLocationState((prevState) => {
      return {
        ...prevState,
        ...location,
      };
    });
  };

  const setLocationTabStateProperty = (propName: string, propValue: any) => {
    setLocationTabState({ ...locationTabState, [propName]: propValue });
  };

  const updateAllLocationStateAndTabState = (location: ILocationState, tabState: ILocationTabState) => {
    updateAllLocationState(location);
    setLocationTabState((prevState) => {
      return {
        ...prevState,
        ...tabState,
      };
    });
  };

  const updateAccountLocations = (location: ILocations[]) => {
    setAccountLocations(location);
  };
  /**
   * This method reset the Location Context, currently it is not being implemented but may need it in the future
   * Passing in the initial state for locationState and locationTabState
   */
  const resetAllLocationStateAndTabState = () => {
    updateAllLocationStateAndTabState(initialState.locationState, initialState.locationTabState);
  };

  return (
    <Provider
      value={{
        locationState,
        accountLocations,
        locationTabState,
        statusTable,
        updateLocationProperty,
        updateAllLocationState,
        setLocationTabStateProperty,
        updateAllLocationStateAndTabState,
        resetAllLocationStateAndTabState,
        setStatusState,
        updateAccountLocations,
      }}
    >
      {children}
    </Provider>
  );
};

export { LocationContext, LocationProvider };
