import IFeatureFlagState from './models/IFeatureFlagState';
import FeatureFlagAction from './FeatureFlagAction';
import IAction from 'models/IAction';
import BaseReducer from 'utilities/BaseReducer';
import IFeatureFlag from './models/IFeatureFlag';

export default class FeatureFlagReducer extends BaseReducer<IFeatureFlagState> {
  public readonly initialState: IFeatureFlagState = {
    featureFlagItems: null,
  };

  public [FeatureFlagAction.REQUEST_FEATURE_FLAG](state: IFeatureFlagState, action: IAction<IFeatureFlag>): IFeatureFlagState {
    return {
      ...state,
      featureFlagItems: action.payload!,
    };
  }
}
