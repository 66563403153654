import styles from './NotFoundPage.module.scss';
import React from 'react';
import mrPingLogo from '../../assets/media/images/mr-ping-logo-01.png';

interface IProps {
  message: string;
}

const NotFoundPage = ({ message }: IProps) => {
  const messageStr = message || 'You are temporarily OB, and the page you’re looking for can’t be found.';

  return (
    <div className='wrapper'>
      <div className={styles.center}>
        <h1 className='hdg hdg--1'>Oops!</h1>
        <div className='vr4' />
        <p className={styles.message}>{messageStr}</p>
        <img src={mrPingLogo} className={styles.logo} alt='Mr. Ping logo' />
        <p className={styles.message}>So let's get back in the fairway:</p>
        <a href='/' className={styles.link}>
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
