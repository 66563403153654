/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import styles from './OrderItems.module.scss';
import React, { useMemo } from 'react';
import IOrder from '../../../../stores/orders/models/IOrder';
import UserRolesEnum from 'constants/UserRolesEnum';
import OrderDetailTable from '../OrderDetailTable/OrderDetailTable';
import { Description, Status, Invoice, Money, Tracking } from './helpers/OrderItemsHelperComps';
import AccessControl from 'components/AccessControl/AccessControl';
import { getItemData } from './helpers/getItemData';
import { DATE_HELPER } from 'index';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  order: IOrder;
  setTracking: any;
  tracking: any;
}

function OrderItems({ order, tracking, setTracking }: IProps): JSX.Element {
  const itemData = getItemData(order);
  const currency = order.currency;
  const getOrderTotal = (data) => {
    const totals = data.map((item) => parseFloat(item.itemTotal));
    const total = totals.reduce((a, b) => {
      return a + b;
    }, 0);

    return total;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'itemNumber',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 350,
        Cell: ({ cell: { value } }) => <Description value={value} />,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Unit Price',
        accessor: 'unitPrice',

        Cell: ({ cell: { value } }) => (
          <AccessControl allowedRole={UserRolesEnum.ViewOrderPricing}>
            <Money value={formatCurrency(currency, value)} />
          </AccessControl>
        ),
      },
      {
        Header: 'Item Total',
        accessor: 'itemTotal',
        Cell: ({ cell: { value } }) => (
          <AccessControl allowedRole={UserRolesEnum.ViewOrderPricing}>
            <Money value={formatCurrency(currency, value)} />
          </AccessControl>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }) => <Status value={value} />,
      },
      {
        Header: 'Est. Date',
        accessor: 'estimatedDate',
        Cell: ({ cell: { value } }) => DATE_HELPER.getDisplayDate(value),
      },
      {
        Header: 'Invoice',
        accessor: 'invoice',
        Cell: ({ cell: { value } }) => <Invoice value={value} />,
      },
      {
        Header: 'Tracking',
        accessor: 'tracking',
        Cell: ({ cell: { value } }) => <Tracking value={value} setTracking={setTracking} tracking={tracking} />,
      },
    ],
    [tracking, setTracking,currency]
  );

  return (
    <div className={styles['order-items']}>
      <div className={styles['order-items__hd']}>
        <h3 className='hdg--3'>Order Items</h3>
      </div>
      <div className={styles['order-items__bd']}>
        <OrderDetailTable columns={columns} data={itemData} />
        <div className={styles['order-items__total']}>
          <AccessControl allowedRole={UserRolesEnum.ViewOrderPricing}>
            <>
              <span>Order total</span> {formatCurrency(currency, getOrderTotal(itemData))}
            </>
          </AccessControl>
        </div>
      </div>
      <div className='vr vr4'></div>
    </div>
  );
}

export default OrderItems;
