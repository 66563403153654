/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import orderStyles from './OrderCart.module.scss';
import { usePopper } from 'react-popper';
import { openModalDynamic } from 'containers/OrderDetailPage/components/OrderItems/helpers/OrderItemsHelperComps';
import { ReactComponent as ArrowDown } from '../../../../assets/media/icons/icon-caret-down--brand-primary.svg';
import OrderCartModal from '../orderCartModal/OrderCartModal';
import { formatCurrency } from 'utilities/FormatCurrency';

const OrderCart = ({ setOpen, isOpen, cartState, accountCurrency }) => {
  const cartItems = cartState.cartItems;
  let cartTotal = 0;
  let currency: string = cartItems.length === 0 ? accountCurrency : '';
  cartItems.length > 0 &&
    cartItems.forEach((item) => {
      const itemPrice = item.price * item.quantity * item.itemSetCount;
      cartTotal = cartTotal + itemPrice;
      currency = item.currency;
    });
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const yoRef = useRef<any>(null);

  const { styles, attributes, update } = usePopper<any>(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [update, cartState, isOpen]);

  return (
    <div className={orderStyles.orderCartWrapper}>
      <div
        className={`${orderStyles.orderCart} ${isOpen ? orderStyles.orderCartOpen : ''}`}
        onClick={() => openModalDynamic(setOpen, isOpen, yoRef)}
        ref={setReferenceElement}
      >
        {/* <div className={`${orderStyles.orderCart} ${isOpen ? orderStyles.orderCartOpen : ''}`} onClick={openPopper} ref={setReferenceElement}> */}
        <div className={orderStyles.orderWrapper}>
          <div>
            <div className={orderStyles.orderItems}>Order Items ({cartItems.length})</div>
            <div className='vr1' />
            <h5 className='hdg hdg--3'>{currency && formatCurrency(currency, cartTotal)}</h5>
          </div>
          <span className={`btn__icon ${orderStyles.orderCartArrow}`}>
            <ArrowDown aria-hidden='true' focusable='false' role='img' />
          </span>
        </div>
      </div>

      {isOpen && (
        <div ref={yoRef}>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
            }}
            className={orderStyles.orderPopper}
            {...attributes.popper}
          >
            <OrderCartModal orders={cartState} currency={currency} cartTotal={cartTotal} setOpen={setOpen} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderCart;
