import _ from 'lodash';
import CreateOrderStatelessCalls from 'stores/create-order/CreateOrderStatelessCalls';
import { IDynamicFormAttribute, IDynamicFormAttributeDependecyResponse } from 'stores/create-order/models/IDynamicFormModels';
import { PRODUCT_CONFIG_LOCAL_STORE, ESTIMATED_DATE } from '../ProductConfig';
import generateFieldState from './generateFieldState';
import getCurrentStep from './getCurrentStep';
import { getDependencyFieldsToCall } from './getDependencyFieldsToCall';

interface IGenerateFormWithNewSelectionLoop {
  product;
  masterFieldObject: IDynamicFormAttribute[];
  setMasterFieldObject;
  fieldState;
  setFieldState;
  accountNumber;
  selectedValue?;
  setSubmittedList;
  finalCall;
  step;
  setStep;
  nullList;
  setIsLoading;
  savedSubmittedList;
  setErrorMessage;
  originalMasterFieldObject;
  setPriceTotal;
  userSelectedCache?;
  firstLoop?;
}

interface IUserSelectedCache {
  attributeName: string;
  selectedValue: any;
  userSelected: boolean | undefined;
}

/**
 * Regenerates the form with the newly selected user values
 *
 * Loops through all fields that can make dependency calls to see if the current value can still exist
 *
 * Regenerates the form based on the responses of those calls.
 * @param param0 object
 */
export default async function generateFormWithNewSelectionLoop({
  product,
  masterFieldObject,
  setMasterFieldObject,
  fieldState,
  setFieldState,
  accountNumber,
  selectedValue,
  setSubmittedList,
  finalCall,
  step,
  setStep,
  setIsLoading,
  savedSubmittedList,
  setErrorMessage,
  originalMasterFieldObject,
  setPriceTotal,
  userSelectedCache,
  nullList,
  firstLoop,
}: IGenerateFormWithNewSelectionLoop) {
  // set default date
  ESTIMATED_DATE.resetProperties();
  ESTIMATED_DATE.setProduct(product);
  let newUserSelectedCache: IUserSelectedCache[] | undefined = userSelectedCache;
  if (firstLoop) {
    newUserSelectedCache = masterFieldObject.map(
      (item: IDynamicFormAttribute): IUserSelectedCache => {
        return {
          attributeName: item.attributeName,
          selectedValue: item.attributeName === selectedValue.Name ? selectedValue.Value : item.selectedValue,
          userSelected: item.userSelected,
        };
      }
    );
  }

  // Clone masterFieldObject and its nested attributes including the values array
  let newMasterFieldObject: IDynamicFormAttribute[] = _.cloneDeep(originalMasterFieldObject);
  const submittedList = savedSubmittedList;
  const newNullList = nullList;

  // Sets all values other then the initial one passed in as null
  if (selectedValue) {
    newMasterFieldObject = newMasterFieldObject.map((item, index) => {
      if (item.independentVariable1 === '' && item.independentVariable2 === '') {
        return {
          ...item,
          selectedValue: newUserSelectedCache?.[index].selectedValue,
        };
      }
      return {
        ...item,
        selectedValue: null,
      };
    });
  }

  // Set clubs to null if field above clubs was reselected
  // If hasClubsFieldIndex is less than 0, then a user selected a Product without a clubs field option I.E (Putter, Accessories, Headwear)'
  // Clubs Field Option only applies to clubs that are either Fairways, Driver or Irons
  if (firstLoop) {
    const hasClubsFieldIndex = newMasterFieldObject.findIndex((item) => item.attributeName === 'CLUBS');
    if (hasClubsFieldIndex >= 0) {
      const changedIndex = newMasterFieldObject.findIndex((item) => item.attributeName === selectedValue.Name);
      submittedList.push('CLUBS');
      // if user selected an attribute option before the club options (IE MODELS)
      if (changedIndex < hasClubsFieldIndex) {
        PRODUCT_CONFIG_LOCAL_STORE.shouldClearClubs = true;
        newMasterFieldObject[hasClubsFieldIndex].selectedValue = [];
        newMasterFieldObject[hasClubsFieldIndex].attributeValues = [];
      }
      // if a user selected an attribute after the club options field
      else if (changedIndex > hasClubsFieldIndex) {
        newMasterFieldObject[hasClubsFieldIndex] = {
          ...newMasterFieldObject[hasClubsFieldIndex],
          attributeValues: masterFieldObject[hasClubsFieldIndex].attributeValues,
          selectedValue: masterFieldObject[hasClubsFieldIndex].selectedValue,
        };
      }
    }
  }
  // Set Department always assuming this is the first thing we set
  if (firstLoop) {
    const departmentIndex = newMasterFieldObject.findIndex((item) => item.attributeName === 'DEPARTMENT');
    newMasterFieldObject[departmentIndex].selectedValue = newUserSelectedCache?.[departmentIndex].selectedValue;
    submittedList.push('DEPARTMENT');
  }

  // If in null list set value to null and reset user selected
  newMasterFieldObject = newMasterFieldObject.map((item) => {
    if (newNullList.includes(item.independentVariable1) || newNullList.includes(item.independentVariable2)) {
      newNullList.push(item.attributeName);
      return {
        ...item,
        selectedValue: null,
        userSelected: false,
      };
    }

    return item;
  });

  /**
   * This finds the first field that can make a call
   *
   * Only used to see if we need to loop;
   * This is where we will need to make async
   * can we use helper function
   */
  const fieldsToCall = getDependencyFieldsToCall(newMasterFieldObject, submittedList);
  if (fieldsToCall.length > 0) {
    const dependencyCallResponses = await Promise.all(
      fieldsToCall.map(async (attribute: IDynamicFormAttribute) => {
        const invar1Value = newMasterFieldObject.find((item) => item.attributeName === attribute.independentVariable1)?.selectedValue;
        const invar2Value = attribute.independentVariable2
          ? newMasterFieldObject.find((item) => item.attributeName === attribute.independentVariable2)?.selectedValue
          : '';

        const requestConfig = {
          product,
          dependentVariable: attribute.attributeName,
          independentVariable1: attribute.independentVariable1,
          independentVariable1Value: invar1Value === 'None' ? ' ' : invar1Value,
          independentVariable2: attribute.independentVariable2,
          independentVariable2Value: invar2Value === 'None' ? ' ' : invar2Value,
          accountNumber,
          setIsLoading,
        };

        const fieldResponse = await CreateOrderStatelessCalls.getFieldsUpdate(requestConfig);

        // checking for errors
        if (fieldResponse.data === undefined) {
          return fieldResponse;
        }

        const responseValues: string[] = fieldResponse.data.data.map((item) => item.dependentVariableValue);
        submittedList.push(attribute.attributeName);

        // Filters the field down
        attribute.attributeValues = attribute.attributeValues.filter((value) => responseValues.includes(value.attributeValue));

        let nulled = false;
        // check cache if user value is still good.
        if (attribute.userSelected) {
          const cachedValue = newUserSelectedCache?.find((item) => item.attributeName === attribute.attributeName)?.selectedValue;
          if (responseValues.includes(cachedValue)) {
            attribute.selectedValue = cachedValue;
          } else {
            attribute.selectedValue = null;
            nulled = true;
          }
        }

        // set the call field if the length is only one
        if (attribute.attributeValues.length === 1) {
          attribute.selectedValue = attribute.attributeValues[0].attributeValue;
        }

        // set the call field if the if there is a default value.
        const fieldResponsDefault: IDynamicFormAttributeDependecyResponse | undefined = fieldResponse.data.data.find(
          (item: IDynamicFormAttributeDependecyResponse) => item.defaultFlag === 'Y'
        );
        if (fieldResponsDefault && !attribute.userSelected) {
          attribute.selectedValue = fieldResponsDefault.dependentVariableValue;
        }

        if (nulled && attribute.attributeValues.length !== 1) {
          newNullList.push(attribute.attributeName);
        }

        // We need to reinsert the callfield
        const attributeIndex = newMasterFieldObject.findIndex((item) => item.attributeName === attribute.attributeName);
        newMasterFieldObject[attributeIndex].attributeValues = attribute.attributeValues;

        return 'completed';
      })
    ); // end of promise

    // return early with error
    // TODO: should check off HttpErrorResponseModel when the httpUtility in the dependency call has been changed to use the EffectUtillity
    const error = dependencyCallResponses.find((item) => item !== 'completed');
    if (error) {
      setIsLoading(false);
      setErrorMessage(error);

      return;
    }

    generateFormWithNewSelectionLoop({
      product,
      masterFieldObject,
      setMasterFieldObject,
      fieldState,
      setFieldState,
      nullList: newNullList,
      accountNumber,
      setSubmittedList,
      finalCall,
      step,
      setStep,
      setIsLoading,
      setPriceTotal,
      savedSubmittedList: submittedList,
      setErrorMessage,
      originalMasterFieldObject: newMasterFieldObject,
      userSelectedCache: newUserSelectedCache,
    });
  } else {
    const newStep = getCurrentStep(newMasterFieldObject, setErrorMessage);

    // Sets Values in ESTIMATED_DATE needed for generateFieldState below
    const updatedFields = generateFieldState(newMasterFieldObject, newStep, setStep);

    setStep(newStep);
    setSubmittedList(submittedList);
    setMasterFieldObject(newMasterFieldObject);
    // Must be last - otherwise clubs field will not be called
    setFieldState(updatedFields);
    setIsLoading(false);
  }
}
