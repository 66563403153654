import styles from './CreditSummary.module.scss';
import React from 'react';
import ICreditSummary from '../../../../stores/credit-summary/models/ICreditSummary';
import { formatCurrency } from 'utilities/FormatCurrency';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import AccountInfoResponseModel from 'stores/accounts/models/AccountInfoResponseModel';
import IAccountInfo from 'stores/accounts/models/IAccountInfo';

interface IProps {
  creditSummary?: ICreditSummary;
}
interface IEntries {
  label: string;
  value: number;
}
const CreditSummary = ({ creditSummary }: IProps) => {
  if (!creditSummary) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const accountInfoResponse: AccountInfoResponseModel | null = useSelector((state: IStore) => oc(state).accounts.accountsInfoResponse(null));
  const accountInfo: IAccountInfo | undefined = accountInfoResponse?.data;
  const currency: string = accountInfo ? accountInfo.currency : 'USD';
  const entries: IEntries[] = [
    { label: 'Credit Limit', value: creditSummary.creditLimit },
    { label: 'Balance', value: creditSummary.currentBalance },
    { label: 'Available Credit', value: creditSummary.availableCredit },
    { label: 'Open Credit', value: creditSummary.openCredit },
  ];
  return (
    <div className={styles['summary-grid']}>
      <dl className={styles['summary-grid__list']}>
        {entries.map((entry) => {
          return (
            <div key={entry.label} className={styles['summary-grid__list__item']}>
              <dt className={styles['summary-grid__list__item__label']}>{entry.label}</dt>
              <dd className={styles['summary-grid__list__item__value']}>{formatCurrency(currency, entry.value)}</dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};
export default CreditSummary;
