import React from 'react';
import RouteEnum from 'constants/RouteEnum';
import { Link } from 'react-router-dom';
import styles from './CreateFirstLocation.module.scss';

const CreateFirstLocation = () => {
  return (
    <div className={styles.locationContainer}>
      <h1 className={styles.locationContainer_header}>No Fitting Locations Yet</h1>
      <p className={styles.locationContainer_text}>Create a new Fitting Location to display on the ping.com Fitting Map.</p>
      <Link to={`${RouteEnum.FittingLocationSetup}`} className='btn'>
        <span className={styles.locationContainer_btnText}>Add Fitting Location</span>
      </Link>
    </div>
  );
};

export default CreateFirstLocation;
