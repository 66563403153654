import environment from 'environment';
import i18n from 'i18n';

const language = i18n.language;

/**
 * This formats a currency to the locale language.
 * @param currency string defaults to environment base currency
 * @param value number
 * @returns currency formatted
 */
export function formatCurrency(currency: string, value: number) {
  let currencyValue;
  if (currency === '') {
    currencyValue = environment.defaultCurrency;
  } else {
    currencyValue = currency;
  }
  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyValue,
    currencyDisplay: 'narrowSymbol',
  });
  return formatter.format(value);
}
