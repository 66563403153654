import { useEffect } from 'react';
import { closeOnEscape } from './keyboard';

export function useCloseOnEscape(isOpen, setIsOpen) {
  useEffect(() => {
    const boundCloseOnEscape = (event) => closeOnEscape(event, setIsOpen);

    if (isOpen) {
      window.addEventListener('keyup', boundCloseOnEscape);
      return () => {
        window.removeEventListener('keyup', boundCloseOnEscape);
      };
    }
  }, [isOpen, setIsOpen]);
}
