import styles from './DatePicker.module.scss';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ReactComponent as CaretDown } from '../../assets/media/icons/icon-caret-down.svg';
import OrdersRequestModel from '../../stores/orders/models/OrdersRequestModel';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import { useLocation } from 'react-router-dom';
interface IProps {
  classNames?: string;
  endDate: OrdersRequestModel['endDate'];
  startDate: OrdersRequestModel['startDate'];
  updateDates: Function;
  setShouldShowDatePicker: (v: boolean) => void;
}

interface IDateHeader {
  date: any;
  selectedDate: any;
  start?: boolean;
}
// eslint-disable-next-line react/prop-types
const DateHeader = ({ date, start, selectedDate }: IDateHeader) => {
  return (
    <>
      <div className={styles.dateHeader__startEnd}>{start ? 'Start Date' : 'End Date'}</div>
      <div>{moment(selectedDate).format('MMM, D')}</div>
      <div className={styles.dateHeader__monthYear}>{moment(date).format('MMMM, YYYY')}</div>
    </>
  );
};

const selectedTile = ({ dateState, date }) => {
  const dateF = new Date(moment(date).format(DateFormatsEnum.MonthDayYear));
  const startF = new Date(moment(dateState.startDate).format(DateFormatsEnum.MonthDayYear));
  const endF = new Date(moment(dateState.endDate).format(DateFormatsEnum.MonthDayYear));

  if (dateF > startF && dateF < endF) {
    return 'react-calander__ping-highlight-range';
  }

  if (moment(startF).isSame(dateF)) {
    return 'react-calendar__ping-highlight-start';
  }

  if (moment(endF).isSame(dateF)) {
    return 'react-calendar__ping-highlight-end';
  }

  return null;
};

function DatePicker({ classNames, endDate, startDate, updateDates, setShouldShowDatePicker }: IProps) {
  const objectStartDate = moment(startDate).toDate();
  const objectEndDate = moment(endDate).toDate();
  const [dateState, setDateState] = useState({
    startDate: objectStartDate,
    endDate: objectEndDate,
  });

  const pathname = useLocation().pathname;

  const handleDate = (newDate, type) => {
    // Object Literal switches
    const changeDate = {
      start: () => {
        if (newDate <= dateState.endDate) {
          setDateState({ ...dateState, startDate: newDate });
        }
      },
      end: () => {
        if (newDate >= dateState.startDate) {
          setDateState({ ...dateState, endDate: newDate });
        }
      },
      def: () => {
        console.warn('Must Specify whether handling start or end');
      },
    };
    changeDate[type]();
  };

  const handleDone = () => {
    updateDates(dateState);
    setShouldShowDatePicker(false);
  };

  const CALENDAR_SHARED_PROPS = {
    className: 'react-calendar-ping',
    formatShortWeekday: (locale: any, date: Date): string => date.toLocaleString(locale, { weekday: 'narrow' }),
    minDetail: 'decade' as const,
    navigationAriaLabel: 'Go up',
    nextAriaLabel: 'Next',
    selectRange: false,
    nextLabel: (
      <>
        <CaretDown className='btn_icon icon--dir-90' aria-hidden='true' focusable='false' role='img' />
      </>
    ),
    prevAriaLabel: 'Previous',
    prevLabel: (
      <>
        <CaretDown className='btn_icon icon--dir-270' aria-hidden='true' focusable='false' role='img' />
      </>
    ),
    showNeighboringMonth: false,
  };

  return (
    <div>
      <div className={`${styles['date-picker']} ${classNames ? classNames : ''}`}>
        <Calendar
          {...CALENDAR_SHARED_PROPS}
          navigationLabel={({ date, label, locale, view }) => <DateHeader date={date} selectedDate={dateState.startDate} start />}
          tileClassName={({ date }) => selectedTile({ date, dateState })}
          onChange={(startDate) => handleDate(startDate, 'start')}
          value={dateState.startDate}
        />
        <Calendar
          {...CALENDAR_SHARED_PROPS}
          navigationLabel={({ date, label, locale, view }) => <DateHeader date={date} selectedDate={dateState.endDate} />}
          minDate={dateState.startDate}
          tileClassName={({ date }) => selectedTile({ date, dateState })}
          onChange={(endDate) => handleDate(endDate, 'end')}
          value={dateState.endDate}
        />
        <div className={`${styles['date-picker__footer']} split`}>
          {pathname === '/orders' && <p>Filter By Order Date</p>}
          {pathname === '/transactions' && <p>Filter By Transaction Date</p>}
          <div className={styles['date-picker__btn-container']}></div>
          <button onClick={handleDone} className='btn btn__text-only'>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default DatePicker;
