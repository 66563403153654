import styles from '../InvoicePage.module.scss';
import React from 'react';

const MemoTotals = ({ memoData }) => {
  const {
    creditDebitTotals: { balanceDue, total },
  } = memoData[0];
  return (
    <div className={styles['invoice-grid-total']}>
      <div className={styles['invoice-grid__total__item']}>Total</div>
      <div className={styles['invoice-grid__total__item']}>${(Math.round(total * 100) / 100).toFixed(2)}</div>

      <div className={styles['invoice-grid__total__item']}>Balance</div>
      <div className={styles['invoice-grid__total__item']}>${balanceDue.toFixed(2)}</div>
    </div>
  );
};

export default MemoTotals;
