import IOrdersState from './models/IOrdersState';
import OrdersAction from './OrdersAction';
import OrdersResponseModel from './models/OrdersResponseModel';
import OrdersSummaryResponseModel from './models/OrdersSummaryResponseModel';
import OrderResponseModel from './models/OrderResponseModel';
import IAction from '../../models/IAction';
import BaseReducer from '../../utilities/BaseReducer';
import OrdersRequestModel from './models/OrdersRequestModel';

export default class OrdersReducer extends BaseReducer<IOrdersState> {
  public readonly initialState: IOrdersState = {
    orderResponse: null,
    ordersResponse: null,
    ordersDashboardResponse: null,
    ordersSummary: null,
    ordersFilters: null,
  };

  public [OrdersAction.SAVE_FILTERS](state: IOrdersState, action: IAction<OrdersRequestModel>): IOrdersState {
    return {
      ...state,
      ordersFilters: action.payload!,
    };
  }

  public [OrdersAction.REQUEST_ORDER_FINISHED](state: IOrdersState, action: IAction<OrderResponseModel>): IOrdersState {
    return {
      ...state,
      orderResponse: action.payload!,
    };
  }

  public [OrdersAction.RESET_ORDER_FINISHED](state: any, action: any): any {
    return {
      ...state,
      orderResponse: action.payload!,
    };
  }

  public [OrdersAction.RESET_ORDERS](state: any, action: any): any {
    return {
      ...state,
      ordersResponse: null,
      ordersDashboardResponse: null,
      ordersSummary: null,
    };
  }

  public [OrdersAction.REQUEST_ORDERS_FINISHED](state: IOrdersState, action: IAction<OrdersResponseModel>): IOrdersState {
    return {
      ...state,
      ordersResponse: action.payload!,
    };
  }

  public [OrdersAction.REQUEST_ORDERS_DASHBOARD_FINISHED](state: IOrdersState, action: IAction<OrdersResponseModel>): IOrdersState {
    return {
      ...state,
      ordersDashboardResponse: action.payload!,
    };
  }

  public [OrdersAction.REQUEST_ORDERS_SUMMARY_FINISHED](state: IOrdersState, action: IAction<OrdersSummaryResponseModel>): IOrdersState {
    return {
      ...state,
      ordersSummary: action.payload!,
    };
  }
}
