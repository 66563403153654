import React from 'react';
import UserRolesEnum from '../../constants/UserRolesEnum';
import IPermission from 'stores/users/models/IPermission';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import NotFoundPage from 'containers/NotFoundPage/NotFoundPage';
import { selectRequesting } from 'selectors/requesting/RequestingSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

interface AccessControlProps {
  allowedRole: UserRolesEnum;
  children: JSX.Element;
  noAccessRedirect?: boolean;
  noAccessRender?: () => {};
  errorResponse?: HttpErrorResponseModel;
  defaultErrorResponse?: boolean;
}

export const checkRoles = (userRoles: IPermission[], allowedRole: UserRolesEnum, isAdmin: boolean) => {
  if (allowedRole === UserRolesEnum.PingAdmin && isAdmin) {
    return true;
  }

  if (allowedRole.length === 0) {
    return true;
  }

  for (const role of userRoles) {
    if (allowedRole === role.key) {
      return true;
    }
  }
};

export default function AccessControl(props: AccessControlProps): JSX.Element {
  const { allowedRole, children, noAccessRedirect, noAccessRender, errorResponse, defaultErrorResponse } = props;
  const errorDefault = new HttpErrorResponseModel();
  errorDefault.statusCode = 401;
  errorDefault.userMessage = 'User is unauthorized. You may not have the appropriate permissions to access this page.';
  const requestingCurrentUser = useSelector((state: IStore) => selectRequesting(state, ['UsersAction.REQUEST_SET_CURRENT_USER']));
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const userRoles: IPermission[] = currentUser ? currentUser.data.roles : [];
  const isAdmin: boolean = Boolean(currentUser?.data.memberships?.find((dm) => dm?.key === UserRolesEnum.PingAdmin));
  const permitted = checkRoles(userRoles, allowedRole, isAdmin);

  if (!currentUser || requestingCurrentUser) {
    return <></>;
  }

  return (
    <>
      {permitted && children}
      {!permitted && noAccessRender && noAccessRender()}
      {!permitted && noAccessRedirect && (
        <NotFoundPage message={'Access is denied. You may not have the appropriate permissions to access this page.'} />
      )}
      {!permitted && errorResponse && <Error4xx response={errorResponse}></Error4xx>}
      {!permitted && defaultErrorResponse && !errorResponse && <Error4xx response={errorDefault}></Error4xx>}
    </>
  );
}
