/* eslint-disable react/display-name */
/* eslint-disable no-useless-rename */
import React, { useMemo, useEffect, useState } from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import OrderReviewDescription from './components/orderReviewDescription/OrderReviewDescription';
import OrderReviewQuantity from './components/orderReviewQuantity/OrderReviewQuantity';
import { IGetCartResponse } from 'stores/create-order/models/IGetCart';
import { DATE_HELPER } from 'index';
import { formatCurrency } from 'utilities/FormatCurrency';

export interface OrderReviewTableProps {
  orders: IGetCartResponse | null;
  setOpen?: (v: boolean) => void;
}

function OrderReviewTable({ orders, setOpen }: OrderReviewTableProps): JSX.Element {
  const tableData = orders?.cartItems.map((item) => {
    const { type: product, description: frontEndDescription, price, currency, quantity: quantity, cartItemID: frontEndItemID } = item;
    return {
      description: {
        item: product,
        frontEndDescription,
        itemId: frontEndItemID,
        qty: quantity,
        // handleItemEdit: onHandleItemEdit,
      },
      unit: formatCurrency(currency, price),
      total: formatCurrency(currency, price),
      qty: {
        qty: quantity,
        itemId: frontEndItemID,
        // onHandleQtyInc,
      },

      estimatedDate: item.estimatedDate !== 'error' ? item.estimatedDate : 'N/A',
    };
  });

  const [tableState, setTableState] = useState(tableData);

  useEffect(() => {
    const newTableState = orders?.cartItems.map((item) => {
      const {
        type: product,
        description: frontEndDescription,
        price,
        currency,
        quantity: quantity,
        // cartID: itemSetCount,
        tagName: tagName,
        cartItemID: frontEndItemID,
        itemSetCount,
      } = item;
      return {
        description: {
          item: product,
          frontEndDescription,
          itemId: frontEndItemID,
          qty: quantity,
          tagName,
          // handleItemEdit: onHandleItemEdit,
        },
        piece: itemSetCount ? itemSetCount : 1,
        unit: formatCurrency(currency, price),
        total: formatCurrency(currency, price * quantity * (itemSetCount ? itemSetCount : 1)),
        qty: {
          qty: quantity,
          itemId: frontEndItemID,
          // onHandleQtyInc,
          // onHandleQtyDec,
        },
        estimatedDate: item.estimatedDate !== 'error' ? item.estimatedDate : 'N/A',
      };
    });

    setTableState(newTableState);
    // eslint-disable-next-line
  }, [orders]);

  const columns = useMemo(
    () => [
      {
        Header: 'Product / Description / tag',
        accessor: 'description',
        Cell: ({ cell: { value } }) => <OrderReviewDescription setOpen={setOpen} value={value} />,
        width: 350,
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Qty</div>,
        accessor: 'qty',
        Cell: ({ cell: { value } }) => <OrderReviewQuantity value={value} />,
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Piece</div>,
        accessor: 'piece',
        Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Unit</div>,
        accessor: 'unit',
        Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Total</div>,
        accessor: 'total',
        Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div>,
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Est Date</div>,
        accessor: 'estimatedDate',
        Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value ? DATE_HELPER.getDisplayDate(value) : 'Loading...'}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <BasicTable columns={columns} data={tableState} />
    </>
  );
}

export default OrderReviewTable;
