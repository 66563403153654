import React, { useEffect, useState } from 'react';
import PingAddressUtitlity from 'utilities/AddressUtility';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import sanatizeHtmlString from 'utilities/HtmlStringSanitizeUtility';
import { PingCustomerServiceContact } from 'components/Content/Contact/PingCustomerServiceContact';

export const PingAddress = () => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState(false);
  useEffect(() => {
    PingAddressUtitlity.getPingAddress().then((data) => {
      setError(!data);
      setAddress(data && sanatizeHtmlString(data, ['p']));
    });
  }, []);
  return address ? (
    <div dangerouslySetInnerHTML={{ __html: address }} />
  ) : error ? (
    <>
      <p>Error retrieving address</p>
      <p>
        If this problem persists please contact Customer Service at <PingCustomerServiceContact />.
      </p>
    </>
  ) : (
    <LoadingIndicator isActive={true} />
  );
};
