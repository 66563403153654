import { BaseModel } from 'sjs-base-model';
import IUpdateUserResponse from './IUpdateUserResponse';

export default class UpdateUserResponseModel extends BaseModel {
  data: IUpdateUserResponse;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
