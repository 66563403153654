import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import LocationForm from 'components/Form/LocationForm';
import FittingLocationContactArr from 'constants/FittingLocationContactArr';
import debounce from 'lodash.debounce';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import ContactResponseModel from 'utilities/Location/models/Contact/ContactResponseModel';
import ContactRequestModel from 'utilities/Location/models/Contact/ContactRequestModel';
import IContact from 'utilities/Location/models/Contact/IContact';
import UpdateContactRequestModel from 'utilities/Location/models/Contact/UpdateContactRequestModel';
import LocationContactUtility from 'utilities/Location/LocationContactUtility';

interface IContactState {
  id: string;
  name: string | null;
  phone: string | null;
  website: string | null;
  countryCodeName: string | null;
  countryCodeNumber: string | null;
}
export interface ISelectedCountryCode {
  countryCodeName: string;
  countryCodeNumber: string;
}

interface IProps {
  formRef: any;
  setSaved: (isSaved: boolean) => void;
}

const LocationContact = ({ formRef, setSaved }: IProps) => {
  const { locationState } = useContext(LocationContext);
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const errorMessage: string = 'Error loading creating contacts.';
  const [contactState, setContactState] = useState<IContactState>({
    id: '',
    name: '',
    phone: '',
    website: '',
    countryCodeName: '',
    countryCodeNumber: '',
  });
  const stateRef: any = useRef();
  stateRef.current = contactState;
  useEffect(() => {
    // Makes an api call to retrieve contact data by location id
    if (locationState.id !== '') {
      LocationContactUtility.requestContactByLocationId(locationState.id).then((res) => {
        if (res instanceof HttpErrorResponseModel) {
          // If Contact Data does not exist, it will return a 404 status code
          if (res.statusCode === 404) {
            LocationContactUtility.createContactByLocation(new ContactRequestModel({ locationId: locationState.id })).then(
              (response: ContactResponseModel) => {
                if (response instanceof HttpErrorResponseModel) {
                  console.error(response.errors);
                  setIsError({ isError: true, message: errorMessage });
                }
                if (response.data) {
                  setContactStateResponse(response.data);
                }
              }
            );
          }
        }
        if (res.data) {
          setContactStateResponse(res.data);
        }
      });
    }
  }, [locationState.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const setContactStateResponse = (data: IContact) => {
    setContactState({
      ...contactState,
      id: data.id,
      name: data.name,
      phone: data.phone,
      website: data.website,
      countryCodeName: data.countryCodeName,
      countryCodeNumber: data.countryCodeNumber,
    });
  };

  const handleFieldChange = (input) => (e) => {
    if (contactState && contactState.id) {
      setContactState({ ...contactState, [input]: e.target.value });
      const tempContactState: IContactState = { ...contactState, [input]: e.target.value };
      updateContactLocation(tempContactState);
      setSaved(true);
    }
  };
  const handleCountryCodeUpdates = (selectedCountry: ISelectedCountryCode) => {
    const savedContact = stateRef.current;
    if (savedContact && savedContact.id && selectedCountry) {
      setContactState({ ...savedContact, countryCodeName: selectedCountry.countryCodeName, countryCodeNumber: selectedCountry.countryCodeNumber });
      const tempContactState: IContactState = {
        ...savedContact,
        countryCodeName: selectedCountry.countryCodeName,
        countryCodeNumber: selectedCountry.countryCodeNumber,
      };
      updateContactLocation(tempContactState);
      setSaved(true);
    }
  };

  const updateContactLocation = useCallback(
    debounce((newContactState: IContactState) => updateContactLocationApi(newContactState), 750),
    []
  );
  const updateContactLocationApi = (newContactState: IContactState): void => {
    LocationContactUtility.updateContactById(newContactState.id, new UpdateContactRequestModel(newContactState));
  };
  return (
    <>
      <LocationForm
        fields={FittingLocationContactArr}
        handleFieldChange={handleFieldChange}
        handleCountryCodeUpdates={(val) => handleCountryCodeUpdates(val)}
        formState={contactState}
        formRef={formRef}
        formId={'location-contacts'}
        isError={isError}
      />
    </>
  );
};

export default LocationContact;
