import styles from './InvoiceCard.module.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';
import IInvoices from '../../../../stores/invoices/models/IInvoices';
import RouteEnum from '../../../../constants/RouteEnum';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import moment from 'moment';
import { formatCurrency } from 'utilities/FormatCurrency';

interface IProps {
  invoice: IInvoices;
}

function InvoiceCard(props: IProps): JSX.Element {
  const { invoice } = props;
  const formattedDueDate = moment(invoice.transactionDueDate).format(DateFormatsEnum.WrittenDayMonthYear);
 
  return (
    <Link className='focus-ring' to={`${RouteEnum.Transactions}/${invoice.referenceNumber}`}>
      <div className={styles.wrapper}>
        <div className={styles.statusBar} data-status={invoice.pastDue && 'past due'}></div>
        <div className={styles.content}>
          <p className={styles.status} data-status={invoice.pastDue && 'past due'}>
            {invoice.pastDue === true ? `Past Due -  ${formattedDueDate}` : `Due ${formattedDueDate}`}
          </p>
          <div className='vr' />
          <p className={styles.number}>
            Reference # <span>{invoice.referenceNumber}</span>
          </p>
          <p className={styles.balance}>{formatCurrency(invoice.currency, invoice.amount)}</p>
        </div>
      </div>
    </Link>
  );
}

export default InvoiceCard;
