import React, { useEffect } from 'react';
import { useTable } from 'react-table';
import styles from './SerialLookupTable.module.scss';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import { ReactComponent as ArrowDown } from '../../assets/media/icons/icon-arrow-down.svg';
import { ReactComponent as IconHammer } from '../../assets/media/icons/icon-hammer.svg';
import { ReactComponent as IconPaper } from '../../assets/media/icons/icon-paper.svg';
import { ReactComponent as IconCopy } from '../../assets/media/icons/icon-copy.svg';
import * as jsonexport from 'jsonexport';

const SerialLookupTable = ({ columns, data, selectedRowId, updatedSelectedRow, onSortColumnClick, sortCol, sortDir }) => {
  const dispatch: Dispatch = useDispatch();
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  useEffect(() => {
    if (rows?.length === 1) {
      updatedSelectedRow(rows[0]);
    }
    // eslint-disable-next-line
  }, [rows]);
  const iconDirection = `icon--active ${sortDir === SortDirectionEnum.DESC ? 'icon icon--dir-0' : 'icon icon--dir-180'}`;
  const isRowSelected = (rowId: string) => {
    return rowId === selectedRowId;
  };
  const copyRowOriginalToClipboard = (row) => {
    if (row?.original) {
      var serialItem = {
        serialNumber: row.original.serialNumber,
        serialComment: row.original.serialComment,
      };
      row.original?.attributes.forEach((a) => {
        serialItem[a.name] = a.value;
      });

      jsonexport([serialItem], (err, csv) => {
        if (err) return dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error));
        navigator.clipboard
          .writeText(csv)
          .then((res) => {
            dispatch(ToastsAction.add(`Serial information has been copied to your clipboard.`, ToastStatusEnum.Success));
          })
          .catch((err) => {
            dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error));
          });
      }).catch((e) => dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error)));
    }
  };
  // Render the UI for your table
  return (
    <div>
      <table {...getTableProps()} className={styles['serial-detail-table']}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className={styles['serial-detail-table__row--hd']} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={`${styles['serial-detail-table__row__cell__label']}`} {...column.getHeaderProps()}>
                  {column.sortable ? (
                    <button
                      type='button'
                      className={`acc-table__sort-btn ${styles['sort-btn']}`}
                      onClick={() => column && column.id && onSortColumnClick(column.id.charAt(0).toUpperCase() + column.id.slice(1))}
                      {...column.getHeaderProps()}
                    >
                      <div className={styles['buttonTextPadding']}>{column.render('Header')}</div>
                      <div className={styles['iconPadding']}>
                        {column && column.id && sortCol === column.id.charAt(0).toUpperCase() + column.id.slice(1) ? (
                          <ArrowDown className={iconDirection} aria-hidden='true' focusable='false' role='img' />
                        ) : (
                          <ArrowDown className='icon' aria-hidden='true' focusable='false' role='img' />
                        )}
                      </div>
                    </button>
                  ) : (
                    column.render('Header')
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr
                  className={`${styles['serial-detail-table__row--bd']} ${isRowSelected(row?.id) && styles['selected-serial-table-row']}`}
                  {...row.getRowProps()}
                  onClick={(r) => updatedSelectedRow && updatedSelectedRow(row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`${styles['serial-detail-table__row__cell']} ${isRowSelected(row?.id) && styles['selected-serial-table-row']}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {isRowSelected(row?.id) && (
                  <tr>
                    <td colSpan={4} className={`${styles['accordian-spec-container']} ${styles['accordian-container-side-padding']}`}>
                      <div className={styles['accordian-container-header']}>
                        <div className={`${styles['accordian-item-container']} ${styles['title-bottom-padding']}`}>
                          <div className={`${styles['bold-title']} `}>
                            <IconHammer className={styles['icon-margin']} aria-hidden='true' focusable='false' role='img' />
                            <div>
                              <p>Original Build Specs</p>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles['bold-title']} ${styles['title-bottom-padding']} `}>
                          <IconPaper className={styles['icon-margin']} aria-hidden='true' focusable='false' role='img' />
                          <div>
                            <p>Comments</p>
                          </div>
                        </div>
                      </div>
                      <div className={styles['flex']}>
                        <div className={styles['half-border-left']}>
                          <hr className='hr' />
                        </div>
                        <div className={styles['half-border-right']}>
                          <hr className='hr' />
                        </div>
                      </div>
                      <div className={`${styles['accordian-container']} ${styles['accordian-spec-container']}`}>
                        <div className={styles['accordian-item-container']}>
                          <div>
                            {row?.original?.attributes.map((attribute) => (
                              <div className={`${styles['bold']} ${styles['title-padding']} ${styles['accordian-item-top-padding']}`}>
                                {attribute?.name}
                              </div>
                            ))}
                          </div>
                          <div>
                            {row?.original?.attributes.map((attribute) => (
                              <div className={`${styles['attribute']} ${styles['accordian-item-top-padding']}`}>
                                {attribute?.value?.trim() || <div className={styles['empty-attribute']}></div>}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={styles['comment-div']}>
                          <div className={`${styles['title-padding']} ${styles['accordian-item-top-padding']}`}>
                            {row?.original?.serialComment?.trim() || 'No comments provided from original order.'}
                          </div>
                        </div>
                      </div>
                      <hr className='hr' />
                      <div className={styles['copy-btn-container']}>
                        <button className='btn focus-ring' type='button' onClick={() => copyRowOriginalToClipboard(row)}>
                          <p className={`${styles['copy-btn-padding']} ${styles['flex-center']}`}>
                            <IconCopy className={styles['icon-margin']} aria-hidden='true' focusable='false' />
                            Copy Club Specs
                          </p>
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SerialLookupTable;
