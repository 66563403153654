import styles from './UserCard.module.scss';
import * as React from 'react';
import IUser from 'stores/users/models/IUser';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { oc } from 'ts-optchain.macro';
import IAccount from '../../../../stores/accounts/models/IAccount';
import IStore from 'models/IStore';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import { canUserEditV3 } from '../../helpers/AccountProfileHelpers';
import RouteEnum from 'constants/RouteEnum';
import { KenticoUser } from 'stores/users/models/IUserV3';
import getUserInitials from 'containers/AccountProfilePage/helpers/UserHelper';

interface IProps {
  rowData: KenticoUser;
  currentUser: IUser;
  fromAdmin?: boolean;
}

function UserCard({ rowData, currentUser, fromAdmin }: IProps): JSX.Element | null {
  const { fullName, email, membership, userId } = rowData;
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const currentUserCanEditArray = currentUser.permissions.canEditMembership;

  const initials = getUserInitials(rowData);

  return (
    <tr className={styles['user-card']}>
      <td className={styles['user-card__column']}>
        <div className={styles['user-name']}>
          <div className={styles['user-initials']}>{initials}</div>
          <div>{fullName}</div>
        </div>
      </td>
      <td className={styles['user-card__column']}>
        <span className={styles.break}>{email}</span>
      </td>
      <td
        data-ui-test={`userMembership-${membership ? membership.value.replace('Ping Biz', '').replace(' ', '') : ''}`}
        className={styles['user-card__column']}
      >
        {/* TODO: Not sure if the data will come in this way or if we'll need to map it to UserMembershipsNamesEnum */}
        {membership ? membership.value.replace('Ping Biz', '') : ''}
      </td>
      <td className={styles['user-card__column']}>
        <AccessControl allowedRole={UserRolesEnum.ManageUsers}>
          {canUserEditV3(currentUser, membership, rowData.userId) || currentUserCanEditArray.includes(UserRolesEnum.PingAdmin) ? (
            <Link
              to={{
                pathname: `${RouteEnum.EditUserPageBase}/${activeAccount?.accountNumber}/${userId}${fromAdmin !== undefined ? `/${fromAdmin}` : ''}`,
                state: { userToEdit: rowData },
              }}
            >
              Edit
            </Link>
          ) : (
            <></>
          )}
        </AccessControl>
      </td>
    </tr>
  );
}
export default UserCard;
