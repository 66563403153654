import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import InvoicesResponseModel from './models/InvoicesResponseModel';
import InvoicesRequestModel from './models/InvoicesRequestModel';
import MatchTransactionsResponseModel from './models/MatchTransactionsResponseModel';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import PDFDownloadResponseModel from './models/PDFDownloadResponseModel';
import InvoiceResponseModel from './models/InvoiceResponseModel';

export default class InvoicesEffect {
  public static async requestInvoices(
    requestInvoicesConfig: InvoicesRequestModel,
    accountNumber: number
  ): Promise<InvoicesResponseModel | HttpErrorResponseModel> {
    const today = moment().format(DateFormatsEnum.HyphensForCalls);
    const beginningDate = moment('2000-1-1').format(DateFormatsEnum.HyphensForCalls);

    if (!requestInvoicesConfig.startDate) {
      // @ts-ignore
      requestInvoicesConfig.startDate = beginningDate;
    } else {
      requestInvoicesConfig.startDate = moment(requestInvoicesConfig.startDate).format(DateFormatsEnum.HyphensForCalls);
    }

    if (!requestInvoicesConfig.endDate) {
      // @ts-ignore
      requestInvoicesConfig.endDate = today;
    } else {
      requestInvoicesConfig.endDate = moment(requestInvoicesConfig.endDate).format(DateFormatsEnum.HyphensForCalls);
    }

    const endpoint: string = `${(environment.api as any).transactions}/${accountNumber}`;
    return EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, endpoint, requestInvoicesConfig);
  }

  /**
   * Uneeded once IS finishes new invoice and memo api
   * @param requestInvoicesConfig
   * @param accountNumber
   */
  public static async requestInvoicesForInvoice(
    requestInvoicesConfig: InvoicesRequestModel,
    accountNumber: number
  ): Promise<InvoicesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).transactions}/${accountNumber}`;
    return EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, endpoint, requestInvoicesConfig);
  }

  public static async requestMatchTransactions(
    accountNum: number,
    tranNumber: string,
    tranType: string,
    tranDate: Date
  ): Promise<MatchTransactionsResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${
      (environment.api as any).transactions
    }/${accountNum}/match?transactionNumber=${tranNumber}&transactionDate=${tranDate}&transactionType=${tranType}`;

    return EffectUtility.getToModel<MatchTransactionsResponseModel>(MatchTransactionsResponseModel, endpoint);
  }

  // Remove?
  public static async requestInvoiceTransactionHistory(
    requestInvoicesConfig: InvoicesRequestModel,
    accountNumber: number,
    invoiceNumber: number
  ): Promise<InvoicesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${
      (environment.api as any).transactions
    }/${accountNumber}/match?transactionNumber=${invoiceNumber}&transactionDate=2018-1-13&transactionType=INV`;
    return EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, endpoint);
  }

  public static async requestInvoice(invoiceNumber: number): Promise<InvoiceResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).transactions3}/invoice/${invoiceNumber}`;

    return EffectUtility.getToModel<InvoiceResponseModel>(InvoiceResponseModel, endpoint);
  }

  public static async downloadPDFs(pdfList: string[], accountNumber: number): Promise<PDFDownloadResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).transactions}/${accountNumber}/GetInvoicePdf?invoices=${pdfList.join('&invoices=')}`;
    return EffectUtility.postToModel<PDFDownloadResponseModel>(PDFDownloadResponseModel, endpoint);
  }

  public static async requestInvoiceMemo(
    requestInvoicesConfig: InvoicesRequestModel,
    accountNumber: number,
    memoNumber: number,
    memoType: string
  ): Promise<InvoicesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${
      (environment.api as any).transactions
    }/${accountNumber}/creditdebitmemo?transactionReferenceNumber=${memoNumber}&transactionType=${memoType}`;

    return EffectUtility.getToModel<InvoicesResponseModel>(InvoicesResponseModel, endpoint, requestInvoicesConfig);
  }
}
