import PaginatedRequestModel from 'stores/pagination/IPaginatedRequestModel';

export default class ContactRequestModel extends PaginatedRequestModel {
  name?: number;
  phone?: string;
  website?: string;
  locationId: string;

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data: any): void {
    super.update(data);
  }
}
