import { IUserV3 } from './IUserV3';
import PaginatedResponseModel from 'stores/pagination/IPaginatedResponseModel';

/*
    // Returned Api Data Sample
    {
      data: [],
      message: null,
      status: 'Ok',
      statusCode: 200,
      source: 'UsersController',
      eventCode: 'GetUser',
    }
 */
export default class UsersResponseModel extends PaginatedResponseModel<IUserV3> {}
