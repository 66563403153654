import SortDirectionEnum from 'constants/SortDirectionEnum';
import { BaseModel } from 'sjs-base-model';
import ISortData from 'stores/dataTable/models/ISortData';

/*
    // Returned Api Data Sample
    {
      "data": IInvoice[],
      ...PaginatedResponseModel
    }
 */

/** TODO: once all paginated request are converted extend
 * a new paginated request model that supplies defaults for fields present on all paginated requests
 */
export default class InvoicesRequestModel extends BaseModel {
  startDate: Date | string | undefined = undefined;
  endDate: Date | string | undefined = undefined;
  transactionStatus: string = '';
  pastDue: string = '';
  transactionType: string = '';
  referenceId: string = '';
  purchaseOrderNumber: string = '';
  pageSize: number = 10;
  page: number = 1;
  sortData: ISortData = {
    Column: 'TransactionDate',
    SortDirection: SortDirectionEnum.DESC,
  };
  filter: string = '';

  constructor(data: {}) {
    super();

    this.update(data);
  }

  public update(data): void {
    super.update(data);
  }
}
