import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import InvoicesResponseModel from '../../../../stores/invoices/models/InvoicesResponseModel';
import TransactionHistoryItem from '../TransactionHistoryItem/TransactionHistoryItem';
import Pagination from '../../../../components/Pagination/Pagination';
import { ReactComponent as ArrowDown } from '../../../../assets/media/icons/icon-arrow-down.svg';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import styles from './TransactionHistoryTable.module.scss';
import AcceptedCheckboxTransactionTypes from 'constants/AcceptedCheckboxTransactionTypesArr';
// import ITransactionReferenceStructured from 'stores/invoices/models/ITransactionReferenceStructured';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadArrow } from 'assets/media/icons/icon-download.svg';
import IStore from 'models/IStore';
import { downloadPDFs } from 'containers/InvoicePage/helpers/pdfDownloadHelperFunctions';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension = 'pdf') {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

interface ITableProps {
  columns: any;
  transactionHistoryResponse: InvoicesResponseModel;
  sortDir: string;
  sortCol: string;
  accountNumber: number;
  onSortColumnClick: (column: string) => void;
  onNewPageClick: (page: number) => void;
}

function TransactionHistoryTable({
  columns,
  transactionHistoryResponse,
  sortDir,
  sortCol,
  accountNumber,
  onSortColumnClick,
  onNewPageClick,
}: ITableProps) {
  // const dispatch: Dispatch = useDispatch();
  const pdfChecklist = useSelector((state: IStore) => state.invoices.pdfChecklistResponse);
  const controlledPageCount = transactionHistoryResponse.totalPages;
  const [pdfLoading, setPdfLoading] = useState(false);
  const tableContent = transactionHistoryResponse.data;
  const tableContentRestructured = tableContent.map((item) => {
    return {
      ...item,
      transactionDueDate: {
        pastDue: item.pastDue,
        transactionDueDate: item.transactionDueDate,
        pastDueCategory: item.pastDueCategory,
      },
      referenceNumber: {
        transactionReferenceNumber: item.referenceNumber,
        transNo: item.transactionNumber,
        accountNumber: item.accountNumber,
        transType: item.transactionType,
      },
    };
  });

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableContentRestructured,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const iconDirection = sortDir === SortDirectionEnum.DESC ? 'icon icon--dir-0 icon--active' : 'icon icon--dir-180 icon--active';

  const allTransactionsRefStructured = page.filter((item) => {
    const transactionObject = item.values.referenceNumber;
    if (AcceptedCheckboxTransactionTypes.includes(transactionObject.transType)) {
      return true;
    }

    return false;
  });

  const allReferenceNumbersCurrentPage = allTransactionsRefStructured.map((item) => item.values.referenceNumber.transactionReferenceNumber);

  const areAllTransactionCurrentPageChecked = allReferenceNumbersCurrentPage.every((refNumber) => pdfChecklist.includes(refNumber));
  // Check if all check boxes are checked otherwise change all checkbox state
  if (page) {
    if (!areAllTransactionCurrentPageChecked) {
      // TODO: what is happening infinite loop if we access the root store from the top level componet.
      // dispatch(InvoiceAction.invoiceAllCheckbox(false));
    }
  }

  // TODO: enable during phase2 when pdf list is zipped
  // function handleCheckboxClick(page) {
  //   dispatch(InvoiceAction.invoiceAllCheckbox(null));
  //   page.forEach((item) => {
  //     const transactionObject: ITransactionReferenceStructured = item.values.transactionReferenceStructured;
  //     if (AcceptedCheckboxTransactionTypes.includes(transactionObject.transType)) {
  //       dispatch(InvoiceAction.invoiceCheckbox(transactionObject.transactionReferenceNumber));
  //     }
  //   });
  // }
  return (
    <>
      <table className={styles['transaction-history-table']} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={`headerGroup-${i}`}
              className={`${styles['transaction-history-table__row']} ${styles['transaction-history-table__row--hd']}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th key={column.id} scope='col' className={styles['transaction-history-table__row__cell']}>
                  {/* {...column.getHeaderProps()} */}
                  <div className={styles['transaction-history-table__cell-container']}>
                    {/*  TODO: finish pdf zip to downloads and add back in all checkboxes Phase2

                    {column.id === 'transactionReferenceStructured' && (
                      <input
                        className={styles['transaction-history-table__checkbox']}
                        onChange={() => handleCheckboxClick(page)}
                        type='checkbox'
                        checked={areAllTransactionCurrentPageChecked}
                        title='Check this checkbox to add this transaction to the download list.'
                        id='pdf-check-select-all'
                        name='pdf-check-select-all'
                        value='pdf-check-select-all'
                      />
                    )} */}
                    <button
                      type='button'
                      className='acc-table__sort-btn focus-ring'
                      onClick={() => onSortColumnClick(column.id.charAt(0).toUpperCase() + column.id.slice(1))}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      {sortCol === column.id.charAt(0).toUpperCase() + column.id.slice(1) ? (
                        <ArrowDown className={iconDirection} aria-hidden='true' focusable='false' role='img' />
                      ) : (
                        <ArrowDown className='icon' aria-hidden='true' focusable='false' role='img' />
                      )}
                    </button>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                {...row.getRowProps()}
                className={`${styles['transaction-history-table__row']} ${styles['transaction-history-table__row--bd']}`}
              >
                <TransactionHistoryItem rowData={row.values} columns={columns} currency={row.original.currency} />
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr className={`${styles['transaction-history-table__row']} ${styles['transaction-history-table__row--ft']}`}>
            <th scope='row' colSpan={7}>
              <div className='split'>
                <div className={styles['transaction-history-table__ft-download-container']}>
                  {/*  TODO: finish pdf zip to downloads and add back in all checkboxes Phase2

                  <input
                    className={styles['transaction-history-table__checkbox']}
                    onChange={() => handleCheckboxClick(page)}
                    type='checkbox'
                    checked={areAllTransactionCurrentPageChecked}
                    title='Check this checkbox to add this transaction to the download list.'
                    id='pdf-check-select-all'
                    name='pdf-check-select-all'
                    value='pdf-check-select-all'
                  />

                  <span>Select / Unselect All</span> */}

                  {pdfLoading ? (
                    <div className='btn--loading'>
                      <LoadingIndicator isActive={true} inverted={true} noLoadingText={true} />
                      <span className='btn--loading__text'>Preparing zip file. This may take some time</span>
                    </div>
                  ) : (
                    <button className='btn btn__text-only' onClick={() => downloadPDFs(pdfChecklist, accountNumber, setPdfLoading)}>
                      Download PDF List &nbsp; <DownloadArrow width='20' height='20' aria-hidden='true' focusable='false' role='img' />
                    </button>
                  )}
                </div>
                {page.length > 0 && <Pagination data={transactionHistoryResponse} onNewPageClick={onNewPageClick} />}
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default TransactionHistoryTable;
