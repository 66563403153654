enum UserMembershipsEnum {
  Sales = 'PingBizSales',
  CorporateUser = 'PingBizCorporateUser',
  Distributor = 'PingBizDistributor',
  Account = 'PingBizAccount',
  IntlProAccount = 'PingBizInternationalProAccount',
  EmployeeBilling = 'PingBizEmployeeBilling',
  Employee = 'PingBizEmployee',
  Fitter = 'PingBizFitter',
  PingAdmin = 'PingBizPingAdmin',
}
export default UserMembershipsEnum;
