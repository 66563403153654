import IFields from '../components/Form/IFields';

const UserFieldsArr: IFields = [
  {
    name: 'first_name',
    type: 'input',
    inputType: 'text',
    label: 'First Name',
  },
  {
    name: 'last_name',
    type: 'input',
    inputType: 'text',
    label: 'Last Name',
  },
  {
    name: 'email',
    type: 'input',
    inputType: 'email',
    label: 'Email',
  },
];

export default UserFieldsArr;
