import React from 'react';
import LocationForm from 'components/Form/LocationForm';
import FittingLocationHourArr from 'constants/FittingLocationHourArr';
import { initialError } from 'containers/LocationInfoPage/models/ILocationError';

interface IProps {
  formState: any;
  formRef: any;
  handleFieldChange: Function;
}
const LocationDisplayHour = ({ formState, formRef, handleFieldChange }: IProps) => {
  return (
    <>
      <LocationForm
        fields={FittingLocationHourArr}
        handleFieldChange={handleFieldChange}
        formState={formState}
        formRef={formRef}
        formId={'display-hours'}
        isError={initialError}
      />
    </>
  );
};
export default LocationDisplayHour;
