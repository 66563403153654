import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { oc } from 'ts-optchain.macro';
import IStore from 'models/IStore';
import AccountDetails from './components/AccountDetails/AccountDetails';
import BillingAndShipping from './components/BillingAndShipping/BillingAndShipping';
import NotificationPreferences from './components/NotificationPreferences/NotificationPreferences';
import IAccount from '../../stores/accounts/models/IAccount';
import IUser from 'stores/users/models/IUser';
import AccountsAction from 'stores/accounts/AccountsAction';
import IAccountAddresses from 'stores/accounts/models/IAccountAddresses';
import INotificationPreference from 'stores/accounts/models/INotificationPreference';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';

const AccountProfilePage = () => {
  let isLoading: boolean = false;
  const dispatch: Dispatch = useDispatch();
  const currentUser: IUser | undefined = useSelector((state: IStore) => oc(state).users.currentUser.data(undefined));
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const addresses: IAccountAddresses | null = useSelector((state: IStore) => oc(state).accounts.addresses(null));
  const notifications: INotificationPreference[] | null = useSelector((state: IStore) => oc(state).accounts.notificationPreferences(null));

  const accountProfile4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED, AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED]
  );

  // Wait for all the API calls to finish gathering data before rendering the page
  // No need to show the loader unless the user has selected an account

  useEffect(() => {
    if (accountNumber) {
      // TODO: need to change the way redux structures these
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isLoading = true;
      Promise.all([
        dispatch(AccountsAction.requestAccountAddresses(accountNumber)),
        dispatch(AccountsAction.requestAccountNotifications(accountNumber)),
      ]).then(() => (isLoading = false));
    }
  }, [accountNumber, dispatch]);

  if (!currentUser) {
    return (
      <div className='wrapper'>
        <h1 className='hdg hdg--1'>Account Profile</h1>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  return (
    <div className='wrapper'>
      <h1 className='hdg hdg--1'>Account Profile</h1>
      <Error4xx response={accountProfile4xxResponses['AccountsAction.REQUEST_ACCOUNT_ADDRESSES_FINISHED']}>
        <div className='tiles tiles--split tiles--vr5'>
          <AccountDetails currentUser={currentUser} activeAccount={activeAccount}></AccountDetails>
          {addresses && <BillingAndShipping addresses={addresses} />}
        </div>
      </Error4xx>
      {activeAccount && (
        <>
          <div className='vr vr5' />
          <Error4xx response={accountProfile4xxResponses['AccountsAction.REQUEST_ACCOUNT_NOTIFICATIONS_FINISHED']} textOnly>
            {notifications && <NotificationPreferences notifications={notifications} currentUser={currentUser} />}
          </Error4xx>
        </>
      )}
    </div>
  );
};
export default AccountProfilePage;
