// http://api.pinggolf.local/api/pages/v1/resources
import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import PagesResponseModel from './models/PagesResponseModel';

export default class PagesEffect {
  public static async requestPage(pageName: string): Promise<PagesResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).pages}/${pageName}`;

    return EffectUtility.getToModel<PagesResponseModel>(PagesResponseModel, endpoint);
  }
}
