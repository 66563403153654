import environment from 'environment';
import HttpUtility from './HttpUtility';

export default class PingAddressUtitlity {
  /**
   * Function makes an api call to kentico setting for Ping address
   * @returns all feature flag status that needs to be displayed or hidden
   */
  public static async getPingAddress() {
    const endpoint = `${(environment.api as any).system3}/PingAddress`;
    const responseStatus = await HttpUtility.get(endpoint);
    return responseStatus?.data?.data ?? false;
  }
  public static async getAddressType() {
    const endpoint = `${(environment.api as any).system1}/AddressType`;
    const responseStatus = await HttpUtility.get(endpoint);
    return responseStatus?.data?.data ?? false;
  }
}
