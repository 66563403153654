import BaseReducer from '../../utilities/BaseReducer';
import PaymentsAction from './PaymentsAction';
import IPaymentsState from './models/IPaymentsState';

export default class PaymentsReducer extends BaseReducer<IPaymentsState> {
  public readonly initialState: IPaymentsState = {
    paymentsResponse: null,
  };

  public [PaymentsAction.GET_PAYMENT_FINISHED](state: any, action: any): any {
    return {
      ...state,
      paymentsResponse: action.payload!,
    };
  }

  public [PaymentsAction.RESET_PAYMENT](state: any, action: any): any {
    return {
      ...state,
      paymentsResponse: action.payload!,
    };
  }
}
