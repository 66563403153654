import React from 'react';
import styles from '../../../InvoicePage/InvoicePage.module.scss';
import paymentsStyles from './PaymentDetailsHeader.module.scss';
import IPayment from 'stores/payments/models/IPayment';
import DateFormatsEnum from 'constants/DateFormatsEnum';
import moment from 'moment';

interface IProps {
  payments: IPayment | undefined;
}

function PaymentDetailsHeader({ payments }: IProps): JSX.Element | null {
  const paymentHeader = payments?.payments[0];

  return (
    <>
      <dl className={paymentsStyles['payment-grid']}>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles['payment-grid--grow-full']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Paid By</dt>
          <dd className={paymentsStyles['payment-grid__value']}>{paymentHeader?.paidBy.toLocaleLowerCase()}</dd>
        </div>
      </dl>

      <dl className={`${paymentsStyles['payment-grid']} ${paymentsStyles['payment-grid--3']}`}>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Reference Number</dt>
          <dd className={paymentsStyles['payment-grid__value']}>{paymentHeader?.transactionReferenceNumber}</dd>
        </div>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Payment Type</dt>
          <dd className={paymentsStyles['payment-grid__value']}>{paymentHeader?.paymentType}</dd>
        </div>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Recieve Date</dt>
          <dd className={paymentsStyles['payment-grid__value']}>{moment(paymentHeader?.date).format(DateFormatsEnum.WrittenDayMonthYear)}</dd>
        </div>
      </dl>
      <dl className={`${paymentsStyles['payment-grid']} ${paymentsStyles['payment-grid--2']}`}>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Amount</dt>
          <dd className={paymentsStyles['payment-grid__value']}>-{paymentHeader?.paymentAmount}</dd>
        </div>
        <div className={`${paymentsStyles['payment-grid__list-item']} ${paymentsStyles.card}`}>
          <dt className={styles['invoice-grid__list__item__label']}>Total Discount</dt>
          <dd className={paymentsStyles['payment-grid__value']}>{paymentHeader?.totalDiscount}</dd>
        </div>
      </dl>
    </>
  );
}

export default PaymentDetailsHeader;
