import environment from 'environment';
import EffectUtility from '../EffectUtility';
import EnvironmentCategoriesResponseModel from './models/EnvironmentCategoriesResonseModel';
import LocationResponseModel from './models/LocationResponseModel';

export default class LocationEnvironmentUtility {
  public static baseEnvironmentCatUri: string = `${(environment.api as any).environmentCategories}`;
  public static baseLocationsUri: string = `${(environment.api as any).location}`;

  /**
   * This method retrieves a list of EnvironmentCategories in the form of a EnvironmentCategoriesResponse object
   * @param dispatch callback to dispatch error action
   */
  // TODO Update this to matched entity
  public static async getAllCertificationCategories() {
    const endpoint: string = `${LocationEnvironmentUtility.baseEnvironmentCatUri}`;
    const response = await EffectUtility.getToModel<EnvironmentCategoriesResponseModel>(EnvironmentCategoriesResponseModel, endpoint);
    // TODO: Handle Error
    return response.data;
  }

  public static async updateLocationEnvironmentVerfication(locationId: string, propertyToUpdate: object) {
    const endpoint: string = `${LocationEnvironmentUtility.baseLocationsUri}/${locationId}`;

    const response = await EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate).catch(() => {
      EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate);
    });
    return response;
  }

  public static async getLocationEnvironments(locationId: string) {
    const endpoint: string = `${LocationEnvironmentUtility.baseLocationsUri}/${locationId}/environments`;
    const response = await EffectUtility.getToModel<EnvironmentCategoriesResponseModel>(EnvironmentCategoriesResponseModel, endpoint);
    return response;
  }

  public static async createLocationEnvironment(locationId: string, environmentId: string) {
    const endpoint: string = `${LocationEnvironmentUtility.baseLocationsUri}/${locationId}/environment/${environmentId}`;
    const response = await EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint).catch(() => {
      EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint);
    });
    return response;
  }

  /**
   * This method deletes the location environment
   * @param locationId Requires a location ID so that we know which location we are changing in the database
   * @param environmentId Requires a environmentId to know which specific certification is being changed
   * @returns
   */
  public static async deleteLocationEnvironment(locationId: string, environmentId: string) {
    const endpoint: string = `${LocationEnvironmentUtility.baseLocationsUri}/${locationId}/environment/${environmentId}`;

    const response = await EffectUtility.deleteToModel<LocationResponseModel>(LocationResponseModel, endpoint);
    return response;
  }
}
