import { BaseModel } from 'sjs-base-model';
import IPDFDownload from './IPDFDownload';

export default class PDFDownloadResponseModel extends BaseModel {
  public readonly data: IPDFDownload;
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data) {
    super();

    // Calls the update method on BaseModel
    this.update(data);
  }
}
