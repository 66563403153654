/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import UserRolesEnum from '../../constants/UserRolesEnum';
import AccessControl from '../../components/AccessControl/AccessControl';
import Title from 'components/Title/Title';
import IStore from '../../models/IStore';
import { oc } from 'ts-optchain.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import UserFieldsArr from '../../constants/UserFieldsArr';
import Form from '../../components/Form/Form';
import IFields from '../../components/Form/IFields';
import styles from './CreateUsersPage.module.scss';
import IAccount from '../../stores/accounts/models/IAccount';
import { useHistory, useLocation } from 'react-router-dom';
import UserRoleDescEditPages from '../EditUsersPage/components/UserRoleDescEditPages';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import { checkAssignableRoles } from 'containers/EditUsersPage/EditUsersPage';
import Error4xx from 'components/Error4xx/Error4xx';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { handleSubmit } from './CreateUsersHelpers';
import { IRolesFilterState } from 'stores/users/UsersAction';
import { IFormErrors } from 'components/Form/IFormErrors';
import highlightFields from 'components/Form/FormHelpers/highlightFields';
import { handleValidation } from 'containers/EditUsersPage/EditUsersHelpers';
import AdminUserConfirmModal from './AdminUserConfirmModal';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';

interface IProps {
  fromAdmin?: boolean;
}

const CreateUsersPage = () => {
  const userRoleFieldName = 'user_membership';
  const history = useHistory();
  const location = useLocation();
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const currentAndPreviousPaths = useSelector((state: IStore) => oc(state).navigation.currentAndPreviousPaths({ current: '', previous: '' }));
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => oc(state).users.rolesFilters(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const editRef = useRef<any>(null);
  const [formState, setFormState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    user_membership: '',
  });
  const [createLoading, setCreateLoading] = useState(false);
  const [error, setError] = useState<HttpErrorResponseModel | null>(null);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    hasError: false,
    errors: [],
  });
  const [openAdminModal, setOpenAdminModal] = useState<boolean>(false);
  const [adminRoleValue, setAdminRoleValue] = useState<any>();
  let fromAdmin: boolean = false;

  // Check if admin
  const isAdmin = () => {
    if (location.pathname.includes('create-user-sa')) {
      fromAdmin = true;
    }

    if (fromAdmin && !currentUser?.data?.memberships?.find((dm) => dm.key === UserRolesEnum.PingAdmin)) {
      history.push('/account-profile');
    }
  };
  isAdmin();

  const fields: IFields = [
    ...UserFieldsArr,
    {
      name: userRoleFieldName,
      type: 'select',
      inputType: 'select',
      label: 'User Role',
      options: currentUser ? checkAssignableRoles(currentUser, false) : [],
    },
  ];
  // console.log(fields,currentUser)

  const handleFieldChange = (input) => (e) => {
    setFormState({ ...formState, [input]: e.target.value });
    if (formErrors?.errors?.length > 0) {
      const newFormState = { ...formState, [input]: e.target.value };
      const errors = handleValidation(newFormState, setFormErrors);
      setFormErrors(errors);
    }
  };
  const updateAdminRole = () => {
    if (adminRoleValue) {
      setFormState({ ...formState, [adminRoleValue?.name]: adminRoleValue?.value });
      if (formErrors?.errors?.length > 0) {
        const newFormState = { ...formState, [adminRoleValue.name]: adminRoleValue.value };
        const errors = handleValidation(newFormState, setFormErrors);
        setFormErrors(errors);
      }
    }
  };

  const handleSelectFieldChange = (newValue: any) => {
    if (newValue.name === userRoleFieldName && newValue.value === UserRolesEnum.PingAdmin) {
      setAdminRoleValue(newValue);
      setOpenAdminModal(true);
      return;
    }
    setFormState({ ...formState, [newValue.name]: newValue.value });
    if (formErrors?.errors?.length > 0) {
      const newFormState = { ...formState, [newValue.name]: newValue.value };
      const errors = handleValidation(newFormState, setFormErrors);
      setFormErrors(errors);
    }
  };

  useEffect(() => {
    const fieldNames = ['first_name', 'last_name', 'email', 'user_membership'];
    highlightFields(fieldNames, formErrors);
  }, [formErrors]);

  return (
    <AccessControl allowedRole={UserRolesEnum.ManageUsers} noAccessRedirect={true}>
      <div className={`wrapper ${styles['edit-user']}`}>
        <UserRoleDescEditPages />
        <Title title='Create New User' />
        {accountNumber ? (
          currentUser && (
            <>
              <Form
                fields={fields}
                handleFieldChange={handleFieldChange}
                handleSelectFieldChange={handleSelectFieldChange}
                formState={formState}
                thankYouMessage='thank you'
                formRef={editRef}
                formId={'create-user-form'}
              />
              <div className={styles['create-user__btns']}>
                <button
                  type='submit'
                  form='create-user-form'
                  className={`btn btn--form-button ${styles.formButton}`}
                  onClick={(e) =>
                    handleSubmit({
                      e,
                      editRef,
                      fromAdmin,
                      setCreateLoading,
                      formState,
                      accountNumber,
                      setError,
                      dispatch,
                      currentAndPreviousPaths,
                      rolesFilter,
                      history,
                      setFormErrors,
                    })
                  }
                >
                  Create User
                </button>
                <div className={styles.loading}>
                  {createLoading && <LoadingIndicator sizeableSpinner={{ height: 50, width: 25, marginLeft: 30 }} />}
                </div>
              </div>
              {formErrors.errors.length > 0 && (
                <div className='form__error'>
                  {formErrors.errors.map((e, i) => (
                    <div className='errors__line' key={i}>
                      {e.errorMessage}
                    </div>
                  ))}
                </div>
              )}
              <Error4xx
                onLight
                // eslint-disable-next-line quotes
                overrideText={{
                  headingText: error?.userMessage || "We're sorry but it looks like an error occurred and we cannot create the user at this time.",
                }}
                response={error}
              />
            </>
          )
        ) : (
          <>{'Please Select an Account'}</>
        )}
        {openAdminModal && (
          <AdminUserConfirmModal
            onConfirm={() => {
              setOpenAdminModal(false);
              updateAdminRole();
            }}
            onCancel={() => setOpenAdminModal(false)}
          />
        )}
      </div>
    </AccessControl>
  );
};

export default CreateUsersPage;
