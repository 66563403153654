import React from 'react';
import styles from '../../OrderItems/OrderItems.module.scss';
import IOrder, { IOrderTracking } from 'stores/orders/models/IOrder';
import { DATE_HELPER } from 'index';

interface IProps {
  order: IOrder | undefined;
  item: IOrderTracking;
}

const TrackingShipTo = ({ order, item }: IProps) => {
  return (
    <div className={styles['trackingColumn']}>
      <h5 className='hdg hdg--5'>PO Number</h5>
      <p className='vr1_5'>{order?.purchaseOrderNumber}</p>
      <h5 className='hdg hdg--5'>Terms</h5>
      <h5 className='hdg hdg--5'>Requested</h5>
      <p className='vr1_5'>{DATE_HELPER.getDisplayDate(order?.wantDate)}</p>
      <h5 className='hdg hdg--5'>Ship To</h5>
      <p className='vr1_5'>
        {item.shipBusiness} <br />
        {item.shipAddress1} <br />
        {item.shipAddress2 !== '' ? (
          <>
            {item.shipAddress2} <br />
          </>
        ) : (
          ''
        )}
        {item.shipZip} <br />
      </p>
    </div>
  );
};

export default TrackingShipTo;
