import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
    }
 */
export default class AccountNotificationsRequestModel extends BaseModel {
  public accountNumber: number = 0;

  /*
   * Client-Side properties (Not from API)
   */
  public noneApiProperties: unknown = null;

  constructor(data: Partial<AccountNotificationsRequestModel>) {
    super();

    this.update(data);
  }

  public update(data: Partial<AccountNotificationsRequestModel>): void {
    super.update(data);
  }
}
