import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import ActionUtility from '../../utilities/ActionUtility';
import CreateOrderEffect from './CreateOrderEffect';
import AddToCartResponseModel from './models/AddToCartResponseModel';
import GetCartResponseModel from './models/GetCartResponseModel';
import GetCartRequestModel from './models/GetCartRequestModel';
import { IDynamicFormAttribute } from './models/IDynamicFormModels';
import ITabState from './models/ITabState';
import IGetEstimatedDateByItemIdWithId from './models/IGetEstimatedDateByIdWithId';

type ActionUnion = void | HttpErrorResponseModel | AddToCartResponseModel | GetCartResponseModel | ITabState | IGetEstimatedDateByItemIdWithId[];

export default class CreateOrderAction {
  public static readonly REQUEST_ADD_TO_CART: string = 'CreateOrderAction.REQUEST_ADD_TO_CART';
  public static readonly REQUEST_ADD_TO_CART_FINISHED: string = 'CreateOrderAction.REQUEST_ADD_TO_CART_FINISHED';
  public static readonly REQUEST_GET_CART: string = 'CreateOrderAction.REQUEST_GET_CART';
  public static readonly REQUEST_GET_CART_FINISHED: string = 'CreateOrderAction.REQUEST_GET_CART_FINISHED';
  public static readonly REQUEST_GET_ESTIMATED_DATE_FINISHED: string = 'CreateOrderAction.REQUEST_GET_ESTIMATED_DATE_FINISHED';
  public static readonly STORE_PRODUCT_CONFIG: string = 'CreateOrderAction.STORE_PRODUCT_CONFIG';
  public static readonly STORE_TAB_STATE: string = 'CreateOrderAction.STORE_TAB_STATE';

  /**
   * Requests the cart from the API and stores it.
   * @param {number} accountNumber - account number
   */
  public static getCart(accountNumber: number | null, disableEstimateCart = false): any {
    return async (dispatch: ReduxDispatch<ActionUnion | null>, getState: () => IStore) => {
      if (accountNumber === null) {
        dispatch({ type: CreateOrderAction.REQUEST_GET_CART_FINISHED, payload: null });
      } else {
        const request = new GetCartRequestModel({ accountNumber });
        console.log('getCart', request);
        const res: GetCartResponseModel | HttpErrorResponseModel = await ActionUtility.createThunkEffect<GetCartResponseModel>(
          dispatch,
          CreateOrderAction.REQUEST_GET_CART,
          CreateOrderEffect.getCart,
          request
        );

        if (res instanceof GetCartResponseModel && !disableEstimateCart) {
          Promise.all(
            res.data.cartItems.map(async (item) => {
              const dateRes = await CreateOrderEffect.getEstimatedDateByItemId(item.cartItemID);

              return { cartItemId: item.cartItemID, date: dateRes?.data?.data };
            })
          ).then((res) => {
            dispatch({ type: CreateOrderAction.REQUEST_GET_ESTIMATED_DATE_FINISHED, payload: res });
          });
        }
      }
    };
  }

  /**
   * Stores the productConfig or submittedList to a specific product
   * @param productConfig the config or submitted list
   * @param {string} product  the product you would like to store to
   * @param {boolean} storingOriginal  are we storing the original config before any manipulation
   * @param {boolean} storingSubmitted  are we storing a submitted list not a config
   */
  public static storeProductConfig(
    productConfig: IDynamicFormAttribute[] | string[],
    product: string,
    storingOriginal?: boolean,
    storingSubmitted?: boolean
  ): any {
    return async (dispatch: any, getState: () => IStore) => {
      dispatch({
        type: CreateOrderAction.STORE_PRODUCT_CONFIG,
        productConfig,
        product,
        storingOriginal: storingOriginal ? storingOriginal : false,
        storingSubmitted: storingSubmitted ? storingSubmitted : false,
      });
    };
  }

  /**
   * Stores the tab state.
   * @param {ITabState} payload tabState being saved.
   */
  public static storeTabState(payload: ITabState): any {
    return async (dispatch: any, getState: () => IStore) => {
      dispatch({ type: CreateOrderAction.STORE_TAB_STATE, payload });
    };
  }
}
