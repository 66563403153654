enum TransactionTypeEnum {
  ALL = '',
  CREDIT_MEMO = 'CM',
  DEDIT_MEMO = 'DM',
  INVOICE = 'INV',
  ADJUSTMENT = 'ADJ',
  PAYMENT = 'PAY',
  INTEREST = 'INT',
}

export default TransactionTypeEnum;

export enum TransactionTypeDescriptionEnum {
  CREDIT_MEMO = 'Credit Memo',
  DEDIT_MEMO = 'Debit Memo',
  INVOICE = 'Invoice',
  ADJUSTMENT = 'Adjustment',
  PAYMENT = 'Payment',
  INTEREST = 'Interest',
  DISCOUNT = 'Discount',
}
