import DatePicker from '../DatePicker/DatePicker';
import { ReactComponent as CaretDown } from '../../assets/media/icons/icon-caret-down.svg';
import React, { useState, useRef } from 'react';
import styles from './DateFilter.module.scss';
import { useCloseOnEscape } from '../../utilities/useCloseOnEscape';
import { useCloseOnClickOutside } from '../../utilities/useCloseOnClickOutside';
import classNames from 'classnames';
import OrdersRequestModel from '../../stores/orders/models/OrdersRequestModel';
import moment from 'moment';
import DateFormatsEnum from 'constants/DateFormatsEnum';

interface IProps {
  className?: string;
  endDate: OrdersRequestModel['endDate'];
  startDate: OrdersRequestModel['startDate'];
  updateDates: Function;
  noDateSelectedText?: string;
}

function DateFilter({ className, endDate, startDate, updateDates, noDateSelectedText }: IProps): JSX.Element {
  const [shouldShowDatePicker, setShouldShowDatePicker] = useState(false);
  const datePickerNode = useRef(null);
  const triggerButtonNode = useRef(null);
  useCloseOnEscape(shouldShowDatePicker, setShouldShowDatePicker);
  useCloseOnClickOutside(shouldShowDatePicker, datePickerNode, setShouldShowDatePicker, triggerButtonNode);

  const getDateToggleText = (startDate, endDate) => {
    if (!startDate && !endDate) {
      return noDateSelectedText ? noDateSelectedText : `All`;
    }
    const today = moment().format(DateFormatsEnum.MonthDayYear);
    const endCompare = moment(endDate).format(DateFormatsEnum.MonthDayYear);
    const start = moment(startDate);
    const end = moment(endDate);

    const daysBetween = start.diff(end, 'day');

    if (endCompare === today) {
      return `Last ${daysBetween} days`.replace('-', '');
    }

    return 'Custom';
  };

  const isFilteredByDate = startDate || endDate;
  const iconClassName = shouldShowDatePicker ? 'icon--dir-180' : 'icon--dir-0';
  const buttonClassNames = classNames(className, {
    btn: true,
    'focus-ring': true,
    [styles['date-filter__btn']]: true,
    [styles['date-filter__btn--is-picker-open']]: shouldShowDatePicker,
    [styles['date-filter__btn--is-selected']]: isFilteredByDate,
  });

  return (
    <div className={styles['date-filter']}>
      <button ref={triggerButtonNode} className={buttonClassNames} onClick={(): void => setShouldShowDatePicker(!shouldShowDatePicker)}>
        <span className='btn__text'>{getDateToggleText(startDate, endDate)}</span>
        <span className='btn__icon'>
          <CaretDown className={iconClassName} aria-hidden='true' focusable='false' role='img' />
        </span>
      </button>
      <div ref={datePickerNode}>
        {shouldShowDatePicker && (
          <DatePicker
            setShouldShowDatePicker={setShouldShowDatePicker}
            classNames={styles['date-filter__date-picker']}
            endDate={endDate}
            startDate={startDate}
            updateDates={updateDates}
          />
        )}
      </div>
    </div>
  );
}

export default DateFilter;
