import environment, { Environment } from './base';

const baseUri = `${process.env.REACT_APP_BASE_URI ?? 'https://test.na.pinggolf.biz'}${process.env.REACT_APP_SUB_FOLDER ?? ''}`;
const baseApi = process.env.REACT_APP_BASE_API ?? 'https://test.api.na.pinggolf.biz';
// TODO UPDATE THIS WHEN ENVIRONMENT ARE SETUP
const baseFittingApi = 'http://13.64.131.124';

const env = environment(baseUri, baseApi, baseFittingApi);
const tenantName = 'pinggolfusb2ctest';
const signInUserFlow = 'b2c_1_signin_v2';

const testEnv: Environment = {
  ...env,
  // override anything that gets added from base.
  route: {
    baseRoute: process.env.REACT_APP_SUB_FOLDER ?? '',
  },
  msalConfig: {
    auth: {
      clientId: '5f62e013-c549-47fa-b0d1-89209c214db1',
      redirectUri: baseUri,
      postLogoutRedirectUri: baseUri,
      authority: `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInUserFlow}`,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
    },
    cacheOptions: {
      cacheLocation: 'sessionStorage',
    },
  },
  isKentico: false,
  isDevelopment: false,
  isProduction: false,
  isTesting: true,
  isStage: false,
  name: 'test',
};

export default testEnv;
