import React, { useContext, useEffect, useState } from 'react';
import LocationTabTemplate from '../LocationTabTemplate/LocationTabTemplate';
import LocationTechnologyUtility from '../../../../utilities/Location/LocationTechnologyUtility';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationInfoFooter from '../LocationInfoFooter/LocationInfoFooter';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationUtility from 'utilities/Location/LocationUtility';
import UpdateLocationRequestModel from 'utilities/Location/models/Address/UpdateLocationRequestModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';
interface IProps {
  type: string;
  statePropertyName: string;
}

const TechnologyTab = ({ type, statePropertyName }: IProps): JSX.Element => {
  const [technologyCategories, setTechnologyCategories] = useState([]);
  const [locationTechnologies, setLocationTechnologies] = useState([]);
  const { locationState, locationTabState, updateAllLocationState } = useContext(LocationContext);
  const [isError, setIsError] = useState<ILocationInfoError>(initialError);
  const errorMessage: string = 'Error loading location technology categories.';

  const updateTechnologyVerfication = (isVerified: boolean) => {
    const updateRequest = new UpdateLocationRequestModel({ technologiesVerified: isVerified });
    LocationUtility.updateLocation(locationState.id, updateRequest).then((response) => {
      if (response && response.data) {
        const updatedLocation = response.data;
        updateAllLocationState &&
          updateAllLocationState({
            id: updatedLocation.id,
            name: updatedLocation.name,
            displayHours: updatedLocation.displayHours,
            regionId: updatedLocation.regionId,
            statusId: updatedLocation.statusId,
            hasAddress: Boolean(updatedLocation.address.latitude !== 0 && updatedLocation.address.longitude !== 0),
            certificationsVerified: updatedLocation.certificationsVerified,
            environmentsVerified: updatedLocation.environmentsVerified,
            technologiesVerified: updatedLocation.technologiesVerified,
            displayOnMap: updatedLocation.displayOnMap,
          });
      }
      if (response instanceof HttpErrorResponseModel) {
        setIsError({ isError: true, message: LocationErrorMessages.UPDATE_LOCATION });
      }
    });
  };

  const addTechnologyToLocation = (technologyId: string) => {
    // call the createlocationCertification to create a specific certification on a specific location
    LocationTechnologyUtility.createLocationTechnology(locationState.id, technologyId);
  };

  const deleteTechnologyToLocation = (technologyId: string) => {
    LocationTechnologyUtility.deleteLocationTechnology(locationState.id, technologyId);
  };
  useEffect(() => {
    LocationTechnologyUtility.getLocationTechnologies(locationState.id)
      .then((data) => {
        setLocationTechnologies(data.data);
      })
      .catch((error) => {
        setIsError({ isError: true, message: errorMessage });
      });
  }, [setTechnologyCategories, locationState.id]);

  useEffect(() => {
    if (technologyCategories.length === 0 && !isError.isError) {
      LocationTechnologyUtility.getAllCertificationCategories()
        .then((data) => {
          if (data.length === 0) {
            setIsError({ isError: true, message: errorMessage });
          } else {
            setTechnologyCategories(data);
          }
        })
        .catch((error) => {
          setIsError({ isError: true, message: errorMessage });
        });
    }
  }, [technologyCategories, isError]);

  return (
    <>
      <LocationTabTemplate
        categoryList={technologyCategories}
        tabName={type}
        itemListName={'technologies'}
        statePropertyName={statePropertyName}
        updateVerification={updateTechnologyVerfication}
        locationSpecificItems={locationTechnologies}
        addLocationSpecificItem={addTechnologyToLocation}
        deleteLocationSpecificItem={deleteTechnologyToLocation}
        isError={isError}
      />
      <LocationInfoFooter tabName={type} statePropertyName={statePropertyName} tabIndex={locationTabState.tabIndex} />
    </>
  );
};

export default TechnologyTab;
