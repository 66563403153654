import styles from './AccountDetails.module.scss';

import * as React from 'react';
import IUser from 'stores/users/models/IUser';
import IAccount from 'stores/accounts/models/IAccount';
import UserPreferredCultureEnum from 'constants/UserPreferredCultureEnum';
import UserPreferredLanguageEnum from 'constants/UserPreferredLanguageEnum';
import { Link } from 'react-router-dom';
import getUserInitials from 'containers/AccountProfilePage/helpers/UserHelper';

interface IProps {
  currentUser: IUser;
  activeAccount: IAccount | null;
}
interface IState {
  userLanguage: UserPreferredLanguageEnum;
}

export default class AccountDetails extends React.Component<IProps, IState> {
  // public static defaultProps: Partial<IProps> = {};
  public state: IState = { userLanguage: UserPreferredLanguageEnum.None };

  public setUserLanguage() {
    let userLanguage: UserPreferredLanguageEnum;

    switch (this.props.currentUser.preferredCulture) {
      case UserPreferredCultureEnum.USEnglish:
        userLanguage = UserPreferredLanguageEnum.English;
        break;
      default:
        userLanguage = UserPreferredLanguageEnum.None;
    }
    this.setState({ userLanguage });
  }

  public componentDidMount() {
    this.setUserLanguage();
  }

  public render(): JSX.Element {
    const { currentUser, activeAccount } = this.props;
    const { userLanguage } = this.state;
    const initials = getUserInitials(currentUser);

    if (!currentUser) {
      return (
        <div className='wrapper'>
          <h1 className='hdg hdg--1'>Account Profile</h1>
        </div>
      );
    }

    return (
      <div className='tiles__tile tiles__tile--has-card'>
        <h3 className='hdg hdg--3'>Account Details</h3>
        <div className='tiles__tile__card'>
          <div>
            <h4 className='hdg hdg--4'>{(activeAccount && activeAccount.businessName) || 'No Account Selected'}</h4>
            <p>{(activeAccount && activeAccount.accountName) || ''}</p>
          </div>
          <div className='vr vr3' />
          <hr className='hr' />
          <div className='vr vr3' />
          <div className={styles.tiles}>
            <div>
              <div className={styles.userInitials}>{initials}</div>
            </div>
            <div>
              <h4 className='hdg hdg--4'>{currentUser && currentUser.fullName}</h4>
              <p className={styles.break}>{currentUser && currentUser.email}</p>
              <div className='vr vr1' />
              {/* TODO: Link needs to go to the "Edit Personal Information" page once that task is complete */}
              {currentUser.permissions.canEditSelf && (
                <Link to={{ pathname: `/users/${activeAccount?.accountNumber}/${currentUser.userID}`, state: { userToEdit: currentUser } }}>
                  Edit
                </Link>
              )}
              <div className='vr vr3'></div>

              <h5 className='hdg hdg--5'>Language</h5>
              <p>{currentUser && userLanguage}</p>
              <div className='vr vr3'></div>
              {/* <h5 className='hdg hdg--5'>Password</h5>
              <p> */}
              {/* TODO: Link needs to hook up to Azure B2C "Forgot Password" policy */}
              {/* <a href='/'>Change Password</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
