import { BaseModel } from 'sjs-base-model';
import IEnvironmentCategory from './IEnvironmentCategory';

export default class EnvironmentCategoriesResponseModel extends BaseModel {
  public readonly data: IEnvironmentCategory[];
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<EnvironmentCategoriesResponseModel>): void {
    super.update(data);
  }
}
