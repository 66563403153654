/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import BasicTable from 'components/BasicTable/BasicTable';
import MatchedTransactions from 'containers/InvoicePage/components/matchedTransactions';
import environment from 'environment';
import HttpUtility from 'utilities/HttpUtility';
import styles from '../../PaymentDetailsPage.module.scss';
import moment from 'moment';
import ITransactions from 'stores/payments/models/ITransactions';
import DateFormatsEnum from 'constants/DateFormatsEnum';

interface IProps {
  payments: any;
}

function PaymentsDetailsTable({ payments }: IProps): JSX.Element | null {
  const { accountNumber, transactionDate, transactionType, transactionNumber } = payments;
  const [matchState, setMatchState] = useState<any>(null);
  const endpoint: string = `${
    (environment.api as any).transactions
  }/${accountNumber}/match?transactionNumber=${transactionNumber}&transactionDate=${transactionDate}&transactionType=${transactionType}`;

  /**
   * Using http utility since payment can be split and can need 2 seperate match transactions.
   */
  const response = async () => {
    const res = await HttpUtility.get(endpoint);

    setMatchState(res.data);
  };

  useEffect(() => {
    response();

    return () => {
      setMatchState(null);
    };
    // eslint-disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (item: ITransactions) => moment(item.date).format(DateFormatsEnum.WrittenDayMonthYear),
      },
      {
        Header: 'Type',
        accessor: 'transactionType',
      },
      {
        Header: 'Reason',
        accessor: 'transactionReason',
      },
      {
        Header: 'Customer #',
        accessor: 'accountNumber',
      },
      {
        Header: 'Amount',
        accessor: 'transactionCreditAmount',
      },
      {
        Header: 'Balance',
        accessor: 'openBalance',
      },
      {
        Header: 'Status',
        accessor: 'transactionStatusDescription',
      },
      {
        Header: 'Status Date',
        accessor: (item: ITransactions) => moment(item.statusDate).format(DateFormatsEnum.WrittenDayMonthYear),
      },
    ],
    []
  );

  const openBalanceConverted = parseFloat(payments.openBalance.replace('$', ''));

  return (
    <div>
      {payments && (
        <div className='wrapper'>
          <div className={styles['payment-container']}>
            <BasicTable columns={columns} data={[payments]} />
            <div className={styles['payment-container__prepaid-amount']}>
              {openBalanceConverted < 0 ? (
                <h4 className='hdg hdg--4'>Prepaid Amount: ${openBalanceConverted * -1}</h4>
              ) : (
                <h4 className='hdg hdg--4'>Prepaid Amount: $00.00</h4>
              )}
            </div>
            {matchState ? (
              (matchState.data && matchState !== undefined) || (matchState.data && matchState !== null) ? (
                <>
                  <MatchedTransactions isFromPayment transactions={matchState.data} noWrapper accountNumber={accountNumber} />
                </>
              ) : (
                <div>
                  <h2 className='hdg hdg--3'>MatchedTransactions</h2>
                  <p> You do not have access to this account </p>
                </div>
              )
            ) : (
              <div>
                <h2 className='hdg hdg--3'>MatchedTransactions</h2>
                <p> You do not have access to this account </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentsDetailsTable;
