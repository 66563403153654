import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import CreateOrderStatelessCalls, { IGetClubs } from 'stores/create-order/CreateOrderStatelessCalls';

/**
 * Sets the clubs options based on the getClubs api call
 *
 * @param param0 Object
 */
export default async function getClubs({ accountNumber, cfgModel, indVarValue }: IGetClubs) {
  const result = await CreateOrderStatelessCalls.getClubs({ accountNumber, cfgModel, indVarValue });
  if (result instanceof HttpErrorResponseModel) {
    return result;
  }

  const clubOptions = result.data.map((item) => item.club);

  return clubOptions;
}
