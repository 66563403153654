enum UserRolesEnum {
  ViewDashboard = 'PingBiz_ViewDashboard',
  ViewUsers = 'PingBiz_ViewUsers',
  ManageUsers = 'PingBiz_ManageUsers',
  ViewNotifications = 'PingBiz_ViewNotifications',
  ManageSelfNotifications = 'PingBiz_ManageSelfNotifications',
  ManageAllNotifications = 'PingBiz_ManageAllNotifications',
  ViewOrders = 'PingBiz_ViewOrders',
  ViewOrderPricing = 'PingBiz_ViewOrderPricing',
  ManageOrders = 'PingBiz_ManageOrders',
  ViewTransactions = 'PingBiz_ViewTransactions',
  ViewMakePayments = 'PingBiz_ViewMakePayments',
  ViewSerialNumbers = 'PingBiz_ViewSerialNumbers',
  ManageSerialNumbers = 'PingBiz_ManageSerialNumbers',
  ViewDistributorShipment = 'PingBiz_ViewDistributorShipment',
  ViewFittingResources = 'PingBiz_ViewFittingResources',
  ViewPricingResources = 'PingBiz_ViewPricingResources',
  ViewSalesSupportResources = 'PingBiz_ViewSalesSupportResources',
  PingAdmin = 'PingBizPingAdmin',
}
export default UserRolesEnum;
