import SearchResponseModelResponseModel from './models/search-response-model/SearchResponseModelResponseModel';
import SearchEffect from './SearchEffect';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { ReduxDispatch } from 'models/ReduxProps';
import ActionUtility from 'utilities/ActionUtility';
import IStore from 'models/IStore';
import ISearchRequestConfig from './models/ISearchRequestConfig';
import InvoicesResponseModel from 'stores/invoices/models/InvoicesResponseModel';
import OrdersResponseModel from 'stores/orders/models/OrdersResponseModel';
import CombinedResponse from './models/ICombinedResponse';
import TagNameSearchResponseModel from './models/TagNameSearchResponseModel';

type ActionUnion =
  | void
  | HttpErrorResponseModel
  | SearchResponseModelResponseModel
  | OrdersResponseModel
  | InvoicesResponseModel
  | CombinedResponse
  | TagNameSearchResponseModel;

export default class SearchAction {
  public static readonly REQUEST_SEARCH_RESPONSE_MODEL: string = 'SearchAction.REQUEST_SEARCH_RESPONSE_MODEL';
  public static readonly REQUEST_SEARCH_RESPONSE_MODEL_FINISHED: string = 'SearchAction.REQUEST_SEARCH_RESPONSE_MODEL_FINISHED';
  public static readonly REQUEST_SEARCH_RESET: string = 'SearchAction.REQUEST_SEARCH_RESET';
  public static readonly REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL: string = 'SearchAction.REQUEST_SEARCH_TAG_NAME_RESPONSE_MODEL';
  public static readonly REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL_FINISHED: string = 'SearchAction.REQUEST_SEARCH_TAG_NAME_RESPONSE_MODEL_FINISHED';

  public static requestSearchResponseModel(searchRequestConfig: ISearchRequestConfig): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<OrdersResponseModel | InvoicesResponseModel | CombinedResponse>(
        dispatch,
        SearchAction.REQUEST_SEARCH_RESPONSE_MODEL,
        SearchEffect.requestSearchResponseModel,
        searchRequestConfig
      );
    };
  }

  public static requestSearchReset(payload): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      dispatch({
        type: SearchAction.REQUEST_SEARCH_RESET,
        payload,
      });
    };
  }
  public static requestSearchByTagNameResponseModel(
    tagName: string[],
    accountNumber: number,
    pageNumber: number,
    pageSize: number,
    status: string | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined
  ): any {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore) => {
      await ActionUtility.createThunkEffect<TagNameSearchResponseModel>(
        dispatch,
        SearchAction.REQUEST_SEARCH_BY_TAG_NAME_RESPONSE_MODEL,
        SearchEffect.requestSearchByTagName,
        tagName,
        accountNumber,
        pageNumber,
        pageSize,
        status,
        startDate,
        endDate
      );
    };
  }
}
