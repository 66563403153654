/**
 * Utility to close an overlay object when keyboard user tabs outside of it.
 * Requires ref of the overlay container in order to check if new target is outside.
 *
 * @param {React.FocusEvent} event blur event
 * @param {React.RefObject} openNode node that is open - we only close if new keyboard target is outside
 * @param {function(Boolean):void} setIsOpen setter for isOpen state
 */
export function closeOnBlur(event, openNode, setIsOpen): void {
  const isFocusInsideNode = openNode.current.contains(event.relatedTarget);
  if (!isFocusInsideNode) {
    setIsOpen(false);
  }
}

export function closeOnEscape(event, setIsOpen): void {
  if (event.keyCode === 27 || event.key === 'Escape') {
    setIsOpen(false);
  }
}
