import IAccount from '../../accounts/models/IAccount';
import PaginatedResponseModel from '../../pagination/IPaginatedResponseModel';

/*
    // Returned Api Data Sample
    {
      "data": IAccount[],
      ...PaginatedResponseModel
    }
 */
export default class AccountsResponseModel extends PaginatedResponseModel<IAccount> {}
