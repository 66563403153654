import ISerialNumberProduct from './ISerialNumberProduct';
import { BaseModel } from 'sjs-base-model';

export default class SerialNumberProductResponse extends BaseModel {
  pageSize: number = 10;
  currentPage: number = 1;
  pageSkip: number;
  totalCount: number;
  totalPages: number;
  userMessage: string;
  public readonly data: ISerialNumberProduct[];
  public readonly message: any = null;
  public readonly status: string = '';
  public readonly statusCode: number = 0;

  constructor(data) {
    super();
    // Calls the update method on BaseModel
    this.update(data);
  }
}
