const labelFontSize = '20px';

const SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
    width: '100%',
  }),
  MenuList: (provided, state) => ({
    ...provided,
    // maxHeight: '20px'
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    border: '2px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
  }),
  singleValue: (provided) => ({
    ...provided,
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
  }),
  multiValue: (provided) => ({
    ...provided,
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
    minHeight: '23px',
    alignSelf: 'start',
  }),
  input: (provided) => ({
    ...provided,
    poition: 'absolute',
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
    marginTop: '20px',
  }),
  placeholder: (provided) => ({
    ...provided,
    poition: 'absolute',
    top: 'initial',
    bottom: 0,
    marginLeft: 0,
    paddingLeft: '10px',
    paddingBottom: 0,
    fontSize: labelFontSize,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // height: '66px',
    height: 'auto',
    marginTop: '30px',
    // marginBottom: '10px', for clear selection spacing.
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginBottom: '0',
    marginTop: '0',
    // marginRight: '1rem',
    // display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 24px',
    color: '#0082d3',
    // display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    // width: '105px',
    // width: '69px'
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    position: 'absolute',
    left: '0px',
    bottom: '0px',
    display: 'none',
  }),
};

export default SelectStyles;
