import React, { useContext, useState } from 'react';
import RouteEnum from 'constants/RouteEnum';
import { Link, useHistory } from 'react-router-dom';
import styles from './SetupLocation.module.scss';
import { ReactComponent as MagicWand } from '../../../../assets/media/icons/icon-magic-wand--gray-chateau.svg';
import { useSelector } from 'react-redux';
import IStore from 'models/IStore';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import CreateLocationRequestModel from 'utilities/Location/models/CreateLocationRequestModel';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import LocationUtility from 'utilities/Location/LocationUtility';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import LocationSetupUtility from 'utilities/Location/LocationSetupUtility';
import ILocationInfoError, { initialError } from 'containers/LocationInfoPage/models/ILocationError';
import LocationInfoError from 'containers/LocationInfoPage/components/LocationInfoError/LocationInfoError';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';

const SetupLocation = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const { updateAllLocationStateAndTabState } = useContext(LocationContext);
  const [error, setError] = useState<ILocationInfoError>(initialError);

  const handleEnterOwnLocation = () => {
    setIsLoading(true);
    const createRequestBody = new CreateLocationRequestModel({
      accountNumber: activeAccount?.accountNumber,
      name: activeAccount?.businessName ? activeAccount?.businessName : activeAccount?.accountName,
    });

    LocationUtility.createLocation(createRequestBody).then((response) => {
      if (response && response.data) {
        const createdLocation = response.data;
        LocationSetupUtility.setupAccountPrograms(createdLocation.id, createdLocation.accountNumber);
        updateAllLocationStateAndTabState &&
          updateAllLocationStateAndTabState(
            {
              id: createdLocation.id,
              name: createdLocation.name,
              displayHours: createdLocation.displayHours,
              regionId: createdLocation.regionId,
              statusId: createdLocation.statusId,
              hasAddress: false,
              certificationsVerified: createdLocation.certificationsVerified,
              environmentsVerified: createdLocation.environmentsVerified,
              technologiesVerified: createdLocation.technologiesVerified,
              displayOnMap: createdLocation.displayOnMap,
            },
            {
              hasAddress: false,
              hasCertifications: createdLocation.certificationsVerified,
              hasEnvironment: createdLocation.environmentsVerified,
              hasTechnology: createdLocation.technologiesVerified,
              tabIndex: 0,
            }
          );
        history.push(`${RouteEnum.FittingLocation}/${createdLocation.id}`);
      }
      if (response instanceof HttpErrorResponseModel) {
        setError({ isError: true, message: LocationErrorMessages.CREATE_LOCATION });
      }
      setIsLoading(false);
    });
  };

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} customLoadingText={'Loading Fitting Location...'} isActive={true} inverted={true} />;
  }

  return (
    <>
      <div className={styles.setupContainer}>
        {error.isError ? (
          <LocationInfoError message={error.message} />
        ) : (
          <>
            <MagicWand aria-hidden='true' focusable='false' role='img' />
            <h1 className={styles.setupContainer_header}>Set Up Fitting Location Address</h1>
            <p className={styles.setupContainer_text}>
              Would you like to use the shipping address information associated <br /> with your account to set up a Fitting Location?
            </p>
            <div className='split'>
              <Link to={`${RouteEnum.FittingLocationFound}`} className='btn'>
                <span className={styles.setupContainer_btnText}>Yes, I'll use my account's address</span>
              </Link>
              <button onClick={handleEnterOwnLocation} className='btn btn--inverted'>
                <span className={styles.setupContainer_btnText}>No, I'll enter a new address</span>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SetupLocation;
