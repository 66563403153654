enum TransactionStatusEnum {
  ALL = '',
  OPEN = 'open', //
  PREPAID = 'prepaid',
  FROZEN = 'frozen',
  POST_PENDING = 'post pending',
  CLOSED = 'closed', // when openBalance is "$0.00"
}

export default TransactionStatusEnum;
