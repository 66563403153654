export default class EstimatedDateController {
  public submittedList: string[] = [];
  public defaultDate: any = null;
  public product: string = '';
  public classEstimatedDates: any = {};

  public setDefaultDate(date: any) {
    this.defaultDate = date;
  }

  public setSubmittedList(list: string[]) {
    this.submittedList = list;
  }

  public setProduct(product: string) {
    this.product = product;
  }

  public setClassEstimatedDates(estimatedDates: any) {
    this.classEstimatedDates = estimatedDates;
  }

  public resetProperties() {
    this.submittedList = [];
    this.defaultDate = null;
    this.product = '';
    this.classEstimatedDates = {};
  }
}
