import React, { useEffect, useState } from 'react';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import SearchBox from 'components/GlobalSearch/SearchBox';
import searchStyles from 'components/GlobalSearch/GlobalSearch.module.scss';
import { ReactComponent as MagnifyingGlass } from '../../assets/media/icons/icon-magnifying-glass.svg';
import { ReactComponent as IconCopy } from '../../assets/media/icons/icon-copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import SerialNumberAction from 'stores/serial-number/SerialNumberAction';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ISerialNumberProduct from 'stores/serial-number/models/ISerialNumberProduct';
import IStore from 'models/IStore';
import { oc } from 'ts-optchain.macro';
import SerialNumberProductResponse from 'stores/serial-number/models/SerialNumberProductResponseModel';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import Error4xx from 'components/Error4xx/Error4xx';
import SerialLookupItems from './SerialLookupItems';
import styles from './SerialLookupTable.module.scss';
import ErrorAction from 'stores/error/ErrorAction';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import * as jsonexport from 'jsonexport';
const LetterOrNumberRegex = new RegExp(/^[A-Za-z0-9]*$/);
const EntryArray = ['10', '20'];

function SerialLookupPage(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const serialNumberResponse: SerialNumberProductResponse | null = useSelector((state: IStore) => oc(state).serialNumber.serialNumberResponse(null));
  const matchingSerialProducts: ISerialNumberProduct[] | undefined = serialNumberResponse?.data;
  const serialNumberError: HttpErrorResponseModel | undefined = selectRawErrors(
    useSelector((state: IStore) => state),
    [SerialNumberAction.REQUEST_SERIAL_NUMBER_FINISHED]
  )[SerialNumberAction.REQUEST_SERIAL_NUMBER_FINISHED];

  const [serialNumberSearch, setSerialNumberSearch] = useState<string>('');
  const [savedSerialSearch, setSavedSerialSearch] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean | undefined>(false);
  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<HttpErrorResponseModel | null>();
  const [serialNumberProducts, setSerialNumberProducts] = useState<ISerialNumberProduct[] | undefined>([]);
  const [firstSearchComplete, setFirstSearchComplete] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);
  const [sortConfig, setSortConfig] = useState({
    column: '',
    sortDirection: SortDirectionEnum.NONE,
  });

  useEffect(() => {
    setError(serialNumberError);
    serialNumberError && setSerialNumberProducts([]);
  }, [serialNumberError]);

  useEffect(() => {
    if ((serialNumberProducts && serialNumberProducts.length > 0) || error) {
      setLoading(false);
      setFirstSearchComplete(true);
    }
  }, [serialNumberProducts, error]);
  useEffect(() => {
    if (matchingSerialProducts) {
      setSerialNumberProducts(matchingSerialProducts);
    }
  }, [matchingSerialProducts]);
  const handleSearchChange = (searchValue: string) => {
    const passesRegex = LetterOrNumberRegex.test(searchValue);
    if (passesRegex) {
      setSerialNumberSearch(searchValue);
    }
    setInvalidSearch(!passesRegex);
  };
  const onSearch = () => {
    if (!loading && serialNumberSearch) {
      dispatch(SerialNumberAction.requestSerialNumber(serialNumberSearch, sortConfig.column, sortConfig.sortDirection, resultsPerPage, page));
      setSavedSerialSearch(serialNumberSearch);
      setSerialNumberProducts([]);
      setSelectedRowId('');
      setLoading(true);
      setError(null);
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (firstSearchComplete) {
      onSearch();
    }
    // eslint-disable-next-line
  }, [resultsPerPage, page, sortConfig]);
  const onSelectedRow = (rowId: string) => {
    if (rowId && rowId !== selectedRowId) {
      setSelectedRowId(rowId);
      return;
    }
    if (rowId) {
      setSelectedRowId(undefined);
    }
  };
  useEffect(
    () => () => {
      // On unmount, clear search results
      dispatch(SerialNumberAction.requestSerialReset(null));
      dispatch(ErrorAction.removeByKey(SerialNumberAction.REQUEST_SERIAL_NUMBER_FINISHED));
    },
    // eslint-disable-next-line
    []
  );
  const copyDataToClipboard = () => {
    if (serialNumberProducts && serialNumberProducts.length > 0) {
      var filteredSnP = serialNumberProducts.map((snp) => {
        var item = {
          serialNumber: snp.serialNumber,
          serialComment: snp.serialComment,
        };
        snp.attributes.forEach((attr) => {
          item[attr.name] = attr.value;
        });
        return item;
      });
      jsonexport(filteredSnP, (err, csv) => {
        if (err) return dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error));
        navigator.clipboard
          .writeText(csv)
          .then((res) => {
            dispatch(ToastsAction.add(`Serial information has been copied to your clipboard.`, ToastStatusEnum.Success));
          })
          .catch((err) => {
            dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error));
          });
      }).catch((e) => dispatch(ToastsAction.add(`Error copying to clipboard`, ToastStatusEnum.Error)));
    }
  };
  const onSortColumnClick = (column: string) => {
    const isDesc = sortConfig.sortDirection === SortDirectionEnum.DESC;
    const isSameCol = sortConfig.column === column;
    const direction = isDesc && isSameCol ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
    setSortConfig({
      column,
      sortDirection: direction,
    });
  };
  return (
    <AccessControl allowedRole={UserRolesEnum.ViewOrders} noAccessRedirect={true}>
      <div className='wrapper'>
        <h1 className='hdg--1'>Serial Number Lookup</h1>
        <div className={styles['serial-card']}>
          <div className={`search ${searchStyles['global-search']}`}>
            <SearchBox
              value={serialNumberSearch}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder='Type Serial Number…'
              buttonIcon={<MagnifyingGlass aria-hidden='true' focusable='false' role='img' />}
              disableInputPadding
              onSubmit={(e) => {
                setSortConfig({
                  column: '',
                  sortDirection: SortDirectionEnum.NONE,
                });
                onSearch();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSortConfig({
                    column: '',
                    sortDirection: SortDirectionEnum.NONE,
                  });
                  onSearch();
                }
              }}
            />
          </div>
          {invalidSearch && <div className={`error-color ${styles['serial-error-message']}`}>This field accepts only digits and letters</div>}
        </div>
        {loading ? (
          <LoadingIndicator isActive={true} inverted />
        ) : (
          <div>
            <div className={styles['flex-space-between']}>
              <div className={styles['accordian-spec-container']}>
                {(error && error.statusCode === 404) || (firstSearchComplete && serialNumberProducts?.length === 0) ? (
                  <p>No results found.</p>
                ) : (
                  serialNumberProducts &&
                  serialNumberProducts.length > 0 && (
                    <h3 className='hdg--3'>
                      Showing {serialNumberResponse?.totalCount} result
                      {serialNumberResponse?.totalCount && serialNumberResponse?.totalCount > 1 && 's'} for "{savedSerialSearch}"
                    </h3>
                  )
                )}
                {!error && firstSearchComplete && (
                  <div>
                    <button className={`${styles['cpy-btn']} icon--copy`} onClick={() => copyDataToClipboard()}>
                      <IconCopy className={styles['icon-margin']} aria-hidden='true' focusable='false' />
                      <p>Copy Search Results</p>
                    </button>
                  </div>
                )}
              </div>
              {!error && firstSearchComplete && (
                <div className='table__header__filters__end'>
                  <select
                    className='table__filter table__filter--wide btn focus-ring'
                    onChange={(e: any): void => setResultsPerPage(e.target.value)}
                    value={resultsPerPage}
                  >
                    {EntryArray.map((entry) => (
                      <option value={entry}>Show {entry} Entries</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {!error && firstSearchComplete && (
              <>
                <SerialLookupItems
                  data={serialNumberProducts || []}
                  serialNumberResponse={serialNumberResponse}
                  onNewPageClick={onPageChange}
                  updateSelectedRow={(row) => {
                    onSelectedRow(row?.id);
                  }}
                  selectedRowId={selectedRowId}
                  onSort={onSortColumnClick}
                  sortConfig={sortConfig}
                  error={error}
                />
              </>
            )}
          </div>
        )}
        {error && error.statusCode !== 404 && <Error4xx response={error} />}
      </div>
    </AccessControl>
  );
}
export default SerialLookupPage;
