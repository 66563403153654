import * as React from 'react';
import IDynamicFormValues from 'stores/create-order/IDynamicFormValues';
import Select, { components } from 'react-select';
import SelectStyles from './SelectStyles';
import SelectStylesMulti from 'components/Form/selectStylesMulti';
import { PRODUCT_CONFIG_LOCAL_STORE } from '../productConfig/ProductConfig';
import { getSelectErrorMessageByFieldName, getSelectHelperMessageByFieldName } from 'utilities/DynamicFormMessagingUtils';

const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <p>Clear Selection</p>
      </components.ClearIndicator>
    )
  );
};

export interface DynamicSelectFieldProps {
  name: string;
  placeHolder: string;
  label: string;
  required: boolean;
  values: string[];
  handleChange: (values: IDynamicFormValues) => void;
  disabled: boolean;
  visible: boolean;
  defaultValue?: '';
  isMulti?: boolean;
  fieldsNotToBeDisplayed: string[];
  isLoading: boolean;
  clubsValue?: string[];
}

interface IHandleSelect {
  value: any;
  selectedValueLockedIn?: boolean;
}

interface IState {
  value: any;
  lockedIn: boolean;
  noClubsSelected: boolean;
  isDisabled: false;
}

export interface DynamicSelectFieldState {}

class DynamicSelectField extends React.Component<DynamicSelectFieldProps, DynamicSelectFieldState> {
  state: IState = {
    value:
      this.props.name === 'CLUBS'
        ? []
        : {
            key: '',
            value: '',
            label: '',
          },
    lockedIn: false,
    noClubsSelected: false,
    isDisabled: false,
  };
  private lockedIn: boolean = false;

  private showTopHelperMessage: boolean = Boolean(this.props.name === 'CUSTOM FITTING FLAG' && !this.props.defaultValue);
  private topHelperMessage: string = getSelectHelperMessageByFieldName(this.props.name);

  private showNotSelectedMessage: boolean = false;
  private showErrorStyle: boolean = false;
  private errorMessage: string = getSelectErrorMessageByFieldName(this.props.name);
  handleSelectChange = (value) => {
    this.setState({ value });
    if (this.props.name === 'CLUBS') {
      if (value) {
        const selectedValues = value.map((item) => item.value);
        this.props.handleChange({ Name: this.props.name, Value: selectedValues });
        this.showErrorStyle = false;
        this.showNotSelectedMessage = false;
      } else {
        this.props.handleChange({ Name: this.props.name, Value: [] });
        this.showNotSelectedMessage = true;
        this.showErrorStyle = true;
      }
    }

    if (this.props.name !== 'CLUBS') {
      // this.setState({ isDisabled: true });
      this.props.handleChange({ Name: this.props.name, Value: value.value });
      this.showTopHelperMessage = !Boolean(this.props.name === 'CUSTOM FITTING FLAG' && value.value);
    }
  };

  getValues = () => {
    const { defaultValue, isMulti } = this.props;
    if (isMulti) {
      if (PRODUCT_CONFIG_LOCAL_STORE.shouldClearClubs) {
        PRODUCT_CONFIG_LOCAL_STORE.shouldClearClubs = false;
        this.setState({ value: [] });
        return [];
      }

      if (this.props.clubsValue) {
        return this.props.clubsValue.map((club) => {
          return { key: club, label: club, value: club };
        });
      }

      return this.state.value;
    }

    return {
      key: defaultValue,
      value: defaultValue,
      label: defaultValue,
    };
  };

  getDisabled = (isLoading, isMulti, values) => {
    if (isLoading) {
      return true;
    }

    if (isMulti) {
      return false;
    }

    if (values.length === 1) {
      return true;
    }

    return false;
  };

  render() {
    const { values, placeHolder, isMulti } = this.props; // , name, required
    return (
      <div
        className={` ${this.props.fieldsNotToBeDisplayed.includes(placeHolder) && 'form__select--not-to-be-displayed'} ${!this.props.visible &&
          'form__select-container--hidden'} form__input-container--full ${this.props.isMulti && 'form__select-container--multi-select'}`}
      >
        {this.showTopHelperMessage && <div className={`top-helper-message`}>{this.topHelperMessage}</div>}
        <div className={`form__select-container`}>
          <div className={`${this.showErrorStyle && `form__error-highlight`}`}>
            <label className={'form__select-label'} htmlFor={placeHolder}>
              {placeHolder}
            </label>

            <Select
              components={{ ClearIndicator }}
              styles={isMulti ? SelectStylesMulti : SelectStyles}
              selection
              onChange={this.handleSelectChange}
              options={values.map((value) => {
                return { key: value, value, label: value };
              })}
              closeMenuOnSelect={isMulti ? false : true}
              placeholder={placeHolder}
              value={this.getValues()}
              isDisabled={this.getDisabled(this.props.isLoading, isMulti, values)}
              // value={this.props.defaultValue}
              // isDisabled={isMulti ? false : this.state.isDisabled || this.props.disabled}
              isMulti={isMulti}
            />
          </div>
          {this.showNotSelectedMessage && (
            <div className={`${this.showErrorStyle && `helper-error-message`} helper-message`}>{this.errorMessage}</div>
          )}
        </div>
      </div>
    );
  }
}

export default DynamicSelectField;
