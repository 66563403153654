import environment from 'environment';
import EffectUtility from '../EffectUtility';
import CertificationCategoriesResponseModel from './models/CertificationCategoriesResonseModel';
import LocationResponseModel from './models/LocationResponseModel';

export default class LocationCertificationUtility {
  public static baseCertificationCatUri: string = `${(environment.api as any).certificationCategories}`;
  public static baseLocationsUri: string = `${(environment.api as any).location}`;

  /**
   * This method retrieves a list of certificationCategories in the form of a CertficationCategoriesResponse object
   * @param dispatch callback to dispatch error action
   */
  public static async getAllCertificationCategories() {
    const endpoint: string = `${LocationCertificationUtility.baseCertificationCatUri}`;
    const response = await EffectUtility.getToModel<CertificationCategoriesResponseModel>(CertificationCategoriesResponseModel, endpoint);
    // TODO: Figure out how to handle errors
    return response.data;
  }

  /**
   * This method retrieves a list of location certification in the form of a CertficationCategoriesResponse object
   * @param dispatch callback to dispatch error action
   */
  public static async getLocationCertifications(locationId: string) {
    const endpoint: string = `${LocationCertificationUtility.baseLocationsUri}/${locationId}/certifications`;
    const response = await EffectUtility.getToModel<CertificationCategoriesResponseModel>(CertificationCategoriesResponseModel, endpoint);
    return response;
  }
  public static async updateLocationCertificationVerfication(locationId: string, propertyToUpdate: object) {
    const endpoint: string = `${LocationCertificationUtility.baseLocationsUri}/${locationId}`;
    const response = await EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate).catch(() => {
      EffectUtility.putToModelBody<LocationResponseModel>(LocationResponseModel, endpoint, propertyToUpdate);
    });
    return response;
  }

  public static async createLocationCertification(locationId: string, certificationId: string) {
    const endpoint: string = `${LocationCertificationUtility.baseLocationsUri}/${locationId}/certification/${certificationId}`;
    const response = await EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint).catch(() => {
      EffectUtility.postToModelBody<LocationResponseModel>(LocationResponseModel, endpoint);
    });
    return response;
  }

  /**
   * This method deletes the location certification
   * @param locationId Requires a location ID so that we know which location we are changing in the database
   * @param certificationId Requires a certificationId to know which specific certification is being changed
   * @returns
   */
  public static async deleteLocationCertification(locationId: string, certificationId: string) {
    const endpoint: string = `${LocationCertificationUtility.baseLocationsUri}/${locationId}/certification/${certificationId}`;
    const response = await EffectUtility.deleteToModel<LocationResponseModel>(LocationResponseModel, endpoint);
    return response;
  }
}
