import { BaseModel } from 'sjs-base-model';
import IContact from './IContact';

export default class ContactResponseModel extends BaseModel {
  public readonly data: IContact;
  public readonly success: boolean = true;
  public readonly errors: string[] = [];

  constructor(data) {
    super();

    this.update(data);
  }

  public update(data: Partial<ContactResponseModel>): void {
    super.update(data);
  }
}
