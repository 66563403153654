import environment from 'environment';
import productionEnv from 'environments/production';
import productionEUEnv from 'environments/production-eu';
import productionCAEnv from 'environments/production-ca';
import testEnv from 'environments/test';
import stageEnv from 'environments/stage';
import stageEUEnv from 'environments/stage-eu';
import stageCAEnv from 'environments/stage-ca';

const getUri = (region: string) => {
  if (environment.isDevelopment) {
    return environment.uri.baseUri;
  }
  if (environment.isTesting) {
    return testEnv.uri.baseUri;
  }
  switch (region) {
    case 'na':
      if (environment.isStage) {
        return stageEnv.uri.baseUri;
      }
      return productionEnv.uri.baseUri;
    case 'eu':
      if (environment.isStage) {
        return stageEUEnv.uri.baseUri;
      }
      return productionEUEnv.uri.baseUri;
    case 'ca':
      if (environment.isStage) {
        return stageCAEnv.uri.baseUri;
      }
      return productionCAEnv.uri.baseUri;
    default:
      console.log('no region found');
      break;
  }
};

export const REGION_COOKIE_NAME = 'Ping_Region_URL';
export const REGIONS = [
  {
    name: 'United States',
    url: getUri('na'),
  },
  {
    name: 'Canada',
    url: getUri('ca'),
  },
  {
    name: 'Europe',
    url: getUri('eu'),
  },
  {
    name: 'Asia',
    url: 'http://www.pinggolf.biz/CSPJAPAN',
  },
];
