import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EffectUtility from '../../utilities/EffectUtility';
import SerialNumberProductResponseModel from './models/SerialNumberProductResponseModel';

export default class SerialNumberEffect {
  public static async requestItemsBySerialNumber(
    serialNumber: string,
    sortKey: string | undefined,
    sortDirection: string | undefined,
    pageSize: number | undefined,
    pageNumber: number | undefined
  ): Promise<SerialNumberProductResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${
      (environment.api as any).serial
    }/serialNumber?serialNumber=${serialNumber}&sortKey=${sortKey}&sortDirection=${sortDirection}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return EffectUtility.getToModel<SerialNumberProductResponseModel>(SerialNumberProductResponseModel, endpoint);
  }
}
