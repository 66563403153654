import React, { useContext } from 'react';
import Modal from 'components/Modal/Modal';
import styles from './LocationCardModal.module.scss';
import ILocations from 'utilities/Location/models/ILocations';
import LocationUtility from 'utilities/Location/LocationUtility';
import LocationsResponseModel from 'utilities/Location/models/LocationsResponseModel';
import { LocationContext } from 'contexts/fitting-management-locations/LocationContext';
import ToastsAction from 'stores/toasts/ToastsAction';
import ToastStatusEnum from 'constants/ToastStatusEnum';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import LocationErrorMessages from 'constants/LocationErrorMessagesEnum';

interface IProps {
  setModalState: any;
  location: ILocations;
}

const LocationCardModal = ({ setModalState, location }: IProps): JSX.Element => {
  const { updateAccountLocations } = useContext(LocationContext);
  const dispatch: Dispatch = useDispatch();

  const removeHandler = () => {
    LocationUtility.deleteLocationById(location.id).then((resp) => {
      setModalState(false);
      if (resp.statusCode === 202) {
        LocationUtility.requestLocationByAccount(location.accountNumber).then((response: LocationsResponseModel) => {
          updateAccountLocations && updateAccountLocations(response.data);
        });
      } else {
        dispatch(ToastsAction.add(LocationErrorMessages.DELETE_LOCATION, ToastStatusEnum.Error));
      }
    });
  };

  return (
    <Modal onClose={() => setModalState(false)} isFullScreen={false} isSmaller={true} aria-labelledby='LinkModal'>
      <h1 className={`hdg hdg--3 ${styles.title}`}>Are you sure you want to remove this location?</h1>
      <p className={styles.message}>
        Removing location <span className={styles.message_name}>{location.name}</span> from this account cannot be undone.
      </p>
      <div className='split'>
        <button className='btn btn--inverted btn__text-only' onClick={() => setModalState(false)}>
          Cancel
        </button>
        <button className='btn btn--red--inverted btn__text-only' onClick={removeHandler}>
          Remove Location
        </button>
      </div>
    </Modal>
  );
};

export default LocationCardModal;
