import IPermission from 'stores/users/models/IPermission';
import IUser from 'stores/users/models/IUser';
import { Membership } from 'stores/users/models/IUserV3';

export function canUserEdit(currentUser: IUser, userToEditMemberships: IPermission[], userToEditId: number = 0): boolean {
  const currentUserPermissions = currentUser.permissions.canEditMembership;

  // User has no membership they can be edited
  if (!userToEditMemberships[0]) {
    return true;
  }

  // CurrentUser has permission to edit user
  if (currentUserPermissions.includes(userToEditMemberships[0].key)) {
    return true;
  }

  // currentUser is userToEdit and has canEditSelf permission
  if (currentUser.userID === userToEditId && currentUser.permissions.canEditSelf) {
    return true;
  }

  return false;
}

export function canUserEditV3(currentUser: IUser, userToEditMembership: Membership, userToEditId: number = 0): boolean {
  const currentUserPermissions = currentUser.permissions.canEditMembership;

  if (!userToEditMembership) {
    return true;
  }

  if (currentUserPermissions.includes(userToEditMembership.key)) {
    return true;
  }

  if (currentUser.userID === userToEditId && currentUser.permissions.canEditSelf) {
    return true;
  }

  return false;
}
