/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import UserRolesEnum from '../../constants/UserRolesEnum';
import AccessControl from '../../components/AccessControl/AccessControl';
import { useParams, useHistory } from 'react-router-dom';
import Title from 'components/Title/Title';
import IStore from '../../models/IStore';
import styles from './EditUsersPage.module.scss';
import { oc } from 'ts-optchain.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import UserRolesOptionsArr from '../../constants/UserRolesOptionsArr';
import UserFieldsArr from '../../constants/UserFieldsArr';
import Form from '../../components/Form/Form';
import UserRoleDescEditPages from './components/UserRoleDescEditPages';
import UsersAction, { IRolesFilterState } from 'stores/users/UsersAction';
import { canUserEditV3 } from 'containers/AccountProfilePage/helpers/AccountProfileHelpers';
import { MSAL_AUTH } from 'index';
import UsersAccountsList from './components/UsersAccountsList';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import Modal from 'components/Modal/Modal';
import Error4xx from 'components/Error4xx/Error4xx';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { KenticoUser } from 'stores/users/models/IUserV3';
import UserResponseModelV3 from 'stores/users/models/UserResponseModelV3';
import { IFormErrors } from 'components/Form/IFormErrors';
import highlightFields from 'components/Form/FormHelpers/highlightFields';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { handleSubmit, handleValidation, handleDeleteUser } from './EditUsersHelpers';
import EditAccountsOverlay from 'components/AccountsOverlay/EditAccountsOverlay';
import { ReactComponent as Restart } from 'assets/media/icons/icon-restart.svg';
import { ReactComponent as ArrowsSwitch } from 'assets/media/icons/icon-arrows-switch.svg';
import { selectRawErrors } from 'selectors/error/ErrorSelector';
import AdminUserConfirmModal from 'containers/CreateUsersPage/AdminUserConfirmModal';

export const checkAssignableRoles = (currentUser: UserResponseModel, checkIfEdit) => {
  let currentUserPermissionsArray;
  if (checkIfEdit) {
    currentUserPermissionsArray = currentUser?.data.permissions.canEditMembership;
  } else {
    currentUserPermissionsArray = currentUser?.data.permissions.canCreateMembership;
  }
  const newRoleOptions = UserRolesOptionsArr.filter((role) => {
    if (currentUserPermissionsArray?.includes(role.value)) {
      return role;
    }
    return null;
  });
  return newRoleOptions;
};

const EditUsersPage = (): JSX.Element => {
  const userRoleFieldName = 'user_membership';
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const { accountNumber, fromAdmin, userId } = useParams();
  const currentAndPreviousPaths = useSelector((state: IStore) => oc(state).navigation.currentAndPreviousPaths({ current: '', previous: '' }));
  const baseUser: UserResponseModelV3 | null = useSelector((state: IStore) => oc(state).users.userById(null));
  const user: KenticoUser | undefined = baseUser?.data.kenticoUser;
  const userIdInt = baseUser?.data.kenticoUser.userId;
  const currentUserData = useSelector((state: IStore) => oc(state).users.currentUser.data());
  const currentUser = useSelector((state: IStore) => oc(state).users.currentUser(null));
  const isCurrentUser = currentUserData?.userID === userIdInt;
  const linkedAccounts = useSelector((state: IStore) => oc(state).users.linkedAccounts.data());
  const rolesFilter: IRolesFilterState | null = useSelector((state: IStore) => oc(state).users.rolesFilters(null));
  const [updateLoading, setUpdateLoading] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    hasError: false,
    errors: [],
  });
  const [callError, setCallError] = useState<HttpErrorResponseModel | null>(null);
  const [, setToken] = useState<any>();
  const [openAdminModal, setOpenAdminModal] = useState<boolean>(false);
  const [adminRoleValue, setAdminRoleValue] = useState<any>();

  const editRef = useRef<any>(null);
  const [formState, setFormState] = useState({
    first_name: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.firstName || '' : user?.firstName || '',
    last_name: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.lastName || '' : user?.lastName || '',
    email: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.email || '' : user?.email || '',
    user_membership:
      currentUserData?.userID === userIdInt
        ? currentUserData?.memberships[0]
          ? currentUserData.memberships[0].key
          : ''
        : user?.membership
        ? user?.membership.key
        : '', // labeled membership in api not roles
  });

  const editAccount4xxResponses = selectRawErrors(
    useSelector((state: IStore) => state),
    [UsersAction.REQUEST_ACCOUNT_USERS_FINISHED, 'linkUserId', 'unlinkUserId', 'linkAndUnlinkMultipleUsers']
  );

  // Check if admin
  const isAdmin = () => {
    if (fromAdmin && !currentUser?.data?.memberships?.find((dm) => dm.key === UserRolesEnum.PingAdmin)) {
      history.push('/account-profile');
    }
  };
  isAdmin();

  // 	// Grabs the user on pageLoad
  useEffect(() => {
    dispatch(UsersAction.requestUserById(userId));

    return () => {
      // Clear Store of edit user info
      dispatch(UsersAction.requestUserAccounts(null));
      dispatch(UsersAction.requestUserById(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // After above grabs the user
  useEffect(() => {
    if (user) {
      setShouldDisable(true);
      dispatch(UsersAction.requestUserAccounts(user.email)).then(() => setShouldDisable(false));
    }
  }, [user, dispatch]);

  // Changes form errors on page load
  useEffect(() => {
    const fieldNames = ['first_name', 'last_name', 'email', 'user_membership'];
    highlightFields(fieldNames, formErrors);
  }, [formErrors]);

  // Set Form State
  useEffect(() => {
    const token = MSAL_AUTH.token;
    setToken(token);

    setFormState({
      first_name: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.firstName || '' : user?.firstName || '',
      last_name: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.lastName || '' : user?.lastName || '',
      email: currentUserData && currentUserData?.userID === userIdInt ? currentUserData.email || '' : user?.email || '',
      user_membership:
        currentUserData?.userID === userIdInt
          ? currentUserData?.memberships[0]
            ? currentUserData.memberships[0].key
            : ''
          : user?.membership
          ? user?.membership.key
          : '',
    });

    /**
     * Check if user is allowed to edit the current user based on permissions
     * If not send them to account profile view
     */
    if (currentUser && user?.membership && !canUserEditV3(currentUser.data, user?.membership, user.userId)) {
      history.push('/account-profile');
    }

    // eslint-disable-next-line
  }, [currentUser, currentUserData, user, userIdInt]);

  const userRolesDefault = {
    value: user?.membership ? user?.membership.key : '',
    label: user?.membership ? user?.membership.displayValue : '',
    name: 'user_membership',
  };

  const currentUserRolesDefault = {
    value: currentUserData?.memberships[0] ? currentUserData?.memberships[0].key : '',
    label: currentUserData?.memberships[0] ? currentUserData?.memberships[0].value.replace('Ping Biz', '') : '',
    name: 'user_membership',
  };

  const fields = [
    ...UserFieldsArr,
    {
      name: 'user_membership',
      type: 'select',
      label: 'User Role',
      inputType: 'select',
      options: currentUser ? checkAssignableRoles(currentUser, true) : [],
      defaultSelectValue: currentUserData?.userID === userIdInt ? currentUserRolesDefault : userRolesDefault,
    },
  ];

  const linkUser = () => {
    setModalState(true);
  };

  const handleFieldChange = (input) => (e) => {
    setFormState({ ...formState, [input]: e.target.value });

    if (formErrors?.errors?.length > 0) {
      const newFormState = { ...formState, [input]: e.target.value };
      const errors = handleValidation(newFormState, setFormErrors);
      setFormErrors(errors);
    }
  };
  const updateAdminRole = () => {
    if (adminRoleValue) {
      setFormState({ ...formState, [adminRoleValue?.name]: adminRoleValue.value });
      const newFormState = { ...formState, [adminRoleValue.name]: adminRoleValue.value };
      const errors = handleValidation(newFormState, setFormErrors);
      setFormErrors(errors);
    }
  };
  const handleSelectFieldChange = (newValue: any) => {
    if (newValue.name === userRoleFieldName && newValue.value === UserRolesEnum.PingAdmin) {
      setAdminRoleValue(newValue);
      setOpenAdminModal(true);
      return;
    }
    setFormState({ ...formState, [newValue.name]: newValue.value });

    if (formErrors?.errors?.length > 0) {
      const newFormState = { ...formState, [newValue.name]: newValue.value };
      const errors = handleValidation(newFormState, setFormErrors);
      setFormErrors(errors);
    }
  };

  if (!user || !userIdInt) {
    return <LoadingIndicator className={'full-height'} isActive={true} inverted={true} />;
  }

  return (
    <AccessControl allowedRole={UserRolesEnum.ManageUsers} noAccessRedirect={true}>
      <div className={'wrapper '}>
        {modalState && (
          <Modal onClose={() => setModalState(false)} aria-labelledby='modalTitle'>
            <EditAccountsOverlay userToLink={user} linkedAccounts={linkedAccounts} closeModal={() => setModalState(false)} />
          </Modal>
        )}
        <UserRoleDescEditPages />
        <Title title={`${currentUserData?.userID === userIdInt ? 'Edit Personal Information' : 'Edit User'}`} />
        <div className={`${styles['edit-user__wrapper']} ${styles['edit-user']}`}>
          <div>
            <>
              <h4 className='hdg hdg--4'>User Profile</h4>
              <Form
                fields={fields}
                handleFieldChange={handleFieldChange}
                handleSelectFieldChange={handleSelectFieldChange}
                formState={formState}
                thankYouMessage='thank you'
                formRef={editRef}
                formId={'edit-user-form'}
              />
              <div className={styles['edit-user__btns']}>
                {currentUserData?.userID !== userIdInt && (
                  <button
                    type='submit'
                    className={`btn--no-style btn--bold btn--red-text`}
                    onClick={() =>
                      handleDeleteUser({
                        editRef,
                        linkedAccounts,
                        setDeleteLoading,
                        dispatch,
                        userId,
                        userIdInt,
                        fromAdmin,
                        rolesFilter,
                        history,
                        currentAndPreviousPaths,
                        accountNumber,
                        setCallError,
                      })
                    }
                  >
                    Delete User
                  </button>
                )}
                <button
                  type='submit'
                  form='edit-user-form'
                  className={`btn btn__icon ${styles.submit}`}
                  onClick={(e) =>
                    handleSubmit({
                      e,
                      formState,
                      setFormErrors,
                      userIdInt,
                      setUpdateLoading,
                      rolesFilter,
                      history,
                      currentAndPreviousPaths,
                      fromAdmin,
                      accountNumber,
                      dispatch,
                      setCallError,
                      isCurrentUser,
                    })
                  }
                >
                  Update User
                  <Restart className='btn__svg-icon' aria-hidden='true' focusable='false' role='img' />
                </button>

                <div className={styles['loading']}>
                  {(deleteLoading || updateLoading) && <LoadingIndicator sizeableSpinner={{ height: 50, width: 25, marginLeft: 20 }} />}
                </div>
              </div>
              {formErrors.errors.length > 0 && (
                <div className='form__error'>
                  {formErrors.errors.map((e, i) => (
                    <div className='errors__line' key={i}>
                      {e.errorMessage}
                    </div>
                  ))}
                </div>
              )}

              <Error4xx
                onLight
                overrideText={{
                  // eslint-disable-next-line quotes
                  headingText:
                    callError?.userMessage ||
                    "We're sorry but it looks like an error occurred and we cannot modify the user you requested at this time.",
                }}
                response={callError}
              />
            </>
          </div>
          <div>
            <>
              <h4 className='hdg hdg--4'>Belongs to these accounts</h4>
              <UsersAccountsList fromAdmin={fromAdmin} linkedAccounts={linkedAccounts} selectedUser={user} />
              <div className={styles['edit-user__button-container']}>
                <button
                  type='submit'
                  disabled={shouldDisable}
                  className={`btn btn__text-only ${shouldDisable && 'btn--disabled'}`}
                  onClick={linkUser}
                >
                  Manage Linked Accounts
                  <ArrowsSwitch className='btn__svg-icon' aria-hidden='true' focusable='false' role='img' />
                </button>
              </div>
              <Error4xx response={editAccount4xxResponses['UsersAction.REQUEST_ACCOUNT_USERS_FINISHED']} />
              <Error4xx response={editAccount4xxResponses['linkUserId']} />
              <Error4xx response={editAccount4xxResponses['unlinkUserId']} />
              <Error4xx response={editAccount4xxResponses['linkAndUnlinkMultipleUsers']} />
            </>
          </div>
        </div>
        {openAdminModal && (
          <AdminUserConfirmModal
            onConfirm={() => {
              setOpenAdminModal(false);
              updateAdminRole();
            }}
            onCancel={() => setOpenAdminModal(false)}
          />
        )}
      </div>
    </AccessControl>
  );
};

export default EditUsersPage;
