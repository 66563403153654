import ILocationState from './ILocationState';

export const initialLocation: ILocationState = {
  id: '',
  name: '',
  regionId: '',
  statusId: '',
  displayHours: false,
  hasAddress: false,
  certificationsVerified: false,
  environmentsVerified: false,
  technologiesVerified: false,
  displayOnMap: true,
};
