import React, { Dispatch, SetStateAction } from 'react';
import logo from '../../../../assets/media/images/header_logo.svg';
import logoSmall from '../../../../assets/media/images/header_logo_small.svg';
import { ReactComponent as CaretDown } from '../../../../assets/media/icons/icon-caret-down.svg';
import styles from './PrimaryNavHeader.module.scss';

interface IProps {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

function PrimaryNavHeader({ isExpanded, setIsExpanded }: IProps): JSX.Element {
  return (
    <>
      <a href='#main-view' className={styles.skipLink}>
        Skip to Main Content
      </a>
      <button className={`${styles.primaryNavHeader} focus-ring`} onClick={(): void => setIsExpanded(!isExpanded)}>
        <CaretDown aria-hidden='true' focusable='false' role='img' />
        <img src={logo} alt='' className={styles['primaryNavHeader-logo_big']} />
        <img src={logoSmall} alt='' className={styles['primaryNavHeader-logo_small']} />
      </button>
    </>
  );
}

export default PrimaryNavHeader;
