import styles from './LoadingWithLogout.module.scss';
import React from 'react';
import classNames from 'classnames';
import { MSAL_AUTH } from 'index';

interface IProps {
  readonly isActive?: boolean;
  readonly className?: string;
  readonly inverted?: boolean;
  readonly noLoadingText?: boolean;
  readonly lineLoader?: boolean;
  readonly smallWrapper?: boolean;
  readonly customErrorText?: string | JSX.Element;
  readonly displayError?: boolean;
}
interface IState {}

interface ItryAgainError {
  customErrorText?: JSX.Element | string;
  handleTryAgain: any;
  customErrorHeader?: string;
  customButtonText?: string;
}
export const TryAgainError = ({ customErrorText, handleTryAgain, customErrorHeader, customButtonText }: ItryAgainError) => {
  return (
    <div>
      <p className={styles['loader-container__HeaderMessage']}>{customErrorHeader ? customErrorHeader : "We're sorry but an error has occurred."}</p>
      <p className={styles['loader-container__ErrorMessage']}>{customErrorText}</p>
      <button className={`${styles.loaderButton} btn btn--loading btn__text-only`} onKeyUp={handleTryAgain} onClick={handleTryAgain}>
        {customButtonText ? customButtonText : 'Try again'}
      </button>
    </div>
  );
};
export default class LoadingWithLogout extends React.PureComponent<IProps, IState> {
  public static defaultProps: IProps = {
    isActive: false,
    // eslint-disable-next-line no-undefined
    className: undefined,
    inverted: false,
    noLoadingText: false,
    displayError: false,
  };

  public render(): JSX.Element {
    const { isActive, className, inverted } = this.props;
    const wrapperCss: string = classNames(className, {
      [styles.wrapper]: isActive,
    });
    const containerCss: string = classNames(styles['loader-container'], {
      [styles.inverted]: inverted,
    });
    const handleTryAgain = () => {
      MSAL_AUTH.logout();
    };

    return (
      <div className={`${this.props.smallWrapper ? styles['small-wrapper'] : wrapperCss}`}>
        {isActive && (
          <div className={containerCss}>
            {!this.props.displayError && (
              <div className={`${this.props.lineLoader ? styles.spinner : styles['lds-roller']}`}>
                {this.props.lineLoader ? (
                  <>
                    <div className={styles.bounce1}></div>
                    <div className={styles.bounce2}></div>
                    <div className={styles.bounce3}></div>
                  </>
                ) : (
                  <>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </>
                )}
              </div>
            )}

            {!this.props.displayError && <p className={styles['loader-container__HeaderMessage']}>Verifying User...</p>}
            {this.props.displayError && (
              <div>
                <TryAgainError customErrorText={this.props.customErrorText} handleTryAgain={handleTryAgain} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
