import LoadingWithLogout from 'components/LoadingWithLogout/LoadingWithLogout';
import RouteEnum from 'constants/RouteEnum';
import IStore from 'models/IStore';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import UserResponseModel from 'stores/users/models/UserResponseModel';
import UsersAction from 'stores/users/UsersAction';
import IAccount from 'stores/accounts/models/IAccount';
import AccountsAction from 'stores/accounts/AccountsAction';
import { MSAL_AUTH } from 'index';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { PingCustomerServiceContactMessage } from 'components/Content/Contact/CustomerServiceContactMessage';

const LoginPage = () => {
  const isMounted = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const entryPath = location.state?.path.location?.pathname;
  const currentUser: UserResponseModel | null = useSelector((state: IStore) => state.users.currentUser);
  const localAccountDetails = localStorage.getItem('accountDetail');
  const currentAccount: IAccount | null = useSelector((state: IStore) => state.accounts.activeAccount);
  const [displayError, setDisplayError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();
  const initiateLogin = async () => {
    if (MSAL_AUTH.token) {
      setIsLoading(true);
      dispatch(UsersAction.setCurrentUser(MSAL_AUTH.token)).then(() => {
        setTimeout(() => {
          if (isMounted.current) {
            setIsLoading(false);
            if (currentUser === null) {
              setDisplayError(true);
            }
          }
        }, 10000);
      });
    } else {
      setTimeout(() => {
        if (isMounted.current) {
          setIsLoading(false);
          setDisplayError(true);
        }
      }, 10000);
    }
  };

  useEffect(() => {
    if (MSAL_AUTH.hasAccount()) {
      if (currentUser === null) {
        if (MSAL_AUTH.token) {
          initiateLogin();
        } else {
          setTimeout(() => initiateLogin(), 100);
        }
      }
    } else {
      setIsLoading(true);
      MSAL_AUTH.initialize().then(() => {
        if (currentUser === null) {
          if (MSAL_AUTH.token) {
            initiateLogin();
          } else {
            setTimeout(() => initiateLogin(), 100);
          }
        } else {
          setIsLoading(false);
          setDisplayError(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (currentUser !== null) {
      if (!currentAccount && localAccountDetails) {
        dispatch(AccountsAction.setActiveAccount(AccountsAction.getActiveAccountLocal().localAccount));
      }
    }

    if (currentUser !== null) {
      if (entryPath) {
        history.push(entryPath);
      } else {
        history.push(RouteEnum.Landing);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return <LoadingIndicator className={'full-height'} customLoadingText={'Verifying User...'} isActive={true} inverted={true} />;
  }
  return (
    <div>
      <LoadingWithLogout
        customErrorText={
          <>
            <PingCustomerServiceContactMessage />
          </>
        }
        className={'full-page'}
        isActive={true}
        inverted={true}
        displayError={displayError}
      />
    </div>
  );
};

export default LoginPage;
