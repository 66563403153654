import React from 'react';
import styles from '../orderCartModal/OrderCartModal.module.scss';
import OrderFooter from '../OrderFooter/OrderFooter';
import { useHistory } from 'react-router-dom';
import { IGetCartResponse } from 'stores/create-order/models/IGetCart';
import OrderReviewTable from '../orderReviewTable/OrderReviewTable';
import { Dispatch } from 'redux';
import IStore from 'models/IStore';
import { useDispatch, useSelector } from 'react-redux';
import IAccount from 'stores/accounts/models/IAccount';
import { oc } from 'ts-optchain.macro';
import TabStateHelpers from 'containers/CreateOrderPage/helpers/TabStateHelpers';
import CartStateHelpers from 'containers/CreateOrderPage/helpers/CartStateHelpers';
import ITabState from 'stores/create-order/models/ITabState';
import { formatCurrency } from 'utilities/FormatCurrency';

// TODO: need more interfaces on files contained in components
interface IProps {
  orders: IGetCartResponse;
  cartTotal: number;
  currency: string;
  setOpen: (v: boolean) => void;
}

const OrderCartModal = ({ orders, cartTotal, currency, setOpen }: IProps) => {
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const activeAccount: IAccount | null = useSelector((state: IStore) => oc(state).accounts.activeAccount(null));
  const accountNumber: number | undefined = activeAccount?.accountNumber;
  const tabState: ITabState = useSelector((state: IStore) => state.createOrder.tabState);
  const cartID = useSelector((state: IStore) => state.createOrder.cartState?.cart.cartID);

  // TODO: Display Address, Order Items, Order Cost

  const pathName = history.location.pathname;
  return (
    <div className={styles.modalWrapper}>
      {orders.cartItems.length > 0 && (
        <div>
          <OrderReviewTable setOpen={setOpen} orders={orders} />
          <div
            className={`${styles['order-cart__subtotal-container']} ${
              pathName !== ('/create-order' || '/create-order/shipping') ? styles['order-cart__subtotal-container--review'] : ''
            }`}
          >
            <div>
              <hr className={styles['order-cart__divider']} />
              <div className={styles['order-cart__subtotal']}>
                <h4 className='hdg hdg--4'>Subtotal {formatCurrency(currency, cartTotal)}</h4>
              </div>
            </div>
          </div>
          {tabState.tabIndex === 0 && (
            <OrderFooter
              stepName='productCards'
              handleShipping={() => TabStateHelpers.handleShipping(dispatch, tabState)} // special
              onClearCartAndShipping={() => CartStateHelpers.cancelOrder(dispatch, accountNumber, cartID)}
            />
          )}
          {(tabState.tabIndex === 1 || tabState.tabIndex === 2) && (
            <OrderFooter stepName='orderShipping' fromModal={true} handleReview={() => TabStateHelpers.handleReview(dispatch, tabState)} />
          )}
        </div>
      )}

      {orders.cartItems.length === 0 && <div className={styles.emptyCart}>Your cart is currently empty.</div>}
    </div>
  );
};

export default OrderCartModal;
