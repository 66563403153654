import React from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
import styles from './Categories.module.scss';
import { CategoryArrayUnion, CategoryUnion } from 'containers/LocationInfoPage/models/ICategoryUnion';
interface IProps {
  listOfCategories: CategoryArrayUnion;
  locationSpecificItems: CategoryArrayUnion;
  itemListName: string;
  addLocationSpecificItem: Function;
  deleteLocationSpecificItem: Function;
  isProtected: boolean;
}

const Categories = ({
  listOfCategories,
  itemListName,
  locationSpecificItems,
  addLocationSpecificItem,
  deleteLocationSpecificItem,
  isProtected,
}: IProps): JSX.Element => {
  return (
    <div className={styles.cardList}>
      {listOfCategories?.map((category: CategoryUnion, index) => {
        return (
          <CategoryCard
            key={category.name + index}
            categoryName={category.name}
            itemList={category[itemListName]}
            locationSpecificItems={locationSpecificItems}
            isProtected={isProtected}
            addLocationSpecificItem={addLocationSpecificItem}
            deleteLocationSpecificItem={deleteLocationSpecificItem}
          />
        );
      })}
      {isProtected && <span className={styles.protectedDescription}>** Locked section(s) are managed by PING</span>}
    </div>
  );
};

export default Categories;
