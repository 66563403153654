import '../../../SearchResultsPage/components/SearchResultsList/SearchResultsList.module.scss';
import React from 'react';
import InvoicesResponseModel from '../../../../stores/invoices/models/InvoicesResponseModel';
import InvoicesRequestModel from '../../../../stores/invoices/models/InvoicesRequestModel';
import TransactionHistoryTable from '../TransactionHistoryTable/TransactionHistoryTable';
import TransactionHistoryFilters from '../TransactionHistoryFilters/TransactionHistoryFilters';
import SortDirectionEnum from 'constants/SortDirectionEnum';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Error4xx from 'components/Error4xx/Error4xx';

// TODO: move filters out to Invoice page so that we can avoid calling and setting filter initial state twice

const columns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Transaction Date',
    accessor: 'transactionDate',
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
  },
  {
    Header: 'Purchase Order',
    accessor: 'purchaseOrderNumber',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Balance',
    accessor: 'openBalance',
  },
  {
    Header: 'Status',
    accessor: 'transactionStatus',
  },
  {
    Header: 'Due Date',
    accessor: 'transactionDueDate',
  },
];

interface IProps {
  transactionResponse: InvoicesResponseModel | null;
  transactionHistoryConfig: any;
  setTransactionHistoryConfig: any;
  onNewPageClick: (page: number) => void;
  accountNumber: number;
  resetTransactionHistory: () => void;
  transactionHistoryLoading: boolean;
  refreshResults: () => void;
  invoices4xxResponses: any;
}

const TransactionHistory = ({
  transactionResponse,
  onNewPageClick,
  transactionHistoryConfig,
  setTransactionHistoryConfig,
  accountNumber,
  resetTransactionHistory,
  transactionHistoryLoading,
  invoices4xxResponses,
  refreshResults,
}: IProps): JSX.Element | null => {
  function onSortColumnClick(column: string) {
    let direction;
    const isDesc = transactionHistoryConfig.sortData.SortDirection === SortDirectionEnum.DESC;
    const isSameCol = transactionHistoryConfig.sortData.Column === column;
    if (isSameCol) {
      direction = isDesc ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
    } else {
      direction = SortDirectionEnum.DESC;
    }

    resetTransactionHistory();
    setTransactionHistoryConfig(
      new InvoicesRequestModel({
        ...transactionHistoryConfig,
        ...{ sortData: { Column: column, SortDirection: direction } },
      })
    );
  }

  function updateResults(updatesToRequestTransactionsConfig) {
    resetTransactionHistory();
    setTransactionHistoryConfig(
      new InvoicesRequestModel({
        ...transactionHistoryConfig,
        ...updatesToRequestTransactionsConfig,
        page: updatesToRequestTransactionsConfig.page ? updatesToRequestTransactionsConfig.page : 1,
      })
    );
  }

  const { endDate, startDate, transactionType, transactionStatus, pastDue } = transactionHistoryConfig;

  return (
    <div>
      <div className='table__header'>
        <h3 className='hdg hdg--3'>Transaction History</h3>
        <TransactionHistoryFilters
          endDate={endDate}
          updateResults={updateResults}
          startDate={startDate}
          transactionType={transactionType}
          transactionStatus={transactionStatus}
          pastDue={pastDue}
        />
      </div>
      <Error4xx response={invoices4xxResponses['InvoicesAction.REQUEST_TRANSACTIONS_FINISHED']} textOnly>
        {transactionHistoryLoading ? (
          <LoadingIndicator isActive={true} className={'full-height'} inverted={true} />
        ) : (
          <>
            {transactionResponse && (
              <TransactionHistoryTable
                accountNumber={accountNumber}
                columns={columns}
                transactionHistoryResponse={transactionResponse}
                onNewPageClick={onNewPageClick}
                onSortColumnClick={onSortColumnClick}
                sortCol={transactionHistoryConfig.sortData.Column}
                sortDir={transactionHistoryConfig.sortData.SortDirection}
              />
            )}
          </>
        )}
      </Error4xx>
    </div>
  );
};

export default TransactionHistory;
