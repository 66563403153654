import React, { useEffect, useState } from 'react';
import styles from './GlobalSearch.module.scss';
import RouteEnum from '../../constants/RouteEnum';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { ReactComponent as MagnifyingGlass } from '../../assets/media/icons/icon-magnifying-glass.svg';
import AccessControl from 'components/AccessControl/AccessControl';
import UserRolesEnum from 'constants/UserRolesEnum';
import SearchAction from 'stores/search/SearchAction';
import SearchEnum from 'constants/SearchEnum';
import SearchBox from './SearchBox';

interface IProps {
  classNames?: string;
  dispatch: Function;
  initialTarget?: string;
  initialValue?: string;
  referringPage?: string;
}

function handleSubmit(e, dispatch, referringPage = '', searchQuery, searchTarget): void {
  e.preventDefault();
  dispatch(SearchAction.requestSearchReset(null));
  dispatch(
    push({
      pathname: RouteEnum.SearchResults,
      search: `?referringPage=${referringPage}&searchQuery=${searchQuery}&searchTarget=${searchTarget}`,
    })
  );
}

const PLACEHOLDER_TEXT = 'Search';
const numberOnly = /^[0-9]*$/;
function GlobalSearch({ classNames, dispatch, initialTarget = '', initialValue = '', referringPage }: IProps): JSX.Element {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [searchTarget, setSearchTarget] = useState(initialTarget);
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    setDisableButton(!searchTarget || !searchQuery || (searchTarget === SearchEnum.Orders && !numberOnly.test(searchQuery)));
  }, [searchQuery, searchTarget]);

  return (
    <form
      className={`search ${styles['global-search']} ${classNames}`}
      onSubmit={(e): void => handleSubmit(e, dispatch, referringPage, searchQuery, searchTarget)}
    >
      <select
        className={`search__select ${styles['global-search__select']} focus-ring`}
        required
        onChange={(e: any): void => setSearchTarget(e.target.value)}
        value={searchTarget}
      >
        <option value=''>Select Filter</option>
        <AccessControl allowedRole={UserRolesEnum.ViewTransactions}>
          <option value={SearchEnum.Transactions}>Transaction Ref</option>
        </AccessControl>
        <option value={SearchEnum.Orders}>Order Number</option>
        {/* <option value='tag'>Tag</option> */}
        <option value={SearchEnum.PoNumber}>PO</option>
      </select>
      <SearchBox
        disabled={disableButton}
        placeholder={PLACEHOLDER_TEXT}
        maxLength={searchTarget === SearchEnum.Orders ? 9 : -1}
        onChange={(e: any): void => setSearchQuery(e.target.value)}
        buttonIcon={<MagnifyingGlass aria-hidden='true' focusable='false' role='img' />}
        onSubmit={(e): void => handleSubmit(e, dispatch, referringPage, searchQuery, searchTarget)}
        value={searchQuery}
        classNames={classNames}
      />
    </form>
  );
}
export default connect()(GlobalSearch);
