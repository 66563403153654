import { AxiosResponse } from 'axios';
import { DropShipStateModel } from 'containers/CreateOrderPage/components/orderShipping/models/DropShipStateModel';
import environment from 'environment';
import HttpErrorResponseModel from 'models/HttpErrorResponseModel';
import { IConstructor } from 'models/IConstructor';
import EffectUtility from 'utilities/EffectUtility';
import HttpUtility from 'utilities/HttpUtility';
import HttpUtilityRequestBody from 'utilities/HttpUtilityRequestBody';
import AddToCartRequestModel from './models/AddToCartRequestModel';
import AddToCartResponseModel from './models/AddToCartResponseModel';
import GetCartResponseModel from './models/GetCartResponseModel';
import SubmitOrderRequestModel from './models/SubmitOrderRequestModel';
import SubmitOrderResponseModel from './models/SubmitOrderResponseModel';
import UpdateCartRequestModel from './models/UpdateCartRequestModel';
import UpdateCartResponseModel from './models/UpdateCartResponseModel';

type FlattenIfArray<T> = T extends (infer R)[] ? R : T;
type SingleItemOrArray<T> = T extends [] ? T[] : T;

interface IUpdateCartItem {
  cartItemId: number;
  cartItemRequest: AddToCartRequestModel;
}

export interface ITMERequest {
  companyName: string;
  street: string;
  locale: string | null;
  city: string;
  region: string;
  postalCode: number | string;
  country: string;
}
export default class CartStatelessCalls {
  public static addToCart(request: AddToCartRequestModel): Promise<AddToCartResponseModel | HttpErrorResponseModel> {
    const endpoint: string = `${(environment.api as any).cartItem}/`;

    return EffectUtility.postToModelBody<AddToCartResponseModel>(AddToCartResponseModel, endpoint, request);
  }

  public static deleteCartItem(cartItemId) {
    const endpoint = `${(environment.api as any).cartItem}/${cartItemId}`;

    return HttpUtility.delete(endpoint);
  }

  public static async getCartItem(cartItemId) {
    const endpoint = `${(environment.api as any).cartItem}/${cartItemId}`;
    const response = await HttpUtilityRequestBody.get(endpoint);

    return CartStatelessCalls._restModelCreator(AddToCartResponseModel, response);
  }

  public static async updateCartItem({ cartItemId, cartItemRequest }: IUpdateCartItem): Promise<HttpErrorResponseModel | AddToCartResponseModel> {
    const endpoint = `${(environment.api as any).cartItem}/${cartItemId}`;
    const response = await HttpUtilityRequestBody.put(endpoint, cartItemRequest);

    return CartStatelessCalls._restModelCreator(AddToCartResponseModel, response);
  }

  public static async updateCart(requestConfig: UpdateCartRequestModel): Promise<HttpErrorResponseModel | UpdateCartResponseModel> {
    const { CartId } = requestConfig;
    const endpoint = `${(environment.api as any).cart}/${CartId}`;
    console.log({ requestConfig });
    const response = await HttpUtilityRequestBody.put(endpoint, requestConfig);

    return CartStatelessCalls._restModelCreator(UpdateCartResponseModel, response);
  }

  public static async deleteCart(cartID): Promise<HttpErrorResponseModel | GetCartResponseModel> {
    const endpoint = `${(environment.api as any).cart}/${cartID}/remove-items`;
    const response = await HttpUtility.post(endpoint);

    return CartStatelessCalls._restModelCreator(GetCartResponseModel, response);
  }

  public static async submitOrder(cartID, expectedPrice): Promise<HttpErrorResponseModel | SubmitOrderResponseModel> {
    const endpoint = `${(environment.api as any).cart}/submit`;
    const submitOrderRequest = new SubmitOrderRequestModel({
      cartId: cartID,
      expectedPrice: expectedPrice.toFixed(2),
    });

    const response = await HttpUtility.post(endpoint, submitOrderRequest);

    console.log(response);
    return CartStatelessCalls._restModelCreator(SubmitOrderResponseModel, response);
  }

  public static async validateDropShip(request: DropShipStateModel) {
    const endpoint = `${(environment.api as any).shipping}/validate-address`;

    const TMERequest = this._convertDropShipModel(request);
    const response = await HttpUtility.post(endpoint, TMERequest);

    return response;
  }

  private static _convertDropShipModel(model: DropShipStateModel): ITMERequest {
    const TMERequest: ITMERequest = {
      city: model.city,
      companyName: model.name,
      country: model.country,
      street: model.address1,
      locale: model.address2,
      postalCode: model.zip.toString(),
      region: model.state,
    };

    return TMERequest;
  }

  // TODO: should convert to HttpUtility for abouve and remove this.
  private static _restModelCreator<T>(
    Model: IConstructor<FlattenIfArray<T>>,
    response: AxiosResponse | HttpErrorResponseModel
  ): SingleItemOrArray<T> | HttpErrorResponseModel {
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return !Array.isArray(response.data) ? new Model(response.data) : (response.data.map((json) => new Model(json)) as any);
  }
}
