import { IGenerateFieldsConfig } from 'stores/create-order/models/IDynamicFormModels';
import ProductsArr from 'constants/ProductsArr';
import { generateInitialFields } from './ProductConfigHelper';
import ITabState from 'stores/create-order/models/ITabState';
import getEditFields from './getEditFields';
import getStoredFields from './getStoredFields';

interface IProps {
  tabState: ITabState;
  accountNumber: number | undefined;
  product: string;
  setFieldState: any;
  setMasterFieldObject: any;
  step: string;
  setStep: any;
  setIsLoading: any;
  submittedList: string[];
  setSubmittedList: any;
  setNotValidProduct: any;
  setErrorMessage: any;
  setPriceTotal: any;
  setNonMasterFieldFormState: any;
  dispatch: any;
  productConfigs: any;
  generateFieldState: any;
  originalProductConfigs: any;
  storedSubmittedLists: any;
}

export default async function initialLoopEffect({
  tabState,
  accountNumber,
  product,
  setFieldState,
  setMasterFieldObject,
  step,
  setStep,
  setIsLoading,
  submittedList,
  setSubmittedList,
  setNotValidProduct,
  setErrorMessage,
  setPriceTotal,
  dispatch,
  productConfigs,
  generateFieldState,
  setNonMasterFieldFormState,
  originalProductConfigs,
  storedSubmittedLists,
}: IProps) {
  /*
   * Check that the user did not type a valid product that pingBiz is not currently supporting.
   */
  const validProduct = ProductsArr.find((item) => item.endpointProduct === product);
  if (!validProduct) {
    setNotValidProduct(true);
  } else {
    if (tabState.itemToEditId) {
      /**
       * Get item that the user is editing
       * The id is stored in the PRODUCT_CONFIG_LOCAL_STORE class
       */
      getEditFields({
        dispatch,
        product,
        accountNumber,
        setIsLoading,
        tabState,
        step,
        setStep,
        setPriceTotal,
        setFieldState,
        setMasterFieldObject,
        setNonMasterFieldFormState,
        setSubmittedList,
        setErrorMessage,
      });
    } else if (productConfigs[product.replace(' ', '_')]) {
      /**
       * If we already have the productConfig stored then use the default saved data
       * instead of makeing a round of calls.
       */
      getStoredFields({
        setErrorMessage,
        dispatch,
        product,
        productConfigs,
        step,
        setStep,
        originalProductConfigs,
        storedSubmittedLists,
        setMasterFieldObject,
        setSubmittedList,
        setFieldState,
      });
    } else {
      const requestConfig: IGenerateFieldsConfig = {
        accountNumber,
        product,
        setFieldState,
        setMasterFieldObject,
        step,
        setStep,
        setIsLoading,
        submittedList,
        setSubmittedList,
        setNotValidProduct,
        setErrorMessage,
        setPriceTotal,
        dispatch,
      };
      generateInitialFields(requestConfig);
    }
  }
}
